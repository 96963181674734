
import Vue, { PropType } from 'vue';

import OneTimePasswordMixin from '@/modules/oneTimePasswordMixin';

export default Vue.extend({
  name: 'already-have-authentication-app-form',

  mixins: [OneTimePasswordMixin],

  props: {
    form: Object as PropType<{ otp: string }>,
  },
});


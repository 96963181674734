/**
 * File: ptbUpdateHostRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class PtbUpdateHostRequest extends ApiRequest {
  get method () {
    return 'PATCH';
  }

  get url () {
    return `/toolbox/webapp/hosts/${this.params.hwId}`;
  }

  get body () {
    return { name: this.params.name };
  }

  getJson () {
    // No JSON content expected here
    return null;
  }
}

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
BaseForm
  template(slot="title")
    | {{ $t('Upgrade Parallels Desktop to Professional') }}
  template(slot="content")
    h3
      | {{ $t('License Keys') }}:
    .margin-top
      div(v-for="licKey in subscription.universalKeys", :key="licKey.key")
        | {{ licKey.key }}
    .margin-top-2x
      list(labelWidth="9", :labelCrop="false")
        list-item
          template(slot="label") {{ $t('Additional Charge') }}
          template(slot="value") {{ upgrade.pro_rated_price | price(upgrade.currency) }}
        list-item
          template(slot="label")
            formatter(:text="$t('Subscription Renewal Price (due on {date})')")
              template(slot="date")
                | {{ upgrade.next_billing_date | date }}
          template(slot="value") {{ upgrade.total_charge | price(upgrade.currency) }}
    PaymentOptionsModal
  template(slot="menu")
    PaymentOptionsLink(:url="upgrade.payment_options_url")
    btn.pull-right(
      @click="$emit('pay')",
      :data-name="$name('button-pay-and-upgrade')"
    ) {{ $t('Pay & Upgrade') }}
</template>

<script>
import BaseForm from '../baseForm';
import PaymentOptionsModal from '@/modules/subscriptions/paymentOptions/paymentOptionsModal.vue';
import PaymentOptionsLink from '@/modules/subscriptions/paymentOptions/paymentOptionsLink';
export default {
  props: ['subscription', 'upgrade'],
  components: { BaseForm, PaymentOptionsModal, PaymentOptionsLink },
};
</script>

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'modal-field',
};

</script>

<template lang="pug">

.row.margin-bottom-2x
  .col-xs-12.col-sm-4.text-right-sm.text-muted.line-height-3x
    slot(name="label")
  .col-xs-12.col-sm-6.no-padding-sm
    slot(name="field")

</template>

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import GetDownloadLinksRequest from '@/api/getDownloadLinksRequest';
import { HTTP_CODES } from '@core/constants/http';
import DownloadDataTable from './table';

export default {
  name: 'download-account-data',
  components: {
    DownloadDataTable,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      error: false,
      forbidden: false,
      loading: true,
      data: {
        request_info: {},
        report_links: [],
        expire: undefined,
      },
      columns: ['file_name', 'format', 'size', 'link'],
    };
  },
  async created () {
    const request = new GetDownloadLinksRequest({
      code: this.code,
      checkUnauthorizedResponse: false,
    });

    this.loading = true;
    try {
      this.data = await this.$api.call(request);
    } catch (e) {
      this.error = true;
      if (e.response.status === HTTP_CODES.UNAUTHORIZED) {
        await this.$router.push({ name: 'login', query: { continue: this.$route.fullPath } });
      } else if (e.response.status === HTTP_CODES.FORBIDDEN) {
        this.forbidden = true;
      }
    } finally {
      this.loading = false;
    }
  },
  computed: {
    accountLinks () {
      const link = this.data.account_link;
      return link ? [link] : [];
    },
    reportLinks () {
      return this.data.report_links;
    },
  },
};

</script>

<template lang="pug">

page(:appData="$appData", :error="error")
  template(v-if="forbidden")
    template(slot="error-header") {{ $t('Access Denied') }}
    div(slot="error-text")
    div(slot="error-button")
  template(slot="pageContent")
    card
      loader(:loading="loading")
        h2
          template(v-if="data.account_exists") {{ $t('Download Parallels User Account Data') }}
          template(v-else) {{ $t('Download Parallels User Data') }}
        h3.text-success.text-center.margin-bottom-2x {{ data.request_info.account_email }}
        .margin-bottom-2x {{ $t('Use links below to download your data collected from Parallels services. Please remember the links are valid until {date}.', { date: formatDateTime(data.expire) }) }}
        template(v-if="accountLinks.length")
          .margin-bottom.text-bold
            template(v-if="data.account_exists") {{ $t('1. Parallels User Account Data') }}
            template(v-else) {{ $t('1. Parallels User Data') }}
          .margin-bottom-2x
            download-data-table(
              name="downloadDataAccountLinks",
              :data="accountLinks",
              :columns="columns"
            )
        template(v-if="reportLinks.length")
          .margin-bottom.text-bold {{ $t('2. Parallels Technical Reports Data') }}
          .margin-bottom
            download-data-table(
              name="downloadDataReportLinks",
              :data="reportLinks",
              :columns="columns"
            )

</template>

/**
 * File: addKeyRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import AddKeyPage from '@/modules/addKey/addKeyPage.vue';

import { ADD_KEY_PAGE } from './constants';

export default [
  {
    path: '/add-key',
    name: ADD_KEY_PAGE,
    component: AddKeyPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/restore_purchases',
    redirect: {
      path: '/add-key',
      query: { restorePurchases: true },
    },
  },
];

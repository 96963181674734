<copyright>
  File: index.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import { required as requiredValidator, minLength, maxLength } from '@core/common/validators';

export default {
  name: 'dynamic-form',
  props: {
    name: {
      type: String,
      required: true,
    },
    fields: {
      type: Object,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  validations () {
    const values = {};
    if (this.fields) {
      for (const [key, item] of Object.entries(this.fields)) {
        values[key] = {};
        if (item.required && item.type !== 'boolean') {
          values[key].required = requiredValidator;
        }
        if (item.minLength) {
          values[key].minLength = minLength(item.minLength);
        }
        if (item.maxLength) {
          values[key].maxLength = maxLength(item.maxLength);
        }
      }
    }
    return { values };
  },
  created () {
    for (const [key, item] of Object.entries(this.fields)) {
      if (!this.values.hasOwnProperty(key) && item.hasOwnProperty('default')) {
        // eslint-disable-next-line vue/no-mutating-props
        this.values[key] = item.default;
      }
    }
  },
  methods: {
    fieldType (item) {
      if (item.type === 'boolean') {
        return 'checkbox';
      }
      return 'textbox';
    },
    attrs (item) {
      const attrs = Object.assign({}, item.attrs);
      if (attrs.placeholder) {
        attrs.placeholder = this.$t(attrs.placeholder) + ' *';
      }
      if (attrs.text) {
        attrs.text = this.$t(attrs.text);
      }
      return attrs;
    },
  },
  watch: {
    fields () {
      this.$v.$reset();
    },
  },
};
</script>

<template lang="pug">

div
  .margin-bottom-2x(v-for="(item, key, index) in fields", :key="index")
    component(
      size="big",
      :is="fieldType(item)",
      :name="key",
      :disabled="disabled",
      :validator="$v.values[key]"
      v-bind="attrs(item)",
      v-model="values[key]"
    )

</template>

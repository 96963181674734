<copyright>
File: hostsBlockModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

modal(ref="modal", name="hosts-block", size="big", :loading="loading")
  template(slot="header") {{ $t('Confirm Blacklisting') }}
  template(slot="content")
    .row.margin-bottom-2x
      .col-xs-12
        | {{ $tc('Parallels Toolbox will be prohibited on {amount} devices.', this.hwIds.length, { amount: this.hwIds.length }) }}
    .row.margin-bottom-2x
      .col-xs-12
        | {{ $t('Provide an optional message that will be displayed to the users:') }}
    .row.margin-bottom-2x
      .col-xs-12
        textbox(
          :placeholder="$t('Your message...')",
          type="text",
          :multiline="true",
          v-model="comment",
          :data-name="$name('textbox-message')"
        )
  template(slot="footer")
    btn(
      @click="$modal.hide()",
      :data-name="$name('button-cancel')"
    ) {{ $t('Cancel') }}
    btn(
      color="white",
      @click="block",
      :data-name="$name('button-block')"
    ) {{ $t('Blacklist') }}

</template>

<script>

import PtbBlockHostsRequest from '@/api/ptbBlockHostsRequest';

export default {
  name: 'hosts-block',

  data () {
    return {
      hwIds: [],
      comment: '',
      loading: false,
    };
  },

  methods: {
    show (hwIds) {
      this.hwIds = hwIds;
      this.$refs.modal.show();
    },

    async block () {
      this.loading = true;

      const request = new PtbBlockHostsRequest({ hwIds: this.hwIds, comment: this.comment });
      try {
        await this.$api.authorizedCall(request);
      } catch (e) {
        this.$toast.show({ text: this.$t('Error occurred'), color: 'red' });
        throw e;
      } finally {
        this.loading = false;
      }

      this.$refs.modal.hide();
      this.$toast.show({ text: $tc('{amount} computers blacklisted.', this.hwIds.length, { amount: this.hwIds.length }) });
      this.$emit('blocked');
    },
  },
};

</script>

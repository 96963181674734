/**
 * File: upgradeToProRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { HTTP_CODES } from '@core/constants/http';

export default class UpgradeToProRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return `/license/api/v1/subscriptions/${this.params.uuid}/upgrade_to_pd_pro`;
  }

  get body () {
    return {
      dry_run: this.params.dryRun,
    };
  }

  getPromise () {
    return super.getPromise()
      .catch((error) => {
        if (error.response.status === HTTP_CODES.CONFLICT) {
          return error.response.json();
        } else {
          throw new UpgradeToProRequestError({ code: error.response.status });
        }
      })
      .then((data) => {
        if (data && data.msg) {
          throw new UpgradeToProRequestError(data);
        }
        return data;
      });
  }

  load () {
    this.emitter.emit('beforeLoad', this);

    return this.getPromise()
      .then((data) => {
        this.data = data;
        this.emitter.emit('completed', this);
        return data;
      });
  }
}

export class UpgradeToProRequestError extends Error {
  constructor (data) {
    super(data.msg || 'UpgradeToProRequestError');

    if (data.code === HTTP_CODES.UNAUTHORIZED) {
      this.invalidToken = true;
    }
    if (data.msg === 'Auto-renew is disabled') {
      this.autoRenewIsOff = true;
    }
    if (data.msg === 'Failed to update subscription') {
      this.pendingTransaction = true;
    }
  }
}

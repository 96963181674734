/**
 * File: router.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';
import Router from 'vue-router';

// TODO: check all push/replace calls to prevent browser console flooding
window.addEventListener('unhandledrejection', function (event) {
  if (event.reason && ['NavigationDuplicated', 'Error'].includes(event.reason.name)) {
    event.preventDefault();
  }
});

Vue.use(Router);

export default new Router({
  routes: [],
  mode: 'history',
});

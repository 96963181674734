/**
 * File: computersContent.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue, { PropType } from 'vue';

import { copyExcluding } from '@core/common/utils';
import ComponentMixIn from '@/modules/componentMixIn';
import ComputersTable from './computersTable/computersTable.vue';
import ComputerDetails from './computerDetails/computerDetails.vue';
import FilterDropdownMy from '@/ui/filterDropdown/index.vue';
import PdComputersRequest from '@/api/pdComputersRequest';
import PdBusinessSubscriptionsRequest from '@/api/pdBusinessSubscriptionsRequest';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import PdHost from '@/models/pdHost';
import Subscription from '@/models/subscription';
import { Dictionary } from '@core/common/types';
import { PRODUCT_KEY_NAME_PDB, PRODUCT_NAME_PDB } from '@core/constants/subscriptions';
import { Resources } from '@core/constants/resources';
import GetPdBusinessSubscriptionsRequest, { IGetPdbDowngradeSeatsResponse } from '@/api/getPdbDowngradeSeatsRequest';

const DISPLAY_MODE_ALL = 'all';
const DISPLAY_MODE_ACTIVE = 'active';
const DISPLAY_MODE_DEACTIVATED = 'deactivated';
const DISPLAY_MODE_BLACKLISTED = 'blacklisted';

interface LicenceKeyInfo {
  name: string;
  key: string;
  value: string;
  parent: boolean;
  text: string;
  subscription: Subscription;
}

function makeGetPdBusinessSubscriptionsRequest (subscription: Subscription, token): Promise<IGetPdbDowngradeSeatsResponse> {
  const licenses = subscription.products.find(product => product.productKeyName === PRODUCT_KEY_NAME_PDB).licenses;
  const request = new GetPdBusinessSubscriptionsRequest({
    uuid: subscription.uuid,
    planned_date: subscription.plannedDowngradeAt.toISOString().slice(0, 10),
    resources: [{ resource_id: Resources.PDB_BY_SUBSCRIPTION, new_limit: licenses.nextPeriod }],
  });
  request.setAuthHeader('Token', token);
  return request.load();
}

export default Vue.extend({
  name: 'desktop-computers-component',
  mixins: [ComponentMixIn],
  components: { FilterDropdownMy, ComputersTable, ComputerDetails },

  props: {
    contextProduct: {
      type: String,
    },
    subscriptions: {
      type: Array as PropType<Subscription[]>,
    },
  },

  data () {
    const computers = {};
    computers[DISPLAY_MODE_ALL] = [];
    computers[DISPLAY_MODE_ACTIVE] = [];
    computers[DISPLAY_MODE_DEACTIVATED] = [];
    computers[DISPLAY_MODE_BLACKLISTED] = [];

    // TODO implement filters
    const displayOptions: Dictionary<DisplayModeOption[]> = {};
    displayOptions[DISPLAY_MODE_ACTIVE] = [
      { text: this.$t('Expiring Soon ({count})'), value: 'expiring', filter: (entry) => { return entry.isExpiringSoon; } },
      { text: this.$t('Expired ({count})'), value: 'expired', filter: (entry) => { return entry.isExpired; } },
    ];
    const today = new Date();
    const last7DaysTime = new Date(new Date().setDate(today.getDate() - 7));
    const last14DaysTime = new Date(new Date().setDate(today.getDate() - 14));
    const lastMonthTime = new Date(new Date().setMonth(today.getMonth() - 1));
    const last3MonthsTime = new Date(new Date().setMonth(today.getMonth() - 3));
    displayOptions[DISPLAY_MODE_DEACTIVATED] = [
      { text: this.$t('Last 7 days ({count})'), value: '7days', filter: (entry) => { return entry.updatedDate.getTime() > last7DaysTime; } },
      { text: this.$t('Last 14 days ({count})'), value: '14days', filter: (entry) => { return entry.updatedDate.getTime() > last14DaysTime; } },
      { text: this.$t('Last Month ({count})'), value: 'month', filter: (entry) => { return entry.updatedDate.getTime() > lastMonthTime; } },
      { text: this.$t('Last 3 Months ({count})'), value: '3month', filter: (entry) => { return entry.updatedDate.getTime() > last3MonthsTime; } },
    ];
    displayOptions[DISPLAY_MODE_BLACKLISTED] = [];

    return {
      allComputers: [] as PdHost[],
      source: computers,
      filters: {} as Dictionary<Function>,
      displayModeValue: (this.$route.query.mode || DISPLAY_MODE_ACTIVE) as string, // current display mode
      displayOptionOptions: [] as DisplayModeOption[], // current options
      displayOptionValue: this.$route.query.display || 'All',
      displayOptions,
      keys: [] as LicenceKeyInfo[],
      searchFields: [
        'name',
        'licKey',
        'buildNumber',
        'notes',
        'userName',
        'osName',
        'serialNumber',
      ],
      deactivatedComputers: [],
      computersToBeDeactivatedPageInformer: '',
      showFilters: true,
    };
  },

  created () {
    if (this.$route.query.mode !== this.displayModeValue) {
      const query = Object.assign({}, this.$route.query, { mode: this.displayModeValue });
      this.$router.replace({ query });
    }

    this.load();
  },

  watch: {
    displayModeValue (newVal) {
      const query = Object.assign({}, this.$route.query, { mode: newVal });
      this.$router.replace({ query });
      this.displayOptionOptions = this.displayOptions[newVal];
    },
    computers () {
      if (this.showFilters) {
        this.getDeactivatedComputers();
      }
      this.setDeactivatedComputersInformer();
    },
    subscriptions () {
      if (this.showFilters) {
        this.getDeactivatedComputers();
      }
    },
    $route (to) {
      if (to.query.mode !== this.displayModeValue) {
        this.displayModeValue = to.query.mode;
        this.load();
      }
      this.showFilters = !this.$route.query.deactivatedComputers;
      this.setDeactivatedComputersInformer();
    },
  },

  methods: {
    load () {
      const request = new CombinedApiRequest();
      request.addRequest('hosts', new PdComputersRequest({ product: this.contextProduct }));
      request.addRequest('keys', new PdBusinessSubscriptionsRequest());

      this.authorizedCall(request).then(() => {
        const allComputers = (request.getRequest('hosts') as PdComputersRequest).getHosts();
        const activeComputers = []; const deactivatedComputers = []; const blacklistedComputers = [];

        allComputers.forEach((c) => {
          if (c.activated) {
            activeComputers.push(c);
          } else if (c.blocked) {
            blacklistedComputers.push(c);
          } else {
            deactivatedComputers.push(c);
          }
        });

        this.source[DISPLAY_MODE_ALL] = allComputers;
        this.source[DISPLAY_MODE_ACTIVE] = activeComputers;
        this.source[DISPLAY_MODE_DEACTIVATED] = deactivatedComputers;
        this.source[DISPLAY_MODE_BLACKLISTED] = blacklistedComputers;

        if (this.$route.query.modal === 'details') {
          const computer = this.getComputerById(this.$route.query.hwid as string);
          if (computer) {
            // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
            this.$refs.computerDetailsModal.show(computer);
          }
        }
        this.displayOptionOptions = this.displayOptions[this.displayModeValue];
        this.$emit('updateComputersAmount', {
          amount: activeComputers.length,
        });

        this.keys = (request.getRequest('keys') as PdBusinessSubscriptionsRequest).getSubscriptions().map((subscription) => {
          // TODO Chrome OS
          const name = subscription.name || PRODUCT_NAME_PDB;
          return {
            name,
            key: subscription.licKey,
            value: subscription.licKey,
            parent: !!subscription.parent,
            text: `${subscription.licKey} ${name}`,
            subscription,
          };
        });
      });
    },

    getComputerById (hwId: string): PdHost {
      return this.computers.find((computer) => computer.hwId === hwId);
    },

    onComputerClick (computer: PdHost) {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.computerDetailsModal.show(computer);
    },

    onComputerDetailsHide () {
      this.$router.replace({ query: copyExcluding(this.$route.query, ['modal', 'hwid']) });
    },

    onComputerDetailsShow (computer: PdHost) {
      this.$router.replace({ query: Object.assign({}, this.$route.query, { modal: 'details', hwid: computer.hwId }) });
    },

    setDeactivatedComputersInformer () {
      if (this.$route.query.deactivatedComputers) {
        const currentDeactivatedComputers = this.deactivatedComputers.find(computer => computer.subscription.uuid === this.$route.query.uuid);
        this.computersToBeDeactivatedPageInformer = $t('Computers to be deactivated on {date} ({count})', { date: currentDeactivatedComputers.date, count: currentDeactivatedComputers.computers.length });
      } else {
        this.computersToBeDeactivatedPageInformer = '';
      }
    },

    async getDeactivatedComputers () {
      if (!this.subscriptions.length || !this.computers.length) {
        return;
      }

      const foundDeactivatedComputers = await this.subscriptions.reduce(async (prevPromise, subscription) => {
        const accumulator = await prevPromise;
        const currentDate = new Date(Date.now());
        const licenses = subscription.products.find(product => product.productKeyName === PRODUCT_KEY_NAME_PDB)?.licenses;
        const forecastQtyDelta = licenses?.nextPeriod - licenses?.thisPeriod;
        if (subscription.isActive && !!subscription.plannedDowngradeAt && forecastQtyDelta < 0 && subscription.plannedDowngradeAt > currentDate) {
          const data = await makeGetPdBusinessSubscriptionsRequest(subscription, this.$appData.session.token);
          const resource = data.resources.find(resource => resource.resource_id === Resources.PDB_BY_SUBSCRIPTION);
          accumulator.push({
            date: data.planned_date,
            forecastQtyDelta: resource.deactivated_hosts.length,
            subscription,
            computers: this.source[DISPLAY_MODE_ALL].filter(computer => resource.deactivated_hosts.some(host => host.host_name === computer.hostName)),
          });
        }
        return accumulator;
      }, Promise.resolve([]));

      if (foundDeactivatedComputers.length) {
        this.deactivatedComputers = foundDeactivatedComputers;
      }
    },
  },

  computed: {
    computers (): PdHost[] {
      if (this.$route.query.uuid) {
        return this.deactivatedComputers.find(computer => computer.subscription.uuid === this.$route.query.uuid)?.computers ||
          this.source[this.displayModeValue];
      }
      return this.source[this.displayModeValue];
    },

    displayModeOptions (): DisplayModeOption[] {
      const options = [
        {
          text: this.$t('All ({amount})', {
            amount: this.source[DISPLAY_MODE_ALL].length,
          }),
          value: DISPLAY_MODE_ALL,
        },
        {
          text: this.$t('Active ({amount})', {
            amount: this.source[DISPLAY_MODE_ACTIVE].length,
          }),
          value: DISPLAY_MODE_ACTIVE,
        }];

      if (this.$appData.session.isBusinessAdmin) {
        options.push(
          {
            text: this.$t('Deactivated ({amount})', {
              amount: this.source[DISPLAY_MODE_DEACTIVATED].length,
            }),
            value: DISPLAY_MODE_DEACTIVATED,
          },
          {
            text: this.$t('Blacklisted ({amount})', {
              amount: this.source[DISPLAY_MODE_BLACKLISTED].length,
            }),
            value: DISPLAY_MODE_BLACKLISTED,
          }
        );
      }
      return options;
    },
  },

});


import Vue from 'vue';
import ComponentMixin from '@/modules/componentMixIn';
import ListBusinessDomainsRequest, { BusinessDomain } from '@/api/listBusinessDomainsRequest';
import { DASHBOARD_PAGE, INVITATIONS_LIST_PAGE } from '@/routes/constants';
import JoinBusinessAccountRequest from '@/api/joinBusinessAccountRequest';

export default Vue.extend({
  name: 'join-to-business-account-modal',
  mixins: [ComponentMixin],
  props: {
    asModal: {
      type: Boolean,
      default: false,
    },
    companyName: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      loading: false,
      size: 10,
    };
  },
  computed: {
    invitationsCount (): number {
      return this.$appData.session?.pendingInvitationsCount;
    },
    showOneInvitationTitle (): boolean {
      return this.invitationsCount === 1;
    },
  },
  methods: {
    handleReviewInvitationsClick (): void {
      this.$router.push({ name: INVITATIONS_LIST_PAGE });
      this.closeModal();
    },
    async handleJoinClick (): Promise<void> {
      this.loading = true;
      const request = new ListBusinessDomainsRequest();
      try {
        const invitations = await this.$api.authorizedCall(request);
        const domains = invitations.domains.filter((domain: BusinessDomain) => domain.is_invite);
        if (domains.length) {
          await Promise.all(domains.map(async (domain: BusinessDomain) => {
            await this.authorizedCall(new JoinBusinessAccountRequest({ domainId: domain.id }));
          }));
          await this.$api.refreshToken(true, true);
          this.$appData.setUserInDomain(this.$appData.session.businessDomainId);
          this.$toast.show({ color: 'green', text: this.$t('You have access to the business account {companyName} now.', { companyName: this.companyName }) });
          this.$router.push({ name: DASHBOARD_PAGE });
        }
        this.closeModal();
      } catch (error) {
        this.$toast.show({ color: 'red', text: this.$t('Error') });
      } finally {
        this.loading = false;
      }
    },
    closeModal (): void {
      if (this.asModal) {
        this.$modal.hide();
      }
      this.$emit('close');
    },
  },
});

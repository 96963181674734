
import Locale from '@/plugins/locale';
import StepAbstract from './stepAbstract.vue';
import { UpdateSsoGroupMappingRequest } from '@/api/sso/ssoGroupMappingRequest';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

interface SsoSettingsInterface {
  groupMapping: { prlGroupId: string; externalId: string; displayName: string }[];
  prlGroups: { id: string; name: string; mandatory: boolean }[];
  stepsDone: { mapping: boolean };
}

export default StepAbstract.extend({
  name: 'step-configure-user-groups-mapping',
  props: {
    title: {
      default: () => { return Locale.i18n.t('Configure User Groups Mapping'); },
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    const groupMapping = {};

    // Add default values
    this.settings.prlGroups.forEach((item) => {
      groupMapping[item.id] = { externalId: '', displayName: '' };
    });

    // Replace with available settings
    this.settings.groupMapping.forEach((item) => {
      groupMapping[item.prlGroupId] = item;
    });

    return {
      loading: false,
      groupMapping,
    };
  },
  validations () {
    const groupMapping = {};
    let vItem;
    const prlGroups = this.settings.prlGroups;

    prlGroups.forEach((item) => {
      vItem = {
        minLength: minLength(3),
        maxLength: maxLength(100),
      };

      if (item.mandatory) {
        vItem.required = required;
      }

      groupMapping[item.id] = { externalId: vItem, displayName: vItem };
    });

    return { groupMapping };
  },
  methods: {
    async update () {
      const params = {
        domainId: this.$appData.session.businessDomainId,
        mapping: Object.keys(this.groupMapping)
          .map((key) => {
            return {
              prlGroupId: key,
              externalId: this.groupMapping[key].externalId,
              displayName: this.groupMapping[key].displayName,
            };
          }),
      };

      const updateSsoGroupMappingRequest = new UpdateSsoGroupMappingRequest(params);
      this.loading = true;
      try {
        await this.$api.authorizedCall(updateSsoGroupMappingRequest);
      } finally {
        this.loading = false;
      }
    },
    onSaveClick () {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.update().then(() => {
        this.emitDone(true);
      });
    },
  },
});



import Vue from 'vue';
import { isLicenseKeyValid, required } from '@core/common/validators';
import StepsMixIn from './StepsMixIn.vue';

export default Vue.extend({
  name: 'forgot-page-step-license-key',

  mixins: [StepsMixIn],

  data () {
    return {
      licenseKey: '',
    };
  },

  validations: {
    licenseKey: {
      required,
      pattern: function (value) {
        return isLicenseKeyValid(value);
      },
    },
  },

});

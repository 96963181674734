/**
 * File: rasSubscriptionBillingPeriodsRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import BillingPeriod from '@/models/billingPeriod';

export default class RasSubscriptionBillingPeriodsRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return `/ras/api/v1/subscriptions/${this.params.uuid}/billing_periods`;
  }

  get body () {
    return { subsets_uuids: this.params.subsetUuids || [] };
  }

  get cacheNameSpace () {
    return undefined;
  }

  onFetchSuccess (periods) {
    return periods.map((item) => new BillingPeriod(item));
  }
}

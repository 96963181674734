<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'security-notice-text',
};

</script>

<template lang="pug">

div
  .margin-bottom {{ $t('To protect your account from fraudulent activity, Parallels takes the following additional measures each time the account email address is changed:') }}
  ul
    li {{ $t('We reset the list of mobile devices, remote computers, and web browsers that are authorized to access your Parallels account. You will be prompted to confirm you trust your web browser or mobile device next time you use it to sign in to your Parallels account.') }}
    li {{ $t('If you use Parallels Access to connect to your Macs or PCs, we sign out all your computers from Parallels Access. You will have to sign in again in the Parallels Access agent installed on these computers.') }}

</template>

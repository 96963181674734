
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'add-key-form-wrap',
  props: {
    asModal: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
  },
});



import Vue from 'vue';
import { CB_TRANSACTION_STATES } from '@core/constants/subscriptions';
import SubscriptionPollingRequest from '@/api/subscriptionPollingRequest';
import ChangeLicenseAmountRequest from '@/api/changeLicenseAmountRequest';
import SubscriptionContactRequest from '@/api/subscriptionContactRequest';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';

const TIMEOUT = 30 * 1000;
const INTERVAL = 1000;

export enum TransactionModalState {
  EmailCheck,
  Default,
  BeforeClose
}

export default Vue.extend({
  name: 'transaction-processing-modal',

  props: {
    modalSize: {
      type: [Number, String],
    },
    timeout: {
      type: Number,
      default: TIMEOUT,
    },
  },

  data () {
    return {
      state: TransactionModalState.EmailCheck as TransactionModalState,
      timeoutId: null as ReturnType<typeof setTimeout>,
      TransactionModalState,
      loading: false,
      request: null as ChangeLicenseAmountRequest,
      billingEmail: '',
    };
  },

  beforeDestroy () {
    this.clearTimeout();
  },

  methods: {
    onCloseClick () {
      if (this.state === TransactionModalState.BeforeClose) {
        this.$modal.hide();
        this.clearTimeout();
      } else {
        this.state = TransactionModalState.BeforeClose;
      }
    },
    onBackClick () {
      this.$modal.show(AddLicensesModal);
    },
    onContinueClick () {
      this.state = TransactionModalState.Default;
      this.changeLisenseAmount(this.request);
    },
    clearTimeout () {
      this.timeoutId && clearTimeout(this.timeoutId);
    },
    async show (e, request: ChangeLicenseAmountRequest) {
      this.request = request;
      this.billingEmail = '';

      this.loading = true;

      try {
        const { uuid, service } = request.params;
        const contactRequest = new SubscriptionContactRequest({ uuid, service, email: this.$appData.session.email });
        const { contactEmail, matched } = await this.$api.authorizedCall(contactRequest);

        if (!matched && contactEmail) {
          this.billingEmail = contactEmail;
        }
      } catch (_) {}

      this.loading = false;

      if (!this.billingEmail) {
        this.onContinueClick();
      }
    },
    async changeLisenseAmount (request: ChangeLicenseAmountRequest) {
      this.timeoutId = setTimeout(() => (this.timeoutId = null), this.timeout);

      await this.$api.authorizedCall(request);

      const { uuid, service } = request.params;

      const subscriptionPollingRequest = new SubscriptionPollingRequest({ uuid, initialTimeout: INTERVAL, service });
      this.$api.authorizedCall(subscriptionPollingRequest).then(() => {
        const subscription = subscriptionPollingRequest.getSubscription();
        if (subscription.transactionState !== CB_TRANSACTION_STATES.increase.offline) {
          this.$emit('success');
        }
        this.hide();
      });
    },
    hide () {
      this.clearTimeout();
      this.$modal.hide();
    },
  },
});


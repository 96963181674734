/**
 * File: pswRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  PSW_SUBPAGE_SUBSCRIPTIONS,
  PSW_SUBPAGE_SUBSCRIPTION_DETAILS, PSW_DOWNLOADS_PAGE
} from './constants';
import pswPage from '@/modules/psw/pswPage.vue';
import { PRODUCT_KEY_NAME_PSW, PRODUCT_NAME_PSW } from '@core/constants/subscriptions';

export default [
  {
    path: '/psw/subscriptions',
    name: PSW_SUBPAGE_SUBSCRIPTIONS,
    component: pswPage,
    props: () => ({
      content: PSW_SUBPAGE_SUBSCRIPTIONS,
      productKeyName: PRODUCT_KEY_NAME_PSW,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_PSW}`,
    },
  },
  {
    path: '/psw/subscriptions/:id',
    name: PSW_SUBPAGE_SUBSCRIPTION_DETAILS,
    component: pswPage,
    props: () => ({
      content: PSW_SUBPAGE_SUBSCRIPTION_DETAILS,
      productKeyName: PRODUCT_KEY_NAME_PSW,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_PSW}`,
    },
  },
  {
    path: '/psw/downloads',
    name: PSW_DOWNLOADS_PAGE,
    component: pswPage,
    props: () => ({
      content: PSW_DOWNLOADS_PAGE,
      productKeyName: PRODUCT_KEY_NAME_PSW,
    }),
    meta: {
      requiresAuth: true,
      title: `Download - ${PRODUCT_NAME_PSW}`,
    },
  },
];

/**
 * File: desktopLitePage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import ProductPageMixIn from '@/modules/productPageMixIn';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import { PRODUCT_KEY_NAME_PDL } from '@core/constants/subscriptions';
import { support as supportLinks } from '@core/constants/links';
import { STATUS_ACTIVE } from '@/models/subscription';
import { GET_SUPPORT_PAGE } from '@/routes/constants';
import Vue from 'vue';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'DesktopLitePage',
  mixins: [ComponentMixIn, ProductPageMixIn, BreadcrumbsMixin],
  components: {
    SubscriptionsContent,
    SubscriptionDetailsContent,
    SubscriptionNotification,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },
  props: ['appData', 'content'],

  data () {
    return {
      supportLinks,
      personalMode: this.appData.session.isPersonalUser(this.appData.userInDomain),
      subscriptions: [],
      // filling when subscriptions is loaded
      subscriptionsAmount: 0,
      computersAmount: 0,
      productKey: PRODUCT_KEY_NAME_PDL,
      displayNotification: true,
    };
  },

  created (): void {
    if (!this.personalMode) {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      this.error = true; // Deny for business
    }
    this.load();
  },

  methods: {
    load (): void {
      const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_PERSONAL });
      this.authorizedCall(request)
        .then((data) => {
          this.subscriptions = request.getSubscriptions().filter(s => s.hasProduct(PRODUCT_KEY_NAME_PDL));
          this.subscriptions.forEach((s) => {
            this.subscriptionsAmount += 1;
            this.computersAmount += s.firstProductUsage;
          });
        });
    },
  },

  computed: {
    subpages (): MenuItem[] {
      return [
        {
          text: this.$t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
          link: { name: 'desktopLiteSubscriptions', query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE } },
        },
        {
          text: this.$t('Support'),
          click: () => {
            if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
              this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_PDL } });
            } else {
              window.location.href = this.supportLinks[PRODUCT_KEY_NAME_PDL];
            }
          },
        },
      ];
    },
  },
});

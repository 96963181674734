<copyright>
File: go.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import api from '@/api';
import GetRedirectLinkRequest from '@/api/getRedirectLinkRequest';
import { isExternalUrl } from '@core/common/url';

export default {
  name: 'go',
  created () {
    if (this.$route.name === 'go') {
      this.makeRedirect(this.$route.query);
    }
  },
  methods: {
    /**
     * Makes redirects/navigates
     * @param url
     * @return {Promise<void>}
     */
    async makeRedirect (url) {
      if (!url) url = '/';
      if (typeof url === 'object') {
        if (url.service && url.service !== 'self') {
          url = (await api.authorizedCall(new GetRedirectLinkRequest(url))).url;
        } else {
          url = url.continue || '/';
        }
      }
      if (isExternalUrl(url)) {
        window.location.replace(url);
      } else {
        this.$router.replace(url);
      }
    },
  },
};

</script>

<template lang="pug">

page

</template>

/**
 * File: ticket.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import CoreModel from '@core/common/model';

export enum TicketStatus {
  new = 'new',
  inProgress = 'in_progress',
  waitingMyResponse = 'waiting_my_response',
  callScheduled = 'call_scheduled',
  closed = 'closed',
  inEngineering = 'in_engineering',
  merged = 'merged',
  unknown = 'unknown',
}

export enum ProductKey {
  pdfm = 'pdfm',
  pdb = 'pdb',
  pmm = 'pmm',
  pax = 'pax',
  ras = 'ras',
  paxb = 'paxb',
  pdpro = 'pdpro',
  pdlite = 'pdlite',
  ptb = 'ptb',
  ptbb = 'ptbb',
  myacc = 'myacc',
  pdfc = 'pdfc',
  awg = 'awg',
  daas = 'daas',
  pbi ='pbi',
}

export type RequestOption = 'phone' | 'skype' | 'email' | 'chat';
export type Severity = 1 | 2 | 3 | 4;

export default class Ticket extends CoreModel {
  id: string;
  activationRequest: boolean;
  self: string;
  fields: {
    status: TicketStatus;
    mergedIn: string|null;
    productKey: ProductKey;
    requestOption: RequestOption;
    author: string;
    created: string;
    summary: string;
    productId: string;
    requestType: string;
    severity: Severity;
    description: string;
    priority: { name: string; id: number }|null;
    queue: { name: string; id: number }|null;
    timetracking: { timeSpent: string; remainingEstimate: string; originalEstimate: string }|null;
    transactions: { name: string; content: string; link: string; details: string; preview: string; created: string }[]|null;
    watchers: string[]|null;
  };

  actions: Array<string>;
}

<copyright>
File: appleDisconnect.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import RestorePasswordRequest from '@/api/restorePasswordRequest';
import componentMixIn from '@/modules/componentMixIn';
import EmailChange from '../emailChange/emailChange.vue';

export default {
  name: 'apple-disconnect',

  mixins: [componentMixIn],

  props: {
    session: Object,
  },

  data () {
    return {
      loading: false,
    };
  },
  methods: {
    resetPassword () {
      const request = new RestorePasswordRequest({ email: this.session.email });
      this.authorizedCall(request, 'loading').then(() => this.$toast.show({
        text: $t('The instructions on how to reset your password have been sent to {email}', { email: this.session.email }),
      }));
    },
    showChangeEmailModal () {
      this.$modal.show(EmailChange);
    },
  },
};

</script>

<template lang="pug">

div
  modal(
    name="appleDisconnect",
    size="big",
    :data-name="$name('modal-apple-disconnect-change')",
    ref="modal"
  )
    template(slot="header") {{ $t('Disconnect Apple Account?') }}
    template(slot="content")
      loader(:loading="loading")
        p
          span.text-bold {{ $t('Important!') }}&nbsp;
          | {{ $t('Before you disconnect it, make sure you can still access your Parallels account:') }}
        p 1.&nbsp;
          formatter(:text="$t('Check that you can access the email associated with your Parallels account ({email}). If not, you can change this email.')")
            a(slot="email", :href="`mailto:${session.email}`") {{ session.email }}
        p 2. {{ $t('Make sure you remember the password for your Parallels account. If you forgot it, reset the password.') }}
        div.text-center
          btn(
            color="white",
            size="small"
            type="reset",
            v-text="$t('Change Email')",
            @click="showChangeEmailModal"
            :data-name="$name('button-change-email')"
          ).margin-right
          btn(
            color="white",
            size="small"
            type="reset",
            v-text="$t('Reset Password')",
            @click="resetPassword"
            :data-name="$name('button-reset-password')"
          ).margin-left
    template(slot="footer", v-if="!loading")
      btn(
        color="blue",
        :disabled="loading",
        v-text="$t('Disconnect')",
        @click="$emit('disconnect')",
        :data-name="$name('button-disconnect')"
      )
      btn(
        color="white",
        type="reset",
        :disabled="loading",
        v-text="$t('Cancel')",
        @click="$refs.modal.hide()"
        :data-name="$name('button-cancel')"
      )

</template>

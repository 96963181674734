/**
 * File: confirmEmailChangeOld.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { ConfirmEmailChangeBaseGet, ConfirmEmailChangeBaseConfirm } from './confirmEmailChangeBase';

export class ConfirmEmailChangeOldGet extends ConfirmEmailChangeBaseGet {
  get url () {
    return `/account/webapp/confirm_email_change/old/${this.params.actionCode}`;
  }
}

export class ConfirmEmailChangeOldConfirm extends ConfirmEmailChangeBaseConfirm {
  get url () {
    return `/account/webapp/confirm_email_change/old/${this.params.actionCode}`;
  }
}

export class ConfirmEmailChangeOldResendNew extends ConfirmEmailChangeBaseConfirm {
  get url () {
    return `/account/webapp/confirm_email_change/resend/${this.params.actionCode}`;
  }
}


import Locale from '@/plugins/locale';
import StepAbstract from './stepAbstract.vue';

export default StepAbstract.extend({
  name: 'step-add-users-to-the-application-groups',
  props: {
    title: {
      default: () => { return Locale.i18n.t('Add Users to Application Groups'); },
    },
  },
});

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import UpdateNotificationSettingsRequest from '@/api/updateNotificationSettingsRequest';

export default {
  name: 'unsubscribe-notification-email',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      // Used for page template only
      error: true,
    };
  },
  created () {
    const request = new UpdateNotificationSettingsRequest({
      product_notifications_on: false,
    });
    request.setAuthHeader('ActionCode', this.code);

    this.loading = true;

    request.load().catch(() => {
      this.openNotificationSettings();
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    openNotificationSettings () {
      this.error = false;
      this.$router.push({
        name: 'personalProfile',
        params: {
          page: 'notification',
        },
      });
    },
  },
};

</script>

<template lang="pug">

page(:appData="$appData", :error="error")
  loader(v-if="loading", :loading="true", slot="error-content")
  template(v-else)
    template(slot="error-image")
      .image.email
    template(slot="error-header") {{ $t('Unsubscribed!') }}
    template(slot="error-text") {{ $t('You have been unsubscribed from receiving email notifications about your registered Parallels products, subscriptions and trials.') }}
    template(slot="error-button")
      btn.block-xs(@click="openNotificationSettings", color="transparent") {{ $t('My Notification Settings') }}

</template>

<style scoped lang="sass">

.image.email
  width: 155px
  height: 135px
  background: url('#{ $image-root }/email3.svg') no-repeat

</style>

/**
 * File: offlineDeactivation.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import fileUploader from '@/ui/file-uploader';
import RasOfflineRequest from '@/api/rasOfflineRequest';

export default {
  name: 'offline-deactivation',

  components: { fileUploader },

  data () {
    return {
      loading: false,
      succeeded: false,
      failed: false,
      resultMessage: '',
      instructionsMessage: '',
      prompt: false,
      promptMessage: '',
      licenseKeyMessage: '',
      deactivationFile: null,
    };
  },

  methods: {
    onUploadSuccess (files) {
      this.loading = true;

      const file = files[0];
      this.deactivationFile = file;

      const fileCopy = new File([file], file.name, { type: file.type });
      const request = new RasOfflineRequest({ action: 'deactivate', info: true }, fileCopy);

      this.$api.authorizedCall(request).then((data) => {
        this.setPrompt(data);
      }).catch((e) => {
        e.response.json().then((data) => {
          this.setFailureResult(data.msg);
        });
      }).finally(() => {
        this.loading = false;
      });
    },

    setPrompt (data) {
      this.prompt = true;
      this.promptMessage = $t('Deactivate the farm "{farmName}"?', { farmName: data.farm_name });
      this.licenseKeyMessage = $t('The farm is activated with the license key {licKey}.', { licKey: data.lic_key });
    },

    setSuccessResult (data) {
      this.succeeded = true;
      this.resultMessage = this.$t('The farm "{farmName}" has been successfully deactivated!', { farmName: data.farm_name });
      this.instructionsMessage = this.$t('You may now use the license key for activating a different farm.');
    },

    setFailureResult (errorMessage) {
      this.failed = true;

      switch (errorMessage) {
        case 'invalid file':
        case 'invalid json':
        case 'invalid checksum':
        case 'invalid payload':
          this.resultMessage = this.$t('The offline deactivation request file is not valid!');
          this.instructionsMessage = this.$t('Please use the file that has been created for offline deactivation by Remote Application Server app.');
          break;
        case 'no such host':
          this.resultMessage = this.$t('This farm is not registered!');
          this.instructionsMessage = this.$t('Check that you\'re deactivating a farm that belongs to this business account.');
          break;
        case 'not activated':
        case 'offline prohibited':
          this.resultMessage = this.$t('Offline deactivation for this farm is not possible!');
          this.instructionsMessage = this.$t('Check that you\'re deactivating a farm that has been previously activated offline.');
          break;
        default:
          this.resultMessage = this.$t('There was a problem completing this request.');
          this.instructionsMessage = '';
      }
    },

    reset () {
      this.succeeded = false;
      this.failed = false;
      this.resultMessage = '';
      this.instructionsMessage = '';
    },

    cancelDeactivation () {
      this.prompt = false;
      this.promptMessage = '';
      this.licenseKeyMessage = '';
      this.deactivationFile = null;
    },

    applyDeactivation () {
      this.loading = true;
      this.prompt = false;

      const request = new RasOfflineRequest({ action: 'deactivate' }, this.deactivationFile);

      this.$api.authorizedCall(request).then((data) => {
        this.setSuccessResult(data);
      }).catch((e) => {
        e.response.json().then((data) => {
          this.setFailureResult(data.msg);
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};

/**
 * File: facebookLoginRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import SocialLoginRequest from './socialLoginRequest';

export default class FacebookLoginRequest extends SocialLoginRequest {
  get baseUrl () {
    return '/account/webapp/social_services/fb';
  }
}

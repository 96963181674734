/**
 * File: initialConfig.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';

export enum Environment {
  Dev00 = 'dev00',
  Dev01 = 'dev01',
  Dev03 = 'dev03',
  Staging = 'staging',
  Test = 'test',
  Test02 = 'test02',
  Production = 'production'
};

export default class InitialConfig extends CoreModel {
  sentryDsn: string;
  environment: Environment;
}

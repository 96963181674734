/**
 * File: rasRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  RAS_SUBPAGE_FARMS,
  RAS_SUBPAGE_DOWNLOADS,
  RAS_SUBPAGE_SUBSCRIPTIONS,
  RAS_SUBPAGE_SUBSCRIPTION_DETAILS,
  RAS_SUBPAGE_OFFLINE_ACTIVATION,
  RAS_SUBPAGE_OFFLINE_DEACTIVATION,
  RAS_BETA_PAGE,
  RAS_FARMS_PAGE,
  RAS_DOWNLOADS_PAGE,
  RAS_SUBSCRIPTIONS_PAGE,
  RAS_SUBSCRIPTION_DETAILS_PAGE,
  RAS_OFFLINE_ACTIVATION_PAGE,
  RAS_OFFLINE_DEACTIVATION_PAGE
} from './constants';
import RASPage from '../modules/ras/rasPage.vue';
import RasBetaPage from '@/modules/ras/beta.vue';
import { PRODUCT_KEY_NAME_RAS } from '@core/constants/subscriptions';

export default [
  {
    path: '/ras/beta',
    name: RAS_BETA_PAGE,
    component: RasBetaPage,
    meta: {
      requiresAuth: true,
      title: 'Technical Preview - Parallels Remote Application Server',
    },
  },
  {
    path: '/ras/farms/:type(prepaid|postpaid)',
    name: RAS_FARMS_PAGE,
    component: RASPage,
    props: (route) => ({
      content: RAS_SUBPAGE_FARMS,
      productKeyName: PRODUCT_KEY_NAME_RAS,
    }),
    meta: {
      requiresAuth: true,
      title: 'Farms - Parallels Remote Application Server',
    },
  },
  {
    path: '/ras/downloads',
    name: RAS_DOWNLOADS_PAGE,
    component: RASPage,
    props: (route) => ({
      content: RAS_SUBPAGE_DOWNLOADS,
      productKeyName: PRODUCT_KEY_NAME_RAS,
    }),
    meta: {
      requiresAuth: true,
      title: 'Download - Parallels Remote Application Server',
    },
  },
  {
    path: '/ras/subscriptions',
    name: RAS_SUBSCRIPTIONS_PAGE,
    component: RASPage,
    props: (route) => ({
      content: RAS_SUBPAGE_SUBSCRIPTIONS,
      productKeyName: PRODUCT_KEY_NAME_RAS,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Remote Application Server',
    },
  },
  {
    path: '/ras/subscriptions/:id',
    name: RAS_SUBSCRIPTION_DETAILS_PAGE,
    component: RASPage,
    props: (route) => ({
      content: RAS_SUBPAGE_SUBSCRIPTION_DETAILS,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Remote Application Server',
    },
  },
  {
    path: '/ras/farms',
    redirect: {
      name: RAS_FARMS_PAGE,
      params: {
        type: 'postpaid',
      },
    },
  },
  {
    path: '/ras/offline/activate',
    name: RAS_OFFLINE_ACTIVATION_PAGE,
    component: RASPage,
    props: (route) => ({
      content: RAS_SUBPAGE_OFFLINE_ACTIVATION,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ras/offline/deactivate',
    name: RAS_OFFLINE_DEACTIVATION_PAGE,
    component: RASPage,
    props: (route) => ({
      content: RAS_SUBPAGE_OFFLINE_DEACTIVATION,
    }),
    meta: {
      requiresAuth: true,
    },
  },
];

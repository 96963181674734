

import Vue from 'vue';
import BannerNotification from '@/ui/bannerNotification/index.vue';
import { FEATURE_PBI_BANNER } from '@core/constants/features';
import bannerNotificationMixin from '@/ui/bannerNotification/bannerNotificationMixin';
import { PBI_PRODUCT_LINK } from '@core/constants/links';

const KEY_NAME = 'pbi-notification-banner-hidden';

export default Vue.extend({
  name: 'pbi-notification-banner',
  components: {
    BannerNotification,
  },
  mixins: [bannerNotificationMixin],
  data () {
    return {
      featureKey: FEATURE_PBI_BANNER,
      storageKey: KEY_NAME,
      hideOnNextUserLogin: true,
    };
  },
  methods: {
    handleLinkClick () {
      window.open(PBI_PRODUCT_LINK, '_blank');
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      this.hide(this.hash);
    },
  },
});


/**
 * File: subscriptionsList.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue, { PropType } from 'vue';
import listItem from './item/item.vue';
import HideSubscriptionModal from '@/modules/subscriptions/list/hideSubscriptionModal.vue';
import Subscription from '@/models/subscription';

export default Vue.extend({
  name: 'subscriptions-list',

  components: {
    listItem,
    HideSubscriptionModal,
  },

  props: {
    subscriptions: {
      type: Array as PropType<Subscription[]>,
    },
    contextProduct: {
      type: String,
    },
  },

});

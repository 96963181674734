
import Vue from 'vue';

import { CONFIGURE_AAS_MODAL } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'configure-aas-link',
  props: ['fromModal', 'isBtn', 'color', 'size'],
  methods: {
    clickHandler (): void {
      const modal = this.$modal.get(CONFIGURE_AAS_MODAL);
      if (modal) {
        modal.$emit('linkClick', this.$props);
      }
    },
  },
});


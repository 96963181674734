/**
 * File: legacyLicenses.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import LegacyLicensesContent from './legacyLicensesContent.vue';
import Vue from 'vue';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'legacy-licenses',
  mixins: [BreadcrumbsMixin],
  components: { LegacyLicensesContent, Breadcrumbs }, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846

  props: {
    appData: Object,
  },
});

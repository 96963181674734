

import Vue from 'vue';

export default Vue.extend({
  name: 'index',

  data () {
    return {
      loading: null,
      switcherOptions: null,
      userInDomain: this.$appData.userInDomain,
    };
  },
  watch: {
    '$appData.userInDomain': function () {
      this.userInDomain = this.$appData.userInDomain;
      this.switcherOptions = this.buildSwitcherOptions(this.$appData.session);
    },
  },

  created () {
    this.switcherOptions = this.buildSwitcherOptions(this.$appData.session);

    // if user has been redirected to the index route he must be redirected to home page or dashboard
    if (this.$route.name === 'index') {
      if (this.isHomePageEnabledByDefault) {
        this.$router.replace({ name: 'home' });
      } else {
        this.$router.replace({ name: 'dashboard' });
      }
    }
  },

  computed: {
    isHomePageEnabledByDefault (): boolean {
      return this.$appData.session.showHomePage;
    },

    isBusinessUser (): boolean {
      return this.$appData.session.isBusinessUser(this.$appData.userInDomain);
    },
  },

  methods: {

    buildSwitcherOptions: function (session): SwitcherOption[] {
      const options = [];

      if (this.$appData.products.personal.length) {
        let name = session.name && session.name.trim();
        let optionTitle;
        if (!name) {
          name = session.email;
          optionTitle = this.$t('Personal Account');
        }
        options.push({
          title: name,
          optionTitle: optionTitle,
          subtitle: this.$t(' (Personal Account)'),
          text: session.email,
          value: session.personalDomainId,
        });
      }

      session.getBusinessDomains().forEach((domain) => {
        options.push({
          title: domain.companyName,
          subtitle: this.$t(' (Business Account)'),
          text: domain.isAdmin ? this.$t('My role: Administrator') : this.$t('My role: Regular Member'),
          value: domain.companyUuid,
        });
      });

      return options;
    },

    async onUserSwitched (userInDomain): Promise<void> {
      if (this.$appData.session.isPersonalUser(userInDomain) || this.$appData.session.isCurrentBusinessUser(userInDomain)) {
        this.$appData.setUserInDomain(userInDomain);
      } else {
        this.loading = true;
        await this.$api.switchBusinessDomain(userInDomain);
        this.loading = false;
      }
    },
  },
});


/**
 * File: subscriptions.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  KEY_NAME_RAS_IN_APP_TRIAL,
  prodKeyToName,
  PRODUCT_NAME_PMM,
  KEY_NAME_PMM_MDM,
  PRODUCT_NAME_PMM_MDM,
  RESOURCE_RAS_AZMP_CUSR_BY_SUBSCRIPTION
} from '../constants/subscriptions';
import { formatText } from './utils';
import { toSqlDateTimeString } from '@core/common/format/date';

export function getSubscriptionName (subscription, stringFunction) {

  if (subscription.name) {
    return subscription.name;
  }
  let productName = prodKeyToName(subscription.firstProductKeyName) || '';
  if (productName === PRODUCT_NAME_PMM) {
    // We have 2 possible values for PMM product - need to check in advance
    if (subscription.firstLicensedProductKeyName === KEY_NAME_PMM_MDM) {
      productName = PRODUCT_NAME_PMM_MDM;
    }
  }
  if (!stringFunction) {
    stringFunction = formatText;
  }

  if (subscription.isPermanent) {
    return String(stringFunction('{product_name} Permanent License', { product_name: productName }));
  }
  if (subscription.firstLicensedProductKeyName === KEY_NAME_RAS_IN_APP_TRIAL) {
    return String(stringFunction('{product_name} In-product Trial', { product_name: productName }));
  }
  if (subscription.firstLicensedProductKeyName === RESOURCE_RAS_AZMP_CUSR_BY_SUBSCRIPTION) {
    const startDateValue = toSqlDateTimeString(subscription.activatedAt);
    return String(
      stringFunction(
        'Parallels RAS managed app subscription (Azure) {start_date_time}',
        { start_date_time: startDateValue }
      )
    );
  }
  return String(stringFunction('{product_name} Subscription', { product_name: productName }));
}

/**
 * File: addKeyForm.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import { defineComponent } from 'vue';

import { required } from 'vuelidate/lib/validators';
import { isLicenseKeyValid } from '@core/common/validators';
import ComponentMixin from '@/modules/componentMixIn';
import addKeyMixin from '@/modules/addKey/addKeyMixin';
import FormWrap from './formWrap.vue';
import EditPropertiesModal from '@/modules/subscriptions/details/editPropertiesModal/editPropertiesModal.vue';
import KeyConflictModal from '@/modules/addKey/keyConflictModal.vue';

export default defineComponent({
  name: 'add-key-form',

  props: {
    asModal: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ComponentMixin, addKeyMixin],

  components: { FormWrap, EditPropertiesModal, KeyConflictModal },

  validations: {
    licenseKey: {
      required,
      pattern: function (value) {
        return isLicenseKeyValid(value);
      },
    },
  },
});

/**
 * File: legacyRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { LEGACY_LICENSES_PAGE } from '@/routes/constants';
import LegacyLicenses from '@/modules/legacyLicenses/legacyLicenses.vue';

export default [
  {
    path: '/legacy',
    name: LEGACY_LICENSES_PAGE,
    component: LegacyLicenses,
    meta: {
      requiresAuth: true,
    },
  },
];

/**
 * File: changeSessionLifetimeRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest, { Method, Json } from '@core/api/apiRequest';

export default class ChangeSessionLifetimeRequest extends ApiRequest {
  get url (): string {
    return '/account/webapp/session/ttl';
  }

  get method (): Method {
    return 'POST';
  };

  get body (): Json {
    return {
      extend: !!this.params.extend,
    };
  }
}

/**
 * File: productCard.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import {
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFC_PURCHASE,
  PRODUCT_NAME_PDFM,
  PRODUCT_NAME_PDFC,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PTB,
  KEY_NAME_PMM
} from '@core/constants/subscriptions';
import Subscription from '@/models/subscription';
import LegacyLicense from '@/models/legacyLicense';

export default class ProductCardsFactory {
  static fromSubscription (subscription) {
    switch (true) {
      case subscription instanceof Subscription:
        return ProductCardsFactory.fromLsSubscription(subscription);
      case subscription instanceof LegacyLicense:
        return ProductCardsFactory.fromLegacyLicense(subscription);
      default:
        return [];
    }
  }

  static fromLsSubscription (subscription) {
    return subscription.products.map((product) => {
      if (product.productKeyName === PRODUCT_KEY_NAME_PMM) {
        return new PmmProductCard(subscription, product);
      }
      return new LsProductCard(subscription, product);
    });
  }

  static fromLegacyLicense (license) {
    return [new LegacyProductCard(license)];
  }

  static addPdfcPuchaseLinkCard () {
    return new PdfcPurchaseLinkCard();
  }
}

class LsProductCard {
  productKeyName: string;
  productName: string;
  totalLimit: number;
  totalSubscriptions: number;
  expiredSubscriptions: number;
  activeSubscriptions: number;
  postpaidContracts: number;
  prepaidContracts: number;
  permanentSubscriptions: number;
  hasPermanent: boolean;
  hasSubscription: boolean;
  hasExpiringSubscriptions: boolean;
  canBuyMoreLicenses: number;
  postpaidUuids: string[];
  legacyLicenses: number;

  constructor (subscription, product) {
    const postpaidUuids = [];
    let totalLimit = product.licenses.thisPeriod;

    if (subscription.isPdfmPermanentPersonal) {
      totalLimit = subscription.firstProductLimitThisPeriod;
    } else if (subscription.hasProduct(PRODUCT_KEY_NAME_PDFC) && product.licenses.ratio) {
      totalLimit /= product.licenses.ratio;
    } else if (subscription.isPostPaid) {
      totalLimit = 0;
    }

    let canBuyMoreLicenses = subscription.canBuyMoreLicenses && subscription.hasProduct(product.productKeyName);
    // TODO: supply correct "isPrimaryResource" value for all products (not only for toolbox) from backend
    if (product.productKeyName.startsWith(PRODUCT_KEY_NAME_PTB)) {
      if (!product.isPrimaryResource) {
        canBuyMoreLicenses = false;
      }
    }
    Object.assign(this, {
      productKeyName: product.productKeyName,
      productName: product.productName,
      totalLimit: (!subscription.isExpired) ? totalLimit : 0,
      totalSubscriptions: 1,
      activeSubscriptions: (subscription.isExpired) ? 0 : 1,
      expiredSubscriptions: (subscription.isExpired) ? 1 : 0,
      postpaidContracts: (subscription.isPostPaid) ? 1 : 0,
      prepaidContracts: (!subscription.isPostPaid) ? 1 : 0,
      hasPermanent: subscription.isPermanent,
      hasSubscription: !subscription.isPermanent,
      hasExpiringSubscriptions: subscription.isExpiringSoon,
      permanentSubscriptions: subscription.isPermanent ? 1 : 0,
      canBuyMoreLicenses: canBuyMoreLicenses,
      legacyLicenses: 0,
      postpaidUuids: postpaidUuids,
    });
    if (subscription.isPostPaid) {
      postpaidUuids.push(subscription.uuid);
    }
  }

  merge (productCard) {
    if (productCard.legacyLicenses) {
      this.legacyLicenses += productCard.legacyLicenses;
    }

    this.totalLimit += productCard.totalLimit;
    this.totalSubscriptions += productCard.totalSubscriptions;
    this.expiredSubscriptions += productCard.expiredSubscriptions;
    this.activeSubscriptions = this.totalSubscriptions - this.expiredSubscriptions;
    this.postpaidContracts += productCard.postpaidContracts;
    this.prepaidContracts += productCard.prepaidContracts;
    this.permanentSubscriptions += productCard.permanentSubscriptions;
    this.hasPermanent = this.hasPermanent || productCard.hasPermanent;
    this.hasSubscription = this.hasSubscription || productCard.hasSubscription;
    this.hasExpiringSubscriptions = this.hasExpiringSubscriptions || productCard.hasExpiringSubscriptions;
    this.canBuyMoreLicenses = this.canBuyMoreLicenses || productCard.canBuyMoreLicenses;
    this.postpaidUuids = this.postpaidUuids.concat(productCard.postpaidUuids);
  }

  get isActive () {
    return this.activeSubscriptions > 0;
  }
}

class PmmProductCard {
  productKeyName: string;
  productName: string;
  totalMacManagementLimit: number;
  totalMdmLimit: number;
  totalSubscriptions: number;
  expiredSubscriptions: number;
  activeSubscriptions: number;
  hasSubscription: boolean;
  hasExpiringSubscriptions: boolean;
  canBuyMoreLicenses: boolean;

  constructor (subscription, product) {
    let totalMacManagementLimit = 0;
    let totalMdmLimit = 0;
    const totalLimit = product.licenses.thisPeriod;

    if (product.keyName === KEY_NAME_PMM) {
      totalMacManagementLimit = totalLimit;
    } else {
      totalMdmLimit = totalLimit;
    }

    Object.assign(this, {
      productKeyName: product.productKeyName,
      productName: product.productName,
      totalMacManagementLimit: (!subscription.isExpired) ? totalMacManagementLimit : 0,
      totalMdmLimit: (!subscription.isExpired) ? totalMdmLimit : 0,
      totalSubscriptions: 1,
      activeSubscriptions: (subscription.isExpired) ? 0 : 1,
      expiredSubscriptions: (subscription.isExpired) ? 1 : 0,
      hasExpiringSubscriptions: subscription.isExpiringSoon,
      canBuyMoreLicenses: subscription.canBuyMoreLicenses && subscription.hasProduct(product.productKeyName),
    });
  }

  merge (productCard) {
    this.totalMacManagementLimit += productCard.totalMacManagementLimit;
    this.totalMdmLimit += productCard.totalMdmLimit;
    this.totalSubscriptions += productCard.totalSubscriptions;
    this.expiredSubscriptions += productCard.expiredSubscriptions;
    this.activeSubscriptions = this.totalSubscriptions - this.expiredSubscriptions;
    this.hasSubscription = this.hasSubscription || productCard.hasSubscription;
    this.hasExpiringSubscriptions = this.hasExpiringSubscriptions || productCard.hasExpiringSubscriptions;
    this.canBuyMoreLicenses = this.canBuyMoreLicenses || productCard.canBuyMoreLicenses;
  }
}

class LegacyProductCard {
  totalLimit: number;
  legacyLicenses: number;

  constructor (license) {
    Object.assign(this, {
      productKeyName: PRODUCT_KEY_NAME_PDFM,
      productName: PRODUCT_NAME_PDFM,
      totalLimit: 1,
      totalSubscriptions: 0,
      activeSubscriptions: 0,
      expiredSubscriptions: 0,
      keys: [],
      postpaidContracts: 0,
      hasPermanent: false,
      hasSubscription: false,
      canBuyMoreLicenses: false,
      legacyLicenses: 1,
    });
  }

  merge (productCard) {
    this.totalLimit += productCard.totalLimit;
    this.legacyLicenses += productCard.legacyLicenses || 0;
  }
}

class PdfcPurchaseLinkCard {
  productKeyName: string;
  productName: string;

  constructor () {
    Object.assign(this, {
      productKeyName: PRODUCT_KEY_NAME_PDFC_PURCHASE,
      productName: PRODUCT_NAME_PDFC,
    });
  }
}

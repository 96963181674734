

import Vue, { PropType } from 'vue';
import AuthenticatorApp from '@/models/authenticatorApp';
import OneTimePasswordMixin from '@/modules/oneTimePasswordMixin';

export default Vue.extend({
  name: 'deleting-authenticator-app-modal',

  mixins: [OneTimePasswordMixin],

  props: {
    authenticatorApp: {
      type: Object as PropType<AuthenticatorApp>,
      required: true,
    },
  },

});


/**
 * File: deleteAuthenticatorAppRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */
import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface DeleteAuthenticatorAppRequestParams {
  oneTimePassword: string;
  authAppOuterId: string;
}

export default class DeleteAuthenticatorAppRequest extends ApiRequest<DeleteAuthenticatorAppRequestParams> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/auth_apps/${this.params.authAppOuterId}/delete`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return { one_time_password: this.params.oneTimePassword };
  }
}

/**
 * File: registerBusinessAccountRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class RegisterBusinessAccountRequest extends ApiRequest {
  get url () {
    return '/account/webapp/use_for_business';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      company: this.params.company,
      first_name: this.params.first_name,
      last_name: this.params.last_name,
    };
  }

  get cacheNameSpace () {
    return false;
  }
}

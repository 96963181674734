/**
 * File: ssoDomainsRequests.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import { ACCOUNT_SERVICE } from '@core/constants/services';
import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import SsoDomain from '@/models/ssoDomain';

const URI_PREFIX = `/${ACCOUNT_SERVICE}/api/v1/ba`;

export interface AddSsoDomainRequestParams {
  companyUuid: number;
  domainName: string;
  description: string;
  notes: string;
}

/* eslint-disable camelcase */
export interface AddSsoDomainRequestResponse {
  job_id: string;
}
/* eslint-enable camelcase */

export interface AddSsoDomainJobStatusRequestParams {
  companyUuid: number;
  jobId: string;
}

export enum CreateSsoDomainJobStatus {
  NEW = 'new',
  QUEUED = 'in_queue',
  FAILED = 'failed',
  EXISTS = 'already_exists',
  COMPLETED = 'completed'
}

/* eslint-disable camelcase */
export interface AddSsoDomainJobStatusRequestResponse {
  status: CreateSsoDomainJobStatus;
  exists_domain_id?: number;
  sso_domain_id?: number;
}
/* eslint-enable camelcase */

export interface UpdateSsoDomainRequestParams {
  companyUuid: number;
  domainId: number;
  description: string;
  notes: string;
}

export interface DeleteSsoDomainRequestParams {
  companyUuid: number;
  domainId: number;
}

export interface DeleteSsoDomainsRequestParams {
  companyUuid: number;
  domains: number[];
}

export interface SsoDomainsRequestParams {
  companyUuid: string;
}

export default class SsoDomainsRequest extends ApiRequest<SsoDomainsRequestParams> {
  get url (): string {
    return `${URI_PREFIX}/${this.params.companyUuid}/sso/domains`;
  }

  getDomains (): SsoDomain[] {
    return this.data.objects.map((rawDomain) => new SsoDomain(rawDomain));
  }

  get cacheNameSpace (): undefined {
    return undefined;
  }
}

export class AddSsoDomainRequest extends ApiRequest<AddSsoDomainRequestParams, AddSsoDomainRequestResponse> {
  get url (): string {
    return `${URI_PREFIX}/${this.params.companyUuid}/sso/domains`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    const p = this.params;
    return {
      domain_name: p.domainName,
      description: p.description,
      notes: p.notes,
    };
  }
}

export class AddSsoDomainJobStatusRequest extends ApiRequest<AddSsoDomainJobStatusRequestParams, AddSsoDomainJobStatusRequestResponse> {
  get url (): string {
    return `${URI_PREFIX}/${this.params.companyUuid}/sso/domains/jobs/${this.params.jobId}`;
  }

  get cacheNameSpace () {
    return undefined;
  }

  get method (): Method {
    return 'GET';
  }
}

export class UpdateSsoDomainRequest extends ApiRequest<UpdateSsoDomainRequestParams> {
  get url (): string {
    return `${URI_PREFIX}/${this.params.companyUuid}/sso/domains/${this.params.domainId}`;
  }

  get method (): Method {
    return 'PUT';
  }

  get body (): Json {
    const p = this.params;
    return {
      description: p.description,
      notes: p.notes,
    };
  }
}

export class DeleteSsoDomainRequest extends ApiRequest<DeleteSsoDomainRequestParams> {
  get url (): string {
    return `${URI_PREFIX}/${this.params.companyUuid}/sso/domains/${this.params.domainId}`;
  }

  get method (): Method {
    return 'DELETE';
  }
}

export class DeleteSsoDomainsRequest extends ApiRequest<DeleteSsoDomainsRequestParams> {
  get url (): string {
    return `${URI_PREFIX}/${this.params.companyUuid}/sso/domains/delete`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      domains: this.params.domains,
    };
  }
}

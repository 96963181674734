/**
 * File: getFileRequest.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

import { Method } from '@core/api/apiRequest';
import ApiRequestDownloadFile from '@core/api/apiRequestDownloadFile';

export default class GetFileRequest extends ApiRequestDownloadFile {
  get method (): Method {
    return 'GET';
  }

  get url (): string {
    return `/account/api/v1/bula/${this.params.language}`;
  }
}

/**
 * File: subscriptionsRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import SubscriptionsPage from '../modules/subscriptions/list/subscriptionsPage.vue';
import SubscriptionDetailsPage from '../modules/subscriptions/details/subscriptionDetailsPage.vue';
import RenewOnlinePage from '../modules/subscriptions/renew/renewOnlinePage.vue';
import ConvertPdlPage from '../modules/subscriptions/convert-pdl/convertPdlPage.vue';
import { CONVERT_PDL_PAGE, RENEW_ONLINE_PAGE, SUBSCRIPTION_DETAILS_PAGE, SUBSCRIPTIONS_PAGE } from '@/routes/constants';

export default [
  {
    path: '/subscriptions',
    name: SUBSCRIPTIONS_PAGE,
    component: SubscriptionsPage,
    meta: {
      requiresAuth: true,
      title: 'Subscriptions',
    },
  },
  {
    path: '/subscriptions/:id',
    name: SUBSCRIPTION_DETAILS_PAGE,
    component: SubscriptionDetailsPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Subscriptions',
    },
  },
  {
    path: '/subscriptions/:id/renew',
    name: RENEW_ONLINE_PAGE,
    component: RenewOnlinePage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/subscriptions/:id/convert_pdl',
    name: CONVERT_PDL_PAGE,
    component: ConvertPdlPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    // This redirect is for PD application:
    //   old: https://license.myparallels.com/#/subscription/renew?subscription_uuid=59359d61585f4d53a5efbc8fc950825e
    //   new: https://my.myparallels.com/subscription/renew?subscription_uuid=59359d61585f4d53a5efbc8fc950825e
    path: '/subscription/renew',
    redirect: (route) => ({
      name: RENEW_ONLINE_PAGE,
      params: {
        id: route.query.subscription_uuid,
      },
      query: {},
    }),
  },
  {
    // This redirect is for PD Lite application:
    //   old: https://license.myparallels.com/#/subscription/convert-pdl
    //   new: https://my.myparallels.com/subscription/convert-pdl
    path: '/subscription/convert_pdl',
    redirect: (route) => ({
      name: CONVERT_PDL_PAGE,
      params: {
        id: route.query.subscription || 'null',
      },
      query: {},
    }),
  },
];



import Vue from 'vue';
import Session from '@/models/session';
import BannerNotification from '@/ui/bannerNotification/index.vue';
import md5 from 'blueimp-md5';

const KEY_NAME = 'invitation-notification-hidden';

export default Vue.extend({
  name: 'invitation-notification',
  components: {
    BannerNotification,
  },
  data () {
    return {
      hidden: true,
      hash: md5(this.$appData.session.token),
    };
  },
  computed: {
    session (): Session {
      return this.$appData.session;
    },
    show (): boolean {
      return this.featureAccessible && !this.hidden;
    },
    sameHash (): boolean {
      return this.hash === this.$localStorage.get(KEY_NAME);
    },
    featureAccessible (): boolean {
      return (
        this.session?.pendingInvitationsCount > 0 &&
        !this.sameHash &&
        this.session?.trusted
      );
    },
  },
  methods: {
    hide () {
      this.$localStorage.set(KEY_NAME, this.hash);
      this.hidden = true;
    },
    openInvitations () {
      this.$router.push({
        name: 'invitationsList',
      });
    },
  },
  watch: {
    'session.pendingInvitationsCount': {
      handler (value) {
        this.hidden = !value || this.sameHash;
      },
      immediate: true,
    },
  },
});


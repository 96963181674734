/**
 * File: inviteInfoRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export type InviteInfoRequestResponse = {
  companyName: string;
  email: string;
  name: string;
  exists: boolean;
}

// https://wiki.prls.net/x/c9URBQ
export default class InviteInfoRequest extends ApiRequest<undefined, InviteInfoRequestResponse> {
  readonly code: string;

  constructor (code: string) {
    super();
    this.code = code;
  }

  get url () {
    return '/account/api/v1/ac/' + this.code + '/info';
  }

  async getJson (response: Response): Promise<InviteInfoRequestResponse> {
    const data = (await super.getJson(response) as any).info;
    return {
      companyName: data.company_name,
      email: data.email,
      name: data.name,
      exists: data.exists,
    };
  }
}

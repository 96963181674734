/**
 * File: configurationProfileModal.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import componentMixIn from '@/modules/componentMixIn';
import DynamicForm from '@/ui/dynamicForm';
import PdConfigurationProfileTemplateRequest from '@/api/pdConfigurationProfileTemplateRequest';
import PdCreateUpdateConfigurationProfileRequest from '@/api/pdCreateUpdateConfigurationProfileRequest';
import PdDeleteConfigurationProfileRequest from '@/api/pdDeleteConfigurationProfileRequest';
import { FEATURE_CONFIGURATION_PROFILE_FOR_M1 } from '@core/constants/features';

export default {
  name: 'configuration-profile-modal',
  mixins: [componentMixIn],
  components: { DynamicForm },
  data () {
    return {
      loading: true,
      configurationProfile: null,
      template: null,
      templateVersion: null,
      name: 'New Configuration Profile',
      values: {},
      enabled: {},
      tab: null,
      tabs: [],
      variables: {
        $BIZ_ACCOUNT_NAME: this.$appData.session.businessDomainName,
      },
    };
  },
  computed: {
    showDeleteButton () {
      return this.configurationProfile && !this.configurationProfile.hostsCnt && !this.configurationProfile.subscriptionsCnt;
    },
  },
  methods: {
    show (configurationProfile = null) {
      if (configurationProfile) {
        this.name = configurationProfile.name;
        this.configurationProfile = configurationProfile;
        this.templateVersion = configurationProfile.templateVersion;
        this.$refs.editableHeader.editable = false;
        Object.assign(this.values, configurationProfile.values); // to avoid updating source
      } else {
        this.$refs.editableHeader.editable = true;
      }

      this.loadTemplate(this.templateVersion).then((template) => {
        this.templateVersion = template.version;
        this.template = template;

        this.initValuesAndTabs();
      });

      this.$refs.modal.show();
    },

    initValuesAndTabs () {
      const values = {};
      Object.assign(values, this.values);

      this.template.items.forEach((item) => {
        if (!this.$appData.session.isFeatureAccessible(FEATURE_CONFIGURATION_PROFILE_FOR_M1) && item.key_name === 'vm_arm') {
          return;
        }

        let enabled = true;

        if (!this.values.hasOwnProperty(item.key_name)) {
          this.values[item.key_name] = {};
          enabled = false;
        }

        this.tabs.push({
          schema: item.schema,
          key_name: item.key_name,
          values: this.values[item.key_name],
          enabled: enabled,
        });

        for (const [propName, propAttrs] of Object.entries(item.schema.properties)) {
          if (!this.values[item.key_name].hasOwnProperty(propName) && propAttrs.hasOwnProperty('default')) {
            this.values[item.key_name][propName] = this.replacePlaceholders(propAttrs.default);
          }
        }
      });

      this.tab = this.tabs[0];
    },

    replacePlaceholders (value) {
      if (typeof value === 'boolean') {
        return value;
      }

      let newValue = '';
      for (const [holderName, holderValue] of Object.entries(this.variables)) {
        newValue = String(value).replace(holderName, holderValue);
      }

      return newValue;
    },

    loadTemplate (templateVersion = null) {
      let requestData = {};
      if (templateVersion) {
        requestData = { version: templateVersion };
      }
      return this.authorizedCall(new PdConfigurationProfileTemplateRequest(requestData)).then((data) => {
        if (templateVersion) {
          return data;
        }
        if (data) {
          // get last template
          return data[data.length - 1];
        }
        return {};
      });
    },

    validateForms () {
      let valid = true;
      for (const form of this.$refs.forms) {
        if (!form.disabled) {
          form.$v.$touch();
          valid = valid && !form.$v.$invalid;
        }
      }
      return valid;
    },

    duplicateConfigurationProfile () {
      this.name += ' Duplicate';
      this.configurationProfile = null;

      this.saveConfigurationProfile();
    },

    saveConfigurationProfile () {
      if (!this.validateForms()) {
        return;
      }

      if (this.$refs.editableHeader) {
        this.$refs.editableHeader.confirm();
      }

      // Waiting for moment when name is updated, then save profile
      this.$nextTick(() => {
        const data = {
          name: this.name,
          values: {},
        };

        this.tabs.forEach((tab) => {
          if (tab.enabled) {
            data.values[tab.key_name] = this.values[tab.key_name];
          }
        });

        if (this.configurationProfile) {
          data.id = this.configurationProfile.id;
        } else {
          data.templateVersion = this.templateVersion;
        }

        const request = new PdCreateUpdateConfigurationProfileRequest(data);

        return this.authorizedCall(request).then(() => {
          if (data.id) {
            this.showToast($t('Configuration profile "{name}" updated.', { name: this.name }));
            this.$emit('updated', data);
          } else {
            this.$emit('created', data);
            this.showToast($t('Configuration profile "{name}" created.', { name: this.name }));
          }
          this.hide();
        }).catch(() => {
          this.$toast.show({
            tag: 'connectionError', // do not duplicate the same toasts
            color: 'red',
            text: $t('There was a problem completing this request.'),
          });
        });
      });
    },

    deleteConfigurationProfile () {
      this.authorizedCall(new PdDeleteConfigurationProfileRequest({ id: this.configurationProfile.id })).then(() => {
        this.showToast($t('Configuration profile "{name}" deleted.', { name: this.name }));
        this.$emit('deleted');
      });
    },

    clear () {
      this.configurationProfile = null;
      this.template = null;
      this.name = 'New Configuration Profile';
      this.tab = null;
      this.tabs = [];
      this.values = {};
      this.loading = true;
    },

    hide () {
      this.$refs.modal.hide();
      this.clear();
    },
  },
};

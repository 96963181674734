/**
 * File: adjustItemsWidthToParentContainer.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

export const adjustItemsWidthToParentContainer = (container, itemSelector) => {
  container.style.opacity = 0;
  const items = Array.from(container.querySelectorAll(itemSelector));
  for (let i = 0; i < items.length; i++) {
    items[i].style.overflow = 'visible';
    items[i].style.width = 'auto';
  }
  const targetItems = items.slice(0, items.length - 1);
  const totalWidth = targetItems.reduce((acc, item) => acc + item.clientWidth, 0);
  const lastItemWidth = items[items.length - 1].clientWidth;
  const containerWidth = container.clientWidth;
  if ((totalWidth + lastItemWidth) > containerWidth) {
    const fittedWidth = (containerWidth - lastItemWidth) / targetItems.length;
    for (let i = 0; i < targetItems.length; i++) {
      const item = targetItems[i];
      const elementWidth = item.offsetWidth;
      if (elementWidth > fittedWidth) {
        item.style.width = fittedWidth + 'px';
        item.style.overflow = 'hidden';
      }
    }
  }
  container.style.opacity = 1;
};

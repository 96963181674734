

import Vue from 'vue';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import CsvSaver from '@/ui/csvSaver.vue';
import TableView from '@/ui/tableView.vue';
import GetRedeemCodesRequest from '@/api/getRedeemCodesRequest';
import CompanyUsersRequest from '@/api/companyUsersRequest';
import { RedeemCode, REDEEMED_STATUS } from '@/models/oemLicenses';
import GenerateRedeemCodeModal
  from '@/modules/home/dashboard/productCards/productCard/productCardPDFC/generateRedeemCodeModal.vue';
import CompanyUser from '@/models/companyUser';

import OemLicensesMixin from './oemLicensesMixin';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

const CODES_REQUEST = 'codesRequest';
const USERS_REQUEST = 'usersRequest';

export default Vue.extend({
  name: 'redemption-codes',
  mixins: [OemLicensesMixin, BreadcrumbsMixin],
  components: {
    CsvSaver,
    GenerateRedeemCodeModal,
    TableView,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },
  data () {
    return {
      codes: [] as RedeemCode[],
      users: [] as CompanyUser[],
      searchFields: [
        'code',
        'organization',
        'email',
        'createdBy',
        'rationale',
      ],
    };
  },
  computed: {
    columns (): { options: TableViewColumn[] } {
      return {
        options: [
          { text: this.$t('Redemption Code'), value: 'code' },
          { text: this.$t('Customer Organization'), value: 'organization' },
          { text: this.$t('Customer Email'), value: 'email', hideOnInit: true },
          { text: this.$t('Date of Creation'), value: 'createdAt' },
          { text: this.$t('Created By'), value: 'createdBy', hideOnInit: true },
          { text: this.$t('Created Using'), value: 'source', hideOnInit: true },
          { text: this.$t('Qty'), value: 'quantity' },
          { text: this.$t('Type of Licenses'), value: 'type', hideOnInit: true },
          { text: this.$t('Rationale'), value: 'rationale' },
          { text: this.$t('Status'), value: 'status', hideOnInit: true },
          { text: this.$t('Date of Redemption'), value: 'usedAt', hideOnInit: true },
          { text: this.$t('Expected Date of Expiration'), value: 'expiredAt', hideOnInit: true },
        ],
      };
    },
    actions (): TableViewActions {
      return {
        id: 'code',
        options: [{ value: 'exportCsv', text: this.$t('Save List to CSV File ({count})') }],
      };
    },
  },
  methods: {
    load () {
      const request = new CombinedApiRequest();

      this.loading = true;

      request.addRequest(CODES_REQUEST, new GetRedeemCodesRequest());
      request.addRequest(USERS_REQUEST, new CompanyUsersRequest({}, this.$appData.session.businessDomainId));

      this.$api.authorizedCall(request).then(() => {
        this.users = (request.getRequest(USERS_REQUEST) as unknown as CompanyUsersRequest).getUsers();
        this.codes = (request.getRequest(CODES_REQUEST) as unknown as GetRedeemCodesRequest).data.redeemCodes.map((code) => {
          const user = this.users.find((user) => user.uuid === code.operatorId);
          code.createdBy = (user !== undefined ? user.email : '?');
          return code;
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    getClassName (code: RedeemCode): string {
      return code.status === REDEEMED_STATUS ? 'text-danger' : 'text-success';
    },
    exportCsv (codes: RedeemCode[]) {
      // @ts-ignore  FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.csv.save({
        columns: this.columns.options,
        rows: codes,
      });
    },
    openRedeemCodeGenerator () {
      this.$modal.show(GenerateRedeemCodeModal);
    },
  },
});


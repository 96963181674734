/**
 * File: renewSubscriptionModal.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { copyExcluding } from '@core/common/utils';
import ComponentMixIn from '@/modules/componentMixIn';
import ContractRequest from '@/api/contractRequest';

export default {
  name: 'renew-subscription-modal',

  mixins: [ComponentMixIn],

  props: {
    subscription: {
      type: Object,
    },
  },

  data () {
    return {
      loading: false,
      contract: { salesRepresentative: {} },
    };
  },

  methods: {
    switchTab (params) {
      this.tab = params.tab;
    },

    show () {
      this.load();
      this.$refs.modal.show();
      this.$emit('show');
    },

    load () {
      const request = new ContractRequest({
        uuid: this.subscription.uuid,
        service: this.subscription.targetServiceName,
      });

      return this.authorizedCall(request).then(data => {
        this.contract = data;
        this.contract.salesRepresentative.mailto = `mailto:${this.contract.salesRepresentative.email}`;
      });
    },

    onHide () {
      this.$emit('hide');
      this.$router.replace({ query: copyExcluding(this.$route.query, ['tab']) });
    },
  },
};

<copyright>
File: decimalTextbox.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import { Textbox } from '@parallels/ui-kit';

const INPUT_TYPE_INSERT_TEXT = 'insertText';
const INPUT_TYPE_PASTE = 'insertFromPaste';

export default {
  name: 'decimal-textbox',
  inheritAttrs: false,
  components: {
    Textbox,
  },
  data () {
    return {
      value: this.$attrs.value,
    };
  },
  methods: {
    input (value, event) {
      if (event.inputType === INPUT_TYPE_INSERT_TEXT || event.inputType === INPUT_TYPE_PASTE) {
        // Clear everything except decimal value on paste
        if (event.inputType === INPUT_TYPE_PASTE) {
          value = parseInt(value);
        }
        // TODO: add property to allow/deny negatives
        value = Math.abs(value);
        // Ignore commas and dots
        if (event.data === '.' || event.data === ',') {
          event.target.value = '';
        } else {
          this.value = value;
        }

        event.target.value = this.value;
        this.$emit('input', this.value);
      } else {
        this.value = value;
        this.$emit('input', value);
      }
    },
  },
  watch: {
    '$attrs.value': function (value) {
      this.value = value;
    },
  },
};

</script>

<template lang="pug">

textbox(v-on="{ ...$listeners, input}", v-bind="$attrs", type="number", :value="value", :min="0")
  //- Pass slots from parent component to table view
  slot(v-for="(_, name) in $slots", :name="name", :slot="name")
  template(v-for="(_, name) in $scopedSlots", :slot="name", slot-scope="slotData")
    slot(:name="name", v-bind="slotData")

</template>

/**
 * File: companyProfileRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class CompanyProfileRequest extends ApiRequest {
  constructor (params, companyUuid) {
    super(params);
    this.company_uuid = companyUuid;
  }

  get url () {
    return '/account/api/v1/ba/' + this.company_uuid + '/';
  }
}

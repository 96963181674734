<copyright>
File: csvSaver.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import { CsvWriter } from '@core/common/csv';

export default {
  name: 'csv-saver',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      rows: '',
    };
  },
  computed: {
    href () {
      // '%EF%BB%BF' is BOM mark that allows Excel to more reliably guess a file encoding
      return 'data:text/plain;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.rows);
    },
  },
  methods: {
    save ({ columns, rows, quotes }) {
      quotes = quotes === undefined || quotes;
      const csv = new CsvWriter(columns, quotes);

      rows = rows.map((row) => {
        return columns.reduce((result, column) => {
          const name = column.value;
          const value = row[name];

          // Format dates with current locale
          result[name] = value instanceof Date ? this.formatDateTime(value) : value;
          return result;
        }, {});
      });

      this.rows = csv.getContent(rows);

      // Fix for csv saving in Edge and IE
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(new Blob([this.rows]), this.name);
      } else {
        this.$nextTick(() => {
          this.$el.click();
        });
      }
    },
  },
};

</script>

<template lang="pug">

a.hidden(:download="name", :href="href")

</template>

/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

export enum OperationStatus {
  PREPROCESSING = 'pre',
  PROCESSING = 'processing',
  READY = 'ready'
}

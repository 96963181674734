/**
 * File: rtTicketsRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest, { RtRequestParams } from './rtRequest';
import Ticket, { TicketStatus } from '@/models/ticket';
import { Method } from '@core/api/apiRequest';
import { getMergedIn, getStatus } from '@/api/rt/ticketStatus';
import { snakeToCamel } from '@/apiv2/apiHelpers';

interface RtTicketsRequestParams extends RtRequestParams {
  amountOnly?: boolean;
  q?: string;
  status?: TicketStatus;
  order?: string;
  limit?: number;
  offset?: string;
  dir?: string;
  queueId?: number;
}

interface RtTicketsRequestResponse {
  amountCallScheduled: number;
  amountInEngineering: number;
  amountWaitingMyResponse: number;
  amount: number;
  amountAll: number;
  amountClosed: number;
  amountInProgress: number;
  tickets: Ticket[];
}

export default class RtTicketsRequest extends RtRequest<RtTicketsRequestParams, RtTicketsRequestResponse> {
  get optionParameters () {
    return ['amountOnly', 'q', 'status', 'limit', 'offset', 'order', 'dir', 'queueId'];
  }

  buildQueryString (qp) {
    return super.buildQueryString(qp);
  }

  get uri (): string {
    const uri = '/tickets';

    const qp = {};

    this.optionParameters.forEach((p) => {
      if (this.params.hasOwnProperty(p)) {
        qp[p] = this.params[p];
      }
    });

    const qs = this.buildQueryString(qp);

    return qs ? uri + qs : uri;
  }

  get method (): Method {
    return 'GET';
  }

  async getJson (args): Promise<RtTicketsRequestResponse> {
    const result = await super.getJson(args);
    if (result.tickets) {
      for (const ticket of result.tickets) {
        const status = ticket.fields.status;
        ticket.fields.mergedIn = getMergedIn(status);
        ticket.fields.status = getStatus(status);
      }
    }
    return snakeToCamel(result);
  }
}

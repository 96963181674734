

import { defineComponent } from 'vue';
import { mixin as clickaway } from 'vue-clickaway';
import AddKeyModal from '@/modules/addKey/addKeyModal.vue';
import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import { ADD_KEY_PAGE, DASHBOARD_PAGE, GENERAL_PAGE, GET_SUPPORT_PAGE, HOME_PAGE, PERSONAL_PROFILE_SCOPE, SECURITY_PAGE } from '@/routes/constants';

const isSupportApp = process.env.VUE_APP_NAME === 'support';

export default defineComponent({
  name: 'main-menu',
  props: {
    session: Object,
    products: Object,
  },
  components: {
    AddKeyModal,
  },
  data () {
    return {
      PERSONAL_PROFILE_SCOPE,
      GENERAL_PAGE,
      SECURITY_PAGE,
    };
  },
  created () {
    this.$bus.$on('showAddKeyModal', this.showAddKeyModal);
  },
  destroyed () {
    this.$bus.$off('showAddKeyModal', this.showAddKeyModal);
  },
  methods: {
    showAddKeyModal (): void {
      if (isSupportApp) {
        this.$trackEvent({
          category: 'Support',
          name: 'Register click in navbar',
          source: document.title,
        });
      }
      if (!this.isAddKeyPage) {
        this.$modal.show('addKeyModal');
      }
    },
    logout (): void {
      this.$api.logout();
    },
    openSupport (): void {
      this.$trackEvent({
        category: 'Support',
        name: 'Support button click in navbar',
        source: document.title,
      });
      this.$router.push({
        name: GET_SUPPORT_PAGE,
      });
    },
    openHome (): void {
      if (isSupportApp) {
        this.$trackEvent({
          category: 'Support',
          name: 'Home click in navbar',
          source: document.title,
        });
      }
      this.$router.push({
        name: HOME_PAGE,
      });
    },
    openDashboard (): void {
      if (isSupportApp) {
        this.$trackEvent({
          category: 'Support',
          name: 'Dashboard click in navbar',
          source: document.title,
        });
      }
      this.$router.push({
        name: DASHBOARD_PAGE,
      });
    },
  },
  computed: {
    isAddKeyPage (): boolean {
      return this.$route.name === ADD_KEY_PAGE;
    },
    isSupportPage (): boolean {
      return this.$route.name === GET_SUPPORT_PAGE;
    },
    isDashboardPage (): boolean {
      return this.$route.name === DASHBOARD_PAGE;
    },
    isHomePage (): boolean {
      return this.$route.name === HOME_PAGE;
    },
    hasProducts (): boolean {
      return this.products.personal.length || this.products.business.length || this.$appData.session.isPdfcPurchaseFeatureAccessible;
    },
    hasRegisterKey (): boolean {
      return this.hasProducts || process.env.VUE_APP_NAME === 'support';
    },
    hasSupport (): boolean {
      return this.session && this.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL);
    },
    hasInvitations (): boolean {
      return !!this.session.pendingInvitationsCount;
    },
    isSso (): boolean {
      return this.$appData.session.sso;
    },
    smallFontSize (): boolean {
      return ['ru', 'pt'].includes(this.session.language);
    },
  },
  mixins: [clickaway],
});


/**
 * File: authenticatorApp.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */
import CoreModel from '@core/common/model';

export enum MFA_AUTH_MECHANISM {
  TOTP = 1,
  HOTP = 2
}

export default class AuthenticatorApp extends CoreModel {
  outerId: string;
  name: string;
  authMechanism: MFA_AUTH_MECHANISM;
  createdAt: Date;
  updatedAt: Date;
}

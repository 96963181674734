/**
 * File: generateRedeemCodeRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import { camelize, snakeize } from '@core/common/utils';

export default class GenerateRedeemCodeRequest extends ApiRequest {
  constructor (params) {
    super(params);
    this.params.numberOfLicenses = Number(this.params.numberOfLicenses);
  }

  get url () {
    return '/license/api/v1/redeem_codes';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return snakeize(this.params);
  }

  onFetchSuccess (data) {
    return camelize(data);
  }
}

/**
 * File: postSubscriptionTrait.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { LICENSE_SERVICE } from '@core/constants/services';

interface ISubscriptionTraitParams {
  'subscription_uuid': string;
  'key_name': string;
  value: string;
}

export default class PostSubscriptionTrait extends ApiRequest<ISubscriptionTraitParams> {
  get url (): string {
    return `/${LICENSE_SERVICE}/api/v1/subscription_trait?subscription_uuid=${this.params.subscription_uuid}`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      key_name: this.params.key_name,
      value: this.params.value,
    };
  }
}

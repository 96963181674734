<copyright>
File: general.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
div
  list
    list-item(labelWidth="6")
      .line-height-3x(slot="label")
        template(v-if="server.isActivated")
          formatter(:text="$t('Activated: {product}')")
            template(slot="product")
              span.text-success {{ activatedProductNames }}
        .text-danger(v-else) {{ $t('Deactivated') }}
      template(slot="value")
        btn(
          v-if="server.isActivated",
          size="small",
          color="red-stroke",
          :disabled="deactivating",
          @click.native="$emit('deactivateClick')",
          :data-name="$name('button-deactivate')"
        ).pull-right {{ $t('Deactivate Site') }}
    list-item
      template(slot="label") {{ $t('Site Code') }}
      template(slot="value") {{ server.code }}
    list-item
      template(slot="label") {{ $t('App Version') }}
      template(slot="value") {{ server.appVersion }}
    list-item
      template(slot="label") {{ $t('Last Report Date') }}
      template(slot="value") {{ formatDate(server.lastReportDate) }}
</template>
<script>

export default {
  name: 'details-modal-general',

  props: {
    server: {
      type: Object,
    },
    form: {
      type: Object,
      default () {
        return {
          notes: '',
        };
      },
    },
    deactivating: Boolean,
  },

  computed: {
    activatedProductNames () {
      const products = [];
      if (this.server.macSubscription) {
        products.push('Mac Management');
      }
      if (this.server.mdmSubscription) {
        products.push('MDM');
      }
      return products.join(', ');
    },
  },
};
</script>

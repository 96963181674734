/**
 * File: pdBusinessSubscriptionInviteRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Method } from '@core/api/apiRequest';

interface PdBusinessSubscriptionInviteRequestParams {
  payload: {
    emails: string;
    locale: string;
    ttl: string;
  };
  subscriptionUuid: string;
  generateMode: boolean;
}

interface PdBusinessSubscriptionInviteResponseParams {
  invitations: Invitation[];
}

interface Invitation {
  email: string;
  'action_code': string;
  'download_link': string;
}

export default class PdBusinessSubscriptionInviteRequest extends ApiRequest<PdBusinessSubscriptionInviteRequestParams, PdBusinessSubscriptionInviteResponseParams> {
  /**
   * @param {Object} params
   * @param {Object} params.payload
   * @param {String} params.subscriptionUuid
   * @param {Boolean} params.generateMode
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get url (): string {
    let url = '/desktop/api/v1/ba/subscriptions/' + this.params.subscriptionUuid + '/invite';

    if (this.params.generateMode) {
      url += '/generate';
    }

    return url;
  }

  get method (): Method {
    return 'POST';
  }

  get body () {
    return Object.assign({}, this.params.payload);
  }

  get errors () {
    return {
      'no more seats': $t('Insufficient number of licenses available.'),
    };
  }
}

/**
 * File: resourceUsageNotificationUpdateRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { copyExcluding, snakeize } from '@core/common/utils';
import { WeekDay } from '@core/common/datetime';

export enum ScheduleRuleType {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  EndOfPeriod = 3
}

export type ScheduleRule = {
  type: ScheduleRuleType;
  value?: WeekDay | number;
}

export enum ThresholdRuleType {
  Any = 0,
  Users = 1,
  Percent = 2
}

export type ThresholdRule = {
  type: ThresholdRuleType;
  value?: number;
}

export type ResourceUsageNotificationUpdateRequestParams = {
  subscriptionUuid: string;
  isActive?: boolean;
  threshold?: ThresholdRule;
  schedule?: ScheduleRule;
  locale?: string;
  receivers?: string[];
  sendSooner?: boolean;
};

export default class ResourceUsageNotificationUpdateRequest extends ApiRequest<ResourceUsageNotificationUpdateRequestParams> {
  get method (): Method {
    return 'PUT';
  }

  get url (): string {
    return `/ras/webapp/subscription/usage_notification/${this.params.subscriptionUuid}`;
  }

  get body (): Json {
    return snakeize(copyExcluding(this.params, ['subscriptionUuid']));
  }

  async load () {
    const result = await super.load();
    this.dropFullCache();
    return result;
  }
}

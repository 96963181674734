/**
 * File: pdSubscription.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 * */

import { prodKeyToName } from '@core/constants/subscriptions';
import CoreModel from '@core/common/model';

export default class PdSubscription extends CoreModel {
  constructor (data, parent) {
    super(data);
    this.parent = parent;
  }

  get isActive () {
    // ended = suspended || expired
    return !this.ended && !this.isBlacklisted;
  }

  get parentUuid () {
    return this.parent && this.parent.uuid;
  }

  get keyFullName () {
    if (this.parent) {
      return `${this.parent.keyFullName} / ${this.name}`;
    } else {
      return this.name || $t(prodKeyToName(this.productKeyName));
    }
  }
}

/**
 * File: createTicketMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import Vue from 'vue';

import {
  TICKET_FIELD_CONTACT_SOURCE,
  TICKET_FIELD_LANGUAGE,
  TICKET_FIELD_SUPPORT_TYPE,
  TICKET_FIELD_TIMEZONE
} from '@/api/rt/constants';
import RtCreateTicketRequest from '@/api/rt/rtCreateTicketRequest';
import RtUpdateTicketRequest, { RtUpdateTicketRequestParams } from '@/api/rt/rtUpdateTicketRequest';
import { SupportType } from '@/modules/support/constants';
import { RequestOption, Severity } from '@/models/ticket';

const SESSION_STORAGE_KEY = 'rtCurrentTicketId';

const LANGUAGE_BY_LOCALE = {
  en_us: 'English',
  de_de: 'German',
  fr_fr: 'French',
  ru_ru: 'Russian',
  es_es: 'Spanish',
  ja_jp: 'Japanese',
  zh_cn: 'Chinese',
  zh_tw: 'Chinese',
  ko_kr: 'Korean',
  it_it: 'Italian',
  pt_br: 'Portuguese',
  // '': 'Polish',
  // '': 'Dutch',
};

export enum SupportOptions {
  Phone = 'phone',
  Chat = 'chat',
  Email = 'email',
  Skype = 'skype'
}

export interface TicketData {
  product: string;
  [TICKET_FIELD_SUPPORT_TYPE]: SupportType;
  'case_origin': RequestOption;
  severity: Severity;
  country: string;
  [TICKET_FIELD_TIMEZONE]: string;
  [TICKET_FIELD_CONTACT_SOURCE]: string;
  summary: string;
  description: string;
  'subscription_uuid': string;
}

export default Vue.extend({
  name: 'create-ticket-mixin',

  methods: {
    getCurrentLanguage (): string {
      if (this.$appData.session && this.$appData.session.locale) {
        const language = LANGUAGE_BY_LOCALE[this.$appData.session.locale.toLowerCase()];
        if (language) {
          return `Language/${language}`;
        }
        return null;
      }
      return null;
    },

    /**
     * If ticket has not been created yet in this session, it creates a new one;
     * otherwise updates already created ticket.
     *
     * @return ticket id
     */
    async createOrUpdateTicket (form: any): Promise<number> {
      let ticketRequest;
      let ticketId = +sessionStorage.getItem(SESSION_STORAGE_KEY);
      const language = this.getCurrentLanguage();

      if (ticketId) {
        const params: RtUpdateTicketRequestParams = { ticketId };

        // update fields only if user entered summary&description (if possible only in "email" mode);
        // otherwise previously created summary&description will be rewritten to useless hardcoded strings
        if (form.case_origin === SupportOptions.Email) {
          params.case_origin = form.case_origin;
          if (form.description) {
            params.description = form.description;
          }
        }

        if (form.case_origin === SupportOptions.Chat && form.description) {
          params.description = form.description;
        }

        ['summary', 'severity', 'share'].forEach((name) => {
          const value = form[name];
          if (value !== undefined) {
            params[name] = value;
          }
        });

        if (language) {
          params[TICKET_FIELD_LANGUAGE] = language;
        }

        ticketRequest = new RtUpdateTicketRequest(params);
      } else {
        if (language) {
          form[TICKET_FIELD_LANGUAGE] = language;
        }

        ticketRequest = new RtCreateTicketRequest({ form });
      }

      const data = ticketRequest && await this.$api.callRt(ticketRequest);
      if (data && data.id) {
        ticketId = data.id;
        ticketId && sessionStorage.setItem(SESSION_STORAGE_KEY, ticketId.toString());
      }
      return ticketId;
    },

    resetCreatedTicketCache () {
      sessionStorage.removeItem(SESSION_STORAGE_KEY);
    },
  },
});

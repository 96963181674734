/**
 * File: rasDeactivateHostsRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import RasPrepaidFarmsRequest from '@/api/rasPrepaidFarmsRequest';
import RasFarmsCountersRequest from '@/api/rasFarmsCountersRequest';

export default class RasDeactivateHostsRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {Array<String>} params.hwIds
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/ras/api/v1/hosts/deactivate';
  }

  get body () {
    return { hw_ids: this.params.hwIds };
  }

  load () {
    return super.load()
      .then((data) => {
        new RasPrepaidFarmsRequest({}).dropCache();
        new RasFarmsCountersRequest({}).dropCache();
        return data;
      });
  }
}


import Vue from 'vue';

import { PRODUCT_KEY_NAME_RAS, PRODUCT_KEY_NAME_RAS_AZMP, PRODUCT_NAME_RAS_AZMP } from '@core/constants/subscriptions';

import Subscription, { STATUS_ACTIVE } from '@/models/subscription';

import { support as supportLinks } from '@core/constants/links';

import SubscriptionsRequest from '@/api/subscriptionsRequest';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductPageMixIn from '@/modules/productPageMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import DownloadsContent from '@/modules/downloads/downloadsContent.vue';
import {
  RAS_AZMP_DOWNLOADS_PAGE,
  RAS_AZMP_SUBPAGE_SUBSCRIPTION_DETAILS,
  RAS_AZMP_SUBPAGE_SUBSCRIPTIONS,
  GET_SUPPORT_PAGE
} from '@/routes/constants';
import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'ras-azmp-page',

  props: ['content'],

  mixins: [
    ComponentMixIn,
    ProductPageMixIn,
    SearchFilterMixin,
    BreadcrumbsMixin,
  ],

  components: {
    SubscriptionNotification,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    DownloadsContent,
    AddLicensesModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  data () {
    return {
      supportLinks,
      subscriptions: [],
      displayNotification: true,
    };
  },

  created () {
    this.load();
  },

  methods: {
    load () {
      const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS });
      this.authorizedCall(request)
        .then(() => {
          this.subscriptions = request.getSubscriptions().filter((s: Subscription) => s.hasProduct(PRODUCT_KEY_NAME_RAS_AZMP));
        });
    },
  },

  computed: {
    productName (): string {
      return PRODUCT_NAME_RAS_AZMP;
    },
    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },
    isSubscriptionsPage (): boolean {
      return this.content === RAS_AZMP_SUBPAGE_SUBSCRIPTIONS;
    },
    isDownloadsPage (): boolean {
      return this.content === RAS_AZMP_DOWNLOADS_PAGE;
    },
    isSubscriptionDetailsPage (): boolean {
      return this.content === RAS_AZMP_SUBPAGE_SUBSCRIPTION_DETAILS;
    },
    subpages (): MenuItem[] {
      const links = [];
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
      const downloadLink = this.getDownloadRoute();
      links.push(
        {
          text: this.$t('Download'),
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
          link: downloadLink,
        },
        {
          text: this.$t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
          link: { name: RAS_AZMP_SUBPAGE_SUBSCRIPTIONS, query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE } },
        },
        {
          text: this.$t('Support'),
          click: () => {
            if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
              const params = {
                category: 'Support',
                name: 'Support button click in menu',
              };
              this.$collectGaStats({ ...params, source: document.title });
              this.$collectStats({ ...params, source: PRODUCT_KEY_NAME_RAS_AZMP });
              this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_RAS } });
            } else {
              window.location.href = this.supportLinks[PRODUCT_KEY_NAME_RAS];
            }
          },
        }
      );
      return links;
    },
  },
});

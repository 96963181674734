<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import LanguageSwitcher from '@/ui/languageSwitcher';
import { HTTP_CODES } from '@core/constants/http';
import Intro from './intro';
import Reminder from './reminder';
import { LANGUAGES } from '@core/constants/langs';
import AcceptConsentRequest from '@/api/acceptConsentRequest';
import consent from '@/data/consent';
import { scrollTo } from '@core/common/animations';

export default {
  name: 'user-consent',
  components: {
    Intro,
    Reminder,
    LanguageSwitcher,
  },
  props: {
    appData: {
      type: Object,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    consent: {
      type: Object,
      default () {
        return {};
      },
    },
    useAuthorizedCall: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    const app = this.$route.query.app;
    const newAccount = this.$route.query.new_account === '1' || this.consent.newAccount;
    const next = this.$route.query.next || this.$route.path;
    const id = this.$route.query.consent_id || this.consent.id;
    const queryParam = next && (next.match(/\?/) ? '&' : '?');
    const webview = app && app !== 'webapp';
    const reminderOnly = this.$route.path === '/data_reminder';

    let acceptUrl;
    let declineUrl;
    let language = (this.$route.query.locale || '').toLowerCase().slice(0, 2);

    if (language) {
      language = LANGUAGES.find((l) => !l.value.indexOf(language));
      language = language && language.value;
    }

    if (webview) {
      acceptUrl = `${next}${queryParam}success=true`;
      declineUrl = `${next}${queryParam}success=false`;
    }

    return {
      reminderOnly,
      page: reminderOnly ? 'reminder' : 'intro',
      app,
      newAccount,
      next: this.$route.query.next,
      webview,
      hasScroll: false,
      id,
      acceptUrl,
      declineUrl,
      loading: false,
      language,
      platform: this.$route.query.platform,
      headerHeight: undefined,
    };
  },
  mounted () {
    if (this.webview) {
      this.$root.$children[0].mode = 'simple';
      this.$nextTick(this.checkScroll);
    }
  },
  computed: {
    // Show page after language is loaded
    ready () {
      return !this.language || this.language === this.$i18n.locale;
    },
    tinyWidth () {
      return this.app === 'toolbox';
    },
    paddingTop () {
      if (this.headerHeight) {
        return `${this.headerHeight + 12}px`;
      } else {
        return '';
      }
    },
  },
  methods: {
    switchPage (name) {
      this.page = name;
      this.$nextTick(this.checkScroll);
    },
    switchLanguage (params) {
      this.language = params.locale;
      this.$nextTick(this.checkScroll);
    },
    accept () {
      this.addQueryParams({
        button: 'accept',
      });

      this.loading = true;

      // Consent on registration page
      // We need to get consent_id and accept it
      if (this.embedded && !this.id) {
        this.$api.getConsent({
          locale: this.$i18n.locale.toLowerCase(),
        }, this.useAuthorizedCall).then((data) => {
          if (data.consent_required) {
            this.id = data.consent_id;
            this.accept();
          } else {
            this.$emit('accept', {
              required: false,
            });
          }
        });
        return;
      }

      // In case if consent is not required and user opened this page directly
      if (!this.id) {
        this.openHomePage();
        return;
      }

      const acceptConsentRequest = new AcceptConsentRequest({
        id: this.id,
        redirect_url: this.next,
        consent_was_completed: true,
      });

      this.$api.call(acceptConsentRequest).then(() => {
        consent.init({
          consent_required: false,
        });

        // Scroll top if consent accepted
        scrollTo({
          to: document.documentElement,
          duration: 250,
        });

        if (this.embedded) {
          this.$emit('accept', {
            id: this.id,
          });
        } else if (this.acceptUrl) {
          location.href = this.acceptUrl;
        } else {
          this.openHomePage();
        }
      }).catch((error) => {
        this.handleConsentError(error);
      });
    },
    decline () {
      this.addQueryParams({
        button: 'cancel',
      });

      this.loading = true;
      const acceptConsentRequest = new AcceptConsentRequest({
        id: this.id,
        redirect_url: this.next,
      });

      this.$api.call(acceptConsentRequest).then(() => {
        if (this.declineUrl) {
          location.href = this.declineUrl;
        }
      }).catch((error) => {
        this.handleConsentError(error, {
          ignoreNetworkError: true,
        });
      });
    },
    handleConsentError (error, params = {}) {
      if (!error.response) {
        if (!params.ignoreNetworkError) {
          this.$toast.show({
            text: $t('Cannot connect to the server. Please check your network connection and try again.'),
            color: 'red',
          });
        } else if (this.declineUrl) {
          location.href = this.declineUrl;
        }
        this.loading = false;
      } else if (error.response.status === HTTP_CODES.CONFLICT && this.acceptUrl) {
        location.href = this.acceptUrl;
      } else if (error.response.status === HTTP_CODES.NOT_FOUND && this.declineUrl) {
        location.href = this.declineUrl;
      } else if (this.embedded && this.id) {
        this.$emit('accept', {
          id: this.id,
        });
      } else {
        this.openHomePage();
      }
    },
    openHomePage () {
      this.$api.refreshToken(true).then(() => {
        this.$router.replace({
          name: 'index',
        });
      });
    },
    checkScroll () {
      const el = document.documentElement;
      this.hasScroll = el.scrollHeight > el.clientHeight;
    },
    addQueryParams (params) {
      if (this.webview) {
        this.$router.push({
          query: {
            ...this.$route.query,
            ...params,
          },
        });
      }
    },
    logout () {
      this.loading = true;
      this.$api.logout();
    },
  },
};

</script>

<template lang="pug">

div
  .webview(v-if="webview", :class="[ app, platform, { 'has-scroll': hasScroll } ]", :style="{ paddingTop }")
    .margin-bottom.clearfix.header
      .switcher
        //- Fake hidden input for MacOS webview - to prevent focus on language switcher
        input.invisible
        language-switcher(type="simple", @switch="switchLanguage", :language="language")
      .logo
      .account(v-if="!tinyWidth") My Account
    template(v-if="ready")
      intro(
        v-if="page === 'intro'",
        @switch="switchPage('reminder')",
        @cancel="decline",
        @logout="logout",
        :webview="webview",
        :newAccount="newAccount",
        :loading="loading",
        :tinyWidth="tinyWidth"
      )
      reminder(
        v-else-if="page === 'reminder'",
        @back="switchPage('intro')",
        @accept="accept",
        :webview="webview",
        :newAccount="newAccount",
        :app="app",
        :loading="loading",
        :reminderOnly="reminderOnly"
      )
    toast
  template(v-else-if="embedded")
    .page
      .row
        .col-sm-8.col-sm-offset-2.col-xs-12(v-if="page === 'intro'")
          card(:padding="3")
            intro(
              @switch="switchPage('reminder')",
              @back="$emit('back')",
              @logout="logout",
              :newAccount="newAccount",
              :loading="loading"
            )
        .col-xs-12(v-else)
          card(:padding="3")
            reminder(
              @back="switchPage('intro')",
              @accept="accept",
              :newAccount="newAccount",
              :app="app",
              :loading="loading",
              :reminderOnly="reminderOnly"
            )
  page.page(:appData="$appData", v-else)
    template(slot="pageContent")
      .row
        .col-sm-8.col-sm-offset-2.col-xs-12(v-if="page === 'intro'")
          card(:padding="3")
            intro(
              @switch="switchPage('reminder')",
              @back="$emit('back')",
              @logout="logout",
              :newAccount="newAccount",
              :loading="loading"
            )
        .col-xs-12(v-else)
          card(:padding="3")
            reminder(
              @back="switchPage('intro')",
              @accept="accept",
              :newAccount="newAccount",
              :app="app",
              :loading="loading",
              :reminderOnly="reminderOnly"
            )

</template>

<style scoped lang="sass">

.webview
  padding: $grid-step * 2 $grid-step * 2 $grid-step
  .switcher
    float: right
    & /deep/ .option
      padding-top: $grid-step / 2
      padding-bottom: $grid-step / 2
  .account
    font-size: 22px
    padding-top: 3px
    color: $steel-grey
  .logo
    float: left
    width: $grid-step * 10
    height: $grid-step * 3
    background: url('~@/assets/logo-black.svg') no-repeat
    background-size: 117px 25px
  & /deep/ h1
    text-indent: 0
  & /deep/ .buttons
    position: fixed
    left: 0
    bottom: 0
    width: 100%
    padding: $grid-step
    background: $white
  &.has-scroll /deep/ .buttons
    box-shadow: 0 -5px 15px fade_out($dark-navy-blue, 0.95)
  & /deep/ .last
    padding-bottom: $grid-step * 5
  &,
  & /deep/ input,
  & /deep/ textarea,
  & /deep/ button
    font-family: Helvetica, Arial, sans-serif

.invisible
  position: absolute
  transform: scale(0)

</style>

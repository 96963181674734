/**
 * File: getRtAuthTokenRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */
import ApiRequest from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface RequestParams {
  code: string;
}

interface ResponseData {
  token: string;
  locale: string;
  rtUrl: string;
}

export default class GetRtAuthTokenRequest extends ApiRequest<RequestParams, ResponseData> {
  get url () {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/get_rt_auth_token/${this.params.code}`;
  }

  get rtAuthToken () {
    return this.data.token;
  }

  get locale () {
    return this.data.locale;
  }

  get rtUrl () {
    return this.data.rtUrl;
  }
}

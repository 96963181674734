/**
 * File: emailErrors.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

export default () => ({
  'account deleted': function (response) {
    return $tc('This email address was previously deleted and cannot be reused for at least {n} days while we`re making sure that all data is completely removed from all Parallels services.', response.details.days, {
      n: response.details.days,
    });
  },
  'email changing restricted': $t('Your account email cannot be changed at the moment. Contact support for assistance.'),
});

/**
 * File: productPageMixIn.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { copyExcluding } from '@core/common/utils';
import { ADD_LICENSES_MODAL, PRODUCT_CONTEXT_TO_DOWNLOAD_MAP } from '@/routes/constants';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import Vue from 'vue';
import Subscription from '@/models/subscription';
import { RawLocation } from 'vue-router/types/router';

export const MODAL_INVITE = 'invite';

export default Vue.extend({
  name: 'product-page-mixin',

  props: {
    productKeyName: {
      type: String,
    },
  },

  data () {
    return {
      subscription: null as Subscription,
      error: false,
    };
  },

  methods: {
    handleModal (modal) {
      if (modal === MODAL_INVITE && this.$refs.hasOwnProperty('subscriptionInviteModal')) {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.subscriptionInviteModal.show();
      } else if (modal === ADD_LICENSES_MODAL && this.$modal.get(AddLicensesModal)) {
        this.$modal.show(AddLicensesModal, {
          product: this.productKeyName,
          subscriptionUuid: this.subscription ? this.subscription.uuid : null,
        });
      }
    },

    clearModalQuery () {
      if (this.$route.query.modal) {
        this.$router.replace({ query: copyExcluding(this.$route.query, ['modal']) });
      }
    },

    onHideInviteModal () {
      this.clearModalQuery();
    },

    onAddLicensesModalHide () {
      this.clearModalQuery();
    },

    onSubscriptionNotFound () {
      this.error = true;
    },

    onSubscriptionLoaded (subscription) {
      this.subscription = subscription;
    },
    getDownloadRoute (productName?: string): RawLocation {
      return PRODUCT_CONTEXT_TO_DOWNLOAD_MAP[productName || this.productKeyName];
    },
  },
  watch: {
    content (newVal) {
      if (newVal !== 'subscription') {
        this.subscription = null; // hide SubscriptionNotification
      }
    },

    '$route.query.modal' (newVal) {
      if (newVal) {
        this.handleModal(newVal);
      }
    },
  },
});

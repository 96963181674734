/**
 * File: getSocialBindingsRequest.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { SOCIAL_SERVICE } from '@/modules/personalProfile/personalProfileSecurity/constants';
import { Dictionary } from '@core/common/types';

/* eslint-disable camelcase */
export interface SocialBindingEntry {
  service_id: SOCIAL_SERVICE;
  link_confirmed: boolean;
  created_at: string;
  updated_at: string;
}

export interface SocialBindingsResponse {
  social_bindings: SocialBindingEntry[];
}
/* eslint-enable camelcase */

export default class GetSocialBindingsRequest extends ApiRequest<Dictionary, SocialBindingEntry> {
  get url (): string {
    return '/account/api/v1/profile/external_auth';
  }

  get method (): Method {
    return 'GET';
  }
}

<copyright>
  File: selectAccountModal.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

  modal(
    ref="modal",
    name="selectAccountModal",
    :closeButton="true",
    :size="10",
    :data-name="$name()",
  )
    template(slot="header") {{ $t('Choose Business Account for Support') }}
    template(slot="content")
      dropdown.block(
        :options="switcherOptions",
        v-model="selectedDomainId",
        :data-name="$name('dropdown-select-business-domain')",
      )
        template(slot="text", slot-scope="{ option }")
          div(v-if="option")
            span.title {{ option.title }}
            span.text-muted.margin-left {{ option.text }}
          div(v-else)
            span.text-muted {{ 'Choose Business Account' }}

    template(slot="footer")
      btn(
        @click="onConfirm",
        :disabled="buttonDisabled"
      ) {{ $t('Confirm') }}
</template>

<script>

import { GET_SUPPORT_PAGE } from '@/routes/constants';

export default {
  name: 'select-account-modal',
  props: {
    searchText: {
      type: String,
    },
    product: {
      type: String,
    },
    supportType: {
      type: String,
    },
  },
  data () {
    return {
      loading: false,
      switcherOptions: [],
      matchedDomains: [],
      selectedDomainId: this.$appData.userInDomain,
    };
  },
  methods: {
    show (matchedDomainIds) {
      this.matchedDomains = matchedDomainIds;
      this.$refs.modal.show();
      this.load();
    },

    load () {
      this.switcherOptions = this.buildSwitcherOptions(this.$appData.session);
    },

    buildSwitcherOptions: function (session) {
      const options = [];
      session.getBusinessDomains().forEach((domain) => {
        if (this.matchedDomains.includes(domain.companyUuid)) {
          options.push({
            title: domain.companyName,
            text: domain.isAdmin ? $t('My role: Administrator') : $t('My role: Regular Member'),
            value: domain.companyUuid,
          });
        }
      });
      return options;
    },

    onConfirm () {
      this.loading = true;
      this.$api.switchBusinessDomain(this.selectedDomainId).then(() => {
        this.$modal.hide();
        this.$router.push({
          name: GET_SUPPORT_PAGE,
          params: {
            product: this.product,
            supportType: this.supportType,
            action: 'request',
          },
          query: {
            searchText: this.searchText,
          },
        });
      });
    },
  },

  computed: {
    buttonDisabled () {
      return this.$appData.session.isPersonalUser(this.selectedDomainId);
    },
  },
};

</script>

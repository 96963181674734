

import Vue, { PropType } from 'vue';
import Subscription, {} from '@/models/subscription';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';

export default Vue.extend({
  name: 'properties',
  mixins: [ComponentMixIn, SubscriptionMixIn],
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
  },
  methods: {
    isSubscriptionHasTrait (trait: string): boolean {
      return this.subscription.traits && this.subscription.traits.hasOwnProperty(trait);
    },
  },
  watch: {
    subscription: {
      handler (subscription) {
        this.customer = this.subscription.traits?.awgCustomer;
        this.partner = this.subscription.traits?.awgPartner;
      },
      deep: true,
    },
  },

  data () {
    return {
      customer: this.subscription.traits?.awgCustomer,
      partner: this.subscription.traits?.awgPartner,
      azmpSubscriptionId: this.subscription.traits?.azmpSubscriptionId,
      azmpResourceUsageId: this.subscription.traits?.azmpResourceUsageId,
      convertedFromLegacyLicense: this.subscription.traits?.awgConvertedFromLegacyAt,
      dailyLicenseCheck: this.subscription.traits?.awgDailyLicenseCheck,
      anonymousLicenseUsageReporting: this.subscription.traits?.awgAnonymousLicenseUsageReport,
      inAppDownload: this.subscription.traits?.awgInAppDownload,
      notForResale: this.subscription.isNfr,
      promoLicense: this.subscription.isPromo,
      betaVersionOnly: this.subscription.isBeta,
    };
  },

});


/**
 * File: registerRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import emailPatternMismatch from './errorHandlers/emailPatternMismatch';
import passwordErrors from './errorHandlers/passwordErrors';
import emailErrors from './errorHandlers/emailErrors';
import { buildQuery } from '@core/common/url';
import { support } from '@core/constants/links';
import Service from '@core/constants/services';

export type RegisterRequestParams = {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  locale: string;
  force?: boolean;
  rememberMe?: boolean;
  consentId?: string;
  dryRun?: boolean;
  service?: Service;
  continue?: string;
}

// https://wiki.prls.net/x/C9URBQ
export default class RegisterRequest extends ApiRequest<RegisterRequestParams> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    const query = buildQuery(['service', 'continue'], this.params);
    return `/account/webapp/register${query}`;
  }

  get body (): Json {
    return {
      first_name: this.params.firstName,
      last_name: this.params.lastName,
      email: this.params.email,
      password: this.params.password,
      locale: this.params.locale,
      remember_me: this.params.rememberMe,
      consent_id: this.params.consentId,
      force: this.params.force,
      dry_run: this.params.dryRun,
    };
  }

  get errors () {
    return Object.assign({
      'account already exists': $t('This email is already in use.'),
      // TODO: add proper modal for "already invited" error
      'already invited': $t('You have already been invited to join existing account but have not yet accepted the invitation.'),
      'invalid parameters': response => emailPatternMismatch(response) || $t('Invalid parameters'),
      'rate limited': $t('Too many account registration requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    }, passwordErrors(), emailErrors());
  }
}

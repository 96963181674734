/**
 * File: pdReactivateHostRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PdComputersRequest from './pdComputersRequest';
import PdBusinessSubscriptionsRequest from './pdBusinessSubscriptionsRequest';
import LsSubscriptionsRequest from './lsSubscriptionsRequest';
import LsUniversalKeysRequest from './lsUniversalKeysRequest';
import SubscriptionUniversalKeysRequest from './subscriptionUniversalKeysRequest';

export default class PdReactivateHostRequest extends ApiRequest {
  get url () {
    return '/desktop/api/v1/ba/hosts/reactivate';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      uuid: this.params.uuid,
      hosts: this.params.hosts,
    };
  }

  get errors () {
    return {
      'subscription doesn\'t exist': $t('Activation failed. The specified license key does not exist.'),
      'universal key doesn\'t exist': $t('Activation failed. The specified license key does not exist.'),
      'can\'t reactivate with a personal key': $t('Activation failed. The specified license key cannot be used for activation.'),
      'universal key is expired': $t('Activation failed. The specified license key has expired.'),
      'not enough seats': $t('Activation failed. Insufficient number of licenses available.'),
      'universal key is blacklisted': $t('Activation failed. The specified license key has been blacklisted.'),
    };
  }

  load () {
    return super.load()
      .then((data) => {
        new PdComputersRequest({}).dropFullCache();
        new PdBusinessSubscriptionsRequest().dropFullCache();
        new LsSubscriptionsRequest().dropFullCache();
        new SubscriptionUniversalKeysRequest({}).dropFullCache();
        new LsUniversalKeysRequest({}).dropFullCache();
        return data;
      });
  }
}


import Vue from 'vue';

import StatusMixin from '../statusMixin';
import PaymentOptionsLink from '@/modules/subscriptions/paymentOptions/paymentOptionsLink.vue';

export default Vue.extend({
  name: 'subscription-status-card-expired',
  mixins: [StatusMixin],
  components: { PaymentOptionsLink },
});

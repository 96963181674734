/**
 * File: searchFilterMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

export default Vue.extend({
  methods: {
    applySearchFilters (searchFilters, data) {
      const filters = Object.values(searchFilters);
      const filterFunc = (entry) => filters.every((filter: Function) => filter(entry));
      return data.filter(filterFunc);
    },
  },
});

/**
 * File: changeLicenseAmountRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import Cache from '@core/api/cache';
import Service from '@core/constants/services';

export interface ChangeLicenseAmountRequestParams {
  uuid: string;
  product: string;
  service: Service;
  amount: number;
}

export default class ChangeLicenseAmountRequest extends ApiRequest<ChangeLicenseAmountRequestParams> {
  json = false;

  get method (): Method {
    return 'PUT';
  }

  get url (): string {
    return `/${this.params.service}/api/v1/subscriptions/${this.params.uuid}/products/${this.params.product}`;
  }

  get body (): Json {
    return {
      thisPeriod: this.params.amount,
    };
  }

  load () {
    return super.load()
      .then((data) => {
        new Cache().dropCache();
        return data;
      });
  }
}



import Vue, { PropType } from 'vue';
import {
  ThresholdRule,
  ThresholdRuleType
} from '@/api/resourceUsageNotificationUpdateRequest';

export default Vue.extend({
  name: 'threshold-rule',

  props: {
    value: Object as PropType<ThresholdRule>,
    allowPercentValue: Boolean,
  },

  data () {
    return {
      ThresholdRuleType,
      val: {
        type: this.value.type,
        usersValue: this.value.type === ThresholdRuleType.Users ? this.value.value : 1,
        percentValue: this.value.type === ThresholdRuleType.Percent ? this.value.value : 50,
      },
    };
  },

  validations: {
    val: {
      usersValue: {
        positive (value): boolean {
          return this.val.type !== ThresholdRuleType.Users || value > 0;
        },
      },
    },
  },

  methods: {
    update ({ type, value }: ThresholdRule) {
      this.val.type = type;
      switch (this.val.type) {
        case ThresholdRuleType.Users:
          this.val.usersValue = value;
          break;
        case ThresholdRuleType.Percent:
          this.val.percentValue = value;
          break;
      }
    },
    emit () {
      let value = null;
      switch (this.val.type) {
        case ThresholdRuleType.Users:
          value = this.val.usersValue;
          break;
        case ThresholdRuleType.Percent:
          value = this.val.percentValue;
          break;
      }
      this.$emit('input', { type: this.val.type, value });
    },
  },

  watch: {
    'value' (value) {
      this.update(value);
    },
    'value.type' () {
      this.update(this.value);
    },
    'value.value' () {
      this.update(this.value);
    },
    'val' () {
      this.emit();
    },
    'val.type' () {
      this.emit();
    },
    'val.usersValue' () {
      this.emit();
    },
    'val.percentValue' () {
      this.emit();
    },
  },
});


/**
 * File: businessProfileMembers.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { GROUP_INVITED, STATUS_DISABLED } from '@/api/userConst';

import componentMixin from '@/modules/componentMixIn';
import searchFilterMixin from '@/modules/searchFilterMixin';
import columnsObserverMixin from '@/modules/columnsObserverMixin';

import CompanyUsersRequest, { CompanyUsersResponse } from '@/api/companyUsersRequest';
import CompanyUsersBlockRequest from '@/api/companyUsersBlockRequest';
import CompanyUsersUnblockRequest from '@/api/companyUsersUnblockRequest';
import CompanyUsersRemoveRequest from '@/api/companyUsersRemoveRequest';
import CompanyUsersReinviteRequest from '@/api/companyUsersReinviteRequest';
import CompanyUsersMakeAdminRequest from '@/api/companyUsersMakeAdminRequest';
import CompanyUsersRevokeAdminRequest from '@/api/companyUsersRevokeAdminRequest';
import GetUsersPermissionsRequest from '@/api/getUsersPermissionsRequest';
import CsvSaver from '@/ui/csvSaver.vue';
import FilterDropdownMy from '@/ui/filterDropdown/index.vue';
import inviteDialog from './inviteDialog/inviteDialog.vue';
import editDialog from './editDialog/editDialog.vue';
import Vue from 'vue';
import Subscription from '@/models/subscription';
import CompanyUser from '@/models/companyUser';

type Actions = {
  name: string;
  text: string;
  filter: (user)=> boolean;
  callback: (user)=> any;
  autohide?: boolean;
}[];
interface IUser {
  uuid: number;
  name: string;
  email: string;
  notes: string;
  isAdmin: boolean;
  isLicenseAdmin: boolean;
  isEmployee: boolean;
  isInvited: boolean;
  isBlocked: boolean;
  isActive: boolean;
  role: string;
  subscriptions?: Subscription[];
  status?: string;
}

interface ITable {
  name: string;
    selectedUuids: number[];
  columns: ('name' | 'email' | 'role' | 'status')[];
  options: {
    sortable: ('name' | 'email' | 'role' | 'status')[];
    uniqueKey: 'uuid';
  };
}

export default Vue.extend({
  name: 'business-profile-users',

  mixins: [componentMixin, columnsObserverMixin, searchFilterMixin],

  props: {
    session: {
      type: Object,
    },
  },

  data () {
    return {
      usersLoading: false,
      actionLoading: false,
      users: [] as IUser[],
      searchFilters: {} as Record<string, any>,
      table: {
        name: 'businessProfileMembers',
        selectedUuids: [],
        columns: ['name', 'email', 'role', 'status'],
        options: {
          sortable: ['name', 'email', 'role', 'status'],
          uniqueKey: 'uuid',
        },
      } as ITable,
      columnsOptions: [
        { text: $t('User Name'), value: 'name' },
        { text: $t('Email'), value: 'email' },
        { text: $t('Role'), value: 'role' },
        { text: $t('Status'), value: 'status' },
      ],
      action: null,
      inspectedUserUuid: null,
      roleOptions: [
        { text: $t('Account Administrator ({count})'), value: 'administrator', filter: (user) => user.isAdmin },
        { text: $t('License Manager ({count})'), value: 'licenseAdministrator', filter: (user) => user.isLicenseAdmin },
        { text: $t('Regular Member ({count})'), value: 'employee', filter: (user) => user.isEmployee },
      ],
      statusOptions: [
        { text: $t('Active ({count})'), value: 'active', filter: (user) => user.isActive },
        { text: $t('Invited ({count})'), value: 'invited', filter: (user) => user.isInvited },
        { text: $t('Blocked ({count})'), value: 'blocked', filter: (user) => user.isBlocked },
      ],
    };
  },

  computed: {

    tableColumns (): string[] {
      return ['uuid'].concat(this.table.columns);
    },

    searchResults (): IUser[] {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.applySearchFilters(this.searchFilters, this.users);
    },

    actionOptions (): { text: string; value: string; disabled: boolean }[] {
      return this.getActions().map((action) => {
        const selectedUsers = this.getSelectedUsers(action.filter);

        if (action.autohide && !selectedUsers.length) {
          return null;
        }

        return {
          text: action.text.replace('{count}', selectedUsers.length.toString()),
          value: action.name,
          disabled: !selectedUsers.length,
        };
      }).filter((v) => v);
    },

    selectAll: {
      get (): boolean {
        return this.searchResults.length && this.table.selectedUuids.length === this.searchResults.length;
      },

      set (v: boolean) {
        // TODO: implement tri-state
        this.table.selectedUuids = v ? this.searchResults.map((user) => user.uuid) : [];
      },
    },

    inspectedUser (): IUser {
      return this.users.find((user) => user.uuid === this.inspectedUserUuid);
    },
  },

  watch: {
    // Clear selected items if it is not present in the table
    searchResults (searchResults) {
      this.table.selectedUuids = this.table.selectedUuids.filter((uuid) => {
        return searchResults.some(user => user.uuid === uuid);
      });
    },
  },

  mounted () {
    this.load();
  },

  methods: {

    load (): void {
      const request = new CompanyUsersRequest({}, this.session.businessDomainId);
      const permissionsRequest = new GetUsersPermissionsRequest({
        domainId: this.session.businessDomainId,
      });

      this.usersLoading = true;

      this.$api.authorizedCall(permissionsRequest).then(() => {
        return this.$api.authorizedCall(request);
      }).then((data: CompanyUsersResponse) => {
        const permissions = permissionsRequest.getPermissions();

        this.users = request.getUsers().map((user) => {
          const subscriptions = permissions.filter((item) => item.uid === user.uuid)
            .reduce((result, item) => {
              return result.concat(item.subscriptions);
            }, []);

          return this.userToProps(user, subscriptions);
        });
        this.$emit('updateMembersAmount', {
          amount: data.users.length,
        });
      }).finally(() => {
        this.usersLoading = false;
      });
    },

    reload () {
      new CompanyUsersRequest({}, this.session.businessDomainId).dropCache();
      this.load();
    },

    userToProps (user: CompanyUser, managedSubscriptions): IUser {
      const
        isAdmin = user.isAdmin;
      const isInvited = user.groups && user.groups.indexOf(GROUP_INVITED) !== -1;
      const isBlocked = user.status === STATUS_DISABLED;
      const isActive = !isInvited && !isBlocked;
      const isLicenseAdmin = !!managedSubscriptions.length;

      return {
        uuid: user.uuid,
        name: user.name,
        email: user.email,
        notes: user.notes,
        isAdmin,
        isLicenseAdmin,
        isEmployee: !isAdmin && !isLicenseAdmin,
        isInvited,
        isBlocked,
        isActive,
        role: isAdmin ?
          $t('Account Administrator') :
          (isLicenseAdmin ? $t('License Manager') : $t('Regular Member')),
        subscriptions: isLicenseAdmin ? managedSubscriptions : undefined,
        status: isInvited ?
          $t('Invited') :
          (isBlocked ?
            $t('Blocked') :
            (isActive ? $t('Active') : undefined)),
      };
    },

    getSelectedUsers (filter): IUser[] {
      return this.users.filter((user) => this.table.selectedUuids.indexOf(user.uuid) !== -1 && filter(user));
    },

    callAction (name) {
      const action = this.getActions().find((action) => action.name === name);
      if (action) {
        action.callback(this.getSelectedUsers(action.filter));
        this.$nextTick(() => {
          this.action = null;
        });
      }
    },

    exportCsv (rows) {
      const columns = this.table.columns.map((value) => {
        return this.columnsOptions.find((column) => column.value === value) || value;
      });

      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.csv.save({
        columns,
        rows,
      });
    },

    makeUsersRequest (requestClass, users): Promise<void> {
      const userUuids = users.map((user) => user.uuid);
      // eslint-disable-next-line
      let request = new requestClass({userUuids}, this.session.businessDomainId);

      this.actionLoading = true;

      return this.$api.authorizedCall(request).then(() => {
        this.actionLoading = false;
        this.reload();
      }).catch(() => {
        this.actionLoading = false;
      });
    },

    getActions (): Actions {
      return [
        {
          name: 'save_csv',
          text: $t('Save List to CSV File ({count})'),
          filter: (user) => true,
          callback: this.exportCsv.bind(this),
        },
        {
          name: 'reinvite',
          text: $t('Resend Invitations ({count})'),
          filter: (user) => user.isInvited,
          callback: users => {
            this.makeUsersRequest(CompanyUsersReinviteRequest, users).then(() => {
              const count = users.length;
              this.$toast.show({
                color: 'green',
                text: $tc('{count} invitations have been sent', count, { count }),
              });
            });
          },
        },
        {
          name: 'block',
          text: $t('Block ({count})'),
          filter: (user) => !(user.isBlocked || user.isInvited),
          callback: this.makeUsersRequest.bind(this, CompanyUsersBlockRequest),
        },
        {
          name: 'unblock',
          text: $t('Unblock ({count})'),
          autohide: true,
          filter: (user) => user.isBlocked,
          callback: this.makeUsersRequest.bind(this, CompanyUsersUnblockRequest),
        },
        {
          name: 'remove',
          text: $t('Remove ({count})'),
          filter: (user) => true,
          callback: this.makeUsersRequest.bind(this, CompanyUsersRemoveRequest),
        },
        {
          name: 'makeAdmin',
          text: $t('Make Admin ({count})'),
          filter: (user) => !user.isAdmin,
          callback: this.makeUsersRequest.bind(this, CompanyUsersMakeAdminRequest),
        },
        {
          name: 'revokeAdmin',
          text: $t('Revoke Admin ({count})'),
          filter: (user) => user.isAdmin,
          callback: this.makeUsersRequest.bind(this, CompanyUsersRevokeAdminRequest),
        },
      ];
    },

    showEditDialog (userUuid) {
      this.inspectedUserUuid = userUuid;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$nextTick(() => this.$refs.editDialog.show());
    },

  },

  components: {
    FilterDropdownMy,
    inviteDialog,
    editDialog,
    CsvSaver,
  },

});

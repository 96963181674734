<copyright>
File: tableView.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import { TableView } from '@parallels/ui-kit';

export default {
  name: 'table-view-my',
  inheritAttrs: false,
  components: {
    TableView,
  },
  computed: {
    actions () {
      return Object.assign({
        label: this.$t('Actions'),
      }, this.$attrs.actions);
    },
    columns () {
      return Object.assign({
        label: this.$t('Columns'),
      }, this.$attrs.columns);
    },
    pagination () {
      if (this.$attrs.pagination) {
        return Object.assign({
          label: this.$t('Items per Page'),
          options: [
            { value: 50, text: '50' },
            { value: 100, text: '100' },
            { value: 500, text: '500' },
            { value: Infinity, text: this.$t('All') },
          ],
        }, this.$attrs.pagination);
      } else {
        return undefined;
      }
    },
  },
  mounted () {
    // TODO: remove this code when config will be reinitialized (in a month, two or three?)
    if (this.$refs.table.configKeyName) {
      const config = JSON.parse(localStorage.getItem(this.$refs.table.configKeyName) || '{}');
      if (!config.allColumns) {
        this.$refs.table.clearState();
      }
    }
  },
};

</script>

<template lang="pug">

//- $listeners - listen and pass all events to parent
//- $attrs - bind every property from this component to table view
table-view(
  ref="table",
  v-on="$listeners",
  v-bind="$attrs",
  :actions="actions",
  :columns="columns",
  :pagination="pagination",
  :smartFilterPlaceholder="$t('Select column')",
  :counter="$t('({count} of {total})')",
  :formatDate="formatDate",
  :showContextMenu="false"
)
  //- Slot for empty records set
  template(v-slot:no-records) {{ $t('No matching records') }}
  //- Filter modal texts
  template(v-slot:filter-apply-button) {{ $t('Apply') }}
  template(v-slot:filter-reset-button) {{ $t('Reset') }}
  template(v-slot:filter-title) {{ $t('Filter') }}
  template(v-slot:filter-dropdown-button) {{ $t('Done') }}
  //- Pass slots from parent component to table view
  slot(v-for="(_, name) in $slots", :name="name", :slot="name")
  template(v-for="(_, name) in $scopedSlots", :slot="name", slot-scope="slotData")
    slot(:name="name", v-bind="slotData")

</template>

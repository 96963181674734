/**
 * File: apiError.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import { RATE_LIMIT_PAGE, UNSUPPORTED_COUNTRY_PAGE } from '@/routes/constants';
import { HTTP_CODES } from '@core/constants/http';

function install (Vue, options) {
  /*
  Plugin listen broadcast event 'api-error' and if response contains the "msg" field and request class has
  appropriate mapping, displays a toast with text from response.
   */
  Vue.nextTick(() => {
    const $app = options.context.app.$root;

    $app.$on('api-error', (params) => {
      if (!params.error.hasOwnProperty('response')) {
        return;
      }

      const errors = params.request.errors;
      const status = params.error.response.status;

      if (status === HTTP_CODES.FORBIDDEN || status === HTTP_CODES.TOO_MANY_REQUESTS) {
        params.error.response.json().then((response) => {
          if (response.msg === 'You are signing in from a country or territory where Parallels products are not supported.') {
            $app.$router.push({
              name: UNSUPPORTED_COUNTRY_PAGE,
            });
          } else if (params.request.getSession && response.msg === 'rate limited') {
            $app.$router.push({
              name: RATE_LIMIT_PAGE,
            });
          }
        });
      }

      if (!errors) {
        return;
      }

      params.error.response.json().then((response) => {
        let text = (response.msg && errors[response.msg]) || errors[status];
        // function with own property 'toString' is $t function
        if (typeof text === 'function' && !text.hasOwnProperty('toString')) {
          text = text(response);
        }
        if (text) {
          $app.$toast.show({
            color: 'red',
            html: text,
          });
        }
      });
    });
  });
}

export default {
  install,
};

/**
 * File: main.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue from 'vue';
import cookie from 'js-cookie';
import clone from 'lodash-es/clone';
import VueStorage from 'vue-ls';
import visibility from 'vue-visibility-change';
import Vuelidate from 'vuelidate';
import UI from '@parallels/ui-kit';

import '@core/common/polyfill';
import ApiError from '@/plugins/apiError';
import '@/plugins/pageTitle';
import { formatBytes } from '@core/common/format/size';
import { date, time, dateValue } from '@core/common/format/date';
import { price } from '@core/common/format/price';
import { firstItem, perLine, getComponentName, truncateTextTo } from '@core/common/utils';
import { LOCALES, DEFAULT_LOCALE } from '@core/constants/geo';
import locale from '@/plugins/locale';
import TranslationString from '@/models/translationString';

import Api from '@/api';
import App from '@/app.vue';
import router from '@/router';
import routes from '@/routes';
import '@/directives/visible';
import '@/plugins/iosClick';
import trackReferrer from '@/plugins/trackReferrer';
import setReportId from '@/plugins/setReportId';
import { appData } from '@/data/appData';

Vue.use({
  install: (Vue) => {
    Vue.prototype.$cookie = cookie;
  },
});

// setup session storage
Vue.use(clone(VueStorage), {
  name: 'ss',
  storage: 'session',
});
Vue.prototype.$sessionStorage = Vue.prototype.$ss;

// setup local storage
Vue.use(clone(VueStorage), {
  name: 'ls',
  storage: 'local',
});
Vue.prototype.$localStorage = Vue.prototype.$ls;

Vue.config.productionTip = false;

Vue.use(visibility);

Vue.prototype.$bus = new Vue();

Vue.prototype.$name = getComponentName;

Vue.use(Vuelidate);

// Setup validation errors texts
Vue.use(UI, {
  validationErrors: {
    errors (validator) {
      const texts: ErrorTexts = {
        required: $t('This field is mandatory'),
        email: $t('Enter a valid email address'),
        sameAsPassword: $t('Passwords do not match!'),
        notSameAsEmail: $t('Password can`t be the same as email'),
        country: $t('No such country'),
        state: $t('No such state'),
        zip: $t('Allowed symbols: 0-9 and letters'),
        phone: $t('Allowed symbols: + space 0-9 a-z ( ) - * #'),
        regexp: $t('Invalid format'),
      };

      if (validator.hasOwnProperty('minLength')) {
        texts.minLength = $tc('Input at least {n} characters', validator.$params.minLength.min, { n: validator.$params.minLength.min });
      }

      if (validator.hasOwnProperty('maxLength')) {
        texts.maxLength = $tc('Input no more than {n} characters', validator.$params.maxLength.max, { n: validator.$params.maxLength.max });
      }

      return texts;
    },
  },
});

const AppDataPlugin = {
  install (Vue, options) {
    Vue.prototype.$appData = appData;
  },
};

const dateFormatter = {
  install (Vue, options) {
    function getLocale () {
      if (appData.session) {
        return LOCALES[appData.session.country] || (appData.session.locale && appData.session.locale.replace('_', '-')) || DEFAULT_LOCALE;
      } else {
        return locale.code.replace('_', '-');
      }
    }
    Vue.prototype.formatDate = (dt, time) => {
      return date(dt, getLocale(), time);
    };
    Vue.prototype.formatDateUTC = (dt) => {
      return date(dt, getLocale(), false, false, true);
    };
    Vue.prototype.formatDateTimeUTC = (dt) => {
      return date(dt, getLocale(), true, false, true);
    };
    Vue.prototype.formatShortDate = (dt) => {
      return date(dt, getLocale(), false, true);
    };
    Vue.prototype.formatTime = (dt) => {
      return time(dt, getLocale());
    };
    Vue.prototype.formatDateTime = (dt) => {
      return date(dt, getLocale(), true);
    };
  },
};

const filters = {
  install (Vue) {
    Vue.filter('price', price);
    Vue.filter('date', Vue.prototype.formatDate);
    Vue.filter('dateUTC', Vue.prototype.formatDateUTC);
    Vue.filter('dateTimeUTC', Vue.prototype.formatDateTimeUTC);
    Vue.filter('time', Vue.prototype.formatTime);
    Vue.filter('dateTime', Vue.prototype.formatDateTime);
    Vue.filter('dateValue', dateValue);
    Vue.filter('firstItem', firstItem);
    Vue.filter('perLine', perLine);
    Vue.filter('fileSize', formatBytes);
    Vue.filter('truncateTextTo', truncateTextTo);
  },
};

class MyMain {
  appData: AppData;
  plugins: Array<any>;
  router: any;
  app: Vue;

  constructor () {
    const self = this;

    this.appData = appData;
    this.plugins = [];

    this.plugins.push(
      AppDataPlugin,
      Api,
      ApiError,
      dateFormatter,
      filters
    );

    // FIXME move it to the app level
    this.appData.setUserInDomain = function (value) {
      this.rtToken = null;

      this.userInDomain = value;
      self.app.$localStorage.set('userInDomain', value);
      self.app.$root.$emit('domain_switched', { userInDomain: value, session: this.session });
    };

    this.appData.initActiveDomain = function () {
      const cookieUserId = Number(self.app.$localStorage.get('userInDomain'));
      let domainId;

      if (this.session.isCurrentBusinessUser(cookieUserId) || (this.session.isPersonalUser(cookieUserId) && this.products.personal.length)) {
        domainId = cookieUserId;
      } else if (this.products && (this.products.business && this.products.business.length || this.session.isPdfcPurchaseFeatureAccessible) && this.session.businessDomainId) {
        domainId = this.session.businessDomainId;
      } else if (this.products && this.products.personal && this.products.personal.length) {
        domainId = this.session.personalDomainId;
      }

      if (domainId) {
        this.setUserInDomain(domainId);
      }
    };

    this.appData.setSession = function (value) {
      this.session = value;
      if (value === null) {
        this.userInDomain = null;
        this.products = null;
      }
      const isLoggedIn = !!(value && value.token);
      // @ts-ignore
      Vue.ls.set('isLoggedIn', isLoggedIn ? '1' : '');
    };

    // @ts-ignore
    Vue.ls.on('isLoggedIn', function (newValue, oldValue) {
      if (oldValue && !newValue && self.appData.session && !self.appData.session.isGuest) {
        window.location.reload();
      }
    });
  }

  init () {
    this.registerGlobalComponents();
    this.bindApp();
  }

  registerGlobalComponents () {
    const globals = this.globalComponents;
    Object.keys(globals).forEach((name) => {
      Vue.component(name, globals[name]);
    });
  }

  get globalComponents () {
    return {
      Page: require('@/ui/page').default,
      ModalField: require('@/ui/modal-field').default,
      Card: require('@/ui/card').default,
      TableView: require('@/ui/tableView').default,
      Subnavigation: require('@/ui/subnavigation').default,
    };
  }

  bindApp () {
    // install plugins
    this.plugins.forEach((plugin) => { Vue.use(plugin, { context: this }); });

    router.addRoutes(routes);

    this.router = router;
    this.app = new Vue({
      el: '#app',
      // @ts-ignore
      i18n: locale.i18n,
      router,
      data: this.appData, // FIXME not mutable dict!
      template: '<app ref="app" :appData="$data" />',
      created () {
        // TODO: get rid of this global functions
        // @ts-ignore
        window.$t = (str, replacements = {}) => {
          return new TranslationString({ str, replacements, context: this });
        };
        // @ts-ignore
        window.$tc = (str, n, replacements = {}) => {
          return new TranslationString({ str, n, replacements, context: this });
        };
      },
      mounted () {
        trackReferrer(this.$sessionStorage);
        setReportId(this.$sessionStorage);
        this.$api.setCountries();
      },
      render: (h) => h(App),
    });
  }
}

const main = new MyMain();
main.init();

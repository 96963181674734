/**
 * File: upgradeOnlineRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class UpgradeOnlineRequest extends ApiRequest {
  /*
  Fetch URL to upgrade online.
  */

  get method () {
    return 'GET';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/upgrade_online`);
  }
}

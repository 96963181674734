
import Vue from 'vue';
import { copyToClipboard } from '@core/common/utils';
import { SSO_SETTINGS_STATUS } from './constants';

export default Vue.extend({
  name: 'step-abstract',
  props: {
    settings: {
      type: Object,
      required: true,
    },
    stepNumber: {
      type: [Number, String],
      required: true,
    },
    stepName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    recommended: {
      type: Boolean,
      required: false,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      problem: false,
    };
  },
  methods: {
    emitDone (val) {
      this.loading = true;
      this.$emit('done', this.stepName, val);
    },
    copyToClipboard (value) {
      copyToClipboard(value);
      this.$toast.show({
        text: this.$t('Copied to the clipboard.'),
      });
    },
    onExpanded (v) {
      this.$emit('expanded', v);
    },
  },
  computed: {
    circleClass (): string {
      if (this.loading) {
        return 'number-circle-loading';
      } else if (this.problem) {
        return 'number-circle-problem';
      } else if (this.done) {
        return 'number-circle-done';
      }
      return '';
    },
    disabled (): boolean {
      return this.loading || this.settings?.status === SSO_SETTINGS_STATUS.ACTIVATED;
    },
    done (): boolean {
      return this.settings?.stepsDone[this.stepName];
    },
  },
});

<copyright>
File: registerForm.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import { not, sameAs } from 'vuelidate/lib/validators';
import { name, email, password, acceptTerms } from '@core/common/validators';

export default {
  name: 'register-form',
  props: {
    // This property is used in acceptTerms validator
    consent: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        rememberMe: false,
        agree: false,
      },
    };
  },
  validations: {
    form: {
      firstName: name,
      lastName: name,
      email: email,
      password: {
        ...password,
        notSameAsEmail: not(sameAs('email')),
      },
      agree: acceptTerms,
    },
  },
  methods: {
    submit () {
      this.$emit('submit', this.form);
    },
  },
};

</script>

<template lang="pug">

form-data(:validator="$v.form", @submit="submit")
  .margin-bottom-2x
    textbox(
      name="email",
      type="email",
      v-model.trim="form.email",
      :icon="false",
      :validator="$v.form.email",
      :placeholder="`${$t('Email')} *`",
      :floating-placeholder="true",
      size="big",
      data-qa="textbox-email",
      :data-name="$name('textbox-email')"
    )
  .margin-bottom-2x
    textbox(
      name="first_name",
      v-model.trim="form.firstName",
      :validator="$v.form.firstName",
      :placeholder="$t('First name')",
      :floating-placeholder="true",
      size="big",
      data-qa="textbox-first-name",
      :data-name="$name('textbox-first-name')"
    )
  .margin-bottom-2x
    textbox(
      name="last_name",
      v-model.trim="form.lastName",
      :validator="$v.form.lastName",
      :placeholder="$t('Last name')",
      :floating-placeholder="true",
      size="big",
      data-qa="textbox-last-name",
      :data-name="$name('textbox-last-name')"
    )
  .margin-bottom-2x
    textbox(
      name="password",
      type="password",
      v-model.trim="form.password",
      :icon="false",
      :validator="$v.form.password",
      :eye="true",
      :eye-inside="true",
      :placeholder="`${$t('Password')} *`"
      :floating-placeholder="true",
      size="big",
      data-qa="textbox-password",
      :data-name="$name('textbox-password')"
    )
  div(v-if="!consent.required")
    checkbox(
      v-model="form.agree",
      :validator="$v.form.agree",
      :data-name="$name('checkbox-agree')"
    )
      formatter(:text="$t('Accept {link}Terms of Use{/link}')")
        template(slot="link", slot-scope="p")
          a(
            href="https://www.parallels.com/about/legal/terms/",
            :data-name="$name('link-terms-of-use')"
          ) {{ p.text }}
      validation-errors(slot="errors", :validator="$v.form.agree", :customErrors="['required']")
        template(slot="requiredError") {{ $t('You must accept the Terms of Use') }}
  btn.block.margin-top-3x(type="submit", data-qa="button-sign-up", :data-name="$name('button-sign-up')") {{ $t('Sign Up') }}

</template>



import Vue from 'vue';
import ComponentMixIn from '@/modules/componentMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import { ActionCallParameters, TableViewActions, TableViewColumns } from '@/ui/tableViewConst';
import TableView from '@/ui/tableView.vue';
import CsvSaver from '@/ui/csvSaver.vue';
import ConfirmationModal from '@/ui/confirmationModal/index.vue';
import SsoDomain from '@/models/ssoDomain';
import SsoDomainsRequest, { DeleteSsoDomainRequest, DeleteSsoDomainsRequest } from '@/api/sso/ssoDomainsRequests';
import SsoDomainModal from './ssoDomainModal.vue';

export default Vue.extend({
  name: 'business-profile-domains-sso',
  mixins: [ComponentMixIn, SearchFilterMixin],
  components: { TableView, CsvSaver, SsoDomainModal, ConfirmationModal },
  props: {
    verificationCode: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      domains: [] as SsoDomain[],
      filters: {} as TableViewFilters,
      table: {
        name: 'businessProfileDomainsSSO',
        selectedIds: [] as string[],
        sortBy: {
          column: 'domainName',
          ascending: true,
        },
      },
      csvFileName: 'domains.csv',
    };
  },
  computed: {
    tableColumns (): TableViewColumns {
      return {
        options: [
          { text: this.$t('Domain'), value: 'domainName' },
          { text: this.$t('Description'), value: 'description' },
          { text: this.$t('Registration Date'), value: 'registration', type: 'date' },
          { text: this.$t('Status'), value: 'status' },
          { text: this.$t('Product Users'), value: 'users' },
        ],
      };
    },
    selectedIds (): SsoDomain[] {
      return this.domains.filter((domain) => this.table.selectedIds.includes(domain.domainName));
    },
    actions (): TableViewActions {
      const actions: TableViewAction[] = [];
      actions.push({
        value: 'showConfirmMultipleDeletionModal',
        text: this.$t('Delete Domain ({count})'),
      });
      actions.push({
        value: 'exportCsv',
        text: this.$t('Save List to CSV File ({count})'),
      });
      return {
        id: 'domainName',
        options: actions,
      };
    },
  },
  mounted () {
    this.load();
  },
  methods: {
    load (): void {
      const domainsRequest = new SsoDomainsRequest({ companyUuid: this.$appData.session.businessDomainId });
      this.loading = true;
      this.$api.authorizedCall(domainsRequest).then(() => {
        this.domains = domainsRequest.getDomains();
        this.$emit('loaded', this.domains);
      }).finally(() => {
        this.loading = false;
      });
    },
    callAction ({ action, items }: ActionCallParameters): void {
      (this[action] as CallbackFunctionType)(items);
    },
    // FIXME CPCLOUD-17383 use CsvSaver included into TableView
    exportCsv (domains: SsoDomain[]): void {
      (this.$refs.csv as unknown as typeof CsvSaver).save({
        columns: this.tableColumns.options,
        rows: domains,
      });
    },
    addDomain (): void {
      this.$modal.show(SsoDomainModal);
    },
    onDomainClick (domain): void {
      this.$modal.show(SsoDomainModal, { domain });
    },
    showConfirmMultipleDeletionModal (): void {
      this.$modal.show(ConfirmationModal, {
        title: this.$tc('Delete {amount} Domains', this.selectedIds.length, { amount: this.selectedIds.length }),
        size: 'big',
        showMessage: false,
        btnLabel: this.$tc('Delete Domains', this.selectedIds.length),
        btnColor: 'red',
        success: (data) => { this.deleteDomains(); },
      });
    },
    showConfirmSingleDeletionModal (domain: SsoDomain): void {
      this.$modal.show(ConfirmationModal, {
        title: this.$t('Delete Domain'),
        message: this.$tc('Confirm deletion of the domain {domainName} ({description}). {users} users will be affected', domain.users,
          { domainName: domain.domainName, description: domain.description, users: domain.users }),
        size: 'big',
        btnLabel: this.$t('Delete Domain'),
        btnColor: 'red',
        success: (data) => { this.deleteSingleDomain(domain); },
      });
    },
    deleteDomains (): void {
      const domainIds = this.selectedIds.map((domain) => domain.id);
      const requestData = {
        companyUuid: this.$appData.session.businessDomainId,
        domains: domainIds,
      };
      this.authorizedCall(new DeleteSsoDomainsRequest(requestData)).then(() => {
        this.load();
        const text = this.$tc('{length} domains have been deleted.', this.selectedIds.length, { length: this.selectedIds.length });
        this.$toast.show({ text });
      });
    },
    deleteSingleDomain (domain: SsoDomain): void {
      const requestData = {
        companyUuid: this.$appData.session.businessDomainId,
        domainId: domain.id,
      };
      this.authorizedCall(new DeleteSsoDomainRequest(requestData)).then(() => {
        this.load();
        const text = this.$t('Domain {domainName} has been deleted.', { domainName: domain.domainName });
        this.$toast.show({ text });
      });
    },
  },
});

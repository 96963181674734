/**
 * File: rtFeedbackRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtFeedbackRequest extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.kb_link
   * @param {String} params.q
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  /**
   * @returns {String}
   */
  get uri () {
    return '/feedback';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      kb_link: this.params.kb_link,
      q: this.params.q,
    };
  }
}

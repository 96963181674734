/**
 * File: chatModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import Vue from 'vue';

import { FEATURE_RASA_BOT_CHAT, FEATURE_UNIFIED_BOT_CHAT } from '@core/constants/features';
import { SupportType } from '@/modules/support/constants';
import { buildQuery } from '@core/common/url';
import PbcRequest, { Agent } from '@/api/pbcRequest';
import { Environment } from '@/models/initialConfig';
import CreateTicketMixin, { TicketData } from '@/modules/support/content/createTicketMixin';
import ComponentMixIn from '@/modules/componentMixIn';
import SupportChatClient from '@/modules/support/content/chatClient/chatClient.vue';
import { DEFAULT_LANGUAGE } from '@core/constants/langs';
import {
  TICKET_FIELD_LANGUAGE,
  TICKET_FIELD_SUPPORT_TYPE,
  UNIFIED_CHAT_FIELD_COUNTRY,
  UNIFIED_CHAT_FIELD_CUSTOMER_NAME
} from '@/api/rt/constants';

export enum ChatType {
  Beacon,
  Rasa,
  Unified,
}

export type ChatModalParams = {
  supportType: SupportType;
  ticketData: TicketData;
  ticketId?: number;
  preferredChannel?: 'email' | 'chat';
};

export const defaultLanguage = Symbol('default-language');

export const QUEUES = {
  [SupportType.License]: {
    CN: 29,
    TW: 29,
    HK: 29,
    KR: 30,
    JP: 31,

    US: 26,
    CA: 26,
    FR: 26,
    AU: 26,
    GB: 26,
    DE: 26,

    [defaultLanguage]: 28,
  },
  [SupportType.Presales]: {
    CN: 29,
    TW: 29,
    HK: 29,
    KR: 30,
    JP: 31,

    US: 26,
    CA: 26,
    FR: 26,
    AU: 26,
    GB: 26,
    DE: 26,

    [defaultLanguage]: 28,
  },
  [SupportType.Technical]: {
    CN: 29,
    TW: 29,
    HK: 29,
    KR: 30,
    JP: 31,

    US: 25,
    CA: 25,
    FR: 25,
    AU: 25,
    GB: 25,
    DE: 25,

    [defaultLanguage]: 27,
  },
};

export const TEST_QUEUE = 33;

interface IChatModalData {
  params: ChatModalParams | null;
  visible: boolean;
  loading: boolean;
  isBotBad: boolean;
  agent: Agent | null;
  ticketId?: number;
  entrypointIntent?: string;
  ChatType;
  modalTitle: string;
  unifiedBotVisible: boolean;
  unifiedBotFromEmailTimeout?: number;
}

const MODAL_TITLE_CHAT = 'Chat with Support Representative';
const MODAL_TITLE_EMAIL = 'Create Support Request';

const UNIFIED_BOT_TIMEOUT = 15000;

export default Vue.extend({
  name: 'support-chat-modal',
  mixins: [CreateTicketMixin, ComponentMixIn],
  components: { SupportChatClient },
  data (): IChatModalData {
    return {
      params: null,
      visible: false,
      loading: false,
      isBotBad: false,
      agent: null,
      ticketId: null,
      entrypointIntent: '',
      ChatType,
      modalTitle: MODAL_TITLE_CHAT,
      unifiedBotVisible: false,
      unifiedBotFromEmailTimeout: null,
    };
  },
  methods: {
    async show (e, params: ChatModalParams) {
      this.loading = true;
      this.params = params;
      window.addEventListener('message', this.onMessageReceived);
      if (this.isfromEmail) {
        this.visible = true;
        this.modalTitle = MODAL_TITLE_EMAIL;
        this.loading = true;
        this.ticketId = params.ticketId;
        this.unifiedBotFromEmailTimeout = setTimeout(() => {
          this.handleSolutionUnavailable();
        }, UNIFIED_BOT_TIMEOUT) as never as number;
        if (!this.isUnifiedChatBot) {
          this.handleUnifiedChatBotFromEmail({ data: { type: 'SOLUTION_UNAVAILABLE' } });
        }
      } else {
        if (this.chatType === ChatType.Rasa) {
          try {
            const data = await this.getRasaUrl();
            this.agent = data.agent;
            this.entrypointIntent = data.entrypointIntent;
            if (!this.agent || this.entrypointIntent === 'null') {
              throw new Error('No agent/scenario for current request');
            }
          } catch {
            this.isBotBad = true;
            await this.createTicket();
          }
        } else {
          await this.createTicket();
        }
        this.visible = true;
        this.unifiedBotVisible = this.visible;
      }
    },
    onMessageReceived ({ data }) {
      if (this.params.preferredChannel === 'chat') {
        this.handleLegacyChatBots(data);
      } else if (this.ticketId && this.params.preferredChannel === 'email') {
        this.handleUnifiedChatBotFromEmail(data);
      }
    },
    handleLegacyChatBots ({ data }) {
      if (data === 'closed') {
        this.close();
      }
    },
    handleUnifiedChatBotFromEmail ({ data }) {
      if (data.type === 'SOLUTION_AVAILABLE') {
        clearTimeout(this.unifiedBotFromEmailTimeout);
        this.handleSolutionAvailable();
      } else if (data.type === 'SOLUTION_UNAVAILABLE') {
        clearTimeout(this.unifiedBotFromEmailTimeout);
        this.handleSolutionUnavailable();
      }
    },
    handleSolutionAvailable () {
      this.loading = false;
      this.unifiedBotVisible = true;
      this.modalTitle = MODAL_TITLE_CHAT;
    },
    handleSolutionUnavailable () {
      const ticketId = this.ticketId;
      this.$emit('solutionUnavailable', { ticketId });
      this.showToast($t('Thank you! Ticket #{ticketId} was created successfully. Parallels Support Team will contact you soon.', { ticketId }));
      this.close();
    },
    close () {
      window.removeEventListener('message', this.onMessageReceived);
      this.visible = false;
      this.unifiedBotVisible = false;
      this.unifiedBotFromEmailTimeout = null;
      this.params = null;
      this.ticketId = undefined;
      this.$modal.hide();
    },
    onLoad () {
      this.visible = true;
      this.loading = false;
    },
    async onChatBotFailed () {
      this.isBotBad = true;
      this.loading = true;
      try {
        await this.createTicket();
      } finally {
        this.loading = false;
      }
    },
    endChat () {
      this.close();
    },
    async getRasaUrl () {
      const talkUrl = '/pbc/api/talk';

      const request = new PbcRequest({
        talkUrl,
        product: '', // this.$route.params.product,
        supportType: '', // this.$route.params.supportType,
        searchText: this.initialRequest,
        senderEmail: this.$appData.session.email,
      });

      return this.$api.call(request);
    },
    async createTicket () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.ticketId = await this.createOrUpdateTicket(this.params.ticketData);
    },
  },
  computed: {
    chatType (): ChatType {
      if (this.$appData.session.isFeatureAccessible(FEATURE_UNIFIED_BOT_CHAT)) {
        return ChatType.Unified;
      }
      const isEnglishLocale = this.$appData.session.locale.slice(0, 2).toLowerCase() === DEFAULT_LANGUAGE;
      if (this.$appData.session.isFeatureAccessible(FEATURE_RASA_BOT_CHAT) && isEnglishLocale && !this.isBotBad) {
        return ChatType.Rasa;
      }
      return ChatType.Beacon;
    },
    url (): string {
      return {
        [ChatType.Rasa]: this.urlRasa,
        [ChatType.Beacon]: this.urlChatBeacon,
        [ChatType.Unified]: this.urlChatUnified,
      }[this.chatType];
    },
    urlChatBeacon (): string {
      const query = buildQuery(
        ['accountId', 'siteId', 'queueId', 'theme', 'popout', 'pc', 'autosubmitpre', 'ticket_id'],
        {
          accountId: 1,
          siteId: 3,
          queueId: this.chatBeaconQueueId,
          theme: 'blue',
          popout: 'true',
          pc: 'false',
          autosubmitpre: 'true',
          ticket_id: this.ticketId,
        });

      return `https://chatbeacon.corel.com/chatbeacon/corel/3/${query}`;
    },
    urlChatUnified (): string {
      // @ts-ignore
      const language = this.getCurrentLanguage();

      const params = {
        group: 'parallels',
        product: this.params.ticketData.product,
        email: this.$appData.session.email,
        severity: this.params.ticketData.severity,
        summary: `${this.params.ticketData.summary}`.trim(),
        description: this.params.ticketData.description,
        [UNIFIED_CHAT_FIELD_CUSTOMER_NAME]: `${this.$appData.session.firstName} ${this.$appData.session.lastName}`.trim(),
        [TICKET_FIELD_SUPPORT_TYPE]: this.params.ticketData[TICKET_FIELD_SUPPORT_TYPE],
        [UNIFIED_CHAT_FIELD_COUNTRY]: this.$appData.session.country.toLowerCase(),
        [TICKET_FIELD_LANGUAGE]: language,
        ticketId: this.ticketId,
        'preferred-channel': this.params.preferredChannel,
        autosubmit: 1,
      };

      const query = Object.keys(params).reduce((query, key) => {
        const value = params[key];
        return value ? query + (query.length ? '&' : '?') + `${key}=${encodeURIComponent(value)}` : query;
      }, '');
      return `${this.$appData.session.chatbotUrl}${query}`;
    },
    chatBeaconQueueId (): number {
      let queueId = TEST_QUEUE;
      if (this.$appData.config.environment === Environment.Production) {
        const currentLanguage = this.$appData.session.country;
        queueId = QUEUES[this.params.supportType][currentLanguage] || QUEUES[this.params.supportType][defaultLanguage];
      }
      return queueId;
    },
    urlRasa (): string {
      if (!this.agent) {
        return;
      }

      // https://chat-widget-docs.rasa.com/?path=/docs/rasa-chat-widget--widget
      const searchText = this.initialRequest;
      const senderEmail = this.$appData.session.email;

      const params = [
        [
          'data-websocket-url',
          `${this.agent.url}/socket.io`,
        ],
        [
          'data-initial-payload',
          `/greet [agent_id='${this.agent.id}'] [agent_name='${this.agent.name}'] [email='${senderEmail}'] [request='${searchText}']`,
        ],
      ];
      const queryString = '?' + params.map((param) => {
        return param[0] + '=' + encodeURIComponent(param[1]);
      }).join('&');

      return `/static/rasa_chat.html${queryString}`;
    },
    initialRequest (): string {
      return this.params.ticketData.summary.replace(/^\[[^\]]+\]\s+/, '');
    },
    isUnifiedChatBot (): boolean {
      return this.chatType === ChatType.Unified;
    },
    isfromEmail (): boolean {
      return this.params.preferredChannel === 'email';
    },
    isCloseButtonVisible (): boolean {
      return this.unifiedBotVisible;
    },
  },
});

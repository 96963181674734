/**
 * File: getConsentUrlRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { buildQuery } from '@core/common/url';

export type GetConsentUrlRequestParams = {
  locale?: string;
  dryRun?: boolean;
  consentId?: string;
}

// https://wiki.prls.net/x/e9URBQ
export default class GetConsentUrl extends ApiRequest<GetConsentUrlRequestParams> {
  get url (): string {
    const query = buildQuery(['locale', 'dry_run', 'consent_id'], {
      locale: this.params.locale,
      dry_run: Number(this.params.dryRun),
      consent_id: this.params.consentId,
    });
    return `/account/api/v1/consent${query}`;
  }

  get cacheNameSpace () {
    return undefined;
  }
}

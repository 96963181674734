

import Vue, { PropType } from 'vue';
import {
  ApplyAutomaticLicensesRevocationRequest,
  GetAutomaticLicensesRevocationRequest
} from '@/api/automaticLicensesRevocation';
import Subscription from '@/models/subscription';
import { Dictionary } from '@core/common/types';
import { AutomaticLicensesRevocationPeriod } from '@core/constants/subscriptions';

export default Vue.extend({
  name: 'automatic-revocation-tab',
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true,
    },
  },

  data () {
    return {
      automaticLicenseRevocationNewValue: AutomaticLicensesRevocationPeriod.ONE_MONTH,
      automaticLicenseRevocationCurrentValue: null as AutomaticLicensesRevocationPeriod,
      automaticLicenseRevocationLoading: true,
    };
  },

  created () {
    this.load();
    this.$bus.$on('subsetSaved', this.save);
  },

  destroyed () {
    this.$bus.$off('subsetSaved', this.save);
  },

  computed: {
    automaticLicenseRevocationOptions (): Dictionary {
      return {
        [AutomaticLicensesRevocationPeriod.NEVER]: this.$t('Never'),
        [AutomaticLicensesRevocationPeriod.ONE_MONTH]: this.$t('1 Month'),
        [AutomaticLicensesRevocationPeriod.QUARTER]: this.$t('3 Months'),
        [AutomaticLicensesRevocationPeriod.HALF_YEAR]: this.$t('6 Months'),
        [AutomaticLicensesRevocationPeriod.ONE_YEAR]: this.$t('1 Year'),
      };
    },
  },

  methods: {
    reset (): void {
      this.automaticLicenseRevocationCurrentValue = null;
    },
    load (): void {
      this.automaticLicenseRevocationLoading = true;
      this.$api.authorizedCall(new GetAutomaticLicensesRevocationRequest({
        subscriptionUuid: this.subscription.uuid,
      })).then((data) => {
        this.automaticLicenseRevocationCurrentValue = data.per_user_automatic_license_revocation;
        this.automaticLicenseRevocationNewValue = data.per_user_automatic_license_revocation;
      }).finally(() => {
        this.automaticLicenseRevocationLoading = false;
      });
    },
    save (params): void {
      let promise = null;
      if (this.automaticLicenseRevocationNewValue !== this.automaticLicenseRevocationCurrentValue) {
        const request = new ApplyAutomaticLicensesRevocationRequest(
          { subscriptionUuid: params.uuid, period: this.automaticLicenseRevocationNewValue }
        );

        promise = this.$api.authorizedCall(request);
      } else {
        promise = Promise.resolve({});
      }
      this.$emit('save', promise);
    },
  },
});




import Vue, { PropType } from 'vue';
import { WeekDay } from '@core/common/datetime';
import { toNumeral } from '@core/common/utils';
import { ScheduleRule, ScheduleRuleType } from '@/api/resourceUsageNotificationUpdateRequest';

const DAYS_IN_MONTH = 31;
export const LAST_DAY_OF_MONTH = DAYS_IN_MONTH + 1;

export default Vue.extend({
  name: 'schedule-rule',

  props: {
    value: Object as PropType<ScheduleRule>,
  },

  data () {
    return {
      LAST_DAY_OF_MONTH,
      WeekDay,
      ScheduleRuleType,
      val: {
        type: this.value.type,
        weeklyValue: this.value.type === ScheduleRuleType.Weekly ? this.value.value : WeekDay.Monday,
        monthlyValue: this.value.type === ScheduleRuleType.Monthly ? this.value.value : 1,
      },
    };
  },

  computed: {
    monthlyString (): string {
      if (this.val.monthlyValue === LAST_DAY_OF_MONTH) {
        return this.$t('Monthly: on the last day of a month');
      } else {
        return this.$t('Monthly: on the {dayInMonth} day', {
          dayInMonth: toNumeral(this.val.monthlyValue, this.$appData.session.language),
        });
      }
    },
  },

  methods: {
    update ({ type, value }: ScheduleRule) {
      this.val.type = type;
      switch (this.val.type) {
        case ScheduleRuleType.Weekly:
          this.val.weeklyValue = value;
          break;
        case ScheduleRuleType.Monthly:
          this.val.monthlyValue = value;
          break;
      }
    },
    emit () {
      let value = null;
      switch (this.val.type) {
        case ScheduleRuleType.Monthly:
          value = this.val.monthlyValue;
          break;
        case ScheduleRuleType.Weekly:
          value = this.val.weeklyValue;
          break;
      }
      this.$emit('input', { type: this.val.type, value });
    },
  },

  watch: {
    'value' (value) {
      this.update(value);
    },
    'value.type' () {
      this.update(this.value);
    },
    'value.value' () {
      this.update(this.value);
    },
    'val' () {
      this.emit();
    },
    'val.type' () {
      this.emit();
    },
    'val.weeklyValue' () {
      this.emit();
    },
    'val.monthlyValue' () {
      this.emit();
    },
  },
});


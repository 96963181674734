/**
 * File: extendSubscriptionRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequestWithFallback from '@core/api/apiRequestWithFallback';
import Subscription from '@/models/subscription';
import Cache from '@core/api/cache';
import { HTTP_CODES } from '@core/constants/http';

export default class ExtendSubscriptionRequest extends ApiRequestWithFallback {
  get method () {
    return 'POST';
  }

  get urlSuffix () {
    return '/api/v1/subscriptions/extend';
  }

  needFallback (response, data) {
    return (response.status === HTTP_CODES.CONFLICT && data.msg === 'invalid license key');
  }

  get body () {
    return {
      lic_key: this.params.licKey,
      subscription_uuid: this.params.uuid,
    };
  }

  // PD product may not be the first in the licensedProducts list
  sortProductsPdFirst (subscription) {
    subscription.licensedProducts.sort((a, b) => {
      return (a.keyName.substr(0, 5) === 'pdfm_' || a.keyName.substr(0, 4) === 'pdb_') ? -1 : 1;
    });
    return subscription;
  }

  // First type of request - check if extension options available
  // Suppress all errors
  getExtensionOptionsPromise () {
    return super.getPromise()
      .then((data) => {
        if (data.subscription) {
          data.subscription = new Subscription(this.sortProductsPdFirst(data.subscription));
        }
        if (data.available_for_extend) {
          data.available_for_extend = data.available_for_extend.map((sub) => {
            return new Subscription(this.sortProductsPdFirst(sub));
          });
        }
        return data;
      })
      .catch(() => {
        // Any error means there are no options for extension
        return {
          available_for_extend: [],
          subscription: null,
        };
      });
  }

  // Second type of request - extend existing subscription with new license key
  getExtendPromise () {
    return super.getPromise()
      .then((data) => {
        if (data.subscription) {
          data.subscription = new Subscription(this.sortProductsPdFirst(data.subscription));
        }
        const cache = new Cache();
        cache.dropNameSpace('subscriptions');
        return data;
      });
  }

  getPromise () {
    return this.params.uuid ? this.getExtendPromise() : this.getExtensionOptionsPromise();
  }
}

/**
 * File: businessProfileIdPIntegration.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';
import {
  GetSsoSettingsRequest,
  UpdateSsoSettingsRequest,
  PrlGroupsInterface,
  GroupMappingInterface
} from '@/api/sso/ssoSettingsRequest';
import StepConfigureOrganizationsDomains from './stepConfigureOrganizationsDomains.vue';
import StepRegisterParallelsEnterpriseApp from './stepRegisterParallelsEnterpriseApp.vue';
import StepConfigureUserGroupsMapping from './stepConfigureUserGroupsMapping.vue';
import StepConfigureSamlIntegration from './stepConfigureSamlIntegration.vue';
import StepConfigureScimIntegration from './stepConfigureScimIntegration.vue';
import StepAddUsersToTheApplicationGroups from './stepAddUsersToTheApplicationGroups.vue';
import StepConfigureBackupLogin from './stepConfigureBackupLogin.vue';
import { SSO_SETTINGS_STATUS, SSO_STEP_NAME } from '@/modules/businessProfile/businessProfileIdPIntegration/constants';

function assignSettings (dst, src) {
  if (src.hasOwnProperty('stepsDone') && dst.hasOwnProperty('stepsDone')) {
    Object.keys(src.stepsDone).forEach((k) => {
      dst.stepsDone[k] = src.stepsDone[k];
    });
  }

  delete src.stepsDone;

  Object.assign(dst, src);
}

export default Vue.extend({
  name: 'business-profile-idp-integration',
  components: {
    StepConfigureOrganizationsDomains,
    StepRegisterParallelsEnterpriseApp,
    StepConfigureUserGroupsMapping,
    StepConfigureSamlIntegration,
    StepConfigureScimIntegration,
    StepAddUsersToTheApplicationGroups,
    StepConfigureBackupLogin,
  },
  data () {
    return {
      loading: false,
      settings: {
        status: SSO_SETTINGS_STATUS.DISABLED,
        verificationCode: '',
        entityId: '',
        ssoUrl: '',
        certificate: '',
        spEntityId: '', // Service Provider Entity ID
        spAcsUrl: '', // Assertion Consumer Service URL
        scimUrl: '',
        bearerToken: '',
        prlGroups: [] as PrlGroupsInterface[],
        groupMapping: [] as GroupMappingInterface[],
        backupLogin: null as number,
        stepsDone: {
          [SSO_STEP_NAME.DOMAIN]: false,
          [SSO_STEP_NAME.APPS]: false,
          [SSO_STEP_NAME.MAPPING]: false,
          [SSO_STEP_NAME.SAML]: false,
          [SSO_STEP_NAME.SCIM]: null,
          [SSO_STEP_NAME.USERS]: false,
          [SSO_STEP_NAME.BACKUP]: false,
        },
      },
      admins: [],
      SSO_SETTINGS_STATUS,
      total: Object.keys(SSO_STEP_NAME).length,
      SSO_STEP_NAME,
      hasProblem: false,
      step: this.$route.query.step,
    };
  },
  mounted () {
    this.load();
  },
  methods: {
    async load (reload = false) {
      const getSsoSettingsRequest = new GetSsoSettingsRequest({
        domainId: this.$appData.session.businessDomainId,
      });
      if (reload) {
        getSsoSettingsRequest.dropFullCache();
      }
      this.loading = true;
      try {
        const settings = await this.$api.authorizedCall(getSsoSettingsRequest);
        assignSettings(this.settings, settings);
      } finally {
        this.loading = false;
      }
    },
    async update (silently: boolean = false, onlyStatus?: boolean): Promise<void> {
      const params = {
        domainId: this.$appData.session.businessDomainId,
        settings: { status: this.settings.status },
      };

      if (!onlyStatus) {
        Object.assign(params.settings, this.settings);
      }

      this.loading = silently !== true;

      const updateSsoSettingsRequest = new UpdateSsoSettingsRequest(params);
      try {
        const settings = await this.$api.authorizedCall(updateSsoSettingsRequest);
        if (this.settings.verificationCode.length < 1) {
          // assign new verification code
          assignSettings(this.settings, settings);
        }
        this.$toast.show({
          text: this.$t('Configuration updated.'),
          color: 'green',
        });
      } catch (err) {
        throw new Error();
      } finally {
        this.loading = false;
      }
    },
    showError () {
      this.$toast.show({
        text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
        color: 'red',
      });
    },
    async updateStatus (status) {
      const oldVal = status;
      this.settings.status = status;
      try {
        await this.update(false, status);
      } catch (err) {
        this.showError();
        this.settings.status = oldVal;
      }
    },
    async onSaveEvent (stepName, data) {
      Object.assign(this.settings, data);

      try {
        await this.update(true);
      } catch (err) {
        this.showError();
      } finally {
        // @ts-ignore
        this.$refs[stepName].loading = false;
      }
    },
    async stepDone (stepName, val) {
      const oldVal = this.settings.stepsDone[stepName];
      this.settings.stepsDone[stepName] = val;
      try {
        await this.update(true);
      } catch (err) {
        this.showError();
        this.settings.stepsDone[stepName] = oldVal;
      } finally {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs[stepName].loading = false;
      }
    },
    onDomainsLoaded (domains) {
      if (domains.some((domain) => domain.isVerified === true)) {
        this.settings.stepsDone[SSO_STEP_NAME.DOMAIN] = true;
      }

      if (domains.some((domain) => domain.isFailed === true)) {
        this.hasProblem = true;
      }
    },
  },
  computed: {
    idpOptions (): { value: boolean; text: string }[] {
      return [
        { value: false, text: this.$t('Disabled') },
        { value: true, text: this.$t('Enabled') },
      ];
    },
    configured (): number {
      let done = 0;
      for (const [key, val] of Object.entries(this.settings.stepsDone)) {
        if (key === SSO_STEP_NAME.SCIM) {
          if (val !== null) {
            done++;
          }
        } else if (val) {
          done++;
        }
      }
      return done;
    },
    remaining (): number {
      return this.total - this.configured;
    },
  },
});

/**
 * File: getConfirmAccountDeleteRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class GetConfirmAccountDeleteRequest extends ApiRequest {
  get url () {
    return `/account/webapp/confirm_account_delete/${this.params.code}`;
  }
}


import Vue from 'vue';
import { BUSINESS_PROFILE_SCOPE, SSO_USERS_PAGE } from '@/routes/constants';

import BusinessProfileSSOMemberInfo from './businessProfileSSOMemberInfo.vue';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'business-profile-sso-member-details-page',
  mixins: [BreadcrumbsMixin],
  components: { BusinessProfileSSOMemberInfo, Breadcrumbs }, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846

  props: {
    memberId: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      BUSINESS_PROFILE_SCOPE,
      SSO_USERS_PAGE,
    };
  },
});

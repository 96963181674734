
import Vue, { PropType } from 'vue';

export type IContractDetails = {
  contractNumber: string;
  accountName: string;
  status: string;
  contractStartDate: Date;
  contractExpirationDate: Date;
  contractType: string;
}

export default Vue.extend({
  name: 'generalTab',

  props: {
    contractDetails: {
      type: Object as PropType<IContractDetails>,
    },
  },
});


import ProductCardMixIn from './productCardMixIn';
import { PRODUCT_NAME_RAS_AZMP } from '@core/constants/subscriptions';
import {
  RAS_AZMP_MANAGED_APPS,
  RAS_AZMP_SUBPAGE_SUBSCRIPTIONS
} from '@/routes/constants';
import Vue, { PropType } from 'vue';
import Subscription, { TYPE_ALL, TYPE_SPLA, TYPE_SUBSCRIPTION } from '@/models/subscription';
import AzmpManagedApplications from '@/apiv2/azmpManagedApplications';

export default Vue.extend({
  name: 'product-card-ras-azmp',
  mixins: [ProductCardMixIn],
  props: {
    subscriptions: {
      type: Array as PropType<Subscription[]>,
      default: null,
    },
  },
  data () {
    return {
      RAS_AZMP_SUBPAGE_SUBSCRIPTIONS,
      RAS_AZMP_MANAGED_APPS,
      PRODUCT_NAME_RAS_AZMP,
      TYPE_SUBSCRIPTION,
      TYPE_SPLA,
      TYPE_ALL,
      managedApplicationsCount: 0,
    };
  },
  async mounted () {
    const request = new AzmpManagedApplications();
    const result = await this.$api.authorizedCall(request);
    this.managedApplicationsCount = result.count;
  },
  computed: {
    isBusinessAdmin (): boolean {
      return this.$appData.session.isBusinessAdmin;
    },
  },
});


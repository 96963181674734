import { render, staticRenderFns } from "./item.pug?vue&type=template&id=97e5ab9e&lang=pug&external"
import script from "./item.ts?vue&type=script&lang=ts&external"
export * from "./item.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./item.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports
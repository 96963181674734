/**
 * File: proxyServersTable.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import componentMixin from '@/modules/componentMixIn';
import columnsObserverMixin from '@/modules/columnsObserverMixin';
import { sortByDate } from '@core/common/sorters';
import CsvSaver from '@/ui/csvSaver';
import PmmDeactivateSitesRequest from '@/api/pmmDeactivateSitesRequest';

export default {
  name: 'proxy-servers-table',

  mixins: [columnsObserverMixin, componentMixin],

  components: {
    CsvSaver,
  },

  props: {
    servers: {
      type: Array,
    },
  },

  data () {
    return {
      table: {
        name: 'pmmServers',
        selectedIds: [],
        permanentColumns: ['Id'],
        columns: [
          'Name', 'MacLicKey', 'MdmLicKey', 'SiteCode', 'OfflineUse', 'AppVersion',
          'MacActivationDate', 'MdmActivationDate',
        ],
        options: {
          sortable: [
            'MacLicKey', 'MdmLicKey', 'Name', 'SiteCode', 'SiteDescription', 'OfflineUse', 'AppVersion',
            'MacActivationDate', 'MdmActivationDate', 'LastReportDate', 'MacLimit', 'MacUsage',
            'MdmLimit', 'MdmUsage', 'Notes',
          ],
          uniqueKey: 'Id',
          customSorting: {
            MacActivationDate (asc) {
              return (a, b) => sortByDate(a.server.macActivationDate, b.server.macActivationDate, asc);
            },
            MdmActivationDate (asc) {
              return (a, b) => sortByDate(a.server.mdmActivationDate, b.server.mdmActivationDate, asc);
            },
            LastReportDate (asc) {
              return (a, b) => sortByDate(a.server.lastReportDate, b.server.lastReportDate, asc);
            },
          },
        },
      },
      columnsOptions: [
        { text: $t('Server Name'), value: 'Name' },
        { text: $t('License Key (Mac Management)'), value: 'MacLicKey' },
        { text: $t('License Key (MDM)'), value: 'MdmLicKey' },
        { text: $t('Site Code'), value: 'SiteCode' },
        { text: $t('Site Description'), value: 'SiteDescription' },
        { text: $t('Offline Use'), value: 'OfflineUse' },
        { text: $t('App Version'), value: 'AppVersion' },
        { text: $t('Mac Mgmt. Activation Date'), value: 'MacActivationDate' },
        { text: $t('MDM Activation Date'), value: 'MdmActivationDate' },
        { text: $t('Last Report Date'), value: 'LastReportDate' },
        { text: $t('Max. Managed Computers'), value: 'MacLimit' },
        { text: $t('Registered Computers'), value: 'MacUsage' },
        { text: $t('Max. Managed Mobile Devices'), value: 'MdmLimit' },
        { text: $t('Registered Mobile Devices'), value: 'MdmUsage' },
        { text: $t('Notes'), value: 'Notes' },
      ],
      action: '',
    };
  },

  methods: {
    getSelectedData (filter) {
      return this.tableData.filter((row) => this.table.selectedIds.indexOf(row.Id) !== -1 && filter(row));
    },
    callAction (name) {
      const action = this.getActions().find((action) => action.name === name);
      if (action) {
        action.callback(this.getSelectedData(action.filter));
        this.$nextTick(() => {
          this.action = null;
        });
      }
    },
    exportCsv (rows) {
      const columns = this.table.columns.map((value) => {
        return this.columnsOptions.find((column) => column.value === value) || value;
      });
      this.$refs.csv.save({
        columns,
        rows,
      });
    },
    getActions () {
      return [
        {
          name: 'deactivate',
          text: this.$t('Deactivate ({count})'),
          filter: (row) => row.MacLicKey || row.MdmLicKey,
          callback: () => this.$refs.confirmDeactivationModal.show(),
        },
        {
          name: 'save_csv',
          text: this.$t('Save List to CSV File ({count})'),
          filter: (row) => true,
          callback: this.exportCsv.bind(this),
        },
      ];
    },
    onServerClick (server) {
      this.$emit('serverClick', server);
    },
    deactivateProxyServers () {
      const request = new PmmDeactivateSitesRequest({ uuids: this.table.selectedIds });
      this.authorizedCall(request, 'actionLoading').then(() => {
        this.$emit('hostsDeactivated', this.table.selectedIds);
        this.showToast($tc('{amount} proxy servers deactivated.', this.table.selectedIds.length, { amount: this.table.selectedIds.length }));
      });
    },
  },

  computed: {

    tableColumns () {
      return this.table.permanentColumns.concat(this.table.columns);
    },

    tableData () {
      const data = [];
      this.servers.forEach((s) => {
        data.push({
          Id: s.uuid,
          Name: s.name,
          MacLicKey: s.macLicKey,
          MdmLicKey: s.mdmLicKey,
          SiteCode: s.code,
          SiteDescription: s.description,
          OfflineUse: s.macOfflineAllowed ? this.$t('Allowed') : this.$t('Not Allowed'),
          AppVersion: s.appVersion,
          MacActivationDate: this.formatDate(s.macActivationDate),
          MdmActivationDate: this.formatDate(s.mdmActivationDate),
          LastReportDate: this.formatDate(s.lastReportDate),
          MacUsage: s.macUsage || 0,
          MacLimit: s.macLimit || 0,
          MdmUsage: s.mdmUsage || 0,
          MdmLimit: s.mdmLimit || 0,
          Notes: s.notes,
          server: s,
        });
      });
      return data;
    },

    selectAll: {
      get () {
        return this.table.selectedIds.length === this.servers.length;
      },

      set (v) {
        // TODO: implement tri-state
        this.table.selectedIds = v ? this.servers.map((s) => s.uuid) : [];
      },
    },

    actionOptions () {
      return this.getActions().map((action) => {
        const selectedServers = this.getSelectedData(action.filter);

        if (action.autohide && !selectedServers.length) {
          return null;
        }
        return {
          text: action.text.replace('{count}', selectedServers.length),
          value: action.name,
          disabled: !selectedServers.length,
        };
      }).filter((v) => v);
    },
  },

  watch: {
    //
    //  Clear selected items if it is not present in the table
    //
    servers (servers) {
      this.table.selectedIds = this.table.selectedIds.filter((uuid) => {
        return servers.some(server => server.uuid === uuid);
      });
    },
  },

};

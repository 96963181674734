

import Vue from 'vue';
import ResendAccountConfirmation from '@/api/resendAccountConfirmationRequest';
import RtTicketsRequest from '@/api/rt/rtTicketsRequest';
import RtCreateActivationTicketRequest from '@/api/rt/rtCreateActivationTicketRequest';
import ContactSupportModal from '@/modules/contactSupportModal.vue';
import { email, maxLength, required } from '@core/common/validators';

export enum State {
  ask,
  support,
  supportTicketSubmitted
}

const MAX_DESCRIPTION_LENGTH = 255;
const TICKET_TIMEOUT = 1000 * 60 * 60 * 24; // 1 day
const SHOW_TICKET_LINK_TIMEOUT = 1000 * 10;

export default Vue.extend({
  name: 'ask-confirm-account',

  components: {
    ContactSupportModal,
  },

  validations: {
    supportForm: {
      description: {
        required,
        maxLength: maxLength(MAX_DESCRIPTION_LENGTH),
      },
      email,
    },
  },

  data () {
    return {
      stateEnum: State,
      maxDescriptionLength: MAX_DESCRIPTION_LENGTH,
      isTicketAlreadyOpen: false,
      loadingSetup: true,
      loadingAsk: false,
      loadingSupport: false,
      loadingSupportTickets: false,
      state: State.ask,
      accountEmail: '',
      expandedConfirmationTips: false,
      showSupportTicketLink: false,
      showSupportTicketLinkTimeout: null,
      supportForm: {
        email: '',
        description: '',
      },
    };
  },

  async created () {
    this.loadingSetup = true;
    await this.$api.refreshToken();
    this.supportForm.email = this.accountEmail = this.$appData.session.email;

    this.isTicketAlreadyOpen = false;
    this.loadingSetup = false;
  },

  beforeDestroy () {
    this.showSupportTicketLinkTimeout && clearTimeout(this.showSupportTicketLinkTimeout);
  },

  methods: {
    async logout () {
      this.loadingAsk = true;
      try {
        await this.$api.logout();
      } finally {
        this.loadingAsk = false;
      }
    },

    async resend () {
      this.loadingAsk = true;
      try {
        await this.$api.authorizedCall(new ResendAccountConfirmation());

        this.$toast.show({
          color: 'green',
          text: this.$t('Confirmation email has been sent to the account email address.'),
        });
      } finally {
        this.loadingAsk = false;
      }

      this.showSupportTicketLinkTimeout = setTimeout(() => {
        this.showSupportTicketLink = true;
        this.showSupportTicketLinkTimeout = null;
      }, SHOW_TICKET_LINK_TIMEOUT);
    },

    async proceed () {
      this.loadingAsk = true;
      try {
        await this.$api.refreshToken(true);

        if (this.$appData.session.isSessionConfirmed()) {
          await this.$router.push({ name: 'index' });
        }
      } finally {
        this.loadingAsk = false;
      }
    },

    async supportClick () {
      if (!this.isTicketAlreadyOpen) {
        this.state = State.support;
        await this.loadTickets();
      }
      this.state = this.isTicketAlreadyOpen ? State.supportTicketSubmitted : State.support;
    },

    async proceedSupport () {
      this.loadingSupport = true;

      try {
        await this.$api.callRt(new RtCreateActivationTicketRequest({
          email: this.supportForm.email,
          description: this.supportForm.description,
          name: this.$appData.session.name,
          country: this.$appData.session.country,
        }));
        this.isTicketAlreadyOpen = true;
        this.state = State.supportTicketSubmitted;
      } finally {
        this.loadingSupport = false;
      }
    },

    async loadTickets () {
      this.loadingSupportTickets = true;

      const now = new Date().getTime();

      try {
        const tickets = await this.$api.callRt(new RtTicketsRequest());
        this.isTicketAlreadyOpen = tickets.tickets.some((ticket) => ticket.activation_request && (now - Date.parse(ticket.fields.created)) < TICKET_TIMEOUT);
      } finally {
        this.loadingSupportTickets = false;
      }
    },
  },
});


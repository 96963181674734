
import Vue from 'vue';

import ErrorPage from '@core/pages/error/index.vue';

import { PERSONAL_PROFILE_SCOPE, SECURITY_PAGE } from '@/routes/constants';

export default Vue.extend({
  name: 'force-auth-app-mfa-page',
  components: {
    ErrorPage,
  },
  data () {
    return {
      PERSONAL_PROFILE_SCOPE,
      SECURITY_PAGE,
    };
  },
});


/**
 * File: renewMixin.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import SubscriptionRequest from '@/api/subscriptionRequest';

export default {
  name: 'renew-mixin',

  data () {
    return {
      subscription: null,
      renewOptions: null,
      durationValue: null,
      amount: 0,
    };
  },

  computed: {

    selectedOption () {
      return this.durationValue && this.renewOptions.find((option) => option.uuid === this.durationValue);
    },

    nextBillingDate () {
      return this.selectedOption && this.selectedOption.next_billing_at;
    },

    subscriptionDuration () {
      return this.subscription && this.getDurationString(this.subscription.durationValue, this.subscription.durationUnit);
    },

  },

  methods: {

    async load () {
      this.loading = true;
      const subscriptionsRequest = new SubscriptionRequest({ uuid: this.id });

      return this.authorizedCall(subscriptionsRequest).then(() => {
        this.subscription = subscriptionsRequest.getSubscription();
        if (this.subscription) {
          this.amount = Number(this.subscription.firstProductLimitThisPeriod);
          this.authorizedCall(this.getOptionsRequest()).then((optionsData) => {
            this.renewOptions = optionsData.options;
            if (this.renewOptions.length) {
              this.durationValue = this.renewOptions[0].uuid;
            }
          });
        }
      });
    },

    proceedToCheckout () {
      this.authorizedCall(this.getCheckoutUrlRequest()).then((data) => {
        this.loading = true;
        window.location.href = data.url;
      });
    },

    getDurationString (value, unit) {
      // $tc('{value} days')
      // $tc('{value} months')
      // $tc('{value} years')
      const text = `{value} ${unit}`;
      return $tc(text, value, { value });
    },

  },

};

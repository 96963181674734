<copyright>
File: index.vue

Copyright:
Copyright © 2018 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import { Icon } from '@parallels/ui-kit';
import AdjustWidthToFitMixin from '../adjustWidthToFit/mixin';

export default {
  name: 'breadcrumbs',
  components: {
    Icon,
  },
  props: {
    tiny: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      itemSelector: '.item',
    };
  },
  watch: {
    $route () {
      setTimeout(() => this.adjustWidthToFit());
    },
  },
  mixins: [AdjustWidthToFitMixin],
};
</script>

<template lang="pug">

.breadcrumbs(v-if="Object.keys($slots).length", :class="{ tiny }")
  .item(v-for="(name, index) in Object.keys($slots)")
    icon.margin-right.hidden-xs(v-if="index" name="arrow-right-gray")
    slot(v-if="$slots[name]", :name="name")

</template>

<style scoped lang="sass">

.breadcrumbs
  padding: $grid-step 0
  white-space: nowrap
  display: flex

  &.tiny
    padding: 0

.item
  display: inline-block
  white-space: nowrap
  text-overflow: ellipsis
  +phone
    &:not(:last-child)
      display: none

  & + &
    margin-left: $grid-step
    +phone
      margin-left: 0

.item /deep/ a
  text-decoration: none
</style>

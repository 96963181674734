/**
 * File: companyLeaveRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class CompanyLeaveRequest extends ApiRequest {
  constructor (params, companyUuid) {
    super(params);
    this.company_uuid = companyUuid;
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/leave_domain/' + this.company_uuid;
  }

  get errors () {
    return {
      'leaving prohibited': $t('You can`t leave the business account since you`re the last admin of the account'),
      'not member': $t('You are not invited to this account'),
    };
  }
}

/**
 * File: rasDismissBannerRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Method, Json } from '@core/api/apiRequest';

export default class RasDismissBannerRequest extends ApiRequest {
  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      subscription_uuid: this.params.subscriptionUuid,
    };
  }

  get url (): string {
    return '/ras/webapp/banners';
  }
}

/**
 * File: inviteAcceptErrors.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

export default () => ({
  'not member': $t('You are not invited to this account'),
  'Can\'t join to another business account': $t('You can’t join the business account as you are already a member of another business account.'),
});

/**
 * File: dismissInvitationRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class DismissInvitationRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.password
   * @param {Number} domainId
   */
  constructor (domainId) {
    super({});
    this.domainId = domainId;
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/dismiss_invite/' + this.domainId;
  }

  get body () {
    return {};
  }
}

/**
 * File: contractRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';
import Service from '@core/constants/services';

type ContractRequestParams = {
  service: Service;
  uuid: string;
}

export type ContractResponseData = {
  contractDetails?: {
    contractNumber: number;
    accountName: string;
    status: string;
    contractStartDate: Date;
    contractExpirationDate: Date;
    contractType: string;
  };
  salesRepresentative?: {
      name: string;
      title: string;
      email: string;
      phone: string;
      mailto?: string;
  };
  downloadLink?: string;
}

export default class ContractRequest extends ApiRequest<ContractRequestParams, ContractResponseData> {
  get cacheNameSpace (): string {
    return 'contract';
  }

  get url (): string {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/sf/contract`);
  }
}

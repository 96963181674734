/**
 * File: date.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { DEFAULT_LOCALE } from '../../constants/geo';

export const DATE_OPTIONS: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
export const DATE_OPTIONS_SHORT: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', weekday: 'short' };

export const TIME_OPTIONS: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

// Some date strings contains hidden unicode symbols to reorder text flow
// Right to left and left to right marks
const RLM_LRM_REGEXP = /[\u200E\u200F]+/g;

// 2020-01-01T10:23:15.23Z
const ISO_DATE_REGEXP = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;
// 2020-01-01 10:23:23
const SQL_DATE_REGEXP = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
// 2020-01-01
const DATE_REGEXP = /^\d{4}-\d{2}-\d{2}$/;

const ALLOWED_DATE_FORMATS = [ISO_DATE_REGEXP, SQL_DATE_REGEXP, DATE_REGEXP];

export const isDateString = function (value: string): boolean {
  if (!value || value.constructor !== String) {
    return false;
  }
  return ALLOWED_DATE_FORMATS.some((regexp) => value.match(regexp));
};

export const toDateObject = function (value): Date | undefined {
  if (!value || value instanceof Date) {
    return value;
  }

  if (isDateString(value)) {
    if (value.match(SQL_DATE_REGEXP)) {
      return new Date(value.replace(/\s/, 'T').concat('Z'));
    }
    return new Date(value);
  }

  return new Date(value);
};

export const date = function (dateValue: string | Date, locale = DEFAULT_LOCALE, time: boolean, short = false, utc = false): string {
  let options: any = Object.assign({}, short ? DATE_OPTIONS_SHORT : DATE_OPTIONS);
  let dateObject = toDateObject(dateValue);
  if (!dateObject) {
    return '';
  }
  if (time) {
    Object.assign(options, TIME_OPTIONS);
  }
  if (utc) {
    options.timeZone = 'UTC';
  }

  return dateObject.toLocaleDateString(locale, options).replace(RLM_LRM_REGEXP, '');
};

export const time = function (date: string | Date, locale = 'en-US'): string {
  date = toDateObject(date);
  if (!date) {
    return '';
  }
  return date.toLocaleTimeString(locale, TIME_OPTIONS).replace(RLM_LRM_REGEXP, '');
};

// Converts Date object to 'yyyy-mm-dd' format, suitable for input[type=date]
export const dateValue = function (date: Date | string): string {
  let dateObject = toDateObject(date);
  if (!dateObject) {
    return '';
  }
  return dateObject.toISOString().split('T')[0];
};

export const addDays = function (date: Date, days: number): Date {
  if (!(date instanceof Date)) {
    throw Error('Argument "date" is not instance of Date');
  }
  date.setDate(date.getDate() + days);
  return date;
};

export const toSqlDateTimeString = function (dateObj: Date | string): string {
  let dateObject: Date = toDateObject(dateObj);
  if (!dateObject) {
    return '';
  }

  const date = dateObject.toISOString().split('T')[0];
  const time = dateObject.toTimeString().split(' ')[0];
  return `${date} ${time}`;
};

/**
 * File: allProductsPerUserRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { licenseService } from '@core/constants/links';

interface AllProductsPerUserRequestParams {
  domainIds?: string[];
}

interface AllProductsPerUserRequestData {
  personal: { [key: string]: string[] };
  business: { [key: string]: string[] };
}

export default class AllProductsPerUserRequest extends ApiRequest<AllProductsPerUserRequestParams, AllProductsPerUserRequestData> {
  get url () {
    const domainIds = this.params.domainIds || [];
    const queryString = this.buildQueryString({
      domainIds: domainIds.join(','),
    });

    return `${licenseService.ALL_PRODUCTS_PER_USER}${queryString}`;
  }

  get cacheNameSpace () {
    return 'subscriptions';
  }
};

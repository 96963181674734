

import { defineComponent } from 'vue';
import { HeaderView } from '@parallels/ui-kit';
import MainMenu from '@/ui/mainMenu.vue';
import consent from '@/data/consent';
import Session from '@/models/session';

export default defineComponent({
  name: 'header-view-my',
  components: {
    HeaderView,
    MainMenu,
  },
  computed: {
    session (): Session {
      return this.$appData.session || {};
    },
    products (): Products {
      return this.$appData.products;
    },
    displayMainMenu (): boolean {
      return this.session.authenticated && this.session.isSessionConfirmed() && this.products && !consent.required;
    },
  },
  mounted () {
    // TODO: remove after https://parallels.atlassian.net/browse/CPCLOUD-19372
    try {
      const logo = document.getElementsByClassName('logo')[0];
      logo.setAttribute('data-qa', 'parallels-logo');
      const title = document.getElementsByClassName('title')[0];
      title.setAttribute('data-qa', 'my-account-title');
      const profileIcon = document.getElementsByClassName('profile-icon')[0];
      if (profileIcon) {
        profileIcon.setAttribute('data-qa', 'profile-icon');
      }
      const menuItems = document.getElementsByClassName('main-menu')[0]?.children;
      if (menuItems) {
        for (const item of menuItems) {
          item.setAttribute('data-qa', item.getAttribute('data-title').toLowerCase().replace(/ /g, '-'));
        }
      }
    } catch (error) {
    }
  },
});


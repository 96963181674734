/**
 * File: confirmAccountDeleteRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class ConfirmAccountDeleteRequest extends ApiRequest {
  get url () {
    return `/account/webapp/confirm_account_delete/${this.params.code}`;
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      keep_forum_posts: this.params.keepForumPosts,
    };
  }
}


import ProductCardMixIn from './productCardMixIn';
import { PRODUCT_KEY_NAME_PBI, PRODUCT_NAME_PBI } from '@core/constants/subscriptions';
import { PBI_DOWNLOADS_PAGE, PBI_SUBPAGE_SUBSCRIPTIONS, GO_PAGE } from '@/routes/constants';
import Vue from 'vue';
import { TYPE_ALL, TYPE_SPLA, TYPE_SUBSCRIPTION } from '@/models/subscription';

export default Vue.extend({
  name: 'product-card-pbi',
  mixins: [ProductCardMixIn],
  props: ['subscriptions'],
  data () {
    return {
      PBI_DOWNLOADS_PAGE,
      PBI_SUBPAGE_SUBSCRIPTIONS,
      PRODUCT_NAME_PBI,
      TYPE_SUBSCRIPTION,
      TYPE_SPLA,
      TYPE_ALL,
      GO_PAGE,
      PRODUCT_KEY_NAME_PBI,
    };
  },
  computed: {
    isBusinessAdmin (): boolean {
      return this.$appData.session.isBusinessAdmin;
    },
    pbiPortalLink (): string {
      return this.$appData.session.pbiUserPortalUrl;
    },
  },
});


/**
 * File: companyUsersInviteRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import CompanyUsersRequest from './companyUsersRequest';

export default class CompanyUsersInviteRequest extends ApiRequest {
  constructor (params, companyUuid) {
    super(params);
    this.company_uuid = companyUuid;
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/ba/' + this.company_uuid + '/users/invite';
  }

  get body () {
    return Object.assign({}, this.params);
  }

  load () {
    return super.load()
      .then((data) => {
        (new CompanyUsersRequest({}, this.company_uuid)).dropCache();
        return data;
      });
  }
}

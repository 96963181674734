<copyright>
File: paymentOptionsModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'payment-options-modal',
  data () {
    return {
      url: '',
      fromModal: '',
    };
  },
  methods: {
    clickHandler (props) {
      this.url = props.url;
      this.fromModal = props.fromModal;
      this.$modal.show('paymentOptionsModal');
    },
    proceedHandler () {
      this.cancelHandler();
      this.$emit('proceed');
    },
    cancelHandler () {
      this.$modal.hide('paymentOptionsModal');
      if (this.fromModal) {
        this.$modal.show(this.fromModal);
      }
    },
  },
};

</script>

<template lang="pug">
  modal(name="paymentOptionsModal", size="auto", @linkClick="clickHandler", :data-name="$name()")
    template(slot="header")
      | {{ $t('Configuring Subscription Payment Options') }}
    template(slot="content")
      .margin-bottom-3x
        .row
          .col-xs-12
            | {{ $t('For security reasons, you’ll be redirected to a separate page which opens in a new tab.') }}
    template(slot="footer")
      btn.pull-right.margin-left(
        v-if="url",
        :href="url",
        @click="proceedHandler",
        target="_blank",
        :data-name="$name('button-cancel')"
      ) {{ $t('Proceed to Payment Options') }}
      btn(
        color="white",
        @click="cancelHandler",
        :data-name="$name('button-configure')"
      ) {{ $t('Cancel') }}
</template>

<copyright>
  File: faqContent.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<style scoped lang="sass">

  .content
    white-space: pre-line

</style>

<template lang="pug">

  div(v-html="Markdown.parse(text)")

</template>

<script>
import Markdown from 'markdown';

const kbHrefReg = /kb\.parallels\.com\/(\w+\/)?(\d+)\/?/i;

export default {
  name: 'support-ticket-content',
  props: {
    text: {
      type: String,
    },
  },

  data () {
    return {
      Markdown: Markdown,
    };
  },

  mounted () {
    this.$el.addEventListener('click', this.onClick);
  },

  destroyed () {
    this.$el.removeEventListener('click', this.onClick);
  },

  methods: {
    onClick (e) {
      if (e.target.href) {
        const match = e.target.href.match(kbHrefReg);
        if (match && match[2]) {
          this.$emit('kbClick', match[2]);
          e.preventDefault();
        } else {
          e.target.setAttribute('target', '_blank');
        }

        this.$trackEvent({
          category: 'Support',
          name: 'FAQ click',
          source: e.target.textContent,
        });
      }
    },
  },
};

</script>

/**
 * File: rtSaveCallbackScheduleRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtSaveCallbackScheduleRequest extends RtRequest {
  /**
   * @returns {String}
   */
  get uri () {
    return `/ticket/${this.params.ticketId}/schedule`;
  }

  get method () {
    return 'POST';
  }

  get body () {
    return this.params.body;
  }
}

/**
 * File: rtFaqRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtFaqRequest extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.productKey
   * @param {String} params.supportType
   * @param {String} params.language
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  /**
   * @returns {String}
   */
  get uri () {
    return `/faq/${this.params.productKey}?support_type=${this.params.supportType}&language=${this.params.language}`;
  }

  get method () {
    return 'GET';
  }
}

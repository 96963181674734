

import Vue, { PropType } from 'vue';

import { RAS_UPGRADED_SUPPORT_STATUS_EXPIRED } from '@core/constants/subscriptions';
import Subscription from '@/models/subscription';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';
import RasSubscription from '@/models/rasSubscription';

export default Vue.extend({
  name: 'subscription-upgraded-support',
  mixins: [ComponentMixIn, SubscriptionMixIn],

  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
    serviceSubscription: {
      type: Object as PropType<RasSubscription>,
    },
    equalTo: {
      type: String,
    },
  },

  data () {
    return {
      RAS_UPGRADED_SUPPORT_STATUS_EXPIRED,
    };
  },

  methods: {},

  computed: {
    isUpgradedSupportExpired (): boolean {
      return this.serviceSubscription.upgradedSupport.endedAt && new Date() > this.serviceSubscription.upgradedSupport.endedAt;
    },
    upgradedSupportStatusColorClass (): string {
      if (this.isUpgradedSupportExpired) {
        return 'text-danger';
      }
      return '';
    },
  },

});


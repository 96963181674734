/**
 * File: licenseDownloadRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import UniversalKey from '@/models/universalKey';
import LicenseTokenRequest from '@/api/licenseTokenRequest';

export type LicenseDownloadRequestParams = {
  universalKey: UniversalKey;
};

export default class LicenseDownloadRequest extends ApiRequest<LicenseDownloadRequestParams, string> {
  licenseFileToken: string;
  subscriptionUuid: string;

  constructor (params) {
    const universalKey: UniversalKey = params.universalKey;

    super(params);

    this.subscriptionUuid = universalKey.subsetUuid || universalKey.subscriptionUuid;
  }

  getFileUrl (): string {
    return `/awingu/webapp/subscriptions/license_file/${this.licenseFileToken}/download`;
  }

  async downloadLicenseFile (apiInstance: any): Promise<void> {
    if (!apiInstance) { return; }
    const tokenRequest = new LicenseTokenRequest({
      subscriptionUuid: this.subscriptionUuid,
    });

    return apiInstance.authorizedCall(tokenRequest)
      .then((res) => {
        // eslint-disable-next-line camelcase
        this.licenseFileToken = res?.license_file_token;
        if (this.licenseFileToken) {
          window.open(this.getFileUrl(), '_blank');
        }
      });
  }
}



import Vue, { PropType } from 'vue';
import {
  PD_PRO_BUY_PUBLIC_ID,
  PD_UPGRADE_TO_PRO_PUBLIC_ID,
  PER_INCIDENT_SUPPORT_PUBLIC_ID,
  PRODUCT_KEY_NAME_MY_ACCOUNT,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PDPRO,
  PRODUCT_KEY_NAME_PER_INCIDENT_SUPPORT,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTB_CVT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_HDT,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  RasUpgradedSupportType,
  RasUpgradedSupportTypeToResource,
  RESOURCE_AWG_MAINTENANCE_AND_SUPPORT,
  RESOURCE_RAS_STANDARD_SUPPORT_KEY_NAME
} from '@core/constants/subscriptions';
import { UA_CONFLICT_EMBARGOED_COUNTRIES } from '@core/constants/geo';
import { getContactSource, SUPPORT_TYPE_LICENSE, SUPPORT_TYPE_TECHNICAL } from '../constants';
import Cache from '@core/api/cache';
import LicenseSubscriptionsRequest from '@/api/lsSubscriptionsRequest';
import GetPlansRequest from '@/api/getPlansRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import CompanyUsersRequest from '@/api/companyUsersRequest';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import RtOptionsRequestV2 from '@/api/rt/rtOptionsRequestV2';
import { TICKET_FIELD_CONTACT_SOURCE, TICKET_FIELD_SUPPORT_TYPE, TICKET_FIELD_TIMEZONE } from '@/api/rt/constants';
import GetSupportModal from './getSupportModal.vue';
import GetSupportByEmailModal from './getSupportByEmailModal.vue';
import ChatModal from './chatModal/chatModal.vue';
import { ChatModalParams } from './chatModal/chatModal';
import PremierLineModal from './premierLineModal.vue';
import IssueSeverityModal from './issueSeverityModal.vue';
import IssueSummaryModal from './issueSummaryModal.vue';
import BuyPhoneSupportModal from './buyPhoneSupportModal.vue';
import BuySubscriptionModal from './buySubscriptionModal.vue';
import { support } from '@core/constants/links';
import { dateValue, toDateObject } from '@core/common/format/date';
import { getMilitaryTimezone } from '@core/common/datetime';
import { capitalize } from '@core/common/utils';
import CreateTicketMixin, { SupportOptions } from '@/modules/support/content/createTicketMixin';
import { Dictionary } from '@core/common/types';
import CompanyUser from '@/models/companyUser';
import Subscription from '@/models/subscription';
import { GET_SUPPORT_PAGE } from '@/routes/constants';

const REQ_NAME_LS_WITH_HIDDEN_PERS_SUBSCRIPTIONS = 'ls_with_hidden_pers_subscriptions';
const REQ_NAME_LS_PERS_SUBSCRIPTIONS = 'ls_pers_subscriptions';
const REQ_NAME_LS_BIZ_SUBSCRIPTIONS = 'ls_biz_subscriptions';
const REQ_NAME_USERS = 'business_users_request';

const PLANS_MAP = {
  buy_phone_support: PER_INCIDENT_SUPPORT_PUBLIC_ID,
  upgrade_to_subscription: PD_UPGRADE_TO_PRO_PUBLIC_ID,
  buy_new_subscription: PD_PRO_BUY_PUBLIC_ID,
};

interface SupportPlan {
  'product_key'?: string;
  options?: Record<string, any>;
  'is_premier'?: boolean;
  types?: Record<string, any>;
  'product_id'?: string;
}

interface Resource {
  resource: string;
  'start_date': string;
  'end_date': string;
  seats: number;
}

export default Vue.extend({
  name: 'support-getsupport',

  components: {
    GetSupportModal,
    GetSupportByEmailModal,
    ChatModal,
    IssueSeverityModal,
    IssueSummaryModal,
    PremierLineModal,
    BuyPhoneSupportModal,
    BuySubscriptionModal,
  },

  mixins: [CreateTicketMixin],

  props: {
    product: {
      type: String,
    },
    products: {
      type: Object,
    },
    supportType: {
      type: String,
    },
    pdLegacy: {
      type: Array as PropType<any[]>,
    },
  },

  data () {
    return {
      loading: false,
      supportPlan: [] as SupportPlan[],
      supportOption: undefined as SupportOptions,
      cardKey: 'supportCard',
      subscriptions: [] as Subscription[],
      ticketId: undefined as string,
      users: [] as CompanyUser[],
      plans: {},
      purchaseInProgress: false,
      perIncidentSubscriptionUuid: undefined as string,
      GET_SUPPORT_PAGE,
      showChatModal: true,
    };
  },

  created () {
    this.loadSubscriptions();
  },

  methods: {
    load () {
      const session = this.$appData.session;
      let resources = [];

      if (this.product === PRODUCT_KEY_NAME_MY_ACCOUNT) {
        const personalDomainId = session.personalDomainId;
        const businessDomainsList = (this.products?.business && Object.keys(this.products?.business)?.length > 0) ? this.products.business : [];

        let allProducts = this.products?.personal[personalDomainId].filter(el => el !== PRODUCT_KEY_NAME_MY_ACCOUNT);
        if (businessDomainsList) {
          for (const domainId of Object.keys(businessDomainsList)) {
            allProducts = allProducts.concat(this.products?.business[domainId]);
          }
        }
        if (allProducts) {
          for (const prod of allProducts) {
            resources = resources.concat(this.loadResources(prod));
          }
        }
      } else {
        resources = this.loadResources(this.product);
      }

      this.$api.callRt(new RtOptionsRequestV2({
        country: session.country,
        product: this.product,
        resources: resources,
      })).then((data) => {
        data.supportPlan.forEach((product) => {
          if (product.options.phone) {
            product.options.skype = product.options.phone;
          }
        });
        this.supportPlan = data.supportPlan;

        if (this.offersAvailable) {
          return this.getPlans();
        }
      }).finally(() => {
        this.loading = false;

        this.$nextTick(() => {
          const cards = this.$el.querySelectorAll('.card');
          const activeCards = Array.prototype.filter.call(cards, function (card) {
            return !card.getAttribute('disabled');
          });

          this.$trackEvent({
            category: 'Support',
            name: 'Options shown',
            source: activeCards.length,
          });
        });
      });
    },

    getPlans () {
      const request = new CombinedApiRequest({ integrity: false });
      const plans = [
        PER_INCIDENT_SUPPORT_PUBLIC_ID,
        PD_UPGRADE_TO_PRO_PUBLIC_ID,
        PD_PRO_BUY_PUBLIC_ID,
      ];

      plans.forEach((plan) => {
        request.addRequest(plan, new GetPlansRequest({ product: plan }));
      });

      return this.$api.authorizedCall(request).then(() => {
        plans.forEach((plan) => {
          this.$set(this.plans, plan, (request.getRequest(plan) as GetPlansRequest).getFirstPlan());
        });
      });
    },

    loadResources (productKeyName): Array<Resource> {
      const resources = [];

      if (this.product === PRODUCT_KEY_NAME_PDPRO) {
        productKeyName = PRODUCT_KEY_NAME_PDFM;
      }

      for (const subscription of this.subscriptions) {
        const startDate = dateValue(subscription.activatedAt);
        let endDate = dateValue(subscription.gracePeriodEndsAt) || undefined;

        if (subscription.isPermanent) {
          const supportOption = subscription.options.find(
            (opt) => [RESOURCE_RAS_STANDARD_SUPPORT_KEY_NAME, RESOURCE_AWG_MAINTENANCE_AND_SUPPORT].includes(opt.keyName)
          );
          if (supportOption) {
            endDate = dateValue(new Date(supportOption.licenses.limit * 1000));
          }
        }

        for (const product of subscription.products) {
          if (product.productKeyName === productKeyName) {
            resources.push({
              resource: product.keyName,
              start_date: startDate,
              end_date: endDate,
              seats: product.licenses.thisPeriod,
            });
          }
        }

        if (productKeyName === PRODUCT_KEY_NAME_PDFM) {
          const product = subscription.products.find((product) => product.keyName === PRODUCT_KEY_NAME_PER_INCIDENT_SUPPORT);

          if (product && product.licenses.available > 0) {
            this.perIncidentSubscriptionUuid = subscription.uuid;

            resources.push({
              resource: product.keyName,
              start_date: startDate,
              seats: product.licenses.available,
            });
          }
        }

        if (productKeyName === PRODUCT_KEY_NAME_RAS) {
          this.addRasUpgradedSupportResource(subscription, resources);
        }
      }

      if (productKeyName === PRODUCT_KEY_NAME_PDFM && !resources.length && this.pdLegacy?.length) {
        for (const item of this.pdLegacy) {
          resources.push({
            resource: item.product_code,
            start_date: '',
            end_date: '',
            seats: '',
          });
        }
      }

      return resources;
    },

    addRasUpgradedSupportResource (subscription: Subscription, resources: Array<Resource>) {
      const product = subscription.products.find((product) => product.productKeyName === PRODUCT_KEY_NAME_RAS);
      if (!product) {
        return;
      }
      if (subscription.traits && subscription.traits.rasUpgradedSupportType !== RasUpgradedSupportType.NO_SUPPORT) {
        this.addOrUpdateRasUpgradedSupportResource(subscription, resources);
      }
    },

    addOrUpdateRasUpgradedSupportResource (subscription: Subscription, resources: Array<Resource>) {
      const supportResourceKeyName = RasUpgradedSupportTypeToResource[subscription.traits.rasUpgradedSupportType];
      const startDate = subscription.traits.rasUpgradedSupportStartDate;
      const endDate = subscription.traits.rasUpgradedSupportEndDate;
      const resourceData = resources.find((el) => el.resource === supportResourceKeyName);
      if (!resourceData) {
        resources.push({
          resource: supportResourceKeyName,
          start_date: dateValue(startDate),
          end_date: dateValue(endDate),
          seats: 0,
        });
        return;
      }
      resourceData.start_date = dateValue(startDate < toDateObject(resourceData.start_date) ? startDate : resourceData.start_date);
      resourceData.end_date = dateValue(endDate > toDateObject(resourceData.end_date) ? endDate : resourceData.end_date);
    },

    reload () {
      new Cache().dropCache();
      this.loadSubscriptions();
    },

    loadSubscriptions () {
      const combinedRequest = new CombinedApiRequest({ integrity: false });

      combinedRequest.addRequest(REQ_NAME_LS_PERS_SUBSCRIPTIONS, new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_PERSONAL }));
      if (this.$appData.session.businessDomainId) {
        combinedRequest.addRequest(REQ_NAME_LS_BIZ_SUBSCRIPTIONS, new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS }));
      }

      if (this.product === PRODUCT_KEY_NAME_PDFM) {
        combinedRequest.addRequest(REQ_NAME_LS_WITH_HIDDEN_PERS_SUBSCRIPTIONS, new LicenseSubscriptionsRequest({
          with_hidden_resources: true,
          product: PRODUCT_KEY_NAME_PER_INCIDENT_SUPPORT,
        }));
      }

      if (this.$appData.session.businessDomainId) {
        combinedRequest.addRequest(REQ_NAME_USERS, new CompanyUsersRequest({}, this.$appData.session.businessDomainId));
      }

      this.loading = true;

      this.$api.authorizedCall(combinedRequest, { retry: false })
        .then(() => {
          const persSubscriptions = (combinedRequest.getRequest(REQ_NAME_LS_PERS_SUBSCRIPTIONS) as SubscriptionsRequest).getSubscriptions();
          const bizSubscriptions = this.$appData.session.businessDomainId ? (combinedRequest.getRequest(REQ_NAME_LS_BIZ_SUBSCRIPTIONS) as SubscriptionsRequest).getSubscriptions() : [];
          let subscriptions = persSubscriptions.concat(bizSubscriptions);

          if (this.product === PRODUCT_KEY_NAME_PDFM) {
            // we should write ... as unknown as ... due to TS2352
            const phoneSubscriptions = (combinedRequest.getRequest(REQ_NAME_LS_WITH_HIDDEN_PERS_SUBSCRIPTIONS) as unknown as LicenseSubscriptionsRequest).getSubscriptions();
            subscriptions = subscriptions.concat(phoneSubscriptions);
          }

          this.subscriptions = subscriptions;

          if (combinedRequest.getFailedRequests().length) {
            this.$toast.show({
              tag: 'connectionError', // do not duplicate the same toasts
              color: 'red',
              text: this.$t('There was a problem completing this request.'),
            });
          }

          if (this.$appData.session.businessDomainId) {
            this.users = (combinedRequest.getRequest(REQ_NAME_USERS) as CompanyUsersRequest).getUsers();
          }

          this.load();
        });
    },

    prepareDataForTicket ({ supportOption, summary, severity }) {
      const personalMode = this.$appData.session.isPersonalUser(this.$appData.userInDomain);
      const companyName = this.$appData.session.businessDomainName;

      return {
        product: this.product_id,
        [TICKET_FIELD_SUPPORT_TYPE]: this.supportType,
        case_origin: supportOption,
        severity,
        country: this.$appData.session.country,
        [TICKET_FIELD_TIMEZONE]: getMilitaryTimezone(),
        [TICKET_FIELD_CONTACT_SOURCE]: getContactSource(),
        summary: personalMode ? summary : `[${companyName}] ${summary}`,
        description: `ticket for ${this.supportOption} support`,
        subscription_uuid: this.perIncidentSubscriptionUuid,
      };
    },

    createTicket ({ supportOption, summary, severity }) {
      this.loading = true;
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.createOrUpdateTicket(
        this.prepareDataForTicket({ supportOption, summary, severity })
      ).then((ticketId) => {
        this.ticketId = ticketId;
        this.$emit('ticketCreated', { ticketId, supportType: this.supportType });
      }).finally(() => {
        this.loading = false;
      });
    },

    needTicket () {
      return this.supportInfo && this.supportType && !this.supportInfo.types[this.supportType];
    },

    onSupportCardClick (supportOption: SupportOptions, severity) {
      this.$trackEvent({
        category: 'Support',
        name: 'Support card click',
        source: capitalize(supportOption),
      });

      this.supportOption = supportOption;

      if (this.isSupportAvailable(supportOption)) {
        const shouldShowPreIssueModal = ['phone', 'skype', 'chat'].includes(supportOption) || supportOption === 'email' && this.product === PRODUCT_KEY_NAME_PDFC;

        if (shouldShowPreIssueModal) {
          if (this.personalMode) {
            // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
            this.$refs.issueSummaryModal.show({ supportOption, summary: this.summary });
          } else {
            // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
            this.$refs.issueSeverityModal.show({ supportOption, severity, summary: this.summary });
          }
        } else {
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
          this.$refs.getSupportByEmailModal.show({ summary: this.summary });
        }
      }
    },

    async onIssueSubmit ({ severity, supportOption, summary }) {
      if (supportOption === SupportOptions.Email) {
        this.onSeverityForEmailChosen({ severity, summary });
        return;
      }

      if (supportOption === SupportOptions.Chat) {
        this.showChatModal = true;
        this.$modal.show(ChatModal, {
          supportType: this.supportType,
          ticketData: this.prepareDataForTicket({ supportOption, summary, severity }),
          preferredChannel: 'chat',
        } as ChatModalParams);
        return;
      }

      await this.createTicket({ supportOption, summary, severity });
      if (supportOption === SupportOptions.Phone || supportOption === SupportOptions.Skype) {
        this.onSeverityForPhoneSkypeChosen({ severity, supportOption, summary });
      }
    },

    onSeverityForPhoneSkypeChosen ({ severity, supportOption, summary }) {
      if (summary) {
        this.summary = summary;
      }
      if (severity === 0 && this.isPremierCustomer) {
        if (supportOption) {
          this.supportOption = supportOption;
          this.$trackEvent({
            category: 'Support',
            name: 'Support card click',
            source: capitalize(supportOption),
          });
        }
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.premierLineModal.show(this.supportOption);
      } else {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.getSupportModal.show({
          supportOption: this.supportOption,
          ticketId: this.ticketId,
          severity,
          summary,
          subscriptionUuid: this.perIncidentSubscriptionUuid,
        });
      }
    },

    onSeverityForEmailChosen ({ severity, summary }) {
      if (summary) {
        this.summary = summary;
      }
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.getSupportByEmailModal.show({ severity, summary });
    },

    onBackToSeveritySelector (severity = 0) {
      this.onSupportCardClick(this.supportOption || SupportOptions.Phone, severity);
    },

    onBuyPhoneClick () {
      this.$trackEvent({
        category: 'Support',
        name: 'Support card click',
        source: 'Buy Phone Support',
      });
      this.$modal.show('buyPhoneSupport');
    },

    onBuySubscriptionClick (params: Dictionary = {}) {
      this.purchaseInProgress = !!params.inProgress;
      if (params.optionName) {
        this.$trackEvent({
          category: 'Support',
          name: 'Support card click',
          source: params.optionName,
        });
      }
      this.$modal.show('buySubscription');
    },

    getSupportOption (optionName: SupportOptions) {
      return this.productSupportOptions[optionName];
    },

    isSupportPossible (optionName: SupportOptions) {
      const option = this.getSupportOption(optionName);

      if ([SupportOptions.Phone, SupportOptions.Chat].includes(optionName) &&
        UA_CONFLICT_EMBARGOED_COUNTRIES.includes(this.$appData.session.geolocation)) {
        return false;
      }

      return option && option.types.includes(this.supportType);
    },

    isSupportAvailable (optionName: SupportOptions) {
      const option = this.getSupportOption(optionName);

      return this.isSupportPossible(optionName) && option.days > 0;
    },

    onTicketCreated (data) {
      this.ticketId = data.ticketId;
      this.$emit('ticketCreated', data.ticketId);
      if (data.caseOrigin === 'email') {
        this.$modal.show(ChatModal, {
          supportType: this.supportType,
          ticketData: {
            product: data.form.product,
            [TICKET_FIELD_SUPPORT_TYPE]: data.form[TICKET_FIELD_SUPPORT_TYPE],
            case_origin: data.caseOrigin,
            severity: 3,
            country: data.form.country,
            [TICKET_FIELD_TIMEZONE]: data.form[TICKET_FIELD_TIMEZONE],
            [TICKET_FIELD_CONTACT_SOURCE]: data.form[TICKET_FIELD_CONTACT_SOURCE],
            summary: data.form.summary,
            description: data.form.description,
          },
          ticketId: Number(this.ticketId),
          preferredChannel: 'email',
        });
      }
    },

    onSupportModalClosed (data) {
      if (data.ticketId) {
        // @ts-ignore
        this.$router.push({ name: 'myTickets', params: { denyGetSupportWithParams: data.caseOrigin === 'email' } });
      }
    },

    openLink (name, source) {
      let link;

      if (support[name]) {
        link = support[name];
      } else if (name === 'forum') {
        link = this.forumLink;
      }

      if (source) {
        this.$trackEvent({
          category: 'Support',
          name: 'Support card click',
          source,
        });
      }

      if (link) {
        window.open(link, '_blank');
      }
    },
  },

  computed: {

    supportInfo (): SupportPlan {
      return this.supportPlan.find((sp) => sp.product_key === this.product) || {};
    },

    summary: {
      get: function (): string | string[] {
        return this.$route.query.searchText;
      },
      set: function (newValue: string) {
        if (newValue !== this.summary) {
          this.$router.replace({
            name: this.$route.name,
            params: this.$route.params,
            query: { ...this.$route.query, searchText: newValue },
          });
        }
      },
    },

    isPremierCustomer (): boolean {
      return this.supportInfo.is_premier;
    },

    product_id (): string {
      return this.supportInfo && this.supportType && this.supportInfo.product_id;
    },

    productSupportOptions (): Record<string, any> {
      return this.supportInfo.options || {};
    },

    forumLink (): string {
      switch (this.product) {
        case PRODUCT_KEY_NAME_PDB:
        case PRODUCT_KEY_NAME_PDFM:
        case PRODUCT_KEY_NAME_PDL:
        case PRODUCT_KEY_NAME_PDPRO:
          return 'https://forum.parallels.com/#parallels-desktop-for-mac.771';
        case PRODUCT_KEY_NAME_PTB:
        case PRODUCT_KEY_NAME_PTB_HDT:
        case PRODUCT_KEY_NAME_PTB_DNST:
        case PRODUCT_KEY_NAME_PTB_DVT:
        case PRODUCT_KEY_NAME_PTB_CVT:
          return 'https://forum.parallels.com/#parallels-toolbox.764';
        case PRODUCT_KEY_NAME_RAS:
          return 'https://forum.parallels.com/#parallels-remote-application-server.745';
        default:
          return 'https://forum.parallels.com';
      }
    },

    hasSupportOptions (): boolean {
      return this.isSupportPossible(SupportOptions.Email) || this.isSupportPossible(SupportOptions.Phone) || this.isSupportPossible(SupportOptions.Chat);
    },

    hidePhoneSupportNote (): boolean {
      const lang = this.$i18n?.locale.substring(0, 2);
      const excludedLocales = ['en', 'fr', 'ru'];
      const isToolbox = this.product.startsWith(PRODUCT_KEY_NAME_PTB);
      const isPdfm = this.product.startsWith(PRODUCT_KEY_NAME_PDFM);
      const pdfmNumber = isPdfm && Number(this.product.replace(/[^\d.-]+/g, ''));
      const isPdfmLess17 = pdfmNumber && pdfmNumber < 17;

      return excludedLocales.includes(lang) || isToolbox || isPdfmLess17;
    },

    presaleRasAndPmmMessage (): boolean {
      return (this.product === PRODUCT_KEY_NAME_RAS) &&
        (this.supportType === SUPPORT_TYPE_LICENSE || this.supportType === SUPPORT_TYPE_TECHNICAL) &&
        !this.hasSupportOptions;
    },

    personalMode (): boolean {
      return !this.$appData.session.businessDomainId || this.$appData.session.isPersonalUser(this.$appData.userInDomain);
    },

    businessAdminMode (): boolean {
      return this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain) && this.$appData.session.isBusinessAdmin;
    },

    offersAvailable (): boolean {
      return !!Object.keys(this.offers).length;
    },

    offers (): Record<string, any> {
      const offers = ['upgrade_to_subscription', 'buy_phone_support', 'buy_new_subscription'].filter((offer: SupportOptions) => this.getSupportOption(offer));
      return offers.reduce((offers, item) => {
        offers[item] = this.plans[PLANS_MAP[item]];
        return offers;
      }, {});
    },

    showSocialSupportOptions (): boolean {
      const excludedProducts = [PRODUCT_KEY_NAME_PDFC, PRODUCT_KEY_NAME_PSW, PRODUCT_KEY_NAME_DAAS, PRODUCT_KEY_NAME_PBI];
      return !excludedProducts.includes(this.product);
    },
    showSkypeSupportOption (): boolean {
      return ![PRODUCT_KEY_NAME_PSW, PRODUCT_KEY_NAME_DAAS, PRODUCT_KEY_NAME_PBI].includes(this.product);
    },
  },
});


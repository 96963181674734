/**
 * File: upgradeToProRoutes.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import UpgradeToProPage from '../modules/upgradeToPro/upgradeToProPage.vue';

export default [
  {
    path: '/upgrade_to_pro/:uuid/:token?',
    name: 'upgradeToPro',
    component: UpgradeToProPage,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
];

<copyright>
File: loginForm.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import { email, password } from '@core/common/validators';

export default {
  name: 'login-form',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        email: '',
        password: '',
      },
    };
  },
  validations: {
    form: {
      email,
      password,
    },
  },
  methods: {
    submit () {
      this.$emit('submit', {
        ...this.form,
        rememberMe: false,
      });
    },
  },
};

</script>

<template lang="pug">

form-data(:validator="$v", @submit="submit")
  .margin-bottom-2x
    textbox(
      size="big",
      type="email",
      data-qa="email"
      name="email",
      :icon="false",
      :placeholder="$t('Email')",
      :floating-placeholder="true",
      v-model.trim="form.email",
      :validator="$v.form.email",
      :data-name="$name('textbox-email')"
    )
  .margin-bottom
    textbox(
      size="big",
      type="password",
      data-qa="password",
      name="password",
      :icon="false",
      :eye-inside="true",
      :placeholder="$t('Password')",
      :floating-placeholder="true",
      v-model.trim="form.password",
      :validator="$v.form.password",
      :data-name="$name('textbox-password')"
    )
  .margin-bottom-2x.text-right.padding-top
    span.link(@click="$emit('forgot')", data-qa="forgotPwdLnk", :data-name="$name('link-forgot-password')").line-height-4x {{ $t('Forgot password?') }}
  btn.block(:disabled="disabled", :data-name="$name('button-submit')", data-qa="signInBtn") {{ $t('Sign In') }}

</template>

/**
 * File: getRedeemCodesRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import { camelize } from '@core/common/utils';
import oemLicensesStatistics from '@/data/oemLicensesStatistics';
import { RedeemCode } from '@/models/oemLicenses';

interface GetRedeemCodesRequestParams {
  totalOnly?: boolean;
}

interface GetRedeemCodesRequestResponse {
  redeemCodes?: RedeemCode[];
  statistics: {
    totalCodes: number;
    totalLicenses: number;
  };
}

export default class GetRedeemCodesRequest extends ApiRequest<GetRedeemCodesRequestParams, GetRedeemCodesRequestResponse> {
  get url () {
    let url = '/license/api/v1/redeem_codes';
    if (this.params.totalOnly) {
      url += '?total_only=true';
    }
    return url;
  }

  onFetchSuccess (data: GetRedeemCodesRequestResponse) {
    const resultData = camelize(data);
    if (resultData.redeemCodes) {
      resultData.redeemCodes = resultData.redeemCodes.map((codeData) => new RedeemCode(codeData));
    }
    oemLicensesStatistics.totalRedeemCodes = resultData.statistics.totalCodes;
    oemLicensesStatistics.totalStandaloneLicenses = resultData.statistics.totalLicenses;
    return resultData;
  }
}

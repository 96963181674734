/**
 * File: pdUnblockHostsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PdComputersRequest from './pdComputersRequest';
import SubscriptionsRequest from './subscriptionsRequest';

export default class PdUnblockHostsRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.hw_ids
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/desktop/api/v1/ba/hosts/blacklist/unblock';
  }

  get body () {
    return {
      hw_id_list: this.params.hwIds,
    };
  }

  load () {
    return super.load()
      .then((data) => {
        new PdComputersRequest({}).dropFullCache();
        new SubscriptionsRequest({}).dropFullCache();
        return data;
      });
  }
}

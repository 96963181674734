/**
 * File: configurationProfiles.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue from 'vue';
import ComponentMixIn from '@/modules/componentMixIn';
import PdConfigurationProfilesRequest from '@/api/pdConfigurationProfilesRequest';
import ConfigurationProfilesTable from './configurationProfilesTable.vue';
import ConfigurationProfileModal from './configurationProfileModal.vue';

interface ConfigurationProfile {
  id: number;
  name: string;
  values: object;
  createdAt: string;
  updatedAt: string;
  hostsCnt: number;
  subscriptionsCnt: number;
  templateVersion: string[];
};

export default Vue.extend({
  name: 'configuration-profiles',
  props: {
    profileId: {
      type: Number,
      default (): number | undefined {
        const id = this.$route.query.profileId;
        return id && Number(id);
      },
    },
  },
  mixins: [ComponentMixIn],
  components: {
    ConfigurationProfilesTable,
    ConfigurationProfileModal,
  },
  data () {
    return {
      loading: true,
      configurationProfiles: [] as ConfigurationProfile[],
    };
  },
  created () {
    this.load({ initial: true });
  },
  methods: {
    load (params: { initial?: boolean } = {}): void {
      const configurationProfilesRequest = new PdConfigurationProfilesRequest();
      this.authorizedCall(configurationProfilesRequest).then((data) => {
        this.$emit('updateConfigurationProfilesAmount', data.total);
        this.configurationProfiles = data.configuration_profiles;
        if (params.initial && this.profileToShow) {
          this.onConfigurationProfileClick(this.profileToShow);
        }
      });
    },
    onConfigurationProfileClick (configurationProfile): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.configurationProfileModal.show(configurationProfile);
    },
    onCreateConfigurationProfileClick (): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.configurationProfileModal.show();
    },
    onConfigurationProfilesRemoved (): void {
      this.refreshTable();
    },
    refreshTable (): void {
      this.load();
    },
  },
  computed: {
    profileToShow (): ConfigurationProfile | undefined {
      return this.configurationProfiles.find((profile) => profile.id === this.profileId);
    },
  },
});

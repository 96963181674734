/**
 * File: actionRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { RouteConfig } from 'vue-router/types/router';

import api from '@/api';
import AskConfirmAccountPage from '@/modules/confirm/account/ask.vue';
import ConfirmAccountPage from '@/modules/confirm/account/confirm.vue';
import BrowserConfirmationPage from '@/modules/confirm/browser/confirmation.vue';
import BrowserTrustPage from '@/modules/confirm/browser/trust.vue';
import ConfirmAccountEmailChange from '@/modules/action/ConfirmAccountEmailChange/ConfirmAccountEmailChange.vue';
import ConfirmAccountEmailChangePhase2
  from '@/modules/action/ConfirmAccountEmailChangePhase2/ConfirmAccountEmailChangePhase2.vue';
import EmailChangeRevert from '@/modules/action/emailChangeRevert/index.vue';
import PasswordReset from '@/modules/action/passwordReset/index.vue';
import AcceptInvite from '@/modules/action/acceptInvite.vue';
import ConfirmDeleteAccount from '@/modules/action/confirmDeleteAccount/index.vue';
import DownloadAccountData from '@/modules/action/downloadAccountData/index.vue';
import UnsubscribeFromNotifications from '@/modules/action/unsubscribe/index.vue';
import AdjustNotificationSettings from '@/modules/action/adjustNotificationSettings/index.vue';
import RestorePurchases from '@/modules/action/restorePurchases/index.vue';
import OpenProduct from '@/modules/action/openProduct/index.vue';
import OemPartnerAbused from '@/modules/action/oemPartnerAbused.vue';
import {
  ACCEPT_INVITE_ACTION_PAGE,
  ADJUST_NOTIFICATIONS_ACTION_PAGE,
  ASK_CONFIRM_ACCOUNT_PAGE,
  CONFIRM_ACCOUNT_PAGE,
  CONFIRM_BROWSER_PAGE,
  CONFIRM_DELETE_ACCOUNT_ACTION_PAGE,
  CONFIRM_EMAIL_CHANGE_ACTION_PAGE,
  CONFIRM_EMAIL_CHANGE_PHASE2_ACTION_PAGE,
  CONFIRM_EMAIL_CHANGE_REVERT_ACTION_PAGE,
  DOWNLOAD_ACCOUNT_DATA_ACTION_PAGE,
  GET_RESCUE_CODES_ACTION_PAGE,
  OPEN_PRODUCT_ACTION_PAGE,
  REDEEM_CODE_ABUSED_ACTION_PAGE,
  REPORT_ACCOUNT_ABUSE_ACTION_PAGE,
  RESET_PASSWORD_ACTION_PAGE,
  RESTORE_PURCHASES_ACTION_PAGE,
  START_PAGE,
  TRUST_BROWSER_PAGE,
  UNSUBSCRIBE_FROM_NOTIFICATIONS_ACTION_PAGE
} from '@/routes/constants';
import GetRescueCodesPage from '@/modules/action/getRescueCodesPage.vue';
import ReportAccountAbusePage from '@/modules/action/reportAccountAbusePage.vue';

export const askConfirmAccount: RouteConfig = {
  path: '/action/account/confirm',
  name: ASK_CONFIRM_ACCOUNT_PAGE,
  component: AskConfirmAccountPage,
  props: true,
  meta: {
    ignoreAnalytics: true,
  },
};

const routes: RouteConfig[] = [
  askConfirmAccount,
  {
    path: '/action/account/confirm/:code',
    name: CONFIRM_ACCOUNT_PAGE,
    component: ConfirmAccountPage,
    props: true,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    path: '/action/account/confirm_browser',
    name: CONFIRM_BROWSER_PAGE,
    component: BrowserConfirmationPage,
    meta: {
      requiresAuth: true,
    },
    beforeEnter (to, from, next) {
      api.refreshToken().then((data) => {
        if (!data.trusted) {
          next();
        } else {
          next({
            name: START_PAGE,
          });
        }
      });
    },
  },
  {
    path: '/action/account/confirm_browser/:code',
    name: TRUST_BROWSER_PAGE,
    component: BrowserTrustPage,
    props: true,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    name: CONFIRM_EMAIL_CHANGE_ACTION_PAGE,
    path: '/action/account/confirm_account_email_change/:code',
    component: ConfirmAccountEmailChange,
    props: true,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    name: CONFIRM_EMAIL_CHANGE_PHASE2_ACTION_PAGE,
    path: '/action/account/confirm_new_account_email/:code',
    component: ConfirmAccountEmailChangePhase2,
    props: true,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    name: CONFIRM_EMAIL_CHANGE_REVERT_ACTION_PAGE,
    path: '/action/account/revert_account_email/:code',
    component: EmailChangeRevert,
    props: true,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    name: RESET_PASSWORD_ACTION_PAGE,
    path: '/action/account/reset/:code',
    component: PasswordReset,
    props: true,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    name: ACCEPT_INVITE_ACTION_PAGE,
    path: '/action/account/accept_ba/:code',
    component: AcceptInvite,
    props: true,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    name: CONFIRM_DELETE_ACCOUNT_ACTION_PAGE,
    path: '/action/account/confirm_delete_profile/:code',
    component: ConfirmDeleteAccount,
    props: true,
    meta: {
      title: 'Deleting Parallels User Account',
      ignoreAnalytics: true,
    },
  },
  {
    name: DOWNLOAD_ACCOUNT_DATA_ACTION_PAGE,
    path: '/action/account/download_profile/:code',
    component: DownloadAccountData,
    props: true,
    meta: {
      title: 'Download Account Data',
      ignoreAnalytics: true,
    },
  },
  {
    name: UNSUBSCRIBE_FROM_NOTIFICATIONS_ACTION_PAGE,
    path: '/action/account/unsubscribe/:code',
    component: UnsubscribeFromNotifications,
    props: true,
    meta: {
      title: 'Notification Settings',
      ignoreAnalytics: true,
    },
  },
  {
    name: ADJUST_NOTIFICATIONS_ACTION_PAGE,
    path: '/action/account/adjust_notifications_settings/:code',
    component: AdjustNotificationSettings,
    props: true,
    meta: {
      title: 'Adjust Notification Settings',
      ignoreAnalytics: true,
    },
  },
  {
    name: RESTORE_PURCHASES_ACTION_PAGE,
    path: '/action/account/restore_purchases/:code',
    component: RestorePurchases,
    props: true,
    meta: {
      title: 'Restore Your Purchases',
      ignoreAnalytics: true,
    },
  },
  {
    name: GET_RESCUE_CODES_ACTION_PAGE,
    path: '/action/account/rescue_codes/:code',
    component: GetRescueCodesPage,
    props: true,
    meta: {
      title: 'Get Rescue Codes',
      ignoreAnalytics: true,
    },
  },
  {
    name: REPORT_ACCOUNT_ABUSE_ACTION_PAGE,
    path: '/action/account/account_abused/:code',
    component: ReportAccountAbusePage,
    props: true,
    meta: {
      title: 'Report Account Abuse',
      ignoreAnalytics: true,
    },
  },
  {
    name: OPEN_PRODUCT_ACTION_PAGE,
    path: '/action/account/openProduct',
    component: OpenProduct,
    meta: {
      title: 'Continue in Parallels Toolbox',
    },
  },
  {
    name: REDEEM_CODE_ABUSED_ACTION_PAGE,
    path: '/action/license/redeem_codes/abuse/:code',
    component: OemPartnerAbused,
    props: true,
    meta: {
      title: 'Parallels Account Abuse Report',
      ignoreAnalytics: true,
    },
  },
];

export default routes;

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

page(:appData="appData")
  template(slot="pageContent")
    loader(:loading="true")

</template>

<script>

export default {
  name: 'reset-password-page',
  props: ['appData', 'code'],
  mounted () {
    // Language switcher watcher needs to be called before this redirect
    this.$nextTick(() => {
      this.$router.replace({
        name: 'reset',
        params: {
          code: this.code,
        },
      });
    });
  },
};

</script>

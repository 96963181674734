/**
 * File: rasDeactivateHostRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import RasPrepaidFarmsRequest from '@/api/rasPrepaidFarmsRequest';
import RasFarmsCountersRequest from '@/api/rasFarmsCountersRequest';
import RasSubscriptionsRequest from './rasSubscriptionsRequest';

export default class RasDeactivateHostRequest extends ApiRequest {
  get method () {
    return 'DELETE';
  }

  get url () {
    return `/ras/api/v1/hosts/${this.params.hwId}`;
  }

  load () {
    return super.load()
      .then((data) => {
        new RasPrepaidFarmsRequest({}).dropCache(); // FIXME it should be static
        new RasFarmsCountersRequest({}).dropCache();
        new RasSubscriptionsRequest({}).dropCache();
        return data;
      });
  }
}



import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'rescue-codes',

  props: {
    codes: Array as PropType<string[]>,
  },

  computed: {
    splitCodes (): string[] {
      return this.codes.map((code) => {
        const chunks = code.match(/.{1,4}/g);
        return chunks ? chunks.join(' ') : '';
      });
    },
  },
});


/**
 * File: formMixin.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

export default {
  name: 'form-mixin',
  data () {
    return {
      passwordType: 'password',
    };
  },
  methods: {
    changeTextboxType (params) {
      this.passwordType = params.type;
    },
  },
};

/**
 * File: price.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

export const price = function (amount?, currency?: string): string {
  if (!amount) {
    amount = '0.00';
  }

  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  let result = [];
  if (currency) {
    result.push(currency);
  }
  result.push(Math.round(amount * 100) / 100);

  return result.join(' ')
    .replace(/\s\d+$/, '$&.00') // replaces " 10" with " 10.00" so "AUD 10" will be "AUD 10.00"
    .replace(/\.\d$/, '$&0'); // replaces ".6" with ".60" so "AUD 76.6" will be "AUD 76.60"
};

/**
 * File: rasProductsRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';
import Service from '@core/constants/services';

export default class RasProductsRequest extends ApiRequest {
  get url (): string {
    return getServiceUrl(Service.RasLicense, '/webapp/products');
  }

  get cacheNameSpace (): string {
    return 'subscriptions';
  }
};

/**
 * File: oemUploadSerialNumbersCsvRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';

import { PackagedLicensesGenerationProgressData } from './checkPackagedLicensesGenerationProgressRequest';
import { camelize } from '@core/common/utils';

interface OemUploadSerialNumbersCsvRequestParams {
  file: File;
}

/* eslint-disable camelcase */
interface OemUploadSerialNumbersCsvRequestResponse {
  serial_numbers_preloaded_list_id: string;
  upload_info: PackagedLicensesGenerationProgressData;
}
/* eslint-enable camelcase */

export default class OemUploadSerialNumbersCsvRequest extends ApiRequest<OemUploadSerialNumbersCsvRequestParams, OemUploadSerialNumbersCsvRequestResponse> {
  constructor (params) {
    super(params);
    this.headers = {};
  }

  get options () {
    const
      options = super.options;
    const body = new FormData();

    body.append('csv', this.params.file);
    options.body = body;

    return options;
  }

  get method (): Method {
    return 'POST';
  }

  get url () {
    return '/license/api/v1/packaged_licenses/upload_csv';
  }

  getUploadInfo (): { serialNumbersPreloadedListId: string; uploadInfo: PackagedLicensesGenerationProgressData } {
    return camelize(this.data);
  }
}

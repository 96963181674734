/**
 * File: heightObserver.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue from 'vue';
import throttle from 'lodash-es/throttle';

var
  items = {};
var observers = {};
var bus = new Vue();
var recalcOnResizeTime = 1000;

bus.$on('mount', function (data) {
  if (!items[data.key]) {
    items[data.key] = {};
  }

  items[data.key][data.uid] = data.el;

  if (Object.keys(items[data.key]).length > 1) {
    bus.$emit('recalc', data);
  }

  try {
    observers[data.uid] = new MutationObserver(function () {
      bus.$emit('recalc', data);
    });

    observers[data.uid].observe(data.el, {
      childList: true,
      characterData: true,
    });
  } catch (e) {}
});

bus.$on('unmount', function (data) {
  items[data.key][data.uid].style.height = '';

  delete items[data.key][data.uid];
  delete observers[data.uid];

  bus.$emit('recalc', data);
});

bus.$on('recalc', function (data) {
  var
    heights = Object.keys(items[data.key]).map(function (uid) {
      items[data.key][uid].style.height = '';
      return items[data.key][uid].clientHeight;
    });
  var height;

  height = Math.max.apply(null, heights);

  bus.$emit('update', {
    key: data.key,
    height: height,
  });
});

function onDocumentResize () {
  for (var key in items) {
    bus.$emit('recalc', {
      key: key,
    });
  }
}

window.addEventListener('resize', throttle(onDocumentResize, recalcOnResizeTime, {
  leading: false,
}));

export default bus;

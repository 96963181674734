/**
 * File: rtEscalateTicketRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtEscalateTicketRequest extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {Number} params.ticketId
   * @param {String} params.comment
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  /**
   * @returns {String}
   */
  get uri () {
    return `/ticket/${this.params.ticketId}/escalate`;
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      comment: this.params.comment,
    };
  }
}

/**
 * File: addKeyModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue, { PropType } from 'vue';

import AddKeyForm from '@/modules/addKey/addKeyForm/addKeyForm.vue';
import RegisterBusinessAccountModal from '@/modules/registerBusinessAccount/registerBusinessAccountModal.vue';
import Session from '@/models/session';
import Subscription from '@/models/subscription';
import BulaModal from '@/modules/bula/bulaModal.vue';
import ExtendSubscriptionModal from './extendSubscriptionModal/extendSubscriptionModal.vue';
import {
  BUSINESS_PROFILE_SCOPE,
  GET_SUPPORT_PAGE,
  IDP_INTEGRATION_PAGE,
  SUBSCRIPTION_DETAILS_PAGE
} from '@/routes/constants';
import { prodKeyToName, PRODUCT_KEY_NAME_PDB } from '@core/constants/subscriptions';
import ConfirmEmailModal from '@/modules/confirmEmailModal/confirmEmailModal.vue';
import UserConfirmEmailRequest from '@/api/userConfirmEmailRequest';
import ComponentMixin from '@/modules/componentMixIn';
import EditPropertiesModal from '@/modules/subscriptions/details/editPropertiesModal/editPropertiesModal.vue';
import KeyConflictModal from '@/modules/addKey/keyConflictModal.vue';

export default Vue.extend({
  name: 'add-key-modal',
  mixins: [ComponentMixin],
  props: {
    session: {
      type: Object as PropType<Session>,
      required: true,
    },
    asModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    KeyConflictModal,
    ConfirmEmailModal,
    AddKeyForm,
    BulaModal,
    RegisterBusinessAccountModal,
    ExtendSubscriptionModal,
    EditPropertiesModal,
  },
  data () {
    return {
      licenseKey: '',
      extendOptions: [] as Subscription[],
      newSubscription: null as Subscription | null,
      keyInfo: null as Subscription | null,
      extendedSubscriptionEdition: '',
      extendedSubscriptionLicenseKey: '',
      extendedSubscriptionExpirationDate: '',
      existsPerUserLicenseKey: '',
      existsPerUserSubscriptionUuid: '',
    };
  },
  methods: {
    subscriptionExtendedHandler (subscription, edition, licenseKey, expirationDate) {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.addKeyForm.licenseKey = '';
      this.extendedSubscriptionEdition = edition;
      this.extendedSubscriptionLicenseKey = licenseKey;
      this.extendedSubscriptionExpirationDate = expirationDate;
    },
    businessDomainChangeHandler (value) {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.addKeyForm.selectedBusinessDomain = value;
    },
    businessDomainRegisterHandler () {
      this.$modal.hide('selectBusinessDomain');
      if (this.asModal) {
        this.$modal.show('addKeyModal');
      }
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.addKeyForm.addKey();
    },
    show () {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.addKeyForm.resetForm();
      this.$modal.show('addKeyModal');
    },
    openIdpIntegrationPage () {
      this.$modal.hide('configureIdpSuggestionModal');
      this.$router.push({ name: BUSINESS_PROFILE_SCOPE, params: { page: IDP_INTEGRATION_PAGE } });
    },
    openExistsPerUserSubscriptionDetails () {
      this.$modal.hide('onlySinglePerUserSubscriptionAllowedModal');
      this.$router.push({ name: SUBSCRIPTION_DETAILS_PAGE, params: { id: this.existsPerUserSubscriptionUuid } });
    },
    openContactSupportPage () {
      this.$modal.hide('onlySinglePerUserSubscriptionAllowedModal');
      this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_PDB } });
    },
    openNewSubscriptionDetails (keyInfo: Subscription) {
      this.$router.push({ name: SUBSCRIPTION_DETAILS_PAGE, params: { id: keyInfo.uuid }, query: { checkLicenseProperties: 'true' } });
    },
    async onNeedConfirmation () {
      this.$modal.show(ConfirmEmailModal, $t('You must confirm your email before registering a license key of a business product. We have sent a confirmation email to {mailAddr}. Please follow the instructions from it to proceed.', { mailAddr: this.$appData.session.email }));
    },
    requestEmail () {
      this.authorizedCall(new UserConfirmEmailRequest());
    },
  },
  computed: {
    adminsDomains (): { companyUuid: number; companyName: string; isAdmin: boolean }[] {
      return this.session.getBusinessDomains(true);
    },
    productName (): string {
      return this.keyInfo ? prodKeyToName(this.keyInfo.firstProductKeyName) : '';
    },
  },
});


import Vue from 'vue';
import { maxLength, required } from '@core/common/validators';
import RtTicketsRequest from '@/api/rt/rtTicketsRequest';
import {
  ACCOUNT_FAILED_SIGN_IN_RT_QUEUE_ID,
  MYACCOUNT_PRODUCT_ID
} from '@/api/rt/constants';
import {
  CHANGE_SETTINGS_MODE,
  LOGIN_PAGE,
  PERSONAL_PROFILE_SCOPE,
  SECURITY_PAGE
} from '@/routes/constants';
import RtCreateTicketRequest from '@/api/rt/rtCreateTicketRequest';

const MAX_DESCRIPTION_LENGTH = 255;

export default Vue.extend({
  name: 'cant-use-account-rescue-codes-page',

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  validations: {
    supportForm: {
      description: {
        required,
        maxLength: maxLength(MAX_DESCRIPTION_LENGTH),
      },
    },
  },

  data () {
    return {
      MAX_DESCRIPTION_LENGTH,
      supportForm: {
        description: '',
      },
      loading: false,
      error: false,
    };
  },

  created () {
    this.checkRtQueue();
  },

  computed: {
    changeSettingsMode (): boolean {
      return this.mode === CHANGE_SETTINGS_MODE;
    },
  },

  methods: {
    goToLoginPage () {
      this.$router.push({ name: LOGIN_PAGE });
    },
    goToSecurityPage () {
      this.$router.push({ name: PERSONAL_PROFILE_SCOPE, params: { page: SECURITY_PAGE } });
    },
    returnToPage () {
      this.changeSettingsMode ? this.goToSecurityPage() : this.goToLoginPage();
    },
    async submitRequest () {
      const request = new RtCreateTicketRequest(
        {
          form: {
            product: MYACCOUNT_PRODUCT_ID,
            queue_id: ACCOUNT_FAILED_SIGN_IN_RT_QUEUE_ID,
            description: this.supportForm.description,
            summary: this.changeSettingsMode ? this.$t('Can’t use rescue codes') : this.$t('Can’t use two-step verification'),
          },
          rtUrl: this.$appData.session.rtUrl,
        }
      );
      this.loading = true;
      try {
        await this.$api.callRt(request);
        this.$toast.show({
          text: this.$t('Request for support has been submitted.'),
        });
        this.returnToPage();
      } catch (e) {
        this.$toast.show({
          text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
          color: 'red',
        });
      } finally {
        this.loading = false;
      }
    },
    async checkRtQueue () {
      this.loading = true;
      try {
        const rtTicketsRequest = new RtTicketsRequest({ queueId: ACCOUNT_FAILED_SIGN_IN_RT_QUEUE_ID, amountOnly: true, rtUrl: this.$appData.session.rtUrl });
        const ticketsStatistics = await this.$api.callRt(rtTicketsRequest);
        this.error = ticketsStatistics.amount > ticketsStatistics.amountClosed;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
});


/**
 * File: permissionsChangeRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PermissionsRequest from '@/api/permissionsRequest';
import { getServiceUrl } from '@core/api/routing';

export default class PermissionsChangeRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/permissions/${this.params.uuid}`);
  }

  get body () {
    return { members: this.params.members };
  }

  load () {
    return super.load()
      .then((data) => {
        new PermissionsRequest().dropFullCache();
        return data;
      });
  }
}

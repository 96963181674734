

import Vue, { PropType } from 'vue';
import { FEATURE_CONFIGURATION_PROFILE_PER_HOST } from '@core/constants/features';
import componentMixin from '@/modules/componentMixIn';
import { sortByIp } from '@core/common/sorters';
import PdDeactivateHostsRequest from '@/api/pdDeactivateHostsRequest';
import ConfirmationBlacklisting from './confirmationBlacklisting.vue';
import PdRemoveHostsRequest from '@/api/pdRemoveHostsRequest';
import PdBlockHostsRequest from '@/api/pdBlockHostsRequest';
import PdUnblockHostsRequest from '@/api/pdUnblockHostsRequest';
import PdApplyConfigurationProfileRequest from '@/api/pdApplyConfigurationProfileRequest';
import ReactivationModal from './reactivationModal.vue';
import ConfigurationProfileDropdown from '@/ui/configurationProfileDropdown/index.vue';
import ConfirmationModal from '@/ui/confirmationModal/index.vue';
import CsvSaver from '@/ui/csvSaver.vue';
import PdHost from '@/models/pdHost';
import PdSubscription from '@/models/pdSubscription';
import {
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDB,
  STORE_NAME_CUSTOM,
  STORE_NAME_CB
} from '@core/constants/subscriptions';
import StatusExpired from '@/modules/subscriptions/list/subscriptionsList/item/statusLine/statuses/statusExpired.vue';
import StatusExpiringSoon from '@/modules/subscriptions/list/subscriptionsList/item/statusLine/statuses/statusExpireSoon.vue';
import Subscription, { VIEW_STATUS } from '@/models/subscription';
import {
  DESKTOP_CONFIGURATION_PROFILES_PAGE,
  DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
  RENEW_ONLINE_PAGE
} from '@/routes/constants';
import { DOWNGRADING_SUBSCRIPTION_PD } from '@core/constants/links';
import { Dictionary } from 'vue-router/types/router';

// TODO: move table interfaces to TableView class CPCLOUD-16423
interface TableItem {
  value: string;
  key: string;
  name: string;
  parent: boolean;
  subscription: PdSubscription;
}

interface TableViewAction {
  value: string;
  text: string;
  disabled?: boolean;
}

interface TableViewActions {
  id: string;
  options: TableViewAction[];
}

interface ActionCallParameters {
  action: string;
  items: TableItem[];
}

interface TableViewColumn {
  text: string;
  value: string;
  type?: string;
  options?: TableItem[];
  multiple?: boolean;
  hidden?: boolean;
  hideOnInit?: boolean;
}

interface TableViewColumns {
  options: TableViewColumn[];
}

interface TableFilters {
  [key: string]: Function;
}

interface IDeactivatedComputers {
  date: Date;
  forecastQtyDelta: number;
  subscription?: Subscription;
}

export default Vue.extend({
  name: 'desktop-computers-table',

  mixins: [componentMixin],

  components: {
    ConfirmationBlacklisting,
    ReactivationModal,
    ConfigurationProfileDropdown,
    ConfirmationModal,
    CsvSaver,
    StatusExpired,
    StatusExpiringSoon,
  },

  props: {
    contextProduct: {
      type: String,
    },
    mode: {
      type: String,
    },
    computers: {
      type: Array as PropType<PdHost[]>,
    },
    filters: {
      type: Object as PropType<TableFilters>,
    },
    keys: {
      type: Array as PropType<TableItem[]>,
    },
    subscriptions: {
      type: Array as PropType<PdSubscription[]>,
    },
    deactivatedComputers: {
      type: Array as PropType<IDeactivatedComputers[]>,
    },
    computersToBeDeactivatedPageInformer: {
      type: String,
    },
  },

  data () {
    return {
      table: {
        columns: [] as TableViewColumn[],
        selectedIds: [] as string[],
      },
      sortBy: {
        column: 'name',
        ascending: true,
      },
      customSorting: {
        ip_addresses (asc, column) {
          return (a, b) => {
            return sortByIp(a[column], b[column], asc);
          };
        },
      },
      pagination: {
        perPage: 50,
      },
      areComputersDeactivating: false,
      configurationProfileId: null as number,
      expiredSubscription: undefined as PdSubscription,
      expiringSubscription: undefined as PdSubscription,
      isSubsetStatus: false,
      DESKTOP_CONFIGURATION_PROFILES_PAGE,
      DOWNGRADING_SUBSCRIPTION_PD,
      DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
    };
  },

  methods: {
    onComputerClick (computer: PdHost): void {
      this.$emit('computerClick', computer);
    },

    callAction ({ action, items }: ActionCallParameters): void {
      (this[action] as CallbackFunctionType)(items);
    },

    exportCsv (computers: PdHost[]): void {
      (this.$refs.csv as unknown as typeof CsvSaver).save({
        columns: this.table.columns,
        rows: computers,
      });
    },

    deactivateComputers (): void {
      const hwIds = this.selectedActiveComputers.map((computer) => computer.hwId);
      const request = new PdDeactivateHostsRequest({ hwIds });
      this.areComputersDeactivating = true;
      this.authorizedCall(request).then(() => {
        this.$emit('hostsDeactivated', hwIds);
        this.showToast(this.$tc('{amount} computers have been deactivated.', hwIds.length, { amount: hwIds.length }));
      }).finally(() => {
        this.areComputersDeactivating = false;
      });
    },

    removeComputers (): void {
      const hwIds = this.selectedForDeleteComputers.map((computer) => computer.hwId);
      const request = new PdRemoveHostsRequest({ hwIds });
      this.authorizedCall(request).then(() => {
        this.$emit('hostsRemoved', hwIds);
        this.showToast(this.$tc('{amount} computers have been removed.', hwIds.length, { amount: hwIds.length }));
      });
    },

    unblockComputers (): void {
      const hwIds = this.selectedForUnblockComputers.map((computer) => computer.hwId);
      const request = new PdUnblockHostsRequest({ hwIds });
      this.$api.authorizedCall(request).then(() => {
        this.$emit('hostsUnblocked', hwIds);
        this.showToast(this.$tc('{amount} devices are no longer blacklisted. The devices need to be manually activated with a valid license key in order to resume Parallels Desktop on them.', hwIds.length, { amount: hwIds.length }));
      });
    },

    onBlacklistConfirmed (notes: string): void {
      const hwIds = this.selectedActiveComputers.map((computer) => computer.hwId);
      const request = new PdBlockHostsRequest({ hwIds: hwIds, comment: notes });
      this.$api.authorizedCall(request).then(() => {
        this.$emit('hostsBlocked', hwIds);
        this.showToast(this.$tc('{amount} computers blacklisted.', hwIds.length, { amount: hwIds.length }));
      });
    },

    onLicenseKeyClick (computer: PdHost): void {
      this.$router.push({ name: 'desktopSubscriptionDetails', params: { id: computer.subscriptionUuid } });
    },

    changeColumns (params): void {
      this.table.columns = params.columns;
    },

    showConfirmDeactivationModal (): void {
      this.$modal.show('confirmDeactivationModal');
    },

    showConfirmationBlacklistingModal (): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.confirmationBlacklistingModal.show(this.selectedActiveComputers.length);
    },

    showReactivationModal (): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.reactivation.show({
        hosts: this.selectedComputers,
        header: this.$t('Reactivate'),
      });
    },

    applyConfigurationProfile (hosts: PdHost[]): void {
      const hwIds = hosts.map((host) => (host.hwId));
      const request = new PdApplyConfigurationProfileRequest({
        configurationProfileId: this.configurationProfileId,
        hosts: hwIds,
      });

      this.authorizedCall(request).then(() => {
        this.$emit('configurationProfileApplied', hwIds);

        this.showToast(this.$tc(
          'Configuration profile "{name}" have been applied to {amount} computers.',
          hwIds.length,
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
          { amount: hwIds.length, name: this.$refs.configurationProfileDropdown.selectedName }
        ));
      });
    },

    showApplyConfigurationProfileModal (hosts: PdHost[]): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.applyConfigurationProfileModal.show({
        title: this.$tc('Apply Configuration Profile to {amount} Computers', hosts.length, { amount: hosts.length }),
        size: 18,
        btnLabel: this.$t('Apply'),
        success: (data) => { this.applyConfigurationProfile(hosts); },
      });
    },

    getHostIconName (host: PdHost): string {
      return host.isExpiringSoon ? 'alert-yellow' : 'alert';
    },

    showSubscriptionStatusModal (host: PdHost): void {
      this.isSubsetStatus = false;
      const uuid = host.subscription.uuid;
      const key = this.keys.find((key) => {
        // Look for parent key
        return key.subscription.uuid === uuid || key.subscription.subsets.find((subset) => {
          if (subset.uuid === uuid) {
            // If subset expires sooner than subscription
            if (subset.expirationDate && subset.expirationDate.getTime() !== key.subscription.expirationDate.getTime()) {
              this.isSubsetStatus = true;
            }
            return true;
          }
          return false;
        });
      });
      const subscription = this.subscriptions.find((subscription) => subscription.uuid === key.subscription.uuid);
      const refName = host.isExpiringSoon ? 'statusExpiringSoon' : 'statusExpired';
      const subscriptionPropertyName = host.isExpiringSoon ? 'expiringSubscription' : 'expiredSubscription';

      if (subscription) {
        this[subscriptionPropertyName] = subscription;
        this.$nextTick(() => {
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
          this.$refs[refName].showModal({ isSubset: this.isSubsetStatus });
        });
      }
    },

    onButtonClick (): void {
      const subscription = this.expiredSubscription || this.expiringSubscription;
      if (this.isSubsetStatus) {
        this.$router.push({
          name: 'desktopSubscriptionDetails',
          params: {
            id: subscription.uuid,
            product: this.contextProduct,
          },
        });
      } else if (subscription.storeId === STORE_NAME_CUSTOM || subscription.storeId === STORE_NAME_CB && subscription.isAutorenewable) {
        this.renewSubscription(subscription);
      } else if (subscription.storeId === STORE_NAME_CB && !subscription.isAutorenewable) {
        this.turnOnAutorenew(subscription);
      }
    },

    turnOnAutorenew (subscription: PdSubscription): void {
      this.$router.push({
        name: DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
        params: {
          id: subscription.uuid,
          product: this.contextProduct,
        },
        query: {
          turnAutoRenewOn: 'true',
        },
      });
    },

    renewSubscription (subscription: PdSubscription): void {
      let route;

      if (subscription.viewStatus === VIEW_STATUS.SF_EXPIRED && !subscription.isGracePeriod) {
        route = { name: 'desktopSubscriptionDetails', params: { id: subscription.uuid, product: this.contextProduct } };
      } else {
        route = { name: RENEW_ONLINE_PAGE, params: { id: subscription.uuid, byRef: true } };
      }

      this.$router.push(route);
    },
  },

  computed: {
    routeQuery (): Dictionary<string | (string | null)[]> {
      return this.$route.query;
    },

    isPdfc (): boolean {
      return this.contextProduct === PRODUCT_KEY_NAME_PDFC;
    },

    isPdb (): boolean {
      return this.contextProduct === PRODUCT_KEY_NAME_PDB;
    },

    tableName (): string {
      return `${this.contextProduct}Computers`;
    },

    selectedComputers (): PdHost[] {
      return this.table.selectedIds.map((id) => this.computers.find((computer) => computer.hwId === id)).filter((computer) => computer);
    },

    selectedActiveComputers (): PdHost[] {
      return this.selectedComputers.filter((computer) => computer.activated);
    },

    selectedForDeleteComputers (): PdHost[] {
      return this.selectedComputers.filter((computer) => !computer.activated && !computer.blocked);
    },

    selectedForUnblockComputers (): PdHost[] {
      return this.selectedComputers.filter((computer) => computer.blocked);
    },

    canManageConfigurationProfile (): boolean {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.isPdb && this.isFeatureAccessible(FEATURE_CONFIGURATION_PROFILE_PER_HOST);
    },

    actions (): TableViewActions {
      const actions: TableViewAction[] = [];

      if (this.canManageConfigurationProfile && this.selectedComputers.length) {
        actions.push({
          value: 'showApplyConfigurationProfileModal',
          text: this.$t('Apply Configuration Profile ({count})', { count: this.selectedComputers.length }),
        });
      }

      if (this.selectedActiveComputers.length) {
        actions.push({
          value: 'showConfirmDeactivationModal',
          text: this.$t('Deactivate ({count})', { count: this.selectedActiveComputers.length }),
        }, {
          value: 'showConfirmationBlacklistingModal',
          text: this.$t('Add to Blacklist ({count})', { count: this.selectedActiveComputers.length }),
        });
      }

      actions.push({
        value: 'showReactivationModal',
        text: this.$t('Reactivate ({count})', { count: this.selectedComputers.length }),
      });

      if (this.selectedForDeleteComputers.length) {
        actions.push({
          value: 'removeComputers',
          text: this.$t('Remove ({count})', { count: this.selectedForDeleteComputers.length }),
        });
      }

      if (this.selectedForUnblockComputers.length) {
        actions.push({
          value: 'unblockComputers',
          text: this.$t('Remove from Blacklist ({count})', { count: this.selectedForUnblockComputers.length }),
        });
      }

      actions.push({
        value: 'exportCsv',
        text: this.$t('Save List to CSV File ({count})'),
      });

      return {
        id: 'hwId',
        options: actions,
      };
    },

    columns (): TableViewColumns {
      const licenseKeyHidden: boolean = ['blacklisted', 'deactivated'].includes(this.mode);
      const statusHidden: boolean = this.mode !== 'all';
      const keys: TableItem[] = this.keys;
      const serialNumberColumnName: string = this.contextProduct === PRODUCT_KEY_NAME_PDFC ? this.$t('Directory API ID') : this.$t('Serial Number');

      const options: TableViewColumn[] = [
        { text: this.$t('Computer Name'), value: 'name' },
        { text: this.$t('License Key'), value: 'licKey', options: keys, multiple: true, hidden: licenseKeyHidden },
        { text: this.$t('Last Contact'), value: 'lastConnectionDate', type: 'date' },
        { text: this.$t('App Version'), value: 'buildNumber' },
        { text: this.$t('MAC Address'), value: 'macAddresses' },
        { text: this.$t('Status'), value: 'status', hidden: statusHidden, hideOnInit: true },
        { text: this.$t('Host OS'), value: 'osName', hideOnInit: true },
        { text: this.$t('User Name'), value: 'userName', hideOnInit: true },
        { text: this.$t('Activation Date'), value: 'activatedDate', type: 'date', hideOnInit: true },
        { text: serialNumberColumnName, value: 'serialNumber', hideOnInit: true },
        { text: this.$t('IPv4'), value: 'ipAddresses', hideOnInit: true },
        { text: this.$t('IPv6'), value: 'ip6Addresses', hideOnInit: true },
        { text: this.$t('Notes'), value: 'notes', hideOnInit: true },
      ];

      if (this.canManageConfigurationProfile) {
        options.push({ text: this.$t('Conf. Profile'), value: 'generalConfigurationProfile' });
      }

      return { options };
    },
  },
});

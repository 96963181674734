/**
 * File: ptbDeactivateHostsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class PtbDeactivateHostsRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/toolbox/licensing_api/v1/deactivate';
  }

  get body () {
    return {
      deactivate: this.params.hosts.map((host) => {
        const result = { hw_id: host.hwId };
        if (host.subscriptionUuid) {
          result.subscription_uuid = host.subscriptionUuid;
        }
        return result;
      }),
    };
  }
}

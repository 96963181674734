
import { defineComponent } from 'vue';

import CheckOemPartnerAbusedRequest from '@/api/checkOemPartnerAbusedRequest';
import ReportRedeemCodeAbuseRequest from '@/api/reportOemPartnerAbusedRequest';
import { LOGIN_PAGE, RESET_PASSWORD_PAGE } from '@/routes/constants';

export default defineComponent({
  name: 'oem-partner-abused',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      error: false,
      loading: true,
      email: '',
      resetPasswordActionCode: '',
      done: false,
    };
  },
  created () {
    const checkCodeRequest = new CheckOemPartnerAbusedRequest({ code: this.code });

    this.$api.call(checkCodeRequest).then((data) => {
      this.resetPasswordActionCode = data.resetPasswordActionCode;
      this.email = data.userEmail;
    }).catch(() => {
      this.error = true;
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    signIn () {
      this.$router.replace({ name: LOGIN_PAGE });
    },
    reportAbuse () {
      const request = new ReportRedeemCodeAbuseRequest({ code: this.code });

      this.$api.call(request).then(() => {
        this.done = true;
      }).catch(() => {
        this.$toast.show({
          text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
          color: 'red',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    resetPassword () {
      this.$router.push({
        name: RESET_PASSWORD_PAGE,
        params: {
          code: this.resetPasswordActionCode,
        },
      });
    },
  },
});


/**
 * File: ptbRemoveHostsRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class PtbRemoveHostsRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/toolbox/api/v1/ba/remove';
  }

  get body () {
    return { hosts: this.params.hwIds };
  }
}

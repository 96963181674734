/**
 * File: pmmRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import PMMPage from '../modules/pmm/pmmPage.vue';
import { PRODUCT_KEY_NAME_PMM } from '@core/constants/subscriptions';

export default [
  {
    path: '/pmm/servers',
    name: 'pmmServers',
    component: PMMPage,
    props: (route) => ({
      content: 'servers',
    }),
    meta: {
      requiresAuth: true,
      title: 'Proxy Servers - Parallels Device Management',
    },
  },
  {
    path: '/pmm/downloads',
    name: 'pmmDownloads',
    component: PMMPage,
    props: (route) => ({
      content: 'downloads',
      productKeyName: PRODUCT_KEY_NAME_PMM,
    }),
    meta: {
      requiresAuth: true,
      title: 'Download - Parallels Device Management',
    },
  },
  {
    path: '/pmm/sign',
    name: 'csrSign',
    component: PMMPage,
    props: (route) => ({
      content: 'csrSign',
    }),
    meta: {
      requiresAuth: true,
      title: 'Parallels Device Management',
    },
  },
  {
    path: '/pmm/offline',
    name: 'offlineValidate',
    component: PMMPage,
    props: (route) => ({
      content: 'offlineValidate',
    }),
    meta: {
      requiresAuth: true,
      title: 'Parallels Device Management',
    },
  },
  {
    path: '/pmm/subscriptions',
    name: 'pmmSubscriptions',
    component: PMMPage,
    props: (route) => ({
      content: 'subscriptions',
      productKeyName: PRODUCT_KEY_NAME_PMM,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Device Management',
    },
  },
  {
    path: '/pmm/subscriptions/:id',
    name: 'pmmSubscriptionDetails',
    component: PMMPage,
    props: (route) => ({
      content: 'pmmSubscriptionDetails',
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Device Management',
    },
  },
];


import { defineComponent } from 'vue';
import { maxLength, required } from '@core/common/validators';
import { LOGIN_PAGE } from '@/routes/constants';
import GetRtAuthTokenRequest from '@/api/getRtAuthTokenRequest';
import RtSessionRequest from '@/api/rt/rtSessionRequest';
import RtTicketsRequest from '@/api/rt/rtTicketsRequest';
import RtCreateTicketRequest from '@/api/rt/rtCreateTicketRequest';
import { ACCOUNT_FAILED_SIGN_IN_RT_QUEUE_ID, MYACCOUNT_PRODUCT_ID } from '@/api/rt/constants';

const MAX_DESCRIPTION_LENGTH = 255;

export default defineComponent({
  name: 'reporting-account-abuse-page',

  props: {
    code: {
      type: String,
      required: true,
    },
  },

  validations: {
    supportForm: {
      description: {
        required,
        maxLength: maxLength(MAX_DESCRIPTION_LENGTH),
      },
    },
  },

  data () {
    return {
      MAX_DESCRIPTION_LENGTH,
      supportForm: {
        description: '',
      },
      error: false,
      loading: false,
      rtToken: null as string,
      rtUrl: null as string,
    };
  },

  created () {
    this.checkRtQueue();
  },

  methods: {
    goToLoginPage () {
      this.$router.push({ name: LOGIN_PAGE });
    },
    async submitRequest () {
      const request = new RtCreateTicketRequest(
        {
          form: {
            product: MYACCOUNT_PRODUCT_ID,
            queue_id: ACCOUNT_FAILED_SIGN_IN_RT_QUEUE_ID,
            description: this.supportForm.description,
            summary: this.$t('My account was abused'),
          },
          rtUrl: this.rtUrl,
        }
      );
      this.loading = true;
      try {
        await this.$api.callRtWithToken(request, this.rtToken);
        this.$toast.show({
          text: this.$t('Request for support has been submitted.'),
        });
        this.goToLoginPage();
      } catch (e) {
        this.$toast.show({
          text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
          color: 'red',
        });
      } finally {
        this.loading = false;
      }
    },
    async checkRtQueue () {
      this.loading = true;
      try {
        const rtAuthTokenRequest = new GetRtAuthTokenRequest({ code: this.code });
        await this.$api.call(rtAuthTokenRequest);
        this.rtUrl = rtAuthTokenRequest.rtUrl;
        const rtSessionRequest = new RtSessionRequest({
          token: rtAuthTokenRequest.rtAuthToken,
          business: false,
          language: rtAuthTokenRequest.locale.split('_')[0],
          rtUrl: this.rtUrl,
        });
        await this.$api.call(rtSessionRequest);
        this.rtToken = rtSessionRequest.data.token;
        const rtTicketsRequest = new RtTicketsRequest({ queueId: ACCOUNT_FAILED_SIGN_IN_RT_QUEUE_ID, amountOnly: true, rtUrl: this.rtUrl });
        const ticketsStatistics = await this.$api.callRtWithToken(rtTicketsRequest, this.rtToken);
        this.error = ticketsStatistics.amount > ticketsStatistics.amountClosed;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
});


/**
 * File: loginRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import SignInPage from '@/modules/login/index.vue';
import ForgotPage from '@/modules/login/forgot/forgotPage.vue';
import ResetPage from '@/modules/login/reset/resetPage.vue';
import RegisterSocial from '@/modules/login/socialRegister.vue';
import SocialLoginErrorsPage from '@/modules/login/socialLoginErrors.vue';
import TwoStepVerificationPageOneTimePassword from '@/modules/login/mfa/twoStepVerificationPageOneTimePassword.vue';
import TwoStepVerificationPageRescueCode from '@/modules/login/mfa/twoStepVerificationPageRescueCode.vue';
import {
  FORGOT_PASSWORD_PAGE,
  LOGIN_PAGE,
  MFA_ONE_TIME_PASSWORD_PAGE,
  MFA_RESCUE_CODE_PAGE,
  REGISTER_PAGE,
  REGISTER_SOCIAL_PAGE,
  RESET_PASSWORD_PAGE,
  SOCIAL_LOGIN_ERRORS_PAGE
} from '@/routes/constants';

export default [
  {
    path: '/login',
    name: LOGIN_PAGE,
    component: SignInPage,
    meta: {
      title: 'Login',
      ignoreAnalytics: true,
    },
  },
  {
    path: '/register',
    name: REGISTER_PAGE,
    component: SignInPage,
    meta: {
      title: 'Register',
      ignoreAnalytics: true,
    },
  },
  {
    path: '/register_social',
    name: REGISTER_SOCIAL_PAGE,
    component: RegisterSocial,
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/forgot',
    name: FORGOT_PASSWORD_PAGE,
    component: ForgotPage,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    path: '/action/account/restore/:code',
    name: RESET_PASSWORD_PAGE,
    props: true,
    component: ResetPage,
    meta: {
      ignoreAnalytics: true,
    },
  },
  {
    path: '/:type(ap|fb|gp)/:result(confirm|unconfirmed|error|general_error|success|try_again|ba_denied|missing_grants|invalid_email|account_deleted|interrupted)',
    name: SOCIAL_LOGIN_ERRORS_PAGE,
    props: true,
    component: SocialLoginErrorsPage,
  },
  {
    path: '/mfa/otp',
    name: MFA_ONE_TIME_PASSWORD_PAGE,
    component: TwoStepVerificationPageOneTimePassword,
    meta: {
      title: 'Two-Step Verification',
      ignoreAnalytics: true,
    },
  },
  {
    path: '/mfa/rescue_code',
    name: MFA_RESCUE_CODE_PAGE,
    component: TwoStepVerificationPageRescueCode,
    meta: {
      title: 'Two-Step Verification',
      ignoreAnalytics: true,
    },
  },
];

/**
 * File: pmmDeactivateSiteRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PmmSitesRequest from './pmmSitesRequest';

export default class PmmDeactivateSiteRequest extends ApiRequest {
  get method () {
    return 'DELETE';
  }

  get url () {
    if (this.params.subscription_uuid) {
      return `/pmm/webapp/sites/${this.params.uuid}/subscriptions/${this.params.subscription_uuid}`;
    } else {
      return `/pmm/webapp/sites/${this.params.uuid}`;
    }
  }

  load () {
    return super.load()
      .then((data) => {
        new PmmSitesRequest({}).dropCache(); // FIXME it should be static
        return data;
      });
  }
}

/**
 * File: genAuthTokenRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class GenAuthTokenRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.service
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get url () {
    return '/account/api/v1/gen_auth_token';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return { service: this.params.service };
  }
}

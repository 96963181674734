/**
 * File: rtSendAttachmentsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtSendAttachmentsRequest extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {Number} params.ticketId
   * @param {Array<File>} params.files
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
    this.headers['Content-Type'] = 'multipart/form-data';
  }

  /**
   * @returns {String}
   */
  get uri () {
    return `/ticket/${this.params.ticketId}/attachments`;
  }

  get method () {
    return 'POST';
  }

  get options () {
    const
      options = super.options;
    const body = new FormData();

    this.params.files.forEach((f) => {
      body.append('attach', f);
    });
    options.body = body;

    return options;
  }
}

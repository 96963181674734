<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import { prodKeyToName, PRODUCT_KEY_NAME_PTB } from '@core/constants/subscriptions';

export default {
  name: 'open-product',

  data () {
    const product = this.$route.query.product || PRODUCT_KEY_NAME_PTB;
    return {
      productName: $t(prodKeyToName(product)),
    };
  },
};

</script>

<template lang="pug">

page(:appData="$appData")
  .row(slot="pageContent")
    card.col-sm-8.col-sm-offset-2.col-xs-12(type="action")
      template(slot="title")
        div {{ $t('Login Succeeded') }}
      .image-restore-purchases.center-block(slot="image")
      div(slot="content") {{ $t('Now you can switch to {productName} and start using it.', { productName }) }}

</template>

<style scoped lang="sass">

$image-ratio: 185 / 135

$image-height: 165px
$image-width: $image-height * $image-ratio

.image-restore-purchases
  width: $image-width
  height: $image-height
  background: url('#{ $image-root }/restorepurchases.svg') no-repeat
  background-size: $image-width $image-height

</style>

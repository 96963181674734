/**
 * File: activateSubscriptionRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { Method } from '@core/api/apiRequest';
import ApiRequestWithFallback from '@core/api/apiRequestWithFallback';
import Cache from '@core/api/cache';
import { HTTP_CODES } from '@core/constants/http';

interface ActivateSubscriptionParams {
  licKey: string;
  cepOption: boolean;
  bulaAccepted: boolean;
  domainId?: number;
  name?: string;
}

interface ActivateSubscriptionResponse {
  msg?: string;
  isBusinessAccountRequired?: boolean;
}

export default class ActivateSubscriptionRequest extends ApiRequestWithFallback<ActivateSubscriptionParams, ActivateSubscriptionResponse> {
  get method (): Method {
    return 'POST';
  }

  get urlSuffix (): string {
    return '/api/v1/subscriptions/activate';
  }

  get body () {
    const body: any = {
      extended_response: true,
      lic_key: (this.params.licKey || '').replace(/[-\s]/g, '').toUpperCase(),
      set_cep_option: this.params.cepOption,
      set_bula_option: this.params.bulaAccepted,
    };
    if (this.params.domainId) {
      body.domain_id = this.params.domainId;
    }
    if (this.params.name) {
      body.name = this.params.name;
    }
    return body;
  }

  checkStatus (response) {
    return this.getJson(response).then(
      (data) => {
        if (this.needFallback(response, data)) {
          return this.getFallbackPromise();
        } else if (response.status === HTTP_CODES.CONFLICT) {
          // This way we can handle all 409 cases in success handler, except if we need to perform fallback request
          return response;
        } else {
          return super.checkStatus(response);
        }
      }
    );
  }

  needFallback (response, data): boolean {
    return (response.status === HTTP_CODES.CONFLICT && data.msg === 'invalid license key');
  }

  // Special behaviour:
  //    409 msg: "can be registered just under business account" is not an error, it tells to show BA form
  getPromise (): Promise<ActivateSubscriptionResponse> {
    return super.getPromise()
      .then((data) => {
        if (data.msg === 'can be registered just under business account') {
          return { isBusinessAccountRequired: true };
        } else {
          if (data.msg) {
            return data;
          } else {
            // Successful activation
            const cache = new Cache();
            cache.dropNameSpace('subscriptions');
            return data;
          }
        }
      });
  }
}

/**
 * File: profileRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { appData } from '@/data/appData';
import PersonalProfile from '@/modules/personalProfile/personalProfile.vue';
import BusinessProfile from '@/modules/businessProfile/businessProfile.vue';
import EmailChangeSecurityNoticePage from '@/modules/personalProfile/emailChangeSecurityNoticePage.vue';
import InvitationsList from '@/modules/invitations/invitationsList.vue';
import CantUseAccountRescueCodesPage from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/cantUseAccountRescueCodesPage.vue';
import {
  BUSINESS_PROFILE_SCOPE,
  CANT_USE_RESCUE_CODES_PAGE,
  EMAIL_CHANGE_SECURITY_NOTICE_PAGE,
  INVITATIONS_LIST_PAGE,
  PERSONAL_PROFILE_SCOPE, SECURITY_PAGE, SSO_MEMBER_DETAILS_PAGE,
  USERS_PAGE
} from '@/routes/constants';
import BusinessProfileSSOMemberDetailsPage
  from '@/modules/businessProfile/businessProfileMembersSSO/businessProfileSSOMemberDetailsPage.vue';

export default [
  {
    name: PERSONAL_PROFILE_SCOPE,
    path: '/profile/personal/:page',
    component: PersonalProfile,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Personal Profile',
      ignoreAnalytics: true,
    },
  },
  {
    name: BUSINESS_PROFILE_SCOPE,
    path: '/profile/business/:page',
    component: BusinessProfile,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Business Profile',
    },
    beforeEnter (to, from, next) {
      if (!appData.session.getCurrentBusinessUser()) {
        next('/');
        return;
      }

      if ([USERS_PAGE, SECURITY_PAGE].includes(to.params.page) && !appData.session.isBusinessAdmin) {
        next('/');
        return;
      }

      next();
    },
  },
  {
    name: EMAIL_CHANGE_SECURITY_NOTICE_PAGE,
    path: '/profile/email_change_security_notice',
    component: EmailChangeSecurityNoticePage,
  },
  {
    name: INVITATIONS_LIST_PAGE,
    path: '/profile/business_invitations',
    component: InvitationsList,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: CANT_USE_RESCUE_CODES_PAGE,
    path: '/profile/personal/cant_use_rescue_codes/:mode',
    component: CantUseAccountRescueCodesPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Can\'t use rescue codes page',
      ignoreAnalytics: true,
    },
  },
  {
    name: SSO_MEMBER_DETAILS_PAGE,
    path: '/profile/business/sso_users/:memberId',
    component: BusinessProfileSSOMemberDetailsPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'User Details',
    },
  },
];


import Vue from 'vue';

import GeneratePackagedLicensesModal from './generatePackagedLicensesModal.vue';
import GenerateRedeemCodeModal from './generateRedeemCodeModal.vue';

export default Vue.extend({
  name: 'select-oem-licenses-type-modal',

  data () {
    return {
      createStandaloneLicenses: true,
    };
  },

  computed: {
    buttonText (): string {
      if (this.createStandaloneLicenses) {
        return this.$t('Create Standalone Licenses');
      } else {
        return this.$t('Create Packaged Licenses');
      }
    },
  },

  methods: {
    licensesTypeChanged (value: boolean) {
      this.createStandaloneLicenses = value;
    },
    showGenerateDialog () {
      this.$modal.hide();
      this.$modal.show(this.createStandaloneLicenses ? GenerateRedeemCodeModal : GeneratePackagedLicensesModal);
    },
  },
});


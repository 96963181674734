<copyright>
File: ticketContent.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<style scoped lang="sass">

.content
  white-space: pre-line

</style>

<template lang="pug">

formatter.content(:text="formattedText")
  template(:slot="linkName", v-for="(linkText, linkName) in links")
    a(:href="links[linkName]", target="_blank") {{ links[linkName] }}

</template>

<script>

const httpHrefReg = /((?:https?(?::\/\/))(?:www\.)?[a-zA-Z\d-_.]+(?:\.[a-zA-Z\d]{2,})(?:(?:[-a-zA-Z\d:%_+.~#!?&//=@]*)(?:[,](?![\s]))*)*)/ig;

export default {
  name: 'support-ticket-content',
  props: {
    text: {
      type: String,
    },
  },

  data () {
    return {
      formattedText: '',
      links: {},
    };
  },

  mounted () {
    this.parseLinks();
  },

  watch: {
    text (newVal) {
      if (newVal) {
        this.parseLinks();
      }
    },
  },

  methods: {
    parseLinks () {
      let i = 0;

      this.formattedText = this.text;
      this.links = {};

      this.formattedText = this.formattedText.replace(httpHrefReg, (match) => {
        const name = `link${i++}`;
        this.links[name] = match;
        // Returns string like {link1}http://my.parallels.com{/link1}
        return `{${name}}${match}{/${name}}`;
      });
    },
  },
};

</script>

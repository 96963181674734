/**
 * File: checkOemPartnerAbusedRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import { camelize } from '@core/common/utils';
import { licenseService } from '@core/constants/links';

interface CheckOemPartnerAbusedRequestParams {
  code: string;
}

interface CheckOemPartnerAbusedRequestResponse {
  userName: string;
  userEmail: string;
  resetPasswordActionCode?: string;
}

export default class CheckOemPartnerAbusedRequest extends ApiRequest<CheckOemPartnerAbusedRequestParams, CheckOemPartnerAbusedRequestResponse> {
  get url () {
    return `${licenseService.OEM_PARTNER_ABUSED}/${this.params.code}`;
  }

  onFetchSuccess (data) {
    return camelize(data);
  }
}

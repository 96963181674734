/**
 * File: addNewAuthenticatorAppRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

import AuthenticatorApp from '@/models/authenticatorApp';

import { patchAuthenticatorAppName, AuthenticatorAppResponse } from './mfaUtils';

/* eslint-disable camelcase */
interface AddNewAuthenticatorAppResponseData {
  auth_app: AuthenticatorAppResponse;
  rescue_codes: string[];
}
/* eslint-enable camelcase */

interface AddNewAuthenticatorAppRequestParams {
  secret: string;
  oneTimePassword: string;
}

export default class AddNewAuthenticatorAppRequest extends ApiRequest<AddNewAuthenticatorAppRequestParams, AddNewAuthenticatorAppResponseData> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/auth_apps`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      secret: this.params.secret,
      one_time_password: this.params.oneTimePassword,
    };
  }

  getAuthenticatorApp (index: number): AuthenticatorApp {
    return new AuthenticatorApp(patchAuthenticatorAppName(this.data.auth_app, index));
  }

  getRescueCodes (): string[] {
    return this.data.rescue_codes;
  }
}

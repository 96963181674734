/**
 * File: columnsObserverMixin.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

export default {
  created () {
    if (!this.columnsConfigKeyName) {
      return;
    }

    const config = JSON.parse(localStorage.getItem(this.columnsConfigKeyName));

    if (config && config.columns) {
      this.table.columns = config.columns.filter((value) => {
        return this.columnsOptions.some((column) => column.value === value);
      });
    }
  },
  computed: {
    columnsConfigKeyName () {
      return this.table && this.table.name && `columns_config_${this.table.name}`;
    },
  },
  watch: {
    'table.columns': function (columns) {
      if (this.columnsConfigKeyName) {
        localStorage.setItem(this.columnsConfigKeyName, JSON.stringify({
          columns,
        }));
      }
    },
  },
};

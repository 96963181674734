/**
 * File: pageTitle.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import router from '@/router';

import {
  prodKeyToName,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDPRO,
  PRODUCT_NAME_PDFM_ST,
  PRODUCT_NAME_PDFM_PRO
} from '@core/constants/subscriptions';

const SUPPORT_TYPE = {
  presales: 'Presales',
  license: 'License',
  technical: 'Technical',
};

function getProductName (productKey) {
  if (productKey === PRODUCT_KEY_NAME_PDFM) {
    return PRODUCT_NAME_PDFM_ST;
  } else if (productKey === PRODUCT_KEY_NAME_PDPRO) {
    return PRODUCT_NAME_PDFM_PRO;
  }
  return prodKeyToName(productKey);
}

router.beforeEach((to, from, next) => {
  const route = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  let titleExtension = 'Parallels My Account';

  if (route) {
    let title = route.meta.title;
    if (title === 'Support') {
      if (to.params.product) {
        titleExtension = getProductName(to.params.product);
      }
      if (to.params.supportType) {
        title = SUPPORT_TYPE[to.params.supportType];
      }
      if (to.params.action && to.params.action === 'request') {
        title = 'Get Support';
      }
    }
    document.title = title + ' - ' + titleExtension;
  } else {
    document.title = titleExtension;
  }
  next();
});


import Vue from 'vue';

import { password } from '@core/common/validators';
import ChangePasswordRequest, { ChangePasswordRequestResponse } from '@/api/changePasswordRequest';
import formMixin from '@/modules/formMixin';
import DisconnectHostsHint from '@/ui/disconnectHostsHint/index.vue';
import RestorePasswordMixin from '@/modules/personalProfile/restorePasswordMixin';

export default Vue.extend({
  name: 'password-change',

  components: {
    DisconnectHostsHint,
  },

  mixins: [formMixin, RestorePasswordMixin],

  data () {
    return {
      loading: false,
      password: '',
      newPassword: '',
      disconnectAll: true,
    };
  },
  validations: {
    password,
    newPassword: {
      ...password,
      notSameAsEmail: (value, self) => value !== self.$appData.session.email,
    },
  },
  methods: {
    save () {
      this.loading = true;

      const changePassword = new ChangePasswordRequest({
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16832
        password: this.password,
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16832
        new_password: this.newPassword,
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16832
        disconnect_remote_hosts: this.disconnectAll,
      });

      this.$api.authorizedCall(changePassword).then((data: ChangePasswordRequestResponse) => {
        this.$appData.session.lepc = Math.floor(Date.now() / 1000);
        this.$appData.session.token = data.token;
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16832
        this.clear();
        this.$modal.hide();
        this.$toast.show({
          text: this.$t('Your Parallels account password has been changed.'),
          color: 'green',
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    clear () {
      this.$v.$reset();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16832
      this.password = '';
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16832
      this.newPassword = '';
    },
    cancel () {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16832
      this.clear();
      this.$modal.hide();
    },
  },
});


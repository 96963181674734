

import ProductCardMixIn from './productCardMixIn';
import { PRODUCT_NAME_PVAD, PRODUCT_KEY_NAME_PVAD } from '@core/constants/subscriptions';
import { GO_PAGE, PVAD_SUBPAGE_SUBSCRIPTIONS } from '@/routes/constants';
import Vue from 'vue';

export default Vue.extend({
  name: 'product-card-pvad',
  data () {
    return {
      PVAD_SUBPAGE_SUBSCRIPTIONS,
      PRODUCT_NAME_PVAD,
      GO_PAGE,
      PRODUCT_KEY_NAME_PVAD,
      supportMailLink: 'mailto:cloud-beta@support.parallels.com',
    };
  },
  mixins: [ProductCardMixIn],
});


/**
 * File: chatClientRasa.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import { io, Socket } from 'socket.io-client';
import { Agent } from '@/api/pbcRequest';
import { ChatClientProvider, ChatStatus, MessageType } from '@/modules/support/content/chatClient/chatClientProvider';
import Vue from 'vue';

export default class ChatClientRasa implements ChatClientProvider {
  readonly name: string;
  private socket: Socket;
  private context: Vue;

  constructor (context: Vue, agent: Agent) {
    this.name = 'Rasa';
    this.context = context;
    const socketUrl = new URL(agent.url);
    let socketPathname = '';
    if (socketUrl.pathname.length > 1) {
      socketPathname = '/' + socketUrl.pathname.split('/').filter(itm => itm !== '').join('/');
    }

    this.connect(
      socketUrl.origin,
      `/${socketPathname || socketUrl.pathname}/socket.io`
    );
  }

  private connect (host: string, path: string) {
    this.socket = io(host, { path: path });

    this.socket.on('connect', () => {
      this.socket.emit('session_request', {
        session_id: this.socket.id,
      }, () => {
        this.context.$emit('typing', false);
        this.context.$emit('change-status', ChatStatus.Active);
        this.context.$emit('ready');
      });
    });

    this.socket.on('connect_error', () => {
      this.context.$emit('change-status', ChatStatus.Error);
    });

    this.socket.on('bot_uttered', (response) => {
      this.context.$emit('message', 'text', MessageType.AgentInput, {
        ...response,
        name: this.name,
        sent: false,
      });
      this.context.$emit('typing', false);
    });
  }

  public disconnect () {
    this.socket.disconnect();
    this.context.$emit('typing', false);
    this.context.$emit('change-status', ChatStatus.Disconnected);
    this.context.$off('ready');
    this.context.$emit('disconnected');
  }

  send (message: string, hidden: boolean = false) {
    this.socket.emit('user_uttered', {
      message: message,
      session_id: this.socket.id,
    });
    if (!hidden) {
      this.context.$emit('message', 'text', MessageType.UserInput, { text: message, sent: false });
      this.context.$emit('typing', true);
    }
  }
}

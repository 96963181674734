<copyright>
File: unsupportedCountry.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import ErrorPage from '@core/pages/error';

export default {
  name: 'unsupported-country',
  props: ['appData'],
  components: {
    ErrorPage,
  },
};

</script>

<template lang="pug">

error-page
  template(slot="header")
    .text-big Access Denied
  template(slot="text")
    | You are signing in from a country or territory where Parallels products are not supported.
  template(slot="button")
    btn(href="https://www.parallels.com/about/contact/#MGMTFeedback", target="_blank") Contact Parallels

</template>

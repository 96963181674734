/**
 * File: pbiRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  PBI_SUBPAGE_SUBSCRIPTIONS,
  PBI_SUBPAGE_SUBSCRIPTION_DETAILS, PBI_DOWNLOADS_PAGE
} from './constants';
import pbiPage from '@/modules/pbi/pbiPage.vue';
import { PRODUCT_KEY_NAME_PBI, PRODUCT_NAME_PBI } from '@core/constants/subscriptions';

export default [
  {
    path: '/pbi/subscriptions',
    name: PBI_SUBPAGE_SUBSCRIPTIONS,
    component: pbiPage,
    props: () => ({
      content: PBI_SUBPAGE_SUBSCRIPTIONS,
      productKeyName: PRODUCT_KEY_NAME_PBI,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_PBI}`,
    },
  },
  {
    path: '/pbi/subscriptions/:id',
    name: PBI_SUBPAGE_SUBSCRIPTION_DETAILS,
    component: pbiPage,
    props: () => ({
      content: PBI_SUBPAGE_SUBSCRIPTION_DETAILS,
      productKeyName: PRODUCT_KEY_NAME_PBI,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_PBI}`,
    },
  },
  {
    path: '/pbi/downloads',
    name: PBI_DOWNLOADS_PAGE,
    component: pbiPage,
    props: () => ({
      content: PBI_DOWNLOADS_PAGE,
      productKeyName: PRODUCT_KEY_NAME_PBI,
    }),
    meta: {
      requiresAuth: true,
      title: `Download - ${PRODUCT_NAME_PBI}`,
    },
  },
];

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import GetRestorePurchasesDataRequest from '@/api/getRestorePurchasesDataRequest';
import RestorePurchasesRequest from '@/api/restorePurchasesRequest';

export default {
  name: 'restore-purchases',

  props: {
    code: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      prevEmail: null,
      newEmail: null,
      loading: true,
      error: false,
    };
  },
  async mounted () {
    try {
      const data = await this.$api.call(new GetRestorePurchasesDataRequest({ code: this.code }));
      this.prevEmail = data.prev_user_email;
      this.newEmail = data.new_user_email;
    } catch (e) {
      this.error = true;
    }
    this.loading = false;
  },
  methods: {
    async restoreClick () {
      this.loading = true;
      try {
        await this.$api.call(new RestorePurchasesRequest({ code: this.code }));
        this.$router.push({ name: 'login' });
      } catch (e) {
        this.error = true;
      }
    },
  },
};

</script>

<template lang="pug">

page(:appData="$appData", :error="error", :loading="loading")
  .row(slot="pageContent")
    card.col-sm-8.col-sm-offset-2.col-xs-12(type="action")
      template(slot="title")
        div {{ $t('Access to Your Parallels Purchases Has Been Restored') }}
      .image-restore-purchases.center-block(slot="image")
      template(slot="content")
        formatter.text-center(:text="$t('From now on, your Parallels purchases registered in the Parallels account {newEmail} can be also used when you sign in with the Parallels account {prevEmail}. To ensure your Parallels applications continue working correctly, we recommend that you sign out and then sign in again in each app.')")
          span.text-bold(slot="prevEmail") {{ prevEmail }}
          span.text-bold(slot="newEmail") {{ newEmail }}
      .text-center(slot="buttons")
        btn(@click="restoreClick", color="blue") {{ $t('Continue') }}
  template(slot="error-header") {{ $t('Expired Action Link') }}
  template(slot="error-text") {{ $t('The link has expired and cannot be used anymore.') }}
  template(slot="error-button")
    btn(@click="$router.push({ name: 'login' })", :data-name="$name('button-sign-in')") {{ $t('Go to My Account') }}

</template>

<style scoped lang="sass">

$image-ratio: 185 / 135

$image-height: 165px
$image-width: $image-height * $image-ratio

.image-restore-purchases
  width: $image-width
  height: $image-height
  background: url('#{ $image-root }/restorepurchases.svg') no-repeat
  background-size: $image-width $image-height

</style>

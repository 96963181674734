/**
 * File: rasAzmpRoutes.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  RAS_AZMP_SUBPAGE_SUBSCRIPTIONS,
  RAS_AZMP_SUBPAGE_SUBSCRIPTION_DETAILS,
  RAS_AZMP_DOWNLOADS_PAGE
} from './constants';
import {
  PRODUCT_KEY_NAME_RAS_AZMP,
  PRODUCT_NAME_RAS_AZMP
} from '@core/constants/subscriptions';
import rasAzmpPage from '@/modules/rasAzmp/rasAzmpPage.vue';

export default [
  {
    path: '/ras-azmp/subscriptions',
    name: RAS_AZMP_SUBPAGE_SUBSCRIPTIONS,
    component: rasAzmpPage,
    props: () => ({
      content: RAS_AZMP_SUBPAGE_SUBSCRIPTIONS,
      productKeyName: PRODUCT_KEY_NAME_RAS_AZMP,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_RAS_AZMP}`,
    },
  },
  {
    path: '/ras-azmp/subscriptions/:id',
    name: RAS_AZMP_SUBPAGE_SUBSCRIPTION_DETAILS,
    component: rasAzmpPage,
    props: () => ({
      content: RAS_AZMP_SUBPAGE_SUBSCRIPTION_DETAILS,
      productKeyName: PRODUCT_KEY_NAME_RAS_AZMP,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_RAS_AZMP}`,
    },
  },
  {
    path: '/ras-azmp/downloads',
    name: RAS_AZMP_DOWNLOADS_PAGE,
    component: rasAzmpPage,
    props: () => ({
      content: RAS_AZMP_DOWNLOADS_PAGE,
      productKeyName: PRODUCT_KEY_NAME_RAS_AZMP,
    }),
    meta: {
      requiresAuth: true,
      title: `Download - ${PRODUCT_NAME_RAS_AZMP}`,
    },
  },
];


import Vue, { PropType } from 'vue';

import PermissionsRequest from '@/api/permissionsRequest';
import CompanyUsersRequest from '@/api/companyUsersRequest';
import PermissionsChangeRequest from '@/api/permissionsChangeRequest';
import { sortByValue } from '@core/common/sorters';
import Subscription from '@/models/subscription';
import CompanyUser from '@/models/companyUser';
import { BUSINESS_PROFILE_SCOPE, SSO_USERS_PAGE, USERS_PAGE } from '@/routes/constants';
import { Json } from '@core/api/apiRequest';

export default Vue.extend({
  name: 'permissions-tab',

  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true,
    },
    subset: {
      type: Object,
    },
  },

  data () {
    return {
      users: [] as CompanyUser[],
      selectedUser: null as number,
      subsetPermissions: [],
      loading: false,
    };
  },

  created () {
    this.loadUsers().then(() => {
      this.reset();
    });
    this.$bus.$on('subsetSaved', this.save);
  },

  destroyed () {
    this.$bus.$off('subsetSaved', this.save);
  },

  methods: {
    reset () {
      this.selectedUser = null;
      this.subsetPermissions = this.subset ? [] : this.admins.slice();
    },

    loadUsers () {
      const companyUsersRequest = new CompanyUsersRequest({}, this.$appData.session.businessDomainId);
      this.loading = true;
      return this.$api.authorizedCall(companyUsersRequest).then(() => {
        this.users = companyUsersRequest.getUsers();
      }).finally(() => {
        this.loading = false;
      });
    },

    loadPermissions () {
      const request = new PermissionsRequest({
        uuid: this.uuid,
        service: this.subscription.targetServiceName,
      });

      this.loading = true;
      this.$api.authorizedCall(request).then((data) => {
        this.subsetPermissions = data.users.map((permission) => {
          const user = this.users.find((user) => user.uuid === permission.id);
          if (user) {
            permission.is_blocked = user.isBlocked;
          }
          return permission;
        });
      }).finally(() => {
        this.loading = false;
      });
    },

    listItemColor (user): string {
      if (user.is_admin) return 'muted';
      if (user.is_blocked) return 'danger';
      return 'default';
    },

    save (params) {
      const uuid = params.uuid; // || this.subset.subsetUuid || this.subset.subscriptionUuid;
      const members = this.subsetPermissions.filter((user) => !user.is_admin).map((user) => user.id);
      const service = this.subscription.targetServiceName;
      const request = new PermissionsChangeRequest({ uuid, members, service });
      const promise = this.$api.authorizedCall(request);

      this.$emit('save', promise);
    },

    addPermission () {
      const user = this.users.find((u) => u.uuid === this.selectedUser);

      if (user) {
        this.selectedUser = null;
        this.subsetPermissions.push({
          id: user.uuid,
          email: user.email,
          name: user.name,
          is_admin: false,
          is_removable: true,
          is_blocked: user.isBlocked,
        });
      }
    },

    removePermission (userId) {
      const i = this.subsetPermissions.map((user) => user.id).indexOf(userId);
      this.subsetPermissions.splice(i, 1);
    },

    manageUsersClicked () {
      if (this.$appData.session.sso) {
        this.$router.push({ name: BUSINESS_PROFILE_SCOPE, params: { page: SSO_USERS_PAGE } });
      } else {
        this.$router.push({ name: BUSINESS_PROFILE_SCOPE, params: { page: USERS_PAGE } });
      }
    },
  },

  computed: {
    uuid (): string {
      return this.subset.subsetUuid || this.subset.subscriptionUuid;
    },

    activeNonAdminUsers (): CompanyUser[] {
      return this.users.filter((user) => {
        // User is not disabled, not admin and accepted invitation
        return !user.isBlocked && !user.isAdmin && !user.isInvited;
      });
    },

    userOptions (): Json {
      const presentUsers = this.subsetPermissions.reduce((res, user) => ({ [user.id]: user, ...res }), {});
      const users = this.activeNonAdminUsers
        .filter(user => !presentUsers[user.uuid])
        .map(user => ({
          value: user.uuid,
          ...user,
        }));
      users.sort((a, b) => sortByValue(a.name.toLowerCase(), b.name.toLowerCase(), true));
      return users;
    },

    permissions (): Json {
      const permissions = this.subsetPermissions;

      const adminsPermissions = permissions.filter(user => user.is_admin);
      adminsPermissions.sort((a, b) => sortByValue(a.name.toLowerCase(), b.name.toLowerCase(), true));

      const membersPermissions = permissions.filter(user => !user.is_admin);
      membersPermissions.sort((a, b) => sortByValue(a.name.toLowerCase(), b.name.toLowerCase(), true));

      return adminsPermissions.concat(membersPermissions);
    },

    userById (): Json {
      return this.users.reduce((res, user) => ({ [user.uuid]: user, ...res }), {});
    },

    admins (): Json[] {
      return this.users.filter((user) => user.isAdmin && !user.isInvited && !user.isBlocked).map((user) => {
        return {
          email: user.email,
          name: user.name,
          id: user.uuid,
          is_admin: user.isAdmin,
          is_removable: !user.isAdmin,
          is_blocked: user.isBlocked,
        };
      });
    },
  },

  watch: {
    subset (value) {
      if (value) {
        this.loadPermissions();
      } else {
        this.reset();
      }
    },
  },
});


/**
 * File: pdConfigurationProfileTemplateRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class PdConfigurationProfileTemplateRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {?String} params.version
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get cacheNameSpace () {
    return false;
  }

  get url () {
    let uri = '/desktop/api/v1/ba/configuration_profiles_template/';
    if (this.params.version) {
      uri += this.params.version;
    }
    return uri;
  }
}

/**
 * File: downloadsRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import DownloadsPage from '../modules/downloads/downloadsPage.vue';

export default [
  {
    path: '/downloads',
    name: 'downloads',
    component: DownloadsPage,
    meta: {
      requiresAuth: true,
      title: 'Download',
    },
  },
];

/**
 * File: universalKeysRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import CombinedApiRequest from '@core/api/combinedApiRequest';
import UniversalKey from '@/models/universalKey';
import LsUniversalKeysRequest from './lsUniversalKeysRequest';
import RasLsUniversalKeysRequest from './rasLsUniversalKeysRequest';

const REQ_NAME_LS_UNIVERSAL_KEYS = 'ls';
const REQ_NAME_RAS_LS_UNIVERSAL_KEYS = 'rasls';

export default class UniversalKeysRequest extends CombinedApiRequest {
  get cacheNameSpace () {
    return 'universal_keys';
  }

  /**
   *
   * @param {?Object} params
   * @param {?Boolean} params.business
   */
  constructor (params) {
    super({ integrity: false });
    this.addRequest(REQ_NAME_LS_UNIVERSAL_KEYS, new LsUniversalKeysRequest(params));
    this.addRequest(REQ_NAME_RAS_LS_UNIVERSAL_KEYS, new RasLsUniversalKeysRequest(params));
  }

  /**
   *
   * @returns {Array<Subscription>} - list of Subscriptions models.
   */
  getUniversalKeys () {
    return this.data.universal_keys.map((data) => new UniversalKey(data));
  }

  combineData (data) {
    let universalKeys = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i] && data[i].universal_keys) {
        universalKeys = universalKeys.concat(data[i].universal_keys);
      }
    }
    return { universal_keys: universalKeys };
  }
}

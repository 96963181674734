/**
 * File: generateCartBuyLinkRequest.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class GenerateCartBuyLinkRequest extends ApiRequest {
  get url () {
    const query = this.buildQueryString(this.params);
    return `/license/api/v1/stores/cb/cart${query}`;
  }
}



import isEqual from 'lodash-es/isEqual';
import Vue from 'vue';

export default Vue.extend({
  name: 'filter-dropdown-my',

  props: {
    /**
     * Filter name to set
     */
    name: String,

    /**
     * Data to search in
     */
    data: Array,

    /**
     * Set of currently active search filters
     */
    filters: Object,

    /**
     * Manage disabled state.
     */
    disabled: Boolean,

    /**
     * Initial value.
     */
    value: {
      type: String,
    },

    /**
     * Dropdown options.
     * Example: [
     *  {
     *     text: this.$t('Expiring Soon ({count})'),
     *     value: 'Expiring Soon',
     *     filter: (entry) => { return entry.isExpiringSoon; }
     *   }, ...
     * ]
     */
    options: {
      type: Array,
      default: () => [],
    },

    label: {
      type: [String, Function],
      default: 'Display',
    },

    ignoreFilters: {
      type: Array,
      default: () => [],
    },

    /**
     * Text shown when 'all' option is selected.
     */
    allOptionText: {
      type: String,
    },
  },

  data () {
    return {
      localFilters: this.filters,
    };
  },

  watch: {
    localFilters (filters) {
      const updatedFilters = Object.assign({}, this.filters, filters);
      if (!filters[this.name]) {
        delete updatedFilters[this.name];
      }
      this.$emit('update:filters', updatedFilters);
    },
    filters (filters) {
      // Prevent endless sync of this property
      if (!isEqual(this.localFilters, filters)) {
        this.localFilters = filters;
      }
    },
  },

  computed: {
    allOptText (): string {
      return this.allOptionText || this.$t('All ({count})');
    },
  },
});


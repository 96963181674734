
import { defineComponent } from 'vue';

import GetRescueCodesRequest from '@/api/getRescueCodesRequest';
import RescueCodesModal from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/rescueCodesModal.vue';
import { LOGIN_PAGE } from '@/routes/constants';

export default defineComponent({
  name: 'get-rescue-codes-page',

  components: { RescueCodesModal },

  props: {
    code: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      codesLoading: false,
      codes: [] as string[],
      error: false,
    };
  },

  methods: {
    goToLoginPage () {
      this.$router.push({ name: LOGIN_PAGE });
    },
    async requestCodes () {
      const request = new GetRescueCodesRequest({ code: this.code });
      this.codesLoading = true;
      try {
        await this.$api.call(request);
        this.codes = request.getRescueCodes();
        this.$modal.show(RescueCodesModal);
      } catch (e) {
        this.error = true;
      } finally {
        this.codesLoading = false;
      }
    },
  },
});




import Vue from 'vue';
import { INVITATIONS_LIST_PAGE } from '@/routes/constants';
import UnregisteredPdfcSupportContent from './unregisteredPdfcSupportContent.vue';

export default Vue.extend({
  name: 'unregistered-pdfc-support',
  components: { UnregisteredPdfcSupportContent },
  data () {
    return {
      invitations: [],
    };
  },
  methods: {
    show (e, invitations) {
      this.invitations = invitations;
    },
    openInvitations (domainId) {
      let routeParams = { name: INVITATIONS_LIST_PAGE };

      if (domainId) {
        routeParams = { ...routeParams, ...{ params: { domainId: domainId } } };
      }
      this.$router.push(routeParams);
    },
  },
  computed: {
    invitationsNumber (): number {
      return this.invitations?.length || 0;
    },
  },
});


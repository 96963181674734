/**
 * File: inviteAcceptRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { snakeize } from '@core/common/utils';
import passwordErrors from './errorHandlers/passwordErrors';
import inviteAcceptErrors from './errorHandlers/inviteAcceptErrors';

export type InviteAcceptRequestParams = {
  name?: string;
  lastName?: string;
  password: string;
  locale?: string;
  consentId?: string;
  dryRun?: string;
};

// https://wiki.prls.net/x/LNURBQ
export default class InviteAcceptRequest extends ApiRequest<InviteAcceptRequestParams> {
  readonly code: string;

  constructor (params: InviteAcceptRequestParams, code: string) {
    super(params);
    this.code = code;
  }

  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return '/account/webapp/accept_invitation/' + this.code;
  }

  get body (): Json {
    return snakeize(this.params);
  }

  get errors () {
    return Object.assign({
      'Invalid password': $t('Invalid password'),
    }, inviteAcceptErrors(), passwordErrors());
  }
}

/**
 * File: utils.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { Dictionary } from './types';

export const firstItem = function (str: string, separator: string = ',', ellipsis: string = '...'): string {
  str = str || '';
  const list = str.split(separator);
  return list.length === 1 ? list[0] : `${list[0]}${separator} ${ellipsis}`;
};

export const perLine = function (list: string, separator: string = ',', lineEnd: string = '\n'): string {
  list = list || '';
  return list.split(separator).join(lineEnd);
};

export const copyExcluding = function (src: Dictionary, exclude: string[]): Dictionary {
  let res = Object.assign({}, src);
  exclude.forEach((key) => {
    delete res[key];
  });
  return res;
};

export const copyToClipboard = function (text: string): void {
  const textarea = document.createElement('textarea');
  textarea.setAttribute('style', 'position:absolute;left:-1000px;');
  textarea.innerHTML = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const capitalize = function (str: string): string {
  if (!str) {
    return str;
  }

  return str.split(' ').map((word) => word ? word[0].toUpperCase() + word.slice(1) : word).join(' ');
};

export const toCamelCase = function (value: string): string {

  return value.replace(/_(.)/g, (match) => match[1].toUpperCase());
};

export const toSnakeCase = function (value: string): string {
  return value.replace(/[A-Z]/g, (match) => '_' + match.toLowerCase());
};

export const toKebabCaseFromPascalCase = function (value: string): string {
  let result = '';
  if (value) {
    result = value[0].toLowerCase() + value.slice(1);
    result = result.replace(/[A-Z]/g, (match) => `-${match}`).toLowerCase();
  }
  return result;
};

export const pluralize = function (singular: string, plural: string, number: Number): string {
  if (number === 1) {
    return singular;
  } else {
    return plural;
  }
};

export const toNumeral = function (n: number, lang: string): string {
  switch (lang) {
    case 'en':
      switch (n % 10) {
        case 1:
          return `${n}st`;
        case 2:
          return `${n}nd`;
        case 3:
          return `${n}rd`;
        default:
          return `${n}th`;
      }
    case 'ru':
      return `${n}-й`;
    default:
      return String(n);
  }
};

export const convertCase = function (data: any, transformer: (string) => string): any {
  if (!data) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((el) => convertCase(el, transformer));
  } else if (data.constructor === Object) {
    return Object.keys(data).reduce((result, key) => {
      let propName = transformer(key);
      result[propName] = convertCase(data[key], transformer);
      return result;
    }, {});
  }

  return data;
};

export const camelize = function (data) {
  return convertCase(data, toCamelCase);
};

export const snakeToCamel = (str: string) => str.toLowerCase().replace(/([-_][a-z])/g, group => group
  .toUpperCase()
  .replace('-', '')
  .replace('_', ''));

export const snakeize = function (data) {
  return convertCase(data, toSnakeCase);
};

export const getComponentName = function (name: string): string {
  // @ts-ignore
  let parentName = toKebabCaseFromPascalCase(this.$options.name);
  return name ? `${parentName}-${name}` : parentName;
};

export const formatText = function (template: string, textObject: Dictionary<string>): string {
  let re = /\{([a-z_]+)\}/g;
  let variableMatch = [...template.matchAll(re)];
  for (let itm of variableMatch) {
    let keyWordTpl = itm[0];
    let keyWord = itm[1];
    template = template.replace(keyWordTpl, `${textObject[keyWord]}`);
  }
  return template;
};

export const getRandomValues = function (buf) {
  const nodeCrypto = require('crypto');

  if (window.crypto && window.crypto.getRandomValues) {
    return window.crypto.getRandomValues(buf);
  }
  if (typeof window.msCrypto === 'object' && typeof window.msCrypto.getRandomValues === 'function') {
    return window.msCrypto.getRandomValues(buf);
  }
  if (nodeCrypto.randomBytes) {
    let bytes = nodeCrypto.randomBytes(buf.length);
    buf.set(bytes);
    return buf;
  } else {
    throw new Error('No secure random number generator available.');
  }
};

export const uuidv4 = function (): string {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};

export const truncateTextTo = function (value: string, length: number): string {
  if (!length || !value || value.length <= length) {
    return value;
  }
  return value.slice(0, length) + '...';
};

// todo: replace with `Record<'text' | 'value', string>` after CPCLOUD-16075
export interface Option {
  text: string;
  value: string | number;
  filter?: (entry: any) => boolean;
  disabled?: boolean;
}

export const findInOptions = function (array: Option[], value: string | number, defaultValue: string = ''): string {
  let item = array.find((item) => item.value === value);
  return item ? item.text : defaultValue;
};

export const isEqual = function (a, b): boolean {
  // slight optimization
  if (a === b) return true;
  if (typeof a !== typeof b) return false;
  // comparison of non-primitives
  if (a === Object(a) && b === Object(b)) {
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) return false;
      return a.every((el, i) => isEqual(el, b[i]));
    } else if (a.constructor === Object && b.constructor === Object) {
      let aKeys = Object.keys(a), bKeys = Object.keys(b);
      if (aKeys.length !== bKeys.length) return false;
      return Object.keys(a).every((property) => isEqual(a[property], b[property]));
    } else if (a.constructor === Date && b.constructor === Date) {
      return a.getTime() === b.getTime();
    }
  }
  // comparison of NaNs
  if (Number.isNaN(a) && Number.isNaN(b)) return true;
  // primitives
  return a === b;
};

export const sleep = function (timeout: number) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

<!--
  File: formFieldError.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
-->

<template lang="pug">

validation-errors(:validator="validator", :customErrors="Object.keys(customErrors)")
  template(
    v-for="(value, key) in customErrors",
    :slot="key + 'Error'"
  ) {{ value }}

</template>

<script>

export default {
  name: 'support-form-field-error',
  props: {
    validator: {
      type: Object,
      default: () => { return {}; },
    },
    customErrors: {
      type: Object,
      default: () => { return {}; },
    },
  },
};

</script>

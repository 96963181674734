/**
 * File: url.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { Dictionary } from './types';

export const buildQuery = function (params: any[] = [], values: Dictionary<string | number | boolean>, query: string = ''): string {
  return params.reduce((query, param) => {
    const value = values[param];
    return value ? query + (query.length ? '&' : '?') + `${param}=${encodeURIComponent(value)}` : query;
  }, query);
};

export const getUpperDomain = function (): string {
  let hostname = window.location.hostname;

  // ipv4
  if (hostname.match(/^(\d+\.){3}\d+$/)) {
    return hostname;
  }

  let domains = hostname.split('.');
  if (domains.length > 2) {
    domains.splice(0, 1);
  }
  return domains.join('.');
};

export const isExternalUrl = function (url: string): boolean {
  let a = document.createElement('a');
  a.href = url;
  let origin = location.origin || (location.protocol + '//' + location.hostname);
  let target = a.origin || (a.protocol + '//' + a.hostname);
  return origin !== target;
};

export const parseQueryString = function (url: string): Dictionary<string> {
  const query = url.split('?')[1] || '',
    params = query.split('&');

  return params.reduce(function (result, param): Dictionary<string> {
    if (!param.length) {
      return result;
    }
    let [name, value] = param.split('=');
    // pluses are not encoded on backend side
    value = value.replace(/\+/g, '%20');
    result[name] = decodeURIComponent(value);
    return result;
  }, {});
};

/**
 * File: pbcRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Method } from '@core/api/apiRequest';

interface PbcRequestParams {
  talkUrl: string;
  product: string;
  supportType: string;
  searchText: string | string[];
  senderEmail: string;
}

export interface Agent {
  id: string;
  url: string;
  name: string;
  description: string;
}

interface PbcResponseParams {
  agent: Agent;
  entrypointIntent: string;
}

export default class PbcRequest extends ApiRequest<PbcRequestParams, PbcResponseParams> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return this.params.talkUrl;
  }

  get body () {
    return {
      senderEmail: this.params.senderEmail,
      subject: this.params.searchText,
      category: `${this.params.product} ${this.params.supportType}`,
    };
  }
}

/**
 * File: restorePasswordRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { support } from '@core/constants/links';

export type RestorePasswordRequestParams = {
  email?: string;
  userProfile?: string;
};

export default class RestorePasswordRequest extends ApiRequest<RestorePasswordRequestParams> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return '/account/webapp/restore';
  }

  get body (): Json {
    if (this.params.userProfile) {
      return { user_profile: this.params.userProfile };
    }
    return { email: this.params.email };
  }

  getJson () {
    // No JSON content expected here
    return null;
  }

  get errors () {
    return {
      'rate limited': $t('Too many password reset requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    };
  }
}

/**
 * File: rtRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { Dictionary } from '@core/common/types';
import { appData } from '@/data/appData';

export interface RtRequestParams {
  url?: string;
  rtUrl?: string;
}

export default class RtRequest<T, U> extends ApiRequest<T, U> {
  version = 1;

  get cacheNameSpace (): string {
    return 'rt';
  }

  get uri (): string {
    if (this.params.hasOwnProperty('url')) {
      // @ts-ignore
      return this.params.url;
    }
    throw new Error('get url () not implemented');
  }

  get url (): string {
    let rtUrl;
    if (this.params.hasOwnProperty('rtUrl')) {
      // @ts-ignore
      rtUrl = this.params.rtUrl;
    } else {
      rtUrl = appData.session?.rtUrl;
    }
    return `${rtUrl}/api/account/v${this.version}${this.uri}`;
  }

  get options (): Dictionary<any> {
    const options = super.options;
    options.mode = 'cors';
    options.credentials = 'include';
    return options;
  }
}


import { ADD_KEY_MODAL, ADD_KEY_PAGE, ADD_LICENSES_MODAL, DASHBOARD_PAGE } from '@/routes/constants';
import Vue from 'vue';

import CombinedApiRequest from '@core/api/combinedApiRequest';

import BrowserListRequest from '@/api/browserListRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import PdLicensesRequest from '@/api/pdLicensesRequest';
import ComponentMixIn from '@/modules/componentMixIn';
import ProductCards from './productCards/productCards.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import PaymentOptionsModal from '@/modules/subscriptions/paymentOptions/paymentOptionsModal.vue';
// CPCLOUD-14654
// import CreatePurchaseLinkModal from './productCards/productCard/productCardPDFC/createPurchaseLinkModal';
import GenerateRedeemCodeModal from './productCards/productCard/productCardPDFC/generateRedeemCodeModal.vue';
import GeneratePackagedLicensesModal from './productCards/productCard/productCardPDFC/generatePackagedLicensesModal.vue';
import SelectOemLicensesTypeModal from './productCards/productCard/productCardPDFC/selectOemLicensesTypeModal.vue';

const REQ_NAME_LS_SUBSCRIPTIONS = 'ls_subscriptions';
const REQ_NAME_PD_LEGACY = 'pd_legacy';
const REQ_NAME_BROWSERS_LIST = 'browsers_list';

export default Vue.extend({
  name: 'dashboard',

  mixins: [ComponentMixIn],

  components: {
    ProductCards,
    AddLicensesModal,
    PaymentOptionsModal,
    // CPCLOUD-14654
    // CreatePurchaseLinkModal,
    GenerateRedeemCodeModal,
    GeneratePackagedLicensesModal,
    SelectOemLicensesTypeModal,
  },

  props: {
    modal: {
      type: String,
    },
    product: {
      type: String,
    },
    uuid: {
      type: String,
    },
  },

  data () {
    return {
      loading: true,
      switcherOptions: [],
      subscriptions: [],
      trusted: false,
      browsersLoading: false,
    };
  },

  created () {
    // If user has no subscriptions and can not generate pdfc links - send to key registration page
    if (!this.$appData.products.personal.length && !this.$appData.products.business.length && !this.$appData.session.isPdfcPurchaseFeatureAccessible) {
      // Current route is not replaced if we have called router.push in current tick
      this.$nextTick(() => {
        this.$router.replace({
          name: ADD_KEY_PAGE,
        });
      });
    } else {
      this.loadSubscriptions();
    }
  },

  beforeRouteEnter (to, from, next) {
    next(function (vm) {
      // @ts-ignore
      vm.showModal(vm.modal);
    });
  },

  watch: {
    '$appData.userInDomain': async function () {
      this.loading = true;
      await this.loadSubscriptions();
      this.loading = false;
    },
  },

  methods: {
    showModal (modal: string) {
      switch (modal) {
        case ADD_LICENSES_MODAL:
          this.$modal.show(AddLicensesModal, { product: this.product, subscriptionUuid: this.uuid });
          break;
        case ADD_KEY_MODAL:
          this.$modal.show('addKeyModal');
          break;
      }
    },

    loadSubscriptions () {
      this.subscriptions = [];
      const requestType = this.personalMode ? SubscriptionsRequest.TYPE_PERSONAL : SubscriptionsRequest.TYPE_BUSINESS;
      const combinedRequest = new CombinedApiRequest({ integrity: false });

      combinedRequest.addRequest(REQ_NAME_LS_SUBSCRIPTIONS, new SubscriptionsRequest({ type: requestType }));

      if (this.personalMode) {
        combinedRequest.addRequest(REQ_NAME_PD_LEGACY, new PdLicensesRequest());
      }

      if (this.checkBrowserStatus) {
        combinedRequest.addRequest(REQ_NAME_BROWSERS_LIST, new BrowserListRequest());
      }

      return this.authorizedCall(combinedRequest, { retry: false })
        .then(() => {
          const subscriptions = (
            combinedRequest.getRequest(REQ_NAME_LS_SUBSCRIPTIONS) as unknown as SubscriptionsRequest
          ).getSubscriptions().filter((subscription) => !subscription.isHidden);

          const pdLegacyRequest = combinedRequest.getRequest(REQ_NAME_PD_LEGACY) as unknown as PdLicensesRequest;
          if (pdLegacyRequest) {
            const legacyLicenses = pdLegacyRequest.getLicenses();
            legacyLicenses && subscriptions.push.apply(subscriptions, legacyLicenses);
          }

          this.subscriptions = subscriptions;

          if (this.checkBrowserStatus) {
            const browsers = (combinedRequest.getRequest(REQ_NAME_BROWSERS_LIST) as unknown as BrowserListRequest).data.browsers || [];
            const current = browsers.find((browser) => browser.current);

            this.trusted = !!(current && current.trusted);
          }

          if (combinedRequest.getFailedRequests().length) {
            this.$toast.show({
              tag: 'connectionError', // do not duplicate the same toasts
              color: 'red',
              text: this.$t('There was a problem completing this request.'),
            });
          }
        });
    },

    onAddLicensesModalHide () {
      this.$router.replace({ name: DASHBOARD_PAGE });
    },
  },

  computed: {
    personalMode (): boolean {
      return this.$appData.session.isPersonalUser(this.$appData.userInDomain);
    },
    businessAdminMode (): boolean {
      return this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain) && this.$appData.session.isBusinessAdmin;
    },
    checkBrowserStatus (): boolean {
      return this.$appData.session.isPdfcPurchaseFeatureAccessible && this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain);
    },
    showProductCards (): boolean {
      return !!this.subscriptions.length || this.isPdfcPurchaseAccessible;
    },
    isPdfcPurchaseAccessible (): boolean {
      // "trusted" flag in session means trusted session, but not browser (depends on Verify Device settings)
      return this.$appData.session.isPdfcPurchaseFeatureAccessible && this.trusted;
    },
  },
});


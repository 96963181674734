
import Vue from 'vue';

export default Vue.extend({
  name: 'status-line',

  data () {
    return {
      modalName: 'statusLineIconText-' + Math.random(),
    };
  },

  methods: {
    showModal () {
      this.$modal.show(this.modalName);
    },
  },
});

/**
 * File: statusMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue, { PropType } from 'vue';

import BaseView from './baseView.vue';
import PaymentOptionsLink from '@/modules/subscriptions/paymentOptions/paymentOptionsLink.vue';
import Subscription from '@/models/subscription';

export default Vue.extend({
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
  },
  components: { BaseView, PaymentOptionsLink },
  data () {
    return {};
  },
  computed: {
    canEditSubscription (): boolean {
      const isPersonalUser = this.$appData.session.isPersonalUser(this.$appData.userInDomain);
      const isBusinessAdmin = this.$appData.session.isBusinessAdmin;
      return isPersonalUser || isBusinessAdmin;
    },
  },
});

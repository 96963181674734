

import Vue, { PropType } from 'vue';
import RescueCodes from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/rescueCodes.vue';

export default Vue.extend({
  name: 'rescue-codes-modal',
  components: { RescueCodes },
  props: {
    codes: Array as PropType<string[]>,
    loading: Boolean,
  },
});


<copyright>
File: productCardPMM.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

card(type="product", :equalTo="equalTo", v-bind:class="[ defaultClass ]", icon="pmm")
  template(slot="title")
    .text-ellipsis {{ $t('Parallels Device Management') }}
  template(slot="actions")
    icon(name="download", @click="$router.push(getDownloadRoute)")
  div(slot="about", :data-name="$name('link-units-label')") {{ pmmProductUnits }}
  template(slot="info")
    list.padding-bottom-xs
      router-link.link(
        :to="{ name: 'pmmSubscriptions', query: { display: STATUS_ACTIVE } }",
        :data-name="$name('link-active-subscriptions')"
      )
        .row
          .col-xs-10.text-ellipsis {{ $t('Active subscriptions') }}
          .col-xs-1
            router-link(
              v-if="item.hasExpiringSubscriptions",
              :to="{ name: 'pmmSubscriptions', query: { display: STATUS_IS_EXPIRING_SOON } }",
              :data-name="$name('link-expiring-subscriptions')"
            )
              icon(name="alert-yellow").pull-right
          .col-xs-1.text-right.text-link.text-bold {{ item.activeSubscriptions }}
      router-link.link(
        v-if="item.expiredSubscriptions",
        :to="{ name: 'pmmSubscriptions', query: { display: STATUS_EXPIRED } }",
        :data-name="$name('link-expired-subscriptions')"
      )
        .row
          .col-xs-9.text-ellipsis {{ $t('Expired subscriptions') }}
          .col-xs-3.text-right.text-link.text-bold {{ item.expiredSubscriptions }}
      router-link.link(
        v-if="proxyServersCount",
        :to="{ name: 'pmmServers' }",
        :data-name="$name('link-registered-proxy-servers')"
      )
        .row
          .col-xs-9.text-ellipsis {{ $t('Registered proxy servers') }}
          .col-xs-3.text-right.text-link.text-bold {{ proxyServersCount }}
  template(slot="menu")
    btn.block-xs.margin-right-2x-sm.margin-top-2x(
      v-if="session.isBusinessAdmin && item.canBuyMoreLicenses",
      @click="showAddLicensesModal",
      size="small",
      color="white",
      :data-name="$name('button-by-more-licenses')"
    ) {{ $t('Buy More Licenses') }}
    btn.block-xs.margin-right-2x-sm.margin-top-2x(@click="$router.push({ name: 'csrSign' })", size="small", color="white")
      | {{ $t('MDM Certificate') }}
    btn.block-xs.margin-top-2x(target="blank", size="small", color="white", @click="onSupportClick")
      | {{ $t('Support') }}

</template>

<script>
import ProductCardMixIn from './productCardMixIn';
import PmmSitesRequest from '@/api/pmmSitesRequest';

export default {
  name: 'product-card-pmm',

  mixins: [ProductCardMixIn],

  data () {
    return {
      proxyServersCount: null,
    };
  },

  created () {
    const request = new PmmSitesRequest();
    this.$api.authorizedCall(request).then(
      (data) => {
        this.proxyServersCount = data && data.length;
      }
    );
  },

  computed: {
    pmmProductUnits () {
      const units = [];
      if (this.item.totalMacManagementLimit) {
        units.push(this.$tc('for {limit} managed computers', this.item.totalMacManagementLimit, { limit: this.item.totalMacManagementLimit }));
      }
      if (this.item.totalMdmLimit) {
        if (this.item.totalMacManagementLimit) {
          units.push(this.$tc('{limit} mobile devices', this.item.totalMdmLimit, { limit: this.item.totalMdmLimit }));
        } else {
          units.push(this.$tc('for {limit} mobile devices', this.item.totalMdmLimit, { limit: this.item.totalMdmLimit }));
        }
      }
      return units.join(` ${this.$t('and')} `);
    },
  },
};

</script>

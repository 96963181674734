/**
 * File: getBrowserInfoRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Method } from '@core/api/apiRequest';

import { Browser } from '@/models/trustedEntity';

/* eslint-disable camelcase */
interface GetBrowserInfoResponseData {
  user_email: string;
  browser: BrowserResponseData;
}
/* eslint-enable camelcase */

export default class GetBrowserInfoRequest extends ApiRequest<ConfirmBrowserParams, GetBrowserInfoResponseData> {
  get method (): Method {
    return 'GET';
  }

  get url (): string {
    return `/account/webapp/confirm_browser/${this.params.code}`;
  }

  getBrowser (): Browser {
    return new Browser(this.data.browser);
  }

  getUserEmail (): string {
    return this.data.user_email;
  }
}

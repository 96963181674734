/**
 * File: heightObserverMixin.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import heightObserver from './heightObserver';

export default {
  methods: {
    setObserverKey (key) {
      if (key) {
        heightObserver.$emit('mount', {
          key: key,
          uid: this._uid,
          el: this.$el,
        });
      }
    },
    removeObserverKey (key) {
      if (key) {
        heightObserver.$emit('unmount', {
          key: key,
          uid: this._uid,
        });
      }
    },
  },
  watch: {
    equalTo: {
      handler (value, oldValue) {
        this.removeObserverKey(oldValue);
        this.setObserverKey(value);
      },
      deep: true,
    },
  },
  created () {
    heightObserver.$on('update', (data) => {
      var height = this.$el.clientHeight;

      if (this.equalTo === data.key && height !== data.height) {
        this.$el.style.height = data.height + 'px';
      }
    });
  },
  updated () {
    var key = this.equalTo;

    if (key) {
      heightObserver.$emit('recalc', {
        key: key,
      });
    }
  },
  mounted () {
    this.setObserverKey(this.equalTo);
  },
  beforeDestroy () {
    this.removeObserverKey(this.equalTo);
  },
};

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'duration-formatter',
  props: {
    duration: {
      type: Object,
    },
  },
  computed: {
    text () {
      return this.units.map((unit) => `{${unit}}`).join(' ');
    },
    units () {
      const duration = this.duration || {};
      return ['hours', 'minutes'].filter((value) => duration[value]);
    },
  },
};

</script>

<template lang="pug">

//- $tc('{n} hours')
//- $tc('{n} minutes')
formatter(:text="text")
  template(v-for="unit in units", :slot="unit") {{ $tc('{n} {unit}', duration[unit], { unit, n: duration[unit] }) }}

</template>

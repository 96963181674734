/**
 * File: offlineActivation.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

import fileUploader from '@/ui/file-uploader/index.vue';
import RasOfflineRequest from '@/api/rasOfflineRequest';
import { required } from 'vuelidate/lib/validators';
import { isLicenseKeyValid } from '@core/common/validators';

export default Vue.extend({
  name: 'offline-activation',

  components: { fileUploader },

  data () {
    return {
      loading: false,
      succeeded: false,
      failed: false,
      licenseKey: '',
      activatedSubscriptionUuid: null,
      resultMessage: '',
      instructionsMessage: '',
    };
  },

  validations: {
    licenseKey: {
      required,
      pattern: function (value) {
        return isLicenseKeyValid(value);
      },
    },
  },

  methods: {
    onUploadSuccess (files) {
      this.loading = true;

      const request = new RasOfflineRequest({ action: 'activate', licKey: this.licenseKey }, files[0]);

      this.$api.authorizedCall(request).then((data) => {
        this.setSuccessResult(data);
      }).catch((e) => {
        e.response.json().then((data) => {
          this.setFailureResult(data);
        });
      }).finally(() => {
        this.loading = false;
      });
    },

    setSuccessResult (data) {
      this.succeeded = true;
      this.activatedSubscriptionUuid = data.subscription_uuid;
      this.resultMessage = this.$t('Offline activation file for the farm "{farmName}" is ready!', { farmName: data.farm_name });
      this.instructionsMessage = this.$t('Use the button to download the file.');
    },

    setFailureResult (data) {
      this.failed = true;

      switch (data.msg) {
        case 'invalid file':
        case 'invalid json':
        case 'invalid checksum':
        case 'invalid payload':
          this.resultMessage = this.$t('The offline activation request file is not valid!');
          this.instructionsMessage = this.$t('Please use the file that has been created for offline activation by Remote Application Server app.');
          break;
        case 'not owner of license':
          this.resultMessage = this.$t('The activation key belongs to a different business account!');
          this.instructionsMessage = this.$t('Check that you use the license key which is registered in your business account.');
          break;
        case 'no such license':
        case 'no such subscription':
        case 'was expired':
        case 'was deleted':
        case 'invalid parameters':
          this.resultMessage = this.$t('The activation key is not valid!');
          this.instructionsMessage = '';
          break;
        case 'malformed request':
        case 'version mismatch':
          this.resultMessage = this.$t('There was a problem completing this request.');
          this.instructionsMessage = '';
          break;
        case 'activation prohibited':
        case 'offline prohibited':
          this.resultMessage = this.$t('This activation key cannot be used for offline activation!');
          this.instructionsMessage = this.$t('Please use a key enabled for offline activation. Contact your Parallels representative to get an offline key.');
          break;
        case 'already occupied':
          this.resultMessage = this.$t('This activation key is being used by a different farm "{farmName}"!', { farmName: data.host.farm_name });
          this.instructionsMessage = this.$t('To use the license key for activation of another farm, first deactivate the farm that uses the key.');
          break;
        default:
          this.resultMessage = this.$t('There was a problem completing this request.');
          this.instructionsMessage = '';
          break;
      }
    },

    reset () {
      this.succeeded = false;
      this.failed = false;
      this.resultMessage = '';
      this.instructionsMessage = '';
    },
  },

  computed: {
    downloadLink (): string {
      if (!this.licenseKey) {
        return '';
      }

      if (!this.activatedSubscriptionUuid) {
        return '';
      }

      return `/ras/webapp/offline/download/${this.activatedSubscriptionUuid}`;
    },
  },
});

/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 **/

export const CONFIGURE_AAS_MODAL = 'configureAasModal';
export const CONFIGURE_GPS_MODAL = 'configureGpsModal';

export const SUBNAVIGATION_SOURCE = 'subnavigation';

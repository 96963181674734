/**
 * File: pdeRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import { PDE_DOWNLOADS_PAGE, PDE_SUBPAGE_SUBSCRIPTION_DETAILS, PDE_SUBPAGE_SUBSCRIPTIONS } from './constants';
import pdePage from '@/modules/pde/pdePage.vue';
import { PRODUCT_KEY_NAME_PDE, PRODUCT_NAME_PDE } from '@core/constants/subscriptions';

export default [
  {
    path: `/${PRODUCT_KEY_NAME_PDE}/subscriptions`,
    name: PDE_SUBPAGE_SUBSCRIPTIONS,
    component: pdePage,
    props: () => ({
      content: PDE_SUBPAGE_SUBSCRIPTIONS,
      productKeyName: PRODUCT_KEY_NAME_PDE,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_PDE}`,
    },
  },
  {
    path: `/${PRODUCT_KEY_NAME_PDE}/subscriptions/:id`,
    name: PDE_SUBPAGE_SUBSCRIPTION_DETAILS,
    component: pdePage,
    props: () => ({
      content: PDE_SUBPAGE_SUBSCRIPTION_DETAILS,
      productKeyName: PRODUCT_KEY_NAME_PDE,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_PDE}`,
    },
  },
  {
    path: `/${PRODUCT_KEY_NAME_PDE}/downloads`,
    name: PDE_DOWNLOADS_PAGE,
    component: pdePage,
    props: () => ({
      content: PDE_DOWNLOADS_PAGE,
      productKeyName: PRODUCT_KEY_NAME_PDE,
    }),
    meta: {
      requiresAuth: true,
      title: `Download - ${PRODUCT_NAME_PDE}`,
    },
  },
];

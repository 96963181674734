/**
 * File: rasNfrBanner.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 **/

import CoreModel from '@core/common/model';

export default class RasNfrBanner extends CoreModel {
  uuid: string;
  key: string;
  version: string;
  seen: boolean;
  closedDetails: boolean = false;
  closedGlobally: boolean = false;
  gracePeriodEndsAt: Date;
}

/**
 * File: oemLicenses.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import CoreModel from '@core/common/model';

export const TYPE_ONE_YEAR = '12-month'; // $t('12-month')
export const TYPE_ONE_MONTH = '1-month (trial)'; // $t('1-month (trial)')

export const REDEEMED_STATUS = 'Redeemed'; // $t('Redeemed')
export const NOT_REDEEMED_STATUS = 'Not redeemed'; // $t('Not redeemed')

export enum OemProduct {
  FullProduct = 'PDFC_OEM_PRODUCT_12M',
  TrialProduct = 'PDFC_OEM_PRODUCT_1M'
}

// $t('Web form')

class OemLicenseBase extends CoreModel {
  createdAt: Date;
  source: string;
  quantity: number;
  productKey: OemProduct;
  rationale: string;
  createdBy?: string;

  get type (): string {
    if (this.productKey === OemProduct.FullProduct) {
      return TYPE_ONE_YEAR;
    } else if (this.productKey === OemProduct.TrialProduct) {
      return TYPE_ONE_MONTH;
    }
  }
}

export class RedeemCode extends OemLicenseBase {
  code: string;
  organization: string;
  email: string;
  operatorId: number;
  usedAt?: Date;

  get status (): string {
    return this.usedAt ? REDEEMED_STATUS : NOT_REDEEMED_STATUS;
  }

  get expiredAt (): Date|null {
    if (!this.usedAt) {
      return null;
    }

    const usedAt = new Date(this.usedAt);

    if (this.productKey === OemProduct.FullProduct) {
      usedAt.setFullYear(usedAt.getFullYear() + 1);
    } else if (this.productKey === OemProduct.TrialProduct) {
      usedAt.setMonth(usedAt.getMonth() + 1);
    }

    return usedAt;
  }
}

export class PackagedLicensesPack extends OemLicenseBase {
  orderId: string;
  seller: string;
  displayName: string;
  requestedUserId: number;
  used: number;

  get enrolled (): string {
    return `${this.used}/${this.quantity}`;
  }
}

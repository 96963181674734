

import Vue from 'vue';

import ComponentMixIn from '@/modules/componentMixIn';
import SSOCompanyUser, { SSOHostInfo } from '@core/models/ssoCompanyUser';
import {
  AutomaticLicensesRevocationPeriod,
  prodKeyToName,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PTBB_PER_USER
} from '@core/constants/subscriptions';
import { ActionCallParameters, TableViewActions, TableViewColumns } from '@/ui/tableViewConst';
import CsvSaver from '@/ui/csvSaver.vue';
import FilterDropdownMy from '@/ui/filterDropdown/index.vue';
import TableView from '@/ui/tableView.vue';
import { SSOCompanyUsersRequest, SSOUserUpdateRequest } from '@/api/sso/ssoCompanyUsersRequest';
import SSOUsersUsageRequest, { RevokeSSOLicenseRequest } from '@/api/sso/ssoUsersUsageRequest';
import { Dictionary } from '@core/common/types';
import { maxLength, required } from '@core/common/validators';
import { DESKTOP_SUBSCRIPTIONS_PAGE } from '@/routes/constants';
import ComputerDetails from '@/modules/desktop/computerDetails/computerDetails.vue';

const ALL_PRODUCTS = 'All';

export default Vue.extend({
  name: 'business-profile-sso-member-info',
  mixins: [ComponentMixIn],
  components: { FilterDropdownMy, TableView, CsvSaver, ComputerDetails },

  props: {
    memberId: {
      type: String,
      required: true,
    },
    productFilter: {
      type: String,
      default: ALL_PRODUCTS,
    },
  },

  validations: {
    form: {
      notes: {
        required,
        maxLength: maxLength(300),
      },
    },
  },

  data () {
    return {
      member: new SSOCompanyUser({}),
      form: {
        notes: '',
      },
      equalTo: 'ssoMemberInfoCard',
      filters: {} as TableViewFilters,
      table: {
        name: 'ssoMemberDevices',
        selectedUuids: [] as string[],
        sortBy: {
          column: 'name',
          ascending: true,
        },
        pagination: {
          perPage: 10,
          options: [
            { value: 5, text: '5' },
            { value: 10, text: '10' },
            { value: 20, text: '20' },
            { value: Infinity, text: this.$t('All') },
          ],
        },
      },
      csvFileName: 'devices.csv',
      currentProduct: this.productFilter,
      productsFilterName: 'products',
      PRODUCT_KEY_NAME_PDB_PER_USER,
    };
  },

  mounted () {
    this.load();
  },

  computed: {
    revocationPeriods (): Dictionary<string> {
      return {
        [AutomaticLicensesRevocationPeriod.NEVER]: this.$t('Never'),
        [AutomaticLicensesRevocationPeriod.ONE_MONTH]: this.$t('1 Month'),
        [AutomaticLicensesRevocationPeriod.QUARTER]: this.$t('3 Months'),
        [AutomaticLicensesRevocationPeriod.HALF_YEAR]: this.$t('6 Months'),
        [AutomaticLicensesRevocationPeriod.ONE_YEAR]: this.$t('1 Year'),
      };
    },
    roleName (): string {
      return this.member?.isAdmin ? this.$t('Account Administrator') : this.$t('Regular Member');
    },
    productsOptions (): { text: string; value: string; filter: Function }[] {
      return [
        { text: this.$t('Parallels Desktop for Mac Business Edition ({count})'), value: PRODUCT_KEY_NAME_PDB_PER_USER, filter: (host) => this.member.products.hasOwnProperty(PRODUCT_KEY_NAME_PDB_PER_USER) && this.member.products[PRODUCT_KEY_NAME_PDB_PER_USER].hosts.find((h) => h.hwId === host.hwId) },
        { text: this.$t('Parallels Toolbox ({count})'), value: PRODUCT_KEY_NAME_PTBB_PER_USER, filter: (host) => this.member.products.hasOwnProperty(PRODUCT_KEY_NAME_PTBB_PER_USER) && this.member.products[PRODUCT_KEY_NAME_PTBB_PER_USER].hosts.find((h) => h.hwId === host.hwId) },
      ];
    },
    devicesTableColumns (): TableViewColumns {
      return {
        options: [
          { text: this.$t('Name'), value: 'name' },
          { text: this.$t('Products'), value: 'products' },
          { text: this.$t('Hardware ID'), value: 'hwId' },
          { text: this.$t('MAC Address'), value: 'macAddresses' },
          { text: this.$t('Host OS'), value: 'osName' },
          { text: this.$t('App Version'), value: 'buildNumber' },
          { text: this.$t('Activation Date'), value: 'activatedAt', type: 'date' },
          { text: this.$t('Last Contact'), value: 'lastConnectionAt', type: 'date' },
        ],
      };
    },
    devicesActions (): TableViewActions {
      const actions: TableViewAction[] = [];
      actions.push({
        value: 'exportCsv',
        text: this.$t('Save List to CSV File ({count})'),
      });
      return {
        id: 'hwId',
        options: actions,
      };
    },
  },
  methods: {
    async load () {
      try {
        this.loading = true;
        const ssoUsersRequest = new SSOCompanyUsersRequest({
          companyUuid: this.$appData.session.businessDomainId, users: [this.memberId],
        });
        await this.authorizedCall(ssoUsersRequest);
        const users = ssoUsersRequest.getUsers();
        const usersUsage = await this.authorizedCall(new SSOUsersUsageRequest({ users }));
        const user = users[0];
        user.fillProductsInfo(usersUsage);
        this.member = user;
        this.form.notes = user.notes;
      } finally {
        this.loading = false;
      }
    },

    onExpanded (value: boolean) {
      // Workaround for adjusting cards height on expand collapsible event
      if (value) {
        this.equalTo = 'ssoMemberInfoCard--expanded';
      } else {
        this.equalTo = 'ssoMemberInfoCard';
      }
    },

    productName (productKeyName: string): string {
      return prodKeyToName(productKeyName);
    },

    productSubscriptionsPage (productKeyName: string): { name: string; params?: { product: string } } {
      return {
        name: DESKTOP_SUBSCRIPTIONS_PAGE,
        params: { product: productKeyName },
      };
    },

    async saveNotes () {
      try {
        this.loading = true;
        await this.$api.authorizedCall(new SSOUserUpdateRequest({
          companyUuid: this.$appData.session.businessDomainId,
          ssoUserId: this.member.uuid,
          notes: this.form.notes,
        }));
        this.member.notes = this.form.notes;
      } finally {
        this.loading = false;
      }
    },

    async revokeLicense (productKeyName: string) {
      try {
        this.loading = true;
        await this.$api.authorizedCall(new RevokeSSOLicenseRequest({ products: [productKeyName], ssoUsersIds: [this.member.uuid] }));
        delete this.member.products[productKeyName];
      } finally {
        this.loading = false;
      }
    },

    callAction ({ action, items }: ActionCallParameters): void {
      (this[action] as CallbackFunctionType)(items);
    },

    // FIXME CPCLOUD-17383 use CsvSaver included into TableView
    exportCsv (devices: SSOHostInfo[]): void {
      (this.$refs.csv as unknown as typeof CsvSaver).save({
        columns: this.devicesTableColumns.options,
        rows: devices,
      });
    },

    deviceActivatedProducts (host: SSOHostInfo): number {
      if (host.products !== undefined) {
        return host.products;
      }
      const pdActivation = this.member?.firstActivationAt(PRODUCT_KEY_NAME_PDB_PER_USER, host);
      const ptbActivation = this.member?.firstActivationAt(PRODUCT_KEY_NAME_PTBB_PER_USER, host);
      host.products = [pdActivation, ptbActivation].filter((d) => d !== undefined).length;
      return host.products;
    },

    deviceLastContactedAt (host: SSOHostInfo): string {
      const lastContactedAt = this.member?.lastContactedAt(this.currentProduct !== ALL_PRODUCTS ? this.currentProduct : undefined, host);
      return lastContactedAt ? this.formatDateTime(lastContactedAt) : '';
    },

    deviceActivatedAt (host: SSOHostInfo): string {
      const firstActivationAt = this.member?.firstActivationAt(this.currentProduct !== ALL_PRODUCTS ? this.currentProduct : undefined, host);
      return firstActivationAt ? this.formatDateTime(firstActivationAt) : '';
    },

    currentProductChanged (value: string) {
      this.currentProduct = value;
    },

    onComputerClick (host: SSOHostInfo) {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.computerDetailsModal.show(host);
    },
  },
});

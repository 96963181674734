/**
 * File: bulaModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { getBulaLanguage } from '@core/constants/langs';
import { BULA_TEXT_URL, CUSTOMER_EXPERIENCE_PROGRAM_URL } from '@core/common/links';
import { FEATURE_MANAGE_CEP_OPTION } from '@core/constants/features';
import GetFileRequest from '@/api/getFileRequest';
import Vue from 'vue';

export default Vue.extend({
  name: 'bula-modal',

  data () {
    return {
      isCepOptionAccessible: this.$appData.session.isFeatureAccessible(FEATURE_MANAGE_CEP_OPTION),
      bulaAccepted: false,
      cepOption: null,
      bulaUrl: BULA_TEXT_URL(this.$appData.session.isoLocale),
      bulaPdfLoading: false,
    };
  },

  methods: {
    show (): void {
      this.bulaUrl = BULA_TEXT_URL(this.$appData.session.isoLocale);
      this.bulaAccepted = false;
      this.cepOption = null;
      this.$modal.show('bulaModal');
    },

    openCepInfo (): void {
      window.open(CUSTOMER_EXPERIENCE_PROGRAM_URL(this.$appData.session.isoLocale));
    },

    registerHandler (): void {
      this.$modal.hide();
      this.$emit('bulaAccepted', { bulaAccepted: this.bulaAccepted, cepOption: this.isCepOptionAccessible ? this.cepOption : null });
    },

    cancelHandler (): void {
      this.$modal.hide();
      this.$emit('cancel');
    },

    downloadHandler (): void {
      this.bulaPdfLoading = true;
      const request = new GetFileRequest({
        language: getBulaLanguage(this.$appData.session.isoLocale),
      });
      this.$api.authorizedCall(request).then(function (blob) {
        const fileName = 'bula.pdf';
        if (navigator.msSaveOrOpenBlob) {
          // For Edge and IE
          navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const objUrl = window.URL && window.URL.createObjectURL && window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objUrl;
          link.download = fileName;
          link.click();
          // For Firefox, it is necessary to delay revoking the ObjectURL.
          setTimeout(() => { window.URL && window.URL.revokeObjectURL(objUrl); }, 250);
        }
      }).finally(() => {
        this.bulaPdfLoading = false;
      });
    },

  },

});

/**
 * File: userProfileRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class UserProfileRequest extends ApiRequest {
  get url () {
    return '/account/api/v1/profile/show';
  }

  get cacheNameSpace () {
    return 'personalProfile';
  }
}

/**
 * File: rasSubscriptionBillingPeriodsRequestV2.ts
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import { V2Response } from '@/apiv2/V2RequestType';
import { getCurrentDateTime, rasServiceUrl } from '@/apiv2/apiHelpers';

export interface BillingPeriodResponse {
  id: number;
  'subscription_uuid': string;
  closed: boolean;
  'started_at': Date;
  'ended_at': Date;
}

interface RasSubscriptionBillingPeriodsParams {
  uuids?: string[];
  url?: string;
}

export default class RasSubscriptionBillingPeriodsRequestV2 extends ApiRequest<RasSubscriptionBillingPeriodsParams, V2Response<BillingPeriodResponse[]>> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    return rasServiceUrl(this.params.url) || `/ras/api/v2/subscriptions/usage_periods?started_at__lt=${getCurrentDateTime()}&subscription_uuid__in=${this.params.uuids.join(',')}`;
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}

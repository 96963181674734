

import Vue from 'vue';
import ProductCardMixIn from './productCardMixIn';
import { prodKeyToName, PRODUCT_KEY_NAME_PTB } from '@core/constants/subscriptions';
import ToolboxHostsCountRequest from '@/api/toolboxHostsCountRequest';
import { Route } from 'vue-router';
import {
  PRODUCT_CONTEXT_TO_DOWNLOAD_MAP,
  TOOLBOX_SUBSCRIPTION,
  TOOLBOX_UTILITY_SUBSCRIPTION
} from '@/routes/constants';
import { getUtilityKeyNameByProductName } from '@/modules/home/dashboard/utils';

export default Vue.extend({
  name: 'product-card-ptb',

  mixins: [ProductCardMixIn],

  data () {
    return {
      activatedCount: null,
      deactivatedCount: null,
      hostsCountersLoading: false,
    };
  },

  computed: {
    isBusinessMode (): boolean {
      return !this.$appData.session.isPersonalUser(this.$appData.userInDomain);
    },

    productName (): string {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      return prodKeyToName(this.item.productKeyName);
    },

    productIcon (): string {
      if (this.utilityKeyName) {
        return this.utilityKeyName;
      }
      return 'toolbox';
    },

    utilityKeyName (): string {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      return getUtilityKeyNameByProductName(this.item.productKeyName);
    },

    subscriptionsRoute (): Pick<Route, 'name' | 'params' | 'query'> {
      return { name: this.utilityKeyName ? TOOLBOX_UTILITY_SUBSCRIPTION : TOOLBOX_SUBSCRIPTION, params: { utility: this.utilityKeyName }, query: { display: 'Active' } };
    },

    expiredSubscriptionsRoute (): Pick<Route, 'name' | 'params' | 'query'> {
      return { name: this.utilityKeyName ? TOOLBOX_UTILITY_SUBSCRIPTION : TOOLBOX_SUBSCRIPTION, params: { utility: this.utilityKeyName }, query: { display: 'Expired' } };
    },

    expiringSubscriptionsRoute (): Pick<Route, 'name' | 'params' | 'query'> {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      return { name: this.utilityKeyName ? TOOLBOX_UTILITY_SUBSCRIPTION : TOOLBOX_SUBSCRIPTION, params: { utility: this.utilityKeyName }, query: { display: this.STATUS_IS_EXPIRING_SOON } };
    },

    downloadsRoute (): Pick<Route, 'name' | 'params' > {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      const productKeyName = this.item.productKeyName === PRODUCT_KEY_NAME_PTB ? null : this.item.productKeyName;
      return PRODUCT_CONTEXT_TO_DOWNLOAD_MAP[PRODUCT_KEY_NAME_PTB](productKeyName);
    },
  },

  async created () {
    if (this.isBusinessMode) {
      const hostsCountersRequest = new ToolboxHostsCountRequest();
      const countData = await this.$api.authorizedCall(hostsCountersRequest, 'hostsCountersLoading');
      this.activatedCount = countData.activated;
      this.deactivatedCount = countData.deactivated;
    }
  },
});

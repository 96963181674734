

import { defineComponent } from 'vue';
import { name, password, acceptTerms } from '@core/common/validators';
import InviteInfoRequest, { InviteInfoRequestResponse } from '@/api/inviteInfoRequest';
import InviteAcceptRequest from '@/api/inviteAcceptRequest';
import formMixin from '@/modules/formMixin';
import UserConsent from '@/modules/userConsent/index.vue';
import { LOGIN_PAGE, START_PAGE } from '@/routes/constants';

export default defineComponent({
  name: 'accept-invite',

  mixins: [formMixin],

  props: {
    code: String,
  },

  components: {
    UserConsent,
  },

  data () {
    return {
      error: false,
      showConsent: false,
      invite: {
        companyName: '',
        email: '',
        name: '',
        exists: false,
      } as InviteInfoRequestResponse,
      formExisting: {
        password: '',
      },
      formNew: {
        firstName: '',
        lastName: '',
        password: '',
        termsAgree: false,
      },
      loading: false,
      consent: {
        id: undefined,
        required: true,
        newAccount: true,
      },
    };
  },

  validations: {
    formExisting: {
      password,
    },
    formNew: {
      firstName: name,
      lastName: name,
      password,
      termsAgree: acceptTerms,
    },
  },

  mounted () {
    this.load();
  },

  methods: {
    async load () {
      this.loading = true;
      const request = new InviteInfoRequest(this.code);

      try {
        this.invite = await this.$api.call(request);
        // Try to get consent if user is not exists
        if (!this.invite.exists) {
          this.consent.required = await this.getConsentNeed();
        }
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    /**
     * Submit handler for existing user. Logs the user in and then joins him into business account
     */
    async submitLogin () {
      this.loading = true;
      try {
        await this.$api.call(new InviteAcceptRequest({ password: this.formExisting.password }, this.code));
        await this.$api.refreshToken(true);
        this.goToHomePage();
      } catch {
        this.loading = false;
      }
    },

    async submitRegister () {
      if (this.consent.required) {
        this.showConsent = true;
      } else {
        this.loading = true;
        try {
          await this.register();
        } catch {
          this.loading = false;
        }
      }
    },

    async register () {
      // make sure that consent is not required or accepted
      await this.$api.call(new InviteAcceptRequest({
        locale: this.$i18n.locale.toLowerCase(),
        consentId: this.consent.id,
        lastName: this.formNew.lastName,
        name: this.formNew.firstName,
        password: this.formNew.password,
      }, this.code));
      await this.$api.refreshToken(true);
      this.$appData.isNewUser = true;
      this.goToHomePage();
    },

    goToLoginPage () {
      this.$router.push({ name: LOGIN_PAGE });
    },

    goToHomePage () {
      this.$router.push({ name: START_PAGE });
    },

    onAccept (params) {
      this.consent.id = params.id;
      this.consent.required = !!params.required;
      this.submitRegister();
    },

    async getConsentNeed () {
      const data = await this.$api.getConsent({ dryRun: true }, false);
      return data.consent_required;
    },

    hideConsent () {
      this.showConsent = false;
    },
  },
});


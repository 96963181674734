/**
 * File: pdDeleteConfigurationProfileRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class PdDeleteConfigurationProfileRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {Number} params.id
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'DELETE';
  }

  get url () {
    return `/desktop/api/v1/ba/configuration_profiles/${this.params.id}`;
  }
}

/**
 * File: emailChangeRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import md5 from 'blueimp-md5';
import emailPatternMismatch from './errorHandlers/emailPatternMismatch';
import passwordErrors from './errorHandlers/passwordErrors';
import emailErrors from './errorHandlers/emailErrors';
import { support } from '@core/constants/links';

export default class EmailChangeRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/profile/change_email';
  }

  get body () {
    return {
      email: this.params.email,
      password: md5(this.params.password),
    };
  }

  get errors () {
    return Object.assign({
      'account already exists': $t('This email is already in use.'),
      'invalid parameters': response => emailPatternMismatch(response) || $t('Invalid parameters'),
      'rate limited': $t('Too many email change requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    }, passwordErrors(), emailErrors());
  }
}

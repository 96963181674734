/**
 * File: companyUpdateRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { STATES } from '@core/constants/geo';

export default class CompanyUpdateRequest extends ApiRequest {
  constructor (params, companyUuid) {
    super(params);
    this.company_uuid = companyUuid;
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/ba/' + this.company_uuid + '/update';
  }

  get body () {
    var payload = Object.assign({}, this.params);

    if (!STATES[payload.country]) {
      delete payload.state;
    }

    return payload;
  }
}

/**
 * File: subscriptionsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import CombinedApiRequest from '@core/api/combinedApiRequest';
import Subscription from '@/models/subscription';
import LsSubscriptionsRequest from './lsSubscriptionsRequest';
import RasLsSubscriptionsRequest from './rasLsSubscriptionsRequest';

const REQ_NAME_LS_SUBSCRIPTIONS = 'ls';
const REQ_NAME_RAS_LS_SUBSCRIPTIONS = 'rasls';

export default class SubscriptionsRequest extends CombinedApiRequest {
  /**
   *
   * @returns {string}
   * @constructor
   */
  static get TYPE_BUSINESS () {
    return 'business';
  }

  /**
   *
   * @returns {string}
   * @constructor
   */
  static get TYPE_PERSONAL () {
    return 'personal';
  }

  get cacheNameSpace () {
    return 'subscriptions';
  }

  /**
   *
   * @param {?Object} params
   * @param {?String} params.type - 'business' or 'personal'
   */
  constructor (params) {
    super({ integrity: false });
    this.addRequest(REQ_NAME_LS_SUBSCRIPTIONS, new LsSubscriptionsRequest(params));
    this.addRequest(REQ_NAME_RAS_LS_SUBSCRIPTIONS, new RasLsSubscriptionsRequest(params));
  }

  /**
   *
   * @returns {Array<Subscription>} - list of Subscriptions models.
   */
  getSubscriptions () {
    if (this.data === null) {
      return null;
    }
    const subscriptions = [];
    this.data.subscriptions.forEach((subData) => {
      subscriptions.push(new Subscription(subData));
    });
    return subscriptions;
  }

  combineData (data) {
    let subscriptions = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i] && data[i].subscriptions) {
        subscriptions = subscriptions.concat(data[i].subscriptions);
      }
    }
    return { subscriptions: subscriptions };
  }
}


import Vue from 'vue';
import Beta from '@/modules/beta.vue';
import RegisterBusinessAccountModal from '@/modules/registerBusinessAccount/registerBusinessAccountModal.vue';
import { FEATURE_RAS_CLOUD_BETA } from '@core/constants/features';
import { START_PAGE } from '@/routes/constants';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import { EnvDependentLink, getEnvDependentLink } from '@/data/links';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'pvad-beta',
  mixins: [BreadcrumbsMixin],
  components: {
    Beta,
    RegisterBusinessAccountModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },
  props: {
    useTimestamp: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    const pvadBetaDocumentsRoot = getEnvDependentLink(this, EnvDependentLink.PVAD_BETA_DOCUMENTS_ROOT);
    return {
      error: false,
      PVAD_BETA_DOCUMENTS_ROOT: pvadBetaDocumentsRoot,
      FEATURE_RAS_CLOUD_BETA,
    };
  },
  mounted () {
    if (!this.$appData.session.hasBusinessDomains && this.$appData.session.isFeatureAccessible(FEATURE_RAS_CLOUD_BETA)) {
      this.$modal.show('registerBusinessAccountModal');
    }
  },
  methods: {
    triggerError () {
      this.error = true;
    },
    onCancel () {
      this.$router.push({ name: START_PAGE });
    },
    onRegister () {
      this.$modal.hide();
    },
  },
});


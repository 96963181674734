import { render, staticRenderFns } from "./addLicensesModal.pug?vue&type=template&id=6426b050&lang=pug&external"
import script from "./addLicensesModal.ts?vue&type=script&lang=ts&external"
export * from "./addLicensesModal.ts?vue&type=script&lang=ts&external"
import style0 from "./addLicensesModal.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./addLicensesModal.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports
<copyright>
File: mdm.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
div
  list
    list-item(labelWidth="6")
      .line-height-3x(slot="label")
        .text-success(v-if="server.mdmSubscription") {{ $t('Activated') }}
        .text-danger(v-else) {{ $t('Deactivated') }}
      template(slot="value")
        btn(
          v-if="server.mdmSubscription",
          size="small",
          color="red-stroke",
          :disabled="deactivating",
          @click.native="$emit('deactivateClick')",
          :data-name="$name('button-mdm-deactivate')"
        ).pull-right {{ $t('Deactivate MDM') }}
    list-item
      template(slot="label") {{ $t('Offline Use') }}
      template(slot="value") {{ server.mdmOfflineAllowed === true ? $t('Allowed') : (server.mdmOfflineAllowed === false ? $t('Not Allowed') : '') }}
    list-item
      template(slot="label") {{ $t('Activation Key') }}
      template(slot="value")
        router-link(
          :to="{ name: 'pmmSubscriptionDetails', params: { id: server.mdmSubscriptionParentUuid } }",
          :data-name="$name('link-mdm-subscription-details')"
        )
          | {{ server.mdmLicKey }}
    list-item
      template(slot="label") {{ $t('Activation Date') }}
      template(slot="value") {{ formatDate(server.mdmActivationDate) }}
</template>
<script>
export default {
  name: 'details-modal-mdm',

  props: {
    server: {
      type: Object,
    },
    form: {
      type: Object,
      default () {
        return {
          notes: '',
        };
      },
    },
    deactivating: Boolean,
  },
};
</script>

import { render, staticRenderFns } from "./personalProfile.pug?vue&type=template&id=7fe9a7e0&lang=pug&external"
import script from "./personalProfile.ts?vue&type=script&lang=ts&external"
export * from "./personalProfile.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./personalProfile.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports
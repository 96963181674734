/**
 * File: billingPeriod.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import CoreModel from '@core/common/model';

export default class BillingPeriod extends CoreModel {}

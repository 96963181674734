<copyright>
File: table.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'download-account-data-table',
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};

</script>

<template lang="pug">

datatable.table(
  :data="data",
  :columns="columns",
  :name="name"
)
  template(slot="file_name_header", slot-scope="s") {{ $t('File Name') }}
  template(slot="format_header", slot-scope="s") {{ $t('Format') }}
  template(slot="size_header", slot-scope="s") {{ $t('Size') }}
  template(slot="link_header", slot-scope="s")
  template(slot="format", slot-scope="s") {{ s.row.format }}
  template(slot="size", slot-scope="s")
    template(v-if="s.row.size > 0") {{ s.row.size | fileSize }}
  template(slot="link", slot-scope="s")
    .text-right
      a.inline-block(:href="s.row.link", :data-name="$name('download-link')")
        icon(name="download")

</template>

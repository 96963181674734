/**
 * File: pmmSitesRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PmmSite from '@/models/pmmSite.js';

export default class PmmSitesRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'PmmSitesRequest';
  }

  /**
   *
   * @param {?Object} params
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get url () {
    return '/pmm/webapp/sites';
  }

  /**
   *
   * @returns {Array<PmmSite>} - list of PmmSite models.
   */
  getSites () {
    if (this.data === null) {
      return null;
    }
    const sites = [];
    this.data.forEach((data) => {
      sites.push(new PmmSite(data));
    });
    return sites;
  }

  /**
   *
   * @returns {Number} - Total of founded subscriptions.
   */
  getTotal () {
    if (this.data === null) {
      return null;
    }

    if (this.data.hasOwnProperty('total')) {
      return this.data.total;
    }

    return this.data.length;
  }
}



import Vue from 'vue';
import HeaderView from '@/ui/header/index.vue';
import { init as initAnalytics } from '@/plugins/analytics';
import InitialConfig from '@/models/initialConfig';
import PswWizardModal from '@/modules/pswWizardModal/pswWizardModal.vue';

export default Vue.extend({
  name: 'app',
  props: {
    appData: {
      type: Object,
    },
  },
  components: {
    HeaderView,
    PswWizardModal,
  },
  data () {
    return {
      mode: '',
      loading: true,
    };
  },
  async created () {
    try {
      const initialConfig: InitialConfig = await this.$api.loadInitialConfig();
      initAnalytics(initialConfig);
      this.$bus.$on('openPswWizard', ({ domain }) => this.$modal.show(PswWizardModal, domain));
    } finally {
      this.loading = false;
    }
  },
});


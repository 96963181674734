

import Vue from 'vue';
import { RawLocation } from 'vue-router/types/router';
import { INVITATIONS_LIST_PAGE } from '@/routes/constants';

export default Vue.extend({
  name: 'unregistered-pdfc-support-content',
  props: {
    invitations: {
      type: Array,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openInvitations (domainId) {
      const routeParams: RawLocation = { name: INVITATIONS_LIST_PAGE };

      if (domainId) {
        routeParams.params = { domainId };
      }
      this.$router.push(routeParams);
    },
  },

  computed: {
    invitationsNumber (): number {
      return this.invitations?.length || 0;
    },
  },
});


<copyright>
File: usage.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
div
  list.margin-bottom-2x(:labelWidth="5")
    list-item(v-if="isPostpaid && periodOptions.length")
      template(slot="label")
        .line-height-3x {{ $t('Billing Period') }}
      template(slot="value")
        dropdown.block(
          @change="$emit('periodChange', $event)",
          ref="dropDownPeriods",
          :options="periodOptions",
          v-model="periodId",
          :data-name="$name('dropdown-period')"
        )
          template(slot="text", slot-scope="{ option }")
            | {{ option.startedAt | dateUTC }} - {{ option.endedAt | dateUTC }}
            span.text-muted(v-if="option.isCurrent")  ({{ $t('current') }})
    list-item(v-if="isPostpaid && host.usedUniversalKey")
      template(slot="label") {{ $t('Used Activation Key') }}
      span(slot="value", :data-name="$name('period-status')")
        router-link(:to="{ name: 'rasSubscriptionDetails', params: { id: host.historySubscription.uuid }}",) {{ host.usedUniversalKey }}
    list-item(v-if="isPostpaid")
      template(slot="label") {{ $t('Peak Usage') }}
      template(slot="value")
        span(:data-name="$name('peak-usage')") {{ $tc('{amount} concurrent users', peakUsage, { amount: peakUsage }) }}

  loader.table.max-height-20x(:loading="hostUsageLoading")
    template(v-if="isPostpaid")
      datatable.margin-bottom-2x(
        :data="hostUsageHistory",
        :columns="columns",
        name="hostUsageHistoryTable",
        :options="tableOptions",
        :data-name="$name('table-usage-reports')"
      )
        //-
        //- Table headers
        //-
        template(slot="receive_date_header" slot-scope="s") {{ $t('Report Received At') }}
        template(slot="report_date_header" slot-scope="s") {{ $t('Report Date') }}
        template(slot="amount_header" slot-scope="s") {{ $t('Peak Usage') }}
        //-
        //- Table body
        //-
        template(slot="receive_date" slot-scope="s") {{ s.row.receive_date | dateTime }}
        template(slot="report_date" slot-scope="s") {{ s.row.report_date | date }}
        template(slot="amount" slot-scope="s") {{ s.row.amount }}
    template(v-else)
      datatable.margin-bottom-2x(
        :data="hostUsageHistory",
        :columns="columns",
        name="prepaidHostUsageHistoryTable",
        :options="tableOptions"
      )
        //-
        //- Table headers
        //-
        template(slot="timestamp_header" slot-scope="s") {{ $t('Last Report') }}
        template(slot="usage_header" slot-scope="s") {{ $t('Daily Usage') }}
        //-
        //- Table body
        //-
        template(slot="timestamp" slot-scope="s") {{ s.row.timestamp | date }}
        template(slot="usage" slot-scope="s") {{ s.row.usage }}

</template>

<script>

import { sortByValue } from '@core/common/sorters';
import RasHostUsageHistoryRequest from '@/api/rasHostUsageHistoryRequest';

export default {
  name: 'farm-details-usage',

  props: ['host', 'options', 'isPostpaid'],

  data () {
    return {
      hostUsageLoading: false,
      periodId: null,
      hostUsageHistory: [],
    };
  },

  methods: {
    loadHostUsageHistory () {
      if (this.isPostpaid && !this.periodId) {
        return;
      }

      const request = new RasHostUsageHistoryRequest({
        hwId: this.host.hwId,
        periodId: this.periodId,
        isPostpaid: this.isPostpaid,
      });

      this.hostUsageLoading = true;

      this.$api.authorizedCall(request).then((data) => {
        this.hostUsageHistory = this.isPostpaid ? data.usage : data;
      }).catch(() => {
        this.hostUsageHistory = [];
      }).finally(() => {
        this.hostUsageLoading = false;
      });
    },

    initPeriodId () {
      const options = this.periodOptions;
      if (options.length > 0) {
        if (this.options.billingPeriodId) {
          this.periodId = this.options.billingPeriodId;
        } else {
          this.periodId = options[0].value; // select first
        }
      } else {
        this.periodId = null;
      }
    },
  },

  computed: {

    subscription () {
      return this.host.subscription || this.host.historySubscription;
    },

    periodOptions () {
      const periods = this.options.billingPeriods;
      const options = periods && periods.sort((a, b) => sortByValue(a.id, b.id, false))
        .map((p) => Object.assign({}, p, { value: p.id })) || [];

      if (options.length > 0) {
        options[0].isCurrent = true;
      }
      return options;
    },

    period () {
      return this.options.billingPeriods.find((period) => {
        return period.id === this.periodId;
      }) || null;
    },

    peakUsage () {
      let peakUsage = 0;
      this.hostUsageHistory.forEach((usage) => {
        if (peakUsage < usage.amount) {
          peakUsage = usage.amount;
        }
      });
      return peakUsage;
    },

    columns () {
      if (this.isPostpaid) {
        return ['receive_date', 'report_date', 'amount'];
      }
      return ['timestamp', 'usage'];
    },

    tableOptions () {
      return {
        sortable: this.columns,
      };
    },
  },

  watch: {
    host (newValue) {
      if (newValue !== null) {
        this.initPeriodId();
        this.loadHostUsageHistory();
      } else {
        this.hostUsageHistory = [];
      }
    },

    periodId (newValue) {
      if (newValue !== null) {
        this.loadHostUsageHistory();
      } else {
        this.hostUsageHistory = [];
      }
    },

    periodOptions () {
      this.initPeriodId();
    },
  },

  created () {
    if (this.isPostpaid) {
      this.initPeriodId();
    } else {
      this.loadHostUsageHistory();
    }
  },

};

</script>

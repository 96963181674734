/**
 * File: setReportId.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import { parseQueryString } from '@core/common/url';

export const REPORT_ID_STORE_KEY = 'supportPortalReportId';

const QUERY_STRING = location.search;

export default function (sessionStorage) {
  const query = parseQueryString(QUERY_STRING);

  if (query.reportId) {
    sessionStorage.set(REPORT_ID_STORE_KEY, query.reportId);
  }
}

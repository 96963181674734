/**
 * File: logoutRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class LoginRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/account/webapp/logout';
  }

  getJson () {
    // No JSON content expected here
    return null;
  }
}

/**
 * File: subscriptionAutoRenewalUpdateRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import SvcSubscriptionRequest from '@/api/svcSubscriptionRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import RasSubscriptionsRequest from '@/api/rasSubscriptionsRequest';
import SubscriptionRequest from '@/api/subscriptionRequest';
import { getServiceUrl } from '@core/api/routing';

export default class SubscriptionAutoRenewalUpdateRequest extends ApiRequest {
  get method () {
    return 'PUT';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}`);
  }

  get body () {
    return {
      autorenew: this.params.autorenew,
    };
  }

  getJson () {
    // No JSON content expected here
    return null;
  }

  load () {
    return super.load()
      .then((data) => {
        // Clear cache for all request where subscription autorenewal state is present
        new SvcSubscriptionRequest({
          uuid: this.params.uuid,
          service: this.params.service,
        }).dropCache();
        new SubscriptionsRequest({
          type: SubscriptionsRequest.TYPE_PERSONAL,
          service: this.params.service,
        }).dropCache();
        new SubscriptionsRequest({
          type: SubscriptionsRequest.TYPE_BUSINESS,
          service: this.params.service,
        }).dropCache();
        new RasSubscriptionsRequest().dropCache();
        new SubscriptionRequest({ uuid: this.params.uuid }).dropCache();
        return data;
      });
  }
}

<copyright>
File: cardInfo.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import heightObserverMixin from './heightObserverMixin';

export default {
  name: 'card-info',
  props: {
    /**
     *  Key to track other cards height with the same key
     */
    equalTo: {
      type: String,
    },
  },
  mixins: [heightObserverMixin],
};

</script>

<template lang="pug">

div
  slot

</template>

/**
 * File: reportOemPartnerAbusedRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest, { Method } from '@core/api/apiRequest';
import { licenseService } from '@core/constants/links';

interface ReportRedeemCodeAbuseRequestParams {
  code: string;
}

export default class ReportRedeemCodeAbuseRequest extends ApiRequest<ReportRedeemCodeAbuseRequestParams> {
  get url (): string {
    return `${licenseService.REDEEM_CODES_ABUSED}/${this.params.code}`;
  }

  get method (): Method {
    return 'POST';
  }
}

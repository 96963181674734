/**
 * File: getPackagedLicensesRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import { camelize } from '@core/common/utils';
import oemLicensesStatistics from '@/data/oemLicensesStatistics';
import { PackagedLicensesPack } from '@/models/oemLicenses';

interface GetPackagedLicensesRequestResponse {
  packagedLicensesPacks?: PackagedLicensesPack[];
  statistics: {
    totalPacks: number;
    totalLicenses: number;
  };
}

interface GetPackagedLicensesRequestParams {
  totalOnly?: boolean;
}

export default class GetPackagedLicensesRequest extends ApiRequest<GetPackagedLicensesRequestParams, GetPackagedLicensesRequestResponse> {
  get url () {
    let url = '/license/api/v1/packaged_licenses';
    if (this.params.totalOnly) {
      url += '?total_only=true';
    }
    return url;
  }

  onFetchSuccess (data: GetPackagedLicensesRequestResponse) {
    const resultData = camelize(data);
    if (resultData.packagedLicensesPacks) {
      resultData.packagedLicensesPacks = resultData.packagedLicensesPacks.map((packData) => new PackagedLicensesPack(packData));
    }
    oemLicensesStatistics.totalPacks = resultData.statistics.totalPacks;
    oemLicensesStatistics.totalPackagedLicenses = resultData.statistics.totalLicenses;
    return resultData;
  }
}

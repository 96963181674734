/**
 * File: renewOnlinePage.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import componentMixIn from '@/modules/componentMixIn';
import RenewOptionsRequest from '@/api/renewOptionsRequest';
import GenerateRenewLinkRequest from '@/api/generateRenewLinkRequest';
import SubscriptionAutoRenewalUpdateRequest from '@/api/subscriptionAutoRenewalUpdateRequest';
import renewMixin from './renewMixin';
import PaymentOptionsModal from '../paymentOptions/paymentOptionsModal.vue';
import PaymentOptionsLink from '../paymentOptions/paymentOptionsLink';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

const HOSTS_STEP = 5;

export default {
  name: 'renew-page',
  mixins: [componentMixIn, renewMixin],

  components: { PaymentOptionsModal, PaymentOptionsLink, Breadcrumbs }, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846

  props: {
    id: {
      type: String,
      required: true,
    },
    appData: {
      type: Object,
    },
    byRef: {
      type: Boolean, // Indicates that page was opened by Webapp reference, not from product or directly by url
    },
    canBeMigrated: {
      type: Boolean,
    },
    buyMoreLicenses: {
      type: Boolean,
    },
  },

  data () {
    return {
      autorenewCompleted: false,
      step: HOSTS_STEP,
    };
  },

  mounted () {
    this.load();
  },

  methods: {

    getOptionsRequest () {
      return new RenewOptionsRequest({
        uuid: this.id,
        service: this.subscription.targetServiceName,
        autoRenewable: true,
      });
    },

    getCheckoutUrlRequest () {
      return new GenerateRenewLinkRequest({
        uuid: this.id,
        service: this.subscription.targetServiceName,
        option_uuid: this.durationValue,
        quantity: this.quantity,
      });
    },

    handleTurnAutorenewOn () {
      const request = new SubscriptionAutoRenewalUpdateRequest({
        uuid: this.id,
        service: this.subscription.targetServiceName,
        autorenew: true,
      });
      return this.authorizedCall(request).then(() => {
        this.autorenewCompleted = true;
      });
    },

    onProceedToPaymentOptions () {
      if (this.isAutoRenewMode && !this.autorenewCompleted && this.subscription.isAutorenewable) {
        this.autorenewCompleted = true;
      }
    },

    backOrCancel () {
      if (this.byRef) {
        this.$router.go(-1);
      } else if (this.subscription) {
        this.$router.push({ name: 'subscriptionDetails', params: { id: this.subscription.uuid } });
      } else {
        this.$router.push({ name: 'subscriptions' });
      }
    },

    signHandler (n) {
      this.amount += n;
    },
  },

  computed: {

    durationOptions () {
      if (!this.renewOptions) {
        return [];
      }

      return this.renewOptions.map((option) => {
        return {
          text: this.getDurationString(option.duration_value, option.duration_unit),
          value: option.uuid,
        };
      });
    },

    quantity () {
      return undefined;
    },

    isMinusDisabled () {
      return this.amount <= this.step;
    },

    daysUntilExpire () {
      return this.subscription ? this.subscription.daysUntilExpire : 0;
    },

    isConvertMode () {
      return this.subscription && !this.subscription.canRenew;
    },

    isAwaitingMode () {
      return this.subscription && this.subscription.isWaitingForOfflinePayment;
    },

    isAutoRenewMode () {
      return this.subscription && this.subscription.canRenew;
    },

  },
};

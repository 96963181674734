
import Vue, { PropType } from 'vue';
import ComponentMixin from '@/modules/componentMixIn';
import PostSubscriptionTrait from '@/api/postSubscriptionTrait';
import Subscription from '@/models/subscription';
import { snakeToCamel } from '@core/common/utils';
import Cache from '@core/api/cache';

const enum PswSubscriptionTraits {
  Customer = 'awg_customer',
  Partner = 'awg_partner',
}

export default Vue.extend({
  name: 'edit-properties-modal',
  mixins: [ComponentMixin],
  props: {
    asModal: {
      type: Boolean,
      default: false,
    },
    subscriptionData: {
      type: Object as PropType<Subscription>,
      default: null,
    },
  },
  data () {
    return {
      customer: '',
      partner: '',
      subscription: null as Subscription | null,
      loading: false,
      showDescription: false,
    };
  },
  methods: {
    show (e: { name: string }, subscription?: Subscription, showDescription?: boolean): void {
      this.subscription = subscription;
      this.customer = this.subscription?.traits?.awgCustomer;
      this.partner = this.subscription?.traits?.awgPartner;
      this.showDescription = showDescription;
    },
    closeModal (): void {
      this.$emit('close');
      this.$modal.hide();
    },
    async save () {
      this.loading = true;
      const subscription = this.subscriptionData || this.subscription;
      const promises = [];
      const hasCustomerChanges = subscription && this.customer !== subscription.traits?.awgCustomer;
      if (hasCustomerChanges) {
        promises.push(new PostSubscriptionTrait({
          subscription_uuid: subscription.uuid,
          key_name: PswSubscriptionTraits.Customer,
          value: this.customer,
        }));
      }
      const hasPartnerChanges = subscription && this.partner !== subscription.traits?.awgPartner;
      if (hasPartnerChanges) {
        promises.push(new PostSubscriptionTrait({
          subscription_uuid: subscription.uuid,
          key_name: PswSubscriptionTraits.Partner,
          value: this.partner,
        }));
      }
      await Promise.all(promises.map(async (promise) => {
        const data = await this.authorizedCall(promise);
        if (!subscription.traits) {
          subscription.traits = {};
        }
        subscription.traits[snakeToCamel(data.trait.key_name)] = data.value;
      }));
      this.$modal.hide();
      this.loading = false;
      if (hasCustomerChanges || hasPartnerChanges) {
        new Cache().dropCache();
        this.$toast.show({ lifetime: 2000, text: this.$t('Changes have been saved.') });
        this.$emit('updateSubscription', subscription);
      }
    },
  },
});

<copyright>
  File: index.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
  notification(
    v-if="subscription && !noNotification",
    :closeButton="true",
    @close="$emit('close')"
  )

    div(v-if="subscription.viewStatus === VIEW_STATUS.EXPIRE_SOON && ($appData.session.isBusinessAdmin || $appData.session.isPersonalUser($appData.userInDomain))")
      | {{ $tc('Your subscription expires in {amount} days. Turn subscription renewal on to continue using the product without interruption.', subscription.daysUntilExpire, { amount: subscription.daysUntilExpire }) }}
    div(v-else-if="subscription.viewStatus === VIEW_STATUS.EXPIRE_SOON && $appData.session.isRegularMember")
      | {{ $tc('Your subscription expires in {amount} days. Please contact your administrator.', subscription.daysUntilExpire, { amount: subscription.daysUntilExpire }) }}
    div(v-else-if="subscription.viewStatus === VIEW_STATUS.EXPIRED")
      formatter(v-if="subscription.isGracePeriod", :text="$t('Your subscription expired on {date}. Renew your subscription online as soon as possible to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}
      formatter(v-else, :text="$t('Your subscription expired on {date}. Renew your subscription online to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.CARD_EXPIRED")
      formatter(:text="$t('The credit or debit card linked with this subscription will expire before {date}. Revise your payment options to ensure subscription renewal.')")
        template(slot="date") {{ subscription.expirationDate | date }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.CARD_EXPIRED")
      formatter(:text="$t('The credit or debit card linked with this subscription will expire before {date}. Revise your payment options to ensure subscription renewal.')")
        template(slot="date") {{ subscription.expirationDate | date }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.CB_EXPIRED") {{ cbExpired }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.RENEW_FAILED")
      span(v-if="subscription.transactionState === CB_TRANSACTION_STATES.renew.refund")
        | {{ $t('Your subscription has been suspended because of a chargeback reported by our payment service provider. Renew your subscription online to continue using the product.') }}
      span(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.renew.offline")
        formatter(:text="$t('Your subscription expired on {date}. Complete your renewal payment with PayPal or wire transfer as soon as possible to continue using the product.')")
          template(slot="date") {{ subscription.expirationDate | date }}
      span(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.renew.decline")
        formatter(:text="$t('Your subscription expired on {date}. Your renewal payment was declined by the payment system or card issuer. Revise your payment options as soon as possible.')")
          template(slot="date") {{ subscription.expirationDate | date }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.PDL_EXPIRE_SOON")
      | {{ $t('Your subscription end date is approaching. Review your subscription status in Apple ID or upgrade to full version to continue using the product without interruption.') }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.PDL_EXPIRED")
      formatter(v-if="subscription.isGracePeriod", :text="$t('Your subscription expired on {date}. Review your subscription status in Apple ID or upgrade to full version as soon as possible to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}
      formatter(v-else, :text="$t('Your subscription expired on {date}. Renew your subscription in Apple ID or upgrade to full version to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}

    div(v-if="subscription.viewStatus === VIEW_STATUS.SF_EXPIRE_SOON")
      template(v-if="$appData.session.isBusinessAdmin") {{ $tc('Your subscription expires in {amount} days. Contact your Parallels sales representative for renewal details to continue using the product without interruption.', subscription.daysUntilExpire, { amount: subscription.daysUntilExpire }) }}
      template(v-else-if="$appData.session.isRegularMember") {{ $tc('Your subscription expires in {amount} days. Please contact your administrator.', subscription.daysUntilExpire, { amount: subscription.daysUntilExpire }) }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.SF_EXPIRED")
      formatter(v-if="subscription.isGracePeriod", :text="$t('Your subscription expired on {date}. Contact your Parallels sales representative for renewal details as soon as possible to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}
      formatter(v-else, :text="$t('Your subscription expired on {date}. Contact your Parallels sales representative for renewal details to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.AAS_EXPIRE_SOON")
      | {{ $t('Your subscription end date is approaching. Review your subscription status in Apple ID to continue using the product without interruption.') }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.SUSPENDED")
      | {{ $t('Your subscription has been suspended. Contact your Parallels sales representative for details.') }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.AAS_EXPIRED")
      formatter(v-if="subscription.isGracePeriod", :text="$t('Your subscription expired on {date}. Renew your subscription online as soon as possible to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}
      formatter(v-else, :text="$t('Your subscription expired on {date}. Renew your subscription online to continue using the product.')")
        template(slot="date") {{ subscription.expirationDate | date }}

    div(v-else-if="subscription.viewStatus === VIEW_STATUS.TRIAL")
      template(v-if="subscription.daysUntilExpire > 0") {{ $t('Your trial expires in {amount} days. Upgrade to subscription as soon as possible to continue using the product without interruption.', { amount: subscription.daysUntilExpire }) }}
      template(v-else) {{ $t('Your trial is expired.') }}

    div(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.increase.offline")
      | {{ $t('Complete the payment for additional licenses using PayPal or wire transfer. Your subscription will be updated once the payment has been received.') }}
    div(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.increase.decline")
      | {{ $t('Your payment for additional licenses was declined by the payment system or card issuer. Revise your payment options as soon as possible.') }}
    div(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.increase.refund")
      | {{ $t('Your recent transaction has been canceled because of a chargeback reported by our payment service provider.') }}

    div(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.upgrade.offline")
      | {{ $t('Complete the payment using PayPal or wire transfer. Your subscription will be updated once the payment has been received.') }}
    div(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.upgrade.decline")
      | {{ $t('Your recent transaction was declined by the payment system or card issuer.') }}
    div(v-else-if="subscription.transactionState === CB_TRANSACTION_STATES.upgrade.refund")
      | {{ $t('Your recent transaction has been canceled because of a chargeback reported by our payment service provider.') }}

</template>

<script>
import { VIEW_STATUS } from '@/models/subscription';
import { CB_TRANSACTION_STATES } from '@core/constants/subscriptions';

export default {
  props: ['subscription'],

  data: () => ({
    VIEW_STATUS,
    CB_TRANSACTION_STATES,
    noNotificationStatus: [
      VIEW_STATUS.PERMANENT,
      VIEW_STATUS.ACTIVE,
      VIEW_STATUS.WILL_RENEW,
      VIEW_STATUS.BLACKLISTED,
      VIEW_STATUS.BYTEBOT,
    ],
  }),

  computed: {
    noNotification () {
      const cbState = this.subscription.transactionState;
      return this.noNotificationStatus.includes(this.subscription.viewStatus) &&
          (!cbState || cbState === CB_TRANSACTION_STATES.default);
    },
    cbExpired () {
      const date = this.formatDate(this.subscription.expirationDate);
      if (this.subscription.isGracePeriod) {
        if (this.$appData.session.isBusinessAdmin || this.$appData.session.isPersonalUser(this.$appData.userInDomain)) {
          return $t('Your subscription expired on {date}. Renew the subscription as soon as possible to continue using the product. Revise your payment options to enable subscription renewal.', { date });
        } else if (this.$appData.session.isCurrentRegularMember(this.$appData.userInDomain)) {
          return $t('Your subscription expired on {date}. Please contact your administrator for renewal details to continue using the product.', { date });
        } else {
          return '';
        }
      } else {
        if (this.$appData.session.isCurrentRegularMember(this.$appData.userInDomain)) {
          return $t('Your subscription expired on {date}. Please contact your administrator for renewal details to continue using the product.', { date });
        } else {
          return $t('Your subscription expired on {date}. Renew the subscription to continue using the product. Revise your payment options to enable subscription renewal.', { date });
        }
      }
    },
  },
};

</script>

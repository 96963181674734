/**
 * File: updateSubsetRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import CreateSubsetRequest from './createSubsetRequest';
import { getServiceUrl } from '@core/api/routing';

export default class UpdateSubsetRequest extends CreateSubsetRequest {
  get method () {
    return 'PUT';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/subsets/${this.params.subsetUuid}`);
  }

  get errors () {
    return {
      'available value is exceeded': $t('License key update has failed: no more licenses available for allocation.'),
    };
  }
}

/**
 * File: profileUpdateRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { STATES } from '@core/constants/geo';

export default class ProfileUpdateRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/profile/update';
  }

  get body () {
    var payload = Object.assign({}, this.params);

    if (!STATES[payload.country]) {
      delete payload.state;
    }

    return payload;
  }
}

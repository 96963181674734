

import Vue from 'vue';
import CreateTicketRequest from '@/api/rt/rtCreateTicketWithoutAuthRequest';
import { emailValidator, required, requiredIf } from '@core/common/validators';

const CONFIRMATION_EMAIL_SCENARIO = 1;
const FORGOT_PASSWORD_SCENARIO = 2;
const SOCIAL_LOGIN_SCENARIO = 3;

export {
  CONFIRMATION_EMAIL_SCENARIO,
  FORGOT_PASSWORD_SCENARIO,
  SOCIAL_LOGIN_SCENARIO
};

export default Vue.extend({
  name: 'contact-support-modal',
  props: {
    email: {
      type: String,
    },
    title: {
      type: String,
    },
    scenario: {
      type: Number,
      required: true,
    },
    displayEmail: {
      type: Boolean,
      default: false,
    },
    // TODO: remove this param, add appropriate scenario if needed
    restorePurchases: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      description: '',
      localEmail: this.email,
      CONFIRMATION_EMAIL_SCENARIO,
      FORGOT_PASSWORD_SCENARIO,
      SOCIAL_LOGIN_SCENARIO,
    };
  },
  validations: {
    localEmail: {
      required: requiredIf(function (this: any) {
        return this.displayEmail;
      }),
      email: emailValidator,
    },
    description: {
      required,
    },
  },
  methods: {
    send (): void {
      const request = new CreateTicketRequest({
        scenario: this.scenario,
        description: this.description,
        email: this.localEmail,
      });
      const method = this.localEmail ? 'call' : 'callRt';

      this.loading = true;

      // RT url comes with session
      // TODO: move session request to app initialization
      if (!this.$appData.session) {
        this.$api.refreshToken().then(() => {
          this.send();
        });
        return;
      }

      this.$api[method](request).then((data) => {
        this.hide();
        this.$toast.show({
          text: $t('Your request has been submitted. The Parallels Support team will contact you soon.'),
        });
      }).catch((error) => {
        error.response.json().then((data) => {
          let html;

          if (data.errorMessages && data.errorMessages.includes('We\'ve already received request')) {
            this.hide();
            html = $t('We’ve already received your request. {line_feed} Please expect a reply from Parallels Support.', { line_feed: '<br/>' });
          } else {
            html = $t('Your request cannot be submitted at the moment. Please try again later.');
          }

          this.$toast.show({
            html,
            color: 'red',
          });
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    hide (): void {
      this.$modal.hide();
      this.loading = false;
      this.description = '';
    },
  },
  watch: {
    email (value) {
      this.localEmail = value;
    },
  },
});


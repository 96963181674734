/**
 * File: subscriptionsPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue, { PropType } from 'vue';

import SubscriptionContent from './subscriptionsContent.vue';
import Subscription from '@/models/subscription';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'subscriptions-page',
  mixins: [BreadcrumbsMixin],
  components: { SubscriptionContent, Breadcrumbs }, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846

  props: {
    appData: {
      type: Object as PropType<AppData>,
      required: true,
    },
  },

  data () {
    return {
      filteredSubscriptions: [] as Subscription[],
    };
  },

  methods: {
    onFilteredSubscriptions (subscriptions: Subscription[]) {
      this.filteredSubscriptions = subscriptions;
    },
  },
});

<copyright>
File: paymentOptionsLink.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'payment-options-link',
  props: ['url', 'fromModal', 'isBtn'],
  methods: {
    clickHandler () {
      const modal = this.$modal.get('paymentOptionsModal');
      if (modal) {
        modal.$emit('linkClick', this.$props);
      }
    },
  },
});

</script>

<template lang="pug">
div
  btn(
    v-if="isBtn && url",
    @click="clickHandler",
    :data-name="$name('button-payment-options')"
  )
    slot(name="text") {{ $t('Payment Options') }}
  span.link(
    v-else-if="url",
    @click="clickHandler",
    :data-name="$name('link-payment-options')"
  )
    slot(name="text") {{ $t('Payment Options') }}
</template>

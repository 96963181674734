/**
 * File: resetPage.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { password } from '@core/common/validators';
import UsePasswordRequest from '@/api/usePasswordRequest';
import CheckResetPasswordCodeRequest from '@/api/checkResetPasswordCodeRequest';
import FormMixin from '@/modules/formMixin';
import ComponentMixin from '@/modules/componentMixIn';
import DisconnectHostsHint from '@/ui/disconnectHostsHint';

export default {
  name: 'reset-password',
  components: {
    DisconnectHostsHint,
  },
  props: ['appData', 'code'],
  mixins: [FormMixin, ComponentMixin],
  data () {
    return {
      password: '',
      disconnectAll: true,
    };
  },
  validations: {
    password,
  },
  created () {
    const request = new CheckResetPasswordCodeRequest({ code: this.code });

    this.loading = true;

    this.$api.call(request).then((data) => {
    }).catch(() => {
      this.error = true;
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    submit () {
      const request = new UsePasswordRequest({
        password: this.password,
        disconnect_remote_hosts: this.disconnectAll,
        code: this.code,
      });

      this.loading = true;

      this.$api.call(request).then(() => {
        this.$toast.show({
          text: $t('The password has been successfully reset.'),
        });
        this.$router.replace({ name: 'login' });
      }).finally(() => {
        this.loading = false;
      });
    },
  },

};

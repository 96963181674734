<copyright>
File: uploader.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import fileUploader from '@/ui/file-uploader';
import PmmCertificateSignRequest from '@/api/pmmCertificateSignRequest';
import componentMixIn from '@/modules/componentMixIn';

export default {
  name: 'uploader',

  mixins: [fileUploader, componentMixIn],

  methods: {
    upload (fileList) {
      this.$emit('start');

      const request = new PmmCertificateSignRequest({}, fileList[0]);

      this.authorizedCall(request).then((data) => {
        this.$emit('success', data, fileList[0].name);
      }).catch((e) => {
        this.error = this.$t('The file does not appear to be valid CSR file.');
        this.$emit('error');
      });
    },
  },
};
</script>



import Vue from 'vue';
import { Dictionary } from '@core/common/types';
import { required, ValidationRule } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'confirmation-modal',

  props: {
    modalName: {
      type: String,
    },

    showNotes: {
      type: Boolean,
      default: false,
    },

    notesMandatory: {
      type: Boolean,
      default: false,
    },

    showMessage: {
      type: Boolean,
      default: true,
    },

    size: {
      type: [String, Number],
      default: 'auto',
    },
  },

  data () {
    return {
      conf: {} as { notesMandatory?: boolean; success?: Function },
      notes: '',
    };
  },

  validations () {
    const validators: { notes?: Dictionary<(string)=> ValidationRule> } = {};

    if (this._notesMandatory) {
      validators.notes = {
        required,
      };
    }

    return validators;
  },

  methods: {
    show (e, conf) {
      this.conf = Object.assign({}, conf);
    },

    reset () {
      this.$v.$reset();
      this.conf = {};
      this.notes = '';
    },

    cancelHandler () {
      this.reset();
      this.$emit('cancel', this.conf);
      this.$modal.hide();
    },

    confirmHandler () {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const data = Object.assign({ notes: this.notes }, this.conf);

      if (this.conf.success) {
        this.conf.success(data);
      }

      this.reset();
      this.$emit('confirm', data);
      this.$modal.hide();
    },
  },

  computed: {
    _notesMandatory (): boolean {
      return this.conf.hasOwnProperty('notesMandatory') ? this.conf.notesMandatory : this.notesMandatory;
    },
  },
});


/**
 * File: componentMixIn.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

import { prodKeyToName, resourceToName } from '@core/constants/subscriptions';

export default Vue.extend({

  data () {
    return {
      loading: false,
      error: false,
      retryInterval: 1000,
    };
  },

  methods: {

    retryCall (request, options: { attempt: number }): Promise<void> {
      options.attempt += 1;

      const interval = this.retryInterval * options.attempt;
      const self = this;

      return new Promise((resolve, reject) => {
        setTimeout(function () {
          self.authorizedCall(request, options)
            .then((data) => {
              resolve(data);
            });
        }, interval);
      });
    },

    authorizedCall (request, loader?: string | object | null): Promise<void> {
      let options = {
        loader: 'loading',
        retry: true, // retry if connection is broken (Failed to fetch)
        toast: true, // show toast if request is failed
        attempt: 0, // current attempt
        maxAttempts: 3,
      };

      const isHideLoader = loader === null;

      if (typeof loader === 'string') {
        options.loader = loader;
      } else if (typeof loader === 'object') {
        options = Object.assign(options, loader);
      }

      if (!isHideLoader) {
        this[options.loader] = true;
      }

      return this.$api.authorizedCall(request)
        .then((data) => {
          this[options.loader] = false;
          return data;
        })
        .catch((error) => {
          const errStr = String(error);
          if (errStr === 'TypeError: Failed to fetch') {
            if (options.retry && options.attempt <= options.maxAttempts) {
              return this.retryCall(request, options);
            }

            this[options.loader] = false;

            if (options.toast) {
              this.$toast.show({
                tag: 'connectionError', // do not duplicate the same toasts
                color: 'red',
                text: $t('There was a problem completing this request.'),
              });
            }
          }
          this[options.loader] = false;
          throw error;
        });
    },

    showToast (msg: string, color?: string): void {
      this.$toast.show({
        color: color || 'green',
        text: msg,
      });
    },

    getProductName (keyName: string): string {
      const name = prodKeyToName(keyName);
      return name ? this.$t(name) : null;
    },

    getResourceName (keyName: string): string {
      const name = resourceToName(keyName);
      return name ? this.$t(name) : null;
    },

    signIn (): void {
      this.$nextTick(() => {
        this.$router.push({
          name: 'login',
        });
      });
    },

    switchBusinessDomain (domainId: number): Promise<void> {
      if (this.session.isCurrentBusinessUser(domainId)) {
        // just change a choice
        this.$appData.setUserInDomain(domainId);
        return this.$api.initProducts();
      } else {
        // switch to business account
        return this.$api.switchBusinessDomain(domainId);
      }
    },

    isFeatureAccessible (featureName: string): boolean {
      return this.$appData.session.isFeatureAccessible(featureName);
    },
  },

  watch: {
    error (value): void {
      this.$emit('error', value);
    },
    loading (value): void {
      this.$emit('loading', value);
    },
  },
});

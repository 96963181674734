

import Vue, { PropType } from 'vue';
import Subscription from '@/models/subscription';
import UniversalKey, { UniversalKeyType } from '@/models/universalKey';
import CreateSubsetRequest from '@/api/createSubsetRequest';
import UpdateSubsetRequest from '@/api/updateSubsetRequest';
import { dateValue } from '@core/common/format/date';
import { requiredUnless } from '@core/common/validators';
import { Dictionary } from '@core/common/types';

type SubsetOption = { value: UniversalKeyType; text: string; disabled?: boolean };

export default Vue.extend({
  name: 'general-tab',
  props: {
    subscription: {
      required: true,
      type: Object as PropType<Subscription>,
    },
    subset: {
      type: Object as PropType<UniversalKey>,
    },
    isPrimaryKey: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    subsetName: {
      type: String as PropType<string>,
    },
    primaryKey: {
      type: Object as PropType<UniversalKey>,
    },
  },
  data () {
    return {
      form: {
        amount: '1',
        type: UniversalKeyType.Reserved,
        expirationDate: null as Date,
        renewAutomatically: false,
      },
      restrictAmount: false,
    };
  },
  validations: {
    form: {
      amount: {
        amount (val, form): boolean {
          val = Number(val);
          if (this.subscription.isRasPostpaid) {
            return (val > 0 && val <= this.limit) || !this.restrictAmount;
          }
          return val > 0 && val <= this.limit;
        },
      },
      expirationDate: {
        required: requiredUnless('renewAutomatically'),
        expired (val, form): boolean {
          if (form.renewAutomatically || !val || !this.subset) {
            return true;
          }
          return val >= this.minExpirationDate;
        },
        valid (val, form): boolean {
          if (form.renewAutomatically || !val || (this.subset && val < this.minExpirationDate)) {
            return true;
          }
          if (!this.maxExpirationDate) {
            // Permanent: no max limit
            return val >= this.minExpirationDate;
          }
          return val <= this.maxExpirationDate && val >= this.minExpirationDate;
        },
      },
    },
  },
  created () {
    this.updateForm(this.subset);
  },
  methods: {
    updateForm (subset?: UniversalKey) {
      const expirationDate = this.getExpirationDate();
      this.form = subset ?
        {
          type: subset.type,
          amount: String(subset.quota),
          renewAutomatically: !subset.expirationDate,
          expirationDate: subset.expirationDate || expirationDate,
        } :
        {
          amount: '1',
          type: this.allowDynamic ? UniversalKeyType.Dynamic : UniversalKeyType.Reserved,
          renewAutomatically: true,
          expirationDate: expirationDate,
        };

      if (this.subscription.isRasPostpaid) {
        this.restrictAmount = !!(subset && subset.isLimited);
      }
    },
    save () {
      if (this.isPrimaryKey) {
        const promise = Promise.resolve({});
        this.$emit('save', promise);
        return promise;
      }

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      const params: Dictionary = {
        uuid: this.subscription.uuid,
        service: this.subscription.targetServiceName,
        name: this.subsetName,
        reservation: this.form.type === UniversalKeyType.Reserved,
        expiration_date: this.form.renewAutomatically ? null : dateValue(this.form.expirationDate),
      };

      if (!this.subscription.isRasPostpaid || this.restrictAmount) {
        params.amount = Number(this.form.amount);
      }

      let request;
      if (this.subset) {
        params.subsetUuid = this.subset.subsetUuid;
        request = new UpdateSubsetRequest(params);
      } else {
        request = new CreateSubsetRequest(params);
      }

      const promise = this.$api.authorizedCall(request);
      this.$emit('save', promise);

      return promise;
    },
    getExpirationDate (): Date {
      let expirationDate = this.maxExpirationDate;
      if (this.subscription.isRasSPLA) {
        const monthFromNow = new Date();
        monthFromNow.setMonth(monthFromNow.getMonth() + 1);
        const isExpirationMoreThanOneMonth = expirationDate && expirationDate.getTime() > monthFromNow.getTime();
        expirationDate = isExpirationMoreThanOneMonth ? monthFromNow : expirationDate;
      }
      return expirationDate;
    },
  },
  computed: {
    allowDynamic (): boolean {
      return this.subscription.isDynamicSubsetAllowed;
    },

    limit (): number {
      // Limit for dynamic subset
      let limit = this.subscription.firstProductLimitThisPeriod || 0;
      // Limit for reserved subset
      if (this.form.type === UniversalKeyType.Reserved) {
        limit = this.primaryKey && this.primaryKey.available || 0;
        if (this.subset) {
          if (this.subset.type === UniversalKeyType.Reserved) {
            // If existing subset has some reserved licences, they are available also
            limit += this.subset.limit;
          } else {
            // If existing reserved subset has some usage - it is available also
            limit += this.subset.usage;
          }
        }
      }

      if (this.restrictAmount) {
        limit = 32000;
      }

      return limit;
    },

    minExpirationDate (): Date {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      now.setHours(0, 0, 0, 0);
      return now;
    },

    maxExpirationDate (): Date {
      return this.subscription?.expirationDate;
    },

    subsetTypeOptions (): SubsetOption[] {
      const options: SubsetOption[] = [{ value: UniversalKeyType.Reserved, text: this.$t('Reserved') }];
      if (this.allowDynamic) {
        options.unshift({ value: UniversalKeyType.Dynamic, text: this.$t('Dynamic'), disabled: !this.allowDynamic });
      }
      return options;
    },
  },
  watch: {
    subset (value) {
      this.updateForm(value);
    },
    'form.renewAutomatically': function (value) {
      if (value && this.maxExpirationDate) {
        this.form.expirationDate = this.getExpirationDate();
      }
      if (!value && !this.form.expirationDate) {
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs.expirationDate.clickPicker();
        });
      } else if (this.form.expirationDate) {
        this.$v.form.expirationDate.$touch();
      }
    },
    'form.type': function () {
      this.$v.form.amount.$touch();
    },
    restrictAmount (value) {
      this.$emit('updateAmountRestriction', {
        restrictAmount: value,
      });
    },
  },
});


/**
 * File: registerEvent.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class RegisterEventRequest extends ApiRequest {
  get url () {
    return '/account/webapp/register_event';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      event_name: this.params.eventName,
      page_url: this.params.pageUrl,
      extra: this.params.extra,
    };
  }
}

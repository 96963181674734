

import Vue, { PropType } from 'vue';
import { email, optional } from '@core/common/validators';
import { CsvReader } from '@core/common/csv';
import fileUploader from '@/ui/file-uploader/index.vue';
import { Dictionary } from '@core/common/types';

interface CustomValidation {
  name: string;
  message: string;
  callback: (string)=> boolean;
}

export default Vue.extend({
/*
  Editable email list
  Allows user to enter emails manually or upload from csv file(s)
*/

  name: 'email-list',

  props: {
    /**
     * The property value changes whenever email list is valid or not - e.g.
     * when not empty and validations are passed
     */
    valid: Boolean as PropType<boolean>,

    /**
     * Property containing entered emails
     */
    list: Array as PropType<string[]>,

    /**
     * List of custom validations for both email input and email list
     * each list item should be a dictonary of 3 keys:
     *  * name - valiation name
     *  * message - error message if validation fails
     *  * callback - validation callback
     */
    customValidations: {
      type: Array as PropType<CustomValidation[]>,
      default: () => [],
    },

    /**
     * Max number of emails in the list
     */
    limit: {
      type: Number as PropType<number>,
      default: Infinity,
    },

    displayEmptyError: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data () {
    return {
      input: '',
      emails: this.list.slice(0) as string[],
    };
  },

  watch: {
    list (value: string[]) {
      this.emails = value.slice(0);
    },
    input () {
      this.resetInputValidation();
    },
  },

  validations () {
    // @ts-ignore
    const validation = Object.assign({}, this.customValidation, email);

    return {
      input: optional(Object.assign({
        duplicate (email) {
          // @ts-ignore
          return !this.emails.includes(email);
        },
        limit () {
          // @ts-ignore
          return this.emails.length <= this.limit - 1;
        },
      }, validation)),
      emails: {
        emptyList (emails: string[]) {
          // @ts-ignore
          return !this.displayEmptyError || emails.length > 0;
        },
        $each: validation,
      },
    };
  },

  computed: {
    customErrorNames (): string[] {
      return ['duplicate', 'limit'].concat(this.customValidations.map((validation) => validation.name));
    },

    customValidation (): Dictionary<CustomValidation['callback']> {
      return this.customValidations.reduce((result, customValidation) => {
        result[customValidation.name] = customValidation.callback;

        return result;
      }, {});
    },

    label (): string {
      if (Number.isFinite(this.limit) && this.limit > 0) {
        return this.$tc('If you have a CSV file with a list of emails, drag and drop it here or click Select File to select a file to upload. You can add up to {participantsCount} recipients', this.limit, { participantsCount: this.limit });
      } else {
        return this.$t('If you have a CSV file with a list of emails, drag and drop it here or click Select File to select a file to upload');
      }
    },

    placeholder (): string {
      if (Number.isFinite(this.limit) && this.limit > 0) {
        return this.$tc('Enter email addresses (up to {n})', this.limit, { n: this.limit });
      }
      return this.$t('Enter email address');
    },
  },

  methods: {
    submit () {
      if (!this.input) {
        return;
      }

      this.$v.input.$touch();

      if (!this.$v.input.$error) {
        this.add();
      }
    },

    add () {
      if (this.emails.indexOf(this.input) === -1) {
        this.emails.push(this.input);
        this.notify();
      }

      this.input = '';
      this.$v.input.$reset();
    },

    remove (target) {
      this.emails = this.emails.filter((email) => email !== target);
      this.notify();
    },

    removeAll () {
      this.emails = [];
      this.notify();
    },

    loadFiles (files) {
      const
        flatten = (arr) => Array.prototype.concat.apply([], arr);
      const readFile = (file) => flatten(new CsvReader().toArray(file));

      this.emails = flatten(files.map(readFile)).map(entry => entry.trim()).filter((entry) => entry.indexOf('@') !== -1);
      this.$nextTick(() => this.notify());
    },

    notify () {
      this.$v.emails.$touch();

      this.$emit('update:list', this.emails);
      this.$emit('update:valid', !this.$v.emails.$invalid);
    },

    resetValidation () {
      this.$v.$reset();
    },

    resetInputValidation () {
      if (this.limit && !this.input) {
        // blur in textbox happens first
        this.$nextTick(() => {
          this.$v.input.$reset();
        });
      }
    },
  },

  components: {
    fileUploader,
  },
});


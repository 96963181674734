/**
 * File: userConst.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

export const STATUS_DISABLED = 2;
export const GROUP_INVITED = 'sys-invited';
export const GROUP_ADMIN = 'sys-admin';

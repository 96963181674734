/**
 * File: addLicensesModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue, { PropType } from 'vue';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import SvcSubscriptionRequest from '@/api/svcSubscriptionRequest';
import ChangeLicenseAmountRequest from '@/api/changeLicenseAmountRequest';
import UpgradeOnlineRequest from '@/api/upgradeOnlineRequest';
import PaymentOptionsLink from '../subscriptions/paymentOptions/paymentOptionsLink.vue';
import TransactionProcessingModal from './transactionProcessingModal.vue';
import SubscriptionProductsTable from './subscriptionProductsTable.vue';
import SalesTab from '@/modules/subscriptions/contract/salesTab.vue';
import { textFilter } from '@/modules/subscriptions/helpers';
import { PRODUCT_KEY_NAME_PMM, PRODUCT_KEY_NAME_PTB } from '@core/constants/subscriptions';
import Subscription, { Modification } from '@/models/subscription';
import ContractRequest from '@/api/contractRequest';
import type { IContract } from '@/modules/subscriptions/contract/contractDetailsModal.vue';

export default Vue.extend({
  name: 'addLicensesModal',
  mixins: [ComponentMixIn, SubscriptionMixIn, SearchFilterMixin],
  components: {
    PaymentOptionsLink,
    TransactionProcessingModal,
    SubscriptionProductsTable,
    SalesTab,
  },
  props: {
    subscriptions: {
      type: Array as PropType<Subscription[]>,
      default: null,
    },
  },
  data () {
    return {
      columns: ['selected', 'name', 'firstProductTotalAvailable', 'expirationDate', 'url'],
      tableOptions: {
        sortable: ['name', 'firstProductTotalAvailable', 'expirationDate'],
      },
      product: null as string,
      subscription: null as Subscription,
      selectedUuid: null as string, // Selected subscription uuid
      predefinedUuid: null as string, // Defined subscription uuid (can't be changed by table selection)
      showBackButton: false,
      add: '0',
      contactToSales: false,
      cbUrl: '',
      filters: {},
      textFilter,
      cbLicenseStage: 0,
      salesRepDetailsLoading: false,
      contract: { contractDetails: {}, salesRepresentative: {} } as IContract,
    };
  },
  methods: {
    show (e, params) {
      if (params) {
        this.product = params.product;
        this.predefinedUuid = params.subscriptionUuid || null;
      }
      if (this.availableSubscriptions.length === 1 && !this.predefinedUuid) {
        this.predefinedUuid = this.availableSubscriptions[0].uuid;
      }
    },
    cancelButtonHandler () {
      this.product = null;
      this.predefinedUuid = null;
      this.selectedUuid = null;
      this.contactToSales = false;
      this.showBackButton = false;
      this.cbUrl = '';
      this.add = '0';
      this.cbLicenseStage = 0;
      this.contract = { contractDetails: {}, salesRepresentative: {} } as IContract;
      this.$modal.hide();
      this.$emit('hide');
    },
    signHandler (amount) {
      this.add = String(this.addAmount + amount);
    },
    payButtonHandler () {
      const request = new ChangeLicenseAmountRequest({
        uuid: this.subscription.uuid,
        product: this.subscription.products[0].keyName,
        amount: Math.ceil(this.newAmount / this.packSize),
        service: this.subscription.targetServiceName,
      });

      this.$modal.hide();

      this.$modal.show(TransactionProcessingModal, request);
    },
    backButtonHandler () {
      this.showBackButton = false;
      this.selectedUuid = null;
      this.add = '0';
    },
    loadSubscription (uuid) {
      const subscription = this.subscriptions.find((s) => s.uuid === uuid);
      if (subscription.canAddLicenses) {
        // load extended subscription details (with price, currency etc.)
        const request = new SvcSubscriptionRequest({ uuid: uuid, service: subscription.targetServiceName });
        this.authorizedCall(request).then(() => {
          // @ts-ignore
          this.subscription = request.getSubscription();
          if (!this.subscription.canAddLicenses) {
            this.contactToSales = true;
          }
        });
      } else if (subscription.canUpgradeOnline) {
        const request = new UpgradeOnlineRequest({ service: subscription.targetServiceName, uuid: uuid });
        this.authorizedCall(request).then((data) => {
          if (data && data.url) {
            this.cbUrl = data.url;
          } else {
            this.contactToSales = true;
          }
        });
      }
    },
    nextButtonHandler () {
      this.showBackButton = true;
      this.loadSubscription(this.selectedUuid);
    },
    onSuccess () {
      this.showToast($tc('{number} additional licenses has been purchased.', this.addAmount, { number: this.addAmount }));
      this.cancelButtonHandler();
      this.$nextTick(() => {
        // re-init current page
        this.$router.replace({ name: 'go', query: { continue: this.$route.fullPath } });
      });
    },
    toStage0 () {
      this.cbLicenseStage = 0;
    },
    toStage1 () {
      this.cbLicenseStage = 1;
    },
    toStage2 () {
      this.cbLicenseStage = 2;
      this.loadContractData();
    },
    loadContractData (): Promise<void> {
      const subscription = this.subscriptions.find((s) => s.uuid === this.selectedUuid);
      const request = new ContractRequest({
        uuid: subscription.uuid,
        service: subscription.targetServiceName,
      });
      this.salesRepDetailsLoading = true;

      return this.authorizedCall(request).then((data) => {
        this.contract = data;
      }).finally(() => {
        this.salesRepDetailsLoading = false;
      });
    },
  },
  computed: {
    availableSubscriptions (): Subscription[] {
      let availableSubscriptions = this.subscriptions.filter(s => s.canBuyMoreLicenses && !s.isExpired);
      if (this.product) {
        if (this.product.startsWith(PRODUCT_KEY_NAME_PTB)) {
          availableSubscriptions = availableSubscriptions.filter(s => s.hasPrimaryProduct(this.product));
        } else {
          availableSubscriptions = availableSubscriptions.filter(s => s.hasProduct(this.product));
        }
      }
      return availableSubscriptions;
    },
    isSalesRepDataAvailable (): boolean {
      return !!(this.contract?.salesRepresentative?.email || this.contract?.salesRepresentative?.phone);
    },
    currency (): string {
      return (this.subscription && this.subscription.nextBillingCostCurrency) || '';
    },
    addAmount (): number {
      return parseInt(this.add) || 0;
    },
    currentAmount (): number {
      return (this.subscription && this.subscription.products && this.subscription.products[0].licenses.thisPeriod) || 0;
    },
    newAmount (): number {
      return this.currentAmount + this.addAmount;
    },
    modification (): Modification {
      return this.subscription && this.subscription.firstProductModification;
    },
    maxAmount (): number {
      return (this.modification && this.modification.maxAmt) || 0;
    },
    maxValue (): number {
      return (this.maxAmount - this.currentAmount) || 0;
    },
    minAmount (): number {
      return (this.modification && this.modification.minAmt) || 0;
    },
    minValue (): number {
      return (this.minAmount - this.currentAmount) || 0;
    },
    packSize (): number {
      return (this.modification && this.modification.increment) || 1;
    },
    step (): number {
      if (this.subscription && this.subscription.hasProduct(PRODUCT_KEY_NAME_PMM)) {
        return 100; // FIXME CPCLOUD-13874
      }
      return this.packSize;
    },
    /**
     *  How much to pay for each additional license for current billing period
     */
    addItemCost (): number {
      return (this.modification && parseFloat(this.modification.incrementCost) / this.packSize) || 0;
    },
    /**
     *  How much each additional license will increase price for next billing periods
     */
    itemCost (): number {
      return (this.modification && parseFloat(this.modification.wholePeriodCost) / this.packSize) || 0;
    },
    isMaxReached (): boolean {
      return Boolean(this.maxAmount && this.currentAmount >= this.maxAmount);
    },
    isWaitingForOfflinePayment (): boolean {
      return Boolean(this.subscription && this.subscription.isWaitingForOfflinePayment);
    },
    isSubscriptionInvalid (): boolean {
      if (this.maxAmount && this.currentAmount > this.maxAmount) {
        return true;
      } else if (this.minAmount && this.currentAmount < this.minAmount) {
        return true;
      }
      return false;
    },
    isFormDisabled (): boolean {
      return this.isMaxReached || this.isSubscriptionInvalid || this.isWaitingForOfflinePayment;
    },
    isPlusDisabled (): boolean {
      return this.isFormDisabled;
    },
    isMinusDisabled (): boolean {
      return Boolean(this.isFormDisabled || (this.minAmount && this.newAmount <= this.minAmount));
    },
    isOverLimit (): boolean {
      return this.maxAmount && this.newAmount > this.maxAmount;
    },
    isSaveDisabled (): boolean {
      return Boolean(this.isFormDisabled || this.addAmount === 0 || this.isOverLimit);
    },
    nextDate (): Date {
      return this.subscription && this.subscription.nextBillingDate;
    },
    additionalCharge (): number {
      if (this.addAmount > 0) {
        return this.addAmount * this.addItemCost;
      }
      return 0;
    },
    renewalPrice (): number {
      return this.subscription && parseFloat(this.subscription.nextBillingCost) + this.addAmount * this.itemCost;
    },
    searchResults (): Subscription[] {
      // @ts-ignore
      return this.applySearchFilters(this.filters, this.availableSubscriptions);
    },
    isReadOnly (): boolean {
      return this.packSize > 1;
    },
    modalWidth (): number|string {
      if (this.cbUrl) {
        return 14;
      } else if (this.contactToSales) {
        return 10;
      } else if (this.subscription) {
        return 16;
      }
      return 20;
    },
    hasSalesForceContract (): boolean {
      const subscription = this.subscriptions.find((s) => s.uuid === this.selectedUuid);
      return subscription?.salesForce?.hasContract || false;
    },
  },
  watch: {
    add (val) {
      this.$nextTick(() => {
        if (this.isFormDisabled) {
          this.add = '0';
        } else if (this.minAmount && this.newAmount < this.minAmount) {
          this.add = String(this.minValue);
        }
      });
    },
    selectedUuid (val) {
      if (!val && this.availableSubscriptions.length > 1) {
        this.subscription = null;
      }
    },
    predefinedUuid (val) {
      if (!val) {
        this.subscription = null;
      } else {
        this.loadSubscription(val);
      }
    },
    searchResults (val) {
      if (this.selectedUuid && !val.some((s) => s.uuid === this.selectedUuid)) {
        this.selectedUuid = null;
      }
    },
    availableSubscriptions (val) {
      if (val.length === 1 && !this.selectedUuid) {
        this.selectedUuid = val[0].uuid;
        this.loadSubscription(this.selectedUuid);
      }
    },
  },
});

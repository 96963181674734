/**
 * File: initRestorePurchasesRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { licenseService } from '@core/constants/links';

interface InitRestorePurchasesRequestParams {
  email?: string;
  licenseKey?: string;
}

interface InitRestorePurchasesRequestResponseData {
  email?: string;
}

export default class InitRestorePurchasesRequest extends ApiRequest<InitRestorePurchasesRequestParams, InitRestorePurchasesRequestResponseData> {
  get url () {
    if (this.params.email) {
      return '/account/webapp/restore_purchases';
    } else if (this.params.licenseKey) {
      return `${licenseService.RESTORE_PURCHASES}`;
    }
  }

  get method (): Method {
    return 'POST';
  }

  get body () {
    if (this.params.email) {
      return {
        email: this.params.email,
      };
    } else if (this.params.licenseKey) {
      return {
        lic_key: this.params.licenseKey,
      };
    }
  }
}

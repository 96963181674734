/**
 * File: csrSign.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Uploader from './uploader.vue';

export default {
  name: 'csr-sign',

  components: { Uploader },

  data () {
    return {
      uuid: null,
      fileName: '',
    };
  },

  methods: {
    onUploadSuccess (responseData, fileName) {
      this.init(fileName, responseData.uuid);
    },

    reset () {
      this.init('', null);
    },

    init (fileName, uuid) {
      this.fileName = fileName;
      this.uuid = uuid;
    },
  },

  computed: {
    link () {
      return `/pmm/webapp/csrs/${this.uuid}/signed`;
    },
  },
};

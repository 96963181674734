/**
 * File: getPlansRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class GetPlansRequest extends ApiRequest {
  get url () {
    const queryString = this.buildQueryString(this.params);
    return `/license/api/v1/plans${queryString}`;
  }

  getFirstPlan () {
    return this.data && this.data.service_plans[0];
  }
}

/**
 * File: pdUpdateHostRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PdComputersRequest from './pdComputersRequest';

export default class PdUpdateHostRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.hwId
   * @param {String} params.name
   * @param {?String} params.configurationProfileId
   * @param {?String} params.notes
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get url () {
    return `/desktop/api/v1/ba/hosts/${this.params.hwId}`;
  }

  get body () {
    const params = this.params;
    const data = {
      name: params.name,
    };
    if (this.params.hasOwnProperty('notes')) {
      data.notes = this.params.notes;
    }
    if (this.params.hasOwnProperty('configurationProfileId')) {
      data.configuration_profile_id = this.params.configurationProfileId;
    }
    return data;
  }

  getJson () {
    // No JSON content expected here
    return null;
  }

  load () {
    return super.load()
      .then((data) => {
        new PdComputersRequest({}).dropFullCache();
        return data;
      });
  }
}

/**
 * File: pmmServerUsageHistoryRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class PmmServerUsageHistoryRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.uuid
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get cacheNameSpace () {
    return 'pmmServerUsageHistoryRequest';
  }

  get url () {
    const params = this.params;
    return `/pmm/webapp/sites/${params.uuid}/usages`;
  }
}

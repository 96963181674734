
import Vue from 'vue';

import { PRODUCT_KEY_NAME_PDFC } from '@core/constants/subscriptions';

// CPCLOUD-14654
// import GetPdfcOnlinePurchaseInfoRequest from '@/api/getPdfcOnlinePurchaseInfoRequest';
// import purchaseInfo from '@/data/pdfcPurchaseInfo';
import GetRedeemCodesRequest from '@/api/getRedeemCodesRequest';
import GetPackagedLicensesRequest from '@/api/getPackagedLicensesRequest';
import oemLicensesStatistics from '@/data/oemLicensesStatistics';
import { PACKAGED_LICENSES_PAGE, REDEEM_CODES_PAGE } from '@/routes/constants';
import ProductCardMixIn from '../productCardMixIn';
import SelectOemLicensesTypeModal from './selectOemLicensesTypeModal.vue';
import CombinedApiRequest from '@core/api/combinedApiRequest';

export default Vue.extend({
  name: 'product-card-pdfc-purchase',

  mixins: [ProductCardMixIn],

  data () {
    return {
      SelectOemLicensesTypeModal,
      PRODUCT_KEY_NAME_PDFC,
      loading: false,
      oemLicensesStatistics,
      REDEEM_CODES_PAGE,
      PACKAGED_LICENSES_PAGE,
    };
  },

  created () {
    const request = new CombinedApiRequest({ integrity: false });
    request.addRequest('standaloneLicenses', new GetRedeemCodesRequest({ totalOnly: true }));
    request.addRequest('packagedLicenses', new GetPackagedLicensesRequest({ totalOnly: true }));

    this.loading = true;

    // The request sets data to shared "oemLicensesStatistics" object itself
    this.$api.authorizedCall(request).finally(() => {
      this.loading = false;
    });
  },
});


/**
 * File: browserListRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { Dictionary } from '@core/common/types';

import { Browser } from '@/models/trustedEntity';

interface BrowsersListResponseData {
  browsers: BrowserResponseData[];
}

export default class BrowserListRequest extends ApiRequest<Dictionary, BrowsersListResponseData> {
  get url (): string {
    return '/account/api/v1/browser/list';
  }

  getBrowsers (): Browser[] {
    if (this.data === null) {
      return null;
    }

    return this.data.browsers.map((entity) => new Browser(entity));
  }
}

/**
 * File: emailChange.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue, { PropType } from 'vue';
import RestorePasswordMixin from '@/modules/personalProfile/restorePasswordMixin';
import EmailChangeRequest from '@/api/emailChangeRequest';
import EmailChangeStatusRequest from '@/api/emailChangeStatusRequest';
import { email, password } from '@core/common/validators';
import Session from '@/models/session';

export default Vue.extend({
  name: 'email-change',

  mixins: [RestorePasswordMixin],

  props: {
    session: {
      type: Object as PropType<Session>,
    },
  },

  data () {
    return {
      password: '',
      newEmail: '',
      inProgress: false,
      step: 1,
    };
  },

  validations: {
    newEmail: Object.assign({}, email, {
      notEqual: (value, self) => value !== self.session.email,
    }),
    password,
  },

  methods: {
    confirm () {
      this.step = 1;
      this.inProgress = true;

      const request = new EmailChangeRequest({
        email: this.newEmail,
        password: this.password,
      });

      this.$api.authorizedCall(request).then(() => {
        this.step = 2;
      }).finally(() => {
        this.inProgress = false;
      });
    },

    resetForm () {
      this.step = 1;
      this.newEmail = '';
      this.password = '';
      this.$v.$reset();
    },

    cancel () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.hideModal();
    },

    show () {
      this.resetForm();
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.wizard.show();
    },

    finish () {
      const request = new EmailChangeStatusRequest();
      request.dropCache();

      this.inProgress = true;
      this.$api.authorizedCall(request).then((data) => {
        if (data.status === 2) {
          this.$toast.show({
            text: $t('To complete the procedure follow the instructions in the message that was sent to {email}', {
              email: data.new_email,
            }),
          });
          this.$emit('finish');
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
          this.hideModal();
        }
      }).finally(() => {
        this.inProgress = false;
      });
    },

  },

});

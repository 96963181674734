

import Vue from 'vue';
import GetSocialBindingsRequest from '@/api/getSocialBindingsRequest';
import RestorePurchasesModal from '@/modules/addKey/restorePurchasesModal.vue';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import SetHomePageDisplayState from '@/api/setHomePageDisplayState.js';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import Subscription, { STATUS_EXPIRED } from '@/models/subscription';
import LsSubscriptionsRequest from '@/api/lsSubscriptionsRequest';
import CompanyUsersRequest from '@/api/companyUsersRequest';
import { RawLocation } from 'vue-router';

import {
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFC_PURCHASE,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDFM_PRO,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PDPRO,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTB_CVT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_HDT,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_RAS
} from '@core/constants/subscriptions';
import {
  ADD_KEY_PAGE,
  DASHBOARD_PAGE,
  DOWNLOADS_PAGE,
  GENERAL_PAGE,
  GET_SUPPORT_PAGE,
  PERSONAL_PROFILE_SCOPE,
  RENEW_ONLINE_PAGE,
  SUBSCRIPTIONS_PAGE
} from '@/routes/constants';

const getProductName = function (keyName) {
  switch (keyName) {
    case PRODUCT_KEY_NAME_PDB:
    case PRODUCT_KEY_NAME_PDFC:
    case PRODUCT_KEY_NAME_PDFM:
    case PRODUCT_KEY_NAME_PDFM_PRO:
    case PRODUCT_KEY_NAME_PDPRO:
    case PRODUCT_KEY_NAME_PDL:
    case PRODUCT_KEY_NAME_PDFC_PURCHASE:
      return 'Parallels Desktop';
    case PRODUCT_KEY_NAME_PMM:
      return 'Parallels Device Management';
    case PRODUCT_KEY_NAME_PTB:
    case PRODUCT_KEY_NAME_PTBB:
    case PRODUCT_KEY_NAME_PTB_HDT:
    case PRODUCT_KEY_NAME_PTB_DNST:
    case PRODUCT_KEY_NAME_PTB_DVT:
    case PRODUCT_KEY_NAME_PTB_CVT:
      return 'Parallels Toolbox';
    case PRODUCT_KEY_NAME_RAS:
      return 'Remote Application Server';
  }
};

interface Button {
  text: string;
  color?: string;
  disabled?: boolean;
  route?: RawLocation;
  dataName?: string;
  dataQA: string;
  onClick?: ()=> void;
}

const customStoreProducts = [
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_RAS,
];

export default Vue.extend({
  name: 'home',

  components: { RestorePurchasesModal },

  data () {
    return {
      loading: false,
      socialBindings: [] as object[],
      switcherOptions: null,
      skipHomePage: false,
      expiredSubscriptions: [] as Subscription[],
    };
  },

  async mounted () {
    this.loading = true;
    this.skipHomePage = !this.$appData.session.showHomePage;
    const data = await this.$api.authorizedCall(new GetSocialBindingsRequest());
    this.socialBindings = data.social_bindings;

    this.expiredSubscriptions = await this.loadExpiredSubscriptions();
    this.loading = false;
  },

  computed: {
    canRenewSubscriptions (): boolean {
      return !this.isBusinessDomain || this.$appData.session.isBusinessAdmin;
    },

    extraButton (): Button {
      if (!this.isBusinessDomain && this.isDomainEmpty && this.socialBindings.length) {
        return {
          text: this.$t('Restore Purchases'),
          onClick: () => this.$modal.show('restorePurchasesPreamble'),
          dataQA: 'restore-purchases-button',
        };
      }

      if (this.expiredSubscriptions.length && this.canRenewSubscriptions) {
        let button;
        if (this.expiredSubscriptions.length > 1) {
          button = {
            text: this.$tc('{n} Subscriptions to Renew', this.expiredSubscriptions.length, { n: this.expiredSubscriptions.length }),
            route: { name: SUBSCRIPTIONS_PAGE, query: { display: STATUS_EXPIRED } },
          };
        } else {
          const subscription: Subscription = this.expiredSubscriptions[0];
          button = {
            // Actual string is "Renew {product}"
            text: this.$tc('{n} Subscriptions to Renew', 1, { product: getProductName(subscription.firstProductKeyName) }),
            dataQA: 'renew-button',
            route: {
              name: RENEW_ONLINE_PAGE,
              params: {
                id: subscription.uuid,
                byRef: true,
                canBeMigrated: !!subscription.canBeMigrated,
              },
            },
          };
        }

        return {
          ...button,
          color: 'blue',
        };
      } else {
        return null;
      }
    },

    mainButtons (): Button[] {
      if (this.isSsoEmployee) {
        return [
          {
            text: this.$t('Get Support'),
            dataQA: 'get-support-button',
            onClick: async () => {
              const admins = await this.loadAdmins();
              const text = this.$t('To get support, please contact the administrators of the business account: {admins}.', { admins: admins });
              this.$toast.show({ text });
            },
          },
          {
            text: this.$t('View My Parallels Products'),
            disabled: this.isDomainEmpty,
            route: { name: DASHBOARD_PAGE },
            dataName: this.$name('dashboard'),
            dataQA: 'view-my-parallels-products-button',
          },
          {
            text: this.$t('Download Product Files'),
            route: { name: DOWNLOADS_PAGE, query: { product: PRODUCT_KEY_NAME_PDFM } },
            dataQA: 'download-product-files-button',
          },
        ];
      } else {
        return [
          {
            text: this.$t('Try or Buy a Parallels Product'),
            onClick: () => (window.location.href = 'https://www.parallels.com/eu/products/'),
            dataQA: 'try-or-buy-parallels-product-button',
          },
          {
            text: this.$t('Register a License Key'),
            onClick: () => this.isDomainEmpty ?
              this.$router.push({ name: ADD_KEY_PAGE }) :
              this.$modal.show('addKeyModal'),
            dataName: this.$name('register-key'),
            dataQA: 'register-license-key-button',
          },
          {
            text: this.$t('Get Support'),
            dataQA: 'get-support-button',
            onClick: () => {
              this.$trackEvent({
                category: 'Support',
                name: 'Get Support click on home page',
              });
              this.$router.push({ name: GET_SUPPORT_PAGE });
            },
          },
          {
            text: this.$t('View My Parallels Products'),
            disabled: this.isDomainEmpty,
            route: { name: DASHBOARD_PAGE },
            dataName: this.$name('dashboard'),
            dataQA: 'view-my-parallels-products-button',
          },
          {
            text: this.$t('Download Product Files'),
            route: { name: DOWNLOADS_PAGE, query: { product: PRODUCT_KEY_NAME_PDFM } },
            dataQA: 'download-product-files-button',
          },
          {
            text: this.$t('Change My Account Settings'),
            route: { name: PERSONAL_PROFILE_SCOPE, params: { page: GENERAL_PAGE } },
            dataName: this.$name('account-settings'),
            dataQA: 'change-my-account-settings-button',
          },
        ];
      }
    },

    isBusinessDomain (): boolean {
      return this.$appData.session.isBusinessUser(this.$appData.userInDomain);
    },

    isDomainEmpty (): boolean {
      return !this.$appData.products[this.isBusinessDomain ? 'business' : 'personal'].length && !this.$appData.session.isPdfcPurchaseFeatureAccessible;
    },

    isUserNew (): boolean {
      return this.$appData.isNewUser;
    },

    isSsoEmployee (): boolean {
      return this.$appData.session.isSsoEmployee;
    },
  },

  methods: {
    onButtonClick (button: Button): void {
      if (button.onClick) {
        button.onClick();
      } else if (button.route) {
        this.$router.push(button.route);
      }
    },

    async loadExpiredSubscriptions (): Promise<Subscription[]> {
      let subscriptions = [];
      const requestType = this.isBusinessDomain ? SubscriptionsRequest.TYPE_BUSINESS : SubscriptionsRequest.TYPE_PERSONAL;
      const combinedRequest = new CombinedApiRequest({ integrity: false });
      combinedRequest.addRequest('ls_subscriptions', new SubscriptionsRequest({ type: requestType }));

      await this.$api.authorizedCall(combinedRequest);

      const lsSubscriptionsRequest = combinedRequest.getRequest('ls_subscriptions') as any as LsSubscriptionsRequest;
      subscriptions = lsSubscriptionsRequest.getSubscriptions().filter((subscription) => !subscription.isHidden);

      return subscriptions.filter((s: Subscription) => s.isExpiringOrExpired && !customStoreProducts.includes(s.firstProductKeyName) && !s.isCustomStore);
    },

    async loadAdmins (): Promise<string> {
      this.loading = true;
      let adminsString = '';
      const request = new CompanyUsersRequest({}, this.$appData.session.businessDomainId);
      await this.$api.authorizedCall(request);
      const users = request.getUsers();
      if (users) {
        adminsString = users.filter((user) => user.isAdmin).slice(0, 3).map(item => item.email).join(', ');
      }
      this.loading = false;
      return adminsString;
    },
  },

  watch: {
    '$appData.userInDomain': async function () {
      this.loading = true;
      this.expiredSubscriptions = await this.loadExpiredSubscriptions();
      this.loading = false;
    },

    skipHomePage (state) {
      const request = new SetHomePageDisplayState({ state: !state });
      this.$api.authorizedCall(request);
    },
  },
});


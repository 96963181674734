/**
 * File: browsersDeleteRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class BrowsersDeleteRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get body () {
    return { identities: this.params.ids };
  }

  get url () {
    return '/account/api/v1/browser/remove_multiple';
  }
}

/**
 * File: restorePasswordMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/
import Vue from 'vue';

import RestorePasswordRequest from '@/api/restorePasswordRequest';

export default Vue.extend({
  methods: {
    restorePassword () {
      const email = this.$appData.session.email;
      const request = new RestorePasswordRequest({ email });

      this.loading = true;
      this.$api.call(request).then(() => {
        this.$toast.show({
          text: this.$t('The instructions on how to reset your password have been sent to {email}', { email }),
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
});

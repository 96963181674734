
import Vue from 'vue';

import StatusMixin from '../statusMixin';
import PaymentOptionsLink from '@/modules/subscriptions/paymentOptions/paymentOptionsLink.vue';
import { CB_TRANSACTION_STATES } from '@core/constants/subscriptions';
import Subscription from '@/models/subscription';

export default Vue.extend({
  name: 'subscription-status-renew-failed',
  mixins: [StatusMixin],
  components: { PaymentOptionsLink },
  data: () => ({
    cbState: CB_TRANSACTION_STATES,
  }),
  computed: {
    isShowRenewButton (): boolean {
      return this.canEditSubscription && (this.subscription as Subscription)?.transactionState === this.cbState.renew.refund;
    },
  },
});

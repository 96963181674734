/**
 * File: oemLicensesStatistics.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

export default {
  totalRedeemCodes: 0,
  totalStandaloneLicenses: 0,
  totalPacks: 0,
  totalPackagedLicenses: 0,
};


import Vue from 'vue';

export default Vue.extend({
  name: 'confirm-redirect-modal',

  props: {
    modalName: {
      type: String,
    },
    size: {
      type: [String, Number],
      default: 'auto',
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    cancelButton: {
      type: Boolean,
      default: true,
    },
  },

  data (): { url: string } {
    return {
      url: '',
    };
  },

  methods: {
    show (url) {
      this.url = url;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
    },

    cancelHandler () {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },
  },
});


/**
 * File: pdHost.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';
import {
  HOST_STATUS_ACTIVE,
  HOST_STATUS_DEACTIVATED,
  HOST_STATUS_BLACKLISTED
} from '@core/constants/subscriptions';
import {
  STATUS_EXPIRED,
  STATUS_IS_EXPIRING_SOON
} from '@/models/subscription';

interface ConfigurationProfile {
  id: number;
}

export default class PdHost extends CoreModel {
  hwId: string;
  buildNumber: string;
  osName: string;
  userName: string;
  licKey: string;
  serialNumber: string;
  macAddresses: string;
  ipAddresses: string;
  ip6Addresses: string;
  customName: string;
  hostName?: string;
  __notes?: string;
  __activated: boolean;
  lastConnectionAt: Date;
  activatedAt: Date;
  updatedAt: Date;
  subscription?: { isExpired: boolean; isExpiringSoon: boolean; isSubset: boolean; uuid: string };
  __configurationProfile?: ConfigurationProfile;
  generalConfigurationProfile?: ConfigurationProfile;
  blocked: boolean;
  subscriptionUuid?: string;

  set name (value: string) {
    this.customName = value;
  }

  get name (): string {
    return this.customName || this.hostName;
  }

  set notes (value: string) {
    this.__notes = value;
  }

  get notes (): string {
    return this.__notes;
  }

  set activated (value: boolean) {
    this.__activated = value;
  }

  get activated (): boolean {
    return this.__activated;
  }

  get lastConnectionDate (): Date {
    return this.lastConnectionAt;
  }

  get activatedDate (): Date {
    return this.activatedAt;
  }

  get updatedDate (): Date {
    return this.updatedAt;
  }

  get isExpired (): boolean {
    return this.subscription && this.subscription.isExpired;
  }

  get isExpiringSoon (): boolean {
    return this.subscription && this.subscription.isExpiringSoon;
  }

  get isSubset (): boolean {
    return this.subscription && this.subscription.isSubset;
  }

  get configurationProfile (): ConfigurationProfile {
    return this.__configurationProfile;
  }

  set configurationProfile (value: ConfigurationProfile) {
    this.__configurationProfile = value;
    if (!this.generalConfigurationProfile) {
      this.generalConfigurationProfile = value;
    }
  }

  get generalConfigurationProfileId (): number {
    return this.generalConfigurationProfile && this.generalConfigurationProfile.id;
  }

  get status (): string {
    if (this.blocked) {
      return HOST_STATUS_BLACKLISTED;
    }
    return this.activated ? HOST_STATUS_ACTIVE : HOST_STATUS_DEACTIVATED;
  }

  get subscriptionExpirationStatus (): string {
    if (!this.subscription) {
      return;
    }
    if (this.subscription.isExpired) {
      return STATUS_EXPIRED;
    }
    if (this.subscription.isExpiringSoon) {
      return STATUS_IS_EXPIRING_SOON;
    }
  }
};

/**
 * File: rasHost.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';
import {
  HOST_STATUS_ACTIVE,
  HOST_STATUS_INACTIVE,
  HOST_STATUS_DEACTIVATED,
  HOST_STATUS_EXPIRED,
  HOST_STATUS_ACTIVE_OFFLINE
} from '@core/constants/subscriptions';
import RasSubscription from '@/models/rasSubscription';

export default class RasHost extends CoreModel {
  hwId: string;
  private __notes: string;
  subscription: RasSubscription;
  activatedOffline: boolean;
  usedAsPrepaid: boolean;
  usedAsPostpaid: boolean;
  historySubscription: RasSubscription;
  private __usedUniversalKey: string;
  private __universalKey: string;
  private __farmGuid: string;
  private __name: string;
  activatedAt: Date;
  lastReportedAt: Date;
  postpaidLastReportedAt: Date;
  prepaidLastReportedAt: Date;

  get farmGuid (): string {
    return (this.__farmGuid || '').toLowerCase();
  }

  get name (): string {
    return this.__name || 'Untitled host';
  }

  set name (value: string) {
    this.__name = value;
  }

  get activationDate (): Date {
    return this.activatedAt;
  }

  get lastReportDate (): Date {
    return this.lastReportedAt;
  }

  get postpaidLastReportDate (): Date {
    return this.postpaidLastReportedAt || this.lastReportedAt;
  }

  get prepaidLastReportDate (): Date {
    return this.prepaidLastReportedAt;
  }

  get universalKey (): string {
    return this.__universalKey || (this.subscription && this.subscription.licKey);
  }

  get usedUniversalKey (): string {
    return this.__usedUniversalKey || (this.historySubscription && this.historySubscription.licKey);
  }

  get universalKeyPostpaid (): string {
    return this.subscriptionIsPostpaid ? this.universalKey : null;
  }

  get usedUniversalKeyPostpaid (): string {
    return this.usedSubscriptionIsPostpaid ? this.usedUniversalKey : null;
  }

  get universalKeyPrepaid (): string {
    return this.subscriptionIsPrepaid ? this.universalKey : null;
  }

  get subscriptionIsPostpaid (): boolean {
    return !!(this.subscription && this.subscription.isPostpaid);
  }

  get subscriptionIsPrepaid (): boolean {
    return !!(this.subscription && this.subscription.isPrepaid);
  }

  get usedSubscriptionIsPostpaid (): boolean {
    return !!(this.historySubscription && this.historySubscription.isPostpaid);
  }

  get isPostpaid (): boolean {
    return this.subscriptionIsPostpaid || this.usedAsPostpaid;
  }

  get isPrepaid (): boolean {
    return this.subscriptionIsPrepaid || this.usedAsPrepaid;
  }

  get active (): boolean {
    return !!this.subscription && !this.subscription.isExpired;
  }

  get activePrepaid (): boolean {
    return this.active && this.subscriptionIsPrepaid;
  }

  get activePostpaid (): boolean {
    return this.active && this.subscriptionIsPostpaid;
  }

  get statusNamePrepaid (): string {
    if (this.subscription && this.subscription.isExpired) {
      return HOST_STATUS_EXPIRED;
    }

    if (this.activePrepaid) {
      return this.activatedOffline ? HOST_STATUS_ACTIVE_OFFLINE : HOST_STATUS_ACTIVE;
    } else {
      return HOST_STATUS_DEACTIVATED;
    }
  }

  get statusNamePostpaid (): string {
    if (this.subscription && this.subscription.isExpired) {
      return HOST_STATUS_EXPIRED;
    }
    return this.activePostpaid ? HOST_STATUS_ACTIVE : HOST_STATUS_INACTIVE;
  }

  get notes (): string {
    return this.__notes || '';
  }

  set notes (value: string) {
    this.__notes = value;
  }
};

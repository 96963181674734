/**
 * File: lsSubscriptionsRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import BaseSubscriptionsRequest from './baseSubscriptionsRequest';

export default class LsSubscriptionsRequest extends BaseSubscriptionsRequest {
  protected _url = '/license/api/v1/subscriptions';
}

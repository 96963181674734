/**
 * File: offlineValidate.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import uploader from './uploader.vue';
import {
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PMM_MDM,
  PRODUCT_NAME_PMM,
  PRODUCT_NAME_PMM_MDM
} from '@core/constants/subscriptions';

const PRODUCTS_NAMES = {
  [PRODUCT_KEY_NAME_PMM]: PRODUCT_NAME_PMM,
  [PRODUCT_KEY_NAME_PMM_MDM]: PRODUCT_NAME_PMM_MDM,
};

export default {
  name: 'offline-validate',

  components: { uploader },

  data () {
    return {
      state: 'default',
      fileName: '',
      error: {},
    };
  },

  methods: {
    onUploadSuccess (responseData, fileName) {
      this.init(fileName, responseData.type_id);
    },

    onUploadError (error) {
      this.error = error;
    },

    reset () {
      this.init('', 'default');
    },

    init (fileName, state) {
      this.error = {};
      this.fileName = fileName;
      this.state = state;
    },

    getPossibleErrors () {
      return {
        common: {
          'Invalid file': 'The offline file is not valid. Please try to generate another file on your Parallels Device Management Proxy Server and repeat. If the problem persists, {link}contact{/link} Parallels Support.',
          'Universal key does not exist': 'The license key specified in the offline file does not exist. Make sure you’re using a valid license key for activating the Parallels Device Management Proxy Server. If the problem persists, {link}contact{/link} Parallels Support.',
          'Subscription not owned': 'The license key specified in the offline file belongs to a different Parallels account. Make sure you’re using a valid Parallels business account. Should you have any questions or need an assistance, {link}contact{/link} Parallels Support.',
          'Invalid universal key': 'The license key specified in the offline file is expired and cannot be used for activation. Make sure you’re using a valid license key for activating the Parallels Device Management Proxy Server. If the problem persists, {link}contact{/link} Parallels Support.',
          'Offline mode disabled': 'Offline operation denied. The license key specified in the offline request file does not have a permission for offline use. Please contact your Parallels sales representative to enable offline operations. {link}Contact{/link} Parallels Support should you have any questions or need an assistance.',
          'Already processed': 'This Parallels Device Management offline request file has already been processed.',
        },
        activate: {
          'Universal key already used': 'The license key specified in the offline file was already used for activation of a different instance of Parallels Device Management Proxy Server. Deactivate the existing server which uses the key, then generate another offline file. Should you have any questions or need an assistance, {link}contact{/link} Parallels Support.',
          'Site already activated': 'The Parallels Device Management Proxy Server specified in the offline file has already been activated and registered.',
          'Incorrect product': 'The license key for {actual_product} specified in the offline file cannot be used for activating {requested_product}. Make sure you are using a valid license key for {requested_product}. If the problem persists, {link}contact{/link} Parallels Support.',
        },
        deactivate: {
          'Site not found': 'The Parallels Device Management Proxy Server specified in the offline file could not be found. Make sure you’re using a valid Parallels business account and the Parallels Device Management Proxy Server is registered in it. Should you have any questions or need an assistance, {link}contact{/link} Parallels Support.',
          'Site is not activated': 'The Parallels Device Management Proxy Server specified in the offline file does not appear to be activated. No deactivation is required. Should you have any questions or need an assistance, {link}contact{/link} Parallels Support.',
          Success: 'The Parallels Device Management Proxy Server specified in the offline file has been successfully deactivated.',
        },
        usage: {
          'Site not found': 'The Parallels Device Management Proxy Server specified in the offline file could not be found. Make sure you’re using a valid Parallels business account and the Parallels Device Management Proxy Server is registered in it. Should you have any questions or need an assistance, {link}contact{/link} Parallels Support.',
          'Site is not activated': 'The Parallels Device Management Proxy Server specified in the offline file does not appear to be activated. You must activate it first. Should you have any questions or need an assistance, {link}contact{/link} Parallels Support.',
          'License overused': undefined, // no message needed in this case
        },
      };
    },
  },

  computed: {
    requestedProduct () {
      return PRODUCTS_NAMES[this.error.requested_product];
    },

    actualProduct () {
      return PRODUCTS_NAMES[this.error.actual_product];
    },

    statusText () {
      const possibleErrors = this.getPossibleErrors();
      const typeError = possibleErrors[this.error.type_id] && possibleErrors[this.error.type_id][this.error.msg];

      return possibleErrors.common[this.error.msg] || typeError || '';
    },
  },
};

/**
 * File: subscriptionsContent.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';

import { sortByDate } from '@core/common/sorters';
import ComponentMixIn from '@/modules/componentMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import { getSubscriptionLinkParams, textFilter } from '../helpers';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import UniversalKeysRequest from '@/api/universalKeysRequest';
import FilterDropdownMy from '@/ui/filterDropdown/index.vue';
import SubscriptionsList from './subscriptionsList/subscriptionsList.vue';
import Subscription, {
  STATUS_ACTIVE,
  STATUS_EXPIRED,
  STATUS_IS_EXPIRING_SOON,
  TYPE_PERPETUAL,
  TYPE_SPLA,
  TYPE_SUBSCRIPTION
} from '@/models/subscription';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

const cookie = require('js-cookie');
const MAX_DATE = new Date('2050-01-01');
const MIN_DATE = new Date('2000-01-01');

export enum SUBSCRIPTIONS_SORT_OPTION {
  REGISTRATION_DESC = 'registrationDesc',
  REGISTRATION_ASC = 'registrationAsc',
  EXPIRATION_DESC = 'expirationDesc',
  EXPIRATION_ASC = 'expirationAsc'
}

export default Vue.extend({
  name: 'subscriptions-content',

  mixins: [ComponentMixIn, SearchFilterMixin, BreadcrumbsMixin],

  components: { FilterDropdownMy, SubscriptionsList },

  props: {
    /*
    Indicates which type of subscriptions should be displayed. Personal or Business.
     */
    personalMode: {
      type: Boolean,
      required: false,
    },

    /*
    Indicates the product context. This means that only this product subscriptions must be display.
     */
    contextProduct: {
      type: String,
    },
  },

  data () {
    return {
      subscriptions: [] as Subscription[],
      filters: {},
      sortOptions: [
        { value: SUBSCRIPTIONS_SORT_OPTION.REGISTRATION_DESC, text: this.$t('Registration Date {i}', { i: '↓' }) },
        { value: SUBSCRIPTIONS_SORT_OPTION.REGISTRATION_ASC, text: this.$t('Registration Date {i}', { i: '↑' }) },
        { value: SUBSCRIPTIONS_SORT_OPTION.EXPIRATION_DESC, text: this.$t('End Date {i}', { i: '↓' }) },
        { value: SUBSCRIPTIONS_SORT_OPTION.EXPIRATION_ASC, text: this.$t('End Date {i}', { i: '↑' }) },
      ],
      displayModeOptions: [{
        text: this.$t('Active ({count})'),
        value: STATUS_ACTIVE,
        filter: (entry: Subscription) => { return !entry.isExpired; },
      }, {
        text: this.$t('Expiring Soon ({count})'),
        value: STATUS_IS_EXPIRING_SOON,
        filter: (entry: Subscription) => { return entry.isExpiringSoon; },
      }, {
        text: this.$t('Expired ({count})'),
        value: STATUS_EXPIRED,
        filter: (entry: Subscription) => { return entry.isExpired; },
      }],
      displayTypeOptions: [{
        text: this.$t('Perpetual'),
        value: TYPE_PERPETUAL,
        filter: (entry: Subscription) => { return entry.isPermanent; },
      }, {
        text: this.$t('SPLA'),
        value: TYPE_SPLA,
        filter: (entry: Subscription) => { return entry.isPostPaid; },
      }, {
        text: this.$t('Subscription'),
        value: TYPE_SUBSCRIPTION,
        filter: (entry: Subscription) => { return !entry.isPostPaid && !entry.isPermanent; },
      }],
      sortOrder: cookie.get('subsSortOrder') || SUBSCRIPTIONS_SORT_OPTION.EXPIRATION_ASC,
      textFilter,
    };
  },

  methods: {
    async load (isSkipRedirect?: boolean) {
      this.loading = true;
      try {
        const requestType = this.personalMode ? SubscriptionsRequest.TYPE_PERSONAL : SubscriptionsRequest.TYPE_BUSINESS;
        const request = new SubscriptionsRequest({ type: requestType });

        this.subscriptions = [];

        await this.authorizedCall(request, 'subscriptionsLoading');
        let subscriptions;

        if (this.contextProduct) {
          subscriptions = request.getSubscriptions().filter((s) => {
            return s.hasProduct(this.contextProduct);
          });
        } else {
          subscriptions = request.getSubscriptions();
        }

        await this.loadUniversalKeys(subscriptions);
        this.subscriptions = subscriptions.filter((subscription) => !subscription.isHidden);
        if (subscriptions.length === 1 && !isSkipRedirect) {
          const linkParams = getSubscriptionLinkParams(this.subscriptions[0], this.contextProduct, this.$route.params.utility);
          this.$router.push(linkParams);
        }
      } finally {
        this.loading = false;
      }
    },

    loadUniversalKeys (subscriptions: Subscription[] = []): Promise<void> {
      // for better performance load lazily and step by step
      const universalKeysRequest = new UniversalKeysRequest({ business: !this.personalMode });
      universalKeysRequest.emitter.on('itemFetched', (rq) => {
        universalKeysRequest.getRequest(rq).getUniversalKeys().forEach((uk) => {
          const subscription = subscriptions.find((s) => uk.subscriptionUuid === s.uuid);
          if (subscription) {
            uk.subscription = subscription;
            subscription.addUniversalKey(uk);
          }
        });
      });

      return this.$api.authorizedCall(universalKeysRequest);
    },

    sortData (data: Subscription[]): Subscription[] {
      const order = this.sortOrder;

      if (order === SUBSCRIPTIONS_SORT_OPTION.EXPIRATION_ASC) {
        data.sort((a, b) => sortByDate(a.expirationDate || MAX_DATE, b.expirationDate || MAX_DATE, true));
      }
      if (order === SUBSCRIPTIONS_SORT_OPTION.EXPIRATION_DESC) {
        data.sort((a, b) => sortByDate(a.expirationDate || MAX_DATE, b.expirationDate || MAX_DATE, false));
      }
      if (order === SUBSCRIPTIONS_SORT_OPTION.REGISTRATION_ASC) {
        data.sort((a, b) => sortByDate(a.activationDate || MIN_DATE, b.activationDate || MIN_DATE, true));
      }
      if (order === SUBSCRIPTIONS_SORT_OPTION.REGISTRATION_DESC) {
        data.sort((a, b) => sortByDate(a.activationDate || MIN_DATE, b.activationDate || MIN_DATE, false));
      }
      return data;
    },
  },

  computed: {
    searchResults (): Subscription[] {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      const filteredSubscriptions = this.sortData(this.applySearchFilters(this.filters, this.subscriptions));
      this.$emit('filteredSubscriptions', filteredSubscriptions);
      return filteredSubscriptions;
    },
  },

  watch: {
    sortOrder: function (value) {
      cookie.set('subsSortOrder', value, { expires: 365 });
    },
  },

  mounted () {
    const isNavigatedFromSubnavigation = this.$route.query?.source === SUBNAVIGATION_SOURCE;
    this.load(isNavigatedFromSubnavigation);
  },
});

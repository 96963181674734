/**
 * File: generateRenewLinkRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class GenerateRenewLinkRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.uuid
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/gen_renew_link`);
  }

  get body () {
    return {
      option_uuid: this.params.option_uuid,
      quantity: this.params.quantity,
    };
  }
}

/**
 * File: getRescueCodesRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface GetRescueCodesResponseData {
  codes: string[];
}

interface GetRescueCodesRequestParams {
  code: string;
}

export default class GetRescueCodesRequest extends ApiRequest<GetRescueCodesRequestParams, GetRescueCodesResponseData> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/rescue_codes/${this.params.code}`;
  }

  get method (): Method {
    return 'POST';
  }

  getRescueCodes (): string[] {
    return this.data.codes;
  }
}

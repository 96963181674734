/**
 * File: chatClientProvider.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

export enum ChatStatus {
  Connecting,
  WaitingForAgent,
  Active,
  Unknown,
  Locked,
  Canceled,
  Disconnected,
  Error,
  CreatingTicket
}

export enum MessageType {
  AgentInput = 0,
  UserInput = 2
}

export interface ChatClientProvider {
  name: string;
  disconnect: ()=> void;
  send: (message: string)=> any;
}

/**
 * File: rtSupportFormRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtSupportFormRequest extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.product
   * @param {String} params.type
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  /**
   * @returns {String}
   */
  get uri () {
    return `/ticket/get_cs_fields?product_key=${this.params.product}&support_type=${this.params.type}&language=${this.params.language}`;
  }

  get method () {
    return 'GET';
  }
}

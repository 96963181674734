

import Vue from 'vue';
import ResourceUsageNotificationGetRequest from '@/api/resourceUsageNotificationGetRequest';
import ResourceUsageNotificationUpdateRequest, {
  ResourceUsageNotificationUpdateRequestParams,
  ScheduleRule,
  ScheduleRuleType,
  ThresholdRule,
  ThresholdRuleType
} from '@/api/resourceUsageNotificationUpdateRequest';
import { WeekDay } from '@core/common/datetime';
import { LANGUAGES } from '@core/constants/langs';
import { sleep, toNumeral } from '@core/common/utils';
import EmailList from '@/ui/email-list/index.vue';
import ScheduleRuleEditor, { LAST_DAY_OF_MONTH } from './scheduleRule.vue';
import ThresholdRuleEditor from './thresholdRule.vue';
import ResourceUsageNotificationDeactivateRequest from '@/api/resourceUsageNotificationDeactivateRequest';
import { HTTP_CODES } from '@core/constants/http';

export default Vue.extend({
  name: 'notifications-tab',

  components: {
    EmailList,
    ScheduleRuleEditor,
    ThresholdRuleEditor,
  },

  props: {
    subscriptionUuid: {
      type: String,
    },
    restrictAmount: {
      type: Boolean,
      required: true,
    },
  },

  validations: {
    form: {
      threshold: {
        value: {
          positive (value): boolean {
            return this.form.threshold.type !== ThresholdRuleType.Users || value > 0;
          },
        },
      },
    },
  },

  data () {
    return {
      ThresholdRuleType,
      loading: false,
      exists: false,
      form: {
        isActive: false,
        threshold: {
          type: ThresholdRuleType.Any,
          value: null,
        } as ThresholdRule,
        schedule: {
          type: ScheduleRuleType.Daily,
          value: null,
        } as ScheduleRule,
        locale: this.$appData.session.isoLocale as string,
        sendSooner: false,
        emails: {
          valid: false,
          list: [] as string[],
        },
      },
      languageOptions: LANGUAGES,
      displayEmptyError: false,
    };
  },

  created () {
    this.$bus.$on('subsetSaved', this.save);
    this.load();
  },

  destroyed () {
    this.$bus.$off('subsetSaved', this.save);
  },

  computed: {
    scheduleStr (): string {
      const value = this.form.schedule.value;
      switch (this.form.schedule.type) {
        case ScheduleRuleType.Daily:
          return this.$t('Daily');
        case ScheduleRuleType.Weekly:
          return this.$t('Weekly, on {weekDay}', { weekDay: this.$t(WeekDay[value]) });
        case ScheduleRuleType.Monthly:
          if (value === LAST_DAY_OF_MONTH) {
            return this.$t('Last day of a month');
          } else {
            return this.$t('Monthly, on the {dayInMonth} day', { dayInMonth: toNumeral(value, this.$appData.session.language) });
          }
        case ScheduleRuleType.EndOfPeriod:
          return this.$t('At the end of the billing period');
        default:
          return '';
      }
    },
    thresholdStr (): string {
      const value = this.form.threshold.value;
      switch (this.form.threshold.type) {
        case ThresholdRuleType.Any:
          return this.$t('Any');
        case ThresholdRuleType.Users:
          return this.$tc('>= {count} users', value, { count: value });
        case ThresholdRuleType.Percent:
          return this.$t('>= {percent}% of threshold', { percent: value });
        default:
          return '';
      }
    },
  },

  methods: {
    reset () {
      this.exists = false;
      this.form = {
        isActive: false,
        threshold: {
          type: ThresholdRuleType.Any,
          value: null,
        } as ThresholdRule,
        schedule: {
          type: ScheduleRuleType.Daily,
          value: null,
        } as ScheduleRule,
        locale: this.$appData.session.isoLocale,
        sendSooner: false,
        emails: {
          valid: false,
          list: [],
        },
      };
      this.displayEmptyError = false;
    },
    async load () {
      if (!this.subscriptionUuid) {
        this.reset();
        return;
      }

      const request = new ResourceUsageNotificationGetRequest({ subscriptionUuid: this.subscriptionUuid });

      this.loading = true;

      try {
        const data = await this.$api.authorizedCall(request);
        if (data) {
          this.exists = true;
          this.form = {
            isActive: data.is_active,
            schedule: data.schedule,
            threshold: data.threshold,
            locale: data.locale,
            sendSooner: data.send_sooner,
            emails: {
              valid: true,
              list: data.receivers,
            },
          };
        }
      } finally {
        this.loading = false;
      }
    },
    async save ({ uuid }) {
      this.displayEmptyError = true;
      if (this.form.isActive) {
        this.$v.$touch();
        // @ts-ignore
        this.$refs.emailList.$v.$touch();

        if (this.$v.$invalid || !this.form.emails.valid) {
          this.$emit('save', Promise.reject(new Error()));
          return;
        }
      }

      const params: ResourceUsageNotificationUpdateRequestParams = { subscriptionUuid: uuid };
      if (this.form.isActive) {
        params.isActive = true;
        params.locale = this.form.locale;
        params.schedule = this.form.schedule;
        params.threshold = this.form.threshold;
        params.receivers = this.form.emails.list;
        params.sendSooner = this.form.sendSooner;
      }
      // todo: move all XHR to parent component (CPCLOUD-17594)
      const promise = this.$api.authorizedCall(
        this.form.isActive ? new ResourceUsageNotificationUpdateRequest(params) : new ResourceUsageNotificationDeactivateRequest(params)
      )
        .catch(async (e) => {
          if (e.response.status === HTTP_CODES.FORBIDDEN) {
            // wait for subscription to appear on RAS backend
            await sleep(1000);
            return this.save({ uuid });
          } else {
            throw e;
          }
        });
      this.$emit('save', promise);
      await promise;
    },
  },

  watch: {
    subscriptionUuid () {
      this.load();
    },
    restrictAmount (value: boolean) {
      if (!value && this.form.threshold.type === ThresholdRuleType.Percent) {
        this.form.threshold.type = ThresholdRuleType.Any;
        this.form.threshold.value = null;
      }
    },
  },
});


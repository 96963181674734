/**
 * File: trustedEntity.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';

export const BROWSER_ENTITY_ID = 'browser';
export const SESSION_ACTIVE = 'active';
export const SESSION_NOT_ACTIVE = 'not_active';
export const SESSION_CURRENT = 'current';
export const OS_TYPE_MAC = 'macOS';

class Entity extends CoreModel {
  id?: string;
  name?: string;
  model?: string;
  osType?: string;
  createdDate?: string;
  updatedDate?: string;
  current?: boolean;
  alive?: boolean;
  trusted?: boolean;
  ipAddress?: string;
  location?: string;

  get fullName (): string {
    return null;
  }

  get typeId (): string {
    return null;
  }

  get lastAccess (): string {
    return this.updatedDate;
  }

  get session (): string {
    if (this.current) {
      return SESSION_CURRENT;
    } else if (this.alive) {
      return SESSION_ACTIVE;
    }
    return SESSION_NOT_ACTIVE;
  }

  get operatingSystemType (): string {
    if (this.osType) {
      if (this.osType.startsWith(OS_TYPE_MAC)) {
        return OS_TYPE_MAC;
      }
    }
    return this.osType;
  }
}

export class Browser extends Entity {
  routerData?: string;

  get fullName (): string {
    return [this.name, this.operatingSystemType].join(', ');
  }

  get typeId (): string {
    return BROWSER_ENTITY_ID;
  }

  get parsedRouterData () {
    if (this.routerData) {
      return JSON.parse(this.routerData);
    }
    return undefined;
  }
}

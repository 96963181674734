/**
 * File: locale.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Dictionary } from '@core/common/types';
import {
  LANGUAGES,
  PLURALIZATION_RULES
} from '@core/constants/langs';
import { parseQueryString } from '@core/common/url';
import DEFAULT_DICTIONARY from '@/locales/en_US.json';

const LANG_PARAM_ALIAS = 'l';
const LANG_VALUE_DELIMITER = '_';
const LANG_RTL_ZH = 'zh';
const DEFAULT_LOCALE = 'en_US';

export const LANGUAGES_DICT = LANGUAGES.reduce((result: Dictionary, lang): Dictionary => {
  const { value } = lang;

  result[value] = lang;

  return result;
}, {});

export const LANGUAGES_LIST = Object.keys(LANGUAGES_DICT);

export function getProperLocale (locale: string): string {
  const parts: Array<string> = locale.split(LANG_VALUE_DELIMITER);
  let fixed: string = parts[0].toLowerCase();

  if (parts[1]) {
    fixed += `${LANG_VALUE_DELIMITER}${parts[1].toUpperCase()}`;
  }

  return fixed;
}

export function getLocaleFromUrl (paramAlias: string = LANG_PARAM_ALIAS): string {
  const url = parseQueryString(location.search);
  let code = url[paramAlias] || '';

  // Normalize location code
  code = getProperLocale(code);

  // Check if given locale code exists
  if (!checkIfLocaleExists(code)) {
    return '';
  }

  return code;
}

export function checkIfLocaleExists (locale: string): boolean {
  return LANGUAGES_DICT[locale] !== undefined;
}

export function fixSlotsForPluralization (slots: string[]): Dictionary {
  const obj = {};

  slots.reduce((obj, item) => {
    obj[item] = `{${item}}`;

    return obj;
  }, obj);

  return obj;
}

export class LocaleProps {
  constructor (locale, i18n) {
    this.i18n = i18n;
    this.code = locale;
    this.default = locale;
  }

  __code: string;
  default: string;
  i18n: VueI18n;

  get code (): string {
    return this.__code;
  }

  set code (newLocale: string) {
    if (!newLocale) {
      this.__code = this.default;
    } else {
      this.__code = newLocale;
    }
  }

  get language (): string {
    return this.code.split(LANG_VALUE_DELIMITER)[0];
  }

  get rtLanguage (): string {
    const parts: Array<string> = this.code.split(LANG_VALUE_DELIMITER);
    let language: string = parts[0];

    if (language === LANG_RTL_ZH) {
      language = parts[1].toLowerCase();
    }

    return language;
  }

  loadLanguage (locale): Promise<Dictionary<string>> {
    return import(/* webpackChunkName: "[name]" */ `@/locales/${locale}`);
  }
}

VueI18n.prototype.getChoiceIndex = function (n: number): number {
  const locale: string = getProperLocale(this.locale);
  const choice: Function = PLURALIZATION_RULES[locale];

  return choice !== undefined ? Number(choice(n)) : 0;
};

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  messages: {
    [DEFAULT_LOCALE]: DEFAULT_DICTIONARY,
  },
  silentTranslationWarn: true,
  formatFallbackMessages: true,
});

const localeProps = new LocaleProps(DEFAULT_LOCALE, i18n);

export default localeProps;

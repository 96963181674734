/**
 * File: appData.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

export const appData: AppData = {
  config: null,
  isLoggedIn: false,
  isNewUser: false,
  userInDomain: null,
  session: null,
  products: null,
  rtToken: null,
  rtSearchEngine: null,
};

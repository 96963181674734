/**
 * File: toolboxPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import ComponentMixIn from '@/modules/componentMixIn';
import ProductPageMixIn from '@/modules/productPageMixIn';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import ToolboxHostsCountRequest from '@/api/toolboxHostsCountRequest';
import DownloadsContent from '@/modules/downloads/downloadsContent.vue';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import ToolboxComputersTable from './computersTable.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import { support as supportLinks } from '@core/constants/links';
import { PRODUCT_KEY_NAME_PTB, PRODUCT_KEY_NAME_PTBB } from '@core/constants/subscriptions';
import { STATUS_ACTIVE } from '@/models/subscription';
import { GET_SUPPORT_PAGE } from '@/routes/constants';
import Vue from 'vue';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import { getToolboxDownloadsRoute } from '@/modules/home/dashboard/utils';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'toolbox-page',

  mixins: [ComponentMixIn, ProductPageMixIn, BreadcrumbsMixin],

  components: {
    ToolboxComputersTable,
    SubscriptionNotification,
    DownloadsContent,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    AddLicensesModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  props: {
    content: String,
    utility: String,
  },

  data () {
    return {
      personalMode: this.$appData.session.isPersonalUser(this.$appData.userInDomain),
      registeredComputersAmount: null,
      supportLinks,
      subscriptions: [],
      displayNotification: true,
    };
  },

  created () {
    this.load();
  },

  methods: {
    async load () {
      const requestType = this.personalMode ? SubscriptionsRequest.TYPE_PERSONAL : SubscriptionsRequest.TYPE_BUSINESS;
      const subscriptionsRequest = new SubscriptionsRequest({ type: requestType });
      const combinedRequest = new CombinedApiRequest();

      if (!this.personalMode) {
        combinedRequest.addRequest('computersCount', new ToolboxHostsCountRequest());
      }

      combinedRequest.addRequest('subscriptions', subscriptionsRequest);

      await this.authorizedCall(combinedRequest);

      if (!this.personalMode) {
        this.registeredComputersAmount = combinedRequest.getRequest('computersCount').data.activated;
      }

      this.subscriptions = (combinedRequest.getRequest('subscriptions') as SubscriptionsRequest)
        .getSubscriptions().filter(s => s.hasProduct(this.productKeyName));
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      this.handleModal(this.$route.query.modal);
    },
  },

  computed: {
    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },

    canBuyMoreLicenses (): boolean {
      return this.canEditSubscription && this.subscriptions.some((subscription) => subscription.canBuyMoreLicenses);
    },

    canEditSubscription (): boolean {
      return this.personalMode || this.$appData.session.isBusinessAdmin;
    },

    productKeyName (): string {
      if (this.personalMode) {
        if (this.utility) {
          return 'ptb-' + this.utility;
        }
        return PRODUCT_KEY_NAME_PTB;
      } else {
        return PRODUCT_KEY_NAME_PTBB;
      }
    },

    contextProduct (): string {
      return this.personalMode && this.utility ? 'ptb-' + this.utility : 'toolbox';
    },

    isPersonalMode (): boolean {
      return this.$appData.session.isPersonalUser(this.$appData.userInDomain);
    },

    subpages (): MenuItem[] {
      const subpages = [];

      if (!this.isPersonalMode && isFinite(this.registeredComputersAmount)) {
        subpages.push({
          text: $t('Registered Computers ({amount})', { amount: this.registeredComputersAmount }),
          link: { name: 'toolboxComputers' },
        });
      }

      subpages.push(
        {
          text: $t('Download'),
          link: getToolboxDownloadsRoute(this.productKeyName),
        },
        {
          text: $t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
          link: {
            name: this.utility ? 'toolboxUtilitySubscriptions' : 'toolboxSubscriptions',
            params: {
              utility: this.utility,
            },
            query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE },
          },
        }
      );

      if (this.content !== 'toolboxSubscriptionDetails' && this.canBuyMoreLicenses) {
        subpages.push({
          text: $t('Buy More Licenses'),
          link: { query: Object.assign({}, this.$route.query, { modal: 'add-licenses' }), replace: true },
          forceLink: true,
        });
      }

      subpages.push({
        text: $t('Support'),
        click: () => {
          if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
            const params = {
              category: 'Support',
              name: 'Support button click in menu',
            };
            this.$collectGaStats({
              ...params,
              source: document.title,
            });
            this.$collectStats({
              ...params,
              source: PRODUCT_KEY_NAME_PTB,
            });
            this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_PTB } });
          } else {
            window.location.href = this.supportLinks[PRODUCT_KEY_NAME_PTB];
          }
        },
      });

      return subpages;
    },
  },
});


import Vue from 'vue';

import StatusMixin from '../statusMixin';
import ConfigureAppleAppStoreLink from '@/modules/subscriptions/configureAppleAppStore/configureAppleAppStoreLink.vue';

export default Vue.extend({
  name: 'subscription-status-aas-expire-soon',
  mixins: [StatusMixin],
  components: { ConfigureAppleAppStoreLink },
});

/**
 * File: analytics.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All Rights Reserved.
 *
 **/

import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueGtm from 'vue-gtm';
import router from '@/router';
import routes from '@/routes';
import { buildQuery, parseQueryString } from '@core/common/url';
import InitialConfig from '@/models/initialConfig';
import { HTTP_CODES } from '@core/constants/http';

const getId = function (initialConfig: InitialConfig): string[] {
  const universalKeys = {
    dev00: ['G-303ZH2VQW3', 'UA-131212017-2'],
    dev01: ['G-8YK5PTQEF4', 'UA-131212017-1'],
    production: ['G-HCL2P4WVSB', 'UA-111201100-1'],
  };
  return universalKeys[initialConfig.environment];
};

const getGtms = function (initialConfig: InitialConfig): string {
  const gtms = {
    dev00: 'GTM-TT88KTM',
    dev01: 'GTM-TN47NQF',
    production: ['GTM-KRP65C2', 'GTM-KGSQVXP'],
  };
  return gtms[initialConfig?.environment];
};

const ignoreRoutes = routes.filter((route) => route.meta && route.meta.ignoreAnalytics).map((route) => route.name);

const replacements = [
  [/[0-9a-z]{32}/gi, '__uuid__'],
  [/[0-9a-z]{20}/gi, '__code__'],
  [/([0-9a-z]{6}-){4}[0-9a-z]{6}/gi, '__license_key__'],
];

function replaceParams (str = ''): string {
  return replacements.reduce(function (str: string, item: string[]) {
    return str.replace(item[0], item[1]);
  }, str);
}

function setUp (id: string[], gtms: string) {
  Vue.use(VueGtag, {
    config: { id: id[0] },
    pageTrackerExcludedRoutes: ignoreRoutes,
    pageTrackerTemplate (route) {
      const params = parseQueryString(window.location.search);
      const keys = Object.keys(params);
      const pathname = replaceParams(window.location.pathname);
      const page = replaceParams(route.path);
      let query = '';

      // Replace decoded query params and build new query with replaced values
      keys.forEach(function (key) {
        params[key] = key === 'token' ? '__token__' : replaceParams(params[key]);
      });
      query = buildQuery(keys, params);

      return {
        page,
        title: document.title,
        location: window.location.origin + pathname + query,
      };
    },
    // TODO: CPCLOUD-18098 remove this and universal analytics id after July 1
    includes: [{
      id: id[1],
    }],
  }, router);

  // google tag manager config
  Vue.use(VueGtm, {
    id: gtms,
    enabled: true,
    vueRouter: router,
  });

  // Disable GA on user data sensitive pages
  router.beforeEach((to, from, next) => {
    if (ignoreRoutes.includes(to.name)) {
      // @ts-ignore
      Vue.$gtag.optOut();
    } else {
      // @ts-ignore
      Vue.$gtag.optIn();
    }
    next();
  });
}

export const init = function (initialConfig: InitialConfig) {
  const id = getId(initialConfig);
  const gtms = getGtms(initialConfig);
  fetch('/account/api/v1/analytics')
    .then((response) => {
      if (response.status === HTTP_CODES.OK) {
        return response;
      }
      throw new Error(response.statusText);
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.enabled) {
        setUp(id, gtms);
      }
    })
    .catch(() => {
      setUp(id, gtms);
    });
};

function normalizeParams (params = {}) {
  return Object.assign({
    event: 'interaction',
    name: 'n/a',
    source: 'n/a',
  }, params);
}

Vue.prototype.$collectStats = function (event) {
  if (!this.$gtm) return;
  event = normalizeParams(event);

  const query = buildQuery(['event', 'value', 'timestamp'], {
    event: event.name,
    value: event.source,
    timestamp: Date.now(),
  });

  fetch(`/support/sdata/send${query}`);
};

Vue.prototype.$collectGaStats = function (event) {
  if (!this.$gtm) return;
  event = normalizeParams(event);

  this.$gtm.trackEvent(event);
};

Vue.prototype.$trackEvent = function (params = {}) {
  if (this.$gtm) {
    this.$collectGaStats(params);
    this.$collectStats(params);
  }
};

<copyright>
File: ssoErrorPage.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import { LOGIN_PAGE } from '@/routes/constants';
import ErrorPage from '@core/pages/error';
import Vue from 'vue';

export default Vue.extend({
  name: 'sso-product-login-page',
  components: {
    ErrorPage,
  },
  methods: {
    goToLoginPage () {
      this.$router.push({ name: LOGIN_PAGE, query: { sso: '1' } });
    },
  },
});

</script>

<template lang="pug">

  error-page
    template(slot="header")
      | {{ $t('Single Sign-On Misconfiguration Detected') }}
    template(slot="text")
      formatter(:text="$t('It looks like the single sign-on for your domain is not properly configured. Please refer the {link}KB article{/link} for details about configuring the single sign-on for your organization.')")
        template(slot="link", slot-scope="prop")
          a(
            href="https://kb.parallels.com/en/129240",
            :data-name="$name('link-kb-article')"
          ) {{ prop.text }}
    template(slot="button")
      btn(@click="goToLoginPage") {{ $t('Continue') }}

</template>

/**
 * File: permissionsRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class PermissionsRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'permissions';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/permissions/${this.params.uuid}`);
  }
}

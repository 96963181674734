/**
 * File: ssoSettingsRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { ACCOUNT_SERVICE } from '@core/constants/services';
import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { camelize } from '@core/common/utils';

export interface PrlGroupsInterface {
  id: string;
  name: string;
  mandatory: boolean;
}

export interface GroupMappingInterface {
  prlGroupId: string;
  externalId: string;
}

export interface GetSsoSettingsRequestParams {
  domainId: number;
}

export interface UpdateSsoSettingsRequestParams {
  domainId: number;
  settings: {
    status: number;
    verificationCode?: string;
    entityId?: string;
    ssoUrl?: string;
    certificate?: string;
    backupLogin?: number;
    stepsDone?: {};
  };
}

export interface SsoSettingsResponse {
  status: number;
  verificationCode: string;
  entityId: boolean;
  ssoUrl: boolean;
  certificate: boolean;
  spEntityId: string;
  spAcsURL: string;
  scimUrl: string;
  bearerToken: string;
  backupLogin: number;
  stepsDone: {};
  prlGroups: PrlGroupsInterface[];
  groupMapping: GroupMappingInterface[];
}

export class GetSsoSettingsRequest extends ApiRequest<GetSsoSettingsRequestParams, SsoSettingsResponse> {
  get method (): Method {
    return 'GET';
  }

  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/ba/${this.params.domainId}/sso/settings`;
  }

  onFetchSuccess (data: SsoSettingsResponse) {
    return camelize(data);
  }
}

export class UpdateSsoSettingsRequest extends ApiRequest<UpdateSsoSettingsRequestParams, SsoSettingsResponse> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/ba/${this.params.domainId}/sso/settings`;
  }

  get body (): Json {
    const s = this.params.settings;
    return {
      status: s.status,
      entity_id: s.entityId,
      sso_url: s.ssoUrl,
      certificate: s.certificate,
      backup_login: s.backupLogin,
      steps_done: s.stepsDone,
    };
  }

  onFetchSuccess (data: SsoSettingsResponse) {
    return camelize(data);
  }
}

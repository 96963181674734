
import Vue from 'vue';

import TwoStepVerificationPageMixin from './twoStepVerificationPageMixin';

import { CANT_USE_RESCUE_CODES_PAGE, MFA_ONE_TIME_PASSWORD_PAGE, VERIFY_OTP_MODE } from '@/routes/constants';

export default Vue.extend({
  name: 'two-step-verification-rescue-code-page',

  mixins: [TwoStepVerificationPageMixin],

  methods: {
    back () {
      this.$router.push({ name: MFA_ONE_TIME_PASSWORD_PAGE });
    },
    cantUseRescueCode () {
      this.$router.push({ name: CANT_USE_RESCUE_CODES_PAGE, params: { mode: VERIFY_OTP_MODE } });
    },
  },
});


/**
 * File: confirmEmailChangeNew.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { ConfirmEmailChangeBaseGet, ConfirmEmailChangeBaseConfirm } from './confirmEmailChangeBase';

export class ConfirmEmailChangeNewGet extends ConfirmEmailChangeBaseGet {
  get url () {
    return `/account/webapp/confirm_email_change/new/${this.params.actionCode}`;
  }
}

export class ConfirmEmailChangeNewConfirm extends ConfirmEmailChangeBaseConfirm {
  get url () {
    return `/account/webapp/confirm_email_change/new/${this.params.actionCode}`;
  }
}

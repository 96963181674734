/**
 * File: pmmPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import { copyExcluding } from '@core/common/utils';
import { support as supportLinks } from '@core/constants/links';
import { PRODUCT_KEY_NAME_PMM } from '@core/constants/subscriptions';

import SubscriptionsRequest from '@/api/subscriptionsRequest';
import PmmSitesRequest from '@/api/pmmSitesRequest';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductPageMixIn from '@/modules/productPageMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import ProxyServersContent from './proxyServersContent.vue';
import CsrSign from './csrSign/csrSign.vue';
import OfflineValidate from './offlineValidate/offlineValidate.vue';
import DownloadsContent from '@/modules/downloads/downloadsContent.vue';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import { STATUS_ACTIVE } from '@/models/subscription';
import { GET_SUPPORT_PAGE } from '@/routes/constants';
import Vue from 'vue';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'pmm-page',

  props: ['content'],

  mixins: [ComponentMixIn, ProductPageMixIn, SearchFilterMixin, BreadcrumbsMixin],

  components: {
    SubscriptionNotification,
    ProxyServersContent,
    CsrSign,
    OfflineValidate,
    DownloadsContent,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    AddLicensesModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  data () {
    return {
      supportLinks,
      subscriptions: [],
      proxyServersCount: 0,
      personalMode: this.$appData.session.isPersonalUser(this.$appData.userInDomain),
      productKeyName: PRODUCT_KEY_NAME_PMM,
      displayNotification: true,
    };
  },

  mounted () {
    this.load();
  },

  methods: {
    load (): void {
      const requestType = this.personalMode ? SubscriptionsRequest.TYPE_PERSONAL : SubscriptionsRequest.TYPE_BUSINESS;
      const request = new SubscriptionsRequest({ type: requestType });
      this.authorizedCall(request)
        .then((data) => {
          this.subscriptions = request.getSubscriptions().filter(s => s.hasProduct(PRODUCT_KEY_NAME_PMM));
          // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
          this.handleModal(this.$route.query.modal);
        });
      const sitesRequest = new PmmSitesRequest();
      this.$api.authorizedCall(sitesRequest).then(
        (data) => {
          this.proxyServersCount = data && data.length;
        }
      );
    },

    onAddLicensesModalHide (): void {
      this.$router.replace({ query: copyExcluding(this.$route.query, ['modal']) });
    },
  },

  computed: {
    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },

    subpages (): MenuItem[] {
      return [
        {
          text: this.$t('Proxy Servers ({amount})', { amount: this.subscriptionsAmount }),
          link: { name: 'pmmServers' },
        },
        {
          text: this.$t('Download'),
          // @ts-ignore  FIXME: https://parallels.atlassian.net/browse/CPCLOUD-16280
          link: this.getDownloadRoute(PRODUCT_KEY_NAME_PMM),
        },
        {
          text: this.$t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
          link: { name: 'pmmSubscriptions', query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE } },
        },
        {
          text: this.$t('Offline Activation and Validation'),
          link: { name: 'offlineValidate' },
        },
        {
          text: this.$t('MDM Certificate Signing'),
          link: { name: 'csrSign' },
        },
        {
          text: this.$t('Support'),
          click: () => {
            if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
              const params = {
                category: 'Support',
                name: 'Support button click in menu',
              };
              this.$collectGaStats({
                ...params,
                source: document.title,
              });
              this.$collectStats({
                ...params,
                source: PRODUCT_KEY_NAME_PMM,
              });
              this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_PMM } });
            } else {
              window.location.href = this.supportLinks[PRODUCT_KEY_NAME_PMM];
            }
          },
        },
      ];
    },
  },
});

/**
 * File: toolboxHostsCountRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class ToolboxHostsCountRequest extends ApiRequest {
  get url () {
    return '/toolbox/webapp/hosts_count';
  }
}

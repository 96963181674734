<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import DurationFormatter from '@/ui/durationFormatter';
import BannerNotification from '@/ui/bannerNotification';
import MaintenanceModeRequest from '@/api/maintenanceModeRequest';
import { getDuration } from '@core/common/datetime';
import { SCHEDULED_MAINTENANCE, SUPPORT_MAINTENANCE } from '@core/constants/maintenance';

const KEY_PREFIX = 'maintenance-accepted-';

export default {
  name: 'maintenance-notification',
  components: {
    BannerNotification,
    DurationFormatter,
  },
  data () {
    return {
      loading: false,
      hidden: false,
      data: {},
      SCHEDULED_MAINTENANCE,
      SUPPORT_MAINTENANCE,
    };
  },
  created () {
    const request = new MaintenanceModeRequest();
    this.loading = true;

    request.load().then((data) => {
      if (data && data.type) {
        this.data = data;
      }
    }).finally(() => {
      this.loading = false;
    });
  },
  computed: {
    duration () {
      return getDuration(this.data.downtime_optimistic);
    },
    showNotification () {
      return !(this.loading || this.accepted || this.hidden) && this.data.type;
    },
    keyName () {
      return this.data.start_date && `${KEY_PREFIX}${this.data.start_date}`;
    },
    accepted () {
      return this.$localStorage.get(this.keyName);
    },
  },
  methods: {
    hide () {
      // Remove old entries
      try {
        Object.keys(this.$localStorage.storage).forEach((key) => {
          if (key.startsWith(KEY_PREFIX)) {
            this.$localStorage.remove(key);
          }
        });
      } catch (e) {}
      this.$localStorage.set(this.keyName, 1);
      this.hidden = true;
    },
    openInfo () {
      this.$router.push({
        name: 'maintenanceInfo',
      });
    },
  },
};

</script>

<template lang="pug">

banner-notification(v-if="showNotification")
  icon.margin-right(name="alert-yellow", :interactive="false")
  template(v-if="data.type === SCHEDULED_MAINTENANCE")
    formatter(text="{info} {details}")
      template(slot="info") {{ $t('Maintenance is scheduled on {startDate}.', { startDate: formatDateTime(data.start_date) }) }}
      formatter(v-if="duration", slot="details", :text="$t('Expected downtime {duration}.')")
        duration-formatter(slot="duration", :duration="duration")
  template(v-else-if="data.type === SUPPORT_MAINTENANCE")
    formatter(text="{info} {details}")
      template(slot="info") {{ $t('Phone services are unavailable during the scheduled maintenance.') }}
      formatter(v-if="duration", slot="details", :text="$t('Expected downtime is {duration}.')")
        duration-formatter(slot="duration", :duration="duration")
  template(v-else) {{ $t('Unexpected difficulties using Parallels My Account services.') }}
  btn.margin-left(color="orange", size="small", @click="hide") {{ $t('Got It') }}
  btn.margin-left(color="green", size="small", @click="openInfo") {{ $t('More Info') }}

</template>

/**
 * File: order.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import CoreModel from '@core/common/model';

export default class Order extends CoreModel {
  constructor (data, subscription = {}) {
    super(data);
    this.packSize = subscription.firstProductModification ? subscription.firstProductModification.increment : 1;
  }

  get price () {
    return this.amount;
  }

  get licenses () {
    return this.quantity * this.packSize;
  }

  get date () {
    return this.payedAt;
  }

  get paymentUrl () {
    return this.paymentDetailsUrl;
  }
}

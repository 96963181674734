/**
 * File: getPdBusinessSubscriptionsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class GetPdBusinessSubscriptionsRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.uuid
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get cacheNameSpace () {
    return undefined;
  }

  get queryParams () {
    const qp = {};
    if (this.params.hasOwnProperty('uuid')) {
      qp.uuid = this.params.uuid;
    }
    return qp;
  }

  get url () {
    return '/desktop/api/v1/ba/subscriptions/' + this.buildQueryString(this.queryParams);
  }
}

/**
 * File: index.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ConfirmAccountEmailMixin from '../ConfirmAccountEmailChange/ConfirmAccountEmailMixin';
import { ConfirmEmailChangeRevert } from '@/api/confirmEmailChangeRevert';

export default {
  name: 'email-change-reset-password',

  mixins: [ConfirmAccountEmailMixin],

  data () {
    return {
      oldEmail: '',
    };
  },

  methods: {
    load () {
      this.$api.call(new ConfirmEmailChangeRevert({ actionCode: this.code }))
        .then(data => {
          this.oldEmail = data.old_email;
          this.loading = false;
        })
        .catch(this.errorHandler);
    },
  },

};

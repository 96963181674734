/**
 * File: GetProfileDataByLicenseKeyRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { licenseService } from '@core/constants/links';

export type GetProfileDataByLicenseKeyRequestParams = {
  licenseKey: string;
};

/* eslint-disable camelcase */
interface RestorePasswordByLicenseKeyResponseData {
  objects: Array<{
    user_profile: string;
  }>;
  total: number;
}
/* eslint-enable camelcase */

export default class GetProfileDataByLicenseKeyRequest extends ApiRequest<GetProfileDataByLicenseKeyRequestParams, RestorePasswordByLicenseKeyResponseData> {
  get url () {
    const licenseKey = this.params.licenseKey || [];
    const queryString = this.buildQueryString({ license_key: licenseKey });

    return `${licenseService.USER_BY_LICENSE_KEY}${queryString}`;
  }
}

/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 */
export type Step = 1 | 2 | 3 | 4;

export const STEP_INITIAL = 1;
export const STEP_EMAIL = 2;
export const STEP_LICENSE_KEY = 3;
export const STEP_DONE = 4;

export const STEP_EMAIL_NAME = 'email';
export const STEP_LICENSE_KEY_NAME = 'licenseKey';

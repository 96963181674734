

import Beta from '@/modules/beta.vue';
import Vue from 'vue';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import { EnvDependentLink, getEnvDependentLink } from '@/data/links';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'desktop-preview',
  mixins: [BreadcrumbsMixin],
  components: {
    Beta,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },
  props: {
    page: {
      type: String,
    },
  },
  data () {
    const desktopBetaDocumentsRoot = getEnvDependentLink(this, EnvDependentLink.DESKTOP_BETA_DOCUMENTS_ROOT);
    return {
      error: false,
      DESKTOP_BETA_DOCUMENTS_ROOT: desktopBetaDocumentsRoot,
    };
  },
  methods: {
    triggerError () {
      this.error = true;
    },
  },
});


/**
 * File: setCepOptionRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class SetCepOptionRequest extends ApiRequest {
  get url (): string {
    return getServiceUrl(this.params.service, `/api/v1/product/${this.params.productKeyName}/cep_option`);
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      cep_option: this.params.cepOption,
    };
  }
}

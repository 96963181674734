

import Vue, { PropType } from 'vue';
import Subscription from '@/models/subscription';

type SubscriptionTableItem = {
  productName: string;
  quantity?: number | string;
};

export default Vue.extend({
  name: 'subscription-products-table',
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
      required: true,
    },
  },
  computed: {
    columns (): TableViewColumns {
      const quantityColumnLabel = `Qty, ${this.subscription?.firstProductUnits}`;
      return {
        options: [
          { text: this.$t('Product'), value: 'productName', sortable: false },
          // $t('Qty, computers')
          // $t('Qty, mobile devices')
          // $t('Qty, managed computers')
          // $t('Qty, concurrent users')
          { text: this.$t(quantityColumnLabel), value: 'quantity', sortable: false },
        ],
      };
    },
    data (): SubscriptionTableItem[] {
      return this.subscription.products.map((product) => {
        return {
          productName: product.productName,
          quantity: product.licenses.thisPeriod,
        };
      }) || [];
    },
  },
});


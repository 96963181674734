/**
 * File: resendEmailErrors.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

export default function () {
  return {
    'rate limited': $t('Too many email re-send requests have been submitted for the account. For security reasons processing of further requests has been temporarily suspended. Please try again later.'),
  };
};

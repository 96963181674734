/**
 * File: passwordErrors.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import TranslationString from '@/models/translationString';
import { Dictionary } from '@core/common/types';

export default (): Dictionary<TranslationString> => ({
  'invalid password': $t('Invalid password'),
  'password has already been used': $t('Password rejected. Please don`t use passwords that you have already used before.'),
  'weak password': $t('You`re not recommended to use this password because it`s widely used and cannot reliably protect your account.'),
  'password is in top passwords list': $t('You`re not recommended to use this password because it`s widely used and cannot reliably protect your account.'),
  'password is the same as email': $t('You`re not recommended to use password that matches your email, because it cannot reliably protect your account.'),
  'The link you clicked is invalid or expired.': $t('The link has expired and cannot be used anymore.'),
});

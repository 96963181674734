/**
 * File: subscriptionPollingRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import SvcSubscriptionRequest, { SvcSubscriptionRequestParams } from './svcSubscriptionRequest';
import { CB_TRANSACTION_STATES } from '@core/constants/subscriptions';

export const DEFAULT_INITIAL_TIMEOUT = 0;
export const DEFAULT_POLL_TIMEOUT = 2000;

interface SubscriptionPollingRequestParams extends SvcSubscriptionRequestParams {
  initialTimeout?: number;
  pollTimeout?: number;
}

export default class SubscriptionPollingRequest extends SvcSubscriptionRequest<SubscriptionPollingRequestParams> {
  get initialTimeout () {
    return this.params.initialTimeout || DEFAULT_INITIAL_TIMEOUT;
  }

  get pollTimeout () {
    return this.params.pollTimeout || DEFAULT_POLL_TIMEOUT;
  }

  isReady (data) {
    const subscription = (data.subscriptions && data.subscriptions[0]) || {};
    return (!subscription.isUpgrading && !subscription.isDowngrading) || CB_TRANSACTION_STATES.increase.offline === subscription.storeLastTransactionState;
  }

  getPromise () {
    this.emitter.emit('load', this);
    return this.getNextPromise(this.initialTimeout);
  }

  getNextPromise (timeout) {
    const timeoutPromise = new Promise(resolve => setTimeout(resolve, timeout));
    super.dropCache();
    return timeoutPromise
      .then(() => super.getFetchPromise())
      .then((data) => this.isReady(data) ? data : this.getNextPromise(this.pollTimeout));
  }
}

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import GetAccountDeleteRequest from '@/api/getConfirmAccountDeleteRequest';
import AccountDeleteRequest from '@/api/confirmAccountDeleteRequest';
import Subscription from '@/models/subscription';
import SubscriptionMixin from '@/modules/subscriptions/subscriptionMixIn';
import { PRODUCT_NAME_PDFM } from '@core/constants/subscriptions';

let n = 1;

export default {
  name: 'confirm-delete-account',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      error: false,
      email: undefined,
      email_locked_days: undefined,
      loading: undefined,
      account: {
        account: {
          personal: {},
          business: { companies: [] },
        },
        ls: {
          personal: { subscriptions: [] },
          business: { subscriptions: [] },
        },
        pd: {
          personal: { hosts: [], legacy_licenses: [] },
        },
        raslic: {
          business: { subscriptions: [] },
        },
      },
      subscriptionsColumns: ['name', 'main_period_ends_at'],
      keepForumPosts: true,
      deleted: false,
    };
  },
  created () {
    const request = new GetAccountDeleteRequest({
      code: this.code,
    });

    this.loading = true;
    this.$api.call(request).then((data) => {
      this.email = data.request_info.account_email;
      this.email_locked_days = data.email_locked_days;
      this.account = data.account_info;
    }).catch(() => {
      this.error = true;
    }).finally(() => {
      this.loading = false;
    });
  },
  updated () {
    n = 1;
  },
  methods: {
    remove () {
      const request = new AccountDeleteRequest({
        code: this.code,
        keepForumPosts: this.keepForumPosts,
      });

      this.loading = true;
      this.$api.call(request).then((data) => {
        this.deleted = true;
      }).catch(() => {
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    getN () {
      return `${n++}.`;
    },
    signIn () {
      this.$router.push({
        name: 'login',
      });
    },
  },
  computed: {
    products () {
      const products = {};
      // LS subscriptions
      this.personalSubscriptions.forEach((subscription) => {
        subscription = new Subscription(subscription);
        if (subscription.isActive) {
          subscription.products.forEach((product) => {
            products[product.productName] = true;
          });
        }
      });
      // PD Legacy
      this.account.pd.personal.legacy_licenses.forEach((license) => {
        // lic_version >= 7 means PD9 and later
        if (license.lic_version >= 7 && license.licenses.some(license => !license.blacklisted)) {
          products[PRODUCT_NAME_PDFM] = true;
        }
      });

      return products;
    },
    personalSubscriptions () {
      return this.account.ls.personal.subscriptions;
    },
    hasProducts () {
      return Object.keys(this.products).length;
    },
    businessSubscriptions () {
      return this.account.ls.business.subscriptions.concat(this.account.raslic.business.subscriptions).map((item) => {
        return new Subscription(item);
      });
    },
    parallelsSubscriptions () {
      return this.personalSubscriptions.map((item) => new Subscription(item)).filter((item) => {
        return item.storeId !== 'aas' && !item.isPermanent && item.isActive;
      });
    },
    appStoreSubscriptions () {
      return this.personalSubscriptions.filter((item) => {
        return item.storeId === 'aas';
      }).map((item) => new Subscription(item));
    },
    companies () {
      return this.account.account.business.companies.filter((company) => company.is_admin);
    },
  },
  mixins: [SubscriptionMixin],
};

</script>

<template lang="pug">

page(:appData="$appData", :error="error")
  template(slot="pageContent")
    card
      loader(:loading="loading")
        template(v-if="deleted")
          h2 {{ $t('Your Parallels Account Has Been Deleted') }}
          h3.text-danger.text-center.margin-bottom-2x {{ email }}
          .margin-bottom-2x {{ $tc('You may not be able to use the same email address to create a new Parallels account for at least {count} days while we are making sure that all data is completely removed from all Parallels services.', email_locked_days, { count: email_locked_days }) }}
          .text-right
            btn.block-xs(
              @click="signIn"
            ) {{ $t('OK') }}
        template(v-else)
          h2 {{ $t('Deleting Parallels User Account') }}
          h3.text-danger.text-center.margin-bottom-2x {{ email }}
          .margin-bottom-3x {{ $t('Once your account is deleted, you will lose the history of your Parallels product license purchases and product license keys. Other consequences of deleting your Parallels account are described below:') }}
          .margin-bottom-3x(v-if="hasProducts")
            .margin-bottom.text-bold {{ $t('{n} The following products will stop working as soon as the account is deleted:', { n: getN() }) }}
            ul
              li(v-for="(v, product) in products") {{ $t(product) }}
          .margin-bottom-3x(v-if="parallelsSubscriptions.length")
            .margin-bottom.text-bold {{ $t('{n} The following Parallels product subscriptions will be canceled automatically:', { n: getN() }) }}
            .margin-bottom
              datatable.table(
                :data="parallelsSubscriptions",
                :columns="subscriptionsColumns",
                name="parallelsSubscriptionsDeleteAccount"
              )
                template(slot="name_header", slot-scope="s") {{ $t('Subscription') }}
                template(slot="main_period_ends_at_header", slot-scope="s") {{ $t('Valid Until') }}
                template(slot="name", slot-scope="s") {{ getSubscriptionName(s.row) }}
                template(slot="main_period_ends_at", slot-scope="s")
                  template(v-if="s.row.isPermanent") {{ $t('Permanent') }}
                  template(v-else) {{ s.row.expirationDate | date }}
            icon.margin-right(slot="icon", name="alert-yellow")
            formatter(:text="$t('Note: We will not be able to automatically arrange a refund for your canceled subscriptions. Click here to learn more about refund: {link}')")
              a(slot="link", href="https://kb.parallels.com/123877", target="_blank") https://kb.parallels.com/123877
          .margin-bottom-3x(v-if="appStoreSubscriptions.length")
            .margin-bottom.text-bold {{ $t('{n} The following subscriptions in the Apple App Store cannot be canceled automatically:', { n: getN() }) }}
            .margin-bottom
              datatable.table(
                :data="appStoreSubscriptions",
                :columns="subscriptionsColumns",
                name="appStoreSubscriptionsDeleteAccount"
              )
                template(slot="name_header", slot-scope="s") {{ $t('Subscription') }}
                template(slot="main_period_ends_at_header", slot-scope="s") {{ $t('Valid Until') }}
                template(slot="name", slot-scope="s") {{ getSubscriptionName(s.row) }}
                template(slot="main_period_ends_at", slot-scope="s")
                  template(v-if="s.row.isPermanent") {{ $t('Permanent') }}
                  template(v-else) {{ s.row.expirationDate | date }}
            icon.margin-right(slot="icon", name="alert-yellow")
            formatter(:text="$t('Click here to learn how to cancel a subscription in App Store: {link}')")
              a(slot="link", href="https://support.apple.com/en-us/HT202039", target="_blank") https://support.apple.com/en-us/HT202039
          .margin-bottom-3x(v-if="businessSubscriptions.length")
            .margin-bottom.text-bold {{ $t('{n} You won`t be able to administer the following business product licenses:', { n: getN() }) }}
            datatable.table(
              :data="businessSubscriptions",
              :columns="subscriptionsColumns",
              name="businessSubscriptionsDeleteAccount"
            )
              template(slot="name_header", slot-scope="s") {{ $t('Subscription') }}
              template(slot="main_period_ends_at_header", slot-scope="s") {{ $t('Valid Until') }}
              template(slot="name", slot-scope="s") {{ getSubscriptionName(s.row) }}
              template(slot="main_period_ends_at", slot-scope="s")
                template(v-if="s.row.isPermanent") {{ $t('Permanent') }}
                template(v-else) {{ s.row.expirationDate | date }}
          .margin-bottom-3x(v-if="companies.length")
            .margin-bottom.text-bold {{ $t('{n} Your administration privileges will be canceled for the following business accounts:', { n: getN() }) }}
            ul
              li(v-for="company in companies") {{ company.name }}
          .margin-bottom-2x
            .margin-bottom.text-bold {{ $t('{n} Your information (if found) will also be deleted from the following services:', { n: getN() }) }}
            .row
              .col-sm-6.col-xs-12
                ul.line-height-3x
                  li.no-margin {{ $t('Parallels Forum') }}
                  li.no-margin {{ $t('Parallels Support System') }}
                  li {{ $t('Parallels Problem Reports Server') }}
              .col-sm-6.col-xs-12
                checkbox(v-model="keepForumPosts") {{ $t('I prefer to keep my posts (if any) on the Parallels Forum') }}
          .text-center-sm
            .text-danger.margin-bottom-4x
              div {{ $t('WARNING: The account deletion procedure is irreversible!') }}
              div {{ $tc('After your account is deleted, you may not be able to use the same email address to create a new Parallels account for at least {count} days.', email_locked_days, { count: email_locked_days }) }}
            btn.block-xs(
              color="red",
              @click="remove"
            ) {{ $t('Delete My Parallels Account') }}
  template(slot="error-header") {{ $t('Expired Link') }}
  template(slot="error-text") {{ $t('The link has expired and cannot be used anymore.') }}
  template(slot="error-button")
    btn(
      @click="signIn",
      :data-name="$name('button-sign-in')"
    ) {{ $t('Go to My Account') }}

</template>

<style scoped lang="sass">

ul
  padding-left: $grid-step

</style>

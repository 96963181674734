/**
 * File: supportRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import GetSupportPage from '../modules/support/getSupportPage.vue';
import MyTicketsPage from '../modules/support/myTicketsPage.vue';
import { GET_SUPPORT_PAGE } from '@/routes/constants';

export default [
  {
    path: '/support/tickets',
    name: 'myTickets',
    props: true,
    component: MyTicketsPage,
    meta: {
      requiresAuth: true,
      title: 'Tickets',
    },
  },
  {
    path: '/support/:product?/:supportType?/:action(request)?',
    name: GET_SUPPORT_PAGE,
    component: GetSupportPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Support',
    },
  },
];

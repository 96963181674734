/**
 * File: getDomainsWithProductRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { licenseService } from '@core/constants/links';

interface GetDomainsWithProductRequestParams {
  domainIds: string;
  product: string;
}

export interface GetDomainsWithProductRequestResponse {
  count: number;
  next: number;
  previous: number;
  results: { id: number }[];
}

export default class GetDomainsWithProductRequest extends ApiRequest<GetDomainsWithProductRequestParams, GetDomainsWithProductRequestResponse> {
  get url () {
    const uri = `${licenseService.DOMAINS_WITH}/${this.params.product}`;
    const queryString = this.buildQueryString({ domains_ids: this.params.domainIds });
    return queryString ? uri + queryString : uri;
  }

  get cacheNameSpace () {
    return `domains_with_${this.params.product}`;
  }
};

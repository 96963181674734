<copyright>
File: pdfcNotification.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import BannerNotification from '@/ui/bannerNotification';
import { FEATURE_PDFC_BANNER } from '@core/constants/features';
import bannerNotificationMixin from '@/ui/bannerNotification/bannerNotificationMixin';

const KEY_NAME = 'pdfc-notification-hidden';

export default {
  name: 'pdfc-notification',
  components: {
    BannerNotification,
  },
  mixins: [bannerNotificationMixin],
  data () {
    return {
      featureKey: FEATURE_PDFC_BANNER,
      storageKey: KEY_NAME,
    };
  },
};
</script>

<template lang="pug">

banner-notification(v-if="show", color="blue")
  icon.margin-right(name="cross-small", @click="hide")
  span {{ $t('Parallels and Google partner to bring Windows apps to Chrome Enterprise.') }}
  btn.margin-left(color="blue", size="small", href="//www.parallels.com/chrome/", target="_blank") {{ $t('Learn More') }}

</template>

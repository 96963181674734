
import Vue from 'vue';
import { email } from '@core/common/validators';
import StepsMixIn from './StepsMixIn.vue';

export default Vue.extend({
  name: 'forgot-page-step-email',

  mixins: [StepsMixIn],

  data () {
    return {
      email: '',
    };
  },

  validations: { email },

});

/**
 * File: editDialog.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { GROUP_ADMIN } from '@/api/userConst';

import CompanyUsersBlockRequest from '@/api/companyUsersBlockRequest';
import CompanyUsersUnblockRequest from '@/api/companyUsersUnblockRequest';
import CompanyUsersRemoveRequest from '@/api/companyUsersRemoveRequest';
import CompanyUsersReinviteRequest from '@/api/companyUsersReinviteRequest';
import CompanyUsersUpdateRequest from '@/api/companyUsersUpdateRequest';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import PermissionsChangeRequest from '@/api/permissionsChangeRequest';

const TAB_GENERAL = 'general';
const TAB_LICENSES = 'permissions';

export default {
  name: 'edit-dialog',

  props: {
    session: Object,
    user: Object,
  },

  data () {
    return {
      state: { is_invited: false, is_blocked: false, is_active: false },
      role: null,
      requestLoading: false,
      notes: this.user.notes,
      dirty: false,
      TAB_GENERAL,
      TAB_LICENSES,
      tab: TAB_GENERAL,
      subscriptions: null,
      removedSubscriptions: [],
    };
  },

  computed: {
    roleOptions () {
      return this.getRoles().map((role) => ({ value: role.name, text: role.text }));
    },
    tabs () {
      const tabs = {
        [TAB_GENERAL]: this.$t('General'),
      };

      if (this.subscriptions) {
        tabs[TAB_LICENSES] = this.$t('Managed Licenses');
      }

      return tabs;
    },
  },

  methods: {
    show () {
      this.init();
      this.$refs.dialog.show();
    },

    init () {
      switch (true) {
        case this.user.isInvited:
          this.setState('is_invited');
          break;
        case this.user.isBlocked:
          this.setState('is_blocked');
          break;
        default:
          this.setState('is_active');
      }

      this.dirty = false;
      this.subscriptions = this.user.subscriptions && this.user.subscriptions.slice();
      this.removedSubscriptions = [];
      this.tab = TAB_GENERAL;
      this.role = this.getRoles().find((role) => role.match(this.user)).name;
      this.notes = this.user.notes;
    },

    notify (message) {
      this.$toast.show({
        color: 'green',
        text: message,
      });
    },

    resend () {
      this.sendRequest(CompanyUsersReinviteRequest, this.user.uuid).then(() => {
        this.notify($t('Invitation sent'));
      });
    },

    block () {
      this.sendRequest(CompanyUsersBlockRequest, this.user.uuid).then(() => {
        this.setState('is_blocked');
        this.notify($t('User blocked'));
      });
    },

    unblock () {
      this.sendRequest(CompanyUsersUnblockRequest, this.user.uuid).then(() => {
        this.setState('is_active');
        this.notify($t('User unblocked'));
      });
    },

    save () {
      const
        role = this.getRoles().find((role) => role.name === this.role);
      const payload = {
        userUuids: [this.user.uuid],
        addGroups: role.addGroups,
        removeGroups: role.removeGroups,
        notes: this.notes,
      };
      const request = new CombinedApiRequest();

      request.addRequest('users', new CompanyUsersUpdateRequest(payload, this.session.businessDomainId));

      this.removedSubscriptions.forEach((subscription) => {
        request.addRequest(`update_permissions_${subscription.uuid}`, new PermissionsChangeRequest({
          uuid: subscription.uuid,
          members: subscription.members,
          service: subscription.targetServiceName,
        }));
      });

      this.requestLoading = true;

      this.$api.authorizedCall(request).then(() => {
        this.notify($t('Changes saved'));
        this.dirty = true;
        this.close();
      }).finally(() => {
        this.requestLoading = false;
      });
    },

    remove () {
      this.sendRequest(CompanyUsersRemoveRequest, this.user.uuid).then(() => {
        this.notify($t('User deleted'));
        this.close();
      });
    },

    removeSubscription (uuid) {
      const i = this.subscriptions.findIndex((s) => s.uuid === uuid);
      const [item] = this.subscriptions.splice(i, 1);
      item.members = item.members.filter((uuid) => uuid !== this.user.uuid);
      this.removedSubscriptions.push(item);
    },

    close () {
      if (this.dirty) {
        this.$emit('reload');
      }

      this.$refs.dialog.hide();
    },

    sendRequest (requestClass, userUuid) {
      // eslint-disable-next-line
      let request = new requestClass({userUuids: [userUuid]}, this.session.businessDomainId);

      request.emitter.on('started', () => {
        this.requestLoading = true;
      });
      request.emitter.on('completed', () => {
        this.dirty = true;
        this.requestLoading = false;
      });
      request.emitter.on('error', () => {
        this.requestLoading = false;
      });

      return this.$api.authorizedCall(request);
    },

    getRoles () {
      const memberRoleText = this.user.isLicenseAdmin ? $t('License Manager') : $t('Regular Member');

      return [
        {
          name: 'user',
          text: memberRoleText,
          addGroups: [],
          removeGroups: [GROUP_ADMIN],
          match: (user) => !user.isAdmin,
        },
        {
          name: 'admin',
          text: $t('Account Administrator'),
          addGroups: [GROUP_ADMIN],
          removeGroups: [],
          match: (user) => user.isAdmin,
        },
      ];
    },

    setState (name) {
      for (const prop of Object.getOwnPropertyNames(this.state)) {
        this.state[prop] = prop === name;
      }
    },

    setTab (params) {
      this.tab = params.tab;
    },
  },
};

/**
 * File: kbSearchRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { appData } from '@/data/appData'; // FIXME use Vuex!
import KbRequest, { KbRequestParams } from './kbRequest';
import { Json, Method } from '@core/api/apiRequest';

interface Response {
  results: Array<{
    TitleSnippet: string;
    Summary: string;
    EffectiveId: string;
    prediction: number;
    'iframe_url': string;
  }>;
  count: number;
}

export interface KbArticle {
  id: string;
  title: string;
  prediction?: number;
  iframeUrl: string;
}

export interface KbSingleArticleOptions extends KbRequestParams {
  id: string;
  language: string;
}

export interface KbSearchArticleOptions extends KbRequestParams {
  language: string;
  productKey: string;
  q: string;
  perPage: number;
  page: number;
  mark: string;
}

export default class KbSearchRequest extends KbRequest<KbSingleArticleOptions & KbSearchArticleOptions, Response> {
  // Example: https://kb.parallels.com/Search/essearch?language=en&product_key=pdfm&q=windows

  get useKbSearch (): boolean {
    return appData.rtSearchEngine === 'ps' && !this.params.id;
  }

  get method (): Method {
    return this.useKbSearch ? 'POST' : 'GET';
  }

  get url (): string {
    return this.useKbSearch ? '/kbsrch/api/search' : super.url;
  }

  get uri (): string {
    const params = this.params;
    const uri = '/Search/essearch';
    // @ts-ignore
    const query = this.buildQueryString({
      id: params.id,
      language: params.language,
      product_key: params.productKey,
      q: params.q,
      PerPage: params.perPage,
      Page: params.page,
      mark: params.mark || 0, // disable html marking of founded keywords
      my: true,
    });

    return query ? uri + query : uri;
  }

  get body (): Json {
    if (this.useKbSearch) {
      return {
        description: this.params.q,
        language: this.params.language,
        product_key: this.params.productKey,
        per_page: this.params.perPage,
        page: this.params.page,
      };
    }
  }

  get articles (): KbArticle[] {
    if (!this.data) {
      return;
    }

    let result: KbArticle[];
    if (this.useKbSearch) {
      result = this.data.results.map((article) => ({
        title: article.TitleSnippet || article.Summary,
        id: article.EffectiveId,
        prediction: article.prediction,
        iframeUrl: article.iframe_url,
      })).filter((article) => {
        return article.title && article.prediction;
      });
    } else {
      result = this.data.results.map((article) => ({
        title: (article.TitleSnippet || article.Summary).replace(/{[^}]+}/g, ''),
        id: article.EffectiveId,
        iframeUrl: article.iframe_url,
      }));
    }

    return result.filter((article) => article.iframeUrl && article.title && article.id);
  }

  get count () {
    return this.data && this.data.count;
  }
}

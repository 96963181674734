/**
 * File: inviteDialog.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { withParams } from 'vuelidate/lib/params';
import { LANGUAGES } from '@core/constants/langs';
import CompanyUsersInviteRequest from '@/api/companyUsersInviteRequest';
import emailList from '@/ui/email-list';

export default {
  name: 'invite-dialog',

  props: {
    session: Object,
    users: Array,
  },

  data () {
    return {
      requestLoading: false,
      localeOptions: LANGUAGES,
      locale: this.session.isoLocale,
      emails: {
        valid: false,
        list: [],
      },
    };
  },

  computed: {
    customValidations () {
      const alreadyMember = (users) => withParams(
        { type: 'alreadyMember', users },
        (email) => {
          return !(email && users.some((user) => !user.is_invited && user.email === email));
        }
      );

      return [{
        name: 'alreadyMember',
        message: this.$t('This user is already a member of the business account'),
        callback: alreadyMember(this.users),
      }];
    },
  },

  methods: {
    resetForm () {
      this.requestLoading = false;
      this.emails = {
        valid: false,
        list: [],
      };
      this.$refs.emailList.removeAll();
      this.$refs.emailList.resetValidation();
    },

    show () {
      this.resetForm();
      this.$refs.dialog.show();
    },

    sendInvites () {
      this.requestLoading = true;

      const payload = {
        mail_list: this.emails.list,
        reinvite: true,
        locale: this.locale,
      };

      const request = new CompanyUsersInviteRequest(payload, this.session.businessDomainId);

      this.$api.authorizedCall(request).then((data) => {
        const count = data.succeed.length;
        if (count) {
          this.$toast.show({
            color: 'green',
            text: $tc('{count} invitations have been sent', count, { count }),
          });
        }
        this.$emit('invitationSent');

        this.requestLoading = false;
        this.$refs.dialog.hide();
      }).catch(() => {
        this.requestLoading = false;
      });
    },
  },

  components: {
    emailList,
  },
};

/**
 * File: rtRemoveToken.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtRemoveToken extends RtRequest {
  /**
   * @returns {String}
   */
  get uri () {
    return '/session';
  }

  get method () {
    return 'DELETE';
  }
}

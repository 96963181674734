/**
 * File: ConfirmAccountEmailChange.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ConfirmAccountEmailMixin from './ConfirmAccountEmailMixin';

import {
  ConfirmEmailChangeOldGet,
  ConfirmEmailChangeOldConfirm,
  ConfirmEmailChangeOldResendNew
} from '@/api/confirmEmailChangeOld';

export default {
  name: 'confirm-account-email-change',

  mixins: [ConfirmAccountEmailMixin],

  methods: {
    getChangeRequest () {
      return new ConfirmEmailChangeOldGet({
        actionCode: this.code,
      });
    },
    getConfirmRequest () {
      return new ConfirmEmailChangeOldConfirm({
        actionCode: this.code,
      });
    },
    getResendRequest () {
      return new ConfirmEmailChangeOldResendNew({
        actionCode: this.code,
      });
    },
  },

};

/**
 * File: oemLicensesMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */
import Vue from 'vue';

import { email } from 'vuelidate/lib/validators';

import BrowserListRequest from '@/api/browserListRequest';

export default Vue.extend({
  data () {
    return {
      error: false,
      loading: false,
      filters: {} as TableViewFilters,
      activeColumns: [] as string[],
    };
  },
  created () {
    if (!this.$appData.session.isPdfcPurchaseFeatureAccessible) {
      this.error = true;
      return;
    }

    this.checkBrowserStatus().then(() => {
      // @ts-ignore  load() expected to present at component which used mixin
      this.load();
    }).catch(() => {
      this.error = true;
    });
  },
  methods: {
    checkBrowserStatus () {
      const request = new BrowserListRequest();

      this.loading = true;

      return this.$api.authorizedCall(request).then((data) => {
        const browsers = data.browsers || [];
        const browser = browsers.find((browser) => browser.current);

        if (!browser || !browser.trusted) {
          throw new Error('browser is not trusted');
        }
      });
    },
    callAction (params) {
      const method = params.action;
      this[method](params.items);
    },
    changeColumns (params) {
      this.activeColumns = params.columns;
    },
    isEmailValid (value: string): boolean {
      // @ts-ignore
      return email(value);
    },
  },
});

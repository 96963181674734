/**
 * File: proxyServersContent.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { copyExcluding } from '@core/common/utils';
import ComponentMixIn from '@/modules/componentMixIn';
import ServerDetailsModal from './detailsModal/proxyServerDetailsModal.vue';
import PmmSitesRequest from '@/api/pmmSitesRequest';
import ProxyServersTable from './proxyServersTable/proxyServersTable.vue';
import FilterDropdownMy from '@/ui/filterDropdown';
import SearchFilterMixin from '@/modules/searchFilterMixin';

const SERVER_STATUS_ACTIVE = 'active';
const SERVER_STATUS_DEACTIVATED = 'deactivated';

export default {
  name: 'proxy-servers-content',
  props: ['appData'],
  mixins: [ComponentMixIn, SearchFilterMixin],
  components: { FilterDropdownMy, ProxyServersTable, ServerDetailsModal },

  data () {
    return {
      servers: [],
      filters: {},
      serversStatusValue: '',
      serversStatusOptions: [
        { text: $t('Active ({count})'), value: SERVER_STATUS_ACTIVE, filter: (entry) => { return entry.isActivated; } },
        { text: $t('Deactivated ({count})'), value: SERVER_STATUS_DEACTIVATED, filter: (entry) => { return !entry.isActivated; } },
      ],
    };
  },

  created () {
    this.load();
  },

  methods: {
    load () {
      const sitesRequest = new PmmSitesRequest({});
      this.authorizedCall(sitesRequest).then(() => {
        this.servers = sitesRequest.getSites();
        if (this.$route.query.modal === 'details') {
          const server = this.getServerById(this.$route.query.uuid);
          if (server) {
            this.$refs.serverDetailsModal.show(server);
          }
        }
      });
    },

    getServerById (uuid) {
      return this.servers.find((server) => server.uuid === uuid);
    },

    onServerClick (server) {
      this.$refs.serverDetailsModal.show(server);
    },

    onServerDetailsShow (server) {
      this.$router.replace({ query: Object.assign({}, this.$route.query, { modal: 'details', uuid: server.uuid }) });
    },

    onServerDetailsHide () {
      this.$router.replace({ query: copyExcluding(this.$route.query, ['modal', 'uuid']) });
    },

    onServersStatusChange (newValue) {
      this.serversStatusValue = newValue;
      this.$emit('serversStatusChange', newValue);
    },

    onServerUpdated (server) {
      const idx = this.servers.map((s) => s.uuid).indexOf(server.uuid);
      this.$set(this.servers, idx, server);
    },
  },

  computed: {
    searchResults () {
      return this.applySearchFilters(this.filters, this.servers);
    },

    subscriptionStatusOptions () {
      if (this.serversStatusValue !== SERVER_STATUS_DEACTIVATED) {
        return [
          {
            text: this.$t('Expiring Soon ({count})'),
            value: 'expiring',
            filter: (entry) => {
              return entry.isExpiringSoon;
            },
          },
          {
            text: this.$t('Expired ({count})'),
            value: 'expired',
            filter: (entry) => {
              return entry.isExpired;
            },
          },
        ];
      }
      return [];
    },
  },
};

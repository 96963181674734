/**
 * File: authByTokenRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { HTTP_CODES } from '@core/constants/http';

export default class AuthByTokenRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/license/api/v1/auth';
  }

  get body () {
    return {
      token: this.params.token,
      ttl: 600,
    };
  }

  getPromise () {
    return super.getPromise()
      .catch((error) => {
        if (error.response.status === HTTP_CODES.CONFLICT) {
          return error.response.json();
        }
        throw error;
      })
      .then((data) => {
        if (data && data.msg) {
          throw new AuthByTokenRequestError(data);
        }
        return data;
      });
  }
}

export class AuthByTokenRequestError extends Error {
  constructor (data) {
    super(data.msg || 'AuthByTokenRequestError');

    if (data.msg === 'invalid token') {
      this.invalidAuthToken = true;
    }
  }
}

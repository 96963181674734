<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import { HTTP_CODES } from '@core/constants/http';
import throttle from 'lodash-es/throttle';
import TTLRequest from '@/api/ttlRequest';
import SessionStatus from './status.js';

/**
 *  TTL value in ms to show user modal window with timer
 */
const PANIC_TIME = 60 * 3 * 1000;

/**
 *  Constant: delta
 *
 *  Time in ms to refresh ttl via setInterval
 *
 */
const delta = 20000;

/**
 *  Constant: MODES
 *
 *  wait: ttl > PANIC_TIME
 *  prepanic: 0 < ttl < PANIC_TIME and we must request actual ttl
 *  panic: ttl is actual and 0 < ttl < PANIC_TIME, so we show modal box with timer
 *  preterminated: ttl = 0 and we must request actual ttl
 *  terminated: ttl is actual and ttl = 0, so we show modal box with session timeout message
 *
 */
const MODES = {
  PRETERMINATED: 'preterminated',
  TERMINATED: 'terminated',
  PANIC: 'panic',
  PREPANIC: 'prepanic',
  WAIT: 'wait',
};

/**
 *  Constant: events
 *
 *  Events to refresh ttl
 *
 */
const events = [
  'keydown',
  'click',
  'touchstart',
  'mousemove',
  'scroll',
];

/**
 *  Variable: intervalId
 *
 *  Interval returned by setInterval to refresh TTL in browser
 *
 */
var intervalId;

var getTTL = function (api, callback, refresh, errorHandler) {
  var ttlRequest = new TTLRequest({
    refresh: refresh,
    checkUnauthorizedResponse: false,
  });
  return api.authorizedCall(ttlRequest).then(callback).catch(errorHandler);
};

var refreshTTL = throttle(getTTL, 61000);

/**
 *  Function: addEventListeners
 *
 *  Set event listeners on user actions to refresh TTL
 *
 */
function addEventListeners (eventListener) {
  events.forEach(function (event) {
    document.addEventListener(event, eventListener);
  });
}

/**
 *  Function: removeEventListeners
 *
 *  Remove listeners and disable ttl refresh
 *
 */
function removeEventListeners (eventListener) {
  events.forEach(function (event) {
    document.removeEventListener(event, eventListener);
  });
}

/**
 *  Function: setTTLTimer
 *
 *  Substract `delta` from TTL every `delta` ms
 *  Using current timestamp because of browser can freeze setInterval for inactive tab
 *
 */
function setTTLTimer (delta, ttl, setTTL) {
  var startTime = Date.now();

  if (intervalId) {
    clearInterval(intervalId);
  }

  intervalId = setInterval(function () {
    var now = Date.now();
    ttl -= now - startTime;
    startTime = now;
    setTTL({
      ttl: ttl,
    });
  }, delta);
}

export default {
  name: 'session-expiration-modal',
  data () {
    return {
      ttl: 0,
      mode: undefined,
      modalName: 'sessionTimeout',
    };
  },
  computed: {
    visible () {
      var visible = this.mode === MODES.PANIC || this.mode === MODES.PRETERMINATED;
      if (!visible) {
        this.$modal.hide(this.modalName);
      }
      return visible;
    },
  },
  watch: {
    ttl (ttl) {
      if (typeof ttl !== 'number') {
        return;
      }

      if (this.mode === MODES.PREPANIC && ttl > 0 && ttl < PANIC_TIME) {
        this.mode = MODES.PANIC;
      } else if ((!this.mode || this.mode === MODES.WAIT) && ttl > 0 && ttl < PANIC_TIME) {
        this.mode = MODES.PREPANIC;
      } else if (this.mode === MODES.PRETERMINATED && ttl <= 0) {
        this.mode = MODES.TERMINATED;
      } else if (ttl <= 0) {
        this.mode = MODES.PRETERMINATED;
      } else if (ttl > PANIC_TIME) {
        this.mode = MODES.WAIT;
      }
    },
    mode (mode) {
      if (mode === MODES.WAIT) {
        addEventListeners(this.refreshTTL);
        this.setTTLTimer(delta);
      } else if (mode === MODES.PREPANIC) {
        this.getTTL();
      } else if (mode === MODES.PRETERMINATED) {
        this.getTTL().catch(() => {
          this.mode = MODES.TERMINATED;
        });
      } else if (mode === MODES.PANIC) {
        this.cancelRefresh();
        removeEventListeners(this.refreshTTL);
        this.$nextTick(() => {
          this.$modal.show(this.modalName);
        });
        this.setTTLTimer(1000);
      } else if (mode === MODES.TERMINATED) {
        clearInterval(intervalId);
        SessionStatus.setExpiration(true);
        this.$api.logout();
      }
    },
  },
  mounted () {
    this.getTTL();
  },
  destroyed () {
    this.cancelRefresh();
    removeEventListeners(this.refreshTTL);
    this.$modal.hide(this.modalName);
  },
  methods: {
    getTTL (refresh) {
      if (this.$route.name !== 'logout' && this.$appData.session && !this.$appData.session.isGuest) {
        if (refresh) {
          this.cancelRefresh();
        }
        return getTTL(this.$api, this.setTTL, refresh).catch(this.terminate);
      }
    },
    setTTL (data) {
      this.ttl = data.ttl;
    },
    refreshTTL () {
      return refreshTTL(this.$api, this.setTTL, true, this.terminate);
    },
    cancelRefresh () {
      return refreshTTL.cancel();
    },
    setTTLTimer (delta) {
      return setTTLTimer(delta, this.ttl, this.setTTL);
    },
    countdown () {
      var minutes = Math.floor(this.ttl / 60000);
      var seconds = Math.floor(this.ttl % 60000 / 1000);

      if (minutes < 0 || seconds < 0) {
        minutes = 0;
        seconds = 0;
      }

      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      // TODO: add pluralization for seconds
      return `${minutes}:${seconds}`;
    },
    logout () {
      this.loading = true;
      this.$api.logout();
    },
    terminate (error = {}) {
      const status = error?.response?.status;
      if (status === HTTP_CODES.UNAUTHORIZED || status === HTTP_CODES.CONFLICT) {
        this.mode = MODES.TERMINATED;
      }
    },
  },
};

</script>

<template lang="pug">

modal(v-if="visible", :name="modalName", :data-name="$name()")
  template(slot="header") {{ $t('Session Timeout') }}
  template(slot="content")
    formatter(:text="$t('Your Parallels My Account session will end in {time}.')")
      template(slot="time", slot-scope="props") {{ countdown() }}
  template(slot="footer")
    btn(@click="getTTL(true)", :data-name="$name('button-extend-session')") {{ $t('Extend Session') }}
    btn(color="white", @click="logout", :data-name="$name('button-sign-out')") {{ $t('Sign Out') }}

</template>

/**
 * File: legacyLicensesContent.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import componentMixIn from '@/modules/componentMixIn';
import PdLicensesRequest from '@/api/pdLicensesRequest';

export default {
  name: 'legacy-licenses-content',

  mixins: [componentMixIn],

  data () {
    return {
      search: '',
      subscriptions: [],
    };
  },

  mounted () {
    this.load();
  },

  methods: {
    load () {
      const request = new PdLicensesRequest();

      this.authorizedCall(request).then((data) => {
        this.subscriptions = data;
      });
    },

    language (downloadUrls) {
      const languages = {
        '': this.$t('Multilingual'),
        en_us: this.$t('English'),
        ru_ru: this.$t('Russian'),
        cs_cz: this.$t('Czech'),
        de_de: this.$t('German'),
        fr_fr: this.$t('French'),
        zh_cn: this.$t('Simplified Chinese'),
        zh_tw: this.$t('Chinese Traditional'),
        it_it: this.$t('Italian'),
        ja_jp: this.$t('Japanese'),
        ko_kr: this.$t('Korean'),
        pl_pl: this.$t('Polish'),
        pt_br: this.$t('Brazilian'),
        es_es: this.$t('Spanish'),
        en_gb: this.$t('British'),
        hu_hu: this.$t('Hungarian'),
        ro_ro: this.$t('Romanian'),
        en_eu: this.$t('European'),
        en_au: this.$t('Australian'),
        en_ca: this.$t('Canadian'),
        en_si: this.$t('Singaporean'),
        en_nl: this.$t('Dutch'),
        en_se: this.$t('Swedish'),
      };

      const source = Object.assign({}, downloadUrls); // remove observer object property assigned by vue

      return Object.getOwnPropertyNames(source).map((language) => languages[language.toLowerCase()]).join(', ');
    },

    downloadLink (downloadUrls) {
      const
        source = Object.assign({}, downloadUrls); // remove observer object property assigned by vue
      const firstKey = Object.getOwnPropertyNames(source)[0];

      return source[firstKey];
    },
  },

  computed: {
    searchResults () {
      return this.search ? this.subscriptions.filter((s) => s.product_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) : this.subscriptions;
    },
  },
};

/**
 * File: downloadsPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ComponentMixIn from '@/modules/componentMixIn';
import DownloadsContent from './downloadsContent.vue';
import Vue from 'vue';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';

export default Vue.extend({
  name: 'downloads-page',
  mixins: [ComponentMixIn, BreadcrumbsMixin],
  components: { DownloadsContent },
  computed: {
    hasProducts () {
      return this.$appData.products.personal.length || this.$appData.products.business.length;
    },
    subpages (): MenuItem[] {
      if (!this.hasProducts) {
        return [
          {
            text: this.$t('Register Key'),
            link: { name: 'addKeyPage' },
          },
          {
            text: this.$t('Downloads'),
            link: { name: 'downloads' },
          },
        ];
      }
      return [];
    },
  },
});

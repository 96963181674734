
import Vue from 'vue';

import StatusMixin from '../statusMixin';
import ConfigureGooglePlayStoreLink from '@/modules/subscriptions/configureGooglePlayStore/configureGooglePlayStoreLink.vue';

export default Vue.extend({
  name: 'subscription-status-gps-expire-soon',
  mixins: [StatusMixin],
  components: { ConfigureGooglePlayStoreLink },
});

<copyright>
File: general.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
div
  list
    list-item
      template(slot="label")
        .line-height-3x(:class="hostActive ? 'text-success' : 'text-danger'", :data-name="$name('status')")
          | {{ $t(hostStatusName) }}
      template(slot="value")
        btn(
          v-if="hostActive",
          size="small",
          color="red-stroke",
          :disabled="deactivating",
          @click.native="$emit('deactivateClick')",
          :data-name="$name('button-deactivate')"
        ).pull-right {{ $t('Deactivate') }}
        span(v-else) &nbsp;
    list-item(:data-name="$name('farm-id')")
      template(slot="label") {{ $t('Farm ID') }}
      template(slot="value") {{ host.farmGuid }}
    list-item(:data-name="$name('server-id')")
      template(slot="label") {{ $t('Server ID') }}
      template(slot="value") {{ host.hwId }}
    list-item(:data-name="$name('ip-address')")
      template(slot="label") {{ $t('Farm IP Address') }}
      template(slot="value") {{ host.ipAddress }}
    list-item(:data-name="$name('app-version')")
      template(slot="label") {{ $t('App. Version') }}
      template(slot="value") {{ host.productVersion }}
    list-item(v-if="hostActive")
      template(slot="label")
        template(v-if="isPostpaid") {{ $t('Current Activation Key') }}
        template(v-else) {{ $t('Activation Key') }}
      template(slot="value", v-if="host.subscription")
        router-link(
          :to="{ name: 'rasSubscriptionDetails', params: { id: host.subscription.parentUuid || host.subscription.uuid }}",
          :data-name="$name('link-subscription-details')"
        ) {{ isPostpaid ? host.universalKeyPostpaid : host.universalKeyPrepaid }}
      template(slot="value", v-else) {{ host.universalKey }}
    list-item(v-if="hostActive", :data-name="$name('activation-date')")
      template(slot="label") {{ $t('Activation Date') }}
      template(slot="value") {{ host.activationDate | dateTime }}
    list-item(:data-name="$name('last-report-date')")
      template(slot="label") {{ $t('Last Report Date') }}
      template(slot="value") {{ isPostpaid ? host.postpaidLastReportDate : host.prepaidLastReportDate | dateTime }}
    list-item(v-if="isPostpaid && host.subscription", :data-name="$name('peak-usage')")
      template(slot="label") {{ $t('Peak Usage') }}
      template(slot="value") {{ host.peak || 0 }}
  .row
    .col-xs-12.text-muted.margin-top-2x
      | {{ $t('Notes') }}
  .row.margin-top
    .col-xs-12
      textbox(:placeholder="$t('Enter farm notes')", type="text", :multiline="true", v-model="form.notes", :data-name="$name('textbox-notes')")
</template>
<script>
export default {
  name: 'farm-details-general',

  props: {
    host: {
      type: Object,
    },
    form: {
      type: Object,
      default () {
        return {
          notes: '',
        };
      },
    },
    deactivating: Boolean,
    isPostpaid: Boolean,
  },
  computed: {
    hostActive () {
      return this.isPostpaid ? this.host.activePostpaid : this.host.activePrepaid;
    },
    hostStatusName () {
      return this.isPostpaid ? this.host.statusNamePostpaid : this.host.statusNamePrepaid;
    },
  },
};
</script>

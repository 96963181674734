/**
 * File: resources.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

export enum Resources {
    PDFM_8_PERMANENT = 21,
    PDFM_8_STM_PERMANENT = 22,
    PDFM_9_PERMANENT = 25,
    PDFM_9_STM_PERMANENT = 26,
    PDFM_10_PERMANENT = 27,
    PDB_BY_SUBSCRIPTION = 29,
    PDFM_11_PRO_PERMANENT = 30,
    PDFM_11_PERMANENT = 31,
    PDFM_BY_SUBSCRIPTION = 32,
    PDFM_PRO_BY_SUBSCRIPTION = 33,
    PDFM_LITE_BY_SUBSCRIPTION = 34,
    PDFM_12_PERMANENT = 35,
    PDFM_UPGRADE_FLAG = 36,
    PDFM_UPGRADE_PROTECTION_FLAG = 37,
    PDFM_CHINA_ONLY_FLAG = 38,
    PDFM_VERSION__LT = 39,
    PDFM_VERSION__GT = 40,
    PDFM_13_PERMANENT = 41,
    PDFM_14_PERMANENT = 42,
    PDFC_BUSINESS_BY_SUBSCRIPTION = 43,
    PDFM_15_PERMANENT = 44,
    PDFC_BUSINESS_PERMANENT = 45,
    PDFM_16_PERMANENT = 46,
    PDFM_17_PERMANENT = 47,
    PDB_PER_USER_BY_SUBSCRIPTION = 48,
    PDFM_18_PERMANENT = 49,
    PAX_BY_SUBSCRIPTION = 50,
    PAX_FOR_BUSINESS_BY_SUBSCRIPTION = 51,
    PAX_SUPPORT_BY_SUBSCRIPTION = 52,
    MDM_BY_SUBSCRIPTION = 100,
    RAS_STANDARD_BY_SUBSCRIPTION = 150,
    RAS_STANDARD_SUPPORT = 151,
    RAS_PREMIUM_SUPPORT = 152,
    RAS_UPGRADE_INSURANCE = 153,
    RAS_EXTENDED_TRIAL = 154,
    RAS_PERMANENT = 155,
    RAS_PREMIUM_BY_SUBSCRIPTION = 156,
    RAS_MAX_VERSION = 157,
    RAS_IN_APP_TRIAL = 158,
    PTB_BASIC_PERMANENT = 200,
    PTB_FULL_BY_SUBSCRIPTION = 201,
    PTB_BUSINESS_BY_SUBSCRIPTION = 202,
    PTB_DOWNLOAD_VIDEO_TOOL_PERMANENT = 203,
    PTB_DO_NOT_SLEEP_TOOL_PERMANENT = 204,
    PTB_HIDE_DESKTOP_TOOL_PERMANENT = 205,
    PTB_CONVERT_VIDEO_TOOL_PERMANENT = 206,
    PTB_BASIC_V_2_0_PERMANENT = 207,
    PTB_BUSINESS_PER_USER_BY_SUBSCRIPTION = 208,
    PMM_BY_SUBSCRIPTION = 300,
    PMM_MDM_BY_SUBSCRIPTION = 301,
    PARTNER = 401,
    PER_INCIDENT_SUPPORT = 500
}

/**
 * File: rtReplyRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';
import RtTicketsRequest from './rtTicketsRequest';

export default class RtReplyRequest extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {Number} params.ticketId
   * @param {String} params.text
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  /**
   * @returns {String}
   */
  get uri () {
    return `/ticket/${this.params.ticketId}/correspond`;
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      body: this.params.text,
    };
  }

  load () {
    return super.load()
      .then((data) => {
        new RtTicketsRequest().dropFullCache();
        return data;
      });
  }
}

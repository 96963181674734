/**
 * File: addKeyPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue, { PropType } from 'vue';

import AddKeyModal from './addKeyModal.vue';
import RestorePurchasesModal from './restorePurchasesModal.vue';
import ComponentMixin from '@/modules/componentMixIn';
import GetSocialBindingsRequest, { SocialBindingEntry } from '@/api/getSocialBindingsRequest';
import { ADD_KEY_PAGE, DOWNLOADS_PAGE } from '@/routes/constants';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'add-key-page',
  props: {
    appData: {
      type: Object as PropType<AppData>,
      required: true,
    },
  },
  mixins: [ComponentMixin],
  // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  components: { AddKeyModal, RestorePurchasesModal, Breadcrumbs },
  data () {
    return {
      socialBindings: [] as SocialBindingEntry[],
    };
  },
  computed: {
    subnavigation (): { text: string; link: { name: string } }[] {
      const items = [
        {
          text: this.$t('Downloads'),
          link: {
            name: DOWNLOADS_PAGE,
          },
        },
      ];

      if (!this.appData.products.personal.length && !this.appData.products.business.length) {
        items.unshift({
          text: this.$t('Register Key'),
          link: {
            name: ADD_KEY_PAGE,
          },
        });
      }

      return items;
    },
    showRestorePurchasesButton (): boolean {
      return this.socialBindings.length && !this.appData.products.personal.length && !this.appData.products.business.length;
    },
  },
  async mounted () {
    const data = await this.authorizedCall(new GetSocialBindingsRequest(), 'loading');
    this.socialBindings = data.social_bindings;

    if (this.$router.currentRoute.query.restorePurchases && this.showRestorePurchasesButton) {
      this.$modal.show('restorePurchasesPreamble');
    }
  },
});

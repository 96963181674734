<copyright>
File: socialRegister.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import SocialRegisterRequest from '@/api/socialRegisterRequest';
import UserConsent from '@/modules/userConsent';
import SessionStatus from '@/modules/sessionExpiration/status';

export default {
  name: 'social-register-page',
  components: {
    UserConsent,
  },
  data () {
    return {
      loading: false,
      consent: {
        id: undefined,
        required: true,
        newAccount: true,
      },
      sessionExpired: SessionStatus.isExpired(),
      credentials: undefined,
    };
  },
  mounted () {
    this.loading = true;

    this.$api.refreshToken().then(() => {
      if (this.$appData.session && !this.$appData.session.isGuest) {
        this.$router.push({
          name: 'index',
        });
        return;
      }
      return this.$api.getConsent({ dryRun: true });
    }).then((data) => {
      if (data) {
        this.consent.required = data.consent_required;
        this.loading = false;
      }
    }).catch(() => {
      this.loading = false;
    });

    this.setSessionExpirationStatus(false);
  },
  methods: {
    async onAccept (consent) {
      this.consent.id = consent.id;
      this.consent.required = !!consent.required;

      try {
        await this.$api.call(new SocialRegisterRequest({ key: this.$route.query.key, consentId: this.consent.id }));
      } catch (e) {
        this.$toast.show({
          color: 'red',
          text: $t('Register via social service failed.'),
        });
      }
      await this.$api.refreshToken(true);

      this.$appData.isNewUser = true;
      this.$router.push({ name: 'index' });
    },
    setSessionExpirationStatus (status = false) {
      SessionStatus.setExpiration(status);
    },
  },
  watch: {
    mode (mode) {
      this.$router.push({
        name: mode,
      });
    },
  },
};

</script>

<template lang="pug">

page(footer="switcher")
  template(slot="pageContent")
    user-consent(:embedded="true", :consent="consent", @accept="onAccept", @back="$router.push({name: 'login'})")

</template>



import Vue, { PropType } from 'vue';
import FarmDetailsModal from '@/modules/ras/farmDetails/farmDetailsModal.vue';
import PeriodSelector from '@/modules/ras/periodSelector.vue';
import { Dictionary } from '@core/common/types';
import { Option } from '@core/common/utils';
import RasPrepaidFarmsRequest from '@/api/rasPrepaidFarmsRequest';
import FarmsTable from '@/modules/ras/farmsTable/farmsTable.vue';
import RasHostsRequestV2 from '@/apiv2/rasHostsRequestV2';
import { UsageReport } from '@/modelsv2/usageReport';
import RasHost from '@/models/rasHost';
import RasSubscription from '@/models/rasSubscription';
import { BillingPeriod } from '@/modelsv2/billingPeriod';
import { getChunks, snakeToCamel } from '@/apiv2/apiHelpers';

const DISPLAY_MODE_ALL = 'all';
const DISPLAY_MODE_ACTIVE = 'active';
const DISPLAY_MODE_DEACTIVATED = 'deactivated';

type BillingPeriodId = number;

export default Vue.extend({
  name: 'farms-page',
  props: {
    isSpla: {
      type: Boolean,
      required: true,
    },
    subscriptions: {
      type: Array as PropType<RasSubscription[]>,
      required: true,
    },
  },
  components: {
    FarmDetailsModal,
    PeriodSelector,
    FarmsTable,
  },
  data () {
    return {
      loading: false,
      totalHosts: null,
      filters: {},
      loadedFarms: {} as Record<BillingPeriodId, RasHost[]>,
      loadingHosts: true,
      status: DISPLAY_MODE_ALL as string,
      totalUsage: 0,
      subscriptionUuid: null as string,
      billingPeriodId: null as BillingPeriodId,
      billingPeriods: [] as BillingPeriod[],
      uniqueReports: [] as Record<number, { periods: UsageReport[]; peakUsage: number }>,
      subscriptionUuids: [] as string[],
    };
  },
  created () {
    this.load();
  },
  computed: {
    allFarms: {
      get (): Dictionary<RasHost[]> {
        return {
          [DISPLAY_MODE_ALL]: this.loadedFarms[this.billingPeriodId] || [],
          [DISPLAY_MODE_ACTIVE]: (this.loadedFarms[this.billingPeriodId] || []).filter((farm) => farm.active),
          [DISPLAY_MODE_DEACTIVATED]: (this.loadedFarms[this.billingPeriodId] || []).filter((farm) => !farm.active),
        };
      },
      set (farms: RasHost[]) {
        this.loadedFarms = {
          ...this.loadedFarms,
          [this.billingPeriodId]: [...(this.loadedFarms[this.billingPeriodId] || []), ...farms],
        };
      },
    },
    farms (): RasHost[] {
      return this.allFarms[this.status];
    },
    showAll (): boolean {
      return this.status === DISPLAY_MODE_ALL;
    },
    statusOptions (): Option[] {
      const options = [{
        text: this.$t('All ({count})', {
          count: this.allFarms[DISPLAY_MODE_ALL].length,
        }) as string,
        value: DISPLAY_MODE_ALL,
      }, {
        text: this.$t('Active ({count})', {
          count: this.allFarms[DISPLAY_MODE_ACTIVE].length,
        }) as string,
        value: DISPLAY_MODE_ACTIVE,
        disabled: !this.allFarms[DISPLAY_MODE_ACTIVE].length,
      }];

      if (this.$appData.session.isBusinessAdmin) {
        // $t('Inactive ({count})')
        // $t('Deactivated ({count})')
        const deactivatedText = this.isSpla ? 'Inactive ({count})' : 'Deactivated ({count})';
        options.push({
          text: this.$t(deactivatedText, {
            count: this.allFarms[DISPLAY_MODE_DEACTIVATED].length,
          }) as string,
          value: DISPLAY_MODE_DEACTIVATED,
          disabled: !this.allFarms[DISPLAY_MODE_DEACTIVATED].length,
        });
      }

      return options;
    },
    subscriptionStatusOptions (): Option[] {
      if (this.status === DISPLAY_MODE_ACTIVE) {
        return [
          {
            text: this.$t('Expiring Soon ({count})') as string,
            value: 'expiring',
            filter (entry) {
              return entry.subscription && entry.subscription.isExpiringSoon;
            },
          },
          {
            text: this.$t('Expired ({count})') as string,
            value: 'expired',
            filter (entry) {
              return entry.subscription && entry.subscription.isExpired;
            },
          },
        ];
      }

      return [];
    },
    deactivationEnabled (): boolean {
      return this.status === DISPLAY_MODE_ALL || this.status === DISPLAY_MODE_ACTIVE;
    },
  },
  methods: {
    load () {
      if (!this.isSpla) {
        this.loadPrepaid();
      }
    },
    showFarmDetailsModal (farm) {
      // @ts-ignore todo: replace with new modal interfaces
      this.$refs.farmDetailsModal.show(farm, { billingPeriodId: this.billingPeriodId, billingPeriods: this.billingPeriods });
    },
    onHostUpdated (value) {
      const index = this.farms.findIndex((farm) => farm.hwId);
      if (index > -1) {
        this.$set(this.farms, index, value);
      }
    },
    reload () {
      if (this.isSpla) {
        this.loadPostpaid({ periodId: this.billingPeriodId, subscriptionUuid: this.subscriptionUuid });
      } else {
        this.loadPrepaid();
      }
    },
    loadPrepaid () {
      const farmsRequest = new RasPrepaidFarmsRequest();

      this.loading = true;

      this.$api.authorizedCall(farmsRequest).then(() => {
        this.allFarms = farmsRequest.getHosts().map((farm) => {
          farm.subscription = this.subscriptions.find((subscription: RasSubscription) => subscription.licKey === farm.universalKey);
          return farm;
        });
      }).finally(() => {
        this.loading = false;
        this.loadingHosts = false;
      });
    },
    onHostsReceived (hosts, currentBillingPeriod) {
      const newFarms: RasHost[] = hosts.map((host) => {
        host.usedAsPostpaid = true;
        host.peak = currentBillingPeriod.hostsUsages.find((hostUsage) => hostUsage.hostId === host.id).maxUsage;
        host.farm_guid = host.farmGuid || host.hwId;
        const rasHost = new RasHost(host);
        rasHost.subscription = this.subscriptions.find((subscription: RasSubscription) => subscription.uuid === host.subscriptionUuid);
        rasHost.historySubscription = this.subscriptions.find((subscription: RasSubscription) => subscription.parentUuid === host.subscriptionUuid) || rasHost.subscription;
        return rasHost;
      });
      this.loadingHosts = false;
      if (currentBillingPeriod.id === this.billingPeriodId) {
        this.allFarms = newFarms;
      }
    },
    loadPostpaid (params: { periodId?: number; subscriptionUuid?: string; subsetUuids?: string[]; billingPeriods?: BillingPeriod[] } = {}) {
      this.billingPeriodId = params.periodId;
      this.subscriptionUuid = params.subscriptionUuid;
      this.subscriptionUuids = params.subsetUuids;
      this.billingPeriods = params.billingPeriods;
      const currentBillingPeriod = this.billingPeriods.find((period) => period.id === params.periodId);
      this.totalHosts = currentBillingPeriod.hosts;
      this.totalUsage = currentBillingPeriod.maxUsage;
      if (this.allFarms[DISPLAY_MODE_ALL].length === this.totalHosts) {
        this.loadingHosts = false;
        return;
      }
      this.loadedFarms[this.billingPeriodId] = [];
      if (params.periodId === undefined || !params.subscriptionUuid) {
        this.totalUsage = 0;
        this.loadingHosts = false;
        return;
      }
      this.loadingHosts = true;
      const hostsIds = currentBillingPeriod.hostsUsages.map((hostUsage) => hostUsage.hostId);
      const hostsIdsChunks = getChunks<number>(hostsIds);
      hostsIdsChunks.forEach(async (chunk) => {
        const request = new RasHostsRequestV2({ hosts: chunk, id: params.periodId });
        const result = await this.$api.authorizedCall(request);
        this.onHostsReceived(snakeToCamel(result.results), currentBillingPeriod);
      });
    },
    clearHosts () {
      this.loadedFarms = {};
      this.totalHosts = 0;
      this.totalUsage = 0;
    },
    changedSubscriptions () {
      this.clearHosts();
      this.loadingHosts = true;
    },
  },
  watch: {
    billingPeriodId: {
      handler (value) {
        if (this.isSpla && !value) {
          this.loadingHosts = !value;
        }
      },
    },
  },
});


<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'banner-notification',
  props: {
    color: {
      type: String,
    },
  },
};

</script>

<template lang="pug">

notification(:closeButton="false", :color="color")
  .wrapper
    slot

</template>

<style scoped lang="sass">

.wrapper
  display: flex
  align-items: center
  width: 100%

</style>

/**
 * File: getPackagedLicensesFailedDetailsRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import { camelize } from '@core/common/utils';

interface GetPackagedLicensesFailedDetailsRequestParams {
  trackerId: string;
}

interface GetPackagedLicensesFailedDetailsRequestResponse {
  downloadToken: string;
}

export default class GetPackagedLicensesFailedDetailsRequest extends ApiRequest<GetPackagedLicensesFailedDetailsRequestParams, GetPackagedLicensesFailedDetailsRequestResponse> {
  get url () {
    return `/license/api/v1/packaged_licenses/${this.params.trackerId}/get_failed_details`;
  }

  onFetchSuccess (data) {
    return camelize(data);
  }
}



import Vue, { PropType } from 'vue';
import {
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_PMM, PRODUCT_KEY_NAME_PDB_PER_USER
} from '@core/constants/subscriptions';
import { FEATURE_CONFIGURATION_PROFILE_PER_SUBSCRIPTION } from '@core/constants/features';
import DeleteSubsetRequest from '@/api/deleteSubsetRequest';
import PmmSitesRequest from '@/api/pmmSitesRequest';
import RasPrepaidFarmsRequest from '@/api/rasPrepaidFarmsRequest';
import UniversalKey from '@/models/universalKey';
import GeneralTab from './generalTab.vue';
import ConfigurationProfileTab from './configurationProfileTab.vue';
import PermissionsTab from './permissionsTab.vue';
import NotificationsTab from './notificationsTab/index.vue';
import AutomaticLicensesRevocationTab from './automaticLicensesRevocationTab.vue';
import Subscription from '@/models/subscription';

export enum LicenseKeyModalTab {
  General = 'general',
  Profile = 'profile',
  Permissions = 'permissions',
  Notifications = 'notifications',
  AutomaticRevocation = 'automatic_revocation'
}

type Tabs = {
  [key in LicenseKeyModalTab]?: string
};

export interface SubscriptionModalOptions {
  subscription: Subscription;
  parent?: Subscription;
  nextSubsetNumber?: number;
}

export default Vue.extend({
  name: 'subscription-modal',

  components: {
    GeneralTab,
    ConfigurationProfileTab,
    PermissionsTab,
    NotificationsTab,
    AutomaticLicensesRevocationTab,
  },

  props: {
    nextSubsetNumber: {
      type: Number,
      default: 0,
    },
    subscription: {
      type: Object as PropType<Subscription>,
      default () {
        return new Subscription({});
      },
    },
    primaryKey: {
      type: Object as PropType<UniversalKey>,
    },
    isDownloadAvailable: {
      type: Boolean,
    },
  },

  data () {
    return {
      loading: false,
      name: '',
      subset: null as UniversalKey,
      tab: LicenseKeyModalTab.General,
      LicenseKeyModalTab,
      restrictAmount: false,
      promises: [] as Array<Promise<any>>,
      uuid: null as string,
      deleteMode: false,
      isLicenceDownloading: false,
      rasDeactivatedFarm: '',
      pmmDeactivatedSite: '',
      PRODUCT_KEY_NAME_PDB,
      PRODUCT_KEY_NAME_RAS,
      PRODUCT_KEY_NAME_PMM,
    };
  },

  methods: {
    reset () {
      this.subset = null;
      this.promises = [];
      this.deleteMode = false;
      if (this.$refs.configurationProfileTab) {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.configurationProfileTab.reset();
      }
    },

    show (subset = null) {
      this.subset = subset;
      if (this.subset) {
        this.name = this.subset.name;
        this.restrictAmount = this.subset.isLimited;
      } else {
        this.name = `License Key ${this.nextSubsetNumber}`;
        this.restrictAmount = false;
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.generalTab.updateForm();
        if (this.tabs.hasOwnProperty('permissions')) {
          // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
          this.$refs.permissionsTab.reset();
        }
      }
      this.promises = [];
      this.tab = LicenseKeyModalTab.General;
      this.$modal.show('subsetSettings');
    },

    save () {
      if (this.$refs.editableHeader) {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.editableHeader.confirm();
      }

      // Waiting for subset name to be saved
      this.$nextTick(() => {
        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
        const promise = this.$refs.generalTab.save();

        if (promise) {
          this.loading = true;
          promise.then((data) => {
            if (this.subset) {
              this.uuid = this.subset.subsetUuid || this.subset.subscriptionUuid;
            } else {
              this.uuid = data.subset_uuid;
            }
            this.$bus.$emit('subsetSaved', {
              uuid: this.uuid,
            });
          }).catch(() => {
            this.promises = [];
            this.loading = false;
          });
        }
      });
    },

    deleteSubset () {
      if (!this.deleteMode && this.subset.usage > 0) {
        if (this.subscription.hasProduct(PRODUCT_KEY_NAME_PMM)) {
          this.loadPmmDeactivatedSite();
        } else if (this.subscription.hasProduct(PRODUCT_KEY_NAME_RAS) && !this.subscription.isRasPostpaid) {
          this.loadRasDeactivatedFarm();
        }

        this.deleteMode = true;

        return;
      }

      const request = new DeleteSubsetRequest({
        uuid: this.subscription.uuid,
        service: this.subscription.targetServiceName,
        subsetUuid: this.subset.subsetUuid,
      });

      return this.$api.authorizedCall(request).then(() => {
        this.$emit('changeSubset');
        this.$toast.show({
          text: this.$t('{key_name} has been deleted.', { key_name: this.name }),
          color: 'orange',
        });
        this.reset();
        this.$modal.hide();
      });
    },

    loadPmmDeactivatedSite () {
      const request = new PmmSitesRequest();
      this.$api.authorizedCall(request)
        .then(
          () => {
            const site = request.getSites().find((item) => {
              return item.subscription && item.licKey === this.subset.licKey;
            });
            if (site) {
              this.pmmDeactivatedSite = site.name;
            }
          }
        );
    },

    loadRasDeactivatedFarm () {
      const request = new RasPrepaidFarmsRequest();
      this.$api.authorizedCall(request)
        .then(
          () => {
            const farm = request.getHosts().find((item) => {
              return item.universalKey === this.subset.licKey;
            });
            if (farm) {
              this.rasDeactivatedFarm = farm.name;
            }
          }
        );
    },

    onCancelClick () {
      this.reset();
      this.$modal.hide();
    },

    updateAmountRestriction (params) {
      this.restrictAmount = params.restrictAmount;
    },

    onTabSave (promise) {
      this.promises.push(promise);
    },

    onSave () {
      if (this.subset) {
        this.$toast.show({
          text: $t('{key_name} has been updated.', { key_name: this.name }),
        });
      } else {
        this.$toast.show({
          text: this.$t('A new license key has been created'),
          button: this.canInviteUsers ? this.$t('Invite Users') : undefined,
          closeButton: false,
          click: () => {
            this.$bus.$emit('invitePdUsers', {
              subsetUuid: this.uuid,
            });
          },
        });
      }
      this.$modal.hide();
      this.reset();
      this.$emit('changeSubset');
    },

    setTab (params) {
      this.tab = params.tab;
    },

    onDownloadClick () {
      this.isLicenceDownloading = true;
      this.$emit('downloadLicense', this.primaryKey, () => { this.isLicenceDownloading = false; });
    },
  },

  computed: {
    isPrimaryKey (): boolean {
      return this.subset && this.subset.isPrimary;
    },

    canInviteUsers (): boolean {
      return this.subscription.hasProduct(PRODUCT_KEY_NAME_PDB);
    },

    canManagePermissions (): boolean {
      return this.subscription.permissionsManageable;
    },

    canManageNotifications (): boolean {
      return this.subscription.isRasPostpaid;
    },

    canManageConfigurationProfile (): boolean {
      return this.$appData.session.isBusinessAdmin &&
        (this.subscription.isPDE || this.subscription.hasProduct(PRODUCT_KEY_NAME_PDB) || this.subscription.hasProduct(PRODUCT_KEY_NAME_PDB_PER_USER)) &&
        this.$appData.session.isFeatureAccessible(FEATURE_CONFIGURATION_PROFILE_PER_SUBSCRIPTION);
    },

    canManageAutomaticRevocation (): boolean {
      return this.$appData.session.isBusinessAdmin && this.subscription.hasProduct(PRODUCT_KEY_NAME_PDB_PER_USER);
    },

    tabs (): Tabs {
      const tabs: Tabs = {
        [LicenseKeyModalTab.General]: this.$t('General'),
      };

      if (this.canManageConfigurationProfile) {
        tabs[LicenseKeyModalTab.Profile] = this.$t('Configuration Profile');
      }

      if (this.canManageAutomaticRevocation) {
        tabs[LicenseKeyModalTab.AutomaticRevocation] = this.$t('Automatic License Revocation');
      }

      if (this.canManagePermissions) {
        tabs[LicenseKeyModalTab.Permissions] = this.$t('License Managers');
      }
      if (this.canManageNotifications) {
        tabs[LicenseKeyModalTab.Notifications] = this.$t('Reports');
      }

      return tabs;
    },
  },

  watch: {
    async promises (promises) {
      if (promises.length === Object.keys(this.tabs).length) {
        try {
          await Promise.all(promises);
          this.onSave();
        } finally {
          this.loading = false;
          this.promises = [];
        }
      }
    },
  },
});

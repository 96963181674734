/**
 * File: contractDownloadUrlRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class ContractDownloadUrlRequest extends ApiRequest {
  get cacheNameSpace (): string {
    return '';
  }

  get url (): string {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/sf/contract_agreement`);
  }

  // eslint-disable-next-line camelcase
  load (): Promise<{ download_link: string }> {
    return super.load()
      // eslint-disable-next-line camelcase
      .then((data: { download_link: string }) => {
        if (data && data.download_link) {
          data.download_link = data.download_link.replace(/^\//, `/${this.params.service}/`);
        } else {
          throw new Error('Error receiving contract details');
        }
        return data;
      });
  }
}

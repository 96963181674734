/**
 * File: profileMixinGeneral.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue, { PropType } from 'vue';
import { State, STATES } from '@core/constants/geo';
import { getCountriesWithEmbargoed } from '@/plugins/sets';
import Session from '@/models/session';

const RESTRICTED_FIELDS = [
  'company_name',
  'address_line1',
  'address_line2',
  'phone',
  'city',
  'zip',
  'state',
] as const;

type RestrictedFieldsType = {
  [key in typeof RESTRICTED_FIELDS[number]]?: boolean
} & { 'country'?: boolean }

export default Vue.extend({
  props: {
    session: Object as PropType<Session>,
  },

  data () {
    return {
      data: {
        country: undefined as string,
        state: undefined as string,
        restricted_fields: [],
        city: null as string,
      },
      form: {
        country: '',
        state: '',
      },
      addressLine2: '',
    };
  },
  mounted () {
    this.updateAddressLine2();
  },
  methods: {
    updateAddressLine2 () {
      const addressLineParts = [];
      const country = this.data.country && this.data.country.toUpperCase();
      const state = this.data.state && this.data.state.toUpperCase();

      if (this.data.city && !this.restrictedFields.city) {
        addressLineParts.push(this.data.city);
      }
      if (country && state && STATES[country] && !this.restrictedFields.state) {
        const stateName = STATES[country][state];
        addressLineParts.push(stateName);
      }
      if (country && !this.restrictedFields.country) {
        const countryName = getCountriesWithEmbargoed(country)[country] || country;
        addressLineParts.push(countryName);
      }

      this.addressLine2 = addressLineParts.join(', ');
    },
  },
  computed: {
    countriesList (): State {
      return getCountriesWithEmbargoed(this.session.country);
    },

    statesList (): State {
      if (this.form.country) {
        return STATES[this.form.country.toUpperCase()];
      }
      return null;
    },

    showStates (): boolean {
      return this.form.country && STATES.hasOwnProperty(this.form.country.toUpperCase());
    },

    restrictedFields (): RestrictedFieldsType {
      let restrictedFields = this.data.restricted_fields;

      if (!restrictedFields) {
        // There is no restricted fields in a business profile
        return {};
      } else if (this.form.country && this.form.country.toUpperCase() === 'RU') {
        // Hide restricted fields if Russia is selected
        restrictedFields = restrictedFields.concat(RESTRICTED_FIELDS);
      }

      return restrictedFields.reduce((fields, item) => {
        fields[item] = true;
        return fields;
      }, {});
    },
  },
  watch: {
    'form.country': function () {
      const { country, state } = this.form;
      if (state && country && (!STATES[country] || !STATES[country][state])) {
        this.form.state = undefined;
      }
    },
    'session.locale': function () {
      this.updateAddressLine2();
    },
    'data.country': function () {
      this.updateAddressLine2();
    },
  },
});


import Vue from 'vue';

import OneTimePasswordMixin from '@/modules/oneTimePasswordMixin';

export default Vue.extend({
  name: 'update-two-step-verification-settings-with-otp-modal',
  props: {
    switchMfaMode: Boolean,
  },

  mixins: [OneTimePasswordMixin],

});


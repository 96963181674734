

import ListBusinessDomainsRequest from '@/api/listBusinessDomainsRequest';
import InvitationCard from './invitationCard.vue';
import UserConfirmEmailRequest from '@/api/userConfirmEmailRequest';
import ComponentMixin from '@/modules/componentMixIn';
import JoinBusinessAccountRequest from '@/api/joinBusinessAccountRequest';
import Vue from 'vue';
import { DASHBOARD_PAGE } from '@/routes/constants';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import ConfirmEmailModal from '@/modules/confirmEmailModal/confirmEmailModal.vue';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

const ERRORS = 'non_field_errors';
const ACCOUNT_CONFIRMED = 'account already confirmed';

export default Vue.extend({
  name: 'invitations-list',
  mixins: [ComponentMixin, BreadcrumbsMixin],
  components: {
    InvitationCard,
    ConfirmEmailModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },
  props: {
    domainId: {
      type: Number,
    },
  },
  data () {
    return {
      loading: false,
      invitations: [],
      currentInvitation: null,
      currentDomainId: null,
    };
  },
  mounted () {
    this.load();
  },
  methods: {
    async onAcceptInvitation (params) {
      if (!this.$appData.session.confirmed) {
        try {
          const result = await this.authorizedCall(new UserConfirmEmailRequest({ domainId: params.domainId }));
          if (result) {
            this.currentDomainId = params.domainId;
            this.$modal.show(ConfirmEmailModal, $t('You must confirm your email before joining a business account of your company or organization. We have sent a confirmation email to {mailAddr}. Please follow the instructions from it to proceed.', { mailAddr: this.$appData.session.email }));
          }
        } catch (error) {
          const message = await error.response.json();
          if (message[ERRORS][0] === ACCOUNT_CONFIRMED) {
            this.$appData.session.setConfirmed(true);
            this.joinAccountAndMoveToDashboard(params);
          }
        }
      } else {
        this.joinAccountAndMoveToDashboard(params);
      }
    },
    joinAccountAndMoveToDashboard (params: { domainId: number }) {
      this.loading = true;
      const request = new JoinBusinessAccountRequest({ domainId: params.domainId });

      this.$api.authorizedCall(request)
        .then(() => this.$api.refreshToken(true))
        .then(() => this.onConfirm(params))
        .then(() => { this.$router.push({ name: DASHBOARD_PAGE }); })
        .finally(() => { this.loading = false; });
    },
    onConfirm (params: { domainId: number }) {
      const invitation = this.invitations.find((domain) => domain.id === params.domainId);
      if (invitation) {
        invitation.is_invite = false;
      }
    },
    load () {
      this.loading = true;

      const request = new ListBusinessDomainsRequest();

      this.$api.authorizedCall(request).then((data) => {
        this.invitations = data.domains.filter((domain) => domain.is_invite);
      }).finally(() => {
        this.loading = false;
        if (this.domainId) {
          this.onAcceptInvitation({ domainId: this.domainId });
        }
      });
    },
    requestEmail () {
      this.authorizedCall(new UserConfirmEmailRequest({ domainId: this.currentDomainId }));
    },
  },
});

<copyright>
File: socialButtons.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import AppleLoginRequest from '@/api/appleLoginRequest';
import FacebookLoginRequest from '@/api/facebookLoginRequest';
import GoogleLoginRequest from '@/api/googleLoginRequest';

import {
  FEATURE_SHOW_CONTINUE_WITH_SSO,
  FEATURE_SIGN_IN_WITH_APPLE,
  FEATURE_SIGN_IN_WITH_FACEBOOK,
  FEATURE_SIGN_IN_WITH_GOOGLE
} from '@core/constants/features';
import { SIWA_SDK_SCRIPT_URL } from '@core/constants/links';
import { parseQueryString } from '@core/common/url';
import { ios } from '@core/common/browser';

function loadScript (src) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve(src);
    }

    const script = document.createElement('script');
    document.head.appendChild(script);
    script.addEventListener('load', function () {
      resolve(src);
    });
    script.src = src;
  });
}

export default {
  name: 'social-buttons',

  props: {
    service: String,
    continue: String,
  },

  data () {
    return {
      services: {
        apple: {
          Request: AppleLoginRequest,
          loading: false,
        },
        facebook: {
          Request: FacebookLoginRequest,
          loading: false,
        },
        google: {
          Request: GoogleLoginRequest,
          loading: false,
        },
      },
    };
  },
  computed: {
    isAppleLoginSupported () {
      return this.isFeatureAccessible(FEATURE_SIGN_IN_WITH_APPLE);
    },
    isFacebookLoginSupported () {
      return this.isFeatureAccessible(FEATURE_SIGN_IN_WITH_FACEBOOK);
    },
    isGoogleLoginSupported () {
      return this.isFeatureAccessible(FEATURE_SIGN_IN_WITH_GOOGLE);
    },
    isSSOModeEnabled () {
      return this.$router.currentRoute.query.sso || this.isFeatureAccessible(FEATURE_SHOW_CONTINUE_WITH_SSO);
    },
    loading () {
      return Object.keys(this.services).some((name) => this.services[name].loading);
    },
  },
  methods: {
    isFeatureAccessible (featureName) {
      return this.$appData.session && this.$appData.session.isFeatureAccessible(featureName);
    },
    login (name) {
      const service = this.services[name];
      const request = new service.Request({
        service: this.service,
        continue: this.continue,
      });

      service.loading = true;

      this.$api.call(request)
        .then((data) => {
          if (data && data.url) {
            if (request instanceof AppleLoginRequest) {
              this.signInWithApple(data.url);
            } else {
              window.location = data.url;
            }
          } else {
            service.loading = false;
          }
        }).catch(() => {
          service.loading = false;
        });
    },
    signInWithApple (url) {
      loadScript(SIWA_SDK_SCRIPT_URL).then(() => {
        // Hide loader on Cancel button for SIWA in MacOS Safari >= 13
        const focusHandler = () => {
          this.services.apple.loading = false;
          window.removeEventListener('focus', focusHandler);
          if (ios) {
            document.removeEventListener('touchstart', focusHandler);
          }
        };
        window.addEventListener('focus', focusHandler);

        const params = parseQueryString(url);
        /* global AppleID */
        AppleID.auth.init({
          clientId: params.client_id,
          scope: params.scope,
          redirectURI: params.redirect_uri,
          state: params.state,
        });

        AppleID.auth.signIn();

        // It is impossible to catch cancel button in SIWA iOS dialog
        // so we emit event about it and reset loaders on touch
        if (ios) {
          document.addEventListener('touchstart', focusHandler);
          setTimeout(() => {
            this.$emit('iosSignIn');
          }, 2000);
        }
      });
    },
  },
  watch: {
    loading (value) {
      this.$emit('submit', value);
    },
  },
};

</script>

<template lang="pug">

div
  .line-height-3x.margin-bottom-2x.text-center {{ $t('Other sign-in options:') }}
  .margin-bottom-2x(v-if="isAppleLoginSupported")
    btn.social(
      @click="login('apple')",
      :disabled="loading",
      :data-name="$name('link-apple-login')",
      color="white"
    )
      .icon(:class="{ apple: !services.apple.loading }")
        loader(v-if="services.apple.loading", :loading="true", :size="2")
      span {{ $t('Continue with Apple') }}
  .margin-bottom-2x(v-if="isFacebookLoginSupported")
    btn.social(
      @click="login('facebook')",
      :disabled="loading",
      :data-name="$name('link-facebook-login')",
      color="white"
    )
      .icon(:class="{ facebook: !services.facebook.loading }")
        loader(v-if="services.facebook.loading", :loading="true", :size="2")
      span {{ $t('Continue with Facebook') }}
  .margin-bottom-2x(v-if="isGoogleLoginSupported")
    btn.social(
      @click="login('google')",
      :disabled="loading",
      :data-name="$name('link-google-login')",
      color="white"
    )
      .icon(:class="{ google: !services.google.loading }")
        loader(v-if="services.google.loading", :loading="true", :size="2")
      span {{ $t('Continue with Google') }}
  div(v-if="isSSOModeEnabled")
    btn.social(
      @click="$emit('signInWithSso')",
      :disabled="loading",
      :data-name="$name('link-sso-login')",
      color="white"
    )
      span {{ $t('Continue with SSO') }}
</template>

<style scoped lang="sass">

.social
  position: relative
  display: block !important
  width: 100%
  padding-left: $grid-step * 3
  padding-right: $grid-step * 2
  .icon
    position: absolute
    left: $grid-step
    top: 50%
    transform: translateY(-50%)
    width: 24px
    height: 24px
    background-repeat: no-repeat
    background-position: center center
    background-size: 24px
    @each $service in (apple, facebook, google)
      &.#{ $service }
        background-image: url('~@/assets/#{ $service }-logo.svg')
    &.facebook
      background-size: 20px

</style>

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import DurationFormatter from '@/ui/durationFormatter';
import MaintenanceModeRequest from '@/api/maintenanceModeRequest';
import { getDuration } from '@core/common/datetime';
import { SCHEDULED_MAINTENANCE, SUPPORT_MAINTENANCE } from '@core/constants/maintenance';
import { GET_SUPPORT_PAGE } from '@/routes/constants';

export default {
  name: 'maintenance-info',
  components: {
    DurationFormatter,
  },
  data () {
    return {
      loading: false,
      data: {},
      SCHEDULED_MAINTENANCE,
      SUPPORT_MAINTENANCE,
      GET_SUPPORT_PAGE,
    };
  },
  created () {
    const request = new MaintenanceModeRequest();
    this.loading = true;

    request.load().then((data) => {
      if (data && data.type) {
        this.data = data;
      }
    }).finally(() => {
      if (!this.data.type) {
        this.$router.push({
          name: 'dashboard',
        });
      } else {
        this.loading = false;
      }
    });
  },
  computed: {
    downtimeOptimistic () {
      return getDuration(this.data.downtime_optimistic);
    },
    downtimePessimistic () {
      return getDuration(this.data.downtime_pessimistic);
    },
    downtimeText () {
      if (this.data.downtime_optimistic === this.data.downtime_pessimistic) {
        return this.$t('We anticipate this planned maintenance to take up to {downtimeOptimistic}.');
      } else {
        return this.$t('We anticipate this planned maintenance to take from {downtimeOptimistic} to {downtimePessimistic}.');
      }
    },
  },
};

</script>

<template lang="pug">

page(:appData="$appData")
  template(slot="pageContent")
    card
      loader(:loading="loading")
        template(v-if="data.type === SCHEDULED_MAINTENANCE")
          h2 {{ $t('Scheduled Maintenance') }}
          .margin-bottom {{ $t('Dear customers!') }}
          .margin-bottom
            formatter(text="{info} {details}")
              template(slot="info") {{ $t('On {startDate} we’re performing major infrastructure upgrades for Parallels My Account services.', { startDate: formatDateTime(data.start_date) }) }}
              formatter(slot="details", :text="downtimeText")
                duration-formatter(slot="downtimeOptimistic", :duration="downtimeOptimistic")
                duration-formatter(slot="downtimePessimistic", :duration="downtimePessimistic")
          .margin-bottom {{ $t('During this maintenance period My Account services will be inaccessible, including activating your Parallels products, registering new Parallels product license keys, managing your Parallels products in My Account and accessing your remote computers via Parallels Access.') }}
          .margin-bottom {{ $t('Parallels products that were activated prior to this maintenance period are expected to continue to work as usual.') }}
          .margin-bottom {{ $t('All operations will resume immediately after the maintenance is complete.') }}
          .margin-bottom {{ $t('We appreciate your patience and understanding.') }}
          .margin-bottom
            formatter(:text="$t('If you have questions or issues, please {link}contact Parallels Support{/link}.')")
              router-link(:to="{ name: GET_SUPPORT_PAGE }", slot="link", slot-scope="s") {{ s.text }}
        template(v-else-if="data.type === SUPPORT_MAINTENANCE")
          h2 {{ $t('Scheduled Maintenance') }}
          .margin-bottom {{ $t('Dear customers!') }}
          .margin-bottom
            formatter(text="{info} {details}")
              template(slot="info") {{ $t('Parallels telephony services will be undergoing planned maintenance on {startDate}.', { startDate: formatDateTime(data.start_date) }) }}
              formatter(slot="details", :text="$t('Maximum expected downtime is {downtimePessimistic}.')")
                duration-formatter(slot="downtimePessimistic", :duration="downtimePessimistic")
          .margin-bottom {{ $t('During this time, incoming and outgoing calls will not be possible. Please use alternative methods to get in touch with Parallels Support during the maintenance window.') }}
          .margin-bottom {{ $t('We apologize for any inconvenience and thank you in advance for your patience and cooperation as we undertake these maintenance activities and service upgrades.') }}
          .margin-bottom {{ $t('The Parallels Team.') }}
        template(v-else)
          h2 {{ $t('Unexpected Outage') }}
          .margin-bottom {{ $t('We are currently experiencing unexpected outage of one or more of Parallels My Account services.') }}
          .margin-bottom {{ $t('As a result you may face difficulties using Parallels My Account, including activating your Parallels products, registering new Parallels product license keys, managing your Parallels products in My Account and accessing your remote computers via Parallels Access.') }}
          .margin-bottom {{ $t('In some rare cases you may also experience problems using your Parallels products that were activated prior to this outage.') }}
          .margin-bottom {{ $t('Our engineers are aware of the problem and are working on resuming operations as soon as possible.') }}
          .margin-bottom {{ $t('We appreciate your patience and understanding.') }}
          .margin-bottom
            formatter(:text="$t('If you have questions or issues, please {link}contact Parallels Support{/link}.')")
              router-link(:to="{ name: GET_SUPPORT_PAGE }", slot="link", slot-scope="s") {{ s.text }}
        .text-right
          btn(@click="$router.back()") {{ $t('OK') }}

</template>

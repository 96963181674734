/**
 * File: desktopPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue, { PropType } from 'vue';

import {
  FEATURE_CONFIGURATION_PROFILE_PER_HOST,
  FEATURE_CONFIGURATION_PROFILE_PER_SUBSCRIPTION,
  FEATURE_SUPPORT_PORTAL
} from '@core/constants/features';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import {
  prodKeyToName,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDE,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_NAME_PDFM
} from '@core/constants/subscriptions';
import { GOOGLE_ADMIN_CONSOLE_URL, support as supportLinks } from '@core/constants/links';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductPageMixIn, { MODAL_INVITE } from '@/modules/productPageMixIn';
import {
  ADD_LICENSES_MODAL,
  COMPUTERS_CONTENT,
  CONFIGURATION_PROFILES_CONTENT,
  DESKTOP_COMPUTERS_PAGE,
  DESKTOP_CONFIGURATION_PROFILES_PAGE,
  DESKTOP_LEGACY_LICENSES_PAGE,
  DESKTOP_SSO_MEMBER_DETAILS_PAGE,
  DESKTOP_SSO_USERS_PAGE,
  DESKTOP_SUBSCRIPTION_DETAILS_CONTENT,
  DESKTOP_SUBSCRIPTIONS_PAGE,
  DOWNLOADS_CONTENT,
  GET_SUPPORT_PAGE,
  LEGACY_LICENSES_CONTENT,
  SSO_USERS_CONTENT,
  SUBSCRIPTIONS_CONTENT
} from '@/routes/constants';

import PdComputersRequest from '@/api/pdComputersRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import PdLicensesRequest from '@/api/pdLicensesRequest';
import PdConfigurationProfilesRequest from '@/api/pdConfigurationProfilesRequest';
import SubscriptionInviteModal from '@/modules/desktop/subscriptionInvite/subscriptionInviteModal.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import ComputersContent from './computersContent.vue';
import ConfigurationProfiles from './configurationProfiles/configurationProfiles.vue';
import DownloadsContent from '@/modules/downloads/downloadsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import LegacyLicensesContent from '@/modules/legacyLicenses/legacyLicensesContent.vue';
import Subscription, { STATUS_ACTIVE } from '@/models/subscription';
import BusinessProfileMembersSSO
  from '@/modules/businessProfile/businessProfileMembersSSO/businessProfileMembersSSO.vue';
import { SSOCompanyUsersRequest } from '@/api/sso/ssoCompanyUsersRequest';
import SSOCompanyUser from '@core/models/ssoCompanyUser';
import SSOUsersUsageRequest from '@/api/sso/ssoUsersUsageRequest';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';
import { getPdeSubpages } from '@/modules/pde/utils';

export default Vue.extend({

  name: 'DesktopPage',

  mixins: [ComponentMixIn, ProductPageMixIn, BreadcrumbsMixin],

  components: {
    SubscriptionNotification,
    ComputersContent,
    DownloadsContent,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    LegacyLicensesContent,
    ConfigurationProfiles,
    SubscriptionInviteModal,
    AddLicensesModal,
    BusinessProfileMembersSSO,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  props: {
    appData: {
      type: Object as PropType<AppData>,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    product: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      loading: true,
      error: false,
      supportLinks,
      personalMode: this.appData.session.isPersonalUser(this.appData.userInDomain),
      subscriptions: [] as Subscription[],
      subscription: null as Subscription,
      // filling when subscriptions are loaded
      computersAmount: 0,
      legacyAmount: 0,
      configurationProfilesAmount: 0,
      ssoUsersAmount: 0,
      displayNotification: true,
      COMPUTERS_CONTENT,
      DOWNLOADS_CONTENT,
      SUBSCRIPTIONS_CONTENT,
      DESKTOP_SUBSCRIPTION_DETAILS_CONTENT,
      LEGACY_LICENSES_CONTENT,
      CONFIGURATION_PROFILES_CONTENT,
      SSO_USERS_CONTENT,
    };
  },

  created () {
    if (this.content === COMPUTERS_CONTENT && (this.personalMode || this.product === PRODUCT_KEY_NAME_PDFC)) {
      this.error = true;
      return;
    }

    this.load();
  },

  methods: {
    load () {
      const requestType = this.personalMode ? SubscriptionsRequest.TYPE_PERSONAL : SubscriptionsRequest.TYPE_BUSINESS;
      const combinedRequest = new CombinedApiRequest();

      combinedRequest.addRequest('subscriptions', new SubscriptionsRequest({ type: requestType }));

      if (this.personalMode) {
        combinedRequest.addRequest('legacyLicenses', new PdLicensesRequest());
      }

      if (this.canManageConfigurationProfile) {
        combinedRequest.addRequest('configurationProfiles', new PdConfigurationProfilesRequest({ totalOnly: true }));
      }

      if (this.showComputersAmount) {
        combinedRequest.addRequest('computers', new PdComputersRequest({
          activated: true,
          totalOnly: true,
          product: this.productKey,
        }));
      }

      if (this.product === PRODUCT_KEY_NAME_PDB_PER_USER) {
        combinedRequest.addRequest('ssoUsers', new SSOCompanyUsersRequest({ companyUuid: this.$appData.session.businessDomainId }));
      }

      this.authorizedCall(combinedRequest).then(() => {
        this.subscriptions = (combinedRequest.getRequest('subscriptions') as SubscriptionsRequest).getSubscriptions().filter((s) => s.hasProduct(this.productKey));
        this.subscriptions.forEach((s) => {
          if (this.showComputersAmount) {
            this.computersAmount = (combinedRequest.getRequest('computers') as PdComputersRequest).getTotal();
          }
        });

        if (this.personalMode) {
          this.legacyAmount = (combinedRequest.getRequest('legacyLicenses') as PdLicensesRequest).getLicenses().length;
        }

        if (this.canManageConfigurationProfile) {
          this.configurationProfilesAmount = combinedRequest.getRequest('configurationProfiles').data.total;
        }

        if (this.product === PRODUCT_KEY_NAME_PDB_PER_USER) {
          const users = (combinedRequest.getRequest('ssoUsers') as SSOCompanyUsersRequest).getUsers();

          // Since desktop:/api/v1/sso/users and toolbox:/api/v1/sso endpoints use GET to provide list,
          // request for 1500 UIDs will be failed by 414
          // chunking product services requests here, but need to add normal pagination on upper level
          // Should be removed when pagination on upper request added
          // TODO: https://parallels.atlassian.net/browse/CPCLOUD-19884
          const chunkSize = 100;

          const chunks = [];
          for (let i = 0; i < users.length; i += chunkSize) {
            chunks.push(users.slice(i, i + chunkSize));
          }

          const processChunk = (chunk) => {
            const usersUsageRequest = new SSOUsersUsageRequest({ users: chunk });
            return this.$api.authorizedCall(usersUsageRequest);
          };

          const usersUsagesPromises = chunks.map(chunk => processChunk(chunk));

          Promise.all(usersUsagesPromises).then(usersUsagesArrays => {
            const usersUsages = [].concat(...usersUsagesArrays);

            users.forEach((user) => user.fillProductsInfo(usersUsages));
            this.ssoUsersAmount = users.filter((user) => Object.keys(user.products).includes(PRODUCT_KEY_NAME_PDB_PER_USER)).length;
          });
        }

        // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
        this.handleModal(this.$route.query.modal);
      });
    },
    updateComputersAmount (params) {
      this.computersAmount = params.amount;
    },
    onUpdateConfigurationProfilesAmount (amount) {
      this.configurationProfilesAmount = amount;
    },
    updateSsoUsersAmount (params) {
      this.ssoUsersAmount = params.amount;
    },
    showSSOUserDetails (member: SSOCompanyUser) {
      this.$router.push({
        name: DESKTOP_SSO_MEMBER_DETAILS_PAGE,
        params: { memberId: member.uuid },
      });
    },
  },

  computed: {
    pdaPortalLink (): string {
      return this.$appData.session.pdaPortalUrl;
    },
    productName (): string {
      const productName = prodKeyToName(this.product);
      return this.personalMode ? this.$t(PRODUCT_NAME_PDFM) : this.$t(productName);
    },

    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },

    canBuyMoreLicenses (): boolean {
      return this.canEditSubscription && this.subscriptions.some((subscription) => subscription.canBuyMoreLicenses);
    },

    canEditSubscription (): boolean {
      return this.personalMode || this.isBusinessAdmin;
    },

    productKey (): string {
      return this.personalMode ? PRODUCT_KEY_NAME_PDFM : (this.product || PRODUCT_KEY_NAME_PDB);
    },

    inviteAvailable (): boolean {
      return this.product === PRODUCT_KEY_NAME_PDB && this.isBusinessAdmin && this.subscriptions.some(subscription => subscription.isLicenseManager);
    },

    isBusinessAdmin (): boolean {
      return !this.personalMode && this.$appData.session.isBusinessAdmin;
    },

    canManageConfigurationProfile (): boolean {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
      const featureEnabled = this.isFeatureAccessible(FEATURE_CONFIGURATION_PROFILE_PER_HOST) || this.isFeatureAccessible(FEATURE_CONFIGURATION_PROFILE_PER_SUBSCRIPTION);
      return this.isBusinessAdmin && [PRODUCT_KEY_NAME_PDB, PRODUCT_KEY_NAME_PDB_PER_USER].includes(this.product) && featureEnabled;
    },

    showComputersAmount (): boolean {
      return !this.personalMode && ![PRODUCT_KEY_NAME_PDFC, PRODUCT_KEY_NAME_PDB_PER_USER].includes(this.product);
    },

    subpages (): MenuItem[] {
      const subpages = [];

      if (this.product === PRODUCT_KEY_NAME_PDE) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
        return getPdeSubpages(this);
      }
      if (this.product === PRODUCT_KEY_NAME_PDB_PER_USER) {
        subpages.push({
          text: this.$t('Registered Users ({amount})', { amount: this.ssoUsersAmount }),
          link: {
            name: DESKTOP_SSO_USERS_PAGE,
            params: { product: this.product },
          },
        });
      }

      if (this.showComputersAmount) {
        subpages.push({
          text: this.$t('Registered Computers ({amount})', { amount: this.computersAmount }),
          link: {
            name: DESKTOP_COMPUTERS_PAGE,
            params: { product: this.product },
          },
        });
      }

      // TODO: open in a new tab
      if (this.product === PRODUCT_KEY_NAME_PDFC) {
        subpages.push({
          text: this.$t('Manage in Google Admin Console'),
          click: () => {
            location.href = `${GOOGLE_ADMIN_CONSOLE_URL}?hl=${this.$appData.session.language}`;
          },
        });
      }

      if (this.legacyAmount) {
        subpages.push({
          text: this.$t('Legacy Licenses ({amount})', { amount: this.legacyAmount }),
          link: { name: DESKTOP_LEGACY_LICENSES_PAGE },
        });
      }

      subpages.push({
        text: this.$t('Download'),
        // @ts-ignore  FIXME: https://parallels.atlassian.net/browse/CPCLOUD-16280
        link: this.getDownloadRoute(this.productKey),
      }, {
        text: this.$t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
        link: {
          name: DESKTOP_SUBSCRIPTIONS_PAGE,
          params: { product: this.product },
          query: {
            display: STATUS_ACTIVE,
            source: SUBNAVIGATION_SOURCE,
          },
        },
      });

      if (this.content !== DESKTOP_SUBSCRIPTION_DETAILS_CONTENT && this.canBuyMoreLicenses && (this.isBusinessAdmin || this.personalMode)) {
        subpages.push({
          text: this.$t('Buy More Licenses'),
          link: {
            query: Object.assign({}, this.$route.query, { modal: ADD_LICENSES_MODAL }),
            replace: true,
          },
          forceLink: true,
        });
      }

      if (this.inviteAvailable) {
        subpages.push({
          text: this.$t('Invite Parallels Desktop Users'),
          link: {
            query: Object.assign({}, this.$route.query, { modal: MODAL_INVITE }),
            replace: true,
          },
        });
      }

      if (this.canManageConfigurationProfile) {
        subpages.push({
          text: `${this.$t('Deprecated')}: ${this.$t('Configuration Profiles ({amount})', { amount: this.configurationProfilesAmount })}`,
          link: {
            name: DESKTOP_CONFIGURATION_PROFILES_PAGE,
            params: { product: this.product },
          },
        });
      }

      subpages.push({
        text: this.$t('Support'),
        click: () => {
          if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
            const params = {
              category: 'Support',
              name: 'Support button click in menu',
            };
            this.$collectGaStats({
              ...params,
              source: document.title,
            });
            this.$collectStats({
              ...params,
              source: this.productKey,
            });
            this.$router.push({
              name: GET_SUPPORT_PAGE,
              params: { product: this.productKey },
            });
          } else {
            window.location.href = this.supportLinks[this.productKey];
          }
        },
      });

      return subpages;
    },
  },
});

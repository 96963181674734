<copyright>
File: formField.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

div
  .row(v-if="fieldData.type === 'combo'")
    .col-sm-4.col-xs-12.text-muted.line-height-3x
      form-label(:label="fieldData.label")
    .col-sm-8.col-xs-12
      dropdown(
        v-model="value",
        :label="placeholder",
        :options="options",
        :disabled="dependsOn && !dependentValue",
        :validator="validator",
        :error="error"
      ).block
        template(slot="errors")
          form-field-error(:validator="validator", :customErrors="customErrors")
  template(v-else-if="fieldData.type === 'radio'")
    .text-muted.margin-bottom
      form-label(:label="fieldData.label")
    template(v-for="option in options")
      radiobutton.margin-right-2x(
        v-model="value",
        :value="option.value",
        :name="fieldData.id",
        :text="$t(option.text)"
      )
  template(v-else-if="fieldData.type === 'textfield'")
    div(:class="{'row': fieldData.size === 'half'}")
      .text-muted(:class="{'col-sm-4 col-xs-12 line-height-3x': fieldData.size === 'half', 'margin-bottom': fieldData.size !== 'half'}")
        form-label(:label="fieldData.label")
        hint.margin-left(v-if="tooltipText.length")
          div(style="width: 480px")
            formatter(:text="$t(tooltipText)")
              template(v-for="(value, key) in tooltipArgs", :slot="key", slot-scope="s")
                a(:href="value", target="_blank") {{ s.text }}
      textbox(
        v-model="value",
        :placeholder="placeholder",
        :disabled="dependsOn && !dependentValue",
        :validator="validator",
        :error="error"
        :class="{'col-sm-8 col-xs-12': fieldData.size === 'half'}"
      )
      template(slot="errors")
        form-field-error(:validator="validator", :customErrors="customErrors")
  template(v-else-if="fieldData.type === 'textarea'")
    .text-muted.margin-bottom
      form-label(:label="fieldData.label")
    textbox(
      v-model="value",
      type="text",
      rows="12",
      :placeholder="placeholder",
      :multiline="true",
      :disabled="dependsOn && !dependentValue",
      :validator="validator",
      :error="error"
    )
      template(slot="errors")
        form-field-error(:validator="validator", :customErrors="customErrors")
</template>

<script>

import FormFieldError from './formFieldError';
import FormLabel from './formLabel';

export default {
  name: 'support-form-field',

  components: {
    FormLabel,
    FormFieldError,
  },

  model: {
    event: 'change',
    prop: 'val',
  },

  props: {

    id: {
      type: String,
    },

    val: {
      type: [String, Number],
    },

    fieldData: {
      type: Object,
    },

    dependsOn: {
      type: String,
    },

    dependentValue: {
      type: [String, Number],
    },

    tooltipText: {
      type: String,
      default: '',
    },

    tooltipArgs: {
      type: Object,
      default: () => { return {}; },
    },

    error: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Object,
      default: () => { return {}; },
    },
  },

  data () {
    return {
      value: this.val,
    };
  },

  watch: {
    value (value, oldValue) {
      this.$emit('change', value);
    },
    val (value) {
      this.value = value;
    },
  },

  computed: {
    placeholder () {
      return this.fieldData.placeholder && this.$t(this.fieldData.placeholder);
    },

    options () {
      const options = this.fieldData.options || [];
      if (this.dependentValue) {
        return options.filter((o) => {
          if (typeof o.dependent_value === 'string') {
            return String(o.dependent_value) === String(this.dependentValue);
          }
          return Number(o.dependent_value) === Number(this.dependentValue);
        });
      }
      return options;
    },

    formatLabel () {
      return this.fieldData.label && this.fieldData.label.text;
    },

    customErrors () {
      if (!this.fieldData || !this.fieldData.validators) {
        return {};
      }

      return Object.keys(this.fieldData.validators).reduce((previous, key) => {
        if (this.fieldData.validators[key].hasOwnProperty('message')) {
          previous[key] = this.fieldData.validators[key].message;
        }
        return previous;
      }, {});
    },
  },
};

</script>

/**
 * File: ticketStatus.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import { TicketStatus } from '@/models/ticket';

const RAW_TICKET_STATUS_NEW: string = 'new';
const RAW_TICKET_STATUS_OPEN: string = 'open';
const RAW_TICKET_STATUS_WAITING_FOR_DEVELOPERS: string = 'temporary onhold (waiting for developers)';
const RAW_TICKET_STATUS_WAITING_FOR_CUSTOMER: string = 'waiting for customer';
const RAW_TICKET_STATUS_FOLLOW_UP_REQUIRED: string = 'temporary onhold (follow-up required)';
const RAW_TICKET_STATUS_WAITING_FOR_MAINTENANCE_WINDOW: string = 'temporary onhold (waiting for maintenance window)';
const RAW_TICKET_STATUS_RESOLVED: string = 'resolved';
const RAW_TICKET_STATUS_CLOSED: string = 'closed';
const RAW_TICKET_STATUS_IN_ENGINEERING: string = 'in_engineering';
const RAW_TICKET_STATUS_MERGED_IN_PREFIX: string = 'merged in ';

export function getMergedIn (status: string): string|null {
  if (status.startsWith(RAW_TICKET_STATUS_MERGED_IN_PREFIX)) {
    return status.slice(RAW_TICKET_STATUS_MERGED_IN_PREFIX.length);
  } else {
    return null;
  }
}

export function getStatus (status: string): TicketStatus {
  if (status === RAW_TICKET_STATUS_NEW || status === RAW_TICKET_STATUS_OPEN || status === RAW_TICKET_STATUS_WAITING_FOR_DEVELOPERS) {
    return TicketStatus.inProgress;
  } else if (status === RAW_TICKET_STATUS_WAITING_FOR_CUSTOMER || status === RAW_TICKET_STATUS_FOLLOW_UP_REQUIRED) {
    return TicketStatus.waitingMyResponse;
  } else if (status === RAW_TICKET_STATUS_WAITING_FOR_MAINTENANCE_WINDOW) {
    return TicketStatus.callScheduled;
  } else if (status === RAW_TICKET_STATUS_RESOLVED || status === RAW_TICKET_STATUS_CLOSED) {
    return TicketStatus.closed;
  } else if (status === RAW_TICKET_STATUS_IN_ENGINEERING) {
    return TicketStatus.inEngineering;
  } else if (status.startsWith(RAW_TICKET_STATUS_MERGED_IN_PREFIX)) {
    return TicketStatus.merged;
  }
  return TicketStatus.unknown;
}

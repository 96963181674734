/**
 * File: restorePurchasesModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue, { PropType } from 'vue';
import {
  clearLicenseKey,
  email,
  isLicenseKeyValid,
  required
} from '@core/common/validators';
import ComponentMixin from '@/modules/componentMixIn';
import ContactSupportModal, { FORGOT_PASSWORD_SCENARIO } from '@/modules/contactSupportModal.vue';
import InitRestorePurchasesRequest from '@/api/initRestorePurchasesRequest';
import Session from '@/models/session';

type STEP_TYPE = 'init' | 'success' | 'purchasesNotFound' | 'contactSupport' | 'invalidKey' | 'rateLimit';
type RESTORING_METHOD_TYPE = 'email';

interface IRestorePurchasesModalData {
  step: STEP_TYPE;
  restoringMethod: RESTORING_METHOD_TYPE;
  licenseKey: string;
  email: string;
  loading: boolean;
  scenario: number;
}

interface IRestorePurchasesModalProps {
  session: Session;
}

interface IRestorePurchasesModalMethods {
  reset(): void;
  restorePurchasesClick(): void;
  restorePurchasesByEmail(): Promise<void>;
  restorePurchasesByLicense(): Promise<void>;
  handleError(e): void;
  showContactSupportModal (): void;
}

export default Vue.extend<IRestorePurchasesModalData, IRestorePurchasesModalMethods, {}, IRestorePurchasesModalProps>({
  name: 'restore-purchases-modal',
  components: { ContactSupportModal },
  mixins: [ComponentMixin],
  props: {
    session: {
      type: Object as PropType<Session>,
    },
  },
  validations: {
    email: {
      ...email,
      sameEmail: function (value: string) {
        return !this.session || value !== this.session.email;
      },
    },
    licenseKey: {
      required,
      pattern: function (value: string) {
        return isLicenseKeyValid(value);
      },
    },
  },
  data (): IRestorePurchasesModalData {
    return {
      step: 'init',
      restoringMethod: 'email',
      licenseKey: '',
      email: '',
      loading: false,
      scenario: FORGOT_PASSWORD_SCENARIO,
    };
  },
  methods: {
    reset (): void {
      this.restoringMethod = 'email';
      this.email = '';
      this.licenseKey = '';
      this.step = 'init';
      this.$v.$reset();
    },
    restorePurchasesClick (): void {
      this.$v.$touch();

      if (this.restoringMethod === 'email') {
        this.restorePurchasesByEmail();
      } else if (this.restoringMethod === 'license') {
        this.restorePurchasesByLicense();
      }
    },
    async restorePurchasesByEmail () {
      if (this.$v.email.$invalid) {
        return;
      }

      const request = new InitRestorePurchasesRequest({ email: this.email });
      try {
        await this.authorizedCall(request, 'loading');
        this.step = 'success';
      } catch (e) {
        await this.handleError(e);
      }
    },
    async restorePurchasesByLicense () {
      if (this.$v.licenseKey.$invalid) {
        return;
      }

      const request = new InitRestorePurchasesRequest({ licenseKey: clearLicenseKey(this.licenseKey) });
      try {
        const response = await this.authorizedCall(request, 'loading');
        this.email = response.email;
        this.step = 'success';
      } catch (e) {
        await this.handleError(e);
      }
    },
    async handleError (e) {
      const data = await e.response.json();
      switch (data.msg) {
        case 'invalid license key': // In case if license key wasn't found at LS service
        case 'no social profiles to reassign': // In case if current user doesn't have any bindings to social profiles to be reassigned
        case 'You are signing in from a country or territory where Parallels products are not supported.':
        case 'invalid parameters':
        case 'user does not exist':
          this.step = 'purchasesNotFound';
          break;
        case 'not acceptable target user': // In case if target user belongs to a business domain
          this.step = 'contactSupport';
          break;
        case 'wrong domain type': // In case if license key for business product was used
          this.step = 'invalidKey';
          break;
        case 'rate limited':
          this.step = 'rateLimit';
          break;
      }
    },
    showContactSupportModal (): void {
      this.$modal.show(ContactSupportModal);
    },
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'file-uploader',

  props: {
    /**
     * List of mime-types to accept
     */
    types: {
      type: Array,
      default: () => [],
    },
    /**
     * Text label to display in the droppable field
     */
    label: String,
    /**
     * Allow to upload multiple files
     */
    multiple: {
      type: Boolean,
      default: true,
    },
    /**
     * Read file content or return raw file object
     */
    readContent: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      error: '',
      loading: false,
    };
  },

  computed: {
    accept (): string|null {
      return this.types.join(', ') || null;
    },
  },

  methods: {
    upload (fileList: File[]) {
      this.$emit('start');
      this.loading = true;

      const promises = Array.prototype.map.call(fileList, (file) => {
        return new Promise((resolve, reject) => {
          // beware that some browsers are not setting file type correctly (e.g. Yandex Browser on Windows)
          if (this.types && file.type && this.types.indexOf(file.type) === -1) {
            reject(this.$t('Invalid file format'));
            return;
          }

          if (this.readContent) {
            const reader = new FileReader();
            reader.onabort = reader.onerror = () => reject(this.$t('Upload failed'));
            reader.onload = (event) => resolve(event.target.result);
            reader.readAsText(file);
          } else {
            resolve(file);
          }
        });
      });

      Promise.all(promises).then((contents) => {
        this.error = '';
        this.loading = false;
        this.$emit('success', contents);
      }).catch((e) => {
        this.error = e;
        this.loading = false;
        this.$emit('error', this.error);
      });
    },
  },
});

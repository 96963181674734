

import Vue, { PropType } from 'vue';
import { CombinedVueInstance } from 'vue/types/vue';
import { STORE_NAME_CB, STORE_NAME_CUSTOM } from '@core/constants/subscriptions';
import PaymentOptionsModal from '@/modules/subscriptions/paymentOptions/paymentOptionsModal.vue';
import ConfigureAppleAppStoreModal from '@/modules/subscriptions/configureAppleAppStore/configureAppleAppStoreModal.vue';
import ConfigureGooglePlayStoreModal from '@/modules/subscriptions/configureGooglePlayStore/configureGooglePlayStoreModal.vue';
import StatusActive from './statuses/statusActive.vue';
import StatusExpireSoon from './statuses/statusExpireSoon.vue';
import StatusExpired from './statuses/statusExpired.vue';
import StatusTrial from './statuses/statusTrial.vue';
import StatusCardExpired from './statuses/statusCardExpired.vue';
import StatusPermanent from './statuses/statusPermanent.vue';
import StatusBlacklisted from './statuses/statusBlacklisted.vue';
import StatusSuspended from './statuses/statusSuspended.vue';
import StatusWillRenew from './statuses/statusWillRenew.vue';
import StatusRenewFailed from './statuses/statusRenewFailed.vue';
import StatusAasExpireSoon from './statuses/statusAasExpireSoon.vue';
import StatusAasExpired from './statuses/statusAasExpired.vue';
import StatusGpsExpireSoon from './statuses/statusGpsExpireSoon.vue';
import StatusGpsExpired from './statuses/statusGpsExpired.vue';
import StatusPdlExpireSoon from './statuses/statusPdlExpireSoon.vue';
import StatusPdlExpired from './statuses/statusPdlExpired.vue';
import StatusSfExpireSoon from './statuses/statusSfExpireSoon.vue';
import StatusSfExpired from './statuses/statusSfExpired.vue';
import StatusCbExpired from './statuses/statusCbExpired.vue';
import StatusBytebot from './statuses/statusBytebot.vue';
import HideSubscriptionModal from '@/modules/subscriptions/list/hideSubscriptionModal.vue';
import Subscription, { VIEW_STATUS } from '@/models/subscription';

export default Vue.extend({
  name: 'status-line',
  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
  },
  components: {
    StatusActive,
    StatusExpireSoon,
    StatusExpired,
    StatusTrial,
    StatusCardExpired,
    StatusPermanent,
    StatusBlacklisted,
    StatusSuspended,
    StatusWillRenew,
    StatusRenewFailed,
    StatusAasExpireSoon,
    StatusAasExpired,
    StatusGpsExpireSoon,
    StatusGpsExpired,
    StatusPdlExpireSoon,
    StatusPdlExpired,
    StatusSfExpireSoon,
    StatusSfExpired,
    StatusCbExpired,
    StatusBytebot,
    PaymentOptionsModal,
    ConfigureAppleAppStoreModal,
    ConfigureGooglePlayStoreModal,
  },

  computed: {
    showStatus (): boolean {
      return !(this.subscription.isNeverExpiringSPLA && !this.subscription.isExpired) && !this.subscription.isRasAzmp;
    },
    statusComponent (): CombinedVueInstance<any, any, any, any, any> {
      return {
        [VIEW_STATUS.PERMANENT]: StatusPermanent,
        [VIEW_STATUS.BLACKLISTED]: StatusBlacklisted,
        [VIEW_STATUS.SUSPENDED]: StatusSuspended,
        [VIEW_STATUS.ACTIVE]: StatusActive,
        [VIEW_STATUS.EXPIRE_SOON]: StatusExpireSoon,
        [VIEW_STATUS.EXPIRED]: StatusExpired,
        [VIEW_STATUS.TRIAL]: StatusTrial,
        [VIEW_STATUS.CARD_EXPIRED]: StatusCardExpired,
        [VIEW_STATUS.RENEW_FAILED]: StatusRenewFailed,
        [VIEW_STATUS.WILL_RENEW]: StatusWillRenew,
        [VIEW_STATUS.AAS_EXPIRE_SOON]: StatusAasExpireSoon,
        [VIEW_STATUS.AAS_EXPIRED]: StatusAasExpired,
        [VIEW_STATUS.GPS_EXPIRE_SOON]: StatusGpsExpireSoon,
        [VIEW_STATUS.GPS_EXPIRED]: StatusGpsExpired,
        [VIEW_STATUS.PDL_EXPIRE_SOON]: StatusPdlExpireSoon,
        [VIEW_STATUS.PDL_EXPIRED]: StatusPdlExpired,
        [VIEW_STATUS.SF_EXPIRE_SOON]: StatusSfExpireSoon,
        [VIEW_STATUS.SF_EXPIRED]: StatusSfExpired,
        [VIEW_STATUS.CB_EXPIRED]: StatusCbExpired,
        [VIEW_STATUS.BYTEBOT]: StatusBytebot,
      }[this.subscription.viewStatus];
    },
  },

  methods: {
    btnClickHandler (): void {
      const status = this.subscription.viewStatus;
      if (this.subscription.storeId === STORE_NAME_CUSTOM) {
        this.$emit('renew');
      }
      if (this.subscription.storeId === STORE_NAME_CB && !this.subscription.isAutorenewable) {
        this.$emit('autoRenewOn');
      }
      if (this.subscription.storeId === STORE_NAME_CB && this.subscription.isAutorenewable) {
        this.$emit('renew');
      }
      if (status === VIEW_STATUS.PDL_EXPIRED || status === VIEW_STATUS.PDL_EXPIRE_SOON || this.subscription.isActivePdlSubscription) {
        this.$emit('convertPdl');
      }
      if (status === VIEW_STATUS.AAS_EXPIRE_SOON || status === VIEW_STATUS.AAS_EXPIRED) {
        this.$emit('appleAppStore');
      }
    },
    hide (): void {
      this.$modal.show(HideSubscriptionModal, { subscription: this.subscription });
    },
  },
});


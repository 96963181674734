
import Vue from 'vue';

import { PRODUCT_KEY_NAME_PVAD, PRODUCT_NAME_PVAD } from '@core/constants/subscriptions';

import Subscription, { STATUS_ACTIVE } from '@/models/subscription';

import SubscriptionsRequest from '@/api/subscriptionsRequest';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductPageMixIn from '@/modules/productPageMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import { PVAD_SUBPAGE_SUBSCRIPTION_DETAILS, PVAD_SUBPAGE_SUBSCRIPTIONS } from '@/routes/constants';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'pvad-page',

  props: ['content'],

  mixins: [
    ComponentMixIn,
    ProductPageMixIn,
    SearchFilterMixin,
    BreadcrumbsMixin,
  ],

  components: {
    SubscriptionNotification,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    AddLicensesModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  data () {
    return {
      subscriptions: [],
      personalMode: this.$appData.session.isPersonalUser(this.$appData.userInDomain) || false,
      productKeyName: PRODUCT_KEY_NAME_PVAD,
      displayNotification: true,
    };
  },

  created () {
    this.load();
  },

  methods: {
    load () {
      const requestType = this.personalMode ? SubscriptionsRequest.TYPE_PERSONAL : SubscriptionsRequest.TYPE_BUSINESS;
      const request = new SubscriptionsRequest({ type: requestType });
      this.authorizedCall(request)
        .then(() => {
          this.subscriptions = request.getSubscriptions().filter((s: Subscription) => s.hasProduct(PRODUCT_KEY_NAME_PVAD));
        });
    },
  },

  computed: {
    productName () {
      return PRODUCT_NAME_PVAD;
    },
    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },
    isSubscriptionsPage (): boolean {
      return this.content === PVAD_SUBPAGE_SUBSCRIPTIONS;
    },
    isSubscriptionDetailsPage (): boolean {
      return this.content === PVAD_SUBPAGE_SUBSCRIPTION_DETAILS;
    },
    subpages (): MenuItem[] {
      return [
        {
          text: this.$t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
          link: { name: PVAD_SUBPAGE_SUBSCRIPTIONS, query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE } },
        },
      ];
    },
  },
});

/**
 * File: rtCreateTicketRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import { Json, Method } from '@core/api/apiRequest';

import RtRequest, { RtRequestParams } from './rtRequest';
import RtTicketsRequest from './rtTicketsRequest';

interface Params extends RtRequestParams {
  form: Json;
}

/**
 * https://wiki.prls.net/x/gjCrAg#apiforaccount.parallels.com-create_ticket
 */
export default class RtCreateTicketRequest extends RtRequest<Params, Json> {
  get uri (): string {
    return '/ticket';
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return { fields: this.params.form };
  }

  load (): Promise<Json> {
    return super.load()
      .then((data) => {
        const params: RtRequestParams = {};
        if (this.params.hasOwnProperty('rtUrl')) {
          params.rtUrl = this.params.rtUrl;
        }
        new RtTicketsRequest(params).dropCache();
        return data;
      });
  }
}

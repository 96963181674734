/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

export const MFA_DISABLED_SIGNAL = 'mfadisabled';
export const MFA_METHOD_CHANGED_SIGNAL = 'mfamethodchanged';
export const MFA_SETTINGS_CARD = 'mfaSettingsCard';

export const MAX_AUTHENTICATOR_APP_NAME_LENGTH = 255;

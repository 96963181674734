/**
 * File: services.ts
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

enum Service {
  Account = 'account',
  Access = 'access',
  Desktop = 'desktop',
  Toolbox = 'toolbox',
  RAS = 'ras',
  PMM = 'pmm',
  AWG = 'awingu',
  License = 'license',
  RasLicense = 'ras-license',
  RT = 'rt',
  My = 'my',
  SA = 'support_assistant',
}

export default Service;

// for backward compatibility
export const ACCOUNT_SERVICE = Service.Account;
export const ACCESS_SERVICE = Service.Access;
export const DESKTOP_SERVICE = Service.Desktop;
export const TOOLBOX_SERVICE = Service.Toolbox;
export const RAS_SERVICE = Service.RAS;
export const PMM_SERVICE = Service.PMM;
export const LICENSE_SERVICE = Service.License;
export const RAS_LICENSE_SERVICE = Service.RasLicense;
export const RT_SERVICE = Service.RT;
export const MY_SERVICE = Service.My;
export const SUPPORT_ASSISTANT = Service.SA;
export const AWINGU_SERVICE = Service.AWG;

/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */
export const SSO_SETTINGS_STATUS = {
  DISABLED: 0,
  ENABLED: 1,
  ACTIVATED: 2,
};

export const SSO_GROUPS_IDS = {
  ADMIN: 1,
  DESKTOP: 10,
  TOOLBOX: 11,
};

export const SSO_STEP_NAME = {
  DOMAIN: 'domain',
  APPS: 'apps',
  MAPPING: 'mapping',
  SAML: 'saml',
  SCIM: 'scim',
  USERS: 'users',
  BACKUP: 'backup',
};


import Vue from 'vue';

import {
  prodKeyToName as getProductName,
  PRODUCT_KEY_NAME_PDFM
} from '@core/constants/subscriptions';
import { password } from '@core/common/validators';
import DeleteProfileRequest from '@/api/deleteProfileRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import PdLicensesRequest from '@/api/pdLicensesRequest';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import ResendDeleteProfileEmailRequest from '@/api/resendDeleteProfileEmailRequest';

import { OperationStatus } from './constants';

export default Vue.extend({
  name: 'deleting-account-modal',
  created () {
    this.getProducts();
  },
  data () {
    return {
      password: '',
      loading: false,
      activeProducts: [] as string[],
      hasProducts: false,
      mode: OperationStatus.PREPROCESSING as OperationStatus,
      expirationDate: undefined as Date,
    };
  },
  validations: {
    password,
  },
  computed: {
    productsList (): string {
      return this.activeProducts.map((key) => this.$t(getProductName(key))).join(', ');
    },
  },
  methods: {
    reset () {
      this.password = '';
      this.mode = OperationStatus.PREPROCESSING;
      this.$modal.hide();
    },
    remove () {
      const request = new DeleteProfileRequest({
        password: this.password,
      });

      this.loading = true;

      this.$api.authorizedCall(request).then(() => {
        this.$modal.hide();
        this.reset();
        this.$toast.show({
          text: $t('To proceed with the account deletion, follow the instructions that have been sent to your account email address {email}.', {
            email: this.$appData.session.email,
          }),
          color: 'green',
        });
      }).catch((error) => {
        error.response.json().then((data) => {
          const actions = {
            'invalid password': {
              text: $t('Invalid password'),
              color: 'red',
              keepModal: true,
            },
            'data processing': {
              mode: OperationStatus.PROCESSING,
            },
            'data ready': {
              mode: OperationStatus.READY,
              expirationDate: data.details && data.details.expire,
            },
          };
          const action = actions[data.msg] || {};

          if (action.mode) {
            this.mode = action.mode;
            this.expirationDate = action.expirationDate;
          } else if (action.text) {
            this.$toast.show(action);
            if (!action.keepModal) {
              this.$modal.hide();
            }
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    getProducts () {
      // Collect info about active and expired products
      const combinedRequest = new CombinedApiRequest({ integrity: false });

      combinedRequest.addRequest('ls', new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_PERSONAL }));
      combinedRequest.addRequest('pd', new PdLicensesRequest());

      this.$api.authorizedCall(combinedRequest).then(() => {
        const subscriptions = (combinedRequest.getRequest('ls') as any as SubscriptionsRequest).getSubscriptions();
        const legacyLicenses = (combinedRequest.getRequest('pd') as any as PdLicensesRequest).getLicenses();
        const activeProducts = {};

        subscriptions.filter((subscription) => {
          return subscription.isActive;
        }).forEach((subscription) => {
          subscription.products.forEach((product) => {
            activeProducts[product.productKeyName] = true;
          });
        });

        legacyLicenses.some((license) => {
          if (!license.isBlacklisted) {
            activeProducts[PRODUCT_KEY_NAME_PDFM] = true;
            return true;
          }
        });

        this.activeProducts = Object.keys(activeProducts);

        if (subscriptions.length || legacyLicenses.length) {
          this.hasProducts = true;
        }
      });
    },
    resend () {
      const request = new ResendDeleteProfileEmailRequest();

      this.loading = true;
      this.$api.authorizedCall(request).then((data) => {
        this.$modal.hide();
        this.$toast.show({
          text: $t('Instructions on deleting your account have been re-sent to {email}.', {
            email: this.$appData.session.email,
          }),
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
});


/**
 * File: rasAllProductsPerUserRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class RasAllProductsPerUserRequest extends ApiRequest {
  get url () {
    const domainIds = this.params.domainIds || [];
    const queryString = this.buildQueryString({
      domainIds: domainIds.join(','),
    });
    return '/ras-license/webapp/all_products_per_user' + queryString;
  }

  get cacheNameSpace () {
    return 'subscriptions';
  }
};

<copyright>
File: callbackModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

modal(
  ref="modal",
  :size="22",
  name="сallbackModal",
  :data-name="$name()",
  :validator="$v"
)
  template(slot="header") {{ $t('Book a Call') }}
  template(slot="content")
    loader(v-if="bookingLoading", :loading="bookingLoading", :text="$t('Booking a call, please wait')")
    loader(v-else, :loading="loading")
      modal-field(:data-name="$name('contact-phone')")
        label(slot="label") {{ $t('Phone number for contact') }}
        textbox(
          slot="field",
          name="phone",
          type="tel",
          placeholder="+1 204 234 5678",
          v-model="phone",
          :validator="$v.phone",
        )
      fieldset
        legend {{ $t('Select time for contact') }}
        .row.margin-bottom
          .col-xs-12.col-sm-2.text-right-sm.text-muted.line-height-3x
            label(slot="label") {{ $t('Date:') }}
          .col-xs-12.col-sm-10
            .row
              .col-sm-3.col-xs-6.margin-bottom(v-for="date in sortedDates")
                radiobutton.block(
                  :value="date",
                  v-model="currentDate",
                  :key="date",
                  mode="button"
                ) {{ date }}
        .row.margin-bottom
          .col-xs-12.col-sm-2.text-right-sm.text-muted.line-height-3x
            label(slot="label") {{ $t('Part of day:') }}
          .col-xs-12.col-sm-10
            .row
              .col-xs-6.col-sm-3.margin-bottom(v-for="dayPeriod in dayPeriods")
                radiobutton.block(
                  :value="dayPeriod",
                  :disabled="!dayPeriodHasSlots(dayPeriod)",
                  v-model="currentDayPeriod",
                  :key="dayPeriod",
                  mode="button"
                ) {{ $t(dayPeriod) }}
        .row
          .col-xs-12.col-sm-2.text-right-sm.text-muted.line-height-3x
            label(slot="label") {{ $t('Time:') }}
          .col-xs-12.col-sm-10
            .placeholder(
              v-if="!availableSlots.length"
            ) {{ $t('You should select part of day') }}
            .placeholder(
              v-else-if="!hasFreeSlots"
            ) {{ $t('Oops... Looks like all slots have been taken for this part of the day. Please select a different part of the day or date.') }}
            .row(v-else)
              .col-xs-3.margin-bottom(v-for="(slot, index) in availableSlots")
                radiobutton.block(
                  :value="slot.value",
                  :key="index",
                  v-model="selectedSlot",
                  :disabled="!slot.value",
                  mode="button"
                ) {{ slot.name }}
  template(slot="footer")
    btn(@click="onBookClick", :disabled="!selectedSlot || !phone") {{ $t('Book') }}
    .pull-left-sm
      btn(
        color="white",
        @click="doneButtonHandler",
        :data-name="$name('button-done')"
      ) {{ $t('Back') }}

</template>

<script>
import RtSaveCallbackScheduleRequest from '@/api/rt/rtSaveCallbackScheduleRequest';
import RtGetCallbackScheduleRequest from '@/api/rt/rtGetCallbackScheduleRequest';
import { getMilitaryTimezone } from '@core/common/datetime';
import { phone, required } from '@core/common/validators';
import { toDateObject } from '@core/common/format/date';

export default {
  name: 'support-callback-modal',

  props: ['ticketId'],

  data () {
    return {
      loading: false,
      bookingLoading: false,
      phone: '',
      slots: [],
      currentDate: '',
      currentDayPeriod: '',
      dayPeriods: ['Night', 'Morning', 'Day', 'Evening'],
      selectedSlot: '',
      type: '2',
    };
  },

  validations: { phone, required },

  methods: {
    show () {
      this.$refs.modal.show();
      this.load();
    },

    flush () {
      this.currentDayPeriod = '';
      this.selectedSlot = '';
    },

    load () {
      // get available callback slots
      this.loading = true;
      this.$api.callRt(new RtGetCallbackScheduleRequest({
        ticketId: this.ticketId,
      })).then(data => {
        this.types = data.types;
        this.slots = data.slots;
        this.loading = false;
      });
    },

    onBookClick () {
      // post chosen callback slot
      const request = new RtSaveCallbackScheduleRequest({
        ticketId: this.ticketId,
        body: {
          country: this.$appData.session.country.toLowerCase(),
          tz_offset: this.tz_offset,
          date: this.selectedSlot,
          type: this.type,
          phone: this.phone,
        },
      });

      this.bookingLoading = true;

      return this.$api.callRt(request).then((data) => {
        this.flush();
        this.$refs.modal.hide();
        this.$emit('callBooked');
        this.$toast.show({
          text: $t('Thank you! Parallels Support representative will contact you within the specified time frame.'),
        });
      }).catch(() => {
        this.$toast.show({
          color: 'red',
          text: $t('Sorry, booking request failed! Try again later.'),
        });
      }).finally(() => {
        this.bookingLoading = false;
      });
    },

    doneButtonHandler () {
      this.flush();
      this.$refs.modal.hide();
    },

    timeOfDay (hour) {
      if (hour >= 12 && hour < 18) {
        return 'Day';
      } else if (hour >= 18 && hour < 24) {
        return 'Evening';
      } else if (hour >= 0 && hour < 6) {
        return 'Night';
      } else if (hour >= 6 && hour < 12) {
        return 'Morning';
      }
    },

    generateTimeSlots () {
      // Creates four sets of 30 min time slotes according to the time of day:
      // Morning: "06:00 - 06:30" - "11:30 - 12:00"
      // Day: "12:00 - 12:30" - "17:30 - 18:00"
      // Evening: "18:00 - 18:30 - "23:30 - 00:00"
      // Night: "00:00 - 00:30" - "05:30 - 06:00"
      const dt = new Date(1970, 0, 1, 0, 0, 0, 0);
      const periods = {
        Morning: [],
        Day: [],
        Evening: [],
        Night: [],
      };
      const step = 30;

      while (dt.getDate() === 1) {
        const partOfDay = this.timeOfDay(dt.getHours());
        const start = this.formatTime(dt);
        dt.setMinutes(dt.getMinutes() + step);
        const stop = this.formatTime(dt);

        const timeFrame = `${start} - ${stop}`;

        periods[partOfDay].push({ name: timeFrame, value: false });
      }
      return periods;
    },

    dayPeriodHasSlots (dayPeriod) {
      return this.currentDate && this.dates[this.currentDate][dayPeriod].length;
    },
  },

  computed: {
    dates () {
      const dates = {};
      for (const slot of this.slots) {
        const day = this.formatShortDate(slot.value);
        // $t('Night')
        // $t('Morning')
        // $t('Day')
        // $t('Evening')
        const dayPeriod = this.timeOfDay(toDateObject(slot.value).getHours());
        const timeFrame = `${this.formatTime(slot.from)} - ${this.formatTime(slot.to)}`;
        if (!Object.keys(dates).length || !Object.keys(dates).includes(day)) {
          dates[day] = this.generateTimeSlots();
        }
        for (const frame of dates[day][dayPeriod]) {
          if (frame.name === timeFrame) {
            frame.value = slot.value;
          }
        }
      }
      return dates;
    },

    sortedDates () {
      return Object.keys(this.dates).sort((a, b) => new Date(a) - new Date(b));
    },

    availableSlots () {
      return this.currentDate && this.currentDayPeriod && this.dates[this.currentDate][this.currentDayPeriod] || [];
    },

    hasFreeSlots () {
      return this.currentDate && this.currentDayPeriod && this.dates[this.currentDate][this.currentDayPeriod].some(el => el.value);
    },

    tz_offset () {
      return getMilitaryTimezone();
    },
  },

  watch: {
    currentDate () {
      this.currentDayPeriod = '';
      this.selectedSlot = '';
    },
    currentDayPeriod () {
      this.selectedSlot = '';
    },
  },
};

</script>

<style scoped lang="sass">

.placeholder
  height: $grid-step * 12
  box-shadow: 0 0 0 1px $silver inset
  border-radius: 2px
  color: $silver
  text-align: center
  padding: $grid-step * 4

</style>

/**
 * File: updateDomainMfaSettingsRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */
import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface UpdateDomainMfaSettingsRequestParams {
  domainId: number;
  mfaEnabled: boolean;
  oneTimePassword?: string;
}

export default class UpdateDomainMfaSettingsRequest extends ApiRequest<UpdateDomainMfaSettingsRequestParams> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/domains/${this.params.domainId}`;
  }

  get method (): Method {
    return 'PUT';
  }

  get body (): Json {
    const body: Json = { mfa_status: this.params.mfaEnabled };
    if (this.params.oneTimePassword) {
      body.one_time_password = this.params.oneTimePassword;
    }
    return body;
  }
}

/**
 * File: companyUsersRequest.ts
 *
 * Copyright:
 * Copyright © 2017 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import CompanyUser, { USER_STATUS } from '@/models/companyUser';

export interface CompanyUserData {
  uuid: number;
  name: string;
  email: string;
  notes: string;
  status: USER_STATUS;
  groups: string[];
  'is_admin': boolean;
}

export interface CompanyUsersResponse {
  users: CompanyUserData[];
}

export default class CompanyUsersRequest extends ApiRequest<Record<string, any>, CompanyUsersResponse> {
  companyUuid: string;

  constructor (params, companyUuid: string) {
    super(params);
    this.companyUuid = companyUuid;
  }

  get url (): string {
    return '/account/api/v1/ba/' + this.companyUuid + '/users';
  }

  getUsers (): CompanyUser[] {
    return this.data && this.data.users.map((rawUser) => new CompanyUser(rawUser));
  }
}

/**
 * File: companyUsersReinviteRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class CompanyUsersReinviteRequest extends ApiRequest {
  constructor (params, companyUuid) {
    super(params);
    this.company_uuid = companyUuid;
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/ba/' + this.company_uuid + '/users/reinvite';
  }

  get body () {
    return {
      users: this.params.userUuids,
    };
  }
}

/**
 * File: switchBusinessDomainRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class SwitchBusinessDomainRequest extends ApiRequest {
  /**
   * @param {Object} params
   * @param {Number} params.domainId
   */
  constructor (params) {
    super(params);
    this.params = params;
  }

  get url () {
    return `/account/api/v1/switch_domain/${this.params.domainId}`;
  }

  get method () {
    return 'POST';
  }
}

/**
 * File: legacyLicense.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';
import { PRODUCT_KEY_NAME_PDFM } from '@core/constants/subscriptions';

export default class LegacyLicense extends CoreModel {
  licKey: string;
  identity: string;
  type: string;
  registered: Date;
  blacklisted: boolean;
  limitReached: boolean;
  hwIds: string[];

  get canAddLicenses () {
    return false;
  }

  get canUpgradeOnline () {
    return false;
  }

  get canBuyMoreLicenses () {
    return false;
  }

  hasProduct (keyName) {
    return keyName === PRODUCT_KEY_NAME_PDFM;
  }
}


import Vue from 'vue';
import Markdown from 'markdown';
import RegisterEventRequest from '@/api/registerEvent';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import { EnvDependentLink, getEnvDependentLink } from '@/data/links';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'desktop-m1-beta',
  mixins: [BreadcrumbsMixin],
  components: { Breadcrumbs }, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  data () {
    return {
      filename: 'm1-beta',
      error: false,
      loading: false,
      text: '' as string,
    };
  },
  mounted () {
    this.load();
    this.registerViewEvent();
  },
  computed: {
    htmlText (): string {
      return this.text && Markdown.parse(this.text, 'Maruku');
    },
  },
  methods: {
    load () {
      this.loading = true;
      const desktopBetaDocumentsRoot = getEnvDependentLink(this, EnvDependentLink.DESKTOP_BETA_DOCUMENTS_ROOT);
      fetch(`${desktopBetaDocumentsRoot}/${this.filename}.md?` + Date.now()).then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            this.text = text;
          });
        }
      }).catch(() => {
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    registerViewEvent () {
      this.$api.authorizedCall(new RegisterEventRequest({
        eventName: 'pdfm_m1_beta view',
        pageUrl: location.pathname,
      }));
    },
  },
});


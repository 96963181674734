/**
 * File: companyUsersRevokeAdminRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { GROUP_ADMIN } from './userConst';
import CompanyUsersUpdateRequest from './companyUsersUpdateRequest';

export default class CompanyUsersRevokeAdminRequest extends CompanyUsersUpdateRequest {
  constructor (params, companyUuid) {
    super(params, companyUuid);

    this.params.addGroups = [];
    this.params.removeGroups = [GROUP_ADMIN];
  }
}



import Vue, { PropType } from 'vue';
import Subscription from '@/models/subscription.js';
import HideSubscriptionRequest from '@/api/hideSubscriptionRequest';

export default Vue.extend({
  name: 'hide-subscription-modal',
  props: {
    subscriptions: {
      type: Array as PropType<Subscription[]>,
    },
  },
  data () {
    return {
      subscription: null as Subscription,
      loading: false,
    };
  },
  methods: {
    onShow (e, params: { subscription: Subscription }): void {
      this.subscription = params.subscription;
    },
    hideSubscriptions (subscriptions: Subscription[]): void {
      const requests = subscriptions.map((subscription) => {
        const request = new HideSubscriptionRequest({ subscription });
        return this.$api.authorizedCall(request);
      });

      this.loading = true;

      Promise.all(requests).then(() => {
        this.$toast.show({
          text: this.$tc('{n} expired subscriptions have been hidden.', subscriptions.length, { n: subscriptions.length }),
        });
        this.$modal.hide();
        this.$emit('reload');
      }).finally(() => {
        this.loading = false;
      });
    },
    hideAllSubscriptions (): void {
      this.hideSubscriptions(this.expiredSubscriptions);
    },
    hideOneSubscription (): void {
      this.hideSubscriptions([this.subscription]);
    },
  },
  computed: {
    expiredSubscriptions (): Subscription[] {
      return this.subscriptions.filter((subscription) => subscription.isExpired);
    },
    hasMoreThanOneExpiredSubscription (): boolean {
      return this.expiredSubscriptions.length > 1;
    },
    hideButtonColor (): string {
      return this.hasMoreThanOneExpiredSubscription ? 'white' : 'blue';
    },
  },
});


/**
 * File: ssoMetadataRequests.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

const URI_PREFIX = `/${ACCOUNT_SERVICE}/api/v1/ba`;

interface SsoMetadataUploadRequestParams {
  domainId: number;
  file: File;
}

/* eslint-disable camelcase */
interface SsoMetadataUploadRequestResponse {
  entity_id: string;
  sso_url: string;
  certificate: string;
}
/* eslint-enable camelcase */

export class SsoMetadataUploadRequest extends ApiRequest<SsoMetadataUploadRequestParams, SsoMetadataUploadRequestResponse> {
  constructor (params) {
    super(params);
    this.headers = {};
  }

  get options () {
    const
      options = super.options;
    const body = new FormData();

    body.append('metadata', this.params.file);
    options.body = body;

    return options;
  }

  get method (): Method {
    return 'POST';
  }

  get url () {
    return `${URI_PREFIX}/${this.params.domainId}/metadata`;
  }
}

interface SsoMetadataDownloadRequestParams {
  domainId: number;
}

interface SsoMetadataDownloadRequestResponse {
  data: string;
}

export class SsoMetadataDownloadRequest extends ApiRequest<SsoMetadataDownloadRequestParams, SsoMetadataDownloadRequestResponse> {
  get method (): Method {
    return 'GET';
  }

  get url () {
    return `${URI_PREFIX}/${this.params.domainId}/metadata`;
  }
}

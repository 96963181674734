/**
 * File: personalProfileGeneral.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';

import { optional, name, country, state } from '@core/common/validators';
import { FEATURE_GDPR_DELETE } from '@core/constants/features';

import UserProfileRequest from '@/api/userProfileRequest';
import ProfileUpdateRequest from '@/api/profileUpdateRequest';
import DeletingAccountModal from './deletingAccountModal.vue';
import ProfileMixinGeneral from '@/modules/profileMixin/profileMixinGeneral';
import TrustBrowserMixIn from '@/modules/personalProfile/trustBrowserMixIn';
import WebBrowserVerificationModal from '@/modules/confirm/browser/webBrowserVerificationModal.vue';
import ComponentMixIn from '@/modules/componentMixIn';

export default Vue.extend({
  name: 'personal-profile-general',

  mixins: [ProfileMixinGeneral, TrustBrowserMixIn, ComponentMixIn],

  validations: {
    form: {
      first_name: name,
      last_name: name,
      country: optional(country),
      state,
    },
  },

  data () {
    return {
      data: {
        first_name: this.session.firstName,
        last_name: this.session.lastName,
        country: undefined as string,
        state: undefined as string,
      },
      form: {
        first_name: this.session.firstName,
        last_name: this.session.lastName,
        country: '',
        state: '',
      },
      equalTo: 'personalProfileGeneralCard',
      profileLoading: false,
      formLoading: false,
      loading: false,
    };
  },

  computed: {
    fullName (): string {
      return `${this.data.first_name} ${this.data.last_name}`.trim();
    },
    isSso (): boolean {
      return this.session.sso;
    },
    isGdprDeleteFeatureAccessible (): boolean {
      return this.session.isFeatureAccessible(FEATURE_GDPR_DELETE);
    },
  },

  mounted () {
    this.load();
  },

  components: {
    DeletingAccountModal,
    WebBrowserVerificationModal,
  },

  methods: {

    load () {
      this.profileLoading = true;
      const request = new UserProfileRequest();

      this.$api.authorizedCall(request).then((data) => {
        Object.assign(this.data, data);
        this.data.first_name = this.session.firstName;
        this.data.last_name = this.session.lastName;
        this.resetForm();
        this.profileLoading = false;
      }).catch(() => {
        this.profileLoading = false;
      });
    },

    resetForm () {
      this.form = {
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        country: this.data.country.toUpperCase(),
        state: this.data.state.toUpperCase(),
      };
    },

    cancel () {
      this.$modal.hide();
      this.resetForm();
    },

    save () {
      this.formLoading = true;

      // Clear restricted fields on save
      Object.keys(this.form).forEach((field) => {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        if (this.restrictedFields[field]) {
          this.form[field] = '';
        }
      });

      const request = new ProfileUpdateRequest({
        name: [this.form.first_name, this.form.last_name].join(' '),
        country: this.form.country,
        state: this.form.state,
      });

      request.emitter.on('completed', (event) => {
        // force session reload
        this.$api.refreshToken(true);
        // fill card with updated information
        Object.keys(this.form).map((key) => {
          this.data[key] = this.form[key];
        });
        this.formLoading = false;
        this.$modal.hide();
      });
      request.emitter.on('error', (event) => {
        this.formLoading = false;
      });

      this.$api.authorizedCall(request);
    },
    async openDeleteAccountDialog () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (!await this.checkBrowserTrusted()) {
        return;
      }
      this.$modal.show(DeletingAccountModal);
    },

    addDataQA () {
      try {
        const country = document.getElementById('country-dropdown');
        const options = country.getElementsByClassName('option');
        for (const option of options) {
          option.setAttribute('data-qa', option.textContent);
        }
        const state = document.getElementById('state-dropdown');
        const stateOptions = state.getElementsByClassName('option');
        for (const option of stateOptions) {
          option.setAttribute('data-qa', option.textContent);
        }
      } catch (error) {
      }
    },
  },

});

import { render, staticRenderFns } from "./generalTab.vue?vue&type=template&id=40d7d546&scoped=true&lang=pug"
import script from "./generalTab.vue?vue&type=script&lang=ts"
export * from "./generalTab.vue?vue&type=script&lang=ts"
import style0 from "./generalTab.vue?vue&type=style&index=0&id=40d7d546&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d7d546",
  null
  
)

/* custom blocks */
import block0 from "./generalTab.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports
/**
 * File: usePasswordRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import passwordErrors from './errorHandlers/passwordErrors';
import { support } from '@core/constants/links';

export default class UsePasswordRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return `/account/webapp/restore/${this.params.code}/use`;
  }

  get body () {
    return {
      password: this.params.password,
      disconnect_remote_hosts: this.params.disconnect_remote_hosts,
    };
  }

  getJson () {
    // No JSON content expected here
    return null;
  }

  get errors () {
    return Object.assign({
      'rate limited': $t('Too many password change requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    }, passwordErrors());
  }
}

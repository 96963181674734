/**
 * File: mappers.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import { RasSubscriptionV2 } from '@/modelsv2/rasSubscription';
import RasSubscription, {
  EXTENDED_TRIAL,
  INITIAL_TRIAL,
  PEPAID_SUBSCRIPTION, PERMANENT,
  POSTPAID_SUBSCRIPTIONS
} from '@/models/rasSubscription';

export function fromRasSubscriptionV2toV1 (subscriptionV2Data: RasSubscriptionV2): RasSubscription {
  let subType = null;
  switch (subscriptionV2Data.subscriptionType) {
    case 'perm_ras':
      subType = PERMANENT;
      break;
    case 'ext_trial_ras':
      subType = EXTENDED_TRIAL;
      break;
    case 'in_app_trial_ras':
      subType = INITIAL_TRIAL;
      break;
    case 'postpaid_ras':
      subType = POSTPAID_SUBSCRIPTIONS;
      break;
    case 'prepaid_ras':
      subType = PEPAID_SUBSCRIPTION;
  }
  return new RasSubscription({
    ...subscriptionV2Data,
    licenseType: subType,
    isDeleted: subscriptionV2Data.deleted,
    isSublicense: !!subscriptionV2Data.parentUuid,
  });
}

/**
 * File: confirmBrowserRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';

export default class ConfirmBrowserInfoRequest extends ApiRequest<ConfirmBrowserParams, Json> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/account/webapp/confirm_browser/${this.params.code}`;
  }
}

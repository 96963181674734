/**
 * File: createSubsetRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class CreateSubsetRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/subsets`);
  }

  get body () {
    const params = this.params;
    return {
      name: params.name,
      amount: params.amount,
      reservation: params.reservation,
      expiration_date: params.expiration_date,
    };
  }

  get errors () {
    return {
      'available value is exceeded': $t('License key creation has failed: no more licenses available for allocation.'),
    };
  }
}

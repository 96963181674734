/**
 * File: deleteSocialBindingRequest.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class DeleteSocialBindingRequest extends ApiRequest {
  get method () {
    return 'DELETE';
  }

  get url () {
    return `/account/api/v1/profile/external_auth/${this.params.serviceId}`;
  }

  getJson () {
    // No JSON content expected here
    return null;
  }
}

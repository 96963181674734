/**
 * File: rtOptionsRequestV2.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtOptionsRequestV2 extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.country
   * @param {String} params.product
   * @param {Object} params.resources
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
    this.version = 2;
  }

  /**
   * @returns {String}
   */
  get uri () {
    return '/support_options';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      product_key: this.params.product,
      country: this.params.country,
      resources: this.params.resources,
    };
  }
}

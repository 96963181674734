/**
 * File: resendBrowserConfirmation.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Method } from '@core/api/apiRequest';
import resendEmailErrors from './errorHandlers/resendEmailErrors';

interface ResendBrowserConfirmationParams {
  forSecuritySettings?: boolean;
}

export default class ResendBrowserConfirmation extends ApiRequest<ResendBrowserConfirmationParams> {
  get method (): Method {
    return 'POST';
  }

  get body () {
    return { for_security_settings: this.params.forSecuritySettings };
  }

  get url () {
    return '/account/api/v1/browser/resend_confirmation';
  }

  get errors () {
    return resendEmailErrors();
  }
}

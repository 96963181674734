/**
 * File: subscriptionRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequestWithFallback from '@core/api/apiRequestWithFallback';
import Subscription from '@/models/subscription';

export default class SubscriptionRequest extends ApiRequestWithFallback {
  get cacheNameSpace () {
    return 'subscriptions';
  }

  get urlSuffix () {
    return `/api/v1/subscriptions/${this.params.uuid}`;
  }

  needFallback (response, data) {
    // this API will return empty list if subscription does not exist
    return !data.subscriptions || !data.subscriptions.length;
  }

  /**
   *
   * @returns {Subscription} - instance of Subscriptions models.
   */
  getSubscription () {
    if (this.data && this.data.subscriptions && this.data.subscriptions[0]) {
      return new Subscription(this.data.subscriptions[0]);
    }
    return null;
  }
}

/**
 * File: otherRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Go from '@/modules/go.vue';
import {
  FORCE_AUTH_APP_MFA_PAGE,
  GO_PAGE,
  MAINTENANCE_INFO_PAGE,
  RATE_LIMIT_PAGE, SSO_ERROR_PAGE, SSO_PRODUCT_LOGIN_PAGE,
  UNSUPPORTED_COUNTRY_PAGE,
  USER_CONSENT_PAGE
} from '@/routes/constants';
import ErrorPage from '@core/pages/error/index.vue';
import UserConsent from '@/modules/userConsent/index.vue';
import MaintenanceInfo from '@/modules/maintenanceInfo/index.vue';
import UnsupportedCountry from '@/modules/unsupportedCountry.vue';
import ForceAuthAppMfaPage from '@/modules/forceAuthAppMfaPage.vue';
import RateLimit from '@/modules/rateLimit.vue';
import SsoProductLoginPage from '@/modules/ssoProductLoginPage.vue';
import SsoErrorPage from '@/modules/ssoErrorPage.vue';

export default [
  {
    name: GO_PAGE,
    path: '/go',
    component: Go,
  },
  {
    name: USER_CONSENT_PAGE,
    path: '/user_consent',
    alias: '/data_reminder',
    component: UserConsent,
    meta: {
      title: 'General Data Protection Regulation',
    },
  },
  {
    path: '/gdpr',
    redirect: {
      name: USER_CONSENT_PAGE,
    },
  },
  {
    name: MAINTENANCE_INFO_PAGE,
    path: '/maintenance',
    component: MaintenanceInfo,
    meta: {
      title: 'Maintenance Information',
    },
  },
  {
    path: '/errors/unsupported_country',
    name: UNSUPPORTED_COUNTRY_PAGE,
    component: UnsupportedCountry,
    meta: {
      title: 'Access Denied',
    },
  },
  {
    path: '/errors/rate_limit',
    name: RATE_LIMIT_PAGE,
    component: RateLimit,
    meta: {
      title: 'Too Many Login Attempts',
    },
  },
  {
    path: '/errors/ba_mfa_required',
    name: FORCE_AUTH_APP_MFA_PAGE,
    component: ForceAuthAppMfaPage,
    meta: {
      title: 'Access Denied',
    },
  },
  {
    path: '*',
    component: ErrorPage,
  },
  {
    name: SSO_PRODUCT_LOGIN_PAGE,
    path: '/sso',
    component: SsoProductLoginPage,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: SSO_ERROR_PAGE,
    path: '/sso-error',
    component: SsoErrorPage,
    meta: {
      requiresAuth: false,
    },
  },
];


import Vue from 'vue';

import StatusMixin from '../statusMixin';

export default Vue.extend({
  name: 'subscription-status-bytebot',
  mixins: [StatusMixin],
});


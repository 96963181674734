<copyright>
  File: premierLineModal.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
modal(
  ref="modal",
  name="premierLineModal",
  :size="15",
  :data-name="$name()"
)
  template(slot="header", v-if="supportOption === 'phone'") {{ $t('Call Us Now') }} - {{ $t('Severity One, Urgent') }}
  template(slot="header", v-else-if="supportOption === 'skype'") {{ $t('Call Us Now Using Skype') }} - {{ $t('Severity One, Urgent') }}
  template(slot="content")
    .row
      .col-xs-12.col-sm-9
        .phone-text-area
          div.margin-bottom
            formatter(:text="$t('Hi, {userName}. Call the number below and dial your PIN to connect to Support.')")
              span(slot="userName") {{ userName }}
          // $t('Priority line: {number}')
          formatter.block.priority_phone(:text="$t('{name}: {number}')")
            template(slot="number")
              a(:href="'tel:' + priorityPhone.number") {{ priorityPhone.number }}
            template(slot="name") {{ priorityPhone.name }}
      .col-xs-12.col-sm-3
        .pin-box
          formatter(v-if="priorityPhone.pin", :text="$t('Your PIN is {pin}')")
            b(slot="pin") {{ priorityPhone.pin }}
  template(slot="footer")
    btn(v-if="supportOption === 'phone'", :disabled="loading", @click="onPhoneClick") {{ $t('Call Using Browser') }}
    btn(v-else-if="supportOption === 'skype'", :disabled="loading", @click="onSkypeClick") {{ $t('Call Using Skype') }}
    .pull-left-sm
      btn(
        color="white",
        @click="backButtonHandler",
        :data-name="$name('button-back')"
      ) {{ $t('Back') }}
      btn(
        color="white",
        @click="doneButtonHandler",
        :data-name="$name('button-done')"
      ) {{ $t('Cancel') }}
</template>

<script>

import { SKYPE_SUPPORT_LINK } from '@core/constants/links';

const PREMIER_PHONE = {
  title: 'Premier Phone',
  text: 'Priority line',
};

const PHONE_URLS = {
  def: 'https://panel.mightycall.com/c2c/widget.htm?gateway=sip.us.mightycall.com:1935&number=sip:b5b8d737-4aa4-49b7-b1ef-05bef96244db@widget;address=rtmfp&mask=%2b14257288265%3bParallels&title=Parallels&key=Sl91DI7prOvoiIR26Mwb&lang=en',
};

export default {
  name: 'support-priority-line-modal',

  props: ['supportInfo'],

  data () {
    return {
      loading: false,
      contactType: null, // rt or phone
      supportOption: null,
    };
  },

  methods: {
    flush () {
      this.supportOption = null;
    },

    show (supportOption = 'phone') {
      this.supportOption = supportOption;
      this.$refs.modal.show();
    },

    doneButtonHandler () {
      this.flush();
      this.$refs.modal.hide();
    },

    backButtonHandler () {
      this.$emit('backToSeveritySelector');
      this.flush();
      this.$refs.modal.hide();
    },

    onPhoneClick () {
      window.open(
        PHONE_URLS.def,
        'mywindow',
        'width=450,height=585,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,copyhistor y=no,resizable=yes'
      );
    },

    onSkypeClick () {
      window.open(SKYPE_SUPPORT_LINK);
    },
  },

  computed: {
    userName () {
      return this.$appData.session.name;
    },

    priorityPhone () {
      if (!this.supportInfo || !this.supportOption) {
        return {};
      }

      const phones = this.supportInfo.options[this.supportOption].phones || [];
      const phone = phones.find((phone) => {
        return phone.name === PREMIER_PHONE.title;
      });
      return { name: PREMIER_PHONE.text, number: phone.number, pin: phone.pin };
    },

  },
};

</script>

<style scoped lang="sass">
.priority_phone
  font-size: 25px

.pin-box
  border-radius: 2px
  border: solid 2px
  border-color: $grey
  text-align: center
  padding: $grid-step * 3

.pin-box b
  font-size: 25px
  display: block

</style>

/**
 * File: ConfirmAccountEmailMixin.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

export default {

  props: {
    appData: Object,
    code: String,
  },

  data () {
    return {
      newEmail: '',
      error: false,
      loading: true,
    };
  },

  mounted () {
    this.load();
  },

  methods: {

    errorHandler () {
      this.error = true;
    },

    load () {
      this.$api.call(this.getChangeRequest())
        .then(data => {
          return this.$api.call(this.getConfirmRequest())
            .then(() => {
              this.newEmail = data.new_email;
              this.loading = false;
            });
        })
        .catch(this.errorHandler);
    },

    signIn () {
      this.$router.push({
        name: 'login',
      });
    },

    resend () {
      this.$api.call(this.getResendRequest())
        .then(() => {
          this.loading = false;
        })
        .catch(this.errorHandler);
    },

  },

};



import Vue from 'vue';
import { maxLength, required } from '@core/common/validators';
import { uuidv4 } from '@core/common/utils';
import CheckPackagedLicensesGenerationProgressRequest, {
  PackagedLicensesGenerationProgressData,
  PackagedLicensesGenerationStatus
} from '@/api/checkPackagedLicensesGenerationProgressRequest';
import GeneratePackagedLicensesRequest, { GeneratePackagedLicensesRequestParams } from '@/api/generatePackagedLicensesRequest';
import OemUploadSerialNumbersCsvRequest from '@/api/oemUploadSerialNumbersCsvRequest';
import GetPackagedLicensesFailedDetailsRequest from '@/api/getPackagedLicensesFailedDetailsRequest';
import FileUploader from '@/ui/file-uploader/index.vue';
import { HTTP_CODES } from '@core/constants/http';

function getDefaultProgressData (): PackagedLicensesGenerationProgressData {
  return {
    status: PackagedLicensesGenerationStatus.Unknown,
    total: 0,
    succeeded: 0,
    failed: 0,
  };
}

function getDefaultFormValues () {
  return {
    displayName: '',
    seller: '',
    isTrial: false,
    rationale: '',
    serialNumbers: [] as string[],
    serialNumbersPreloadedListId: null as string,
    generationProgress: getDefaultProgressData(),
    returnedUnits: false,
  };
}

const SERIAL_NUMBER_MAX_LENGTH = 32;
const MAX_SERIAL_NUMBERS_MANUALLY_ADDED_QUANTITY = 100;
enum LicensesReturnMode {
  REVOKE = 1,
  KEEP = 2
}

export default Vue.extend({
  name: 'generate-packaged-licenses-modal',
  components: { FileUploader },
  data () {
    return {
      SERIAL_NUMBER_MAX_LENGTH,
      MAX_SERIAL_NUMBERS_MANUALLY_ADDED_QUANTITY,
      loading: false,
      form: getDefaultFormValues(),
      trackerId: null as string,
      serialNumber: '',
      uploadedFileName: null as string,
      wrongCsvUploaded: false,
      licensesReturnMode: LicensesReturnMode.KEEP as LicensesReturnMode,
    };
  },
  validations: {
    form: {
      displayName: {
        required,
        maxLength: maxLength(255),
      },
      seller: {
        required,
        maxLength: maxLength(255),
      },
      isTrial: {
        required,
      },
      rationale: {
        required,
        maxLength: maxLength(255),
      },
      serialNumbers: {
        amount: function (value, parentVm) {
          return value.length > 0;
        },
        checkReturnedUnitsLimit: function (value, parentVm) {
          if (parentVm.returnedUnits) {
            if (parentVm.serialNumbersPreloadedListId) {
              return parentVm.generationProgress.total <= 10;
            } else {
              return value.length <= 10;
            }
          } else {
            return true;
          }
        },
      },
    },
  },
  created () {
    this.init();
  },
  computed: {
    licenseTypes (): { value: boolean; text: string }[] {
      return [
        { value: true, text: this.$t('1-month license (trial)') },
        { value: false, text: this.$t('12-month license') },
      ];
    },
    isGenerateNotStarted (): boolean {
      return this.form.generationProgress.status === PackagedLicensesGenerationStatus.Unknown;
    },
    isPackGenerated (): boolean {
      return this.form.generationProgress.status === PackagedLicensesGenerationStatus.Finished;
    },
    isGenerateInProgress (): boolean {
      return this.form.generationProgress.status === PackagedLicensesGenerationStatus.InProgress;
    },
    licensesQuantity (): number {
      if (this.uploadedFileName) {
        return this.form.generationProgress.total;
      } else {
        return this.form.serialNumbers.length;
      }
    },
    licensesReturnModeOptions (): { text: string; value: number }[] {
      return [
        { text: this.$t('Keep'), value: LicensesReturnMode.KEEP },
        { text: this.$t('Revoke'), value: LicensesReturnMode.REVOKE },
      ];
    },
  },
  methods: {
    init (e?: { name: string }, trackerId?: string) {
      this.loading = false;
      this.form = getDefaultFormValues();
      this.serialNumber = '';
      this.trackerId = null;
      this.uploadedFileName = null;
      this.wrongCsvUploaded = false;
      this.$v.$reset();

      if (trackerId) {
        this.loading = true;
        this.trackerId = trackerId;
        this.$nextTick(this.pollGenerateProgress);
      }
    },
    handleError (e: Error) {
      this.$toast.show({
        text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
        color: 'red',
      });
      this.$modal.hide();
    },
    async pollGenerateProgress () {
      const request = new CheckPackagedLicensesGenerationProgressRequest({ trackerId: this.trackerId });
      try {
        await this.$api.authorizedCall(request);
        this.form.generationProgress = request.data;
        if (!this.isPackGenerated) {
          setTimeout(this.pollGenerateProgress, 1000);
        } else {
          this.$emit('generate');
          this.loading = false;
        }
      } catch (e) {
        this.handleError(e);
      }
    },
    async generate () {
      const params: GeneratePackagedLicensesRequestParams = Object.assign({}, this.form);
      // @ts-ignore eliminate unexpected param
      delete params.generationProgress;
      if (this.form.serialNumbersPreloadedListId) {
        delete params.serialNumbers;
      } else {
        delete params.serialNumbersPreloadedListId;
      }
      const request = new GeneratePackagedLicensesRequest(params);

      this.loading = true;

      try {
        await this.$api.authorizedCall(request);
        this.trackerId = request.getTrackerId();
        this.$nextTick(this.pollGenerateProgress);
      } catch (e) {
        this.handleError(e);
      }
    },
    deleteSerialNumber (serialNumber: string) {
      this.form.serialNumbers = this.form.serialNumbers.filter((item) => item !== serialNumber);
    },
    addSerialNumbers () {
      const serialNumbers = this.serialNumber.split(/,|;| /).filter((s) => s.length > 0);
      if (serialNumbers.length > 0) {
        if (this.form.serialNumbersPreloadedListId) {
          this.form.serialNumbers = serialNumbers;
        } else {
          this.form.serialNumbers = this.form.serialNumbers.concat(serialNumbers);
        }
        this.serialNumber = '';
        this.uploadedFileName = null;
        this.form.serialNumbersPreloadedListId = null;
        this.wrongCsvUploaded = false;
      }
    },
    async uploadCsv (files: File[]) {
      const request = new OemUploadSerialNumbersCsvRequest({ file: files[0] });
      this.loading = true;
      try {
        await this.$api.authorizedCall(request);
        const uploadInfo = request.getUploadInfo();
        this.form.serialNumbers = [uuidv4()]; // Add fake serial number to make validator happy
        this.uploadedFileName = files[0].name;
        this.wrongCsvUploaded = false;
        Object.assign(this.form.generationProgress, uploadInfo.uploadInfo);
        this.form.serialNumbersPreloadedListId = uploadInfo.serialNumbersPreloadedListId;
      } catch (e) {
        if (e.response.status === HTTP_CODES.BAD_REQUEST) {
          this.wrongCsvUploaded = true;
        } else {
          this.handleError(e);
        }
      } finally {
        this.loading = false;
      }
    },
    async downloadFailedDetails () {
      const request = new GetPackagedLicensesFailedDetailsRequest({ trackerId: this.trackerId });
      this.loading = true;
      try {
        await this.$api.authorizedCall(request);
        location.href = `${location.origin}/license/api/v1/packaged_licenses/${request.data.downloadToken}/download_failed_details`;
      } catch (e) {
        this.handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
});


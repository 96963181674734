/**
 * File: model.ts
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import { toCamelCase } from '@core/common/utils';
import { toDateObject, isDateString } from '@core/common/format/date';

interface SetDataProps {
  ignoreExistingProps?: boolean
}

export function setData (data, result = {}, params: SetDataProps = {}) {
  if (!data) {
    return data;
  }

  if (Array.isArray(data)) {
    result = data.map((el) => setData(el));
  } else if (data.constructor === Object) {
    Object.keys(data).forEach((key) => {
      let propName = toCamelCase(key);
      if (!params.ignoreExistingProps && propName in result) {
        propName = `__${propName}`;
      }
      result[propName] = setData(data[key]);
    });
  } else if (isDateString(data)) {
    result = toDateObject(data);
  } else {
    result = data;
  }
  return result;
}

export default class CoreModel {

  constructor (data = {}) {
    setData(data, this);
  }

  update (data) {
    setData(data, this, { ignoreExistingProps: true });
  }

  copy () {
    let prototype = Object.getPrototypeOf(this),
      copy = Object.create(prototype);

    return Object.assign(copy, this);
  }

}

/**
 * File: companyUsersUpdateRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class CompanyUsersUpdateRequest extends ApiRequest {
  constructor (params, companyUuid) {
    super(params);
    this.company_uuid = companyUuid;
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/account/api/v1/ba/' + this.company_uuid + '/users/manage';
  }

  get body () {
    const groups = this.params.addGroups.map((name) => {
      return {
        name,
        value: true,
      };
    }).concat(this.params.removeGroups.map((name) => {
      return {
        name,
        value: false,
      };
    }));

    return {
      groups,
      users: this.params.userUuids,
      notes: this.params.notes, // TODO: implement on backend
    };
  }
}

/**
 * File: ConfirmAccountEmailChangePhase2.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ConfirmAccountEmailMixin from '../ConfirmAccountEmailChange/ConfirmAccountEmailMixin';

import {
  ConfirmEmailChangeNewGet,
  ConfirmEmailChangeNewConfirm
} from '@/api/confirmEmailChangeNew';

import SecurityNoticeText from '@/modules/personalProfile/securityNoticeText';

export default {
  name: 'confirm-account-email-change-phase2',

  mixins: [ConfirmAccountEmailMixin],

  components: {
    SecurityNoticeText,
  },

  methods: {
    getChangeRequest () {
      return new ConfirmEmailChangeNewGet({
        actionCode: this.code,
      });
    },
    getConfirmRequest () {
      return new ConfirmEmailChangeNewConfirm({
        actionCode: this.code,
      });
    },
    openNoticeModal () {
      this.$refs.securityNoticeModal.show();
    },

  },

};

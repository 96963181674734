/**
 * File: combinedApiRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

// @ts-ignore
import mitt from 'mitt';
import { Dictionary } from '../common/types';
import ApiRequest, { Json } from './apiRequest';

enum RequestStatus {
  Error,
  New,
  Started,
  Completed,
}

interface CombinedApiRequestParams {
  // Throw on error if one of request was failed
  integrity?: boolean;
  consistently?: boolean;
}

/**
 * A class that combines requests (ApiRequest) and their results into one.
 */
export default class CombinedApiRequest {
  data: Json;
  emitter: any;
  response: Response;
  error: Error;

  _requests: Dictionary<ApiRequest>;
  _status: Dictionary<RequestStatus>;

  integrity: boolean;
  consistently: boolean;

  constructor ({ integrity = false, consistently = false }: CombinedApiRequestParams = {}) {
    this.emitter = mitt();
    this._requests = {};
    this._status = {};
    this.integrity = integrity;
    this.consistently = consistently;
    this.data = null;
  }

  /**
   * Register one more request.
   * @param {string} key - request key name.
   * @param {ApiRequest} request - API request.
   *
   */
  addRequest (key, request) {
    this._requests[key] = request;
    this._status[key] = RequestStatus.New;

    request.emitter.on('started', () => { this.onItemStarted(key); });
    request.emitter.on('completed', () => { this.onItemFetched(key); });
    request.emitter.on('error', (error) => { this.onItemError(key, error); });
  }

  getRequests (): ApiRequest[] {
    return Object.values(this._requests);
  }

  getRequestsName () : string[] {
    return Object.keys(this._requests);
  }

  getRequest (key: string): ApiRequest {
    return this._requests[key] || null;
  }

  delRequest (key: string) {
    delete this._requests[key];
    delete this._status[key];
  }

  getRequestStatus (key: string) {
    return this._status[key];
  }

  getFailedRequests () {
    return Object.keys(this._status).filter((k) => this._status[k] === RequestStatus.Error);
  }

  /**
   * @return {number} amount of registered request.
   */
  get total () {
    return Object.keys(this._requests).length;
  }

  get fetched () {
    let self = this;
    return Object.keys(this._requests).filter(function (key) {
      return self.getRequestStatus(key) === RequestStatus.Completed;
    }).length;
  }

  get fetching () {
    let self = this;
    return Object.keys(this._requests).filter(function (key) {
      return self.getRequestStatus(key) === RequestStatus.Started;
    }).length;
  }

  get progress () {
    return Math.floor(this.fetched / this.total * 100);
  }

  get loadPromise () {
    let allPromises = Object.keys(this._requests).map((key) => {
      let promiseForKey = this._requests[key].load();
      if (this.integrity) {
        return promiseForKey;
      } else {
        return promiseForKey.catch(() => { return {}; });
      }
    });
    if (this.consistently && allPromises.length > 1) {
      return allPromises.reduce((a, b) => a.then(() => b));
    } else {
      return Promise.all(allPromises);
    }
  }

  combineData (data) {
    return data;
  }

  load () {
    this._status = {};

    return this.loadPromise
      .then((data) => {
        this.data = this.combineData(data);
        return this.data;
      });
  }

  onItemStarted (requestKey) {
    this._status[requestKey] = RequestStatus.Started;
    this.emitter.emit('started', requestKey);
  }

  onItemFetched (requestKey) {
    this._status[requestKey] = RequestStatus.Completed;
    this.emitter.emit('itemFetched', requestKey);

    if (this.fetched === this.total) {
      this.onCompleted();
    }
  }

  onCompleted () {
    this.emitter.emit('completed');
  }

  onItemError (requestKey, error) {
    this._status[requestKey] = RequestStatus.Error;
    this.emitter.emit('error', requestKey, error);
  }

  setAuthHeader (name, value) {
    this.getRequests().forEach((r) => {
      r.setAuthHeader(name, value);
    });
  }

  getFailed () {
    return Object.keys(this._status).filter(k => this._status[k] === RequestStatus.Error);
  }

  dropCache () {
    this.getRequests().forEach((r) => r.dropCache());
  }

  dropFullCache () {
    this.getRequests().forEach((r) => r.dropFullCache());
  }
}

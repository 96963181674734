
import Vue from 'vue';
import ComponentMixIn from '@/modules/componentMixIn';
import ProductCardMixIn from './productCardMixIn';
import RasFarmsCountersRequest from '@/api/rasFarmsCountersRequest';
import { RAS_DOWNLOADS_PAGE } from '@/routes/constants';

export default Vue.extend({
  name: 'product-card-ras',
  mixins: [ProductCardMixIn, ComponentMixIn],

  // TODO: investigate vue - typescript - mixins problem https://jira.prls.net/browse/CPCLOUD-16280
  props: {
    item: {
      type: Object,
    },
  },

  data: function () {
    return {
      farmsCountersLoading: true,
      prepaidFarmsAmount: null,
      postpaidFarmsAmount: null,
      RAS_DOWNLOADS_PAGE,
    };
  },

  created () {
    const farmsCountersRequest = new RasFarmsCountersRequest();
    this.authorizedCall(farmsCountersRequest, 'farmsCountersLoading').then((data) => {
      if (this.item.prepaidContracts) {
        this.prepaidFarmsAmount = data.prepaid_total;
      }
      if (this.item.postpaidContracts) {
        this.postpaidFarmsAmount = data.postpaid_total;
      }
    });
  },

  computed: {
    prepaidTitle (): string {
      return this.prepaidFarmsAmount && this.postpaidFarmsAmount ?
        this.$t('Registered farms, non-SPLA') :
        this.$t('Registered farms');
    },
  },
});

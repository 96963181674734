/**
 * File: getDownloadLinksRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';

export default class GetDownloadLinksRequest extends ApiRequest {
  get url () {
    return `/account/webapp/download_profile/${this.params.code}/links`;
  }
}

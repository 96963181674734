

import { defineComponent, PropType } from 'vue';
import Session from '@/models/session';
import HeaderView from '@/ui/header/index.vue';
import FooterView from '@/ui/footer/index.vue';
import ErrorPage from '@core/pages/error/index.vue';
import LanguageSwitcher from '@/ui/languageSwitcher.vue';
import MaintenanceNotification from '@/ui/maintenanceNotification/index.vue';
import SessionExpiration from '@/modules/sessionExpiration/index.vue';
import consent from '@/data/consent';
import PdfcNotification from '@/modules/pdfcNotification.vue';
import RasNfrNotification from '@/modules/ras/rasNfrNotification.vue';
import InvitationNotification from '@/modules/invitationNotification.vue';
import RasTechnicalPreviewNotification from '@/modules/rasTechnicalPreviewNotification/index.vue';
import BottomLinks from '@/ui/bottomLinks.vue';
import { LEGAL_NOTICES_URL, PRIVACY_POLICY_URL } from '@core/constants/links';
import DaasNotificationBanner from '@/modules/daasNotificationBanner/index.vue';
import { PRODUCT_KEY_NAME_DAAS, PRODUCT_KEY_NAME_PBI } from '@core/constants/subscriptions';
import PbiNotificationBanner from '@/modules/pbiNotificationBanner/index.vue';

export default defineComponent({
  props: {
    appData: Object as PropType<AppData> | undefined,
    error: Boolean,
    /**
     * Possible values: default, policyLinks, switcher
     */
    footer: {
      type: String,
      default: function () {
        return this.$route.meta.requiresAuth ? 'default' : 'policyLinks';
      },
    },
    alignCenter: {
      type: Boolean,
      default: function () {
        return !this.$route.meta.requiresAuth;
      },
    },
    alignByVertical: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    stretchContent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RasNfrNotification,
    HeaderView,
    FooterView,
    ErrorPage,
    SessionExpiration,
    MaintenanceNotification,
    LanguageSwitcher,
    PdfcNotification,
    InvitationNotification,
    BottomLinks,
    RasTechnicalPreviewNotification,
    DaasNotificationBanner,
    PbiNotificationBanner,
  },
  data () {
    return {
      year: (new Date()).getFullYear(),
      bottomLinksOptions: [LEGAL_NOTICES_URL, PRIVACY_POLICY_URL],
    };
  },
  computed: {
    session (): Session {
      return this.appData.session || {};
    },
    products (): Products {
      return this.appData?.products;
    },
    hasDaasProducts (): boolean {
      const businessProducts = this.products?.business;
      return Array.isArray(businessProducts) && businessProducts.includes(PRODUCT_KEY_NAME_DAAS);
    },
    hasPbiProducts (): boolean {
      const businessProducts = this.products?.business;
      return Array.isArray(businessProducts) && businessProducts.includes(PRODUCT_KEY_NAME_PBI);
    },
    isShowDaasBanner (): boolean {
      return this.$appData.session && this.$appData.session.businessDomain && !this.hasDaasProducts;
    },
    isShowPbiBanner (): boolean {
      return this.$appData.session && this.$appData.session.businessDomain && !this.hasPbiProducts;
    },
    authenticated (): boolean {
      return !!(this.session.authenticated && this.appData.products && !consent.required);
    },
  },
});


/**
 * File: newAuthenticatorAppSecretRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Json } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

/* eslint-disable camelcase */
interface NewAuthenticatorAppSecretResponseData {
  setup_account_link: string;
  secret: string;
}
/* eslint-enable camelcase */

export default class NewAuthenticatorAppSecretRequest extends ApiRequest<Json, NewAuthenticatorAppSecretResponseData> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/auth_apps/secret`;
  }

  getSecret (): { setupAccountLink: string; secret: string } {
    return { setupAccountLink: this.data.setup_account_link, secret: this.data.secret };
  }
}

/**
 * File: rasFarmsCountersRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class RasFarmsCountersRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'RasFarmsCountersRequest';
  }

  get url () {
    return '/ras/api/v1/hosts_counters';
  }
}

/**
 * File: acceptNdaRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class AcceptNdaRequest extends ApiRequest {
  get url () {
    return '/account/api/v1/nda/accept';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      nda_id: this.params.id,
    };
  }
}

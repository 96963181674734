/**
 * File: rtCreateTicketWithoutAuthRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import RtRequest from './rtRequest';

export default class RtCreateTicketWithoutAuthRequest extends RtRequest {
  get uri () {
    return '/noauth/ticket';
  }

  get method () {
    return 'POST';
  }

  get body () {
    const fields = {
      description: this.params.description,
      scenario: this.params.scenario,
    };
    if (this.params.email) {
      fields.email = this.params.email;
    }
    return { fields };
  }
}

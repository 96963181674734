
import Vue from 'vue';
import ComponentMixin from '@/modules/componentMixIn';
import Subscription from '@/models/subscription';

export default Vue.extend({
  name: 'renewal-notification-modal',
  mixins: [ComponentMixin],
  props: {
    subscription: {
      type: Subscription,
      default: null,
    },
  },
  methods: {
    okClickHandler (): void {
      this.$modal.hide();
    },
  },
});

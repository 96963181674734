import { render, staticRenderFns } from "./registerBusinessAccountModal.pug?vue&type=template&id=1a590d66&lang=pug&external"
import script from "./registerBusinessAccountModal.ts?vue&type=script&lang=ts&external"
export * from "./registerBusinessAccountModal.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./registerBusinessAccountModal.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports
/**
 * File: kbRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { appData } from '@/data/appData';
import { Dictionary } from '@core/common/types';

export interface KbRequestParams {
  uri: string;
}

export default class KbRequest<ParamsType extends KbRequestParams, ResponseData> extends ApiRequest<ParamsType, ResponseData> {
  get cacheNameSpace (): string {
    return 'kb';
  }

  get uri (): string {
    if (this.params.hasOwnProperty('uri')) {
      return this.params.uri;
    }
    throw new Error('get uri () not implemented');
  }

  get url (): string {
    return appData.session.kbUrl + this.uri;
  }

  get options (): Dictionary<string> {
    const options = super.options;
    options.mode = 'cors';
    options.credentials = 'include';
    return options;
  }
}


import Vue from 'vue';

import OneTimePasswordMixin from '@/modules/oneTimePasswordMixin';
import AlreadyHaveAuthenticationAppForm
  from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/alreadyHaveAuthenticationAppForm.vue';

export default Vue.extend({
  name: 'already-have-authentication-app-modal',

  mixins: [OneTimePasswordMixin],
  props: {
    asInline: Boolean,
  },

  components: { AlreadyHaveAuthenticationAppForm },
});


/**
 * File: trackReferrer.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

const HREF = location.href;

export default function (sessionStorage) {
  const storeKey = `referrer-${process.env.VUE_APP_NAME}`;

  // Keep only the first entrance
  if (!sessionStorage.get(storeKey)) {
    sessionStorage.set(storeKey, { direct: !document.referrer, href: document.referrer || HREF });
  }
}

/**
 * File: langs.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */
import { Dictionary } from '../common/types';

export const LANGUAGES: Dictionary<string>[] = [
  { text: 'English', value: 'en_US' },
  { text: 'German / Deutsch', value: 'de_DE' },
  { text: 'Italian / Italiano', value: 'it_IT' },
  { text: 'French / Français', value: 'fr_FR' },
  { text: 'Portuguese / Português', value: 'pt_BR' },
  { text: 'Spanish / Español', value: 'es_ES' },
  { text: 'Simplified Chinese / 简体中文', value: 'zh_CN' },
  { text: 'Traditional Chinese / 繁體中文', value: 'zh_TW' },
  { text: 'Japanese / 日本語', value: 'ja_JP' },
  { text: 'Korean / 한국어', value: 'ko_KR' }
];

export const WEBSITE_LANGUAGES: Dictionary<string> = {
  en_US: 'en',
  de_DE: 'de',
  it_IT: 'it',
  fr_FR: 'fr',
  pt_BR: 'br',
  es_ES: 'es',
  zh_CN: 'cn',
  zh_TW: 'hk',
  ja_JP: 'jp',
  ko_KR: 'kr'
};

export const BULA_LANGUAGES: Dictionary<string> = {
  en_US: 'en',
  de_DE: 'de',
  fr_FR: 'fr',
  ja_JP: 'jp',
  zh_CN: 'tw',
  zh_TW: 'tw'
};

export const KB_LANGUAGES: Dictionary<string> = {
  en_US: 'en',
  de_DE: 'de',
  it_IT: 'it',
  fr_FR: 'fr',
  pt_BR: 'en',
  es_ES: 'es',
  zh_CN: 'cn',
  zh_TW: 'cn',
  ja_JP: 'jp',
  ko_KR: 'en'
};

export const PLURALIZATION_RULES: Dictionary<(number) => boolean|number> = {
  en_US (n) { return n !== 1; },
  de_DE (n) { return n !== 1; },
  it_IT (n) { return n !== 1; },
  fr_FR (n) { return n > 1; },
  pt_BR (n) { return n > 1; },
  es_ES (n) { return n !== 1; },
  zh_CN (n) { return 0; },
  zh_TW (n) { return 0; },
  ja_JP (n) { return 0; },
  ko_KR (n) { return 0; }
};

export const DEFAULT_LANGUAGE = 'en';

export function getBulaLanguage (isoLocale: string) {
  return BULA_LANGUAGES[isoLocale] || DEFAULT_LANGUAGE;
}

export function getWebsiteLanguage (isoLocale: string) {
  return WEBSITE_LANGUAGES[isoLocale] || DEFAULT_LANGUAGE;
}

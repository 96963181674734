
import Locale from '@/plugins/locale';
import StepAbstract from './stepAbstract.vue';

export default StepAbstract.extend({
  name: 'step-configure-saml-integration',
  props: {
    title: {
      default: () => { return Locale.i18n.t('Configure SCIM Integration'); },
    },
    optional: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    scimSupported: {
      get (): boolean {
        return this.settings.stepsDone[this.stepName] !== false;
      },
      set (val: boolean) {
        this.emitDone(val ? null : false);
      },
    },
    configurationInIdpIsDone: {
      get (): boolean {
        return this.settings.stepsDone[this.stepName] === true;
      },
      set (val: boolean) {
        this.emitDone(val ? true : null);
      },
    },
    done (): boolean {
      return this.settings.stepsDone[this.stepName] !== null;
    },
  },
});

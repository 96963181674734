<copyright>
File: mac.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
div
  list
    list-item(labelWidth="6")
      .line-height-3x(slot="label")
        .text-success(v-if="server.macSubscription") {{ $t('Activated') }}
        .text-danger(v-else) {{ $t('Deactivated') }}
      template(slot="value")
        btn(
          v-if="server.macSubscription",
          size="small",
          color="red-stroke",
          :disabled="deactivating",
          @click.native="$emit('deactivateClick')",
          :data-name="$name('button-mac-deactivate')"
        ).pull-right {{ $t('Deactivate Mac Management') }}
    list-item
      template(slot="label") {{ $t('Offline Use') }}
      template(slot="value") {{ server.macOfflineAllowed === true ? $t('Allowed') : (server.macOfflineAllowed === false ? $t('Not Allowed') : '') }}
    list-item
      template(slot="label") {{ $t('Activation Key') }}
      template(slot="value")
        router-link(
          :to="{ name: 'pmmSubscriptionDetails', params: { id: server.macSubscriptionParentUuid } }",
          :data-name="$name('link-mac-subscription-details')"
        )
          | {{ server.macLicKey }}
    list-item
      template(slot="label") {{ $t('Activation Date') }}
      template(slot="value") {{ formatDate(server.macActivationDate) }}
</template>
<script>
export default {
  name: 'details-modal-mac',

  props: {
    server: {
      type: Object,
    },
    form: {
      type: Object,
      default () {
        return {
          notes: '',
        };
      },
    },
    deactivating: Boolean,
  },
};
</script>

/**
 * File: ttlRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

interface TtlParams {
  refresh?: boolean;
}

interface TtlResponse {
  ttl: number;
}

export default class TTLRequest extends ApiRequest<TtlParams, TtlResponse> {
  get url () {
    let url = '/account/webapp/session/ttl';
    if (this.params.refresh) {
      url += '?refresh=1';
    }
    return url;
  }

  get cacheNameSpace () {
    return undefined;
  }
}

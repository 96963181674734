/**
 * File: personalProfile.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

import PersonalProfileGeneral from './personalProfileGeneral/personalProfileGeneral.vue';
import PersonalProfileSecurity from './personalProfileSecurity/personalProfileSecurity.vue';
import NotificationSettings from '@/modules/notificationSettings/index.vue';
import { GENERAL_PAGE, NOTIFICATIONS_PAGE, PERSONAL_PROFILE_SCOPE, SECURITY_PAGE } from '@/routes/constants';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({

  name: 'personal-profile',
  mixins: [BreadcrumbsMixin],
  props: {
    page: {
      type: String,
    },
    enableTotp: {
      type: Boolean,
    },
  },

  data () {
    return {
      GENERAL_PAGE,
      NOTIFICATIONS_PAGE,
      SECURITY_PAGE,
    };
  },

  components: {
    PersonalProfileGeneral,
    PersonalProfileSecurity,
    NotificationSettings,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  computed: {
    subpages (): MenuItem[] {
      const subPages = [];
      subPages.push({
        text: this.$t('General'),
        link: { name: PERSONAL_PROFILE_SCOPE, params: { page: GENERAL_PAGE } },
      });
      if (!this.$appData.session.sso) {
        subPages.push({
          text: this.$t('Security'),
          link: { name: PERSONAL_PROFILE_SCOPE, params: { page: SECURITY_PAGE } },
        },
        {
          text: this.$t('Notifications'),
          link: { name: PERSONAL_PROFILE_SCOPE, params: { page: NOTIFICATIONS_PAGE } },
        });
      }
      return subPages;
    },
  },

});

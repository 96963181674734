/**
 * File: personalProfileSecurity.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';

import { MFA_METHOD } from '@core/constants/mfa';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import { FEATURE_MANAGE_TRUSTED_CHECK, FEATURE_TWO_STEP_VERIFICATION_VIA_TOTP } from '@core/constants/features';
import { KB_LINK_EXTENDED_SESSION } from '@core/constants/links';

import ChangeSessionLifetimeRequest from '@/api/changeSessionLifetimeRequest';
import AuthenticatorAppsListRequest from '@/api/authenticatorAppsListRequest';
import BrowserListRequest from '@/api/browserListRequest';
import BrowserTrustRequest from '@/api/browserTrustRequest';
import BrowsersDeleteRequest from '@/api/browsersDeleteRequest';
import DeleteSocialBindingRequest from '@/api/deleteSocialBindingRequest';
import GetSocialBindingsRequest from '@/api/getSocialBindingsRequest';
import componentMixIn from '@/modules/componentMixIn';
import searchFilterMixin from '@/modules/searchFilterMixin';
import columnsObserverMixin from '@/modules/columnsObserverMixin';
import {
  Browser,
  BROWSER_ENTITY_ID,
  SESSION_ACTIVE,
  SESSION_CURRENT,
  SESSION_NOT_ACTIVE
} from '@/models/trustedEntity';
import FilterDropdownMy from '@/ui/filterDropdown/index.vue';
import PasswordChange from '../passwordChange/index.vue';
import EmailChange from '../emailChange/emailChange.vue';
import AppleDisconnect from './appleDisconnect.vue';
import TableFilter from '@/modules/tableFilter/index.vue';
import {
  APPLE_SOCIAL_SERVICE,
  FACEBOOK_SOCIAL_SERVICE,
  GOOGLE_PLAY_SOCIAL_SERVICE
} from '@/modules/personalProfile/personalProfileSecurity/constants';
import MfaSettings from './mfaSettings/mfaSettings.vue';
import AuthenticatorApp from '@/models/authenticatorApp';
import AuthenticatorApps from './mfaSettings/authenticatorApps.vue';
import DeletingAuthenticatorAppModal from './mfaSettings/deletingAuthenticatorAppModal.vue';
import DeletingLastAuthenticatorAppModal from './mfaSettings/deletingLastAuthenticatorAppModal.vue';
import { MFA_SETTINGS_CARD } from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/constants';
import AuthenticatorAppPropertiesModal from './mfaSettings/authenticatorAppPropertiesModal.vue';
import AddNewAuthenticatorAppModal from './mfaSettings/addNewAuthenticatorAppModal.vue';
import NewAuthenticatorAppSecretRequest from '@/api/newAuthenticatorAppSecretRequest';
import SwitchMfaMethodRequest from '@/api/switchMfaMethodRequest';
import AlreadyHaveAuthenticatorAppModal from './mfaSettings/alreadyHaveAuthenticationAppModal.vue';
import AddNewAuthenticatorAppRequest from '@/api/addNewAuthenticatorAppRequest';
import ChangeAuthenticatorAppPropertiesRequest from '@/api/changeAuthenticatorAppPropertiesRequest';
import DeleteAuthenticatorAppRequest from '@/api/deleteAuthenticatorAppRequest';
import UpdateTwoStepVerificationSettingsWithOtpModal
  from './mfaSettings/updateTwoStepVerificationSettingsWithOtpModal.vue';
import DisableMfaRequest from '@/api/disableMfaRequest';
import RescueCodesModal from './mfaSettings/rescueCodesModal.vue';
import UpdateTwoStepVerificationSettingsWithPasswordModal
  from './mfaSettings/updateTwoStepVerificationSettingsWithPasswordModal.vue';
import WebBrowserVerificationModal from '@/modules/confirm/browser/webBrowserVerificationModal.vue';
import TrustBrowserMixIn from '@/modules/personalProfile/trustBrowserMixIn';

export default Vue.extend({
  name: 'personal-profile-security',

  mixins: [componentMixIn, searchFilterMixin, columnsObserverMixin, TrustBrowserMixIn],

  props: {
    enableTotp: {
      type: Boolean,
    },
  },

  components: {
    AppleDisconnect,
    FilterDropdownMy,
    PasswordChange,
    TableFilter,
    EmailChange,
    MfaSettings,
    AuthenticatorApps,
    DeletingAuthenticatorAppModal,
    DeletingLastAuthenticatorAppModal,
    AuthenticatorAppPropertiesModal,
    AddNewAuthenticatorAppModal,
    AlreadyHaveAuthenticatorAppModal,
    UpdateTwoStepVerificationSettingsWithOtpModal,
    RescueCodesModal,
    UpdateTwoStepVerificationSettingsWithPasswordModal,
    WebBrowserVerificationModal,
  },

  data () {
    return {
      session: this.$appData.session,
      SESSION_CURRENT,
      SESSION_ACTIVE,
      entitiesLoading: false,

      modalEntity: new Browser({}) as Browser,
      modalLoading: false,

      authenticatorApps: [] as AuthenticatorApp[],
      modalAuthenticatorApp: {} as AuthenticatorApp,
      authenticatorAppsLoading: false,
      newMfaMethod: null as MFA_METHOD,
      rescueCodes: [] as string[],
      MFA_SETTINGS_CARD,

      setupAccountLink: '',
      secret: '',
      newAuthenticatorAppSecretLoading: false,

      socialBindings: {},
      socialBindingsNames: {
        [APPLE_SOCIAL_SERVICE]: 'Apple',
        [FACEBOOK_SOCIAL_SERVICE]: 'Facebook',
        [GOOGLE_PLAY_SOCIAL_SERVICE]: 'Google',
      },
      socialBindingLoading: false,
      currentBindingForDisconnect: undefined,

      table: {
        name: 'recentlyUsedDevices',
        columns: ['fullName', 'location', 'trusted', 'lastAccess', 'session'],
        options: {
          sortable: ['fullName', 'location', 'trusted', 'lastAccess', 'session'],
          orderBy: { column: 'fullName' },
          uniqueKey: 'id',
        },
        selected: [],
        smartFilters: {},
      },
      filters: {},

      KB_LINK_EXTENDED_SESSION,
      sessionDisablingLoading: false,
    };
  },

  mounted () {
    const promises = this.load();
    if (this.enableTotp) {
      Promise.all(promises).then(() => (this.onMfaMethodChanged(MFA_METHOD.AUTH_APPLICATION)));
    }
  },

  computed: {
    columnsOptions (): ColumnsOptions[] {
      const trustedOptions = [{
        text: this.$t('Trusted'),
        value: true,
      }, {
        text: this.$t('Not Trusted'),
        value: false,
      }];
      const sessionOptions = [{
        text: this.$t('Active'),
        value: SESSION_ACTIVE,
      }, {
        text: this.$t('Current'),
        value: SESSION_CURRENT,
      }, {
        text: this.$t('Not Active'),
        value: SESSION_NOT_ACTIVE,
      }];

      return [
        { text: this.$t('Device/Browser Name'), value: 'fullName', prop: 'fullName' },
        { text: this.$t('Location'), value: 'location', prop: 'location' },
        { text: this.$t('Status'), value: 'trusted', prop: 'trusted', options: trustedOptions },
        { text: this.$t('Last Access'), value: 'lastAccess', prop: 'lastAccess', type: 'date' },
        { text: this.$t('Session'), value: 'session', prop: 'session', options: sessionOptions, multiple: true },
      ];
    },

    typeOptions (): { text: string; value: string; filter: Function }[] {
      return [
        {
          text: this.$t('Web Browsers ({count})'),
          value: BROWSER_ENTITY_ID,
          filter: (entity) => entity.typeId === BROWSER_ENTITY_ID,
        },
      ];
    },

    daysSincePasswordChangeMessage (): string {
      if (this.session.daysSincePasswordChange) {
        return this.$tc('Password Was Changed {n} Days Ago', this.session.daysSincePasswordChange, { n: this.session.daysSincePasswordChange });
      }
      return this.$t('Password Was Changed Today');
    },

    tableColumns (): string[] {
      return ['id'].concat(this.table.columns);
    },

    searchResults (): Array<Browser> {
      const filters = Object.assign({}, this.filters, this.table.smartFilters);
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.entitiesLoading ? [] : this.applySearchFilters(filters, this.entities);
    },

    selectAll: {
      get (): boolean {
        return this.table.selected.length && this.table.selected.length === this.searchResults.length;
      },

      set (v: boolean) {
        // TODO: implement tri-state
        this.table.selected = v ? this.searchResults.map((entity) => entity.id) : [];
      },
    },

    filterIcon (): string {
      return this.smartFiltersCount ? 'filter-enabled' : 'filter';
    },

    smartFiltersCount (): number {
      return Object.keys(this.table.smartFilters).length;
    },

    canControlTrustedBrowser (): boolean {
      return this.session.isFeatureAccessible(FEATURE_MANAGE_TRUSTED_CHECK);
    },

    isTotpAccessible (): boolean {
      return this.session.mfaMethod === MFA_METHOD.AUTH_APPLICATION || this.session.isFeatureAccessible(FEATURE_TWO_STEP_VERIFICATION_VIA_TOTP);
    },

    switchMfaMode (): boolean {
      return this.newMfaMethod !== null;
    },
  },

  watch: {
    'session.locale' (val) {
      this.loadEntities();
    },
  },

  methods: {
    load (): Promise<void>[] {
      return [
        this.loadEntities(),
        this.loadSocialBindings(),
        this.loadAuthenticatorApps(),
      ];
    },

    loadEntities (): Promise<void> {
      const request = new CombinedApiRequest();

      const browsersRequest = new BrowserListRequest();
      request.addRequest('browsers', browsersRequest);

      return this.authorizedCall(request, 'entitiesLoading').then(() => {
        const browsers = browsersRequest.getBrowsers();

        this.table.selected = [];
        this.$nextTick(() => {
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
          this.entities = [...browsers];
        });
      });
    },

    loadSocialBindings (): Promise<void> {
      const request = new GetSocialBindingsRequest();

      return this.authorizedCall(request).then((data) => {
        this.socialBindings = data.social_bindings
          .map((socialBinding) => socialBinding.service_id)
          .reduce((acc, val) => {
            acc[val] = acc[val] === undefined ? 1 : acc[val] += 1;
            return acc;
          }, {});
      });
    },

    loadAuthenticatorApps (): Promise<void> {
      const request = new AuthenticatorAppsListRequest();
      return this.authorizedCall(request, 'authenticatorAppsLoading').then(() => {
        this.authenticatorApps = request.getAuthenticatorApps();
      });
    },

    deleteSocialBindingClick (serviceId, noMultipleDisconnectWarning = false) {
      if (!noMultipleDisconnectWarning && this.socialBindings[serviceId] > 1) {
        this.currentBindingForDisconnect = serviceId;
        this.$modal.show('multipleAccountsDisconnectWarning');
        return;
      }

      // Apple Sign In has same specificities, so we should show the warning before unbind
      if (serviceId === APPLE_SOCIAL_SERVICE) {
        this.$modal.show('appleDisconnect');
      } else {
        this.deleteSocialBinding(serviceId);
      }
    },

    deleteSocialBinding (serviceId) {
      const request = new DeleteSocialBindingRequest({ serviceId });
      this.authorizedCall(request, 'socialBindingLoading').then(() => {
        this.$delete(this.socialBindings, serviceId);
        if (serviceId === APPLE_SOCIAL_SERVICE) {
          this.$modal.hide('appleDisconnect');
        }
        this.$toast.show({
          text: this.$t('{serviceName} account has been disconnected.', { serviceName: this.socialBindingsNames[serviceId] }),
        });
      });
    },

    deleteEntities () {
      const
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        entities = this.entities.filter((entity) => this.table.selected.indexOf(entity.id) !== -1);
      const browserIds = entities.filter((entity) => entity.typeId === BROWSER_ENTITY_ID).map((entity) => entity.id);
      const request = new CombinedApiRequest();

      if (browserIds.length) {
        request.addRequest('browsers', new BrowsersDeleteRequest({ ids: browserIds }));
      }

      // entitiesLoading should be set through all requests
      request.emitter.on('started', () => {
        this.entitiesLoading = true;
      });

      this.$api.authorizedCall(request).then(() => {
        this.reloadEntities();
      });
    },

    reloadEntities () {
      new BrowserListRequest().dropCache();
      this.loadEntities();
    },

    showEntityModal (entityId) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.modalEntity = this.entities.find((entity) => entity.id === entityId);

      this.$nextTick(() => {
        this.$modal.show('entityModal');
      });
    },

    trustBrowser () {
      const request = new BrowserTrustRequest({ id: this.modalEntity.id });
      this.entityRequest(request);
    },

    deleteBrowser () {
      const request = new BrowsersDeleteRequest({ ids: [this.modalEntity.id] });
      this.entityRequest(request);
    },

    entityRequest (request) {
      this.authorizedCall(request, 'modalLoading').then(() => {
        this.$modal.hide('entityModal');
        this.modalEntity = new Browser({});
        this.reloadEntities();
      });
    },

    disableExtendedSession () {
      const request = new ChangeSessionLifetimeRequest();

      this.sessionDisablingLoading = true;

      this.$api.authorizedCall(request).then(() => {
        this.$appData.session.extended = false;
        this.$toast.show({
          text: this.$t('Your settings have been saved.'),
        });
      }).catch(() => {
        this.$toast.show({
          text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
          color: 'red',
        });
      }).finally(() => {
        this.sessionDisablingLoading = false;
      });
    },

    async openChangeEmailDialog () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (!await this.checkBrowserTrusted()) {
        return;
      }
      this.$modal.show(EmailChange);
    },

    async openChangePasswordDialog () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (!await this.checkBrowserTrusted()) {
        return;
      }
      this.$modal.show(PasswordChange);
    },

    refreshEmail () {
      // force session reload
      this.$api.refreshToken(true).then(() => {
        this.load();
      });
    },

    async onMfaDisabled () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (!await this.checkBrowserTrusted()) {
        return;
      }
      this.showUpdateMfaSettingsModal();
    },

    async updateMfaSettings ({ oneTimePassword = null as string, password = null as string }) {
      if ([MFA_METHOD.EMAIL_ALWAYS, MFA_METHOD.EMAIL_SENSITIVE_DATA].includes(this.newMfaMethod)) {
        this.loading = true;
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.resetAppliedPasswordState(password || oneTimePassword);
        try {
          await this.switchMfaMethod({ oneTimePassword, password });
          this.$modal.hide();
          this.$toast.show({
            color: 'green',
            text: this.$t('Two-step verification via e-mail configured.'),
          });
        } catch (e) {
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
          this.invalidPasswordEntered();
        } finally {
          this.loading = false;
        }
      } else {
        const request = new DisableMfaRequest({ oneTimePassword, password });
        this.loading = true;
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.resetAppliedPasswordState(password || oneTimePassword);
        try {
          await this.$api.authorizedCall(request);
          this.$appData.session.mfaStatus = false;
          this.$modal.hide();
          this.$toast.show({
            color: 'green',
            text: this.$t('Two-step verification disabled.'),
          });
        } catch (e) {
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
          this.invalidPasswordEntered();
        } finally {
          this.loading = false;
        }
      }
    },

    async onMfaMethodChanged (newMethod: MFA_METHOD) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (!await this.checkBrowserTrusted()) {
        return;
      }
      this.newMfaMethod = newMethod;
      if (newMethod === MFA_METHOD.AUTH_APPLICATION) {
        if (this.authenticatorApps.length > 0) {
          this.showAlreadyHaveAuthenticatorAppModal();
        } else {
          this.showAddNewAuthenticatorAppModal();
        }
      } else {
        this.showUpdateMfaSettingsModal();
      }
    },

    async switchMfaMethod ({ oneTimePassword = null as string, password = null as string }): Promise<SwitchMfaMethodRequest> {
      const request = new SwitchMfaMethodRequest({ newMfaMethod: this.newMfaMethod, oneTimePassword, password, secret: this.secret });
      await this.$api.authorizedCall(request);
      this.$appData.session.mfaStatus = true;
      this.$appData.session.mfaMethod = this.newMfaMethod;
      this.newMfaMethod = null;
      return request;
    },

    showUpdateMfaSettingsModal () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState();
      if (this.session.mfaMethod === MFA_METHOD.AUTH_APPLICATION) {
        this.$modal.show(UpdateTwoStepVerificationSettingsWithOtpModal);
      } else {
        this.$modal.show(UpdateTwoStepVerificationSettingsWithPasswordModal);
      }
    },

    showAlreadyHaveAuthenticatorAppModal () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState();
      this.$modal.show(AlreadyHaveAuthenticatorAppModal);
    },

    async showAddNewAuthenticatorAppModal () {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (!await this.checkBrowserTrusted()) {
        return;
      }
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState();
      // Method can be called from already have authenticator app modal
      this.$modal.hide();
      this.loading = true;
      this.$modal.show(AddNewAuthenticatorAppModal);
      try {
        const request = new NewAuthenticatorAppSecretRequest();
        await this.$api.authorizedCall(request);
        const responseData = request.getSecret();
        this.setupAccountLink = responseData.setupAccountLink;
        this.secret = responseData.secret;
      } catch (e) {
        this.$modal.hide();
      } finally {
        this.loading = false;
      }
    },

    async showDeleteAuthenticatorAppModal (authenticatorAppId: string) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (!await this.checkBrowserTrusted()) {
        return;
      }
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState();
      if (this.authenticatorApps.length > 1 || this.session.mfaMethod !== MFA_METHOD.AUTH_APPLICATION) {
        this.modalAuthenticatorApp = this.authenticatorApps.find(
          (authenticatorApp) => authenticatorApp.outerId === authenticatorAppId
        );

        this.$modal.show(DeletingAuthenticatorAppModal);
      } else {
        this.$modal.show(DeletingLastAuthenticatorAppModal);
      }
    },

    showAuthenticatorAppPropertiesModal (authenticatorAppId) {
      this.modalAuthenticatorApp = this.authenticatorApps.find(
        (authenticatorApp) => authenticatorApp.outerId === authenticatorAppId
      );

      this.$modal.show(AuthenticatorAppPropertiesModal);
    },

    async addNewAuthenticatorApp (oneTimePassword: string) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState(oneTimePassword);
      this.loading = true;
      try {
        let request = null;
        if (this.newMfaMethod === MFA_METHOD.AUTH_APPLICATION && this.newMfaMethod !== this.session.mfaMethod) {
          request = await this.switchMfaMethod({ oneTimePassword });
        } else {
          request = new AddNewAuthenticatorAppRequest({ secret: this.secret, oneTimePassword });
          await this.$api.authorizedCall(request);
        }
        this.authenticatorApps.push(request.getAuthenticatorApp(this.authenticatorApps.length));
        this.$modal.hide();
        this.rescueCodes = request.getRescueCodes();
        this.$modal.show(RescueCodesModal);
        if (request instanceof SwitchMfaMethodRequest) {
          this.$toast.show({
            color: 'green',
            text: this.$t('Two-step verification via authenticator app configured.'),
          });
        } else {
          this.$toast.show({
            color: 'green',
            text: this.$t('New authenticator app added.'),
          });
        }
      } catch (e) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.invalidPasswordEntered();
      } finally {
        this.loading = false;
      }
    },

    async enableTotpWithExistingAuthenticatorApp (oneTimePassword: string) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState(oneTimePassword);
      this.loading = true;
      try {
        this.newMfaMethod = MFA_METHOD.AUTH_APPLICATION;
        const request = await this.switchMfaMethod({ oneTimePassword });
        this.$modal.hide();
        this.rescueCodes = request.getRescueCodes();
        this.$modal.show(RescueCodesModal);
        this.$toast.show({
          color: 'green',
          text: this.$t('Two-step verification via authenticator app configured.'),
        });
      } catch (e) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.invalidPasswordEntered();
      } finally {
        this.loading = false;
      }
    },

    async onAuthAppPropertiesChanged (props: { newName: string; authApp: AuthenticatorApp }) {
      this.loading = true;
      try {
        await this.$api.authorizedCall(
          new ChangeAuthenticatorAppPropertiesRequest(
            { newName: props.newName, authAppOuterId: props.authApp.outerId }
          )
        );
        this.$toast.show({
          color: 'green',
          text: this.$t('Authenticator app "{name}" properties updated.', { name: props.authApp.name }),
        });
        props.authApp.name = props.newName;
      } finally {
        this.loading = false;
      }
    },

    async onDeleteAuthApp (props: { oneTimePassword: string; authApp: AuthenticatorApp }) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState(props.oneTimePassword);
      this.loading = true;
      try {
        await this.$api.authorizedCall(
          new DeleteAuthenticatorAppRequest(
            { oneTimePassword: props.oneTimePassword, authAppOuterId: props.authApp.outerId }
          )
        );
        this.authenticatorApps = this.authenticatorApps.filter((authApp) => authApp.outerId !== props.authApp.outerId);
        this.$modal.hide();
        this.$toast.show({
          color: 'green',
          text: this.$t('Authenticator app "{name}" deleted.', { name: props.authApp.name }),
        });
      } catch (e) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.invalidPasswordEntered();
      } finally {
        this.loading = false;
      }
    },

    resetMfaSettings () {
      const mfaSettingsCard = this.$refs[MFA_SETTINGS_CARD];
      if (mfaSettingsCard) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16310
        mfaSettingsCard.resetForm();
      }
    },
  },
});

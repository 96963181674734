/**
 * File: rasSubscriptionsRequestV2.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import { V2Response } from '@/apiv2/V2RequestType';
import { rasServiceUrl } from '@/apiv2/apiHelpers';

export interface RasSubscriptionResponse {
  'is_limited': boolean;
  'is_nfr': boolean;
  'lic_key': string;
  name: string;
  'next_billing_at': Date;
  owner: {
    id: number;
    'is_business': boolean;
    uuid: boolean;
  };
  'subscription_type': 'perm_ras' | 'prepaid_ras' | 'postpaid_ras' | 'ext_trial_ras' | 'trial_ras' | 'in_app_trial_ras';
  uuid: string;
  'parent_uuid': string;
  'deleted': boolean;
  'is_offline_allowed': boolean;
}

export default class RasSubscriptionsRequestV2 extends ApiRequest<{ url?: string }, V2Response<RasSubscriptionResponse[]>> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    return rasServiceUrl(this.params.url) || '/ras/api/v2/subscriptions/';
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}

/**
 * File: breadcrumbsMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

import { getBreadcrumbsFirstItem } from '@core/common/breadcrumbs';
import { DASHBOARD_PAGE } from '@/routes/constants';

export default Vue.extend({
  data () {
    return {
      DASHBOARD_PAGE,
    };
  },
  computed: {
    breadcrumbsFirstOption (): BreadcrumbsItem {
      return getBreadcrumbsFirstItem(this);
    },
    rootOptionName (): string {
      return this.breadcrumbsFirstOption.name || this.$t('Dashboard');
    },
  },
});

/**
 * File: ordersRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import Order from '@/models/order';
import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class OrdersRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'orders';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/orders`);
  }

  getData (subscription) {
    return this.data.orders.map((data) => new Order(data, subscription));
  }
}

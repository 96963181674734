/**
 * File: baseSubscriptionsRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import Subscription from '@/models/subscription';
import { Dictionary } from '@core/common/types';

export default abstract class BaseSubscriptionsRequest extends ApiRequest<Dictionary, { subscriptions: JSON[] }> {
  protected abstract _url: string;

  static get TYPE_BUSINESS (): string {
    return 'business';
  }

  static get TYPE_PERSONAL (): string {
    return 'personal';
  }

  get cacheNameSpace (): string {
    return 'subscriptions';
  }

  get url (): string {
    const params = Object.assign({}, this.params);
    if (params.type) {
      params.domain_type = params.type;
      delete params.type;
    }
    return this._url + this.buildQueryString(params);
  }

  getSubscriptions (): Subscription[] {
    if (this.data === null) {
      return null;
    }

    return this.data.subscriptions.map((data) => new Subscription(data));
  }
}

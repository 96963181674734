/**
 * File: ssoSignInRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import { ACCOUNT_SERVICE } from '@core/constants/services';
import ApiRequest, { Json, Method } from '@core/api/apiRequest';

export interface SsoSignInRequestParams {
  email: string;
}

export interface SsoSignInResponse {
  url: string;
}

export class SsoSignInRequest extends ApiRequest<SsoSignInRequestParams, SsoSignInResponse> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/sso/sign-on`;
  }

  get body (): Json {
    return {
      email: this.params.email,
    };
  }
}

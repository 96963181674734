/**
 * File: resendAccountConfirmationRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Method } from '@core/api/apiRequest';
import resendEmailErrors from './errorHandlers/resendEmailErrors';

export default class ResendAccountConfirmationRequest extends ApiRequest {
  json = false;

  get method (): Method {
    return 'GET';
  }

  get url (): string {
    return '/account/api/v1/profile/resend_confirmation_email';
  }

  get errors () {
    return resendEmailErrors();
  }
}

<copyright>
File: confirmationBlacklisting.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
  modal(ref="modal", size="big")
    template(slot="header") {{ $t('Confirm Blacklisting') }}
    template(slot="content")
      .row.margin-bottom-2x
        .col-xs-12
          | {{ $tc('Parallels Desktop will be prohibited on {amount} devices.', amount, { amount: amount }) }}
      .row.margin-bottom-2x
        .col-xs-12
          | {{ $t('Provide an optional message that will be displayed to the users:') }}
      .row.margin-bottom-2x
        .col-xs-12
          textbox(
            :placeholder="$t('Your message...')",
            type="text",
            :multiline="true",
            v-model="notes",
            :data-name="$name('textbox-message')"
          )
    template(slot="footer")
      btn(
        @click.native="confirm",
        :data-name="$name('button-confirm')"
      ) {{ $t('Blacklist') }}
      btn(
        color="white",
        @click.native="hide",
        :data-name="$name('button-cancel')"
      ) {{ $t('Cancel') }}
</template>
<script>
export default {
  name: 'confirmation-blacklisting-modal',

  data () {
    return {
      amount: null,
      notes: '',
    };
  },

  methods: {
    show (amount) {
      this.amount = amount;
      this.$refs.modal.show();
    },

    hide () {
      this.$refs.modal.hide();
      this.amount = null;
      this.notes = '';
    },

    confirm () {
      this.$emit('confirmed', this.notes);
      this.notes = '';
      this.amount = null;
      this.$refs.modal.hide();
    },
  },
};
</script>

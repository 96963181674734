<copyright>
File: rateLimit.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import ErrorPage from '@core/pages/error';

export default {
  name: 'rate-limit',
  props: ['appData'],
  components: {
    ErrorPage,
  },
};

</script>

<template lang="pug">

error-page
  template(v-slot:header) {{ $t('Too Many Login Attempts') }}
  template(v-slot:text) {{ $t('Please try again later.') }}
  template(v-slot:button) &nbsp;

</template>

/**
 * File: rtGetCallbackScheduleRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtGetCallbackScheduleRequest extends RtRequest {
  /**
   * @returns {String}
   */
  get uri () {
    return `/ticket/${this.params.ticketId}/schedule`;
  }
}

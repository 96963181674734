/**
 * File: confirmAccountRequest.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json } from '@core/api/apiRequest';
import { camelize } from '@core/common/utils';

type ConfirmAccountRequestParams = {
  code: string;
};

export default class ConfirmAccountRequest extends ApiRequest<ConfirmAccountRequestParams, Json> {
  get url (): string {
    return `/account/webapp/confirm/${this.params.code}`;
  }

  onFetchSuccess (data): Json {
    return camelize(data);
  }
};

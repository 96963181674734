/**
 * File: userConfirmEmailRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import { ACCOUNT_SERVICE } from '@core/constants/services';
import ApiRequest, { Method } from '@core/api/apiRequest';

interface IUserConfirmEmailRequestProps {
  domainId: number;
}

interface IUserConfirmEmailResponse {
  'email_has_been_sent': boolean;
}

export default class UserConfirmEmailRequest extends ApiRequest<IUserConfirmEmailRequestProps, IUserConfirmEmailResponse> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/profile/send_confirmation_email${this.params.domainId ? '/' + this.params.domainId : ''}`;
  }
}

/**
 * File: generateConvertPdlLinkRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class generateConvertPdlLinkRequest extends ApiRequest {
  get cacheNameSpace () {
    return false;
  }

  get url () {
    return `/license/api/v1/stores/cb/cart?plan=${this.params.plan}&discount_option=${this.params.uuid}&pre_fill=true`;
  }
}

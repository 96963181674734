/**
 * File: rasOfflineRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class RasOfflineRequest extends ApiRequest {
  constructor (params, file) {
    super(params);
    this.file = file;
    this.headers = {};
  }

  get options () {
    const
      options = super.options;
    const body = new FormData();

    if (this.params.hasOwnProperty('licKey')) {
      body.append('lic_key', this.params.licKey);
    }

    body.append('request', this.file);
    options.body = body;

    return options;
  }

  get method () {
    return 'POST';
  }

  get url () {
    let url = `/ras/webapp/offline/${this.params.action}`;
    if (this.params.info) {
      url += '/info';
    }
    return url;
  }
}

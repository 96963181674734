/**
 * File: rasLsSubscriptionsRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import BaseSubscriptionsRequest from './baseSubscriptionsRequest';
import { getServiceUrl } from '@core/api/routing';
import Service from '@core/constants/services';

export default class RasLsSubscriptionsRequest extends BaseSubscriptionsRequest {
  protected _url = getServiceUrl(Service.RasLicense, '/api/v1/subscriptions');
}

<copyright>
File: hostsDeactivateModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
  modal(
    ref="modal",
    name="hosts-deactivate",
    size="big",
    :closeButton="true",
    :loading="loading",
    :data-name="$name('hosts-deactivate-modal')"
  )
    template(slot="header") {{ $t('Deactivating a Group of Computers') }}
    template(slot="content")
      div {{ $tc('Parallels Toolbox will be deactivated on {amount} devices.', hosts.length, { amount: hosts.length }) }}
    template(slot="footer")
      btn(@click="$modal.hide()", :data-name="$name('button-cancel')") {{ $t('Cancel') }}
      btn(color="white", @click="deactivate", :data-name="$name('button-deactivate')") {{ $t('Deactivate') }}

</template>

<script>

import PdDeactivateHostsRequestSingle from '@/api/ptbDeactivateHostsRequest';

export default {
  name: 'hosts-deactivate-modal',

  data () {
    return {
      hosts: [],
      loading: false,
    };
  },

  methods: {
    show (hosts) {
      this.hosts = hosts;
      this.$refs.modal.show();
    },

    async deactivate () {
      this.loading = true;

      const request = new PdDeactivateHostsRequestSingle({ hosts: this.hosts });
      try {
        await this.$api.authorizedCall(request);
      } catch (e) {
        this.$toast.show({ text: this.$t('Error occurred'), color: 'red' });
        throw e;
      } finally {
        this.loading = false;
      }

      this.$refs.modal.hide();
      this.$toast.show({ text: this.$tc('{amount} computers have been deactivated.', this.hosts.length, { amount: this.hosts.length }) });
      this.$emit('deactivated');
    },
  },
};

</script>

/**
 * File: suspendSubscriptionRequest.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { LICENSE_SERVICE } from '@core/constants/services';

export type SuspendSubscriptionRequestParams = {
  uuid: string;
};

export default class SuspendSubscriptionRequest extends ApiRequest<SuspendSubscriptionRequestParams> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/${LICENSE_SERVICE}/api/v1/subscriptions/${this.params.uuid}/suspend`;
  }

  get body (): Json {
    return {};
  }
}


import Vue from 'vue';

import { PRODUCT_KEY_NAME_PDE, PRODUCT_NAME_PDE } from '@core/constants/subscriptions';

import Subscription from '@/models/subscription';

import { support as supportLinks } from '@core/constants/links';

import SubscriptionsRequest from '@/api/subscriptionsRequest';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductPageMixIn from '@/modules/productPageMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import DownloadsContent from '@/modules/downloads/downloadsContent.vue';
import { PDE_DOWNLOADS_PAGE, PDE_SUBPAGE_SUBSCRIPTION_DETAILS, PDE_SUBPAGE_SUBSCRIPTIONS } from '@/routes/constants';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import SubscriptionInviteModal from '@/modules/desktop/subscriptionInvite/subscriptionInviteModal.vue';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import PdConfigurationProfilesRequest from '@/api/pdConfigurationProfilesRequest';
import PdComputersRequest from '@/api/pdComputersRequest';
import { getPdeSubpages } from '@/modules/pde/utils';

export default Vue.extend({
  name: 'pde-page',

  props: ['content'],

  mixins: [
    ComponentMixIn,
    ProductPageMixIn,
    SearchFilterMixin,
    BreadcrumbsMixin,
  ],

  components: {
    SubscriptionInviteModal,
    SubscriptionNotification,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    DownloadsContent,
    AddLicensesModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  data () {
    return {
      supportLinks,
      PRODUCT_KEY_NAME_PDE,
      subscriptions: [],
      displayNotification: true,
      configurationProfilesAmount: 0,
      computersAmount: 0,
    };
  },

  created () {
    this.load();
  },

  methods: {
    load () {
      const combinedRequest = new CombinedApiRequest();
      combinedRequest.addRequest('subscriptions', new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS }));
      combinedRequest.addRequest('configurationProfiles', new PdConfigurationProfilesRequest({ totalOnly: true }));
      combinedRequest.addRequest('computers', new PdComputersRequest({
        activated: true,
        totalOnly: true,
        product: PRODUCT_KEY_NAME_PDE,
      }));
      this.authorizedCall(combinedRequest)
        .then(() => {
          this.subscriptions = (combinedRequest.getRequest('subscriptions') as SubscriptionsRequest).getSubscriptions().filter((s: Subscription) => s.hasProduct(PRODUCT_KEY_NAME_PDE));
          this.configurationProfilesAmount = combinedRequest.getRequest('configurationProfiles').data.total;
          this.computersAmount = (combinedRequest.getRequest('computers') as PdComputersRequest)?.getTotal();
        });
    },
  },

  computed: {
    productName () {
      return PRODUCT_NAME_PDE;
    },
    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },
    isSubscriptionsPage (): boolean {
      return this.content === PDE_SUBPAGE_SUBSCRIPTIONS;
    },
    isDownloadsPage (): boolean {
      return this.content === PDE_DOWNLOADS_PAGE;
    },
    isSubscriptionDetailsPage (): boolean {
      return this.content === PDE_SUBPAGE_SUBSCRIPTION_DETAILS;
    },
    subpages (): MenuItem[] {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
      return getPdeSubpages(this);
    },
  },
});



import Vue from 'vue';
import { prodKeyToName } from '@core/constants/subscriptions';
import { getMilitaryTimezone } from '@core/common/datetime';
import {
  helpers,
  minLength,
  maxLength,
  required as requiredValidator
} from '@core/common/validators';
import { formatBytes } from '@core/common/format/size';
import locale from '@/plugins/locale';
import { REPORT_ID_STORE_KEY } from '@/plugins/setReportId';

import { getContactSource } from '../constants';
import { sortByValue } from '@core/common/sorters';
import FormField from './formField.vue';
import RtSupportFormRequest from '@/api/rt/rtSupportFormRequest';
import RtSendAttachmentsRequest from '@/api/rt/rtSendAttachmentsRequest';
import ComponentMixIn from '@/modules/componentMixIn';
import CreateTicketMixin from './createTicketMixin';
import CompanyUser from '@/models/companyUser';

export interface SupportByEmailModalParams {
  summary: string;
  severity?: number;
}

interface Field {
  id: string;
  type: string;
  value?: string;
  options: { value: string }[];
  required: boolean;
  validators: Record<string, any>[];
}

export default Vue.extend({
  name: 'support-get-support-byemail-modal',
  mixins: [
    ComponentMixIn,
    CreateTicketMixin,
  ],
  components: {
    FormField,
  },
  props: ['supportInfo', 'supportType', 'users'],

  data () {
    return {
      loading: false,
      ticketId: null as number,
      fields: [] as Field[],
      // eslint-disable-next-line camelcase
      form: {} as { severity?: number; share?: { domain_id: number; emails: string[] }; summary?: string; case_origin?: string },
      severity: undefined as number,
      variables: {
        $supportType: this.supportType,
        $summary: '',
        $timezone: getMilitaryTimezone(),
        $description: '',
        $companyName: this.$appData.session.businessDomainName,
        $contactSource: getContactSource(),
        $country: this.$appData.session.country,
        $reportId: this.$sessionStorage.get(REPORT_ID_STORE_KEY),
      },
      files: [] as { name: string; size: number }[],
      maxFilesSize: 20971520, // 20 Mb
      formatBytes,
      locale,
      tab: 'general',
      currentUser: {} as CompanyUser,
      watchersPool: [] as CompanyUser[],
      selectedUser: null as number,
      ticketWatchers: [] as CompanyUser[],
      defaultWatchers: [] as CompanyUser[],
    };
  },

  validations () {
    const validators = {};

    this.fields.forEach((f) => {
      validators[f.id] = {};

      if (f.required) {
        validators[f.id].required = requiredValidator;
      }
      /**
       * Example:
       * validators': {
       *  'notEmpty': {
       *    'message': 'This field is required'
       *  },
       *  'stringLength': {
       *    'min': 5,
       *    'max': 200,
       *    'message': 'Please enter at least 5 characters and no more than 200'
       *  },
       *  'regexp': {
       *    'regexp': '/^\\s*(?:\\w{4}-\\w{4}-\\w{4}-\\w{4}|\\w{6}-\\w{6}-\\w{6}-\\w{6}-\\w{6})\\s*$/',
       *    'message': 'Invalid license key format'
       *  }
       * }
       */
      if (f.validators) {
        Object.keys(f.validators).forEach((k) => {
          const vData = f.validators[k];

          if (k === 'notEmpty') {
            validators[f.id].required = requiredValidator;
          } else if (k === 'stringLength') {
            if (vData.min) {
              validators[f.id].minLength = minLength(vData.min);
            } else if (vData.max) {
              validators[f.id].maxLength = maxLength(vData.max);
            }
          } else if (k === 'regexp') {
            validators[f.id].regexp = helpers.regex(f.id, new RegExp(vData.regexp.replace(/^\/|\/$/g, '')));
          }
        });
      }
    });

    return { form: validators };
  },

  methods: {

    load () {
      this.loading = true;

      if (this.isBusinessMode) {
        this.currentUser = this.users.filter((user: CompanyUser) => this.$appData.session.email === user.email)[0];
        if (this.currentUser.isAdmin) {
          this.watchersPool = this.users && this.users.filter((user: CompanyUser) => user.email !== this.$appData.session.email);
        } else {
          this.watchersPool = this.users && this.users.filter((user: CompanyUser) => user.email !== this.currentUser.email && user.isAdmin);
        }
      }

      this.$api.callRt(new RtSupportFormRequest({
        product: this.supportInfo.product_key,
        type: this.supportType,
        language: this.locale.rtLanguage,
      }))
        .then((data) => {
          data.fields.forEach((f) => {
            if (f.value && String(f.value).startsWith('$')) {
              this.$set(this.form, f.id, this.variables[f.value] || '');
            } else if (f.type === 'radio') {
              this.$set(this.form, f.id, f.options[0].value);
            } else {
              this.$set(this.form, f.id, f.value || '');
            }
          });

          this.$nextTick(() => {
            this.fields = data.fields;
          });

          this.loading = false;
          return data;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    addWatchers () {
      const user = this.watchersPool.find((u) => u.uuid === this.selectedUser);

      if (user) {
        this.selectedUser = null;
        this.ticketWatchers.push(user);
      }
    },

    flush () {
      this.severity = undefined;
      this.fields = [];
      this.form = {};
      this.files = [];
      this.$v.$reset();
    },

    show (params: SupportByEmailModalParams) {
      this.severity = params.severity;
      this.variables.$summary = params.summary;
      this.load();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
    },

    close () {
      this.$collectGaStats({
        category: 'Support',
        name: 'Email cancel',
        source: prodKeyToName(this.supportInfo.product_key),
      });
      this.flush();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },

    async submit () {
      this.$v.$touch();

      if (this.$v.form.$invalid || this.filesLimitReached) {
        return;
      }

      const personalMode = this.$appData.session.isPersonalUser(this.$appData.userInDomain);
      const companyName = this.$appData.session.businessDomainName;
      const form = Object.assign({}, this.form);

      form.case_origin = 'email';
      if (!personalMode) {
        form.summary = `[${companyName}] ${form.summary}`;
      }

      if (this.watchers.length) {
        const emails = this.watchers.map(watcher => watcher.email);
        form.share = {
          domain_id: this.$appData.session.businessDomainId,
          emails: emails,
        };
      }

      if (this.severity !== undefined) {
        form.severity = this.severity;
      }

      this.loading = true;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      const ticketId = await this.createOrUpdateTicket(form);
      try {
        this.ticketId = ticketId;
        if (this.files.length > 0) {
          this.$api.callRt(new RtSendAttachmentsRequest({ ticketId, files: this.files }));
        }
        this.loading = false;
        this.$collectGaStats({
          category: 'Support',
          name: 'Email ticket submission',
          source: prodKeyToName(this.supportInfo.product_key),
        });
        this.$emit('ticketCreated', { ticketId, supportType: this.supportType, caseOrigin: 'email', form: this.form });
        this.close();
      } finally {
        this.loading = false;
      }
    },

    attach (fileLst) {
      // TODO Add limit to files size and amount
      const fileNames = this.files.map((f) => { return f.name; });
      Array.prototype.forEach.call(fileLst, (f) => {
        if (fileNames.indexOf(f.name) < 0) {
          this.files.push(f);
        }
      });
    },

    remove (file) {
      const idx = this.files.indexOf(file.name);
      this.files.splice(idx, 1);
    },

    setTab (params) {
      this.tab = params.tab;
    },

    listItemColor (user) {
      if (user.is_account_admin) return 'muted';
      return 'default';
    },

    removeWatcher (userUid) {
      const i = this.ticketWatchers.map((user) => user.uuid).indexOf(userUid);
      this.ticketWatchers.splice(i, 1);
    },
  },

  computed: {
    totalFileSize (): number {
      let totalSize = 0;

      this.files.forEach((f) => { totalSize += f.size; });

      return totalSize;
    },

    filesLimitReached (): boolean {
      return this.totalFileSize >= this.maxFilesSize;
    },

    visibleFields (): Field[] {
      return this.fields.filter((field) => field.type !== 'hidden');
    },

    userOptions (): Record<string, any>[] {
      const presentUsers = this.ticketWatchers.reduce((res, user) => ({ [user.uuid]: user, ...res }), {});
      return this.watchersPool
        .filter(user => !presentUsers[user.uuid])
        .map(user => ({
          value: user.uuid,
          ...user,
        })).sort((a, b) => sortByValue(a.name.toLowerCase(), b.name.toLowerCase(), true));
    },

    getTabs (): { general: string; watchers: string } {
      const tabs = {
        general: this.$t('General'),
        watchers: null,
      };

      if (this.isBusinessMode) {
        tabs.watchers = this.$t('Add Watchers');
      }
      return tabs;
    },

    isBusinessMode (): boolean {
      return this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain);
    },

    watchers (): CompanyUser[] {
      // eslint-disable-next-line
      return this.ticketWatchers.sort((a, b) => sortByValue(a.name.toLowerCase(), b.name.toLowerCase(), true));
    },
  },
});




import Vue from 'vue';
import CsvSaver from '@/ui/csvSaver.vue';
import ToolboxHostsRequest from '@/api/toolboxHostsRequest';
import HostsBlockModal from './hostsBlockModal.vue';
import HostsDeactivateModal from './hostsDeactivateModal.vue';
import HostDetailsModal from './hostDetailsModal.vue';
import PtbUnBlockHostsRequest from '@/api/ptbUnblockHostsRequest';
import PtbRemoveHostsRequest from '@/api/ptbRemoveHostsRequest';
import ToolboxHost from '@/models/toolboxHost';

const DISPLAY_MODE_ACTIVE = 'activated';
const DISPLAY_MODE_BLOCKED = 'blocked';
const DISPLAY_MODE_DEACTIVATED = 'deactivated';

export default Vue.extend({
  name: 'toolbox-computers-table',

  components: { CsvSaver, HostsBlockModal, HostsDeactivateModal, HostDetailsModal },

  data () {
    return {
      loading: false,
      isDeactivating: false,
      computers: [] as ToolboxHost[],
      table: {
        selectedColumns: [],
        pagination: {
          perPage: 50,
        },
        selectedHwIds: [],
      },
      filters: {} as TableViewFilters,
    };
  },

  created (): void {
    this.refresh();
  },

  computed: {
    actions (): TableViewActions {
      const actions = [];
      if (this.selectedActivatedComputers.length) {
        actions.push({
          value: 'showHostsDeactivateModal',
          text: this.$t('Deactivate ({count})', { count: this.selectedActivatedComputers.length }),
        });
        actions.push({
          value: 'showHostsBlockModal',
          text: this.$t('Add to Blacklist ({count})', { count: this.selectedActivatedComputers.length }),
        });
      }
      if (this.selectedDeactivatedComputers.length) {
        actions.push({
          value: 'hostsRemove',
          text: this.$t('Remove ({count})', { count: this.selectedDeactivatedComputers.length }),
        });
      }
      if (this.selectedBlockedComputers.length) {
        actions.push({
          value: 'hostsUnlock',
          text: this.$t('Remove from Blacklist ({count})', { count: this.selectedBlockedComputers.length }),
        });
      }
      actions.push({
        value: 'exportCsv',
        text: this.$t('Save List to CSV File ({count})', { count: this.selectedComputers.length }),
      });

      return {
        id: 'hwId',
        options: actions,
      };
    },

    columns (): { options: ColumnsOptions[]} {
      return {
        options: [
          { text: this.$t('Computer Name'), value: 'name' },
          { text: this.$t('License Key'), value: 'keys' },
          { text: this.$t('Host OS'), value: 'osName' },
          { text: this.$t('Activation Date'), value: 'activatedAt', type: 'date' },
        ],
      };
    },

    activatedCount (): number {
      return this.computers.filter((host) => host.isActivated).length;
    },

    blockedCount (): number {
      return this.computers.filter((host) => host.isBlocked).length;
    },

    deactivatedCount (): number {
      return this.computers.filter((host) => !host.isActivated && !host.isBlocked).length;
    },

    displayModeOptions (): DisplayModeOption[] {
      return [
        {
          text: this.$t('Active ({amount})', { amount: this.activatedCount }),
          value: DISPLAY_MODE_ACTIVE,
          filter: (host) => host.isActivated,
        }, {
          text: this.$t('Deactivated ({amount})', { amount: this.deactivatedCount }),
          value: DISPLAY_MODE_DEACTIVATED,
          filter: (host) => !host.isActivated && !host.isBlocked,
        }, {
          text: this.$t('Blacklisted ({amount})', { amount: this.blockedCount }),
          value: DISPLAY_MODE_BLOCKED,
          filter: (host) => host.isBlocked,
        },
      ];
    },

    selectedComputers (): ToolboxHost[] {
      return this.table.selectedHwIds
        .map((hwId) => this.computers.find((computer) => computer.hwId === hwId))
        .filter((host) => host);
    },

    selectedActivatedComputers (): ToolboxHost[] {
      return this.selectedComputers.filter((computer) => computer.isActivated);
    },

    selectedBlockedComputers (): ToolboxHost[] {
      return this.selectedComputers.filter((computer) => computer.isBlocked);
    },

    selectedDeactivatedComputers (): ToolboxHost[] {
      return this.selectedComputers.filter((computer) => !computer.isActivated && !computer.isBlocked);
    },
  },

  methods: {
    callAction (params) {
      const method = params.action;
      this[method](params.items);
    },

    async refresh () {
      this.loading = true;
      const requestHosts = new ToolboxHostsRequest();
      requestHosts.dropCache();

      await this.$api.authorizedCall(requestHosts);

      this.computers = requestHosts.hosts;
      this.loading = false;
    },

    changeColumns (params) {
      this.table.selectedColumns = params.columns;
    },

    exportCsv (computers) {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.csv.save({
        columns: this.table.selectedColumns,
        rows: computers,
      });
    },

    showHostsDeactivateModal () {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.hostsDeactivateModal.show(this.selectedActivatedComputers);
    },

    showHostsBlockModal () {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.hostsBlockModal.show(this.selectedActivatedComputers.map((host) => host.hwId));
    },

    async hostsUnlock () {
      const hwIds = this.selectedBlockedComputers.map((host) => host.hwId);
      const request = new PtbUnBlockHostsRequest({ hwIds });

      this.loading = true;
      try {
        await this.$api.authorizedCall(request);
      } catch (e) {
        this.loading = false;
        this.$toast.show({ text: this.$t('Error occurred'), color: 'red' });
        throw e;
      }

      this.$toast.show({ text: $tc('{amount} devices are no longer blacklisted. To continue working with Parallels Toolbox on them, you need to manually activate it with a valid product key.', hwIds.length, { amount: hwIds.length }) });
      await this.refresh();
    },

    async hostsRemove () {
      const hwIds = this.selectedDeactivatedComputers.map((host) => host.hwId);
      const request = new PtbRemoveHostsRequest({ hwIds });

      this.loading = true;
      try {
        await this.$api.authorizedCall(request);
      } catch (e) {
        this.loading = false;
        this.$toast.show({ text: this.$t('Error occurred'), color: 'red' });
        throw e;
      }

      this.$toast.show({ text: $tc('{amount} computers have been removed.', hwIds.length, { amount: hwIds.length }) });
      await this.refresh();
    },
  },
});


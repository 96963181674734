/**
 * File: rasLsUniversalKeysRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import LsUniversalKeysRequest from './lsUniversalKeysRequest';

export default class RasLsUniversalKeysRequest extends LsUniversalKeysRequest {
  prefix = '/ras-license';

  get cacheNameSpace () {
    return 'ras_universal_keys';
  }
}

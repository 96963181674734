/**
 * File: pmmOfflineRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class PmmOfflineRequest extends ApiRequest {
  constructor (params, file) {
    super(params);
    this.file = file;
    this.headers = {};
  }

  get options () {
    const
      options = super.options;
    const body = new FormData();

    body.append('request', this.file);
    options.body = body;

    return options;
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/pmm/webapp/offline';
  }
}

/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

export const ACCOUNT_FAILED_SIGN_IN_RT_QUEUE_ID = 8403;
export const MYACCOUNT_PRODUCT_ID = 177;
export const PSW_PRODUCT_ID = 274;
export const PSW_STAGING_PRODUCT_ID = 292;

export const TICKET_FIELD_LANGUAGE = 'CustomField-105';
export const TICKET_FIELD_CASE_ORIGIN = 'CustomField-1231';
export const TICKET_FIELD_SUPPORT_TYPE = 'CustomField-1202';
export const TICKET_FIELD_TIMEZONE = 'CustomField-125';
export const TICKET_FIELD_CONTACT_SOURCE = 'CustomField-1203';
export const UNIFIED_CHAT_FIELD_CUSTOMER_NAME = 'CustomField-903';
export const UNIFIED_CHAT_FIELD_COUNTRY = 'CustomField-692';

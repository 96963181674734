/**
 * File: referenceRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import {
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_RAS
} from '@core/constants/subscriptions';
import {
  PSW_DOWNLOADS_PAGE,
  DESKTOP_DOWNLOADS_PAGE,
  PMM_DOWNLOADS_PAGE,
  RAS_DOWNLOADS_PAGE,
  TOOLBOX_DOWNLOADS_PAGE
} from '@/routes/constants';

export const referenceLinks = {
  [PRODUCT_KEY_NAME_PSW]: { name: PSW_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_PDB]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDB } },
  [PRODUCT_KEY_NAME_PDB_PER_USER]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDB_PER_USER } },
  [PRODUCT_KEY_NAME_PDFC]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDFC } },
  [PRODUCT_KEY_NAME_PDFM]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDFM } },
  [PRODUCT_KEY_NAME_PMM]: { name: PMM_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_RAS]: { name: RAS_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_PTB]: { name: TOOLBOX_DOWNLOADS_PAGE },
  toolbox: { name: TOOLBOX_DOWNLOADS_PAGE },
};

// TOOLBOX uses different download page and passes props to DownloadsContent inside it
export const checkContextLinks = {
  [PRODUCT_KEY_NAME_PSW]: { name: PSW_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_PDB]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDB } },
  [PRODUCT_KEY_NAME_PDB_PER_USER]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDB_PER_USER } },
  [PRODUCT_KEY_NAME_PDFC]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDFC } },
  [PRODUCT_KEY_NAME_PDFM]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDFM } },
  [PRODUCT_KEY_NAME_PMM]: { name: PMM_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_RAS]: { name: RAS_DOWNLOADS_PAGE },
};



import Vue from 'vue';
import PdConfigurationProfilesRequest from '@/api/pdConfigurationProfilesRequest';

type Option = {
  value: number;
  text: string;
}

export default Vue.extend({
  name: 'configuration-profile-dropdown',

  model: {
    event: 'change',
    prop: 'val',
  },

  props: {
    val: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
    },
  },

  data () {
    return {
      loading: false,
      value: this.val,
      options: [
        { value: null, text: this.$t('Default') },
      ] as Option[],
    };
  },

  created () {
    this.loadProfiles();
  },

  methods: {
    async loadProfiles () {
      this.loading = true;
      this.$emit('loadStarted');
      try {
        const request = new PdConfigurationProfilesRequest();
        await this.$api.authorizedCall(request);
        this.options = this.options.concat(request.getProfiles().map(
          (item) => ({ value: item.id, text: item.name }))
        );
      } finally {
        this.loading = false;
        this.$emit('ready');
      }
    },
  },

  computed: {
    selectedName (): string {
      const item = this.options.find((item) => { return item.value === this.value; });
      return item?.text;
    },
  },

  watch: {
    value (value: number) {
      this.$emit('change', value);
    },
    val (value: number) {
      this.value = value;
    },
  },
});


<copyright>
File: emailChangeSecurityNotice.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import SecurityNoticeText from '@/modules/personalProfile/securityNoticeText';

export default {
  name: 'email-change-security-notice',
  components: {
    SecurityNoticeText,
  },
};

</script>

<template lang="pug">

card(type="plain")
  h2(v-text="$t('Parallels Account Security Notice')")
  security-notice-text

</template>

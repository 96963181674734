/**
 * File: pdLicensesRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import LegacyLicense from '@/models/legacyLicense';

export default class PdLicensesRequest extends ApiRequest {
  get url () {
    return '/desktop/api/v1/licenses';
  }

  get cacheNameSpace () {
    return 'subscriptions';
  }

  getLicenses () {
    return this.data && this.data.reduce(function (licenses, product) {
      return licenses.concat(product.licenses.map(function (license) {
        return new LegacyLicense(license);
      }));
    }, []);
  }
}

/**
 * File: dashboardRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import DashboardPage from '@/modules/home/dashboard/dashboardPage.vue';
import HomeBase from '@/modules/home/base.vue';
import HomePage from '@/modules/home/home.vue';
import {
  ADD_KEY_DASHBOARD_PAGE,
  ADD_KEY_MODAL,
  ADD_LICENSES_DASHBOARD_PAGE,
  ADD_LICENSES_FOR_PRODUCT_PAGE,
  ADD_LICENSES_MODAL,
  DASHBOARD_PAGE,
  HOME_PAGE,
  START_PAGE
} from '@/routes/constants';

export default [
  {
    name: START_PAGE,
    path: '/',
    component: HomeBase,
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
    },
    children: [
      {
        name: HOME_PAGE,
        path: '/home',
        component: HomePage,
        meta: {
          requiresAuth: true,
          title: 'Home',
        },
      },
      {
        name: DASHBOARD_PAGE,
        path: '/dashboard',
        component: DashboardPage,
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
      },
      {
        path: `/dashboard/:modal(${ADD_KEY_MODAL})`,
        props: true,
        name: ADD_KEY_DASHBOARD_PAGE,
        component: DashboardPage,
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
      },
      {
        path: `/:modal(${ADD_LICENSES_MODAL})/:product`,
        props: true,
        name: ADD_LICENSES_FOR_PRODUCT_PAGE,
        component: DashboardPage,
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
      },
      {
        path: `/:modal(${ADD_LICENSES_MODAL})/:product/:uuid`,
        props: true,
        name: ADD_LICENSES_DASHBOARD_PAGE,
        component: DashboardPage,
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
      },
    ],
  },
];

/**
 * File: index.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/
import { RouteConfig } from 'vue-router/types/router';

const routes: string[] = [
  'action',
  'addKey',
  'dashboard',
  'desktop',
  'downloads',
  'legacy',
  'login',
  'other',
  'pmm',
  'profile',
  'pvad',
  'ras',
  'subscriptions',
  'support',
  'toolbox',
  'upgradeToPro',
  'psw',
  'daas',
  'pbi',
  'rasAzmp',
  'pde',
];

export default routes.reduce<RouteConfig[]>((result, name) => {
  const routes = <RouteConfig>require(`./${name}Routes`).default;
  return result.concat(routes);
}, []);

/**
 * File: rasSubscriptionsRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import RasSubscription from '@/models/rasSubscription';

export default class RasSubscriptionsRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'ras_subscriptions';
  }

  get url () {
    return '/ras/webapp/subscriptions';
  }

  /**
   *
   * @returns {Array<RasSubscription>} - list of Subscriptions models.
   */
  getSubscriptions () {
    return this.data.subscriptions.map((data) => new RasSubscription(data));
  }
}



import Vue from 'vue';
import LoginForm from './loginForm.vue';
import RegisterForm from './registerForm.vue';
import SocialButtons from './socialButtons.vue';
import RegisterRequest from '@/api/registerRequest';
import UserConsent from '@/modules/userConsent/index.vue';
import SignInWithSsoModal from '@/modules/login/signInWithSsoModal.vue';
import SessionStatus from '@/modules/sessionExpiration/status';
import BottomLinks from '@/ui/bottomLinks.vue';
import {
  LEGAL_NOTICES_URL,
  HELP_WITH_SIGN_IN_URL
} from '@core/constants/links';
import { UA_CONFLICT_EMBARGOED_COUNTRIES } from '@core/constants/geo';

export default Vue.extend({
  name: 'sign-in-page',
  components: {
    BottomLinks,
    LoginForm,
    RegisterForm,
    SocialButtons,
    UserConsent,
    SignInWithSsoModal,
  },
  data () {
    return {
      loading: false,
      mode: this.$route.name,
      consent: {
        id: undefined,
        required: true,
        newAccount: true,
      },
      showConsent: false,
      sessionExpired: SessionStatus.isExpired(),
      buttonsDisabled: false,
      iosSignIn: false,
      credentials: undefined,
      bottomLinksOptions: [LEGAL_NOTICES_URL, HELP_WITH_SIGN_IN_URL],
    };
  },
  mounted () {
    this.refreshToken();
    this.setSessionExpirationStatus(false);
  },
  methods: {
    refreshToken () {
      this.loading = true;

      this.$api.refreshToken().then(() => {
        if (this.$appData.session && !this.$appData.session.isGuest) {
          this.$router.push({
            name: 'index',
          });
          return;
        }
        return this.$api.getConsent({ dryRun: true });
      }).then((data) => {
        if (data) {
          this.consent.required = data.consent_required;
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    openForgotPasswordPage () {
      this.$router.push({
        name: 'forgot',
        query: {
          service: this.$route.query.service,
          continue: this.$route.query.continue,
        },
      });
    },
    login (params) {
      this.loading = true;
      this.$api.login(params).catch(() => {
        this.loading = false;
      });
    },
    register (params) {
      const payload = Object.assign({
        locale: this.$i18n.locale.toLowerCase(),
        dryRun: this.consent.required,
        service: this.$route.query.service,
        continue: this.$route.query.continue,
        consentId: this.consent.id,
      }, params);

      const registerRequest = new RegisterRequest(payload);

      this.loading = true;

      this.$api.call(registerRequest).then(() => {
        if (this.consent.required) {
          this.loading = false;
          this.showConsent = true;
          this.credentials = params;
        } else {
          this.$api.loginSuccessHandler();
          this.$appData.isNewUser = true;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    onAccept (consent) {
      this.consent.id = consent.id;
      this.consent.required = !!consent.required;
      this.showConsent = false;
      this.register(this.credentials);
    },
    hideConsent () {
      this.showConsent = false;
    },
    toggleButtonsStatus (status) {
      this.buttonsDisabled = !!status;
    },
    setSessionExpirationStatus (status = false) {
      SessionStatus.setExpiration(status);
    },
    toggleIosSignInStatus (status) {
      this.iosSignIn = status;
    },
    signInWithSso () {
      this.$modal.show(SignInWithSsoModal);
    },
  },
  computed: {
    showRestrictionsBanner (): boolean {
      return UA_CONFLICT_EMBARGOED_COUNTRIES.includes(this.$appData.session?.geolocation);
    },
  },
  watch: {
    mode (mode) {
      const closedForm: any = mode === 'login' ? this.$refs.registerForm : this.$refs.loginForm;
      const validator = closedForm.$v.form;

      // reset validator only for empty fields
      for (const field in validator.$params) {
        if (validator[field].$model === '') {
          validator[field].$reset();
        }
      }

      this.$router.push({
        name: mode,
        query: {
          service: this.$route.query.service,
          continue: this.$route.query.continue,
        },
      });
    },
  },
});


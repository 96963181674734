<copyright>
File: ssoProductLoginPage.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import ErrorPage from '@core/pages/error';
import Vue from 'vue';

export default Vue.extend({
  name: 'sso-product-login-page',
  components: {
    ErrorPage,
  },
  data () {
    return {
      redirectUri: '',
      productName: '',
    };
  },
  mounted () {
    this.redirectUri = this.$router.currentRoute.query.redirectUri;
    this.productName = this.$router.currentRoute.query.productName;
    this.openProductApp();
  },
  methods: {
    openProductApp () {
      window.location.replace(this.redirectUri);
    },
  },
});

</script>

<template lang="pug">

  error-page
    template(slot="header")
      | {{ $t('Redirecting to {productName}', { productName }) }}
    template(slot="text")
      | {{ $t('You will be redirected in a few moments. If the application does not open, click the button below.') }}
    template(slot="button")
      btn(@click="openProductApp") {{ $t('Relaunch') }}

</template>

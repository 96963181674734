/**
 * File: sets.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/
import { getCountries, RESTRICTED_COUNTRIES, sortCountries, State } from '@core/constants/geo';

export function getCountriesWithEmbargoed (country: string): State {
  const countries = getCountries();
  if (Object.keys(RESTRICTED_COUNTRIES).includes(country)) {
    return sortCountries({ ...countries, [country]: RESTRICTED_COUNTRIES[country] });
  }
  return countries;
}

<copyright>
File: productCardPDFM.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

card(type="product", :equalTo="equalTo", v-bind:class="[ defaultClass ]", icon="pd", data-qa="pdfm-card")
  template(slot="title")
    .text-ellipsis(data-qa="pdfm-product-name") {{ $t('Parallels Desktop for Mac') }}
  template(slot="actions")
    icon(name="download", @click="$router.push(getDownloadRoute)", data-qa="pdfm-download-icon")
  template(slot="about")
    span(data-qa="pdfm-total-limit") {{ $tc('for {limit} computers', item.totalLimit, { limit: item.totalLimit }) }}
  template(slot="info")
    list.padding-bottom-xs
      router-link.link(
        :to="{ name: 'desktopSubscriptions', query: { display: STATUS_ACTIVE } }",
        :data-name="$name('link-subscriptions-active')",
        data-qa="pdfm-desktop-subscriptions-active"
      )
        .row
          .col-xs-10.text-ellipsis
            span(v-if="!item.hasPermanent", data-qa="pdfm-active-subscriptions-lbl") {{ $t('Active subscriptions') }}
            span(v-else-if="!item.hasSubscription", data-qa="pdfm-permanent-licenses-lbl") {{ $t('Permanent licenses') }}
            span(v-else, data-qa="pdfm-active-subscriptions-and-permanent-licenses-lbl") {{ $t('Active subscriptions and permanent licenses') }}
          .col-xs-1
            router-link(
              v-if="item.hasExpiringSubscriptions",
              :to="{ name: 'desktopSubscriptions', query: { display: STATUS_IS_EXPIRING_SOON } }",
              :data-name="$name('link-subscriptions-expiring')",
              data-qa="pdfm-desktop-subscriptions-expiring"
            )
              icon(name="alert-yellow").pull-right
          .col-xs-1.text-right.text-link.text-bold(data-qa="pdfm-active-subscriptions-count") {{ item.activeSubscriptions }}
      router-link(
        v-if="item.expiredSubscriptions",
        :to="{ name: 'desktopSubscriptions', query: { display: STATUS_EXPIRED } }",
        :data-name="$name('router-link-desktop-subscriptions-expired')",
        data-qa="pdfm-desktop-subscriptions-expired"
      )
        .row
          .col-xs-9.text-ellipsis(data-qa="pdfm-expired-subscriptions-lbl") {{ $t('Expired subscriptions') }}
          .col-xs-3.text-right.text-link.text-bold(data-qa="pdfm-expired-subscriptions-count") {{ item.expiredSubscriptions }}
      router-link(
        :to="{ name: 'legacyLicenses' }",
        v-if="item.legacyLicenses",
        :data-name="$name('router-link-legacy-licenses')",
        data-qa="pdfm-legacy-licenses"
      )
        .row
          .col-xs-9.text-ellipsis(data-qa="pdfm-legacy-licenses") {{ $t('Legacy licenses') }}
          .col-xs-3.text-right.text-link.text-bold(data-qa="pdfm-legacy-licenses-count") {{ item.legacyLicenses }}
  template(slot="menu")
    btn.block-xs.margin-right-2x-sm.margin-top-2x(
      v-if="item.canBuyMoreLicenses",
      @click="showAddLicensesModal",
      size="small",
      color="white",
      :data-name="$name('button-buy-more-licenses')",
      data-qa="pdfm-buy-more-licenses-button"
    )
      | {{ $t('Buy More Licenses') }}
    btn.block-xs.margin-top-2x(
      target="_blank",
      size="small",
      color="white",
      :data-name="$name('button-support')",
      data-qa="pdfm-support-button",
      @click="onSupportClick"
    )
      | {{ $t('Support') }}

</template>

<script>

import ProductCardMixIn from './productCardMixIn';
import { DESKTOP_DOWNLOADS_PAGE } from '@/routes/constants';

export default {
  name: 'product-card-pdfm',
  mixins: [ProductCardMixIn],
  data () {
    return {
      DESKTOP_DOWNLOADS_PAGE: DESKTOP_DOWNLOADS_PAGE,
    };
  },
};

</script>


import Vue, { PropType } from 'vue';
import QrcodeVue from 'qrcode.vue';

import OneTimePasswordMixin from '@/modules/oneTimePasswordMixin';

export default Vue.extend({
  name: 'add-new-authenticator-app-form',

  mixins: [OneTimePasswordMixin],

  props: {
    setupAccountLink: String,
    secret: String,
    form: Object as PropType<{ otp: string }>,
  },

  components: { QrcodeVue },

  data () {
    return {
      qrSize: 200,
    };
  },

  computed: {
    splitSecret (): string {
      const chunks = this.secret.match(/.{1,4}/g);
      return chunks ? chunks.join(' ') : '';
    },
  },
});


/**
 * File: generatePackagedLicensesRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { snakeize } from '@core/common/utils';

export interface GeneratePackagedLicensesRequestParams {
  displayName: string;
  seller: string;
  isTrial?: boolean;
  rationale: string;
  serialNumbers?: string[];
  serialNumbersPreloadedListId?: string;
  returnedUnits?: boolean;
}

interface GeneratePackagedLicensesRequestResponse {
  // eslint-disable-next-line camelcase
  tracker_id: string;
}

export default class GeneratePackagedLicensesRequest extends ApiRequest<GeneratePackagedLicensesRequestParams, GeneratePackagedLicensesRequestResponse> {
  get url () {
    return '/license/api/v1/packaged_licenses';
  }

  get method (): Method {
    return 'POST';
  }

  get body () {
    return snakeize(this.params);
  }

  getTrackerId () {
    return this.data.tracker_id;
  }
}

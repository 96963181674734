
import Vue, { PropType } from 'vue';
import Subscription from '@/models/subscription';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';
import { DOWNGRADING_SUBSCRIPTION_PD } from '@core/constants/links';

export default Vue.extend({
  name: 'productsList',

  mixins: [ComponentMixIn, SubscriptionMixIn],

  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
    subscriptionOptions: {
      type: Array,
    },
    label: {
      type: String,
    },
    showDowngrade: {
      type: Boolean,
    },
    forecastQtyDelta: {
      type: String,
    },
  },

  data () {
    return {
      DOWNGRADING_SUBSCRIPTION_PD,
    };
  },

  computed: {
    labelWidth (): number {
      return this.subscription.isBytebotStore ? 9 : 8;
    },
  },
});


/**
 * File: rtSessionRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest, { RtRequestParams } from './rtRequest';
import { Method } from '@core/api/apiRequest';

interface Params extends RtRequestParams {
  withSupportOptions?: boolean;
  country?: string;
  token: string;
  business: boolean;
  language: string;
}

interface Response {
  token: string;
  searchEngine: string;
}

interface QueryParams {
  fields?: string;
  country?: string;
}

export default class RtSessionRequest extends RtRequest<Params, Response> {
  get uri (): string {
    const uri = '/session';
    const qp = {} as QueryParams;

    if (this.params.withSupportOptions) {
      qp.fields = 'support_options';
      qp.country = this.params.country;
    }

    const qs = this.buildQueryString(qp);

    return qs ? uri + qs : uri;
  }

  get method (): Method {
    return 'POST';
  }

  get body () {
    return {
      token: this.params.token,
      business: !!this.params.business,
      language: this.params.language,
    };
  }
}

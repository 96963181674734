/**
 * File: iosClick.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 **/

import { ios } from '@core/common/browser';

// iOS refuse to handle click events for elements without "cursor: pointer"
// this code added to handle document element clicks
if (ios) {
  const el = document.getElementById('app');
  el.style.cursor = 'pointer';
}

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

function model (params = {}) {
  return {
    column: params.prop,
    value: params.multiple ? [] : '',
    options: params.options,
    multiple: params.multiple,
    type: params.type,
  };
}

export default {
  name: 'table-filter',
  props: {
    name: {
      type: String,
      required: true,
    },
    modal: {
      type: String,
      default: 'tableFilter',
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 12,
    },
    title: {
      type: String,
    },
    columns: {
      type: Array,
    },
    filters: {
      type: Object,
    },
  },
  data () {
    return {
      tableFilters: [model()],
    };
  },
  created () {
    this.loadFilters();
  },
  computed: {
    modalTitle () {
      return this.title || this.$t('Filter');
    },
    canRemoveFilter () {
      return !(this.tableFilters.length === 1 && !this.tableFilters[0].column);
    },
    configName () {
      const uid = (this.$appData.session && this.$appData.session.personalDomainId) || 0;
      return `smart_filters_${this.name}_${uid}`;
    },
  },
  methods: {
    getFilterOptions (selectedColumn) {
      const excludeOptions = this.tableFilters.filter((filter) => filter.column !== selectedColumn).map((filter) => filter.column);
      return this.columns.filter((option) => excludeOptions.indexOf(option.prop) < 0).map((option) => {
        return Object.assign({}, option, {
          value: option.prop,
        });
      });
    },
    addFilter (i) {
      this.tableFilters.splice(i + 1, 0, model());
    },
    removeFilter (i) {
      this.tableFilters.splice(i, 1);
      if (!this.tableFilters.length) {
        this.tableFilters.push(model());
      }
    },
    removeSmartFilters () {
      const filters = Object.assign({}, this.filters);
      Object.keys(filters).forEach((name) => {
        if (name.match(/^smart__/)) {
          delete filters[name];
        }
      });
      return filters;
    },
    apply () {
      const smartFilters = this.tableFilters.reduce((filters, filter) => {
        if (typeof filter.value === 'boolean' || (filter.value && (filter.value.length || filter.value instanceof Date))) {
          filters[`smart__${filter.column}`] = (function (field, term, self) {
            return function (entry) {
              let value = typeof entry[field] === 'boolean' ? entry[field] : (entry[field] || '');
              if (typeof value === 'boolean') {
                return value === term;
              }
              // Convert date and numbers to String
              if (value instanceof Date) {
                value = self.formatDate(value);
              } else if (!isNaN(parseFloat(value)) && isFinite(value)) {
                value = String(value);
              }
              if (term instanceof Date) {
                term = self.formatDate(term);
              }
              if (Array.isArray(term)) {
                return term.some((term) => value.toLowerCase() === term.toLowerCase());
              }
              return value.toLowerCase().indexOf(term.toLowerCase()) > -1;
            };
          })(filter.column, filter.value, this);
        }
        return filters;
      }, {});
      const filters = this.removeSmartFilters();

      this.$emit('update:filters', Object.assign({}, filters, smartFilters));
      this.saveFilters();
      this.$modal.hide();
    },
    reset () {
      this.tableFilters = [model()];
      this.$emit('update:filters', this.removeSmartFilters());
      this.$emit('reset');
      this.saveFilters();
      this.$modal.hide();
    },
    setFilter (value) {
      const filter = this.tableFilters.find((filter) => filter.column === value);
      let column = this.columns.find((column) => column.prop === value);

      if (filter) {
        column = model(column);
        // Save value if it has the same type as column
        if (typeof column.value === typeof filter.value) {
          column.value = filter.value;
        }
        Object.assign(filter, column);
      }
    },
    saveFilters () {
      localStorage.setItem(this.configName, JSON.stringify({
        filters: this.tableFilters,
      }));
    },
    loadFilters () {
      const config = JSON.parse(localStorage.getItem(this.configName));
      if (config && config.filters) {
        const tableFilters = config.filters.filter((filter) => {
          return this.columns.some((column) => column.prop === filter.column);
        }).map((filter) => {
          if (filter.type === 'date') {
            filter.value = new Date(filter.value);
          }
          if (filter.options) {
            filter.options = this.columns.find((column) => column.prop === filter.column).options;
          }
          return filter;
        });
        if (tableFilters.length) {
          this.tableFilters = tableFilters;
          this.apply();
        }
      }
    },
  },
};

</script>

<template lang="pug">

modal(:name="modal", :closeButton="closeButton", :size="size")
  template(slot="header") {{ modalTitle }}
  template(slot="content")
    list(label-width="5", :labelCrop="false", labelColor="default")
      list-item(v-for="(filter, i) in tableFilters", :key="i")
        dropdown(slot="label", :options="getFilterOptions(filter.column)", @change="setFilter", v-model="filter.column", :placeholder="$t('Select column')").block
        div(slot="value")
          .row
            .col-xs-8
              template(v-if="filter.options")
                template(v-if="$scopedSlots.dropdown")
                  dropdown(
                    v-model="filter.value",
                    :placeholder="filter.placeholder",
                    :options="filter.options",
                    :multiple="filter.multiple",
                    mode="form",
                    :disabled="!filter.column",
                    :comboBox="filter.multiple",
                    :button="$t('Done')"
                  ).block
                    template(v-if="$scopedSlots.dropdown", slot="text", slot-scope="item")
                      slot(name="dropdown", :item="item", :filter="filter")
                template(v-else)
                  dropdown(
                    v-model="filter.value",
                    :placeholder="filter.placeholder",
                    :options="filter.options",
                    :multiple="filter.multiple",
                    mode="form",
                    :disabled="!filter.column",
                    :button="$t('Done')"
                  ).block
              textbox(
                v-else,
                v-model="filter.value",
                :placeholder="filter.placeholder",
                :disabled="!filter.column",
                :type="filter.type",
                :formatDate="formatDate"
              )
            .col-xs-4.text-right.line-height-3x
              btn.sign(
                color="white",
                size="small",
                :square="true",
                @click="removeFilter(i)",
                :disabled="!canRemoveFilter",
                :data-name="$name('button-filter-remove')"
              ) &#65293;
              btn.sign.margin-left(
                color="white",
                size="small",
                :square="true",
                @click="addFilter(i)",
                :disabled="!filter.column",
                :data-name="$name('button-filter-add')"
              ) &#65291;
  template(slot="footer")
    btn(
      @click="apply",
      :data-name="$name('button-apply')"
    ) {{ $t('Apply') }}
    .pull-left
      btn(
        color="red-stroke",
        @click="reset",
        :data-name="$name('button-reset')"
      ) {{ $t('Reset') }}

</template>

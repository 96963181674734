/**
 * File: rasHostsRequestV2.ts
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import { V2Response } from '@/apiv2/V2RequestType';

export interface RasHostResponse {
  'id': number;
  'subscription_uuid': string;
  'name': string;
  'os_name': string;
  'product_version': string;
  'hw_id': string;
  'farm_guid': string;
  'last_reported_at': Date;
  'activated_at': Date;
  'notes': string;
  'universal_key_id': number;
  'owner': {
    'id': number;
    'is_business': boolean;
    'uuid': string;
  };
}

interface RasHostsRequestParams {
  id?: number;
  hosts?: number[];
}

export default class RasHostsRequestV2 extends ApiRequest<RasHostsRequestParams, V2Response<RasHostResponse[]>> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    return `/ras/api/v2/subscriptions/usage_periods/${this.params.id}/specific_hosts?host_id__in=${this.params.hosts.join(',')}`;
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}

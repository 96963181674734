
import { email } from '@core/common/validators';
import RestorePasswordRequest from '@/api/restorePasswordRequest';
import GetProfileDataByLicenseKeyRequest from '@/api/GetProfileDataByLicenseKeyRequest';
import ContactSupportModal, { FORGOT_PASSWORD_SCENARIO } from '@/modules/contactSupportModal.vue';
import StepInitial from './stepInitial.vue';
import StepEmail from './stepEmail.vue';
import StepLicenseKey from './stepLicenseKey.vue';
import StepDone from './stepDone.vue';
import Vue from 'vue';
import { LOGIN_PAGE, FORGOT_PASSWORD_PAGE } from '@/routes/constants';
import {
  STEP_EMAIL_NAME,
  STEP_LICENSE_KEY_NAME,
  STEP_INITIAL,
  STEP_EMAIL,
  STEP_LICENSE_KEY,
  STEP_DONE,
  Step
} from './constants';

export const STORAGE_KEY_NAME = 'reset-password-attempts';
const MAX_ATTEMPTS = 2;

export default Vue.extend({
  name: 'forgot-page',

  props: {
    appData: {
      type: Object,
    },
  },

  components: {
    ContactSupportModal,
    StepInitial,
    StepEmail,
    StepLicenseKey,
    StepDone,
  },

  data () {
    const attempts = this.$sessionStorage.get(STORAGE_KEY_NAME, {}) as { [key: string]: number };

    return {
      loading: false,
      backPathExists: false,
      step: 1 as Step,
      email: '',
      licenseKey: '',
      attempts,
      fromStepName: null as typeof STEP_EMAIL_NAME | typeof STEP_LICENSE_KEY_NAME,
      FORGOT_PASSWORD_SCENARIO,
      STEP_INITIAL,
      STEP_EMAIL,
      STEP_LICENSE_KEY,
      STEP_DONE,
    };
  },

  validations: { email },

  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (from.name && from.name !== FORGOT_PASSWORD_PAGE) {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        vm.backPathExists = true;
      }
    });
  },

  methods: {

    calculateAttempts (key: string) {
      if (typeof this.attempts[key] === 'number') {
        this.attempts[key]++;
      } else {
        this.attempts[key] = 1;
      }
    },

    async submitEmail (email: string) {
      this.email = email;
      this.licenseKey = '';

      this.fromStepName = STEP_EMAIL_NAME;

      this.calculateAttempts(email);

      if (this.attempts[this.email] > MAX_ATTEMPTS) {
        this.$modal.show(ContactSupportModal);
      } else {
        this.$sessionStorage.set(STORAGE_KEY_NAME, this.attempts);

        const request = new RestorePasswordRequest({ email: this.email });
        this.loading = true;

        try {
          await this.$api.call(request);
        } finally {
          this.loading = false;
        }
      }
    },

    async submitLicenseKey (licenseKey: string) {
      this.licenseKey = licenseKey;
      this.email = '';

      this.fromStepName = STEP_LICENSE_KEY_NAME;

      this.calculateAttempts(licenseKey);

      if (this.attempts[this.licenseKey] > MAX_ATTEMPTS) {
        this.$modal.show(ContactSupportModal);
      } else {
        this.$sessionStorage.set(STORAGE_KEY_NAME, this.attempts);

        const profileDataRequest = new GetProfileDataByLicenseKeyRequest({ licenseKey: this.licenseKey });
        this.loading = true;

        try {
          const data = await this.$api.call(profileDataRequest);
          const passwordRestoreRequest = new RestorePasswordRequest({ userProfile: data.objects[0].user_profile });
          try {
            await this.$api.call(passwordRestoreRequest);
          } catch (e) {}
        } finally {
          this.loading = false;
        }
      }
    },

    goToStep (step: Step) {
      this.step = step;
    },

    goSign () {
      this.$router.push({ name: LOGIN_PAGE });
    },

  },

  computed: {
    displayEmail (): boolean {
      return !this.email;
    },
  },

});

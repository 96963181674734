/**
 * File: pdCreateUpdateConfigurationProfileRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class PdCreateUpdateConfigurationProfileRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {?Number} params.id
   * @param {?String} params.templateVersion
   * @param {String} params.name
   * @param {String} params.values
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    if (this.params.id) {
      return 'PUT';
    }
    return 'POST';
  }

  get url () {
    if (this.params.id) {
      return `/desktop/api/v1/ba/configuration_profiles/${this.params.id}`;
    }
    return '/desktop/api/v1/ba/configuration_profiles/';
  }

  get body () {
    const data = { name: this.params.name, values: this.params.values };
    if (this.params.templateVersion) {
      data.templateVersion = this.params.templateVersion;
    }
    return data;
  }
}

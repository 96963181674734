

import Vue from 'vue';
import BannerNotification from '@/ui/bannerNotification/index.vue';
import RasBannersRequest from '@/api/rasBannersRequest';
import RasDismissBannerRequest from '@/api/rasDismissBannerRequest';
import type RasNfrBanner from '@/models/rasNfrBanner';
import { KB_LINK_RAS_NFR } from '@core/constants/links';

const KEY_PREFIX: string = 'ras-nfr-banners';
const PAGINATE_NOTIFICATIONS_MODAL_NAME: string = 'paginateNotificationsModal';
const EVENT_NAME_BANNER_SEEN: string = 'bannerSeen';

export default Vue.extend({
  name: 'ras-nfr-notification',
  props: {
    subscription: {
      type: Object,
      required: false,
      default () {
        return null;
      },
    },
  },
  components: {
    BannerNotification,
  },
  data () {
    return {
      banners: [] as RasNfrBanner[],
      currentIndex: 0,
      kbArticleLink: KB_LINK_RAS_NFR,
      paginateNotificationsModalName: PAGINATE_NOTIFICATIONS_MODAL_NAME,
      eventNameBannerSeen: EVENT_NAME_BANNER_SEEN,
    };
  },
  computed: {
    visibleBanners (): RasNfrBanner[] {
      if (this.subscription) {
        return this.banners.filter((item) => !item.closedDetails);
      } else {
        return this.banners.filter((item) => !item.closedGlobally && !item.closedDetails && !item.seen);
      }
    },
    show (): boolean {
      if (this.subscription) {
        return !!this.getBanner(this.subscription.uuid);
      } else {
        return this.visibleBanners.length > 0;
      }
    },
    keyName (): string {
      return `${KEY_PREFIX}-${this.$appData.session.personalDomainId}`;
    },
    singleBanner (): RasNfrBanner|null {
      if (this.visibleBanners.length === 1) {
        return this.visibleBanners[0];
      } else {
        return null;
      }
    },
    multipleBanners (): RasNfrBanner[]|null {
      if (this.visibleBanners.length > 1) {
        return this.visibleBanners;
      } else {
        return null;
      }
    },
    currentBanner (): RasNfrBanner {
      return this.multipleBanners[this.currentIndex];
    },
    subscriptionBanner (): RasNfrBanner|null {
      if (this.subscription) {
        return this.getBanner(this.subscription.uuid);
      } else {
        return null;
      }
    },
    hasNextBanner (): boolean {
      return this.currentIndex < this.visibleBanners.length - 1;
    },
    hasPrevBanner (): boolean {
      return this.currentIndex > 0;
    },
  },
  created (): void {
    this.init();
    if (!this.subscription) {
      this.$bus.$on(this.eventNameBannerSeen, this.init);
    }
  },
  destroyed (): void {
    if (!this.subscription) {
      this.$bus.$off(this.eventNameBannerSeen, this.init);
    }
  },
  methods: {
    load (): void {
      const request = new RasBannersRequest();
      this.$api.authorizedCall(request).then((data) => {
        this.banners = request.getBanners();
        this.saveBannersToLocalStorage();
      });
    },
    init (): void {
      let storedBanners = null;
      Object.keys(this.$localStorage.storage).forEach((storageKey) => {
        if (storageKey.startsWith(KEY_PREFIX)) {
          if (storageKey === this.keyName) {
            storedBanners = this.$localStorage.get(storageKey);
          } else {
            this.$localStorage.remove(storageKey);
          }
        }
      });
      if (storedBanners) {
        this.banners = storedBanners;
      } else {
        this.load();
      }
    },
    saveBannersToLocalStorage (): void {
      this.$localStorage.set(this.keyName, this.banners);
    },
    closeBanner (subscriptionUuid): void {
      const banner = this.getBanner(subscriptionUuid);
      if (banner) {
        if (this.subscription) {
          banner.closedDetails = true;
        } else {
          banner.closedGlobally = true;
        }
        this.saveBannersToLocalStorage();
      }
    },
    closeAllBanners (): void {
      this.visibleBanners.forEach((banner) => {
        banner.closedGlobally = true;
      });
      this.saveBannersToLocalStorage();
    },
    openModal (): void {
      this.$modal.show(this.paginateNotificationsModalName);
    },
    hideModal (): void {
      this.$modal.hide(this.paginateNotificationsModalName);
    },
    onKeyClick (subscriptionUuid): void {
      this.hideModal();
      this.$router.push({
        name: 'rasSubscriptionDetails',
        params: {
          id: subscriptionUuid,
        },
      });
    },
    nextBanner (): void {
      if (this.hasNextBanner) {
        this.currentIndex += 1;
      }
    },
    prevBanner (): void {
      if (this.hasPrevBanner) {
        this.currentIndex -= 1;
      }
    },
    getBanner (subscriptionUuid): RasNfrBanner {
      return this.visibleBanners.find((banner) => banner.uuid === subscriptionUuid);
    },
    setBannerSeen (): void {
      const banner = this.getBanner(this.subscription.uuid);
      if (!banner || banner.seen) {
        return;
      }
      this.$api.authorizedCall(
        new RasDismissBannerRequest({ subscriptionUuid: banner.uuid })
      ).then(() => {
        banner.seen = true;
        this.saveBannersToLocalStorage();
        this.$bus.$emit(this.eventNameBannerSeen);
      });
    },
  },
  watch: {
    banners (newValue) {
      if (newValue.length && this.subscription) {
        this.setBannerSeen();
      }
    },
    'subscription.uuid': {
      handler (newValue) {
        if (newValue) {
          this.setBannerSeen();
        }
      },
    },
  },
});



import Vue, { PropType } from 'vue';
import { STEP_INITIAL, STEP_DONE, Step } from './constants';

export default Vue.extend({
  props: {
    step: {
      type: Number as PropType<Step>,
    },
  },

  methods: {
    submit (key: string) {
      this.$emit('submit', key);
      this.$emit('goToStep', STEP_DONE);
    },
    goSign () {
      this.$emit('goSign');
    },
    goNext () {
      const nextStep = this.step + 1;
      if (nextStep > STEP_DONE) {
        this.goSign();
      } else {
        this.$emit('goToStep', nextStep);
      }
    },
    goBack () {
      const prevStep = this.step - 1;
      if (prevStep < STEP_INITIAL) {
        this.goSign();
      } else {
        this.$emit('goToStep', prevStep);
      }
    },
  },
});


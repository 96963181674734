
import Vue, { PropType } from 'vue';

export type ISalesRepresentative = {
  name: string;
  title: string;
  email: string;
  phone: string;
}

export default Vue.extend({
  name: 'salesTab',

  props: {
    salesRepresentative: {
      type: Object as PropType<ISalesRepresentative>,
    },
  },
});

/**
 * File: session.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';
import { toSnakeCase } from '@core/common/utils';
import { FEATURE_CONFIRMED_ACCOUNTS, FEATURE_PURCHASE_PDFC_ONLINE } from '@core/constants/features';
import { MFA_METHOD } from '@core/constants/mfa';
import { Dictionary } from '@core/common/types';

const DEFAULT_LANGUAGE = 'en';

export enum ROLES {
  GUEST = 'guest',
  PERSONAL = 'pa-admin',
  BA_ADMIN = 'ba-admin',
  BA_USER = 'ba-user'
}

export const SECONDS_IN_DAY = 86400;

export default class Session extends CoreModel {
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  personalDomain: any; // todo: create TS domain model
  businessDomain: any; // todo: create TS domain model
  lepc: number;

  trusted: boolean;
  confirmed: boolean;
  token: string;
  locale: string;
  otherBusinessDomains: any[];
  roles: any[];
  pendingInvitationsCount: number;
  mfaStatus: boolean;
  mfaMethod: MFA_METHOD;
  showSessionExtension: boolean;
  showHomePage: boolean;
  sso: boolean;

  rtUrl: string;
  kbUrl: string;
  chatbotUrl: string;
  daasAdminPortalUrl?: string;
  daasUserPortalUrl?: string;
  pbiUserPortalUrl?: string;
  pdaPortalUrl?: string;
  extended: boolean;

  private __geolocation: string;
  private __country: string;
  private __features: Dictionary;
  private __featuresByProduct: Dictionary;

  get name () {
    return `${this.firstName} ${this.lastName}`;
  }

  get personalDomainId () {
    return this.personalDomain && this.personalDomain.domainId;
  }

  get businessDomainId () {
    return this.businessDomain && this.businessDomain.companyUuid;
  }

  get daysSincePasswordChange () {
    if (this.lepc) {
      const seconds = Date.now() / 1000 - this.lepc;
      return Math.floor(seconds / SECONDS_IN_DAY);
    } else {
      return 0;
    }
  }

  get country () {
    return this.__country.toUpperCase();
  }

  get geolocation () {
    return this.__geolocation.toUpperCase();
  }

  get businessDomainMemberSince () {
    return this.businessDomain && this.businessDomain.memberSince;
  }

  get authenticated () {
    return !!(this.trusted && this.token);
  }

  get isoLocale () {
    return this.locale && this.locale.replace(/([^_]+_)(.+)/, (_, language, country) => language + country.toUpperCase());
  }

  get language () {
    return (this.locale && this.locale.split('_')[0]) || DEFAULT_LANGUAGE;
  }

  get businessDomainName () {
    return this.businessDomain && this.businessDomain.companyName;
  }

  get isPersonal () {
    return this.roles.includes(ROLES.PERSONAL);
  }

  get isGuest () {
    return this.roles.includes(ROLES.GUEST);
  }

  get isBusinessAdmin () {
    return this.businessDomain && this.businessDomain.isAdmin;
  }

  get isRegularMember () {
    return this.businessDomain && !this.businessDomain.isAdmin;
  }

  get isSsoEmployee (): boolean {
    return this.sso && this.isRegularMember;
  }

  get hasBusinessDomains (): boolean {
    return !!this.getBusinessDomains().length;
  }

  getBusinessDomains (adminOnly = false) {
    let domains = [];
    const currentDomain = this.getCurrentBusinessUser();

    if (currentDomain) {
      domains.push(currentDomain);
    }

    if (this.otherBusinessDomains) {
      domains = domains.concat(this.otherBusinessDomains);
    }

    if (adminOnly) {
      return domains.filter((domain) => domain.isAdmin);
    }

    return domains;
  }

  isBusinessUser (userInDomain) {
    const businessDomains = this.getBusinessDomains();
    for (var domain of businessDomains) {
      if (domain.companyUuid === userInDomain) {
        return true;
      }
    }
    return false;
  }

  isPersonalUser (userInDomain) {
    return this.personalDomain && this.personalDomain.domainId === userInDomain;
  }

  isCurrentBusinessUser (userInDomain) {
    const currentBusinessUser = this.getCurrentBusinessUser();
    return currentBusinessUser && currentBusinessUser.companyUuid === userInDomain;
  }

  isCurrentRegularMember (userInDomain) {
    const currentBusinessUser = this.getCurrentBusinessUser();
    return currentBusinessUser && !currentBusinessUser.isAdmin && currentBusinessUser.companyUuid === userInDomain;
  }

  getCurrentBusinessUser () {
    return this.businessDomain;
  }

  invitationProcessed () {
    if (this.pendingInvitationsCount) {
      this.pendingInvitationsCount -= 1;
    }
  }

  get features () {
    return Object.keys(this.__features || {}).reduce((features, name) => {
      features[toSnakeCase(name)] = this.__features[name];
      return features;
    }, {});
  }

  get featuresByProduct () {
    return Object.keys(this.__featuresByProduct || {}).reduce((featuresByProduct, name) => {
      featuresByProduct[toSnakeCase(name)] = this.__featuresByProduct[name];
      return featuresByProduct;
    }, {});
  }

  isFeatureAccessible (name) {
    return !!(this.features[name] && this.features[name].accessible);
  }

  isFeatureAccessibleByProduct (name, products) {
    const fbp = this.featuresByProduct[name];
    return this.isFeatureAccessible(name) && (!fbp || !fbp.length || products.some((product) => fbp.includes(product.productKeyName)));
  }

  isSessionConfirmed () {
    // Session is considered as confirmed if 'confirmed_accounts' feature is not accessible or user confirmed his email.
    return !this.isFeatureAccessible(FEATURE_CONFIRMED_ACCOUNTS) || this.confirmed;
  }

  public setConfirmed (value: boolean) {
    this.confirmed = value;
  }

  get isPdfcPurchaseFeatureAccessible () {
    return this.isFeatureAccessible(FEATURE_PURCHASE_PDFC_ONLINE);
  }
}

/**
 * File: joinBusinessAccountRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import md5 from 'blueimp-md5';
import passwordErrors from './errorHandlers/passwordErrors';
import inviteAcceptErrors from './errorHandlers/inviteAcceptErrors';

export type JoinBusinessAccountRequestParams = {
  domainId: number;
  password?: string;
};

// https://wiki.prls.net/x/YNURBQ
export default class JoinBusinessAccountRequest extends ApiRequest<JoinBusinessAccountRequestParams> {
  get method (): Method {
    return 'POST';
  }

  get url () {
    return `/account/api/v2/business_domain/${this.params.domainId}/join`;
  }

  get body (): Json {
    if (this.params.password) {
      return {
        password: md5(this.params.password),
      };
    } else {
      return {};
    }
  }

  get errors () {
    return Object.assign({}, inviteAcceptErrors(), passwordErrors());
  }
}

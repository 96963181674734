/**
 * File: trustBrowserMixIn.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */
import Vue from 'vue';
import BrowserListRequest from '@/api/browserListRequest';
import { Browser } from '@/models/trustedEntity';
import WebBrowserVerificationModal from '@/modules/confirm/browser/webBrowserVerificationModal.vue';
import { MFA_METHOD } from '@core/constants/mfa';
import VerifyOneTimePasswordRequest from '@/api/verifyOneTimePasswordRequest';
import ResendBrowserConfirmation from '@/api/resendBrowserConfirmation';
import OneTimePasswordWorkflowMixin from '@/modules/oneTimePasswordWorkflowMixin';

export default Vue.extend({
  name: 'trust-browser-mix-in',
  mixins: [OneTimePasswordWorkflowMixin],
  data () {
    return {
      entities: [] as Array<Browser>,
    };
  },
  computed: {
    currentEntity (): Browser {
      return this.entities.find(e => e.current);
    },

    isCurrentEntityTrusted (): boolean {
      return this.currentEntity && this.currentEntity.trusted;
    },
  },
  methods: {
    async checkBrowserTrusted () {
      if (!this.$appData.session.mfaStatus) {
        return true;
      }

      if (!this.isCurrentEntityTrusted) {
        const request = new BrowserListRequest();
        await this.authorizedCall(request, 'loading');
        if (this.entities.length > 0) {
          request.getBrowsers().forEach((browser) => {
            this.entities.forEach((entity) => {
              if (entity instanceof Browser) {
                if (browser.id === entity.id) {
                  Object.assign(entity, browser);
                }
              }
            });
          });
        } else {
          this.entities = request.getBrowsers();
        }
      }

      const currentBrowserIsTrusted = this.isCurrentEntityTrusted;
      if (!currentBrowserIsTrusted) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.resetAppliedPasswordState();
        this.$modal.show(WebBrowserVerificationModal, this.$appData.session.mfaMethod === MFA_METHOD.AUTH_APPLICATION);
      }

      return currentBrowserIsTrusted;
    },

    async confirmBrowser (oneTimePassword: string = null) {
      if (!oneTimePassword) {
        await this.sendBrowserVerificationLetter();
      } else {
        const request = new VerifyOneTimePasswordRequest({ oneTimePassword: oneTimePassword, markTrusted: true });
        this.loading = true;
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.resetAppliedPasswordState(oneTimePassword);
        try {
          await this.$api.authorizedCall(request);
          this.$modal.hide();
          this.$toast.show({
            color: 'green',
            text: this.$t('Browser was successfully verified.'),
          });
          new BrowserListRequest().dropCache();
          this.entities.find((entity) => entity.current).trusted = true;
        } catch (e) {
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
          this.invalidPasswordEntered();
        } finally {
          this.loading = false;
        }
      }
    },

    async sendBrowserVerificationLetter () {
      await this.$api.authorizedCall(new ResendBrowserConfirmation({ forSecuritySettings: true }));
      this.$modal.hide();
      this.$toast.show({
        color: 'green',
        text: this.$t('Instructions on verifying your web browser have been sent to your email address {email}.', { email: this.session.email }),
      });
      new BrowserListRequest().dropCache();
    },
  },
});

/**
 * File: toolboxHostsRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import ToolboxHost from '@/models/toolboxHost';

export default class ToolboxHostsRequest extends ApiRequest {
  get url () {
    return '/toolbox/webapp/hosts';
  }

  /**
   * @returns Array{ToolboxHost}
   */
  get hosts () {
    return this.data && this.data.hosts.map((host) => new ToolboxHost(host));
  }
}

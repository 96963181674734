/**
 * File: upgradeToProPage.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { LICENSE_SERVICE } from '@core/constants/services';
import AuthByTokenRequest from '@/api/authByTokenRequest';
import UpgradeToProRequest from '@/api/upgradeToProRequest';
import SubscriptionRequest from '@/api/subscriptionRequest';
import SubscriptionTurnOnRequest from '@/api/subscriptionAutoRenewalUpdateRequest';
import UpgradeForm from './upgradeForm';
import BaseForm from './baseForm';
import BottomLinks from '@/ui/bottomLinks.vue';
import {
  LEGAL_NOTICES_URL,
  HELP_UPGRADE_TO_PRO_URL
} from '@core/constants/links';

export default {
  name: 'upgrade-to-pro',

  props: {
    token: {
      type: String,
      required: false,
    },
    uuid: {
      type: String,
      required: true,
    },
  },

  components: { UpgradeForm, BaseForm, BottomLinks },

  data () {
    return {
      loading: false,
      upgradeComplete: false,
      turnOnComplete: false,
      error: null,
      authToken: this.token,
      sessionToken: '',
      upgrade: null,
      subscription: null,
      bottomLinksOptions: [LEGAL_NOTICES_URL, HELP_UPGRADE_TO_PRO_URL],
    };
  },

  created () {
    // Remove token from url
    this.$router.replace({ params: { token: undefined } });
    this.load();
  },

  methods: {

    authorizedRequest (request) {
      this.$api.setAuthHeader(request, { token: this.sessionToken });
      return request;
    },

    requestErrorHandler (error) {
      this.error = error;
      this.loading = false;
      this.upgradeComplete = false;
      this.turnOnComplete = false;
      this.subscription = null;
    },

    load () {
      this.loading = true;
      const request = new AuthByTokenRequest({ token: this.authToken });
      return request.load()
        .then(data => {
          this.sessionToken = data.token;
          const subsRequest = this.authorizedRequest(new SubscriptionRequest({ uuid: this.uuid }));
          const upgradeCheck = this.authorizedRequest(new UpgradeToProRequest({ uuid: this.uuid, dryRun: true }));
          return Promise.all([subsRequest.load(), upgradeCheck.load()]);
        })
        .then(data => {
          const [subsList, upgrade] = data;
          this.subscription = subsList && subsList.subscriptions[0];
          this.upgrade = upgrade;
          this.error = null;
          if (this.upgrade.url) {
            // Redirect URL to cart came in response - let's redirect at once
            window.location.href = this.upgrade.url;
          } else {
            this.loading = false;
          }
        })
        .catch(error => this.requestErrorHandler(error));
    },

    payHandler () {
      this.loading = true;
      const request = this.authorizedRequest(new UpgradeToProRequest({ uuid: this.subscription.uuid, dryRun: false }));
      return request.load()
        .then(data => {
          this.loading = false;
          this.upgrade = null;
          this.upgradeComplete = true;
          return data;
        })
        .catch(error => this.requestErrorHandler(error));
    },

    turnOnHandler () {
      this.loading = true;
      const request = this.authorizedRequest(new SubscriptionTurnOnRequest({ uuid: this.uuid, autorenew: true, service: LICENSE_SERVICE }));
      return request.load()
        .then(data => {
          this.loading = false;
          this.error = null;
          this.upgrade = null;
          this.turnOnComplete = true;
          return data;
        })
        .catch(error => this.requestErrorHandler(error));
    },

  },

};

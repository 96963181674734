

import Vue from 'vue';

import { companyName, country, state, zip, phone, address, city } from '@core/common/validators';

import ComponentMixin from '@/modules/componentMixIn';
import CompanyProfileRequest from '@/api/companyProfileRequest';
import CompanyUsersRequest from '@/api/companyUsersRequest';
import CompanyUpdateRequest from '@/api/companyUpdateRequest';
import CompanyLeaveRequest from '@/api/companyLeaveRequest';
import profileMixinGeneral from '@/modules/profileMixin/profileMixinGeneral';

interface CompanyData {
  name: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  // eslint-disable-next-line camelcase
  address_line1: string;
  phone: string;
}

export default Vue.extend({
  name: 'business-profile-general',

  mixins: [ComponentMixin, profileMixinGeneral],

  validations: {
    form: {
      name: companyName,
      address_line1: address,
      city,
      country,
      state,
      zip: zip,
      phone: phone,
    },
  },

  data () {
    return {
      data: {} as CompanyData,
      form: {},
      companyUsers: [],
      equalTo: 'businessProfileGeneralCard',
      companyLoading: false,
      formLoading: false,
      companyUsersLoading: false,
      leaveLoading: false,
    };
  },

  computed: {
    memberSince (): string {
      return this.formatDate(this.session.businessDomainMemberSince);
    },

    companyAdmins (): any[] {
      return this.companyUsers.filter(user => user.isAdmin && !user.isInvited);
    },

    isSso (): boolean {
      return this.session.sso;
    },
  },

  mounted () {
    this.load();
  },

  methods: {
    load (): void {
      if (!this.session.businessDomainId) {
        this.$router.replace({ name: 'dashboard' });
        return;
      }

      const companyProfileRequest = new CompanyProfileRequest({}, this.session.businessDomainId);

      this.authorizedCall(companyProfileRequest, 'profileLoaded').then((data) => {
        this.data = data.profile;
        this.resetForm();
      });

      const companyUsersRequest = new CompanyUsersRequest({}, this.session.businessDomainId);

      this.authorizedCall(companyUsersRequest, 'companyUsersLoading').then(() => {
        this.companyUsers = companyUsersRequest.getUsers();
      });
    },

    resetForm (): void {
      this.form = {
        name: this.data.name,
        city: this.data.city,
        country: this.data.country.toUpperCase(),
        state: this.data.state.toUpperCase(),
        address_line1: this.data.address_line1,
        phone: this.data.phone,
        zip: this.data.zip,
      };
    },

    cancelCompanyEdit (): void {
      this.$modal.hide('companyEdit');
      this.resetForm();
    },

    saveCompanyEdit (): void {
      const request = new CompanyUpdateRequest(this.form, this.session.businessDomainId);

      this.authorizedCall(request, 'formLoading').then(() => {
        // force session reload
        this.$api.refreshToken(true);
        // fill card with updated information
        Object.keys(this.form).map((key) => {
          this.data[key] = this.form[key];
        });
        this.formLoading = false;
        this.$modal.hide('companyEdit');
      });
    },

    leave (): void {
      const request = new CompanyLeaveRequest({}, this.session.businessDomainId);

      this.leaveLoading = true;

      this.$api.authorizedCall(request).then(() => {
        // force session reload
        return this.$api.refreshToken(true);
      }).then(() => {
        this.$modal.hide('companyLeave');
        // this.load();
        // CPCLOUD-9480
        this.$router.replace({ name: 'dashboard' });
      }).finally(() => {
        this.leaveLoading = false;
      });
    },
  },

});


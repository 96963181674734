
import Vue from 'vue';

import { required, maxLength, emailValidator } from '@core/common/validators';
import { copyToClipboard } from '@core/common/utils';
import GenerateRedeemCodeRequest from '@/api/generateRedeemCodeRequest';
import GetRedeemCodesRequest from '@/api/getRedeemCodesRequest';
import DecimalTextbox from '@/ui/decimalTextbox.vue';

const DEFAULT_FORM_VALUE = {
  customerOrganisationName: '',
  customerEmail: '',
  numberOfLicenses: '0',
  isTrial: false,
  reason: '',
};

const DEFAULT_REDEEM_CODE_VALUE = {
  code: '',
  customerOrganisationName: '',
  customerEmail: '',
  numberOfLicenses: 0,
  validFor: {
    units: '',
    value: 0,
  },
  reason: '',
};

export default Vue.extend({
  name: 'generate-redeem-code-modal',
  components: {
    DecimalTextbox,
  },
  data () {
    return {
      loading: false,
      form: Object.assign({}, DEFAULT_FORM_VALUE),
      redeemCode: Object.assign({}, DEFAULT_REDEEM_CODE_VALUE),
    };
  },
  validations: {
    form: {
      customerOrganisationName: {
        required,
        maxLength: maxLength(255),
      },
      customerEmail: {
        required,
        email: emailValidator,
        maxLength: maxLength(255),
      },
      numberOfLicenses: {
        required,
        amount (value) {
          return value === '' || value > 0;
        },
      },
      isTrial: {
        required,
      },
      reason: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  created () {
    this.init();
  },
  computed: {
    licenseTypes (): { value: boolean; text: string }[] {
      return [
        { value: true, text: this.$t('1-month license (trial)') },
        { value: false, text: this.$t('12-month license') },
      ];
    },
    validFor (): string {
      // $tc('{value} days')
      // $tc('{value} months')
      // $tc('{value} years')
      const { units: unit, value } = this.redeemCode.validFor;
      if (unit && value) {
        const text = `{value} ${unit}`;
        return this.$tc(text, value, { value });
      }
      return '';
    },
    numberOfLicenses (): string {
      // $tc('{value} licenses, valid for')
      const numberOfLicenses = this.$tc('{value} licenses, valid for', this.redeemCode.numberOfLicenses, { value: this.redeemCode.numberOfLicenses });
      return `${numberOfLicenses} ${this.validFor}`;
    },
  },
  methods: {
    init () {
      Object.assign(this.form, DEFAULT_FORM_VALUE);
      Object.assign(this.redeemCode, DEFAULT_REDEEM_CODE_VALUE);
      this.$v.$reset();
    },
    loadStatistics () {
      const request = new GetRedeemCodesRequest({ totalOnly: true });
      return this.$api.authorizedCall(request);
    },
    generate () {
      const request = new GenerateRedeemCodeRequest(this.form);

      this.loading = true;

      this.$api.authorizedCall(request).then((data) => {
        this.$emit('generate');
        this.redeemCode = data.redeemCode;
        return this.loadStatistics();
      }).catch(() => {
        this.$toast.show({
          text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
          color: 'red',
        });
        this.$modal.hide();
      }).finally(() => {
        this.loading = false;
      });
    },
    copyToClipboard (value) {
      copyToClipboard(value);
      const text = value === this.redeemCode.code ? this.$t('The code has been copied to the clipboard.') : this.$t('The link has been copied to the clipboard.');
      this.$toast.show({ text });
    },
    licensesTypeSelected (isTrial) {
      if (isTrial) {
        this.form.numberOfLicenses = '5';
      }
    },
  },
});


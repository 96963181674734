/**
 * File: routing.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import Service from '../constants/services';

export const getServiceUrl = function (service: Service, suffix: string): string {
  if (service === Service.License) {
    return '/license' + suffix;
  } else if (service === Service.RasLicense) {
    return '/ras-license' + suffix;
  }
  throw new Error('unknown service ' + service);
};

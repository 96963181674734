/**
 * File: productsRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { licenseService } from '@core/constants/links';

export default class ProductsRequest extends ApiRequest {
  get url () {
    return `${licenseService.PRODUCTS}`;
  }

  get cacheNameSpace () {
    return 'subscriptions';
  }
};

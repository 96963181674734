/**
 * File: daasRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  DAAS_SUBPAGE_SUBSCRIPTIONS,
  DAAS_SUBPAGE_SUBSCRIPTION_DETAILS, DAAS_DOWNLOADS_PAGE
} from './constants';
import daasPage from '@/modules/daas/daasPage.vue';
import { PRODUCT_KEY_NAME_DAAS, PRODUCT_NAME_DAAS } from '@core/constants/subscriptions';

export default [
  {
    path: '/daas/subscriptions',
    name: DAAS_SUBPAGE_SUBSCRIPTIONS,
    component: daasPage,
    props: () => ({
      content: DAAS_SUBPAGE_SUBSCRIPTIONS,
      productKeyName: PRODUCT_KEY_NAME_DAAS,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_DAAS}`,
    },
  },
  {
    path: '/daas/subscriptions/:id',
    name: DAAS_SUBPAGE_SUBSCRIPTION_DETAILS,
    component: daasPage,
    props: () => ({
      content: DAAS_SUBPAGE_SUBSCRIPTION_DETAILS,
      productKeyName: PRODUCT_KEY_NAME_DAAS,
    }),
    meta: {
      requiresAuth: true,
      title: `Subscriptions - ${PRODUCT_NAME_DAAS}`,
    },
  },
  {
    path: '/daas/downloads',
    name: DAAS_DOWNLOADS_PAGE,
    component: daasPage,
    props: () => ({
      content: DAAS_DOWNLOADS_PAGE,
      productKeyName: PRODUCT_KEY_NAME_DAAS,
    }),
    meta: {
      requiresAuth: true,
      title: `Download - ${PRODUCT_NAME_DAAS}`,
    },
  },
];

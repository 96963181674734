
import Vue from 'vue';
import { email } from '@core/common/validators';
import { SsoSignInRequest } from '@/api/sso/ssoSignInRequest';
import { HTTP_CODES } from '@core/constants/http';

export default Vue.extend({
  name: 'sign-in-modal',
  data () {
    return {
      loading: false,
      email: '',
    };
  },
  validations: { email },
  methods: {
    errorToMessage (err) {
      const resp = err.response;

      if (![HTTP_CODES.CONFLICT, HTTP_CODES.NOT_FOUND].includes(resp.status)) {
        throw err;
      }

      return this.$t('Login failed!');
    },
    async signIn () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const request = new SsoSignInRequest({ email: this.email });

      this.loading = true;

      try {
        window.location = (await this.$api.call(request)).url;
      } catch (err) {
        this.loading = false;
        this.$toast.show({ color: 'red', html: this.errorToMessage(err) });
      }
    },
  },
});


/**
 * File: chatClient.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import Vue, { PropType } from 'vue';
import { Agent } from '@/api/pbcRequest';
import ChatClientRasa from './chatClientRasa';
import ChatClientBeacon from './chatClientBeacon';
import { ChatStatus, MessageType } from './chatClientProvider';
import CreateTicketMixin, { TicketData } from '@/modules/support/content/createTicketMixin';

type Message = {
  type: string;
  provider: string;
  sourceName: string;
  source: MessageType;
  data: any;
}

const RASA_PROVIDER_NAME = 'Rasa';
const RASA_WELCOME_MESSAGE = 'Hello, I\'m {name}. I will try to assist you.';
const RASA_SWITCH_TO_AGENT_MESSAGE = 'ACTION_SWITCH_TO_AGENT';
const SWITCH_TO_AGENT_MESSAGE = 'Now you will be switched to support engineer.';
const TICKET_CREATED_MESSAGE = 'Ticket #{ticketId} created.';
const CHAT_HISTORY_TITLE = 'Chat conversation history';

const BEACON_ACCOUNT_ID = 1;
const BEACON_SITE_ID = 3;

export default Vue.extend({
  name: 'support-chat-client',
  mixins: [CreateTicketMixin],
  props: {
    agent: {
      type: Object as PropType<Agent>,
    },
    initialRequest: {
      type: String as PropType<string>,
    },
    initialIntent: {
      type: String as PropType<string>,
    },
    ticketData: {
      type: Object as PropType<TicketData>,
    },
    chatBeaconQueueId: {
      type: Number as PropType<number>,
    },
  },
  data () {
    return {
      messageInput: '',
      messages: [] as Message[],
      provider: null,
      isTyping: false,
      chatStatus: 0,
      ChatStatuses: ChatStatus,
      userName: `${this.$appData.session.firstName} ${this.$appData.session.lastName}`,
    };
  },
  mounted () {
    this.$on('change-status', (status: ChatStatus) => { this.chatStatus = status; });
    this.$on('typing', (isTyping: boolean) => { this.isTyping = isTyping; });
    this.$on('message', this.appendMessage);
    this.connectToRasa();
  },
  beforeDestroy () {
    this.provider.disconnect();
  },
  methods: {
    connectToRasa () {
      this.$on('ready', () => {
        this.sendPayload(`/inform{"agent_id":"${this.agent.id}", "agent_name":"${this.agent.name}", \
          "email":"${this.$appData.session.email}", "request":"${this.initialRequest}"}`, true);
        this.appendMessage('text', MessageType.AgentInput, {
          text: RASA_WELCOME_MESSAGE.replace('{name}', this.agent.name),
          name: this.provider.name,
        });
        if (this.initialIntent) {
          this.appendMessage('text', MessageType.UserInput, {
            text: this.initialRequest,
          });
          this.sendPayload(`/${this.initialIntent}`, true);
        } else {
          this.sendPayload(this.initialRequest);
        }
        this.isTyping = true;
        this.scrollToBottomAndFocus();
      });
      this.provider = new ChatClientRasa(this, this.agent);
      this.$emit('load');
    },
    appendMessage (type: string, source: MessageType, data: any) {
      const message: Message = {
        type,
        provider: this.provider.name,
        sourceName: source === MessageType.AgentInput ? data.name : this.userName,
        source,
        data,
      };

      if (this.provider.name === RASA_PROVIDER_NAME && data.text === RASA_SWITCH_TO_AGENT_MESSAGE) {
        this.switchToAgent();
        return;
      }

      this.messages.push(message);
      this.scrollToBottomAndFocus();
    },

    formatMessage (message: string): string {
      return message.trim()
        .replaceAll(/\r?\n/g, '<br>')
        .replaceAll(
          /(https?:\/\/)(\S+)/g,
          '<a href="https://$2" target="_blank">$2</a>'
        );
    },

    async switchToAgent () {
      this.chatStatus = ChatStatus.CreatingTicket;
      const ticketId = await this.createTicket(this.getChatHistory('Rasa'));

      this.sendPayload(`/inform{"ticket_id":"${ticketId}"}`, true);

      this.provider.disconnect();

      this.$on('ready', () => {
        this.getChunkedChatHistoryForBeacon().forEach(message => {
          this.sendPayload(message, true);
        });
      });

      this.$on('disconnected', () => {
        const chatHistory = this.getChatHistory('Beacon');
        if (chatHistory) {
          this.createTicket(chatHistory);
        }
      });

      this.appendMessage('text', MessageType.AgentInput, {
        text: TICKET_CREATED_MESSAGE.replace('{ticketId}', ticketId),
        name: this.provider.name,
      });
      this.appendMessage('text', MessageType.AgentInput, {
        text: SWITCH_TO_AGENT_MESSAGE,
        name: this.provider.name,
      });

      this.chatStatus = ChatStatus.Connecting;
      this.provider = new ChatClientBeacon(
        this,
        {
          accountId: BEACON_ACCOUNT_ID,
          siteId: BEACON_SITE_ID,
          queueId: this.chatBeaconQueueId,
        },
        {
          name: `${this.$appData.session.firstName} ${this.$appData.session.lastName}`,
          email: this.$appData.session.email,
          company: this.$appData.session.businessDomainName,
        },
        ticketId
      );
    },

    cancelChatRequest () {
      this.provider.disconnect();
    },

    sendPayload (payload: string, hidden = false) {
      this.provider.send(payload, hidden);
    },

    sendUserInput () {
      if (this.isAbleToSend) {
        this.provider.send(this.messageInput);
        this.messageInput = '';
      }
    },

    sendQuickReply (payload: string, title: string, message: any) {
      this.sendPayload(payload, true);
      message.data.sent = true;
      this.appendMessage('text', MessageType.UserInput, { text: title });
      this.$emit('typing', true);
    },

    scrollToBottomAndFocus () {
      this.$nextTick(() => {
        const msgContainer = this.$refs.messages as HTMLElement;
        if (msgContainer) {
          msgContainer.scrollTop = msgContainer.scrollHeight;
        }
        const chatInput = this.$refs.chatInput as HTMLElement;
        if (chatInput) {
          chatInput.focus();
        }
      });
    },

    getMessageClass (source: MessageType): string {
      return { 0: 'agent', 2: 'user' }[source];
    },

    async createTicket (description: string) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return await this.createOrUpdateTicket({
        ...this.ticketData,
        description: description,
      });
    },

    getChatHistory (filterByProvider = ''): string {
      const messages = this.messages
        .filter(message => filterByProvider ? message.provider === filterByProvider : true)
        .map((itm) => {
          return `${itm.sourceName}: ${itm.data.text}`;
        });
      return messages.length ? `${CHAT_HISTORY_TITLE}:\n\n${messages.join('\n\n')}` : '';
    },

    getChunkedChatHistoryForBeacon (chunkSize: number = 950): string[] {
      const message = this.getChatHistory('Rasa');
      if (message === '') {
        return [];
      }
      if (message.length <= chunkSize) {
        return [message];
      }
      const result: string[] = [];
      let chunkStart = 0;
      let chunkEnd = chunkSize;
      for (let i = 0; i < Math.ceil(message.length / chunkSize); i++) {
        const tmpChunk = message.substring(chunkStart, chunkStart + chunkSize);
        chunkEnd = tmpChunk.lastIndexOf('\n\n');
        const chunk = message.substring(
          chunkStart,
          chunkStart + (chunkEnd !== -1 ? chunkEnd : chunkSize)
        ).trim();
        if (!result.length) {
          result.push(chunk);
        } else {
          result.push(`${CHAT_HISTORY_TITLE} (continuation):\n\n${chunk}`);
        }
        chunkStart = chunkStart + chunkEnd;
      }
      return result;
    },
  },
  computed: {
    isAbleToSend (): boolean {
      return this.chatStatus === ChatStatus.Active && this.messageInput.trim() !== '';
    },
    isWaitingForQuickInput (): boolean {
      const msg = this.messages[this.messages.length - 1];
      return msg && msg.source === MessageType.AgentInput && ('quick_replies' in msg.data) && !msg.data.sent;
    },
  },
  watch: {
    chatStatus (status: number) {
      if (status === ChatStatus.Error) {
        this.$emit('bot-error');
      }
      this.scrollToBottomAndFocus();
    },
    isTyping (isTyping: boolean) {
      this.scrollToBottomAndFocus();
    },
  },
});

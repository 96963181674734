
import Vue from 'vue';

import { capitalize } from '@core/common/utils';
import { getMilitaryTimezone } from '@core/common/datetime';
import { getContactSource } from '../constants';
import CallbackModal from './callbackModal.vue';
import { SKYPE_SUPPORT_LINK } from '@core/constants/links';
import {
  prodKeyToName,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_RAS
} from '@core/constants/subscriptions';
import CreateTicketMixin from '@/modules/support/content/createTicketMixin';
import {
  TICKET_FIELD_CONTACT_SOURCE,
  TICKET_FIELD_SUPPORT_TYPE,
  TICKET_FIELD_TIMEZONE
} from '@/api/rt/constants';

const PREMIER_PHONE = 'Premier Phone';

const PHONE_URLS = {
  def: 'https://panel.mightycall.com/c2c/widget_parallels.htm?gateway=108.168.152.167:1935&number=sip:8d0b952f-5aea-4da1-aeae-16e652c5ab04@widget;address=rtmfp&mask=%2b18888112489%3bParallels+US&title=Parallels&key=Sl91DI7prOvoiIR26Mwb&lang=en',
  ru: 'https://panel.mightycall.com/c2c/widget.htm?gateway=77.221.150.86:1935&number=sip:20ac27e2-fc0b-4883-8ec2-5b73aec2a11a@widget;address=rtmfp&mask=+78005557275;ParallelsRU&title=Parallels&key=Sl91DI7prOvoiIR26Mwb&lang=ru',
  ja: 'https://panel.mightycall.com/c2c/widget.htm?gateway=sip.us.mightycall.com:1935&number=sip:9cc15014-987a-41dc-834a-4ffbeefe9f70@widget;address=rtmfp&mask=%2b81345208174%3bParallels&title=Parallels&key=Sl91DI7prOvoiIR26Mwb&lang=en',
  zh: 'https://panel.mightycall.com/c2c/widget.htm?gateway=sip.us.mightycall.com:1935&number=sip:e53466ae-c930-43aa-975f-313cfe2c2b41@widget;address=rtmfp&mask=%2b864008429144%3bParallels&title=Parallels&key=Sl91DI7prOvoiIR26Mwb&lang=en',
};

const SEVERITIES = {
  0: 'Severity One, Urgent',
  1: 'Severity Two, High',
  2: 'Severity Three, Normal',
  3: 'Severity Four, Enhancement',
};

export interface SupportGetSupportModalParams {
  ticketId: number;
  supportOption: string;
  severity: number;
  subscriptionUuid: string;
  summary: string;
}

export default Vue.extend({
  name: 'support-get-support-modal',

  props: ['supportInfo', 'supportType', 'product'],

  components: { CallbackModal },

  mixins: [CreateTicketMixin],

  data () {
    return {
      loading: false,
      supportOption: null as string, // skype, phone
      ticketId: null as number,
      summary: null as string,
      contactType: null as string, // rt or phone
      russianPresalesPhones: [{
        number: '+7 (495) 232-52-16',
        name: 'for Moscow and International',
      },
      {
        number: '8 800 301-45-61',
        name: 'for toll-free calls within Russia',
      },
      ],
      severity: null as number,
      subscriptionUuid: null as string,
    };
  },

  methods: {
    flush () {
      this.supportOption = null;
      this.ticketId = null;
      this.severity = null;
      this.subscriptionUuid = null;
      this.summary = null;
    },

    show ({ supportOption, ticketId, severity, subscriptionUuid, summary }: SupportGetSupportModalParams) {
      this.supportOption = supportOption;
      this.ticketId = ticketId;
      this.severity = severity;
      this.subscriptionUuid = subscriptionUuid;
      this.summary = summary;

      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();

      if (this.needTicket) {
        this.createTicket();
      }
    },

    createTicket () {
      const personalMode = this.$appData.session.isPersonalUser(this.$appData.userInDomain);
      const companyName = this.$appData.session.businessDomainName;

      this.loading = true;
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.createOrUpdateTicket({
        product: this.product_id,
        [TICKET_FIELD_SUPPORT_TYPE]: this.supportType,
        case_origin: this.supportOption,
        country: this.$appData.session.country,
        [TICKET_FIELD_TIMEZONE]: getMilitaryTimezone(),
        [TICKET_FIELD_CONTACT_SOURCE]: getContactSource(),
        summary: personalMode ? this.summary : `[${companyName}] ${this.summary}`,
        description: `ticket for ${this.supportOption} support`,
        severity: this.severity,
        subscription_uuid: this.subscriptionUuid,
      }).then((ticketId) => {
        this.$collectGaStats({
          category: 'Support',
          name: `${this.supportOptionName} ticket submission`,
          source: this.productName,
        });
        this.ticketId = ticketId;
        this.$emit('ticketCreated', { ticketId: this.ticketId, supportType: this.supportType });
      }).finally(() => {
        this.loading = false;
      });
    },

    doneButtonHandler () {
      this.$collectGaStats({
        category: 'Support',
        name: `${this.supportOptionName} cancel`,
        source: this.productName,
      });
      this.flush();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },

    backButtonHandler () {
      this.$emit('backToSeveritySelector', this.severity);
      this.flush();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },

    onPhoneClick () {
      window.open(
        PHONE_URLS[this.$i18n.locale.substring(0, 2)] || PHONE_URLS.def,
        'mywindow',
        'width=450,height=585,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,copyhistor y=no,resizable=yes'
      );
    },

    onSkypeClick () {
      window.open(SKYPE_SUPPORT_LINK);
    },

    onCallbackClick () {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.сallbackModal.show();
    },

    onCallBooked () {
      this.$emit('close', { ticketId: this.ticketId, supportType: this.supportType });
      this.flush();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },
  },

  computed: {
    userName (): string {
      return this.$appData.session.name;
    },

    email (): string {
      return this.$appData.session.email;
    },

    product_id (): string {
      return this.supportInfo && this.supportType && this.supportInfo.product_id;
    },

    needTicket (): boolean {
      if (this.supportInfo && this.supportType && !this.supportInfo.types[this.supportType]) {
        if (this.supportType === 'presales' && this.$appData.session.language === 'ru') {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    phones (): { number: string; name: string }[] {
      if (!this.supportInfo || !this.supportType || !this.supportOption) {
        return [];
      }

      const specialPhones = this.supportInfo.types[this.supportType] && this.supportInfo.types[this.supportType].phones;
      const defaultPhones = this.supportInfo.options[this.supportOption].phones;

      if (this.supportType === 'presales' && this.$appData.session.language === 'ru') {
        return this.russianPresalesPhones;
      }
      const phones = specialPhones || defaultPhones;
      return phones?.filter((phone) => {
        return phone.name !== PREMIER_PHONE;
      }) || [];
    },

    ticketSeverity (): string {
      return SEVERITIES[this.severity];
    },

    isBusinessProduct (): boolean {
      return this.product === PRODUCT_KEY_NAME_PDB || this.product === PRODUCT_KEY_NAME_RAS;
    },

    supportOptionName (): string {
      return capitalize(this.supportOption);
    },

    productName (): string {
      return prodKeyToName(this.product);
    },
  },
});


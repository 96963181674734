/**
 * File: renewOptionsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { getServiceUrl } from '@core/api/routing';

export default class RenewOptionsRequest extends ApiRequest {
  buildQueryString (qp) {
    return super.buildQueryString(qp);
  }

  /**
   *
   * @param {Object} params
   * @param {String} params.uuid
   * @param {?Boolean} params.autoRenewable
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get queryParams () {
    const params = this.params;
    const qp = {};

    if (params.hasOwnProperty('autoRenewable')) {
      qp.auto_renewable = params.autoRenewable;
    }

    return qp;
  }

  get url () {
    const url = getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/renew_options`);
    const qs = this.buildQueryString(this.queryParams);

    return qs ? url + qs : url;
  }
}

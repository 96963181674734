import { render, staticRenderFns } from "./stepInitial.vue?vue&type=template&id=c225bfdc&lang=pug"
import script from "./stepInitial.vue?vue&type=script&lang=ts"
export * from "./stepInitial.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./stepInitial.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports
/**
 * File: utils.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import { PRODUCT_CONTEXT_TO_DOWNLOAD_MAP } from '@/routes/constants';
import { PRODUCT_KEY_NAME_PTB } from '@core/constants/subscriptions';

export const getUtilityKeyNameByProductName = (productKeyName: string): string => {
  if (productKeyName.startsWith('ptb-')) {
    return productKeyName.split('-')[1];
  }
  return null;
};

export const getToolboxDownloadsRoute = (productKeyName?: string) => {
  const utilityKeyName = productKeyName && getUtilityKeyNameByProductName(productKeyName);
  return PRODUCT_CONTEXT_TO_DOWNLOAD_MAP[PRODUCT_KEY_NAME_PTB](utilityKeyName);
};

<copyright>
File: usage.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
div
  .row
    .col-xs-12.col-md-6.margin-bottom-2x
      dropdown.block(
        ref="dropDownPeriods",
        :label="$t('Display Period')",
        :options="periodOptions",
        v-model="periodId",
        @change="$emit('periodChange', $event)",
        :data-name="$name('dropdown-period')"
      )

  loader(:loading="usageLoading")
    datatable.max-height-30x.table.margin-bottom-2x(
      :data="usageHistory",
      :columns="columns",
      name="usageHistoryTable",
      :options="tableOptions"
    )
      //-
      //- Table headers
      //-
      template(slot="date_header" slot-scope="s") {{ $t('Last Report') }}
      template(slot="mac_usage_header" slot-scope="s") {{ $t('Registered Computers') }}
      template(slot="mdm_usage_header" slot-scope="s") {{ $t('Registered Mobile Devices') }}
      //-
      //- Table body
      //-
      template(slot="date" slot-scope="s") {{ s.row.date | date }}
      template(slot="mac_usage" slot-scope="s") {{ s.row.mac_usage }}
      template(slot="mdm_usage" slot-scope="s") {{ s.row.mdm_usage }}
</template>

<script>
import { toDateObject } from '@core/common/format/date';
import PmmServerUsageHistoryRequest from '@/api/pmmServerUsageHistoryRequest';
const LAST_MONTH = 'month';
const LAST_3MONTH = '3month';
const LAST_YEAR = 'year';

export default {
  name: 'pmm-details-modal',

  props: {
    server: Object,
  },

  data () {
    return {
      usageLoading: false,
      periodId: '',
      usageHistory: [],
      lastYearData: [],
      lastMonthData: [],
      last3MonthData: [],
      columns: ['date', 'mac_usage', 'mdm_usage'],
      tableOptions: {
        sortable: ['date', 'mac_usage', 'mdm_usage'],
        customSorting: {
          date (asc) {
            return function (a, b) {
              const dtA = toDateObject(a.date);
              const dtB = toDateObject(b.date);

              if (dtA.getTime() > dtB.getTime()) {
                return asc ? 1 : -1;
              }
              return asc ? -1 : 1;
            };
          },
        },
      },
    };
  },

  mounted () {
    this.loadUsageHistory();
  },

  methods: {
    loadUsageHistory () {
      const request = new PmmServerUsageHistoryRequest({ uuid: this.server.uuid });
      request.emitter.on('started', () => { this.usageLoading = false; });
      request.emitter.on('completed', () => {
        this.splitData(request.data);
        this.periodId = LAST_YEAR;
        this.fillData();
        this.usageLoading = false;
      });
      request.emitter.on('error', () => { this.usageLoading = false; });
      this.$api.authorizedCall(request);
    },
    /*
      Split data for periods.
       */
    splitData (rawData) {
      const today = new Date();
      let rowTime;
      const lastYearFromTime = new Date(new Date().setYear(1900 + today.getYear() - 1)).getTime();
      const lastMonthFromTime = new Date(new Date().setMonth(today.getMonth() - 1)).getTime();
      const last3MonthFromTime = new Date(new Date().setMonth(today.getMonth() - 3)).getTime();
      const lastYearData = []; const lastMonthData = []; const last3MonthData = [];
      rawData.forEach((r) => {
        if (!r.hasOwnProperty('date')) {
          r.date = toDateObject(r.report_date);
        }
        rowTime = r.date.getTime();
        if (rowTime > lastMonthFromTime) {
          lastMonthData.push(r);
        }
        if (rowTime > last3MonthFromTime) {
          last3MonthData.push(r);
        }
        if (rowTime > lastYearFromTime) {
          lastYearData.push(r);
        }
      });
      this.lastMonthData = lastMonthData;
      this.last3MonthData = last3MonthData;
      this.lastYearData = lastYearData;
    },
    fillData () {
      if (this.periodId === LAST_YEAR) {
        this.usageHistory = this.lastYearData;
      } else if (this.periodId === LAST_3MONTH) {
        this.usageHistory = this.last3MonthData;
      } else if (this.periodId === LAST_MONTH) {
        this.usageHistory = this.lastMonthData;
      } else {
        this.usageHistory = [];
      }
    },
  },

  computed: {

    periodOptions () {
      return [
        {
          text: this.$t('Last Month ({amount})').replace('{amount}', this.lastMonthData.length),
          value: LAST_MONTH,
        },
        {
          text: this.$t('Last 3 Month ({amount})').replace('{amount}', this.last3MonthData.length),
          value: LAST_3MONTH,
        },
        {
          text: this.$t('Last Year ({amount})').replace('{amount}', this.lastYearData.length),
          value: LAST_YEAR,
        },
      ];
    },
  },

  watch: {
    periodId () {
      this.fillData();
    },
    server () {
      this.loadUsageHistory();
    },
  },
};
</script>

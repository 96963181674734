
import Vue from 'vue';

import StatusMixin from '../statusMixin';

export default Vue.extend({
  mixins: [StatusMixin],
  name: 'subscription-status-expire-soon',
  data () {
    return {
      isSubset: false,
    };
  },
  methods: {
    showModal (params: { isSubset: boolean }): void {
      this.isSubset = params.isSubset;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.baseView.showModal();
    },
  },
});

/**
 * File: sessionRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import Session from '@/models/session';
import { ACCOUNT_SERVICE } from '@core/constants/services';
import { Dictionary } from '@core/common/types';

interface PersonalDomain {
  'domain_id': number;
}

interface BusinessDomain {
  'company_uuid': number;
  'company_name': string;
  'is_admin': boolean;
  'support_info'?: string;
  groups?: Array<string>;
  'member_since'?: Date;
  'mfa_status'?: boolean;
}

interface SessionResponse {
  'first_name': string;
  'last_name': string;
  email: string;
  'personal_domain': PersonalDomain;
  'business_domain': BusinessDomain;
  'other_business_domains': Array<BusinessDomain>;
  roles: Array<string>;
  'rt_url': string;
  'kb_url': string;
  'chatbot_url': string;
  'daas_admin_portal_url': string;
  'daas_user_portal_url': string;
  'pbi_admin_portal_url': string;
  'pda_portal_url': string;
  trusted: boolean;
}

export default class SessionRequest extends ApiRequest<Dictionary, SessionResponse> {
  get url () {
    return `/${ACCOUNT_SERVICE}/webapp/session`;
  }

  getSession () {
    return (this.data === null) ? null : new Session(this.data);
  }
}

/**
 * File: pvadRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  PVAD_SUBPAGE_SUBSCRIPTIONS,
  PVAD_SUBPAGE_SUBSCRIPTION_DETAILS,
  PVAD_BETA_PAGE
} from './constants';
import PvadPage from '../modules/pvad/pvadPage.vue';
import PvadBetaPage from '@/modules/pvad/beta.vue';
import { PRODUCT_KEY_NAME_PVAD } from '@core/constants/subscriptions';

export default [
  {
    path: '/cloud/beta',
    name: PVAD_BETA_PAGE,
    component: PvadBetaPage,
    meta: {
      requiresAuth: true,
      title: 'Technical Preview - Parallels Virtual Applications and Desktop (Experimental)',
    },
  },
  {
    path: '/pvad/subscriptions',
    name: PVAD_SUBPAGE_SUBSCRIPTIONS,
    component: PvadPage,
    props: (route) => ({
      content: PVAD_SUBPAGE_SUBSCRIPTIONS,
      productKeyName: PRODUCT_KEY_NAME_PVAD,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Cloud (Private Preview)',
    },
  },
  {
    path: '/pvad/subscriptions/:id',
    name: PVAD_SUBPAGE_SUBSCRIPTION_DETAILS,
    component: PvadPage,
    props: (route) => ({
      content: PVAD_SUBPAGE_SUBSCRIPTION_DETAILS,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Cloud (Private Preview)',
    },
  },
];


import Vue from 'vue';
import ComponentMixin from '@/modules/componentMixIn';

export default Vue.extend({
  name: 'license-ready-await-modal',
  mixins: [ComponentMixin],
  methods: {
    hide (): void {
      this.$modal.hide();
      this.$emit('cancel');
    },
  },
});



import Vue from 'vue';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import CsvSaver from '@/ui/csvSaver.vue';
import TableView from '@/ui/tableView.vue';
import GetPackagedLicensesRequest from '@/api/getPackagedLicensesRequest';
import CompanyUsersRequest from '@/api/companyUsersRequest';
import { PackagedLicensesPack } from '@/models/oemLicenses';
import GeneratePackagedLicensesModal
  from '@/modules/home/dashboard/productCards/productCard/productCardPDFC/generatePackagedLicensesModal.vue';
import CompanyUser from '@/models/companyUser';

import OemLicensesMixin from './oemLicensesMixin';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

const PACKS_REQUEST = 'packsRequest';
const USERS_REQUEST = 'usersRequest';

export default Vue.extend({
  name: 'packaged-licenses',
  mixins: [OemLicensesMixin, BreadcrumbsMixin],
  components: {
    CsvSaver,
    GeneratePackagedLicensesModal,
    TableView,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },
  mounted () {
    const trackerId = this.$route.query.trackerId;
    if (trackerId) {
      this.$modal.show(GeneratePackagedLicensesModal, trackerId);
    }
  },
  data () {
    return {
      packs: [] as PackagedLicensesPack[],
      users: [] as CompanyUser[],
      searchFields: [
        'orderId',
        'displayName',
        'seller',
        'createdBy',
        'rationale',
      ],
    };
  },
  computed: {
    columns (): { options: TableViewColumn[] } {
      return {
        options: [
          { text: this.$t('Order ID'), value: 'orderId' },
          { text: this.$t('Display Name'), value: 'displayName' },
          { text: this.$t('Seller'), value: 'seller', hideOnInit: true },
          { text: this.$t('Date of Creation'), value: 'createdAt' },
          { text: this.$t('Created By'), value: 'createdBy', hideOnInit: true },
          { text: this.$t('Created Using'), value: 'source', hideOnInit: true },
          { text: this.$t('Qty'), value: 'quantity' },
          { text: this.$t('Type of Licenses'), value: 'type', hideOnInit: true },
          { text: this.$t('Rationale'), value: 'rationale' },
          { text: this.$t('Enrolled'), value: 'enrolled', hideOnInit: true },
        ],
      };
    },
    actions (): TableViewActions {
      return {
        id: 'orderId',
        options: [{ value: 'exportCsv', text: this.$t('Save List to CSV File ({count})') }],
      };
    },
  },
  methods: {
    load () {
      const request = new CombinedApiRequest();

      this.loading = true;

      request.addRequest(PACKS_REQUEST, new GetPackagedLicensesRequest());
      request.addRequest(USERS_REQUEST, new CompanyUsersRequest({}, this.$appData.session.businessDomainId));

      this.$api.authorizedCall(request).then(() => {
        this.users = (request.getRequest(USERS_REQUEST) as unknown as CompanyUsersRequest).getUsers();
        this.packs = (request.getRequest(PACKS_REQUEST) as unknown as GetPackagedLicensesRequest).data.packagedLicensesPacks.map((pack) => {
          const user = this.users.find((user) => user.uuid === pack.requestedUserId);
          pack.createdBy = (user !== undefined ? user.email : '?');
          return pack;
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    exportCsv (packs: PackagedLicensesPack[]) {
      // @ts-ignore  FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.csv.save({
        columns: this.columns.options,
        rows: packs,
      });
    },
    openPackagedLicensesGenerator () {
      this.$modal.show(GeneratePackagedLicensesModal);
    },
  },
});




import Vue from 'vue';
import MyTickets from './content/myTickets/myTickets.vue';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import Subscription from '@/models/subscription';
import { GET_SUPPORT_PAGE } from '@/routes/constants';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'support-my-tickets-page',

  props: {
    /* If true, all navigations to getSupport page will open the page without params (i.e base support page)
     *
     * For example, navigation to /support/pdfc/presales/request will open /support
     */
    denyGetSupportWithParams: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [BreadcrumbsMixin],
  components: { MyTickets, Breadcrumbs }, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846

  data () {
    return {
      loading: false,
      subscriptions: [] as Subscription[],
      GET_SUPPORT_PAGE,
    };
  },

  created () {
    this.loadSubscriptions();
  },

  beforeRouteLeave (to, from, next) {
    if (this.denyGetSupportWithParams && to.name === GET_SUPPORT_PAGE && Object.keys(to.params).length) {
      // clear path params
      next({ name: GET_SUPPORT_PAGE });
    } else {
      next();
    }
  },

  computed: {
    subpages (): MenuItem[] {
      return [{
        text: this.$t('New Ticket'),
        link: { name: GET_SUPPORT_PAGE },
      }];
    },

    isRegularMember (): boolean {
      return this.$appData.session.isCurrentRegularMember(this.$appData.userInDomain);
    },

    hasSubscriptions (): boolean {
      return this.subscriptions.length > 0;
    },

    isSupportable (): boolean {
      return !(this.isRegularMember && !this.hasSubscriptions);
    },

    isBusinessMode (): boolean {
      return this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain);
    },
  },

  methods: {
    async loadSubscriptions () {
      if (this.isBusinessMode) {
        this.loading = true;
        const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS });
        try {
          await this.$api.authorizedCall(request);
          this.subscriptions = request.getSubscriptions();
        } finally {
          this.loading = false;
        }
      }
    },
  },
});



import Vue from 'vue';

import { password } from '@core/common/validators';

import RestorePasswordMixin from '@/modules/personalProfile/restorePasswordMixin';

export default Vue.extend({
  name: 'update-two-step-verification-settings-with-password-modal',

  mixins: [RestorePasswordMixin],

  props: {
    switchMfaMode: Boolean,
    disablingMfaLoading: Boolean,
    invalidPassword: Boolean,
    appliedPassword: String,
  },

  validations: {
    form: {
      password: {
        ...password,
        ...{
          invalidPassword: function () {
            // @ts-ignore
            return !(this.invalidPassword && this.appliedPassword === this.form.password);
          },
        },
      },
    },
  },

  data () {
    return {
      form: { password: '' },
      loading: false,
    };
  },

  methods: {
    onShow () {
      this.form.password = '';
      this.$v.$reset();
    },
  },
});


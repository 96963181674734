/**
 * File: ssoCompanyUsersRequest.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import SSOCompanyUser from '@core/models/ssoCompanyUser';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface SsoCompanyUsersRequestParams {
  companyUuid: string;
  users?: string[];
}

export class SSOCompanyUsersRequest extends ApiRequest<SsoCompanyUsersRequestParams> {
  get url (): string {
    let uri = `/${ACCOUNT_SERVICE}/api/v1/ba_sso/${this.params.companyUuid}/users`;
    let queryStringParams = {};
    if (this.params.users) {
      queryStringParams = { ...queryStringParams, users: this.params.users.join(',') };
    }

    // Added limit here as a dirty hack to fix https://parallels.atlassian.net/browse/CPCLOUD-19874 and
    // https://parallels.atlassian.net/browse/CPCLOUD-19880
    queryStringParams = { ...queryStringParams, limit: 1500 };

    if (Object.keys(queryStringParams).length) {
      uri += `${this.buildQueryString(queryStringParams)}`;
    }

    return uri;
  }

  getUsers (): SSOCompanyUser[] {
    return this.data.users.map(rawUser => new SSOCompanyUser(rawUser));
  }
}

interface SSOUserUpdateRequestParams {
  companyUuid: string;
  ssoUserId: string;
  notes: string;
}

export class SSOUserUpdateRequest extends ApiRequest<SSOUserUpdateRequestParams> {
  get method (): Method {
    return 'PUT';
  }

  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/ba_sso/${this.params.companyUuid}/users/${this.params.ssoUserId}`;
  }

  get body (): Json {
    return { notes: this.params.notes };
  }

  load () {
    return super.load()
      .then((data) => {
        new SSOCompanyUsersRequest({ companyUuid: this.params.companyUuid }).dropFullCache();
        return data;
      });
  }
}

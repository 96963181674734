<copyright>
File: adminsList.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import CompanyUsersRequest from '@/api/companyUsersRequest';

export default {
  name: 'support-admins-list',
  data () {
    return {
      loading: true,
      admins: [],
    };
  },
  created () {
    const request = new CompanyUsersRequest({}, this.$appData.session.businessDomainId);

    this.$api.authorizedCall(request).then(() => {
      const users = request.getUsers();
      if (users) {
        this.admins = users.filter((user) => user.isAdmin);
      }
    }).finally(() => {
      this.loading = false;
    });
  },
};

</script>

<template lang="pug">

.row
  .col-sm-8.col-sm-push-2.col-xs-12
    card
      h4.margin-bottom-2x {{ $t('Please contact your local system administrator to get assistance:') }}
      loader(v-if="loading")
      list(v-else)
        div(v-for="admin in admins")
          .row
            .col-xs-6 {{ admin.name }}
            .col-xs-6.text-rtl
              a(:href="admin.mailto") {{ admin.email }}

</template>

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import NotificationSettingsRequest from '@/api/notificationSettingsRequest';
import UpdateNotificationSettingsRequest from '@/api/updateNotificationSettingsRequest';

const settingsMap = {
  product: 'product_notifications_on',
  marketing: 'marketing_notifications_on',
  betaPersonal: 'beta_personal_notifications_on',
  betaBusiness: 'beta_business_notifications_on',
};

const keys = Object.keys(settingsMap);

export default {
  name: 'notification-settings',
  props: {
    actionCode: {
      type: String,
    },
  },
  data () {
    const data = {
      loading: false,
      initial: {},
    };

    keys.forEach((name) => {
      data[name] = data.initial[name] = null;
    });

    return data;
  },
  computed: {
    buttonDisabled () {
      return keys.every((name) => this[name] === this.initial[name]);
    },
  },
  created () {
    this.load();
  },
  methods: {
    load () {
      const request = new NotificationSettingsRequest();
      this.loading = true;

      this.sendRequest(request).then((data) => {
        keys.forEach((name) => {
          this[name] = this.initial[name] = data.notifications_settings[settingsMap[name]];
        });
      }).catch(() => {
        this.$emit('error');
      }).finally(() => {
        this.loading = false;
      });
    },
    save () {
      const params = keys.reduce((data, name) => {
        if (this[name] !== null) {
          data[settingsMap[name]] = this[name];
        }
        return data;
      }, {});
      const request = new UpdateNotificationSettingsRequest(params);

      this.loading = true;

      this.sendRequest(request).then(() => {
        keys.forEach((name) => {
          this.initial[name] = this[name];
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    // TODO: move to request on refactoring
    sendRequest (request) {
      if (this.actionCode) {
        request.setAuthHeader('ActionCode', this.actionCode);
      } else {
        request.setAuthHeader('Token', this.$appData.session.token);
      }
      return request.load();
    },
  },
};

</script>

<template lang="pug">

card
  loader(:loading="loading")
    h4(data-qa="notfn-checklist-title").margin-bottom {{ $t('Send me email notifications on events related to:') }}
    div(v-if="product !== null")
      checkbox(v-model="product", data-qa="product-notification") {{ $t('My products, subscriptions and trials') }}
    div(v-if="marketing !== null")
      checkbox(v-model="marketing", data-qa="new-prod-notification") {{ $t('New Parallels products and special offers') }}
    div(v-if="betaPersonal !== null")
      checkbox(v-model="betaPersonal", data-qa="beta-consumer-prod-notification") {{ $t('Beta versions of Parallels consumer products') }}
    .margin-bottom(v-if="betaBusiness !== null")
      checkbox(v-model="betaBusiness", data-qa="beta-business-prod-notification") {{ $t('Beta versions of Parallels products for business') }}
    .text-muted.text-small.margin-bottom {{ $t('In addition, you will always receive critical account security alerts and important Parallels product notifications, such as account password changes, login attempts from unrecognized web browsers and mobile devices, and some others.') }}
    .text-right
      btn.block-xs(data-qa="apply-button", @click="save", :disabled="buttonDisabled") {{ $t('Apply') }}

</template>

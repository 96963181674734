/**
 * File: ssoGroupMappingRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';
import { camelize } from '@core/common/utils';

export interface GetSsoGroupMappingRequestParams {
  domainId: number;
}

export interface GetSsoGroupMappingRequestResponse {
  domainId: number;
  mapping: {
    prlGroupId: string;
    externalId: string;
    displayName: string;
  }[];
}

export interface UpdateSsoGroupMappingRequestParams {
  domainId: number;
  mapping: {
    prlGroupId: string;
    externalId: string;
    displayName: string;
  }[];
}

export class GetSsoGroupMappingRequest extends ApiRequest<GetSsoGroupMappingRequestParams, GetSsoGroupMappingRequestResponse> {
  get method (): Method {
    return 'GET';
  }

  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/ba_sso/${this.params.domainId}/group_mapping`;
  }

  onFetchSuccess (data: GetSsoGroupMappingRequestResponse) {
    return camelize(data);
  }
}

export class UpdateSsoGroupMappingRequest extends ApiRequest<UpdateSsoGroupMappingRequestParams|any> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/ba_sso/${this.params.domainId}/group_mapping`;
  }

  get body (): Json {
    const mapping = this.params.mapping;
    return {
      mapping: mapping.map((item) => {
        return {
          prl_group_id: parseInt(item.prlGroupId),
          external_id: item.externalId,
          display_name: item.displayName,
        };
      }),
    };
  }
}

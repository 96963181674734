/**
 * File: acceptConsentRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class acceptConsentRequest extends ApiRequest {
  get url () {
    return `/account/api/v1/consent/${this.params.id}`;
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      consent_was_completed: !!this.params.consent_was_completed,
      redirect_url: this.params.redirect_url,
    };
  }
}

/**
 * File: rasPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue, { PropType } from 'vue';

import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import { PRODUCT_KEY_NAME_RAS } from '@core/constants/subscriptions';
import ProductPageMixIn from '@/modules/productPageMixIn';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import { support as supportLinks } from '@core/constants/links';

import ComponentMixIn from '@/modules/componentMixIn';

import RasLsSubscriptionsRequest from '@/api/rasLsSubscriptionsRequest';
import BaseSubscriptionsRequest from '@/api/baseSubscriptionsRequest';
import RasFarmsCountersRequest from '@/api/rasFarmsCountersRequest';
import RasSubscriptionsRequest from '@/api/rasSubscriptionsRequest';
import RasNfrNotification from './rasNfrNotification.vue';
import SplaNotification from './splaNotification.vue';
import FarmsContent from '@/modules/ras/farmsContent.vue';
import DownloadsContent from '@/modules/downloads/downloadsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import OfflineActivation from './offline/offlineActivation.vue';
import OfflineDeactivation from './offline/offlineDeactivation.vue';
import {
  ADD_LICENSES_MODAL,
  GET_SUPPORT_PAGE,
  RAS_SUBPAGE_DOWNLOADS,
  RAS_SUBPAGE_FARMS,
  RAS_SUBPAGE_OFFLINE_ACTIVATION,
  RAS_SUBPAGE_OFFLINE_DEACTIVATION,
  RAS_SUBPAGE_SUBSCRIPTION_DETAILS,
  RAS_SUBPAGE_SUBSCRIPTIONS
} from '@/routes/constants';
import Subscription, { STATUS_ACTIVE } from '@/models/subscription';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import RasSubscriptionsRequestV2 from '@/apiv2/rasSubscriptionsRequestV2';
import { fromRasSubscriptionV2toV1 } from '@/modules/ras/mappers';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

export default Vue.extend({
  /**
   * FIXME add support of automatically opening dialog with farm details by query string, at the current moment it
   * is difficult because farms for the period loads in postpaidFarms component
   */
  name: 'ras-page',

  mixins: [
    ComponentMixIn,
    ProductPageMixIn,
    BreadcrumbsMixin,
  ],

  props: {
    appData: {
      type: Object as PropType<AppData>,
    },
    content: {
      type: String,
    },
  },

  components: {
    SubscriptionNotification,
    RasNfrNotification,
    FarmsContent,
    DownloadsContent,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    AddLicensesModal,
    SplaNotification,
    OfflineActivation,
    OfflineDeactivation,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  data () {
    return {
      RAS_SUBPAGE_FARMS,
      RAS_SUBPAGE_DOWNLOADS,
      RAS_SUBPAGE_SUBSCRIPTIONS,
      RAS_SUBPAGE_SUBSCRIPTION_DETAILS,
      RAS_SUBPAGE_OFFLINE_ACTIVATION,
      RAS_SUBPAGE_OFFLINE_DEACTIVATION,
      loading: true,
      updating: false,
      supportLinks,
      personalMode: this.appData.session.isPersonalUser(this.appData.userInDomain),
      subscriptions: [],
      rasSubscriptions: [],
      isOfflineActivationEnabled: false,
      // filling when subscriptions is loaded
      splaSubscriptionsAmount: 0,
      prepaidSubscriptionsAmount: 0,
      prepaidFarmsAmount: 0,
      postpaidFarmsAmount: 0,
      displayNotification: true,
      productKeyName: PRODUCT_KEY_NAME_RAS,
    };
  },

  created () {
    this.load();
  },

  beforeRouteUpdate (to, from, next) {
    // Force to rerender component on farm type change
    if (to.params.type !== from.params.type) {
      this.updating = true;
      this.$nextTick(() => {
        this.updating = false;
        next();
      });
    } else {
      next();
    }
  },

  methods: {
    load () {
      const combinedRequest = new CombinedApiRequest();

      const farmsRequestName = 'farmsCounters';
      const lsSubscriptionRequestName = 'lsSubscriptions';

      const formRequest = (url) => new RasSubscriptionsRequestV2({ url });
      this.$api.authorizedPaginatedCall(formRequest)
        .then((data) => this.setRasSubscriptions(data));

      combinedRequest.addRequest(farmsRequestName, new RasFarmsCountersRequest());
      combinedRequest.addRequest(lsSubscriptionRequestName, new RasLsSubscriptionsRequest({ type: BaseSubscriptionsRequest.TYPE_BUSINESS }));
      return this.authorizedCall(combinedRequest)
        .then(() => {
          this.setFarmsCounters(combinedRequest.getRequest(farmsRequestName).data);
          this.setSubscriptions((combinedRequest.getRequest(lsSubscriptionRequestName) as RasSubscriptionsRequest).getSubscriptions());
          // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
          this.handleModal();
        });
    },

    setFarmsCounters (data) {
      this.prepaidFarmsAmount = data.prepaid_total;
      this.postpaidFarmsAmount = data.postpaid_total;
    },

    setSubscriptions (data) {
      this.subscriptions = data.filter((s) => s.hasProduct(PRODUCT_KEY_NAME_RAS));
      this.subscriptions.forEach((s: Subscription) => {
        if (s.isPostPaid) {
          this.splaSubscriptionsAmount += 1;
        } else {
          this.prepaidSubscriptionsAmount += 1;
        }
      });
    },

    setRasSubscriptions (data) {
      this.rasSubscriptions = data.map((entity) => fromRasSubscriptionV2toV1(entity));
      this.isOfflineActivationEnabled = this.rasSubscriptions.some(s => s.offlineAllowed);
    },
  },

  computed: {
    isSplaPage (): boolean {
      return this.$route.params.type === 'postpaid';
    },

    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },

    canBuyMoreLicenses (): boolean {
      return this.canEditSubscription && this.subscriptions.some((subscription: Subscription) => subscription.canBuyMoreLicenses);
    },

    canEditSubscription (): boolean {
      return this.personalMode || this.$appData.session.isBusinessAdmin;
    },

    subpages (): MenuItem[] {
      const subpages = [];

      if (this.splaSubscriptionsAmount) {
        subpages.push({
          text: this.$t('Registered farms, SPLA ({amount})', { amount: this.postpaidFarmsAmount }),
          link: { name: 'rasFarms', params: { type: 'postpaid' } },
        });
      }

      if (this.prepaidSubscriptionsAmount) {
        subpages.push({
          text: this.splaSubscriptionsAmount ?
            this.$t('Registered farms, non-SPLA ({amount})', { amount: this.prepaidFarmsAmount }) :
            this.$t('Registered farms ({amount})', { amount: this.prepaidFarmsAmount }),
          link: { name: 'rasFarms', params: { type: 'prepaid' } },
        });
      }

      subpages.push({
        text: this.$t('Download'),
        // @ts-ignore  FIXME: https://parallels.atlassian.net/browse/CPCLOUD-16280
        link: this.getDownloadRoute(PRODUCT_KEY_NAME_RAS),
      }, {
        text: this.$t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
        link: { name: 'rasSubscriptions', query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE } },
      });

      if (this.content !== RAS_SUBPAGE_SUBSCRIPTION_DETAILS && this.canBuyMoreLicenses) {
        subpages.push({
          text: this.$t('Buy More Licenses'),
          link: { query: Object.assign({}, this.$route.query, { modal: ADD_LICENSES_MODAL }), replace: true },
          forceLink: true,
        });
      }

      if (this.isOfflineActivationEnabled) {
        subpages.push({
          text: this.$t('Offline Activation'),
          link: { name: 'rasOfflineActivation' },
        }, {
          text: this.$t('Offline Deactivation'),
          link: { name: 'rasOfflineDeactivation' },
        });
      }

      subpages.push({
        text: this.$t('Support'),
        click: () => {
          if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
            const params = {
              category: 'Support',
              name: 'Support button click in menu',
            };
            this.$collectGaStats({
              ...params,
              source: document.title,
            });
            this.$collectStats({
              ...params,
              source: PRODUCT_KEY_NAME_RAS,
            });
            this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_RAS } });
          } else {
            window.location.href = this.supportLinks[PRODUCT_KEY_NAME_RAS];
          }
        },
      });

      return subpages;
    },
  },
});

/**
 * File: rasHostUsageHistoryRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class rasHostUsageHistoryRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'rasHostUsageHistoryRequest';
  }

  get url () {
    if (this.params.isPostpaid) {
      return `/ras/api/v1/hosts/${this.params.hwId}/billing_periods/${this.params.periodId}/usage`;
    }
    return `/ras/api/v1/hosts/${this.params.hwId}/usage_prepaid`;
  }
}

/**
 * File: index.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import { getUpperDomain } from '@core/common/url';
import Cache from '@core/api/cache';
import { PRODUCT_KEY_NAME_PSW, PRODUCT_KEY_NAME_PDFM } from '@core/constants/subscriptions';
import { MFA_METHOD } from '@core/constants/mfa';

import router from '@/router';
import {
  CANT_USE_RESCUE_CODES_PAGE,
  CONFIRM_BROWSER_PAGE,
  FORCE_AUTH_APP_MFA_PAGE,
  GO_PAGE,
  LOGIN_PAGE,
  MFA_ONE_TIME_PASSWORD_PAGE,
  PERSONAL_PROFILE_SCOPE,
  REGISTER_PAGE,
  REGISTER_SOCIAL_PAGE,
  SECURITY_PAGE,
  START_PAGE,
  USER_CONSENT_PAGE
} from '@/routes/constants';
import { RT_SERVICE } from '@core/constants/services';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import InitialConfigRequest from './initialConfigRequest';
import SessionRequest from './sessionRequest';
import ProductsRequest from './productsRequest';
import RasProductsRequest from './rasProductsRequest';
import PdLicensesRequest from './pdLicensesRequest';
import SwitchBusinessDomainRequest from './switchBusinessDomainRequest';
import LoginRequest, { LoginRequestParams } from './loginRequest';
import LogoutRequest from './logoutRequest';
import GetConsentUrlRequest, { GetConsentUrlRequestParams } from './getConsentUrlRequest';
import GenAuthTokenRequest from './genAuthTokenRequest';
import RtSessionRequest from './rt/rtSessionRequest';
import RtRemoveTockenRequest from './rt/rtRemoveToken';
import consent from '@/data/consent';
import { appData } from '@/data/appData'; // FIXME use Vuex!
import supportRoutes from '@/routes/supportRoutes';
import { askConfirmAccount } from '@/routes/actionRoutes';
import ApiRequest from '@core/api/apiRequest';
import InitialConfig from '@/models/initialConfig';
import { HTTP_CODES } from '@core/constants/http';
import { GetCountriesRequest } from '@/api/getSetsRequest';
import ListBusinessDomainsRequest, { BusinessDomain } from '@/api/listBusinessDomainsRequest';
import GetDomainsWithProductRequest, {
  GetDomainsWithProductRequestResponse
} from '@/api/getDomainsWithProductRequest';
import Session from '@/models/session';
import { snakeToCamel } from '@/apiv2/apiHelpers';

// @ts-ignore
const devMode = typeof webpackHotUpdate !== 'undefined';
const supportRouteNames = supportRoutes.map((route) => route.name);
const isSupportApp = process.env.VUE_APP_NAME === 'support';

const PSW_LOCAL_STORAGE_KEY = 'pswCheck';

type PaginatedResponse = { results: unknown[]; next: string; prev: string };

/**
 * Api plugin.
 * Currently we are using ApiRequest objects as wrapper over Request and fetch.
 * FIXME use Vue.http like this https://github.com/prograhammer/example-vue-project
 */
export default {

  /**
   * Install the Auth class.
   *
   * @param {Object} Vue The global Vue.
   * @param {Object} options Any options we want to have in our plugin.
   * @return {void}
   */
  install (Vue, options) {
    // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
    this.options = options;
    router.beforeEach((to, from, next) => {
      // Refresh page to break html5 history mode
      if (!devMode && isSupportApp !== (supportRouteNames.indexOf(to.name) > -1)) {
        location.href = to.fullPath;
        return;
      }
      if (to.meta.requiresAuth) {
        if (!appData.session) {
          this.refreshToken().then((data) => {
            this.validateSessionBeforeRoute(to, from, next);
          });
          next(false); // stop route, stay on current page until session refresh
        } else {
          this.validateSessionBeforeRoute(to, from, next);
        }
      } else {
        next();
      }
    });

    router.beforeEach((to, from, next) => {
      if (to.meta.requiresAuth && appData.session && !appData.products) {
        this.initProducts().then(() => {
          next();
        });
        next(false);
      } else {
        next();
      }
    });

    Vue.prototype.$api = Vue.api = this;
  },

  validateSessionBeforeRoute (to, from, next) {
    const { session } = appData;
    let query;

    // accept service/continue params only from these pages and '/'
    const allowedPages = [
      LOGIN_PAGE,
      CONFIRM_BROWSER_PAGE,
      REGISTER_PAGE,
      USER_CONSENT_PAGE,
      REGISTER_SOCIAL_PAGE,
      MFA_ONE_TIME_PASSWORD_PAGE,
      askConfirmAccount.name,
    ];
    if (~allowedPages.indexOf(from.name) || from.fullPath === '/') {
      if (from.query.continue) {
        // 1st case to get redirect params:
        // if previous page had redirect params, pass them to the next page
        query = {
          service: from.query.service,
          continue: from.query.continue,
        };
      } else if (to.path !== '/') {
        // 2nd case to get redirect params:
        // if user navigates to the custom page
        // remember target url, because gdpr or trust browser pages could be opened
        query = { continue: to.fullPath };
      } else if (to.path === '/' && to.query.continue) {
        // 3rd case to get redirect params:
        // if previous page does not have redirect params,
        // but user navigates on / with redirect params (from external link as example)
        query = {
          service: to.query.service,
          continue: to.query.continue,
        };
      }
    }

    if (to.query.ma_token) {
      this.redirectToAutoLogin(query.continue || '/');
      return;
    }

    if (session.isGuest && to.name !== LOGIN_PAGE) {
      next({
        name: LOGIN_PAGE,
        query,
        replace: true,
      });
    } else if (!session.trusted) {
      if (to.name === CANT_USE_RESCUE_CODES_PAGE) {
        next();
      } else if (session.mfaMethod === MFA_METHOD.AUTH_APPLICATION) {
        if (to.name !== MFA_ONE_TIME_PASSWORD_PAGE) {
          next({
            name: MFA_ONE_TIME_PASSWORD_PAGE,
            query,
            replace: true,
          });
        } else {
          next();
        }
      } else {
        if (to.name !== CONFIRM_BROWSER_PAGE) {
          next({
            name: CONFIRM_BROWSER_PAGE,
            query,
            replace: true,
          });
        } else {
          next();
        }
      }
    } else if (!appData.session.isSessionConfirmed()) {
      if (to.name !== askConfirmAccount.name) {
        next({
          name: askConfirmAccount.name,
          query,
          replace: true,
        });
      } else {
        next();
      }
    } else if (consent.required) {
      this.getConsent({
        locale: session.locale,
      }).then(() => {
        if (consent.required) {
          next({
            path: consent.url,
            query,
            replace: true,
          });
        } else {
          this.validateSessionBeforeRoute(to, from, next);
        }
      });
    } else if (session.businessDomain?.mfaStatus && (!session.mfaStatus || session.mfaMethod !== MFA_METHOD.AUTH_APPLICATION)) {
      if (to.name !== FORCE_AUTH_APP_MFA_PAGE && to.name !== PERSONAL_PROFILE_SCOPE && to.params !== { page: SECURITY_PAGE, enableTotp: 'true' }) {
        next({
          name: FORCE_AUTH_APP_MFA_PAGE,
          query,
          replace: true,
        });
      } else {
        next();
      }
    } else {
      if (query && (query.service || query.continue) && from.name !== GO_PAGE) {
        // if redirect to the external service or MA page is required

        if (query.service) {
          // if it should be redirected to the external service
          next({
            name: GO_PAGE,
            query,
          });
        } else {
          // if redirect to the external service is not required.
          // in this case we should check weather user is redirecting to target MA page in this
          // 'validateSessionBeforeRoute' hook.
          // if yes, we should navigate user to the target page using 'next()'; otherwise redirect to the continue url

          // url in 'continue' can be in any format. we should check weather is it equals to query.continue
          if (query.continue) {
            // normalize: to.fullPath always begins with '/'
            if (!query.continue.startsWith('/')) {
              query.continue = `/${query.continue}`;
            }
            // normalize: to.fullPath does not contains '?' without query params
            if (query.continue.endsWith('?')) {
              query.continue = query.continue.slice(0, -1);
            }
          }

          // if it should be redirected to MA page
          if (query.continue !== to.fullPath && router.resolve(query.continue).route.name) {
            next({
              path: query.continue,
              replace: true,
            });
          } else {
            // if redirect is not required
            next();
          }
        }
      } else {
        // if redirect is not required
        next();
      }
    }
  },

  redirectToAutoLogin: function (continueUri) {
    // @ts-ignore
    window.location = 'https://account.' + getUpperDomain() + '/webapp/auto_login?continue=' +
      encodeURIComponent(continueUri) + '&service=self';
  },

  /**
   *
   * @param {ApiRequest|CombinedApiRequest} request
   */
  authorizedCall<Params, Response> (request: ApiRequest<Params, Response>): Promise<Response> {
    const session = appData.session;
    if (session) {
      this.setAuthHeader(request, appData.session);

      return request.load()
        .catch((error) => {
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
          this.options.context.app.$emit('api-error', {
            error,
            request,
          });
          throw error;
        });
    }

    return this.refreshToken().then(() => {
      if (appData.session.isGuest) {
        router.push({ name: LOGIN_PAGE });
      } else {
        return this._retry(request);
      }
    });
  },

  async authorizedAsyncPaginatedCall<Params> (
    formRequest: (url?: string)=> ApiRequest<Params, PaginatedResponse>,
    callbackOnIteration
  ) {
    let nextRequest = formRequest();
    while (nextRequest) {
      const response = await this.authorizedCall<Params, PaginatedResponse>(nextRequest);
      nextRequest = response.next ? formRequest(response.next) : null;
      callbackOnIteration(snakeToCamel(response.results));
    }
  },

  async authorizedPaginatedCall<Params> (formRequest: (url?: string)=> ApiRequest<Params, PaginatedResponse>) {
    const data = [];
    let nextRequest = formRequest();
    while (nextRequest) {
      const response = await this.authorizedCall<Params, PaginatedResponse>(nextRequest);
      data.push(...response.results);
      nextRequest = response.next ? formRequest(response.next) : null;
    }
    return snakeToCamel(data);
  },

  /**
   *
   * @param {ApiRequest} request
   */
  call<Params, Response> (request: ApiRequest<Params, Response>): Promise<Response> {
    return request.load()
      .catch((error) => {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
        this.options.context.app.$emit('api-error', {
          error,
          request,
        });
        throw error;
      });
  },

  callRt<Request, Response> (request: ApiRequest<Request, Response>, isBusiness?: boolean, isRetryMode: boolean = false) {
    if (appData.rtToken && (isBusiness === undefined || isBusiness === appData.rtBusiness)) {
      return this.callRtWithToken(request, appData.rtToken, false);
    }

    // No token? just request it first
    return this.authorizedCall(new GenAuthTokenRequest({ service: RT_SERVICE }))
      .then((data: any) => {
        let business = isBusiness;
        if (business === undefined) {
          business = appData.userInDomain && !appData.session.isPersonalUser(appData.userInDomain);
        }

        const rtSessionRequest = new RtSessionRequest({
          token: data.token,
          business: business,
          language: appData.session.language,
        });

        return this.call(rtSessionRequest).then((data) => {
          appData.rtSearchEngine = data.searchEngine;
          appData.rtToken = data.token; // keeping received token for the next requests
          appData.rtBusiness = business;
          return this.callRtWithToken(request, data.token, isRetryMode); // and call original request
        });
      });
  },

  callRtWithToken (request, token: string, isRetryMode: boolean = false) {
    request.setAuthHeader('Token', token);

    return request.load()
      .catch((error: { response: { status: number } }) => {
        if (!isRetryMode && [HTTP_CODES.UNAUTHORIZED, HTTP_CODES.FORBIDDEN].includes(error.response?.status)) {
          appData.rtToken = null;
          return this.callRt(request, appData.rtBusiness, true);
        }
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
        this.options.context.app.$emit('api-error', {
          error,
          request,
        });
        throw error;
      });
  },

  async login (credentials: LoginRequestParams) {
    await this.call(new LoginRequest(credentials));
    await this.loginSuccessHandler();
  },

  async loginSuccessHandler () {
    await this.refreshToken(true);
    // catch block prevents router to flood in console
    router.push({ name: START_PAGE }).catch(() => {});
  },

  logout: function (params: { query?: Record<string, any> } = {}) {
    if (appData.rtToken) {
      this.callRtWithToken(new RtRemoveTockenRequest(), appData.rtToken);
    }

    return this.authorizedCall(new LogoutRequest())
      .then(() => {
        new Cache().dropCache();
        appData.setSession(null);
        appData.isNewUser = false;
        consent.init();
        router.push({
          name: LOGIN_PAGE,
          query: params.query,
        });
      });
  },

  switchBusinessDomain: function (userInDomain) {
    return this.authorizedCall(new SwitchBusinessDomainRequest({ domainId: userInDomain }))
      .then(() => {
        new Cache().dropCache(); // Drop all cache
        appData.setUserInDomain(userInDomain);
        // FIXME To avoid redundant request maybe is better to update session cache?
        return this.refreshToken(true);
      });
  },

  setAuthHeader: function (req, session) {
    req.setAuthHeader('Token', session.token);
  },

  _retry (req) {
    this.setAuthHeader(req, appData.session);

    return req.load()
      .catch((error) => {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
        this.options.context.app.$emit('api-error', error);
      });
  },

  refreshToken: function (force: boolean = false, skipPswCheck?: boolean) {
    const sessionRequest = new SessionRequest();
    if (force) {
      new Cache().dropCache(); // Drop all cache
    }
    return sessionRequest.load()
      .then((data) => {
        appData.setSession(sessionRequest.getSession());
        return data;
      }).then((data) => {
        if (force) {
          if (!skipPswCheck) {
            this.runPswCheck(sessionRequest.getSession());
          }
          return this.initProducts(force).then(() => {
            return data;
          });
        }
        return data;
      }).catch((error) => {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
        this.options.context.app.$emit('api-error', {
          error,
          request: sessionRequest,
        });
        throw error;
      });
  },

  async loadInitialConfig (): Promise<InitialConfig> {
    const request = new InitialConfigRequest();
    await this.call(request);
    appData.config = request.getInitialConfig();
    return appData.config;
  },

  initProducts (force: boolean = false) {
    if (force) {
      new ProductsRequest().dropCache();
      new RasProductsRequest().dropCache();
      new PdLicensesRequest().dropCache();
    }

    // performing requests in parallel
    const pdLicensesRequest = new PdLicensesRequest();
    const combinedRequest = new CombinedApiRequest({ integrity: false });

    combinedRequest.addRequest('ls', new ProductsRequest());
    combinedRequest.addRequest('rasls', new RasProductsRequest());
    combinedRequest.addRequest('legacy_pd', pdLicensesRequest);

    // @ts-ignore
    return this.authorizedCall(combinedRequest).then((data: any) => {
      let personal = [];
      let business = [];
      const cepOptions = {};

      try {
        const lsProducts = combinedRequest.getRequest('ls').data || {};
        if (lsProducts && Object.keys(lsProducts).length > 0) {
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16430
          personal = personal.concat(lsProducts.personal);
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16430
          business = business.concat(lsProducts.business);
          Object.assign(cepOptions, lsProducts.cep_options);
        }

        const rasLsProducts = combinedRequest.getRequest('rasls').data || {};
        if (rasLsProducts && Object.keys(rasLsProducts).length > 0) {
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16430
          personal = personal.concat(rasLsProducts.personal);
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16430
          business = business.concat(rasLsProducts.business);
          Object.assign(cepOptions, lsProducts.cep_options);
        }

        const pdLegacySubscriptions = combinedRequest.getRequest('legacy_pd').data || [];
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16430
        if (personal.indexOf(PRODUCT_KEY_NAME_PDFM) < 0 && pdLegacySubscriptions.length > 0) {
          personal.push(PRODUCT_KEY_NAME_PDFM);
        }
      } catch (e) {}

      appData.products = { personal, business, cepOptions };
      appData.initActiveDomain();

      return data;
    });
  },

  getConsent (params: GetConsentUrlRequestParams, authorizedCall: boolean = true) {
    const getConsentUrlRequest = new GetConsentUrlRequest(params);
    const method = appData.session && appData.session.token && authorizedCall ? 'authorizedCall' : 'call';

    return this[method](getConsentUrlRequest).then((data) => {
      consent.init(data);
      return data;
    });
  },

  async setCountries (force: boolean = false) {
    if (!sessionStorage.getItem('COUNTRIES') || force) {
      new GetCountriesRequest().dropCache();
      const request = new GetCountriesRequest();
      const data = await this.call(request);
      const countries = Object.fromEntries(data.items);
      sessionStorage.setItem('COUNTRIES', JSON.stringify(countries));
    } else {
      return Promise.resolve({});
    }
  },
  async runPswCheck (session: Partial<Session>): Promise<void> {
    const userId = session.personalDomainId;
    const existedPswCheck = JSON.parse(localStorage.getItem(PSW_LOCAL_STORAGE_KEY) || localStorage.getItem('awinguCheck') || '{}');
    if (existedPswCheck[userId]) {
      return;
    }
    try {
      const request = new ListBusinessDomainsRequest({ get_signed_domains_for: 'ls' });
      const { signed_domains_ids: domainIds, domains } = await this.authorizedCall(request);
      if (domainIds && domainIds.length) {
        const productRequest = new GetDomainsWithProductRequest({ domainIds, product: PRODUCT_KEY_NAME_PSW });
        const productResponse: GetDomainsWithProductRequestResponse = await this.authorizedCall(productRequest);
        if (productResponse.count) {
          for (const domainWithPswId of productResponse.results.map(product => product.id)) {
            const domainWithPsw = domains.find((domain: BusinessDomain) => domain.id === domainWithPswId);
            if (!domainWithPsw.is_invite) {
              continue;
            }
            // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
            const $bus = this.options.context?.app?.$bus;
            if ($bus) {
              existedPswCheck[userId] = true;
              localStorage.setItem(PSW_LOCAL_STORAGE_KEY, JSON.stringify(existedPswCheck));
              $bus.$emit('openPswWizard', { domain: domainWithPsw });
            }
            break;
          }
        }
      }
    } catch (e) {}
  },
};

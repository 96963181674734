/**
 * File: configurationProfile.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import CoreModel from '@core/common/model';

export default class ConfigurationProfile extends CoreModel {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  hostsCnt: string;
  subscriptionsCnt: number;
  templateVersion: string[];
  values: {
    vm?: {
      name: string,
      checksum?: number,
      downloadUrl: string;
    },
    updates?: {
      allowUpgrade: boolean;
    }
  };
}

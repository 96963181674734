
import Vue from 'vue';

export default Vue.extend({
  name: 'confirm-email-modal',
  data () {
    return {
      text: '',
      loading: false,
      size: 10,
    };
  },
  methods: {
    show (e: { name: string }, text: string) {
      this.text = text;
    },
    sendEmail (): void {
      this.$emit('requestEmail');
    },
    closeModal (): void {
      this.$modal.hide();
    },
  },
});

/**
 * File: rtGetTicketRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest, { RtRequestParams } from './rtRequest';
import { Method } from '@core/api/apiRequest';
import Ticket from '@/models/ticket';
import { getMergedIn, getStatus } from '@/api/rt/ticketStatus';

interface RtGetTicketRequestParams extends RtRequestParams {
  ticketId: string;
}

export default class RtGetTicketRequest extends RtRequest<RtGetTicketRequestParams, Ticket> {
  /**
   * @returns {String}
   */
  get uri (): string {
    return `/ticket/${this.params.ticketId}`;
  }

  get method (): Method {
    return 'GET';
  }

  async getJson (args): Promise<Ticket> {
    const ticket = await super.getJson(args);
    const status = ticket.fields.status;
    ticket.fields.mergedIn = getMergedIn(status);
    ticket.fields.status = getStatus(status);
    return ticket;
  }
}

/**
 * File: licenseKeyInfoRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequestWithFallback from '@core/api/apiRequestWithFallback';
import Subscription from '@/models/subscription';
import { HTTP_CODES } from '@core/constants/http';

export default class LicenseKeyInfoRequest extends ApiRequestWithFallback {
  get method () {
    return 'POST';
  }

  get urlSuffix () {
    return '/api/v1/subscriptions/get_info';
  }

  get body () {
    return {
      lic_key: this.params.licKey,
    };
  }

  needFallback (response, data) {
    return (response.status === HTTP_CODES.CONFLICT && data.msg === 'invalid license key');
  }

  load () {
    return super.load().then(data => {
      if (data && data.subscription) {
        data.subscription = new Subscription(data.subscription);
      }
      return data;
    });
  }
}

/**
 * File: twoStepVerificationPageMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */
import Vue from 'vue';

import { oneTimePassword } from '@core/common/validators';

import { OTP_TYPE_RC, OTP_TYPE_OTP } from '@/ui/one-time-password';
import InputOneTimePassword from '@/ui/one-time-password/index.vue';
import VerifyOneTimePasswordRequest from '@/api/verifyOneTimePasswordRequest';
import { START_PAGE } from '@/routes/constants';

export default Vue.extend({

  components: {
    InputOneTimePassword,
  },

  validations: {
    form: {
      otp: {
        ...oneTimePassword,
        ...{
          invalidOneTimePasswordOrRescueCode: function () {
            // @ts-ignore
            return !(this.invalidOneTimePasswordOrRescueCode && this.appliedOneTimePasswordOrRescueCode === this.form.otp);
          },
        },
      },
    },
  },

  data () {
    return {
      OTP_TYPE_RC,
      OTP_TYPE_OTP,

      form: {
        otp: '',
        markTrusted: false,
      },
      loading: false,
      invalidOneTimePasswordOrRescueCode: false,
      appliedOneTimePasswordOrRescueCode: '',
    };
  },

  methods: {
    async submit () {
      this.appliedOneTimePasswordOrRescueCode = this.form.otp;
      this.invalidOneTimePasswordOrRescueCode = false;
      this.loading = true;
      try {
        await this.$api.authorizedCall(new VerifyOneTimePasswordRequest(
          { oneTimePassword: this.form.otp, markTrusted: this.form.markTrusted }
        ));
        // Redirect to dashboard
        await this.$api.refreshToken(true);
        await this.$router.push({ name: START_PAGE });
      } catch (e) {
        this.invalidOneTimePasswordOrRescueCode = true;
        this.$v.$touch();
      } finally {
        this.loading = false;
      }
    },
    async cancel () {
      this.loading = true;
      this.$api.logout();
    },
  },

  watch: {
    'form.otp': function (value: string) {
      if (value.includes(' ')) {
        this.form.otp = value.replace(' ', '');
      }
    },
  },
});


import Vue from 'vue';

import { CONFIGURE_GPS_MODAL } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'configure-gps-link',
  props: ['fromModal', 'isBtn', 'color', 'size'],
  methods: {
    clickHandler () {
      const modal = this.$modal.get(CONFIGURE_GPS_MODAL);
      if (modal) {
        modal.$emit('linkClick', this.$props);
      }
    },
  },
});


/**
 * File: pdComputersRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PdHost from '@/models/pdHost';

export default class PdComputersRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'PdComputers';
  }

  buildQueryString (qp) {
    return super.buildQueryString(qp);
  }

  /**
   *
   * @param {?Object} params
   * @param {?String} [params.q]
   * @param {?Array|String} [params.keys]
   * @param {?Boolean} [params.totalOnly]
   * @param {?Boolean} [params.countTotal]
   * @param {?Number} [params.limit]
   * @param {?Number} [params.offset]
   * @param {?String} [params.product]
   * @param {?Boolean} [params.activated]
   * @param {?Boolean} [params.totalOnly]
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get queryParams () {
    const params = this.params;
    const qp = {};

    if (params.hasOwnProperty('product')) {
      qp.product = params.product;
    }

    if (params.hasOwnProperty('q')) {
      qp.q = params.q;
    }

    if (params.hasOwnProperty('activated')) {
      qp.activated = params.activated === true ? 1 : 0;
    }

    if (params.hasOwnProperty('wo_blocked')) {
      qp.wo_blocked = params.wo_blocked ? 'true' : 'false';
    }

    if (params.hasOwnProperty('keys')) {
      if (Array.isArray(params.keys)) {
        qp.keys = params.keys.join(',');
      } else {
        qp.keys = params.keys;
      }
    }

    if (params.hasOwnProperty('totalOnly')) {
      qp.to = 1;
    }

    if (params.hasOwnProperty('countTotal')) {
      qp.ct = 1;
    }

    if (params.hasOwnProperty('limit')) {
      qp.limit = params.limit;
    }

    if (params.hasOwnProperty('offset')) {
      qp.offset = params.offset;
    }

    return qp;
  }

  get url () {
    const url = '/desktop/api/v1/ba/hosts';
    const qs = this.buildQueryString(this.queryParams);

    return qs ? url + qs : url;
  }

  /**
   *
   * @returns {Number} - Total of founded subscriptions.
   */
  getTotal () {
    if (this.data === null) {
      return null;
    }

    if (this.data.hasOwnProperty('total')) {
      return this.data.total;
    }

    return this.data.hosts.length;
  }

  /**
   *
   * @returns {Array<PdHost>} - list of PdHost models.
   */
  getHosts () {
    if (this.data === null) {
      return null;
    }
    const hosts = [];
    this.data.hosts.forEach((subData) => {
      hosts.push(new PdHost(subData));
    });
    return hosts;
  }
}

<copyright>
File: buyPhoneSupportModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

modal(:name="name", @show="onShow", @hide="onHide", :size="12")
  template(slot="header") {{ $t('Buying Phone Support') }}
  template(slot="content")
    loader(:loading="loading")
      template(v-if="inProgress")
        formatter(:text="$t('Complete the purchase in the Parallels Online Shopping cart (opened in a separate web browser’s tab). Click {b}Done{/b} when your transaction is finished.')")
          template(v-slot:b="{ text }")
            b {{ text }}
      template(v-else)
        template(v-if="phonePlan")
          .margin-bottom
            formatter(:text="$t('You’re about to purchase a single-case Parallels Desktop support ticket for {price}.')")
              template(v-slot:price) {{ phonePlan.price | price(phonePlan.currency) }}
          .margin-bottom(v-if="upgradeSubscriptionPlan")
            formatter(:text="$t('As an alternative, consider purchasing the upgrade to 1-year Parallels Desktop subscription for {price} and get Phone, Chat, Email and Skype support for free.')")
              template(v-slot:price) {{ upgradeSubscriptionPlan.price | price(upgradeSubscriptionPlan.currency) }}
        div {{ $t('To proceed with the purchase, you will be redirected to Parallels Online Store for checkout. Return to this page after completing the purchase.') }}
  template(slot="footer")
    template(v-if="inProgress")
      btn(@click="checkPurchase", key="done", :disabled="loading") {{ $t('Done') }}
    template(v-else)
      btn.proceed(:href="links.phone", target="_blank", @click="onPurchase", key="phone", :disabled="loading") {{ $t('Proceed to Checkout') }}
      btn.proceed(color="white", :href="links.upgrade", target="_blank", @click="onUpgrade", key="upgrade", :disabled="loading") {{ $t('Upgrade to Subscription') }}
    btn(color="white", @click="$modal.hide()") {{ $t('Cancel') }}

</template>

<script>

import CombinedApiRequest from '@core/api/combinedApiRequest';
import GenerateCartBuyLinkRequest from '@/api/generateCartBuyLinkRequest';
import SubscriptionsRequest from '@/api/lsSubscriptionsRequest';
import {
  PRODUCT_KEY_NAME_PER_INCIDENT_SUPPORT,
  PER_INCIDENT_SUPPORT_PUBLIC_ID,
  PD_UPGRADE_TO_PRO_PUBLIC_ID
} from '@core/constants/subscriptions';

export default {
  name: 'buy-support-modal',
  props: {
    name: {
      type: String,
      required: true,
    },
    phonePlan: {
      type: Object,
    },
    upgradeSubscriptionPlan: {
      type: Object,
    },
  },
  data () {
    return {
      loading: false,
      links: {
        phone: undefined,
        upgrade: undefined,
      },
      inProgress: false,
    };
  },
  methods: {
    onShow () {
      if (this.links.phone && this.links.upgrade) {
        return;
      }

      const request = new CombinedApiRequest();

      request.addRequest('PER_INCIDENT_SUPPORT', new GenerateCartBuyLinkRequest({ product: PER_INCIDENT_SUPPORT_PUBLIC_ID }));
      request.addRequest('UPGRADE_TO_PRO', new GenerateCartBuyLinkRequest({ product: PD_UPGRADE_TO_PRO_PUBLIC_ID }));

      this.loading = true;

      this.$api.authorizedCall(request).then(() => {
        this.links.phone = request.getRequest('PER_INCIDENT_SUPPORT').data.url;
        this.links.upgrade = request.getRequest('UPGRADE_TO_PRO').data.url;
      }).finally(() => {
        this.loading = false;
      });
    },
    onHide () {
      this.inProgress = false;
    },
    onPurchase () {
      this.inProgress = true;
    },
    onUpgrade () {
      this.$modal.hide();
      this.$emit('upgrade');
    },
    checkPurchase () {
      const request = new SubscriptionsRequest({
        with_hidden_resources: true,
        product: PRODUCT_KEY_NAME_PER_INCIDENT_SUPPORT,
      });

      request.dropCache();

      this.loading = true;

      this.$api.authorizedCall(request).then(() => {
        const subscription = request.getSubscriptions().find((s) => {
          return s.products.find((product) => product.keyName === PRODUCT_KEY_NAME_PER_INCIDENT_SUPPORT && product.licenses.available > 0);
        });

        if (subscription) {
          this.$modal.hide();
          this.$emit('reload');
        } else {
          this.$toast.show({
            text: $t('No updates about the purchase. Make sure the transaction is completed.'),
            color: 'yellow',
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};

</script>

<style scoped lang="sass">

.proceed
  float: right
  margin-left: $grid-step
  +phone
    display: block
    float: none
    width: 100%
    margin: 0 0 $grid-step 0

</style>

/**
 * File: pmmSite.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';

export default class PmmSite extends CoreModel {
  get name () {
    return this.__name || 'Untitled server';
  }

  set name (value) {
    this.__name = value;
  }

  get description () {
    return this.__description || '';
  }

  get macActivationDate () {
    return this.macSubscription && this.macSubscription.activatedAt;
  }

  get mdmActivationDate () {
    return this.mdmSubscription && this.mdmSubscription.activatedAt;
  }

  get lastReportDate () {
    return this.lastReportedAt;
  }

  get macLicKey () {
    return this.macSubscription && this.macSubscription.licKey;
  }

  get mdmLicKey () {
    return this.mdmSubscription && this.mdmSubscription.licKey;
  }

  get macSubscriptionParentUuid () {
    return this.macSubscription && (this.macSubscription.parentUuid || this.macSubscription.uuid);
  }

  get mdmSubscriptionParentUuid () {
    return this.mdmSubscription && (this.mdmSubscription.parentUuid || this.mdmSubscription.uuid);
  }

  get macSubscriptionUuid () {
    return this.macSubscription && this.macSubscription.uuid;
  }

  get mdmSubscriptionUuid () {
    return this.mdmSubscription && this.mdmSubscription.uuid;
  }

  get notes () {
    return this.__notes || '';
  }

  set notes (value) {
    this.__notes = value;
  }

  get appVersion () {
    return this.proxyVersion;
  }

  get offlineAllowed () {
    return this.macOfflineAllowed || this.mdmOfflineAllowed;
  }

  get macOfflineAllowed () {
    return this.macSubscription && this.macSubscription.offlineAllowed;
  }

  get mdmOfflineAllowed () {
    return this.mdmSubscription && this.mdmSubscription.offlineAllowed;
  }

  get macLimit () {
    return this.macSubscription && this.macSubscription.limit;
  }

  get mdmLimit () {
    return this.mdmSubscription && this.mdmSubscription.limit;
  }

  get macUsage () {
    return this.macSubscription && this.macSubscription.usage;
  }

  get mdmUsage () {
    return this.mdmSubscription && this.mdmSubscription.usage;
  }

  get macIsExpired () {
    return this.macSubscription && this.macSubscription.isExpired;
  }

  get mdmIsExpired () {
    return this.mdmSubscription && this.mdmSubscription.isExpired;
  }

  get macIsExpiringSoon () {
    return this.macSubscription && this.macSubscription.isExpiringSoon;
  }

  get mdmIsExpiringSoon () {
    return this.mdmSubscription && this.mdmSubscription.isExpiringSoon;
  }
};

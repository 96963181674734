import { render, staticRenderFns } from "./index.vue?vue&type=template&id=229814c8&scoped=true&lang=pug"
import script from "./index.ts?vue&type=script&lang=ts&external"
export * from "./index.ts?vue&type=script&lang=ts&external"
import style0 from "./index.vue?vue&type=style&index=0&id=229814c8&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "229814c8",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports
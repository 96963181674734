/**
 * File: apiRequestWithFallback.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import 'whatwg-fetch';
import ApiRequest from './apiRequest';
import { getServiceUrl } from './routing';
import Service from '../constants/services';

export default class ApiRequestWithFallback<ParamsData, ResponseData> extends ApiRequest<ParamsData, ResponseData> {

  get urlSuffix (): string {
    throw new Error('Please define url and fallbackUrl or urlSuffix!');
  }

  get url (): string {
    return getServiceUrl(Service.License, this.urlSuffix);
  }

  /**
   * URL to use for fallback request.
   */
  get fallbackUrl (): string {
    return getServiceUrl(Service.RasLicense, this.urlSuffix);
  }

  getFallbackPromise () {
    this._json = null; // clearing cached response
    this.promise = fetch(this.fallbackUrl, this.options);
    return this.promise
      .then((response) => {
        this.response = response;
        return response;
      })
      .then((response) => {
        return super.checkStatus(response);
      });
  }

  needFallback (response: Response, data: ResponseData): boolean {
    throw new Error('get needFallback () not implemented');
  }

  // @ts-ignore
  checkStatus (response: Response): Promise<Response> {
    return this.getJson(response).then(
      (data) => {
        if (this.needFallback(response, data)) {
          return this.getFallbackPromise();
        } else {
          return super.checkStatus(response);
        }
      }
    );
  }
}

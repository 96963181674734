/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

export const APPLE_SOCIAL_SERVICE = 'ap';
export const FACEBOOK_SOCIAL_SERVICE = 'fb';
export const GOOGLE_PLAY_SOCIAL_SERVICE = 'gp';

export type SOCIAL_SERVICE = 'ap' | 'fb' | 'gp';

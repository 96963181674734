/**
 * File: ptbBlockHostsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class PtbBlockHostsRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/toolbox/api/v1/ba/block';
  }

  get body () {
    return {
      hosts: this.params.hwIds,
      comment: this.params.comment,
    };
  }
}



import Vue, { PropType } from 'vue';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionMixIn from '@/modules/subscriptions/subscriptionMixIn';
import { LicensesInfo, ProductInfo } from '@/models/subscription';

export default Vue.extend({
  name: 'license-seats',
  mixins: [ComponentMixIn, SubscriptionMixIn],
  props: {
    productInfo: {
      type: Object as PropType<ProductInfo>,
      default: () => {},
    },
  },
  computed: {
    licensesInfo (): LicensesInfo {
      return (this.productInfo as ProductInfo)?.licenses as LicensesInfo;
    },
    occupiedLicenseSeats (): number {
      return (this.occupiedReservedPool + this.occupiedDynamicPool) || 0;
    },
    vacantLicenseSeats (): number {
      return (this.licensesInfo.thisPeriod - this.occupiedLicenseSeats) || 0;
    },
    occupiedReservedPool (): number {
      return this.licensesInfo.totalReservedUsage || 0;
    },
    occupiedDynamicPool (): number {
      return this.licensesInfo.totalDynamicUsage || 0;
    },
    vacantReservedPool (): number {
      return this.licensesInfo.reserved || 0;
    },
    vacantDynamicPool (): number {
      return (this.vacantLicenseSeats - this.vacantReservedPool) || 0;
    },
  },
});


/**
 * File: companyUser.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 */

import CoreModel from '@core/common/model';
import { GROUP_INVITED } from '@/api/userConst';

export enum USER_STATUS {
  UNCONFIRMED = 0,
  CONFIRMED = 1,
  DISABLED = 2
}
export const ROLE_ALL = 'All';

export default class CompanyUser extends CoreModel {
  groups?: string[];
  status: number;
  email: string;
  name: string;
  uuid: number;
  notes: string;
  isAdmin: boolean;

  get isInvited (): boolean {
    return this.groups && this.groups.includes(GROUP_INVITED);
  }

  get isBlocked (): boolean {
    return this.status === USER_STATUS.DISABLED;
  }

  get mailto (): string {
    return `mailto:${this.email}`;
  }
}

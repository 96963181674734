/**
 * File: subscriptionUpdateRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import SubscriptionRequest from '@/api/subscriptionRequest';
import SvcSubscriptionRequest from '@/api/svcSubscriptionRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import SubscriptionUniversalKeysRequest from '@/api/subscriptionUniversalKeysRequest';
import RasSubscriptionsRequest from '@/api/rasSubscriptionsRequest';
import { getServiceUrl } from '@core/api/routing';

export default class SubscriptionUpdateRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/update`);
  }

  get body () {
    return {
      name: this.params.name,
    };
  }

  getJson () {
    // No JSON content expected here
    return null;
  }

  load () {
    return super.load()
      .then((data) => {
        // Clear cache for all request where subscription name is present
        new SubscriptionUniversalKeysRequest({
          subscriptionUuid: this.params.uuid,
          service: this.params.service,
        }).dropCache();
        new SubscriptionUniversalKeysRequest({
          subscriptionUuid: this.params.uuid,
          includeSubsets: true,
          service: this.params.service,
        }).dropCache();
        new SubscriptionRequest({ uuid: this.params.uuid }).dropCache();
        new SvcSubscriptionRequest({ uuid: this.params.uuid, service: this.params.service }).dropCache();
        new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_PERSONAL }).dropCache();
        new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS }).dropCache();
        new RasSubscriptionsRequest().dropCache();
        return data;
      });
  }
}

/**
 * File: pmmDeactivateSitesRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PmmSitesRequest from './pmmSitesRequest';

export default class pmmDeactivateSitesRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {Array<String>} params.uuids
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/pmm/webapp/sites/deactivate';
  }

  get body () {
    return { uuids: this.params.uuids };
  }

  load () {
    return super.load()
      .then((data) => {
        new PmmSitesRequest({}).dropFullCache();
        return data;
      });
  }
}

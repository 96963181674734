/**
 * File: maintenance.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 **/

export const SCHEDULED_MAINTENANCE = 1;
export const UNEXPECTED_OUTAGE = 2;
export const SUPPORT_MAINTENANCE = 3;

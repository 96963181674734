/**
 * File: setHomePageDisplayState.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class SetHomePageDisplayState extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/account/webapp/set_home_page_display_status';
  }

  get body () {
    return { state: this.params.state };
  }

  get jsonData () {
    return null; // no response data is expected
  }
}

<copyright>
File: formLabel.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

span
  template(v-if="format")
    formatter(:text="$t(label.text)")
      template(v-for="(link, slot) in label.arguments", :slot="slot", slot-scope="s")
        a(:href="link", target="_blank") {{ s.text }}
  template(v-else) {{ $t(label) }}

</template>

<script>

export default {
  name: 'support-form-label',
  props: {
    label: {
      type: [String, Object],
      required: true,
    },
  },
  computed: {
    format () {
      return this.label.text;
    },
  },
};

</script>

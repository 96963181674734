
import Vue from 'vue';

import StatusMixin from '../statusMixin';

export default Vue.extend({
  name: 'subscription-status-cb-expired',
  mixins: [StatusMixin],

  computed: {
    cbExpired (): string {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      const date = this.formatDate(this.subscription.expirationDate);
      const regularMemberText = this.$t('Your subscription expired on {date}. Please contact your administrator for renewal details to continue using the product.', { date });
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      if (this.subscription.isGracePeriod) {
        if (this.$appData.session.isBusinessAdmin || this.$appData.session.isPersonalUser(this.$appData.userInDomain)) {
          return this.$t('Your subscription expired on {date}. Renew the subscription as soon as possible to continue using the product. Revise your payment options to enable subscription renewal.', { date });
        } else if (this.$appData.session.isRegularMember) {
          return regularMemberText;
        } else {
          return '';
        }
      } else {
        if (this.$appData.session.isCurrentRegularMember(this.$appData.userInDomain)) {
          return regularMemberText;
        } else {
          return this.$t('Your subscription expired on {date}. Renew the subscription to continue using the product. Revise your payment options to enable subscription renewal.', { date });
        }
      }
    },
  },
});

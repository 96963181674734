/**
 * File: proxyServerDetailsModal.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ComponentMixIn from '@/modules/componentMixIn';
import GeneralTab from './general.vue';
import MacTab from './mac.vue';
import MdmTab from './mdm.vue';
import UsageTab from './usage.vue';
import PmmDeactivateSiteRequest from '@/api/pmmDeactivateSiteRequest';
import PmmUpdateSiteRequest from '@/api/pmmUpdateSiteRequest';

export default {
  name: 'proxy-server-details-modal',

  mixins: [ComponentMixIn],

  components: { GeneralTab, MacTab, MdmTab, UsageTab },

  data () {
    return {
      updating: false,
      deactivating: false,
      showNotes: true,
      tabs: {
        general: $t('General'),
        mac: 'Mac Management',
        mdm: 'MDM',
        usage: $t('Usage'),
      },
      server: null,
      form: {
        name: '',
        notes: '',
      },
    };
  },

  methods: {
    tabChanged (params) {
      this.showNotes = params.tab !== 'usage';
    },

    show (server) {
      this.server = server;
      this.form.name = server.name;
      this.form.notes = server.notes;
      this.$refs.modal.show();
      this.$emit('show', server);
    },

    hide () {
      this.$refs.editableHeader.undo();
      this.$refs.modal.hide();
      this.$emit('hide', this.server);
    },

    deactivate () {
      this.authorizedCall(
        new PmmDeactivateSiteRequest({ uuid: this.server.uuid }),
        'deactivating'
      ).then(() => {
        this.$emit('serverDeactivated', this.host);
        this.showToast($t('Proxy Server "{name}" has been deactivated.', { name: this.form.name }));
      });
    },

    macDeactivate () {
      this.authorizedCall(
        new PmmDeactivateSiteRequest({ uuid: this.server.uuid, subscription_uuid: this.server.macSubscriptionUuid }),
        'deactivating'
      ).then(() => {
        this.$emit('serverDeactivated', this.host);
        this.showToast($t('Proxy Server "{name}" has been deactivated.', { name: this.form.name }));
      });
    },

    mdmDeactivate () {
      this.authorizedCall(
        new PmmDeactivateSiteRequest({ uuid: this.server.uuid, subscription_uuid: this.server.mdmSubscriptionUuid }),
        'deactivating'
      ).then(() => {
        this.$emit('serverDeactivated', this.host);
        this.showToast($t('Proxy Server "{name}" has been deactivated.', { name: this.form.name }));
      });
    },

    save () {
      if (!this.changed) {
        return;
      }
      this.authorizedCall(
        new PmmUpdateSiteRequest({
          uuid: this.server.uuid,
          name: this.form.name,
          notes: this.form.notes,
        }),
        'updating'
      ).then(() => {
        this.server.name = this.form.name;
        this.server.notes = this.form.notes;
        this.$emit('serverUpdated', this.server);
        this.showToast($t('Proxy Server "{name}" has been updated.', { name: this.form.name }));
      });
    },
  },

  computed: {
    changed () {
      const server = this.server; const form = this.form;
      return (server && (server.notes !== form.notes || server.name !== form.name));
    },
  },
};

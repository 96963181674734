/**
 * File: index.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'one-time-password-cell',
  props: {
    value: {
      type: String,
    },
    focus: {
      type: Boolean,
    },
    propClass: {
      type: Array as PropType<string[]>,
    },
    readonly: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    isLastChild: {
      type: Boolean,
    },
  },
  data () {
    return {
      model: this.value || '',
    };
  },
  mounted () {
    if (this.$refs.input && this.focus) {
      const input = this.$refs.input as HTMLInputElement;
      input.focus();
    }
  },
  watch: {
    value (value: string, prevValue: string) {
      if (value !== prevValue) {
        this.model = value;
      }
    },
    focus (focused: boolean, prevFocused: boolean) {
      if (prevFocused !== focused && (this.$refs.input && this.focus)) {
        const input = this.$refs.input as HTMLInputElement;
        input.focus();
        input.select();
      }
    },
  },
  methods: {
    onChange () {
      if (this.model.length > 1) {
        this.model = this.model.slice(0, 1);
      }
      this.$emit('change', this.model);
    },
    onKeyDown (event) {
      this.$emit('keydown', event);
    },
    onPaste (event) {
      this.$emit('paste', event);
    },
    onFocus () {
      const input = this.$refs.input as HTMLInputElement;
      input.select();
      this.$emit('focus');
    },
    onBlur () {
      this.$emit('blur');
    },
  },
});

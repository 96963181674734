/**
 * File: switchMfaMethodRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import md5 from 'blueimp-md5';

import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';
import { MFA_METHOD } from '@core/constants/mfa';

import AuthenticatorApp from '@/models/authenticatorApp';

import { AuthenticatorAppResponse, patchAuthenticatorAppName } from './mfaUtils';

/* eslint-disable camelcase */
interface SwitchMfaMethodResponseData {
  auth_app?: AuthenticatorAppResponse;
  rescue_codes: string[];
}
/* eslint-enable camelcase */

interface SwitchMfaMethodRequestParams {
  newMfaMethod: MFA_METHOD;
  secret?: string;
  password?: string;
  oneTimePassword?: string;
}

export default class SwitchMfaMethodRequest extends ApiRequest<SwitchMfaMethodRequestParams, SwitchMfaMethodResponseData> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/method`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      new_method: this.params.newMfaMethod,
      secret: this.params.secret,
      password: this.params.password ? md5(this.params.password) : this.params.oneTimePassword,
    };
  }

  getAuthenticatorApp (index: number): AuthenticatorApp {
    return new AuthenticatorApp(patchAuthenticatorAppName(this.data.auth_app, index));
  }

  getRescueCodes (): string[] {
    return this.data.rescue_codes;
  }
}

/**
 * File: subscriptionInviteModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue from 'vue';
import { Dictionary } from '@core/common/types';
import { LANGUAGES } from '@core/constants/langs';
import CsvSaver from '@/ui/csvSaver.vue';
import emailList from '@/ui/email-list/index.vue';
import PdBusinessSubscriptionsRequest from '@/api/pdBusinessSubscriptionsRequest';
import PdBusinessSubscriptionInviteRequest from '@/api/pdBusinessSubscriptionInviteRequest';
import GetPdBusinessSubscriptionInviteRequest from '@/api/getPdBusinessSubscriptionInviteRequest';
import componentMixIn from '@/modules/componentMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import { textFilter } from '@/modules/subscriptions/helpers';

const DEFAULT_TTL_VALUE = 7;
const SUBSET_POLLING_TIME = 2000;
const SUCCESS_COLOR_VALUE = 'green';
const SUCCESS_MESSAGE_VALUE = '{count} invitations have been sent'; // $tc('{count} invitations have been sent')
const SUBSCRIPTIONS_DETAILS_ALIAS = 'subscriptionDetails';

const EVENT_HIDE_ALIAS = 'hide';
const COL_UUID_ALIAS = 'uuid';
const COL_FULL_NAME_ALIAS = 'keyFullName';
const COL_AVAILABLE_ALIAS = 'available';
const COL_PENDING_ALIAS = 'invitationsPending';
const COL_EXPIRATION_ALIAS = 'expirationDate';
const COL_DETAILS_ALIAS = 'details';
const COL_EMAIL_LABEL = 'Email'; // $t('Email')
const COL_EMAIL_VALUE = 'email';
const COL_CODE_LABEL = 'Action Code'; // $t('Action Code')
const COL_CODE_VALUE = 'action_code';
const COL_DOWNLOAD_LABEL = 'Download Link'; // $t('Download Link')
const COL_DOWNLOAD_VALUE = 'download_link';

const COLS_LIST = [
  COL_UUID_ALIAS,
  COL_FULL_NAME_ALIAS,
  COL_AVAILABLE_ALIAS,
  COL_PENDING_ALIAS,
  COL_EXPIRATION_ALIAS,
  COL_DETAILS_ALIAS,
];

const COLS_SORTABLE = [
  COL_FULL_NAME_ALIAS,
  COL_AVAILABLE_ALIAS,
  COL_PENDING_ALIAS,
  COL_EXPIRATION_ALIAS,
];

const TTL_THREE_DAYS_LABEL = '3 days'; // $t('3 days')
const TTL_THREE_DAYS_VALUE = 3;
const TTL_WEEK_LABEL = '7 days'; // $t('7 days')
const TTL_WEEK_VALUE = 7;
const TTL_HALF_MONTH_LABEL = '14 days'; // $t('14 days')
const TTL_HALF_MONTH_VALUE = 14;
const TTL_MONTH_LABEL = '1 month'; // $t('1 month')
const TTL_MONTH_VALUE = 31;

interface ModalData {
  selectedUuid: string | null;
  subscriptions: Dictionary[];
  subscription: Dictionary | null;
  form: ModalFormData;
  table: ModalTableData;
  filters: Dictionary;
  textFilter: Function;
}

interface ModalFormData {
  ttl: number;
  locale: string;
  ttlOptions: Dictionary[];
  localeOptions: Dictionary[];
  emails: {
    valid: boolean;
    list: string[];
  };
}

interface ModalTableData {
  columns: string[];
  options: {
    sortable: string[];
    uniqueKey: string;
    orderBy: {
      column: string;
    };
  };
}

export default Vue.extend({

  name: 'subscription-invite-modal',

  mixins: [
    componentMixIn,
    SearchFilterMixin,
  ],

  components: {
    emailList,
    CsvSaver,
  },

  props: {
    /**
     * Currently selected universal key
     * if set, the modal switches to step 2 immediately
     */
    // universalKey: Object,

    /**
     * Current user session
     */
    session: Object,

    /**
     * Default invite TTL (in days)
     */
    defaultTtl: {
      type: Number,
      default: DEFAULT_TTL_VALUE,
    },

    product: {
      type: String,
    },
  },

  data (): ModalData {
    return {
      selectedUuid: null,
      subscriptions: [],
      subscription: null,
      form: {
        emails: {
          valid: false,
          list: [],
        },
        localeOptions: LANGUAGES,
        locale: this.session.isoLocale,
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        ttlOptions: this.getTtlOptions(),
        ttl: this.defaultTtl,
      },
      table: {
        columns: COLS_LIST,
        options: {
          orderBy: {
            column: COL_FULL_NAME_ALIAS,
          },
          sortable: COLS_SORTABLE,
          uniqueKey: COL_UUID_ALIAS,
        },
      },
      filters: {},
      textFilter,
    };
  },

  methods: {

    load (): Promise<void> {
      const request = new PdBusinessSubscriptionsRequest({ product: this.product });

      return this.authorizedCall(request).then(() => {
        const subscriptions = request.getSubscriptions();

        this.subscriptions = subscriptions.filter((subscription) => subscription.isActive);
      });
    },

    loadSubscription (subscriptionUuid: string): Promise<void> {
      const request = new GetPdBusinessSubscriptionInviteRequest({
        subscriptionUuid,
      });

      return this.authorizedCall(request);
    },

    reset (): void {
      this.subscription = null;

      new PdBusinessSubscriptionsRequest({ product: this.product }).dropCache();
    },

    resetForm (): void {
      this.form.emails = {
        valid: false,
        list: [],
      };

      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.emailList.removeAll();

      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.emailList.resetValidation();
    },

    show (subscriptionUuid: string): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.searchField && this.$refs.searchField.clear();

      if (subscriptionUuid) {
        // Check if subset is created and load full list of subscriptions
        this.loadSubscription(subscriptionUuid).then(() => {
          return this.load();
        }).then(() => {
          this.subscription = this.subscriptions.find((subscription) => subscription.uuid === subscriptionUuid);
        }).catch(() => {
          this.loading = true;

          setTimeout(() => {
            this.show(subscriptionUuid);
          }, SUBSET_POLLING_TIME);
        });
      } else {
        // Load full list of subscriptions
        this.load().then(() => {
          if (this.subscriptions.length === 1) {
            this.subscription = this.subscriptions[0];
          }
        });
      }

      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.modal.show();
    },

    hide (): void {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.modal.hide();
      this.$emit(EVENT_HIDE_ALIAS);

      if (this.subscription) {
        this.resetForm();
        this.subscription = null;
      }
    },

    next (): void {
      this.subscription = this.subscriptions.find((subscription) => subscription.uuid === this.selectedUuid);

      this.$nextTick(() => this.resetForm());
    },

    back (): void {
      this.subscription = null;
    },

    send (): void {
      const
        payload = {
          emails: this.form.emails.list,
          locale: this.form.locale,
          ttl: this.form.ttl,
          force: true,
        };
      const request = new PdBusinessSubscriptionInviteRequest({
        payload,
        subscriptionUuid: this.subscription.uuid,
        generateMode: false,
      });

      this.authorizedCall(request)
        .then((data) => {
          const count = data.sent.length;

          this.hide();
          this.reset();
          this.resetForm();

          this.$toast.show({
            color: SUCCESS_COLOR_VALUE,
            text: this.$tc(SUCCESS_MESSAGE_VALUE, count, { count }),
          });
        });
    },

    details (subscriptionUuid): void {
      this.hide();

      this.$router.push({ name: SUBSCRIPTIONS_DETAILS_ALIAS, params: { id: subscriptionUuid } });
    },

    getTtlOptions (): Dictionary {
      return [
        { text: this.$t(TTL_THREE_DAYS_LABEL), value: TTL_THREE_DAYS_VALUE },
        { text: this.$t(TTL_WEEK_LABEL), value: TTL_WEEK_VALUE },
        { text: this.$t(TTL_HALF_MONTH_LABEL), value: TTL_HALF_MONTH_VALUE },
        { text: this.$t(TTL_MONTH_LABEL), value: TTL_MONTH_VALUE },
      ];
    },

    refreshSubscription (): void {
      const subscriptionUuid = this.subscription.uuid;

      this.reset();
      this.show(subscriptionUuid);
    },

    download (): void {
      const
        payload = {
          emails: this.form.emails.list,
          locale: this.form.locale,
          ttl: this.form.ttl,
        };
      const request = new PdBusinessSubscriptionInviteRequest({
        payload,
        subscriptionUuid: this.subscription.uuid,
        generateMode: true,
      });

      this.authorizedCall(request).then((data) => {
        const columns = [
          { text: this.$t(COL_EMAIL_LABEL), value: COL_EMAIL_VALUE },
          { text: this.$t(COL_CODE_LABEL), value: COL_CODE_VALUE },
          { text: this.$t(COL_DOWNLOAD_LABEL), value: COL_DOWNLOAD_VALUE },
        ];

        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.$refs.csv.save({
          columns,
          rows: data.invitations,
          quotes: false,
        });

        // Refresh subscription after csv save
        this.$nextTick(() => {
          this.refreshSubscription();
        });
      });
    },
  },

  computed: {
    searchResults (): Dictionary[] {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.applySearchFilters(this.filters, this.subscriptions);
    },

    added (): number {
      return this.form.emails.list.length;
    },
  },

  watch: {
    searchResults (val: Dictionary[]): void {
      if (this.selectedUuid && !val.some((s) => s.uuid === this.selectedUuid)) {
        this.selectedUuid = null;
      }
    },
  },

});

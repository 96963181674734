
import Vue from 'vue';
import { PERSONAL_PROFILE_SCOPE, SECURITY_PAGE } from '@/routes/constants';

export default Vue.extend({
  name: 'enable-authenticator-app-mfa-modal',
  methods: {
    goToPersonalSecuritySettings () {
      this.$router.replace({ name: PERSONAL_PROFILE_SCOPE, params: { page: SECURITY_PAGE, enableTotp: 'true' } });
      this.$modal.hide();
    },
  },
});


<copyright>
  File: kbArticleModal.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

modal(
  :name="modalName",
  :closeButton="true",
  :size="20",
  :data-name="$name()",
  data-qa="support-kb-article-modal",
  @hide="flush"
)
  template(slot="header") {{ title }}
  template(slot="content")
    loader(:updating="loading")
      .height-35x
        iframe(v-if="url", :src="iframeUrl", @load="onFrameReady")
  template(slot="footer")
    btn(
      color="green-stroke",
      :data-name="$name('button-helped')",
      @click="solutionHelped",
      data-qa="solution-helped-button"
    ) {{ $t('Solution Helped') }}
    .pull-left
      btn(
        @click="onOpenInNewTabClick"
        :href="directLinkUrl",
        target="_blank",
        color="white",
        :data-name="$name('button-open-in-new-tab')",
        data-qa="open-in-new-tab-button"
      ) {{ $t('Open in New Tab') }}

</template>

<script>

import { buildQuery } from '@core/common/url';
import RtFeedbackRequest from '@/api/rt/rtFeedbackRequest';
import KbSearchRequest from '@/api/kb/kbSearchRequest';

import Vue from 'vue';
import { EnvDependentLink, getEnvDependentLink } from '@/data/links';

export default Vue.extend({
  name: 'support-kb-article-modal',
  props: {
    searchText: {
      type: String,
    },
  },
  data () {
    return {
      loading: false,
      url: undefined,
      id: undefined,
      title: undefined,
      modalName: 'kbArticleContent',
      track: false,
    };
  },
  methods: {
    async init (params) {
      if (params.iframeUrl) {
        this.url = params.iframeUrl.replace(/^http[^/]+/, '');
        this.title = params.title;
        if (this.track) {
          this.$trackEvent({
            category: 'Support',
            name: 'KB click',
            source: params.id,
          });
        }
      } else {
        await this.load();
      }
    },

    async show (params) {
      this.track = !!params.track;
      this.id = params.id;
      await this.init(params);
      this.loading = true;
      this.$modal.show(this.modalName);
    },

    onFrameReady () {
      this.loading = false;
    },

    flush () {
      this.url = undefined;
      this.title = undefined;
    },

    solutionHelped () {
      if (this.track) {
        this.$trackEvent({
          category: 'Support',
          name: 'Solution helped',
          source: this.id,
        });
      }

      const request = new RtFeedbackRequest({
        kb_link: this.directLinkUrl,
        q: this.searchText || 'FAQ',
      });
      this.$api.call(request);
      this.$modal.hide(this.modalName);
      this.$toast.show({
        text: this.$t('Thank you!'),
      });
    },

    async load () {
      const request = new KbSearchRequest({
        language: this.$appData.session.language,
        id: this.id,
      });

      await this.$api.call(request);
      const article = request.articles[0];
      article && await this.init(article);
    },

    onOpenInNewTabClick () {
      if (this.track) {
        this.$trackEvent({
          category: 'Support',
          name: 'KB tab click',
          source: this.id,
        });
      }
    },
  },

  computed: {
    iframeUrl () {
      /**
       *  KB stylesheet need to be generated by sass and placed to static repo, run from My Frontend root:
       *
       *  ./node_modules/.bin/node-sass --include-path ./node_modules/ui-kit/src/theme src/ui/kb.sass > ${PATH_TO_STATIC_REPO}/assets/kb/style.css
       *
       */
      const staticUrl = getEnvDependentLink(this, EnvDependentLink.STATIC_URL);
      const assetsRoot = `${staticUrl}/assets/kb`;
      const query = buildQuery(['script', 'style'], {
        style: `${assetsRoot}/style.css`,
        script: `${assetsRoot}/script.js`,
      });

      return this.url + query;
    },
    directLinkUrl () {
      return `${this.$appData.session.kbUrl}/${this.id}`;
    },
  },
});

</script>

/**
 * File: ssoUsersUsageRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import CombinedApiRequest from '@core/api/combinedApiRequest';
import ApiRequest, { Method } from '@core/api/apiRequest';
import SSOCompanyUser, { SSOHostInfo, SSOUserUsage } from '@core/models/ssoCompanyUser';
import Service from '@core/constants/services';
import { PRODUCT_KEY_NAME_PDB_PER_USER, PRODUCT_KEY_NAME_PTBB_PER_USER } from '@core/constants/subscriptions';

const REQ_NAME_PD_USAGE = 'pd';
const REQ_NAME_PTB_USAGE = 'ptb';

interface BaseSSOUsersUsageRequestParams {
  users: SSOCompanyUser[];
}

abstract class BaseSSOUsersUsageRequest extends ApiRequest<BaseSSOUsersUsageRequestParams> {
  protected readonly abstract _url: string;

  get url (): string {
    return `${this._url}${this.buildQueryString({ users: this.params.users.map(user => user.uuid).join(',') })}`;
  }
}

export class PDSSOUsersUsageRequest extends BaseSSOUsersUsageRequest {
  protected _url = `/${Service.Desktop}/api/v1/sso/users`;
}

export class PTBSSOUsersUsageRequest extends BaseSSOUsersUsageRequest {
  protected _url = `/${Service.Toolbox}/api/v1/sso/users`;
}

export default class SSOUsersUsageRequest extends CombinedApiRequest {
  public requestUsers: SSOCompanyUser[];

  constructor (params) {
    super();
    this.addRequest(REQ_NAME_PD_USAGE, new PDSSOUsersUsageRequest(params));
    this.addRequest(REQ_NAME_PTB_USAGE, new PTBSSOUsersUsageRequest(params));
    this.requestUsers = params.users;
  }

  combineData (data): Array<SSOUserUsage> {
    const usages: SSOUserUsage[] = [];
    this.requestUsers.forEach((user) => {
      const pdData = data[0].hosts.find(obj => obj.uuid === user.uuid);
      const ptbData = data[1].hosts.find(obj => obj.uuid === user.uuid);
      usages.push(new SSOUserUsage({
        uuid: user.uuid,
        pdPerUserLicenseRevocationPeriod: data[0].per_user_automatic_license_revocation,
        ptbPerUserLicenseRevocationPeriod: data[1].per_user_automatic_license_revocation,
        pdHosts: pdData ? pdData.licensed_hosts.map((hostData) => new SSOHostInfo(hostData)) : [],
        ptbHosts: ptbData ? ptbData.licensed_hosts.map((hostData) => new SSOHostInfo(hostData)) : [],
      }));
    });
    return usages;
  }
}

interface SvcRevokeSSOLicenseRequestParams {
  service: Service;
  ssoUsersIds: string[];
}

export class SvcRevokeSSOLicenseRequest extends ApiRequest<SvcRevokeSSOLicenseRequestParams> {
  get method (): Method {
    return 'DELETE';
  }

  get url (): string {
    return `/${this.params.service}/api/v1/sso/users` + this.buildQueryString({ users: this.params.ssoUsersIds.join(',') });
  }
}

interface RevokeSSOLicenseRequestParams {
  products: string[];
  ssoUsersIds: string[];
}

const PRODUCT_TO_SERVICE = {
  [PRODUCT_KEY_NAME_PDB_PER_USER]: Service.Desktop,
  [PRODUCT_KEY_NAME_PTBB_PER_USER]: Service.Toolbox,
};

export class RevokeSSOLicenseRequest extends CombinedApiRequest {
  constructor (params: RevokeSSOLicenseRequestParams) {
    super();
    for (const product of params.products) {
      this.addRequest(product, new SvcRevokeSSOLicenseRequest({ service: PRODUCT_TO_SERVICE[product], ssoUsersIds: params.ssoUsersIds }));
    }
  }
}

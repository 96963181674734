/**
 * File: ordersHistoryModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { CHANNEL_NAMES, PRODUCT_KEY_NAME_PSW, PRODUCT_KEY_NAME_RAS } from '@core/constants/subscriptions';
import { dateValue } from '@core/common/format/date';
import ComponentMixIn from '@/modules/componentMixIn';
import OrdersRequest from '@/api/ordersRequest.js';
import RasSubscriptionBillingPeriodsRequest from '@/api/rasSubscriptionBillingPeriodsRequest';
import Vue, { PropType } from 'vue';
import Subscription from '@/models/subscription';

/*
  Compare by currency and by amount
 */
function compareOrdersByPrice (a, b) {
  if (a.currency !== b.currency) {
    a = a.currency;
    b = b.currency;
  } else {
    a = parseFloat(a.amount);
    b = parseFloat(b.amount);
  }
  return a > b ? 1 : (a < b ? -1 : 0);
}

/*
  Compare by status and by operation
 */
function compareOrdersByOperation (a, b) {
  if (a.status !== b.status) {
    a = a.status;
    b = b.status;
  } else {
    a = a.description;
    b = b.description;
  }
  return a > b ? 1 : (a < b ? -1 : 0);
}

export default Vue.extend({
  name: 'orders-history-modal',

  mixins: [ComponentMixIn],

  props: {
    subscription: {
      type: Object as PropType<Subscription>,
    },
  },

  data () {
    return {
      loading: false,
      tableOptions: {
        sortable: ['date', 'description', 'licenses', 'channel', 'price'],
        customSorting: {
          price (asc) {
            return (a, b) => asc ? compareOrdersByPrice(a, b) : compareOrdersByPrice(b, a);
          },
          description (asc) {
            return (a, b) => asc ? compareOrdersByOperation(a, b) : compareOrdersByOperation(b, a);
          },
        },
      },
      orders: [],
    };
  },

  methods: {
    show (): void {
      this.load();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
      this.$emit('show');
    },

    load () {
      const request = new OrdersRequest({
        uuid: this.subscription.uuid,
        service: this.subscription.targetServiceName,
      });

      this.loading = true;

      return this.$api.authorizedCall(request).then((data) => {
        this.orders = request.getData(this.subscription);
        if (this.isRasSPLA) {
          return this.$api.authorizedCall(new RasSubscriptionBillingPeriodsRequest({ uuid: this.subscription.uuid }));
        }
      }).then((data) => {
        // Set peak usages for orders
        if (data) {
          this.orders.forEach((order) => {
            const period = data.find((period) => dateValue(period.endedAt) === dateValue(order.payedAt));
            order.peakUsage = period ? period.totalUsage : undefined;
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },

    getSalesChannelName (channel): string {
      return CHANNEL_NAMES.hasOwnProperty(channel) ? $t(CHANNEL_NAMES[channel]) : channel;
    },
  },

  computed: {
    columns (): string[] {
      return [
        'date',
        'description',
        'licenses',
        'channel',
        ...((this.subscription.isBytebotStore || this.subscription.hasProduct(PRODUCT_KEY_NAME_PSW)) ?
          [] :
          ['price']),
        'order',
        'invoice',
      ];
    },

    isRasSPLA (): boolean {
      return this.subscription && this.subscription.isPostPaid && this.subscription.hasProduct(PRODUCT_KEY_NAME_RAS);
    },

    isSPLA (): boolean {
      return this.subscription && this.subscription.isPostPaid;
    },
  },
});

/**
 * File: resourceUsageNotificationDeactivateRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';

export type ResourceUsageNotificationDeactivateRequestParams = {
  subscriptionUuid: string;
};

export default class ResourceUsageNotificationDeactivateRequest extends ApiRequest<ResourceUsageNotificationDeactivateRequestParams> {
  get method (): Method {
    return 'PUT';
  }

  get url (): string {
    return `/ras/webapp/subscription/usage_notification/${this.params.subscriptionUuid}/deactivate`;
  }

  async load () {
    const result = await super.load();
    this.dropFullCache();
    return result;
  }
}

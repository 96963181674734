/**
 * File: getPdBusinessSubscriptionInviteRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class GetPdBusinessSubscriptionInviteRequest extends ApiRequest {
  get url () {
    return `/desktop/api/v1/ba/subscriptions/${this.params.subscriptionUuid}/invite`;
  }
}

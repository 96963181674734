

import Vue from 'vue';
import { required } from '@core/common/validators';

export interface IssueSummaryModalParams {
  summary: string;
  supportOption: string;
}

export default Vue.extend({
  name: 'issue-summary-modal',

  data () {
    return {
      summary: '',
      supportOption: null,
    };
  },

  validations: {
    summary: { required },
  },

  methods: {
    show (options: IssueSummaryModalParams) {
      this.supportOption = options.supportOption;
      this.summary = options.summary;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
    },

    flush () {
      this.supportOption = null;
      this.summary = null;
    },

    close () {
      this.flush();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },

    nextButtonHandler () {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        severity: 3,
        supportOption: this.supportOption,
        summary: this.summary,
      });
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
      this.flush();
    },
  },
});




import Vue, { PropType } from 'vue';
import {
  minLength,
  maxLength,
  required as requiredValidator
} from 'vuelidate/lib/validators';
import { formatBytes } from '@core/common/format/size';
import { sortByValue } from '@core/common/sorters';
import {
  RESOURCE_PDB_BY_SUBSCRIPTION_KEY_NAME,
  RESOURCE_PMM_BY_SUBSCRIPTION_KEY_NAME,
  RESOURCE_RAS_PERMANENT_KEY_NAME,
  RESOURCE_RAS_STD_BY_SUBSCRIPTION_KEY_NAME
} from '@core/constants/subscriptions';
import { ACTION_REOPEN } from '@/modules/support/constants';
import TicketMixIn from '@/modules/support/ticketsMixIn';
import ComponentMixIn from '@/modules/componentMixIn';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import RtReplyRequest from '@/api/rt/rtReplyRequest';
import RtAddWatcherRequest from '@/api/rt/addWatcherRequest';
import RtDeleteWatcherRequest from '@/api/rt/deleteWatcherRequest';
import RtSendAttachmentsRequest from '@/api/rt/rtSendAttachmentsRequest';
import RtEscalateTicketRequest from '@/api/rt/rtEscalateTicketRequest';
import RtGetTicketRequest from '@/api/rt/rtGetTicketRequest';
import RtUpdateTicketRequest from '@/api/rt/rtUpdateTicketRequest';
import CompanyUsersRequest from '@/api/companyUsersRequest';
import TicketContent from './ticketContent.vue';
import Ticket, { TicketStatus } from '@/models/ticket';
import Subscription from '@/models/subscription';
import CompanyUser from '@/models/companyUser';

const RESOURCES_FOR_SEND_COPY = [
  RESOURCE_PDB_BY_SUBSCRIPTION_KEY_NAME,
  RESOURCE_PMM_BY_SUBSCRIPTION_KEY_NAME,
  RESOURCE_RAS_PERMANENT_KEY_NAME,
  RESOURCE_RAS_STD_BY_SUBSCRIPTION_KEY_NAME,
];

interface TransactionInfo {
  name: string;
  content: string;
  created: string;
  preview?: string;
}

export default Vue.extend({
  name: 'support-ticket-modal',

  mixins: [
    ComponentMixIn,
    TicketMixIn,
  ],

  components: { TicketContent },

  props: {
    subscriptions: {
      type: Array as PropType<Subscription[]>,
      required: true,
    },
  },

  data () {
    return {
      STATUS_CLOSED: TicketStatus.closed,
      ticket: null as Ticket,
      loading: false,
      message: '', // TODO Watch and save the message in the cookie to prevent the window closing case
      ticketId: null as string,
      actions: [] as string[],
      summary: '',
      sendCopy: false,
      status: '',
      files: [] as { name: string; size: number }[],
      titleMaxLength: 60,
      transactions: [] as TransactionInfo[],
      expandedItem: null as number,
      maxFilesSize: 20971520, // 20 Mb
      formatBytes,
      tab: 'general',
      watchersPool: [] as CompanyUser[],
      author: {} as CompanyUser,
      currentUser: {} as CompanyUser,
      selectedUser: null as number,
      currentWatchersEmails: [] as string[],
      ticketAuthorEmail: null as string,
      productKey: '',
      ticketWatchers: [] as CompanyUser[],
      defaultWatchers: [] as CompanyUser[],
    };
  },

  validations: {
    message: {
      required: requiredValidator,
      minLength: minLength(5),
      maxLength: maxLength(2000),
    },
  },

  methods: {
    show (ticketId) {
      this.reset(ticketId);

      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
      this.$nextTick(() => {
        this.load();
      });
      this.$trackEvent({
        category: 'Support',
        name: 'Ticket details opened',
      });
    },

    async load () {
      this.loading = true;

      const request = new RtGetTicketRequest({ ticketId: this.ticketId });
      try {
        await this.$api.callRt(request);
        const ticket = this.ticket = request.data;
        if (this.isBusinessMode) {
          this.loadUsers();
        }

        const fields = ticket.fields;
        this.summary = fields.summary;
        this.status = fields.status;
        this.currentWatchersEmails = fields.watchers;
        this.ticketAuthorEmail = fields.author;
        this.productKey = fields.productKey;

        this.transactions = [{
          name: this.$t('Ticket created'),
          content: fields.description,
          created: fields.created,
          preview: undefined as string,
        }].concat(fields.transactions.filter((item) => {
          return item.name && (item.content || item.link || item.details);
        })).map((item) => {
          if (item.content) {
            item.preview = item.content.replace(/\s+/g, ' ').slice(0, 60);
          }
          return item;
        });
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          this.scrollToEnd();
        });
      }
    },

    loadUsers () {
      const companyUsersRequest = new CompanyUsersRequest({}, this.$appData.session.businessDomainId);
      this.loading = true;
      return this.$api.authorizedCall(companyUsersRequest).then((data) => {
        const companyUsers = companyUsersRequest.getUsers();
        this.author = companyUsers.find((user) => this.ticketAuthorEmail === user.email);
        this.currentUser = companyUsers.find((user) => this.$appData.session.email === user.email);
        if (this.currentUser.isAdmin) {
          this.watchersPool = companyUsers.filter((user) => user.email !== this.currentUser.email && user.email !== this.ticketAuthorEmail);
        } else {
          this.watchersPool = companyUsers.filter((user) => user.email !== this.currentUser.email && user.email !== this.ticketAuthorEmail && user.isAdmin);
        }

        const ticketWatchers = this.watchersPool.filter((watcher) => this.currentWatchersEmails.includes(watcher.email));
        this.$set(this, 'ticketWatchers', ticketWatchers);
      }).finally(() => {
        this.loading = false;
      });
    },

    setTab (params) {
      this.tab = params.tab;
    },

    listItemColor (user) {
      if (user.isAdmin && !this.canManageTicket) return 'muted';
      return 'default';
    },

    addWatchers () {
      const user = this.watchersPool.find((u) => u.uuid === this.selectedUser);

      if (user) {
        const request = new RtAddWatcherRequest({ ticketId: this.ticketId, watcher: user.email, domain_id: this.$appData.session.businessDomainId });
        this.loading = true;
        this.$api.callRt(request)
          .then((data) => {
            this.selectedUser = null;
            this.ticketWatchers.push(user);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    removeWatcher (userUid) {
      const i = this.ticketWatchers.findIndex((usr) => usr.uuid === userUid);
      const user = this.ticketWatchers.find((usr) => usr.uuid === userUid);
      const request = new RtDeleteWatcherRequest({ ticketId: this.ticketId, watcher: user.email, domain_id: this.$appData.session.businessDomainId });
      this.loading = true;
      this.$api.callRt(request)
        .then((data) => {
          this.ticketWatchers.splice(i, 1);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reply () {
      this.scrollToEnd();

      this.$v.$touch();

      if (this.$v.$invalid || this.filesLimitReached) {
        return;
      }

      const request = new CombinedApiRequest();

      request.addRequest('text', new RtReplyRequest({ ticketId: this.ticketId, text: this.message }));
      if (this.files.length > 0) {
        request.addRequest('attachment', new RtSendAttachmentsRequest({ ticketId: this.ticketId, files: this.files }));
      }
      if (this.sendCopy) {
        request.addRequest('escalate', new RtEscalateTicketRequest({ ticketId: this.ticketId, comment: this.message }));
      }

      this.loading = true;
      this.$api.callRt(request)
        .then(() => {
          this.$nextTick(() => {
            this.$emit('reply', this.ticketId);
          });
          this.showToast(this.$t('Thank you for your request. Our team will contact you soon.'));
          this.close();
        })
        .finally(() => {
          this.loading = false;
          this.$trackEvent({
            category: 'Support',
            name: 'Reply sent',
          });
          if (this.sendCopy) {
            this.$trackEvent({
              category: 'Support',
              name: 'Escalation enabled',
            });
          }
        });
    },

    close () {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },

    reset (ticketId: string) {
      this.message = '';
      this.files = [];
      this.transactions = [];
      this.productKey = '';
      this.expandedItem = null;
      this.sendCopy = false;
      this.ticketId = ticketId;
      this.summary = this.$t('Loading ticket...');
      this.$v.$reset();
    },

    addAttach (fileLst) {
      const fileNames = this.files.map((f) => { return f.name; });
      Array.prototype.forEach.call(fileLst, (f) => {
        if (fileNames.indexOf(f.name) < 0) {
          this.files.push(f);
        }
      });
    },

    removeAttach (file) {
      const idx = this.files.indexOf(file.name);

      this.files.splice(idx, 1);
    },

    onTransactionExpand (index, value) {
      if (value === true) {
        this.expandedItem = index;
      }
    },

    closeTicket () {
      this.loading = true;
      this.$api.callRt(new RtUpdateTicketRequest({ ticketId: this.ticketId, status: 'resolved' }))
        .then(() => {
          this.$emit('ticketClosed', this.ticketId);
          this.showToast(this.$t('Thank you! We`re always here to help.', { ticketId: this.ticketId }));
          this.close();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    reopenTicket () {
      this.loading = true;
      this.$api.callRt(new RtUpdateTicketRequest({ ticketId: this.ticketId, status: 'open' }))
        .then(() => {
          this.$emit('ticketReopened', this.ticketId);
          this.showToast(this.$t('Ticket {ticketId} reopened.', { ticketId: this.ticketId }));
          this.close();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    scrollToEnd () {
      const container = this.$el.querySelector('#history-content');
      container.scrollTop = container.scrollHeight;
    },
  },

  computed: {
    rowsCount (): number {
      const MAX_ROWS_COUNT = 8;
      const BORDER = 4;
      const MIN_ROWS_COUNT = 4;
      if (this.transactions.length >= BORDER) {
        return MIN_ROWS_COUNT;
      } else {
        return Math.max(MAX_ROWS_COUNT - this.transactions.length, MIN_ROWS_COUNT);
      }
    },
    header (): string {
      if (this.ticketId && this.summary) {
        return '#' + this.ticketId + ' – ' + this.summary;
      } else if (this.ticketId) {
        return this.$t('Loading ticket {ticketId}...', { ticketId: this.ticketId });
      }
      return this.$t('Loading ticket...');
    },

    statusColor (): string {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.ticket && this.getStatusColor(this.ticket);
    },

    statusName (): string {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.ticket && this.getStatusName(this.ticket);
    },

    totalFileSize (): number {
      let totalSize = 0;

      this.files.forEach((f) => { totalSize += f.size; });

      return totalSize;
    },

    filesLimitReached (): boolean {
      return this.totalFileSize >= this.maxFilesSize;
    },

    userOptions (): Record<string, any>[] {
      const presentUsers = this.ticketWatchers.reduce((res, user) => ({ [user.uuid]: user, ...res }), {});
      return this.watchersPool
        .filter(user => !presentUsers[user.uuid])
        .map(user => ({
          value: user.uuid,
          ...user,
        })).sort((a, b) => sortByValue(a.name.toLowerCase(), b.name.toLowerCase(), true));
    },

    getTabs (): { general: string; watchers: string } {
      const tabs = {
        general: this.$t('General'),
        watchers: undefined as string,
      };

      if (this.isBusinessMode) {
        tabs.watchers = this.$t('Ticket Watchers');
      }
      return tabs;
    },

    isBusinessMode (): boolean {
      return this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain);
    },

    isCurrentUserTicketAuthor (): boolean {
      return this.currentUser && this.currentUser.email === this.ticketAuthorEmail;
    },

    canManageTicket (): boolean {
      return this.currentUser && this.isCurrentUserTicketAuthor || this.currentUser.isAdmin;
    },

    canReopenTicket (): boolean {
      return this.actions.includes(ACTION_REOPEN);
    },

    watchers (): CompanyUser[] {
      return this.ticketWatchers.slice(0).sort((a, b) => sortByValue(a.email.toLowerCase(), b.email.toLowerCase(), true));
    },

    canSendCopyToManager (): boolean {
      return !this.$appData.session.isPersonalUser(this.$appData.userInDomain) && this.subscriptions.some((subscription) => {
        return subscription.products.find((product) => RESOURCES_FOR_SEND_COPY.includes(product.keyName));
      });
    },
  },
});


/**
 * File: getRedirectLinkRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class GerRedirectLinkRequest extends ApiRequest {
  get url () {
    const uri = '/account/webapp/get_redirect_url';

    const qs = this.buildQueryString(this.params);

    return qs ? uri + qs : uri;
  }
}

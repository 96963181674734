

import Vue from 'vue';
import ConfirmAccountRequest from '@/api/confirmAccountRequest';
import ConfirmBrowserRequest from '@/api/confirmBrowserRequest';
import { LOGIN_PAGE } from '@/routes/constants';

export default Vue.extend({
  name: 'confirm-account',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      error: false,
      trustThisBrowser: true,
      trustBrowserCode: undefined,
      saved: false,
    };
  },
  async created () {
    const confirmAccountRequest = new ConfirmAccountRequest({ code: this.code });

    this.loading = true;

    try {
      const response = await this.$api.call(confirmAccountRequest);
      this.trustBrowserCode = response.actionCode;
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    proceed () {
      if (this.saved) {
        this.signIn();
        return;
      }
      if (this.trustThisBrowser) {
        this.markBrowserAsTrusted();
      } else {
        this.signIn();
      }
    },
    signIn () {
      if (this.error) {
        this.$router.push({ name: LOGIN_PAGE });
        return;
      }
      this.$toast.show({
        text: this.$t('The status of this web browser has been saved.'),
      });
      this.saved = true;
    },
    async markBrowserAsTrusted () {
      const confirmBrowserRequest = new ConfirmBrowserRequest({ code: this.trustBrowserCode });

      this.loading = true;

      try {
        await this.$api.call(confirmBrowserRequest);
        this.signIn();
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
});


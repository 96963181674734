/**
 * File: confirmEmailChangeBase.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export class ConfirmEmailChangeBaseGet extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.actionCode
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }
}

export class ConfirmEmailChangeBaseConfirm extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.actionCode
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {};
  }

  get jsonData () {
    return null; // no response data is expected
  }
}

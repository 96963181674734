/**
 * File: resendDeleteProfileEmailRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import resendEmailErrors from './errorHandlers/resendEmailErrors';

export default class ResendDeleteProfileEmailRequest extends ApiRequest {
  get url () {
    return '/account/api/v1/profile/delete/resend_mail';
  }

  get method () {
    return 'POST';
  }

  get errors () {
    return resendEmailErrors();
  }
}


import Vue from 'vue';

import { PRODUCT_KEY_NAME_DAAS, PRODUCT_NAME_DAAS } from '@core/constants/subscriptions';

import Subscription, { STATUS_ACTIVE } from '@/models/subscription';

import { support as supportLinks } from '@core/constants/links';

import SubscriptionsRequest from '@/api/subscriptionsRequest';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductPageMixIn from '@/modules/productPageMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import SubscriptionNotification from '@/modules/subscriptions/details/notification/index.vue';
import SubscriptionDetailsContent from '@/modules/subscriptions/details/subscriptionDetailsContent.vue';
import SubscriptionsContent from '@/modules/subscriptions/list/subscriptionsContent.vue';
import AddLicensesModal from '@/modules/addLicenses/addLicensesModal.vue';
import DownloadsContent from '@/modules/downloads/downloadsContent.vue';
import {
  DAAS_DOWNLOADS_PAGE,
  DAAS_SUBPAGE_SUBSCRIPTION_DETAILS,
  DAAS_SUBPAGE_SUBSCRIPTIONS,
  GET_SUPPORT_PAGE
} from '@/routes/constants';
import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';
import { SUBNAVIGATION_SOURCE } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'daas-page',

  props: ['content'],

  mixins: [
    ComponentMixIn,
    ProductPageMixIn,
    SearchFilterMixin,
    BreadcrumbsMixin,
  ],

  components: {
    SubscriptionNotification,
    SubscriptionsContent,
    SubscriptionDetailsContent,
    DownloadsContent,
    AddLicensesModal,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  data () {
    return {
      supportLinks,
      subscriptions: [],
      displayNotification: true,
    };
  },

  created () {
    this.load();
  },

  methods: {
    load () {
      const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS });
      this.authorizedCall(request)
        .then(() => {
          this.subscriptions = request.getSubscriptions().filter((s: Subscription) => s.hasProduct(PRODUCT_KEY_NAME_DAAS));
        });
    },
  },

  computed: {
    productName () {
      return PRODUCT_NAME_DAAS;
    },
    subscriptionsAmount (): number {
      return this.subscriptions.length;
    },
    isSubscriptionsPage (): boolean {
      return this.content === DAAS_SUBPAGE_SUBSCRIPTIONS;
    },
    isDownloadsPage (): boolean {
      return this.content === DAAS_DOWNLOADS_PAGE;
    },
    isSubscriptionDetailsPage (): boolean {
      return this.content === DAAS_SUBPAGE_SUBSCRIPTION_DETAILS;
    },
    subpages (): MenuItem[] {
      const links = [];
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
      const downloadLink = this.getDownloadRoute();
      if (this.$appData.session.isBusinessAdmin) {
        links.push({
          text: this.$t('Management Portal'),
          click: () => {
            window.open(this.$appData.session.daasAdminPortalUrl, '_blank');
          },
        }, {
          text: this.$t('User Portal'),
          click: () => {
            window.open(this.$appData.session.daasUserPortalUrl, '_blank');
          },
        });
      }
      links.push(
        {
          text: this.$t('Download'),
          // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16431
          link: downloadLink,
        },
        {
          text: this.$t('Product Subscriptions ({amount})', { amount: this.subscriptionsAmount }),
          link: { name: DAAS_SUBPAGE_SUBSCRIPTIONS, query: { display: STATUS_ACTIVE, source: SUBNAVIGATION_SOURCE } },
        },
        {
          text: this.$t('Support'),
          click: () => {
            if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
              const params = {
                category: 'Support',
                name: 'Support button click in menu',
              };
              this.$collectGaStats({ ...params, source: document.title });
              this.$collectStats({ ...params, source: PRODUCT_KEY_NAME_DAAS });
              this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_DAAS } });
            } else {
              window.location.href = this.supportLinks[PRODUCT_KEY_NAME_DAAS];
            }
          },
        }
      );
      return links;
    },
  },
});

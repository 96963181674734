
import ProductCardMixIn from './productCardMixIn';
import { PRODUCT_NAME_PSW } from '@core/constants/subscriptions';
import { PSW_DOWNLOADS_PAGE, PSW_SUBPAGE_SUBSCRIPTIONS } from '@/routes/constants';
import Vue from 'vue';
import exchangeOldKeysModal from '@/modules/home/dashboard/modal/exchangeOldKeysModal.vue';
import { TYPE_ALL, TYPE_PERPETUAL, TYPE_SPLA, TYPE_SUBSCRIPTION } from '@/models/subscription';

export default Vue.extend({
  name: 'product-card-psw',
  mixins: [ProductCardMixIn],
  components: { exchangeOldKeysModal },
  props: ['subscriptions'],
  methods: {
    onExchangeOldKeysClick (): void {
      this.$modal.show(exchangeOldKeysModal);
    },
  },
  data () {
    return {
      PSW_DOWNLOADS_PAGE,
      PSW_SUBPAGE_SUBSCRIPTIONS,
      PRODUCT_NAME_PSW,
      TYPE_SUBSCRIPTION,
      TYPE_PERPETUAL,
      TYPE_SPLA,
      TYPE_ALL,
    };
  },
});


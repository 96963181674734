/**
 * File: index.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import Vue from 'vue';
import OneTimePasswordCell from './one-time-password-cell/index.vue';

export const OTP_RC_LENGTH = 8;
export const OTP_OTP_LENGTH = 6;
export const OTP_TYPE_RC = 'resque-code';
export const OTP_TYPE_OTP = 'one-time-password';
export const OTP_EVENT_INPUT = 'input';
export const OTP_REGEX = /[\d\w]/g;

interface IOneTimePasswordData {
  activeIndex: number;
  codeLength: 6 | 8;
  input: string;
  otp: string[];
  prevOtp: string[];
}

export default Vue.extend({
  name: 'one-time-password',
  components: {
    OneTimePasswordCell,
  },
  props: {
    id: String,
    name: String,
    type: {
      type: String,
      default: OTP_TYPE_OTP,
    },
    value: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Object,
    },
  },
  data (): IOneTimePasswordData {
    return {
      activeIndex: 0,
      codeLength: this.type === OTP_TYPE_RC ? OTP_RC_LENGTH : OTP_OTP_LENGTH,
      input: '',
      otp: [],
      prevOtp: [],
    };
  },
  watch: {
    value (newValue: string) {
      if (newValue !== [...this.otp].join('')) {
        this.update(newValue);
      }
    },
    type (newType: string) {
      this.codeLength = newType === OTP_TYPE_RC ? OTP_RC_LENGTH : OTP_OTP_LENGTH;
      this.update(this.input);
    },
  },
  created () {
    if (this.value) {
      this.update(this.value);
    }
  },
  mounted () {
    if (this.value && this.validator && this.validator.$invalid) {
      this.validator.$touch();
    }
  },
  computed: {
    hasErrors (): boolean {
      return this.validator.$error;
    },
  },
  methods: {
    focusInput (index: number) {
      this.activeIndex = Math.max(Math.min(this.codeLength - 1, index), 0);
    },
    focusNextInput () {
      this.focusInput(this.activeIndex + 1);
    },
    focusPrevInput () {
      this.focusInput(this.activeIndex - 1);
    },
    update (value: string) {
      const otp = value.slice(0, this.codeLength);
      this.input = value;
      this.otp = [...otp];
      this.$emit(OTP_EVENT_INPUT, otp);
    },
    changeCodeAtFocus (value?: string) {
      this.prevOtp = Object.assign([], this.otp);
      this.otp.splice(this.activeIndex, 1, value);
      this.input = this.otp.join('');
      if (this.prevOtp.join('') !== this.input) {
        this.$emit(OTP_EVENT_INPUT, this.input);
      }
    },
    onFocus (index: number) {
      this.activeIndex = index;
    },
    onBlur () {
      this.activeIndex = -1;
    },
    onPaste (event) {
      const pastedData = event.clipboardData.getData('text/plain').replace(/_/g, '').match(OTP_REGEX);
      this.otp = this.otp.slice(0, this.activeIndex).concat(pastedData);
      this.input = this.otp.join('');
      this.focusInput(this.otp.length);
      this.update(this.input);
    },
    onChange (value: string) {
      this.changeCodeAtFocus(value);
      this.focusNextInput();
    },
    onDelete () {
      this.changeCodeAtFocus();
      this.focusPrevInput();
    },
  },
});

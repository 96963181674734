<copyright>
File: socialLoginErrors.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import { appData } from '@/data/appData';
import cookie from 'js-cookie';
import ContactSupportModal, { SOCIAL_LOGIN_SCENARIO } from '@/modules/contactSupportModal';
import SignInPage from '@/modules/login';

export default {
  name: 'social-login-errors',
  components: { SignInPage, ContactSupportModal },
  props: ['type', 'result'],

  data () {
    return {
      appData,
      email: cookie.get('email'),
      SOCIAL_LOGIN_SCENARIO,
    };
  },

  mounted () {
    this.showModal('modal');
  },

  methods: {
    closeHandler () {
      this.hideModal();
      this.$router.replace({ name: 'login' });
    },
    showContactSupportModal () {
      this.$modal.show(ContactSupportModal);
    },
  },
};
</script>

<template lang="pug">

div
  sign-in-page
  modal(ref="modal", :size="14", :data-name="$name('modal-authentication')", :closeButton="type === 'ap' && result === 'missing_grants'")

    template(v-if="type === 'ap'", slot="header")
      span(v-if="['general_error', 'try_again', 'ba_denied', 'invalid_email', 'interrupted'].indexOf(result) >= 0")
        | {{ $t('Cannot Sign In with Apple') }}
      span(v-else-if="result === 'missing_grants'")
        | {{ $t('Authentication Problem') }}
      span(v-else)
        | {{ $t('Authentication Through Apple') }}

    template(v-if="type === 'gp'", slot="header")
      | {{ $t('Authentication Through Google+') }}

    template(v-if="type === 'fb'", slot="header")
      span(v-if="['general_error', 'try_again', 'ba_denied', 'invalid_email'].indexOf(result) >= 0")
        | {{ $t('Cannot Sign In with Facebook') }}
      span(v-else)
        | {{ $t('Authentication Through Facebook') }}

    template(v-if="type === 'ap'", slot="content")
      span(v-if="result === 'confirm' || result === 'unconfirmed'")
        | {{ $t('Authentication through Apple succeeded. An existing Parallels account with matching email address {email} was found. Instructions on how to confirm that this is your account have been sent to that address.', {email}) }}
      span(v-if="result === 'error'")
        | {{ $t('Account ownership has already been confirmed.') }}
      span(v-if="result === 'general_error' || result === 'try_again'")
        | {{ $t('The timeout period has ended. Please, try again.') }}
      span(v-if="result === 'success'")
        | {{ $t('You successfully confirmed ownership of the Parallels account {email}.', {email}) }}
      template(v-if="result === 'ba_denied'")
        div {{ $t('Members of a business account cannot sign in through Apple. Use your business account email and password to sign in.') }}
      span(v-if="result === 'missing_grants'")
        div {{ $t('Could not proceed with Sign In With Apple. Read more to learn how to resolve this issue. Alternatively, you can use your Parallels account email and password or sign-in using Google or Facebook. If the problem persists, contact Parallels Support for assistance.') }}
        div.margin-top
          formatter(:text="$t('Read {link} for details.')")
            a(slot="link", href="https://kb.parallels.com/124747") KB-124747
      span(v-if="result === 'invalid_email'")
        | {{ $t('There`s no email address associated with your Apple account. Please add an email to the Apple account and try again.') }}
      span(v-if="result === 'account_deleted'")
        | {{ $tc('This email address was previously deleted and cannot be reused for at least {n} days while we`re making sure that all data is completely removed from all Parallels services.', 60, {n: 60}) }}
      span(v-if="result === 'interrupted'")
        | {{ $t('Sign-in has been interrupted or canceled. To access your Parallels account next time, please complete the operation.') }}

    template(v-if="type === 'gp'", slot="content")
      span(v-if="result === 'confirm' || result === 'unconfirmed'")
        | {{ $t('Authentication through Google+ succeeded. An existing Parallels account with matching email address {email} was found. Instructions on how to confirm that this is your account have been sent to that address.', {email}) }}
      span(v-if="result === 'error'")
        | {{ $t('Account ownership has already been confirmed.') }}
      span(v-if="result === 'general_error' || result === 'try_again'")
        | {{ $t('The timeout period has ended. Please, try again.') }}
      span(v-if="result === 'success'")
        | {{ $t('You successfully confirmed ownership of the Parallels account {email}.', {email}) }}
      span(v-if="result === 'ba_denied'")
        | {{ $t('Members of a business account cannot sign in through Google+. Use your business account email and password to sign in.') }}
      span(v-if="result === 'account_deleted'")
        | {{ $tc('This email address was previously deleted and cannot be reused for at least {n} days while we`re making sure that all data is completely removed from all Parallels services.', 60, {n: 60}) }}

    template(v-if="type === 'fb'", slot="content")
      span(v-if="result === 'confirm' || result === 'unconfirmed'")
        | {{ $t('Authentication through Facebook succeeded. An existing Parallels account with matching email address {email} was found. Instructions on how to confirm that this is your account have been sent to that address.',  {email}) }}
      span(v-if="result === 'error'")
        | {{ $t('Account ownership has already been confirmed.') }}
      span(v-if="result === 'general_error' || result === 'try_again'")
        | {{ $t('The timeout period has ended. Please, try again.') }}
      span(v-if="result === 'success'")
        | {{ $t('You successfully confirmed ownership of the Parallels account {email}.', {email}) }}
      span(v-if="result === 'ba_denied'")
        | {{ $t('Members of a business account cannot sign in through Facebook. Use your business account email and password to sign in.') }}
      span(v-if="result === 'missing_grants'")
        | {{ $t('To sign in to Parallels with your Facebook account, share the email address associated with the Facebook account.') }}
      span(v-if="result === 'invalid_email'")
        | {{ $t('There`s no email address associated with your Facebook account. Please add an email to the Facebook account and try again.') }}
      span(v-if="result === 'account_deleted'")
        | {{ $tc('This email address was previously deleted and cannot be reused for at least {n} days while we`re making sure that all data is completely removed from all Parallels services.', 60, {n: 60}) }}
      span(v-if="result === 'interrupted'")
        | {{ $t('Sign-in has been interrupted or canceled. To access your Parallels account next time, please complete the operation.') }}

    template(slot="footer")
      btn(color="blue", @click.native="closeHandler", :data-name="$name('button-close')") {{ $t('OK') }}
      btn(v-if="type === 'ap' && result === 'missing_grants'",
        color="white",
        @click="showContactSupportModal",
        :data-name="$name('button-parallels-support')"
      ) {{ $t('Contact Parallels Support') }}

  contact-support-modal(:scenario="SOCIAL_LOGIN_SCENARIO", :displayEmail="true")

</template>

/**
 * File: checkPackagedLicensesGenerationProgressRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export enum PackagedLicensesGenerationStatus {
  Unknown = 'unknown',
  Finished = 'finished',
  InProgress = 'in progress'
}

interface CheckPackagedLicensesGenerationProgressRequestParams {
  trackerId: string;
}

export interface PackagedLicensesGenerationProgressData {
  status: PackagedLicensesGenerationStatus;
  total: number;
  succeeded: number;
  failed: number;
}

export default class CheckPackagedLicensesGenerationProgressRequest extends ApiRequest<CheckPackagedLicensesGenerationProgressRequestParams, PackagedLicensesGenerationProgressData> {
  get url () {
    return `/license/api/v1/packaged_licenses/${this.params.trackerId}`;
  }

  get cacheNameSpace () {
    return undefined;
  }
}

/**
 * File: geo.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import { Dictionary } from '../common/types';

export type State = Dictionary<string>;
export type States = Dictionary<State>;

export const UA_CONFLICT_EMBARGOED_COUNTRIES = ['RU', 'BY'];
export const RESTRICTED_COUNTRIES = { AF: 'Afghanistan', BY: 'Belarus', RU: 'Russia' };

export function sortCountries (countries: State): State {
  return Object.fromEntries(Object.entries(countries).sort(([, a], [, b]) => a > b ? 1 : -1));
}

export function getCountries (): State {
  let countries: State = JSON.parse(sessionStorage.getItem('COUNTRIES') || '{"US": "United States"}');
  return sortCountries(countries);
}

export const US_STATES: State = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AP: 'Armed Forces Pacific',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FM: 'Fed.States of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UM: 'United States Minor Outlying Islands',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const CA_STATES: State = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon'
};

export const DE_STATES: State = {
  BW: 'Baden-W\u00fcrttemberg',
  BY: 'Bavaria',
  BE: 'Berlin',
  BB: 'Brandenburg',
  HB: 'Bremen',
  HH: 'Hamburg',
  HE: 'Hesse',
  NI: 'Lower Saxony',
  MV: 'Mecklenburg-Vorpommern',
  NW: 'North Rhine-Westphalia',
  RP: 'Rhineland-Palatinate',
  SL: 'Saarland',
  SN: 'Saxony',
  ST: 'Saxony-Anhalt',
  SH: 'Schleswig-Holstein',
  TH: 'Thuringia'
};

export const STATES: States = {
  US: US_STATES,
  CA: CA_STATES,
  DE: DE_STATES
};

export const LOCALES: State = {
  AF: 'ps-AF',
  AL: 'sq-AL',
  DZ: 'ar-DZ',
  AD: 'ca-AD',
  AO: 'pt-AO',
  AG: 'en-AG',
  AR: 'es-AR',
  AM: 'hy-AM',
  AU: 'en-AU',
  AT: 'de-AT',
  AZ: 'az-AZ',
  BS: 'en-BS',
  BH: 'ar-BH',
  BD: 'bn-BD',
  BB: 'en-BB',
  BY: 'be-BY',
  BE: 'nl-BE',
  BZ: 'en-BZ',
  BJ: 'fr-BJ',
  BT: 'dz-BT',
  BO: 'es-BO',
  BA: 'bs-BA',
  BW: 'en-BW',
  BR: 'pt-BR',
  BN: 'ms-BN',
  BG: 'bg-BG',
  BF: 'fr-BF',
  BI: 'fr-BI',
  KH: 'km-KH',
  CM: 'en-CM',
  CA: 'en-CA',
  CV: 'pt-CV',
  CF: 'fr-CF',
  TD: 'fr-TD',
  CL: 'es-CL',
  CN: 'zh-CN',
  CO: 'es-CO',
  KM: 'ar-KM',
  CG: 'fr-CG',
  CD: 'fr-CD',
  CR: 'es-CR',
  CI: 'fr-CI',
  HR: 'hr-HR',
  CU: 'es-CU',
  CW: 'nl-CW',
  CY: 'el-CY',
  CZ: 'cs-CZ',
  DK: 'da-DK',
  DJ: 'fr-DJ',
  DM: 'en-DM',
  DO: 'es-DO',
  EC: 'es-EC',
  EG: 'ar-EG',
  SV: 'es-SV',
  GQ: 'es-GQ',
  ER: 'ti-ER',
  EE: 'et-EE',
  ET: 'am-ET',
  FJ: 'en-FJ',
  FI: 'fi-FI',
  FR: 'fr-FR',
  GA: 'fr-GA',
  GM: 'en-GM',
  GE: 'ka-GE',
  DE: 'de-DE',
  GH: 'en-GH',
  GR: 'el-GR',
  GD: 'en-GD',
  GT: 'es-GT',
  GN: 'fr-GN',
  GW: 'pt-GW',
  GY: 'en-GY',
  HT: 'fr-HT',
  HN: 'es-HN',
  HK: 'zh-HK',
  HU: 'hu-HU',
  IS: 'is-IS',
  IN: 'hi-IN',
  ID: 'id-ID',
  IR: 'fa-IR',
  IQ: 'ar-IQ',
  IE: 'ga-IE',
  IL: 'he-IL',
  IT: 'it-IT',
  JM: 'en-JM',
  JP: 'ja-JP',
  JO: 'ar-JO',
  KZ: 'kk-KZ',
  KE: 'en-KE',
  KI: 'en-KI',
  KP: 'ko-KP',
  KR: 'ko-KR',
  KW: 'ar-KW',
  KG: 'ky-KG',
  LA: 'lo-LA',
  LV: 'lv-LV',
  LB: 'ar-LB',
  LS: 'en-LS',
  LR: 'en-LR',
  LY: 'ar-LY',
  LI: 'de-LI',
  LT: 'lt-LT',
  LU: 'fr-LU',
  MK: 'mk-MK',
  MG: 'fr-MG',
  MW: 'en-MW',
  MY: 'ms-MY',
  MV: 'dv-MV',
  ML: 'fr-ML',
  MT: 'mt-MT',
  MH: 'en-MH',
  MR: 'ar-MR',
  MU: 'en-MU',
  MX: 'es-MX',
  FM: 'en-FM',
  MD: 'ro-MD',
  MC: 'fr-MC',
  MN: 'mn-MN',
  ME: 'sr-ME',
  MA: 'ar-MA',
  MZ: 'pt-MZ',
  MM: 'my-MM',
  NA: 'en-NA',
  NR: 'en-NR',
  NP: 'ne-NP',
  AN: 'pap-AN',
  NL: 'nl-NL',
  NZ: 'en-NZ',
  NI: 'es-NI',
  NE: 'fr-NE',
  NG: 'en-NG',
  NO: 'no-NO',
  OM: 'ar-OM',
  PK: 'en-PK',
  PW: 'en-PW',
  PA: 'es-PA',
  PG: 'en-PG',
  PY: 'es-PY',
  PE: 'es-PE',
  PH: 'en-PH',
  PL: 'pl-PL',
  PT: 'pt-PT',
  QA: 'ar-QA',
  RO: 'ro-RO',
  RU: 'ru-RU',
  RW: 'rw-RW',
  KN: 'en-KN',
  LC: 'en-LC',
  VC: 'en-VC',
  WS: 'sm-WS',
  SM: 'it-SM',
  ST: 'pt-ST',
  SA: 'ar-SA',
  SN: 'fr-SN',
  RS: 'sr-RS',
  SC: 'fr-SC',
  SL: 'en-SL',
  SG: 'en-SG',
  SK: 'sk-SK',
  SI: 'sl-SI',
  SB: 'en-SB',
  SO: 'so-SO',
  ZA: 'af-ZA',
  ES: 'es-ES',
  LK: 'si-LK',
  SD: 'ar-SD',
  SR: 'nl-SR',
  SZ: 'en-SZ',
  SE: 'sv-SE',
  CH: 'de-CH',
  SY: 'ar-SY',
  TW: 'zh-TW',
  TJ: 'tg-TJ',
  TZ: 'sw-TZ',
  TH: 'th-TH',
  TG: 'fr-TG',
  TO: 'en-TO',
  TT: 'en-TT',
  TN: 'ar-TN',
  TR: 'tr-TR',
  TM: 'tk-TM',
  TV: 'en-TV',
  UG: 'en-UG',
  UA: 'uk-UA',
  AE: 'ar-AE',
  GB: 'en-GB',
  US: 'en-US',
  UY: 'es-UY',
  UZ: 'uz-UZ',
  VU: 'bi-VU',
  VA: 'it-VA',
  VE: 'es-VE',
  VN: 'vi-VN',
  YE: 'ar-YE',
  ZM: 'en-ZM',
  ZW: 'en-ZW'
};

export const DEFAULT_LOCALE = 'en-US';

/**
 * File: apiHelpers.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

export function rasServiceUrl (urlString?: string) {
  if (!urlString) return null;
  return '/ras' + urlString;
}

export function getCurrentDateTime () {
  return new Date().toISOString();
}

export function snakeToCamel (obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => snakeToCamel(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      acc[camelKey] = snakeToCamel(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

export function getChunks<T> (array: T[], chunkSize = 100): T[][] {
  const arrayOfChunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    arrayOfChunks.push(array.slice(i, i + chunkSize));
  }
  return arrayOfChunks;
}

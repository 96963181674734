/**
 * File: rasSubscription.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';
import { RAS_UPGRADED_SUPPORT_TYPES, PRODUCT_KEY_NAME_RAS } from '@core/constants/subscriptions';

export const PERMANENT = 1;
export const PEPAID_SUBSCRIPTION = 2;
export const POSTPAID_SUBSCRIPTIONS = 3;
export const EXTENDED_TRIAL = 4;
export const INITIAL_TRIAL = 5;

type RasHost = {
  name: string;
  hwId: string;
  productVersion: string;
  lastReportedAt: Date;
  activatedAt: Date;
};

export default class RasSubscription extends CoreModel {
  private __name: string;
  mainPeriodEndsAt: Date;
  licenseType: number;
  isOfflineAllowed: boolean;
  upgradedSupport: { type: number; startedAt: Date; endedAt: Date };
  licKey: string;
  isExpired: boolean;
  uuid: string;
  productVersion: string;
  isSublicense: boolean;
  activatedAt: Date;
  gracePeriodEndsAt: Date;
  isAutoRenewable: boolean;
  isNfr: boolean;
  isSuspended: boolean;
  isGracePeriod: boolean;
  isExpiringSoon: boolean;
  isLimited: boolean;
  isOutdated: boolean;
  licenses: number;
  overLimitPeriodDays: number;
  supportType: number;
  supportEndsAt: Date;
  upgradeInsuranceEndsAt: Date;
  nextBillingAt: Date;
  host: RasHost;
  hosts: RasHost[];
  usage: number;
  isDeleted: boolean;
  billingPeriods: [];
  parentKey: string;
  parentUuid: string;

  get name () {
    return this.__name || '';
  }

  get mainPeriodEndsData () {
    return this.mainPeriodEndsAt;
  }

  get isPostpaid () {
    return this.licenseType === POSTPAID_SUBSCRIPTIONS;
  }

  get isPrepaid () {
    return !this.isPostpaid;
  }

  get firstProductKeyName () {
    return PRODUCT_KEY_NAME_RAS;
  }

  get offlineAllowed () {
    return this.isOfflineAllowed;
  }

  get upgradedSupportTypeName () {
    return RAS_UPGRADED_SUPPORT_TYPES[this.upgradedSupport.type];
  }
};

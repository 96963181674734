<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import NotificationSettings from '@/modules/notificationSettings';

export default {
  name: 'adjust-notification-settings',
  components: {
    NotificationSettings,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
    };
  },
  methods: {
    openNotificationSettings () {
      this.loading = true;
      this.$router.push({
        name: 'personalProfile',
        params: {
          page: 'notification',
        },
      });
    },
  },
};

</script>

<template lang="pug">

page(:appData="$appData")
  template(slot="pageContent")
    loader(:loading="loading")
      notification-settings(:actionCode="code", @error="openNotificationSettings")

</template>

/**
 * File: rasHostsPerPeriod.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

interface RasHostsPerPeriodParams {
  periodId: number;
  subscriptionUuids: string[];
}

interface RasHostsResponse {
  'period_id': number;
  'total_hosts': number;
  'total_usage': number;
  'hosts_usages': {
    'host_id': number;
    'max_usage': number;
  }[];
}

export default class RasHostsPerPeriod extends ApiRequest<RasHostsPerPeriodParams, RasHostsResponse> {
  get method () {
    return 'POST' as const;
  }

  get url () {
    return '/ras/api/v2/subscriptions/usage_reports/calculate_max_host_usage';
  }

  get body () {
    return {
      period_id: this.params.periodId,
      subscription_uuids: this.params.subscriptionUuids,
    };
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}

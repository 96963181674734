/**
 * File: restorePurchasesRequest.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class RestorePurchasesRequest extends ApiRequest {
  get url () {
    return `/account/webapp/restore_purchases/${this.params.code}`;
  }

  get method () {
    return 'POST';
  }
}

/**
 * File: bannerNotificationMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';
import Session from '@/models/session';
import md5 from 'blueimp-md5';

export default Vue.extend({
  data () {
    return {
      hidden: true,
      featureKey: '',
      storageKey: '',
      hideOnNextUserLogin: false,
    };
  },
  computed: {
    session (): Session | null {
      return this.$appData.session;
    },
    show (): boolean {
      return this.featureAccessible && this.authenticatedBusinessUser && !this.hidden;
    },
    authenticatedBusinessUser (): boolean {
      return !!(this.session && this.session.getBusinessDomains().length);
    },
    keyName (): string {
      const id = this.session?.personalDomainId;
      return `${this.storageKey}-${id}`;
    },
    featureAccessible (): boolean {
      return !!this.session?.isFeatureAccessible(this.featureKey);
    },
    sameHash (): boolean {
      return this.hash === this.$localStorage.get(this.keyName)?.hash;
    },
    hasHash (): boolean {
      return !!this.$localStorage.get(this.keyName)?.hash;
    },
    hash (): string {
      return md5(this.session?.token);
    },
  },
  methods: {
    hide (hash?: string) {
      const hashValue = this.hideOnNextUserLogin ? md5(this.session.accountId) : hash;
      this.$localStorage.set(this.keyName, { hash: hashValue, hidden: true });
      this.hidden = true;
    },
  },
  watch: {
    '$appData.session.personalDomainId': {
      handler (value) {
        const data = this.$localStorage.get(this.keyName);
        const hashCondition = this.hideOnNextUserLogin ? md5(this.session.accountId) : (!this.hasHash || this.sameHash);
        this.hidden = !value || !!data?.hidden && hashCondition;
      },
      immediate: true,
    },
  },
});

/**
 * File: disableMfaRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import md5 from 'blueimp-md5';

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface DisableMfaRequestParams {
  oneTimePassword?: string;
  password?: string;
}

export default class DisableMfaRequest extends ApiRequest<DisableMfaRequestParams> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/disable`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    const body: { password: string } = { password: null };
    if (this.params.oneTimePassword) {
      body.password = this.params.oneTimePassword;
    } else if (this.params.password) {
      body.password = md5(this.params.password);
    }

    return body;
  }
}



import Vue, { PropType } from 'vue';
import Session from '@/models/session';
import { FEATURE_DISABLE_EMAIL_CONDITIONAL, FEATURE_TWO_STEP_VERIFICATION_VIA_TOTP } from '@core/constants/features';
import { MFA_METHOD } from '@core/constants/mfa';
import { MFA_DISABLED_SIGNAL, MFA_METHOD_CHANGED_SIGNAL } from './constants';

export default Vue.extend({
  name: 'mfa-settings',

  props: {
    session: {
      type: Object as PropType<Session>,
      default: () => ({}),
    },
  },

  data () {
    return {
      mfaEnabled: 1,
      mfaMethodValue: this.session.isFeatureAccessible(FEATURE_DISABLE_EMAIL_CONDITIONAL) ? MFA_METHOD.EMAIL_ALWAYS : MFA_METHOD.EMAIL_SENSITIVE_DATA,
    };
  },

  mounted (): void {
    this.resetForm();
  },

  computed: {
    mfaOptions (): { text: string; level: string; value: number }[] {
      const options = [];

      if (this.isTotpAccessible) {
        options.push({
          text: this.$t('Authenticator App {level}'),
          level: this.$t('(recommended)'),
          value: MFA_METHOD.AUTH_APPLICATION,
        });
      }

      if (this.session.isFeatureAccessible(FEATURE_DISABLE_EMAIL_CONDITIONAL)) {
        options.push({ text: this.$t('Email {level}'), level: '', value: MFA_METHOD.EMAIL_ALWAYS });
      } else {
        options.push(
          { text: this.$t('Email, unconditional {level}'), level: '', value: MFA_METHOD.EMAIL_ALWAYS },
          { text: this.$t('Email, conditional {level}'), level: this.$t('(least recommended)'), value: MFA_METHOD.EMAIL_SENSITIVE_DATA }
        );
      }

      return options;
    },
    mfaEnabledOptions (): { text: string; value: number }[] {
      return [
        { text: this.$t('Enabled'), value: 1 },
        { text: this.$t('Disabled'), value: 0 },
      ];
    },
    isTotpAccessible (): boolean {
      return this.session.mfaMethod === MFA_METHOD.AUTH_APPLICATION || this.session.isFeatureAccessible(FEATURE_TWO_STEP_VERIFICATION_VIA_TOTP);
    },
  },

  methods: {
    onMfaMethodChange (v: number): void {
      if (v !== this.session.mfaMethod) {
        this.$emit(MFA_METHOD_CHANGED_SIGNAL, v);
      }
    },
    onMfaEnabledChange (v: number): void {
      if (v === 0 && this.session.mfaStatus) {
        this.$emit(MFA_DISABLED_SIGNAL);
      } else if (v === 1 && !this.session.mfaStatus) {
        this.$emit(MFA_METHOD_CHANGED_SIGNAL, this.mfaMethodValue);
      }
    },
    resetForm (): void {
      this.mfaEnabled = this.session.mfaStatus ? 1 : 0;
      this.mfaMethodValue = this.session.mfaMethod;
    },
  },
});


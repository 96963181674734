/**
 * File: socialLoginRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class SocialLoginRequest extends ApiRequest {
  get cacheNameSpace () {
    return false;
  }

  get baseUrl () {
    throw new Error('get baseUrl () should be overridden');
  }

  get queryParams () {
    const result = {
      consent_id: this.params.consent_id,
    };

    if (this.params.service || this.params.continue) {
      result.service = this.params.service || 'self';
      result.continue = this.params.continue || '/';
    }

    return result;
  }

  get url () {
    return this.baseUrl + this.buildQueryString(this.queryParams);
  }
}

/** * File: addKeyMixin.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue, { VueConstructor } from 'vue';
import { clearLicenseKey } from '@core/common/validators';
import ExtendSubscriptionRequest from '@/api/extendSubscriptionRequest';
import ActivateSubscriptionRequest from '@/api/activateSubscriptionRequest';
import LicenseKeyInfoRequest from '@/api/licenseKeyInfoRequest';
import RegisterLegacyLicenseRequest from '@/api/registerLegacyLicenseRequest';
import { DASHBOARD_PAGE, GO_PAGE } from '@/routes/constants';
import Subscription, { SubscriptionDurationUnit } from '@/models/subscription';
import { GetSsoSettingsRequest } from '@/api/sso/ssoSettingsRequest';
import { SSO_SETTINGS_STATUS } from '@/modules/businessProfile/businessProfileIdPIntegration/constants';
import { FEATURE_ENFORCE_CONFIRMATION_FOR_BIZ_REG, FEATURE_SHOW_BULA_OPTION_MODAL } from '@core/constants/features';
import UserConfirmEmailRequest from '@/api/userConfirmEmailRequest';
import SubscriptionRequest from '@/api/subscriptionRequest';

const ERRORS = 'non_field_errors';
const ACCOUNT_CONFIRMED = 'account already confirmed';

export interface AdminsDomain {
 companyUuid: number; companyName: string; isAdmin: boolean;
}

export interface AddKeyMixin extends Vue {
  asModal: boolean;
  hideError: ()=> void;
}

export default (Vue as VueConstructor<AddKeyMixin>).extend({
  data () {
    const replacements = {
      link: '<a href="http://www.parallels.com/support/" target="_blank">',
      end: '</a>',
    };
    const errors = {
      'already registered': this.$t('This license key is already registered.'),
      'license key already registered': this.$t('This license key is already registered.'),
      'already been registered in your account': this.$t('This license key is already registered in your account.'),
      'already been registered in another account': this.$t('This license key is already registered in a different account.'),
      'blacklisted license key': this.$t('This license key is blacklisted.'),
      'this license key was already used to extend some subscription': this.$t('This license key was already used to extend some subscription.'),
      'not eligible key': this.$t('You`re not eligible to use this license key as you don`t have an appropriate Parallels Desktop legacy version licenses registered in your account.'),
      'failed to activate your subscription': this.$t('Operation cannot be completed at the moment. Try again later. If the problem persists, {link}contact{end} Parallels Support Team.', replacements),
      'invalid universal key': this.$t('This license key cannot be used. Please {link}contact{end} Parallels Support Team.', replacements),
      'license key cannot be registered': this.$t('You`re not allowed to register one more promo key. Please {link}contact{end} Parallels Support Team.', replacements),
      'can be registered just under personal account': this.$t('You must be signed in to a private user account to register this license key.'),
      other: this.$t('The key you entered is not valid. Please make sure you have a valid key and try again. If the problem persists, {link}contact{end} Parallels Support Team.', replacements),
    };

    return {
      bulaAccepted: false,
      cepOption: false,
      loading: false,
      licenseKey: '',
      subscriptionName: '',
      extendOptions: [] as Subscription[],
      newSubscription: null as Subscription,
      keyInfo: {} as Subscription | null,
      selectedBusinessDomain: null as number,
      errors,
      errorToast: null as string,
      existsPerUserLicenseKey: '',
      existsPerUserSubscriptionUuid: '',
    };
  },

  mounted () {
    this.resetForm();
    // Check whether license key was passed within query parameter
    const licenseKey = this.$router.currentRoute.query.lic_key;
    if (licenseKey) {
      // @ts-ignore theoretically here can be string | string[] but we know for sure that there will be string
      this.licenseKey = licenseKey;
    }
  },

  destroyed () {
    this.hideHandler();
  },

  methods: {
    resetForm () {
      this.licenseKey = '';
      this.subscriptionName = '';
      this.extendOptions = [];
      this.newSubscription = null;
      this.$emit('extendOptions', this.extendOptions, this.newSubscription);
      this.keyInfo = null;
      this.$emit('keyInfo', this.keyInfo);
      if (this.$v) {
        this.$v.$reset();
      }
    },

    hideHandler () {
      this.hideError();
    },

    clearOptions () {
      this.cepOption = false;
      this.bulaAccepted = false;
    },

    // eslint-disable-next-line camelcase
    loadOptions (): Promise<{ newSubscription: Subscription; available_for_extend: Subscription[] }> {
      const request = new ExtendSubscriptionRequest({ licKey: this.licenseKey });
      return this.$api.authorizedCall(request).then((data) => {
        this.extendOptions = data.available_for_extend || [];
        this.newSubscription = data.subscription || null;
        this.$emit('extendOptions', this.extendOptions, this.newSubscription);
        return data;
      });
    },

    loadInfo (): Promise<{ subscription: Record<string, any> }> {
      const request = new LicenseKeyInfoRequest({ licKey: this.licenseKey });
      return this.$api.authorizedCall(request)
        .catch(() => {
          return {};
        })
        .then((data) => {
          this.keyInfo = data.subscription;
          this.$emit('keyInfo', this.keyInfo);
          return data;
        });
    },
    async addKey (): Promise<{ isBusinessAccountRequired: boolean }> | undefined {
      const needConfirmation = await this.checkIfConfirmationRequired();
      if (needConfirmation) {
        return;
      }
      if (this.isBusinessLicense && !this.selectedBusinessDomain && !this.initBusinessDomain()) {
        return;
      }
      if (this.isBusinessLicense && !this.bulaAccepted && this.isBulaFeatureOn()) {
        this.$emit('bulaModalShow');
        return;
      }
      await this.sendAddKeyRequest();
    },

    async sendAddKeyRequest (params?: {}) {
      const requestParams: any = params || { licKey: this.licenseKey, bulaAccepted: this.bulaAccepted, cepOption: this.cepOption };
      if (!params && this.selectedBusinessDomain) {
        requestParams.domainId = this.selectedBusinessDomain;
      }
      if (!params && this.subscriptionName.length) {
        requestParams.name = this.subscriptionName;
      }
      this.loading = true;
      const request = new ActivateSubscriptionRequest(requestParams);
      this.clearOptions();
      return this.$api.authorizedCall(request).then(async (data) => {
        if (await this.addKeyError(data, requestParams)) {
          // Key is not registered, some error
          return;
        }
        if (data.isBusinessAccountRequired) {
          // Key is not registered, BA required
          this.$emit('businessAccountRequired');
          return data;
        }

        const promise = data.is_business && this.selectedBusinessDomain ?
          // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16280
          this.switchBusinessDomain(this.selectedBusinessDomain) :
          this.$api.refreshToken(true);

        return promise.then(async () => {
          if (this.keyInfo.isPerUserSubscription) {
            const getSsoSettingsRequest = new GetSsoSettingsRequest({
              domainId: this.session.businessDomainId,
            });
            this.$api.authorizedCall(getSsoSettingsRequest).catch(() => {
              this.licenseKeyRegistered();
            }).then((settings) => {
              if (settings.status !== SSO_SETTINGS_STATUS.ACTIVATED) {
                this.$modal.show('configureIdpSuggestionModal');
              } else {
                this.licenseKeyRegistered();
              }
            });
          } else {
            this.licenseKeyRegistered();
          }
          return data;
        });
      }).catch((e) => {
        e.response.json().then((data) => {
          // TODO: rework copy paste below and key registration process
          if (data.msg === 'invalid license key') {
            const request = new RegisterLegacyLicenseRequest({
              licenseKey: this.licenseKey,
            });

            this.$api.authorizedCall(request).then(() => {
              this.licenseKeyRegistered();
            }).catch((error) => {
              error.response.json().then((data) => {
                this.addKeyError(data);
              });
            });
          } else {
            // Key is not registered, some error
            this.addKeyError(data);
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    licenseKeyRegistered () {
      this.$emit('licenseKey', this.licenseKey);
      this.$emit('success', this.keyInfo);
      this.showSuccessfulToast();
      this.$modal.hide();
      this.resetForm();
    },

    showSuccessfulToast () {
      // $t('Parallels Desktop for Mac Business Edition license key has been registered.')
      // $t('Parallels Remote Application Server license key has been registered.')
      // $t('Parallels Toolbox license key has been registered.')
      // $t('Parallels Device Management license key has been registered.')
      // $t('Parallels Desktop for Mac Standard Edition license key has been registered.')
      // $t('Parallels Access license key has been registered.')
      const text = this.keyInfo && this.keyInfo.products[0] ?
        this.$t('The license key for {productName} has been registered.', { productName: this.keyInfo.products[0].productName }) :
        this.$t('Your license key {licKey} was successfully registered.', { licKey: this.licenseKey });

      this.$toast.show({ text });
    },

    redirect () {
      this.$modal.hide();
      this.licenseKey = '';
      this.$router.replace({
        name: GO_PAGE,
        query: {
          continue: this.$router.resolve({ name: DASHBOARD_PAGE }).href,
        },
      });
    },

    showError (error) {
      this.hideError();
      this.errorToast = this.$toast.show({ color: 'red', html: error, lifetime: 0 });
    },

    hideError () {
      if (this.errorToast) {
        this.$toast.hide(this.errorToast);
        this.errorToast = null;
      }
    },

    async addKeyError (data, requestParams?: {}) {
      if (!data.msg) {
        return false;
      }
      const isOnlySinglePerUserAllowed = data.msg === 'only single per user subscription allowed';
      if (isOnlySinglePerUserAllowed) {
        this.existsPerUserLicenseKey = data.lic_key;
        this.existsPerUserSubscriptionUuid = data.subscription_uuid;
        this.$emit('existsPerUserLicenseKey', this.existsPerUserLicenseKey);
        this.$emit('existsPerUserSubscriptionUuid', this.existsPerUserSubscriptionUuid);
        this.$emit('licenseKey', this.licenseKey);
        if (this.keyInfo.isPDE) {
          const request = new SubscriptionRequest({ uuid: this.existsPerUserSubscriptionUuid });
          try {
            await this.$api.authorizedCall(request);
            const existedSubscription = request.getSubscription();
            const isExtendedTrial = (existedSubscription as Subscription).durationUnit === SubscriptionDurationUnit.MONTH;
            const modalData = { keyInfo: this.keyInfo, existedSubscription, isSuspendable: isExtendedTrial, existedLicKey: this.existsPerUserLicenseKey, registeredLicKey: this.licenseKey, requestParams };
            this.$modal.show('keyConflictModal', modalData);
          } catch (e) {}
        } else {
          this.$modal.show('onlySinglePerUserSubscriptionAllowedModal');
        }
        return true;
      }
      const error = this.errors[data.msg] || this.errors.other;
      this.showError(error);
      return true;
    },

    addKeyHandler () {
      this.hideError();

      this.keyInfo = null;
      this.$emit('keyInfo', this.keyInfo);

      this.loading = true;
      return this.loadInfo().then(() => this.loadOptions()).then(() => {
        const isNewKeyIsPDE = this.keyInfo && this.keyInfo.isPDE;
        if (this.extendOptions.length && !isNewKeyIsPDE) {
          // has some extending options, break
          this.$modal.show('addKeyExtendSubscription');
          return;
        }

        return this.addKey();
      }).finally(() => {
        this.loading = false;
      });
    },

    initBusinessDomain (): boolean {
      if (this.adminsDomains?.length < 1) {
        // No one business domains
        this.$emit('businessAccountRequired'); // after creation will be triggered registerSubscriptionHandler
        return false;
      }

      if (this.adminsDomains?.length === 1) {
        // Select first business domain
        this.selectedBusinessDomain = this.adminsDomains[0].companyUuid;
        return true;
      }

      // User should choose, show selection dialog
      this.$modal.show('selectBusinessDomain'); // after selection, addKeyModal will be shown again
      return false;
    },

    accountRegisteredHandler (result: { purpose: string }) {
      this.loading = true;
      this.addKey().finally(() => {
        this.loading = false;
      });
    },

    bulaAcceptedHandler (result) {
      this.loading = true;
      this.bulaAccepted = !!result.bulaAccepted;
      this.cepOption = !!result.cepOption;
      this.addKey().finally(() => {
        this.loading = false;
      });
    },

    registerSubscriptionHandler () {
      return this.addKey();
    },

    isBulaFeatureOn () {
      return this.session ? this.session.isFeatureAccessibleByProduct(FEATURE_SHOW_BULA_OPTION_MODAL, this.keyInfo.products) : false;
    },

    async checkIfConfirmationRequired () {
      if (
        this.isBusinessLicense &&
        this.session.isFeatureAccessible(FEATURE_ENFORCE_CONFIRMATION_FOR_BIZ_REG) &&
        !this.session.confirmed
      ) {
        try {
          const result = await this.authorizedCall(new UserConfirmEmailRequest());
          if (result.email_has_been_sent) {
            this.$emit('onNeedConfirmation');
            return true;
          }
        } catch (error) {
          const message = await error.response.json();
          if (message[ERRORS]?.[0] === ACCOUNT_CONFIRMED) {
            this.session.setConfirmed(true);
            return false;
          }
          throw error;
        }
      }
      return false;
    },
  },

  computed: {
    session () {
      return this.$appData.session;
    },
    adminsDomains (): AdminsDomain[] {
      return this.session.getBusinessDomains(true) || [];
    },
    isBusinessLicense (): boolean {
      return this.keyInfo && this.keyInfo.isBusiness;
    },
    showTrialLinks (): boolean {
      return !this.asModal && navigator.webdriver;
    },
  },

  watch: {
    licenseKey (val) {
      this.licenseKey = clearLicenseKey(val);
    },
  },
});

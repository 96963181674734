/**
 * File: pswWizardModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import StepAbstract from '@/modules/businessProfile/businessProfileIdPIntegration/stepAbstract.vue';
import { SSO_SETTINGS_STATUS } from '@/modules/businessProfile/businessProfileIdPIntegration/constants';
import Vue from 'vue';
import NewAuthenticatorAppSecretRequest from '@/api/newAuthenticatorAppSecretRequest';
import oneTimePasswordWorkflowMixin from '@/modules/oneTimePasswordWorkflowMixin';
import RescueCodes from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/rescueCodes.vue';
import LanguageSwitcher from '@/ui/languageSwitcher.vue';
import AddNewAuthenticatorAppModal
  from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/addNewAuthenticatorAppModal.vue';
import ResendAccountConfirmation from '@/api/resendAccountConfirmationRequest';
import { required } from '@core/common/validators';
import SwitchMfaMethodRequest from '@/api/switchMfaMethodRequest';
import { MFA_METHOD } from '@core/constants/mfa';
import {
  PSW_PRODUCT_ID,
  PSW_STAGING_PRODUCT_ID,
  TICKET_FIELD_CONTACT_SOURCE,
  TICKET_FIELD_SUPPORT_TYPE,
  TICKET_FIELD_TIMEZONE
} from '@/api/rt/constants';
import { getContactSource, SUPPORT_TYPE_LICENSE } from '@/modules/support/constants';
import { getMilitaryTimezone } from '@core/common/datetime';
import { Environment } from '@/models/initialConfig';
import RtCreateTicketRequest from '@/api/rt/rtCreateTicketRequest';
import AuthenticatorAppsListRequest from '@/api/authenticatorAppsListRequest';
import AuthenticatorApp from '@/models/authenticatorApp';
import AddNewAuthenticatorAppRequest from '@/api/addNewAuthenticatorAppRequest';
import AlreadyHaveAuthenticatorAppModal
  from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/alreadyHaveAuthenticationAppModal.vue';
import JoinToBusinessAccountModal from '@/modules/invitations/joinToBusinessAccountModal.vue';
import { BusinessDomain } from '@/api/listBusinessDomainsRequest';

const DESCRIPTION_MAX_LENGTH = 255;

export const PSW_WIZARD_STEP_NAME = {
  TWO_FACTOR_VERIFICATION: 'twoFactorVerification',
  EMAIL_VERIFICATION: 'emailVerification',
};

export enum PAGE_CONTENT {
  MAIN = 'main',
  TWO_STEP_VERIFICATION = 'twoStepVerification',
  SUPPORT = 'support',
}

export default Vue.extend({
  name: 'psw-wizard-modal',
  mixins: [oneTimePasswordWorkflowMixin],
  components: {
    JoinToBusinessAccountModal,
    RescueCodes,
    AddNewAuthenticatorAppModal,
    AlreadyHaveAuthenticatorAppModal,
    StepAbstract,
    LanguageSwitcher,
  },
  data () {
    return {
      isInit: false,
      loading: false,
      content: PAGE_CONTENT.MAIN as PAGE_CONTENT,
      PSW_WIZARD_STEP_NAME,
      PAGE_CONTENT,
      settings: {
        status: SSO_SETTINGS_STATUS.DISABLED,
        stepsDone: {
          [PSW_WIZARD_STEP_NAME.EMAIL_VERIFICATION]: false,
          [PSW_WIZARD_STEP_NAME.TWO_FACTOR_VERIFICATION]: false,
        },
      },
      loadingAsk: false,
      loadingSupport: false,
      showHiddenText: false,
      building: require('./assets/building.svg'),
      setupAccountLink: '',
      secret: '',
      description: '',
      newAuthenticatorAppSecretLoading: false,
      rescueCodes: [] as string[],
      authenticatorApps: [] as AuthenticatorApp[],
      isAddNewVerificationModalOpen: false,
      showWarning: false,
      showPendingInvitationsBlock: false,
      domain: null as BusinessDomain,
      descriptionMaxLength: DESCRIPTION_MAX_LENGTH,
      descriptionPlaceholder: this.$t('Please describe the problem in detail ({amount} chars max)', { amount: DESCRIPTION_MAX_LENGTH }),
    };
  },
  computed: {
    isFirstStepCompleted (): boolean {
      return this.settings.stepsDone[PSW_WIZARD_STEP_NAME.EMAIL_VERIFICATION];
    },
    isSecondStepCompleted (): boolean {
      return this.settings.stepsDone[PSW_WIZARD_STEP_NAME.TWO_FACTOR_VERIFICATION];
    },
    isAllStepsCompleted (): boolean {
      return this.isFirstStepCompleted && (!this.mfaRequired || this.isSecondStepCompleted);
    },
    userEmail (): string {
      return this.$appData.session?.email;
    },
    companyName (): string {
      return this.domain?.name;
    },
    isUserConfirmed (): boolean {
      return this.$appData.session?.confirmed;
    },
    isMfaEnabled (): boolean {
      return this.$appData.session?.mfaStatus === true && this.$appData?.session?.mfaMethod === MFA_METHOD.AUTH_APPLICATION;
    },
    mfaRequired (): boolean {
      // eslint-disable-next-line camelcase
      return this.domain?.mfa_status;
    },
    hasPendingInvitations (): boolean {
      return this.$appData.session?.pendingInvitationsCount > 0 && this.$appData.session?.trusted;
    },
    secondStepTitle (): string {
      return this.mfaRequired ?
        this.$t('Setup two-step verification with an authenticator app') :
        this.$t('Setup two-step verification with an authenticator (recommended)');
    },
  },
  mounted (): void {
    if (this.isUserConfirmed) {
      this.settings.stepsDone[PSW_WIZARD_STEP_NAME.EMAIL_VERIFICATION] = true;
    }
    if (this.isMfaEnabled) {
      this.settings.stepsDone[PSW_WIZARD_STEP_NAME.TWO_FACTOR_VERIFICATION] = true;
    }
  },
  watch: {
    '$appData.session.confirmed' (value): void {
      this.settings.stepsDone[PSW_WIZARD_STEP_NAME.EMAIL_VERIFICATION] = value;
    },
    '$appData.session.mfaStatus' (): void {
      this.updateVerificationStep();
    },
    '$appData.session.mfaMethod' (): void {
      this.updateVerificationStep();
    },
    '$appData.session.pendingInvitationsCount' (newValue, oldValue): void {
      // it's prevent glitch when session updated before products init in refreshToken and modal stays a few seconds with wrong data
      if (oldValue > 0 && newValue === 0) {
        this.closeModal();
      }
    },
  },
  validations: { description: { required } },
  methods: {
    showContactSupportModal (): void {
      this.content = PAGE_CONTENT.SUPPORT;
    },
    async showVerificationStep (): Promise<void> {
      this.rescueCodes = [];
      await this.loadAuthenticatorApps();
      this.content = PAGE_CONTENT.TWO_STEP_VERIFICATION;
      if (this.authenticatorApps.length > 0) {
        this.isAddNewVerificationModalOpen = false;
      } else {
        await this.showAddNewAuthenticatorAppBlock();
      }
    },

    async showAddNewAuthenticatorAppBlock (): Promise<void> {
      this.loading = true;
      try {
        this.isAddNewVerificationModalOpen = true;
        const request = new NewAuthenticatorAppSecretRequest();
        await this.$api.authorizedCall(request);
        const responseData = request.getSecret();
        this.setupAccountLink = responseData.setupAccountLink;
        this.secret = responseData.secret;
      } catch (e) {
        this.content = PAGE_CONTENT.MAIN;
      } finally {
        this.loading = false;
      }
    },

    async addNewAuthenticatorApp (oneTimePassword: string) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState(oneTimePassword);
      this.loading = true;
      try {
        const isNeedToSwitch = this.$appData?.session?.mfaStatus && this.$appData?.session?.mfaMethod && this.$appData?.session?.mfaMethod !== MFA_METHOD.AUTH_APPLICATION;
        let request = null;
        if (isNeedToSwitch) {
          request = await this.switchMfaMethod({ oneTimePassword });
        } else {
          request = new AddNewAuthenticatorAppRequest({ secret: this.secret, oneTimePassword });
        }
        await this.$api.authorizedCall(request);
        this.authenticatorApps.push(request.getAuthenticatorApp(this.authenticatorApps.length));
        this.mfaSuccessfullyAdded(request.getRescueCodes());
      } catch (e) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.invalidPasswordEntered();
      } finally {
        this.loading = false;
      }
    },

    async enableTotpWithExistingAuthenticatorApp (oneTimePassword: string) {
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState(oneTimePassword);
      this.loading = true;
      try {
        await this.switchMfaMethod({ oneTimePassword });
      } catch (e) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.invalidPasswordEntered();
      } finally {
        this.loading = false;
      }
    },

    mfaSuccessfullyAdded (resqueCodes: string[]): void {
      this.$appData.session.mfaStatus = true;
      this.$appData.session.mfaMethod = MFA_METHOD.AUTH_APPLICATION;
      this.rescueCodes = resqueCodes;
      this.$toast.show({
        color: 'green',
        text: this.$t('Two-step verification via authenticator app configured.'),
      });
      if (!this.isFirstStepCompleted) {
        this.updateState();
      }
    },

    async switchMfaMethod ({ oneTimePassword = null as string }): Promise<void> {
      const newMfaMethod = MFA_METHOD.AUTH_APPLICATION;
      const request = new SwitchMfaMethodRequest({ newMfaMethod, oneTimePassword, secret: this.secret });
      await this.$api.authorizedCall(request);
      this.mfaSuccessfullyAdded(request.getRescueCodes());
    },
    updateVerificationStep (): void {
      this.settings.stepsDone[PSW_WIZARD_STEP_NAME.TWO_FACTOR_VERIFICATION] = this.isMfaEnabled;
    },
    handleCannotFindEmailClick (): void {
      this.showHiddenText = !this.showHiddenText;
    },
    async handleResendClick (): Promise<void> {
      this.loadingAsk = true;
      try {
        await this.$api.authorizedCall(new ResendAccountConfirmation());

        this.$toast.show({
          color: 'green',
          text: this.$t('Confirmation email has been sent to the account email address.'),
        });
      } finally {
        this.loadingAsk = false;
      }
    },
    handleShow (e, domain: BusinessDomain): void {
      this.domain = domain;
      this.isInit = true;
      document.querySelector('html').classList.add('overflow-hidden');
    },
    async updateState (): Promise<void> {
      this.loading = true;
      await this.$api.refreshToken(true, true);
      this.loading = false;
    },
    async confirm (): Promise<void> {
      await this.updateState();
      if (!this.isAllStepsCompleted) {
        this.showWarning = true;
      } else {
        if (this.hasPendingInvitations) {
          this.showPendingInvitationsBlock = true;
        } else {
          this.closeModal();
        }
      }
    },
    closeModal (): void {
      document.querySelector('html').classList.remove('overflow-hidden');
      this.$modal.hide();
      this.resetModalState();
    },
    logout (): void {
      document.querySelector('html').classList.remove('overflow-hidden');
      this.loading = true;
      this.$api.logout();
      this.$modal.hide();
      this.resetModalState();
    },
    handleSupportCancel (): void {
      this.content = PAGE_CONTENT.MAIN;
    },
    async handleSupportSend (): Promise<void> {
      const env = this.$appData.config?.environment;
      const queueId = env === Environment.Production ? 8405 : env === Environment.Dev00 ? 8421 : 8318;
      const productId = env === Environment.Production ? PSW_PRODUCT_ID : PSW_STAGING_PRODUCT_ID;
      const request = new RtCreateTicketRequest({
        form: {
          product: productId,
          [TICKET_FIELD_SUPPORT_TYPE]: SUPPORT_TYPE_LICENSE,
          case_origin: 'email',
          country: this.$appData?.session.country,
          [TICKET_FIELD_TIMEZONE]: getMilitaryTimezone(),
          [TICKET_FIELD_CONTACT_SOURCE]: getContactSource(),
          summary: `User ${this.userEmail} reports a problem submitting a support request`,
          description: this.description,
          queue_id: queueId,
        },
      });
      this.loadingSupport = true;

      try {
        await this.$api.callRt(request);
        this.$toast.show({
          text: this.$t('Request for support has been submitted.'),
        });
        this.content = PAGE_CONTENT.MAIN;
        this.description = '';
      } catch (e) {
        this.$toast.show({
          color: 'red',
          text: this.$t('Your request cannot be submitted at the moment. Please try again later.'),
        });
      } finally {
        this.loadingSupport = false;
      }
    },
    loadAuthenticatorApps (): Promise<void> {
      const request = new AuthenticatorAppsListRequest();
      return this.$api.authorizedCall(request, 'authenticatorAppsLoading').then(() => {
        this.authenticatorApps = request.getAuthenticatorApps();
      });
    },
    async handleCheckStatusClick (): Promise<void> {
      await this.$api.refreshToken(true, true);
    },
    resetModalState (): void {
      this.loading = false;
      this.isInit = false;
      this.content = PAGE_CONTENT.MAIN;
      this.loadingAsk = false;
      this.description = '';
      this.showWarning = false;
      this.showHiddenText = false;
      this.isAddNewVerificationModalOpen = false;
      this.showPendingInvitationsBlock = false;
      this.rescueCodes = [];
      this.setupAccountLink = '';
      this.secret = '';
    },
  },
});



import Vue from 'vue';
import { required } from '@core/common/validators';

export interface IssueSeverityModal {
  summary: string;
  severity: number;
  supportOption: string;
}

export default Vue.extend({
  name: 'issue-severity-modal',

  data () {
    return {
      summary: '',
      severity: null as number,
      switcherOptions: [
        {
          text: this.$t('Severity One, Urgent'),
          value: 0,
        },
        {
          text: this.$t('Severity Two, High'),
          value: 1,
        },
        {
          text: this.$t('Severity Three, Normal'),
          value: 2,
        },
        {
          text: this.$t('Severity Four, Enhancement'),
          value: 3,
        },
      ],
      supportOption: null as string,
    };
  },

  validations: {
    summary: { required },
  },

  methods: {
    show (options: IssueSeverityModal) {
      this.supportOption = options.supportOption;
      this.severity = options.severity;
      this.summary = options.summary;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
    },

    flush () {
      this.severity = null;
      this.supportOption = null;
      this.summary = null;
    },

    close () {
      this.flush();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
    },

    nextButtonHandler () {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        severity: this.severity,
        summary: this.summary,
        supportOption: this.supportOption,
      });
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
      this.flush();
    },
  },

  computed: {
    isSeveritySelected (): boolean {
      return this.severity !== null;
    },
  },
});




import Vue, { PropType } from 'vue';
import Session from '@/models/session';
import { MFA_METHOD } from '@core/constants/mfa';
import EnableAuthenticatorAppMfaModal from './enableAuthenticatorAppMfaModal.vue';
import UpdateTwoStepVerificationSettingsWithOtpModal from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/updateTwoStepVerificationSettingsWithOtpModal.vue';
import OneTimePasswordWorkflowMixin from '@/modules/oneTimePasswordWorkflowMixin';
import UpdateDomainMfaSettingsRequest from '@/api/updateDomainMfaSettingsRequest';

export default Vue.extend({
  name: 'mfa-settings',

  mixins: [OneTimePasswordWorkflowMixin],

  components: {
    UpdateTwoStepVerificationSettingsWithOtpModal,
    EnableAuthenticatorAppMfaModal,
  },

  props: {
    session: {
      type: Object as PropType<Session>,
      default: () => ({}),
    },
  },

  data () {
    return {
      mfaEnabled: false,
      loading: false,
    };
  },

  mounted (): void {
    this.resetForm();
  },

  methods: {
    onMfaEnabledChange (v: boolean): void {
      if (v) {
        if (!this.session.mfaStatus || this.session.mfaMethod !== MFA_METHOD.AUTH_APPLICATION) {
          this.$modal.show(EnableAuthenticatorAppMfaModal);
          Vue.nextTick(() => {
            this.mfaEnabled = false;
          });
        } else {
          this.changeDomainMfaSetting();
        }
      } else {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.resetAppliedPasswordState();
        this.$modal.show(UpdateTwoStepVerificationSettingsWithOtpModal);
      }
    },
    resetForm (): void {
      this.mfaEnabled = !!this.session.businessDomain.mfaStatus;
    },
    async changeDomainMfaSetting (authObj?: { oneTimePassword: string }) {
      this.loading = true;
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetAppliedPasswordState(authObj?.oneTimePassword);
      try {
        await this.$api.authorizedCall(new UpdateDomainMfaSettingsRequest({ domainId: this.session.businessDomainId, mfaEnabled: this.mfaEnabled, oneTimePassword: authObj?.oneTimePassword }));
        this.$appData.session.businessDomain.mfaStatus = this.mfaEnabled;
        this.$modal.hide();
        let text = this.$t('Enforcement of two-step verification via authenticator app has been successfully enabled.');
        if (!this.mfaEnabled) {
          text = this.$t('Enforcement of two-step verification via authenticator app has been successfully disabled.');
        }
        this.$toast.show({ color: 'green', text });
      } catch (e) {
        // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
        this.invalidPasswordEntered();
      } finally {
        this.loading = false;
      }
    },
  },
});


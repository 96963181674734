// /api/account/v1/ticket/{id}/gen_survey
/**
 * File: rtGenerateSurveyRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import RtRequest from './rtRequest';

export default class RtGenerateSurveyRequest extends RtRequest {
  /**
   *
   * @param {Object} params
   * @param {Number} params.ticketId
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  /**
   * @returns {String}
   */
  get uri () {
    return `/ticket/${this.params.ticketId}/gen_survey`;
  }

  get method () {
    return 'POST';
  }
}

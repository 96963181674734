/**
 * File: notificationSettingsRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class NotificationSettingsRequest extends ApiRequest {
  get url () {
    return '/account/api/v1/notifications_settings';
  }
}

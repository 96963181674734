

import { defineComponent } from 'vue';
import { LanguageSwitcher } from '@parallels/ui-kit';
import { LANGUAGES, WEBSITE_LANGUAGES, KB_LANGUAGES } from '@core/constants/langs';
import { getUpperDomain } from '@core/common/url';
import locale, { getLocaleFromUrl } from '@/plugins/locale';
import ProfileUpdateRequest from '@/api/profileUpdateRequest';
import Session from '@/models/session';

const localeCookie = 'PAXLocale';
const websiteLocaleCookie = 'cpweb_lang';
const kbLocaleCookie = 'KB_LANG';

export default defineComponent({
  name: 'language-switcher-my',
  components: {
    LanguageSwitcher,
  },
  props: {
    type: {
      type: String,
    },
    language: {
      type: String,
    },
    displayArrow: {
      type: Boolean,
    },
    focusable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      languages: LANGUAGES,
      locale,
    };
  },
  created () {
    const outerLocale = getLocaleFromUrl();

    // @ts-ignore
    this.locale.code = outerLocale || this.language || this.session.isoLocale || this.$cookie.get(localeCookie);

    if (outerLocale) {
      this.saveLocale(outerLocale);
    }
  },
  computed: {
    session (): Session {
      return this.$appData.session || {};
    },
  },
  watch: {
    'locale.code': async function (value) {
      if (!value) {
        this.locale.code = this.locale.default;
        return;
      }

      this.saveLocale(value);

      this.locale.loadLanguage(value).then((data) => {
        this.locale.i18n.mergeLocaleMessage(value, data);
        this.locale.i18n.locale = value;
        // Emit event after template re-render
        this.$nextTick(() => {
          this.$emit('switch', { locale: value });
          // Emit resize to recalc width/height of components
          try {
            window.dispatchEvent(new Event('resize'));
          } catch (e) {}
        });
      });

      await this.$api.setCountries(true);

      if (this.session.authenticated) {
        const request = new ProfileUpdateRequest({
          locale: value.toLowerCase(),
        });

        this.$api.authorizedCall(request).then((data) => {
          this.$api.refreshToken(true);
        });
      }
    },
  },
  methods: {
    saveLocale (value) {
      const options = { domain: getUpperDomain(), expires: 365 * 5 };

      // @ts-ignore
      this.$cookie.set(localeCookie, value, options);
      // @ts-ignore
      this.$cookie.set(websiteLocaleCookie, WEBSITE_LANGUAGES[value], options);
      // @ts-ignore
      this.$cookie.set(kbLocaleCookie, KB_LANGUAGES[value], options);
    },
  },
});


/**
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import debounce from 'lodash-es/debounce';
import { adjustItemsWidthToParentContainer } from '@/ui-kit-fork/utils/adjustItemsWidthToParentContainer';

export default {
  methods: {
    adjustWidthToFit () {
      adjustItemsWidthToParentContainer(this.$el, this.itemSelector);
    },
  },
  data () {
    return {
      resizeWatcher: debounce(this.adjustWidthToFit, 300),
    };
  },
  mounted () {
    this.adjustWidthToFit();
    window.addEventListener('resize', this.resizeWatcher);
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeWatcher);
  },
};

/**
 * File: browser.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 **/

const { detect } = require('detect-browser');

export const browser = detect();

// TODO: move to platform constant
export const ios: boolean = (function () {
  if (navigator.userAgent.match(/iPad|iPhone|iPod/)) {
    return true;
  } else if (navigator.userAgent.match('Macintosh')) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {}
  }
  return false;
})();

export enum Platform {
  Macintosh = 'Macintosh',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux'
};

export const PLATFORMS = [
  Platform.Macintosh,
  Platform.Windows,
  Platform.Android,
  Platform.Linux
];

export const platform: Platform | undefined = (function () {
  return PLATFORMS.find((platform) => {
    return navigator.userAgent.match(platform);
  });
})();

/**
 * File: pmmUpdateSiteRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PmmSitesRequest from './pmmSitesRequest';

export default class PmmUpdateSiteRequest extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.uuid
   * @param {String} params.name
   * @param {?String} params.notes
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get url () {
    return `/pmm/webapp/sites/${this.params.uuid}`;
  }

  get body () {
    const params = this.params;
    const data = {
      name: params.name,
    };
    if (this.params.hasOwnProperty('notes')) {
      data.notes = this.params.notes;
    }
    return data;
  }

  getJson () {
    // No JSON content expected here
    return null;
  }

  load () {
    return super.load()
      .then((data) => {
        new PmmSitesRequest({}).dropFullCache();
        return data;
      });
  }
}

/**
 * File: pdBlockHostsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import PdComputersRequest from './pdComputersRequest';
import SubscriptionsRequest from './subscriptionsRequest';

class PdBlockHostsRequestSingle extends ApiRequest {
  /**
   *
   * @param {Object} params
   * @param {String} params.hw_ids
   * @param {?String} params.comment
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get method () {
    return 'POST';
  }

  get url () {
    return '/desktop/api/v1/ba/hosts/block';
  }

  get body () {
    return {
      hw_id_list: this.params.hwIds,
      comment: this.params.comment || '',
    };
  }
}

export default class PdBlockHostsRequest extends CombinedApiRequest {
  maxHwIdList = 100;

  constructor (params) {
    super({ consistently: true });

    for (let i = 0; i < params.hwIds.length; i += this.maxHwIdList) {
      this.addRequest(`PdBlockHostsRequest${i}`, new PdBlockHostsRequestSingle({
        hwIds: params.hwIds.slice(i, i + this.maxHwIdList),
        comment: params.comment,
      }));
    }
  }

  load () {
    return super.load()
      .then((data) => {
        new PdComputersRequest({}).dropFullCache();
        new SubscriptionsRequest({}).dropFullCache();
        return data;
      });
  }
}

/**
 * File: socialRegisterRequest.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';

export default class SocialRegisterRequest extends ApiRequest {
  get url () {
    return '/account/webapp/social_register';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return { key: this.params.key, consent_id: this.params.consentId };
  }
}

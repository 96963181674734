/**
 * File: rasSubscriptionRequest.ts
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import RasSubscription from '@/models/rasSubscription';

export default class RasSubscriptionRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'ras_subscription';
  }

  get url () {
    return `/ras/webapp/subscription/${this.params.uuid}`;
  }

  /**
   *
   * @returns {RasSubscription} - instance of RasSubscriptions model.
   */
  getSubscription () {
    if (this.data) {
      return new RasSubscription(this.data);
    }

    return null;
  }
}

<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

card(type="product", :equalTo="equalTo", v-bind:class="[ defaultClass ]", icon="pdfc")
  template(slot="title")
    .text-ellipsis {{ $t(item.productName) }}
  template(slot="actions")
    icon(name="download", @click="$router.push(getDownloadRoute)")
  template(slot="about") {{ $t('Enterprise and Education Edition') }}
  template(slot="info")
    list.padding-bottom-xs
      router-link(
        :to="{ name: 'desktopSubscriptions', query: { display: STATUS_ACTIVE }, params: { product: item.productKeyName } }",
        :data-name="$name('router-link-desktop-subscriptions-active')"
      )
        .row
          .col-xs-10.text-ellipsis {{ $t('Active subscriptions') }}
          .col-xs-1
            router-link(
              v-if="item.hasExpiringSubscriptions",
              :to="{ name: 'desktopSubscriptions', query: { display: STATUS_IS_EXPIRING_SOON }, params: { product: item.productKeyName }  }",
              :data-name="$name('router-link-desktop-subscriptions-expiring')"
            )
              icon(name="alert-yellow").pull-right
          .col-xs-1.text-right.text-link.text-bold {{ item.activeSubscriptions }}
      router-link(
        v-if="item.expiredSubscriptions",
        :to="{ name: 'desktopSubscriptions', query: { display: STATUS_EXPIRED }, params: { product: item.productKeyName } }",
        :data-name="$name('router-link-desktop-subscriptions-expired')"
      )
        .row
          .col-xs-9.text-ellipsis {{ $t('Expired subscriptions') }}
          .col-xs-3.text-right.text-link.text-bold {{ item.expiredSubscriptions }}
  template(slot="menu")
    btn.block-xs.margin-right-2x-sm.margin-top-2x(
      v-if="session.isBusinessAdmin && item.canBuyMoreLicenses",
      @click="showAddLicensesModal",
      size="small",
      color="white",
      :data-name="$name('button-by-more-licenses')"
    ) {{ $t('Buy More Licenses') }}
    btn.block-xs.margin-right-2x-sm.margin-top-2x(
      size="small",
      color="white",
      :data-name="$name('button-support')",
      @click="onSupportClick"
    ) {{ $t('Support') }}
    btn.block-xs.margin-top-2x(
      target="_blank",
      size="small",
      color="white",
      :href="googleConsoleLink"
    ) {{ $t('Manage in Google Admin Console') }}

</template>

<script>

import ProductCardMixIn from '../productCardMixIn';
import { GOOGLE_ADMIN_CONSOLE_URL } from '@core/constants/links';
import { DESKTOP_DOWNLOADS_PAGE } from '@/routes/constants';

export default {
  name: 'product-card-pdfc',
  mixins: [ProductCardMixIn],
  computed: {
    googleConsoleLink () {
      return `${GOOGLE_ADMIN_CONSOLE_URL}?hl=${this.$appData.session.language}`;
    },
  },
  data: function () {
    return {
      DESKTOP_DOWNLOADS_PAGE: DESKTOP_DOWNLOADS_PAGE,
    };
  },
};

</script>

<copyright>
File: productCardPDB.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

card(type="product", :equalTo="equalTo", v-bind:class="[ defaultClass ]", icon="pd", data-qa="pdb-card")
  template(slot="title")
    .text-ellipsis(data-qa="pdb-product-name") {{ productName }}
  template(slot="actions")
    icon(name="download", @click="$router.push(getDownloadRoute)", data-qa="pdb-download-icon")
  template(slot="about")
    span(data-qa="pdb-total-limit") {{ $tc('for {limit} computers', item.totalLimit, { limit: item.totalLimit }) }}
  template(slot="info")
    list.padding-bottom-xs
      router-link(
        :to="{ name: 'desktopSubscriptions', query: { display: STATUS_ACTIVE }, params: { product: item.productKeyName } }",
        :data-name="$name('router-link-desktop-subscriptions-active')",
        data-qa="pdb-desktop-subscriptions-active"
      )
        .row
          .col-xs-10.text-ellipsis(data-qa="pdb-active-subscriptions-lbl") {{ $t('Active subscriptions') }}
          .col-xs-1
            router-link(
              v-if="item.hasExpiringSubscriptions",
              :to="{ name: 'desktopSubscriptions', query: { display: STATUS_IS_EXPIRING_SOON }, params: { product: item.productKeyName }  }",
              :data-name="$name('router-link-desktop-subscriptions-expiring')",
              data-qa="pdb-desktop-subscriptions-expiring"
            )
              icon(name="alert-yellow").pull-right
          .col-xs-1.text-right.text-link.text-bold(data-qa="pdb-active-subscriptions-count") {{ item.activeSubscriptions }}
      router-link(
        v-if="item.expiredSubscriptions",
        :to="{ name: 'desktopSubscriptions', query: { display: STATUS_EXPIRED }, params: { product: item.productKeyName } }",
        :data-name="$name('router-link-desktop-subscriptions-expired')",
        data-qa="pdb-desktop-subscriptions-expired"
      )
        .row
          .col-xs-9.text-ellipsis(data-qa="pdb-expired-subscriptions-lbl") {{ $t('Expired subscriptions') }}
          .col-xs-3.text-right.text-link.text-bold {{ item.expiredSubscriptions }}
      router-link(
        :to="{ name: 'desktopComputers', params: { product: item.productKeyName } }",
        :data-name="$name('router-link-desktop-computers')"
      )
        .row
          .col-xs-9.text-ellipsis(data-qa="pdb-registered-computers-lbl") {{ $t('Registered computers') }}
          .col-xs-3.text-right.text-link.text-bold
            loader.pull-right(:loading="activeComputersLoading", size="2", data-qa="pdb-registered-computers-count") {{ activeComputers }}
      template(v-if="$appData.session.isBusinessAdmin")
        router-link(
          :to="{ name: 'desktopComputers', query: { mode: 'deactivated' }, params: { product: item.productKeyName } }",
          :data-name="$name('router-link-desktop-computers-deactivated')",
          data-qa="pdb-desktop-computers-deactivated"
        )
          .row
            .col-xs-9.text-ellipsis(data-qa="pdb-deactivated-computers-lbl") {{ $t('Deactivated computers') }}
            .col-xs-3.text-right.text-link.text-bold
              loader.pull-right(:loading="deactivatedComputersLoading", size="2", data-qa="pdb-deactivated-computers-count")  {{ deactivatedComputers }}
        .row(v-if="isCepOptionAccessible").custom-style-info
          .col-xs-10.text-ellipsis(data-qa="pdb-enable-customer-exp-prgm-lbl") {{ $t('Enable Customer Experience Program') }}
          .col-xs-1
            icon(name="info-small", @click="openCepInfo", data-qa="pdb-customer-exp-prgm-icon").pull-right
          .col-xs-1
            loader.pull-right(:loading="setCepOptionLoading", size="2")
              checkbox(
                :val="cepOption",
                @change="setCepOption",
                :data-name="$name('checkbox-cep-option')",
                data-qa="pdb-customer-exp-prgm-checkbox"
              )
  template(slot="menu")
    btn.block-xs.margin-right-2x-sm.margin-top-2x(
      v-if="session.isBusinessAdmin && item.canBuyMoreLicenses",
      @click="showAddLicensesModal",
      size="small",
      color="white",
      :data-name="$name('button-by-more-licenses')",
      data-qa="pdb-buy-more-licenses-button"
    ) {{ $t('Buy More Licenses') }}
    btn.block-xs.margin-right-2x-sm.margin-top-2x(
      v-if="inviteAvailable",
      @click="$refs.subscriptionInviteModal.show()",
      size="small",
      color="white",
      :data-name="$name('button-invite-users')",
      data-qa="pdb-invite-users-button"
    )
      | {{ $t('Invite Users') }}
    btn.block-xs.margin-top-2x(
      target="_blank",
      size="small",
      color="white",
      :data-name="$name('button-support')",
      data-qa="pdb-support-button",
      @click="onSupportClick"
    )
      | {{ $t('Support') }}

    subscriptionInviteModal(
      v-if="inviteAvailable",
      ref="subscriptionInviteModal",
      :product="item.productKeyName",
      :session="session",
      :data-name="$name('subscription-invite-modal')"
    )

</template>

<script>
import {
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_NAME_PDB,
  PRODUCT_NAME_PDFC
} from '@core/constants/subscriptions';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductCardMixIn from './productCardMixIn';
import PdComputersRequest from '@/api/pdComputersRequest';
import subscriptionInviteModal from '@/modules/desktop/subscriptionInvite/subscriptionInviteModal.vue';
import { DESKTOP_DOWNLOADS_PAGE } from '@/routes/constants';

export default {
  name: 'product-card-pdb',

  mixins: [ComponentMixIn, ProductCardMixIn],

  components: { subscriptionInviteModal },

  data: function () {
    return {
      activeComputersLoading: true,
      activeComputers: null,
      deactivatedComputersLoading: true,
      deactivatedComputers: null,
      DESKTOP_DOWNLOADS_PAGE: DESKTOP_DOWNLOADS_PAGE,
    };
  },

  created: function () {
    const activeComputersRequest = new PdComputersRequest({
      activated: true,
      totalOnly: true,
      product: this.item.productKeyName,
    });
    const deactivatedComputersRequest = new PdComputersRequest({
      activated: false,
      wo_blocked: true,
      totalOnly: true,
      product: this.item.productKeyName,
    });

    this.authorizedCall(activeComputersRequest, 'activeComputersLoading')
      .then((data) => {
        this.activeComputers = data.total;
      });

    this.authorizedCall(deactivatedComputersRequest, 'deactivatedComputersLoading')
      .then((data) => {
        this.deactivatedComputers = data.total;
      });
  },

  computed: {
    productName () {
      return this.item.productKeyName === PRODUCT_KEY_NAME_PDFC ? this.$t(PRODUCT_NAME_PDFC) : this.$t(PRODUCT_NAME_PDB);
    },

    inviteAvailable () {
      return this.item.productKeyName === PRODUCT_KEY_NAME_PDB;
    },
  },
};
</script>

<style scoped lang="sass">
.custom-style-info /deep/
  .info-small
    background-color: #3D73D8 !important
  .icon
    margin-top: $grid-step / 3
.checkbox
  margin-top: -$grid-step / 3
</style>

/**
 * File: rtUpdateTicketRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import RtRequest from './rtRequest';
import { Json, Method } from '@core/api/apiRequest';
import { Dictionary } from '@core/common/types';
import { TICKET_FIELD_CASE_ORIGIN } from '@/api/rt/constants';

export interface RtUpdateTicketRequestParams extends Dictionary {
  ticketId: number|string;
}

export default class RtUpdateTicketRequest extends RtRequest<RtUpdateTicketRequestParams, Json> {
  json = false;

  get uri (): string {
    return `/ticket/${this.params.ticketId}`;
  }

  get method (): Method {
    return 'PUT';
  }

  get body (): any {
    const fields: any = {};
    for (const field of ['status', 'summary', 'description', 'comment', 'share', 'severity']) {
      const value = this.params[field];
      if (value !== undefined) {
        fields[field] = value;
      }
    }

    if (this.params.case_origin) {
      fields[TICKET_FIELD_CASE_ORIGIN] = this.params.case_origin;
    }

    return { fields };
  }
}

/**
 * File: chatClientBeacon.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import { ChatClientProvider, ChatStatus, MessageType } from '@/modules/support/content/chatClient/chatClientProvider';
import Vue from 'vue';

const API_ROOT = 'https://chatbeacon.corel.com/chatbeacon/corel/{SITE_ID}/api/visitor/account';
const WEB_SOCKET_URL = 'wss://chatbeacon.corel.com/chatbeacon/corel/{SITE_ID}/api/ws/chat';

const WEB_SOCKET_PING_INTERVAL = 25000;

const SURVEY_TYPE_PRE_CHAT = 1;

const SESSION_REQUEST_DATA = {
  resolution: [window.screen.availHeight, window.screen.availWidth, window.screen.colorDepth].join('x'),
  gdpr: false,
  useragent: navigator.userAgent,
};

const INITIAL_SESSION_DATA = {
  accountId: 0,
  siteId: 0,
  visitorId: '00000000-0000-0000-0000-000000000000',
  sessionId: '00000000-0000-0000-0000-000000000000',
};

type UserProfile = {
  name: string;
  email: string;
  company: string;
}

type ChatBeaconConfig = {
  accountId: number;
  siteId: number;
  queueId: number;
}

export default class ChatClientBeacon implements ChatClientProvider {
  readonly name: string;
  private socket: WebSocket
  private context: Vue
  private config: ChatBeaconConfig
  private apiRoot: string = ''
  private webSocketUrl: string = ''
  private messagesCount: number = 0
  private chatId: number
  private chatStatus: ChatStatus = 0
  private isOperatorTyping: boolean = false
  private hiddenUserMessages: number = 0
  private sessionData: Record<string, any> = null
  private userProfile: UserProfile = null
  private ticketId: number
  private objectUrls: Array<string> = []
  private socketKeepAliveInterval: ReturnType<typeof setInterval> = null

  constructor (context: Vue, config: ChatBeaconConfig, profile: UserProfile, ticketId: number = null) {
    this.name = 'Beacon';
    this.context = context;
    this.config = config;
    this.userProfile = profile;
    this.ticketId = ticketId;

    this.apiRoot = API_ROOT.replace('{SITE_ID}', this.config.siteId.toString());
    this.webSocketUrl = WEB_SOCKET_URL.replace('{SITE_ID}', this.config.siteId.toString());

    this.sessionData = { ...INITIAL_SESSION_DATA, accountId: this.config.accountId, siteId: this.config.siteId };

    this.connect();
  }

  private connect () {
    this.requestSession()
      .then((sessionData) => {
        this.sessionData = { ...this.sessionData, ...sessionData };
        this.sendSurvey().then((survey) => {
          this.chatId = survey.request.id;
          this.sendVariables();
          this.initSocket();
        });
      });
  }

  private initSocket () {
    this.socket = new WebSocket(this.webSocketUrl);
    this.socket.onopen = () => {
      this.socket.send(this.getSocketAuth());
      this.socketKeepAliveInterval = setInterval(() => {
        this.socket.send(this.getSocketAuth());
      }, WEB_SOCKET_PING_INTERVAL);
    };
    this.socket.onmessage = (event) => {
      this.onMessageReceived(event);
    };
    this.socket.onerror = () => {
      // Safari NSURLSession WebSocket API bug workaround.
      // Don't throw error if disconnection status received with message data.
      if ([ChatStatus.Canceled, ChatStatus.Disconnected].includes(this.chatStatus)) {
        this.context.$emit('change-status', ChatStatus.Error);
      }
    };
    this.socket.onclose = () => {
      clearInterval(this.socketKeepAliveInterval);
    };
  }

  public disconnect (): Promise<any> {
    if (this.objectUrls.length) {
      this.objectUrls.forEach((item) => {
        URL.revokeObjectURL(item);
      });
    }
    this.context.$off('ready');
    if (this.chatStatus === ChatStatus.Active) {
      this.context.$emit('disconnected');
    }
    if (this.chatStatus === ChatStatus.WaitingForAgent) {
      return this.apiCall('/site/queue/request', null, 'text', 'DELETE');
    }
    return this.apiCall('/site/queue/chat', null, 'text', 'DELETE');
  }

  private onMessageReceived (event: MessageEvent) {
    const data = JSON.parse(event.data);

    if (this.chatStatus !== data.chat.status) {
      this.chatStatus = data.chat.status;
      this.context.$emit('change-status', data.chat.status);
      if (this.chatStatus === ChatStatus.Active) {
        this.context.$emit('ready');
      }
    }

    if ([ChatStatus.Canceled, ChatStatus.Disconnected].includes(data.chat.status)) {
      this.socket.close();
      this.context.$emit('disconnected');
    }

    if (this.isOperatorTyping !== !!data.chat.operatorTyping) {
      this.isOperatorTyping = !!data.chat.operatorTyping;
      this.context.$emit('typing', this.isOperatorTyping);
    }

    if (!data.chat.segments || !data.chat.segments.length) return;

    const messages = data.chat.segments.filter((msg) => [MessageType.AgentInput, MessageType.UserInput].includes(msg.type));
    if (messages.length === this.messagesCount) {
      return;
    }

    const newMessages = messages.length > 1 ? messages.slice((messages.length - this.messagesCount) * -1) : messages;
    newMessages.forEach(async (msg) => {
      this.messagesCount++;
      if (msg.media) {
        const attachment = await this.getFileLink(msg.media);
        this.context.$emit('message', 'image', msg.type, attachment);
        return;
      }
      msg.text = this.cleanupMessage(msg.text);
      if (data.chat.translating) {
        msg.translation.text = this.cleanupMessage(msg.translation.text);
      }
      if (msg.type === MessageType.AgentInput || (!this.hiddenUserMessages && msg.type === MessageType.UserInput)) {
        this.context.$emit('message', 'text', msg.type, msg);
      } else {
        this.hiddenUserMessages -= 1;
      }
    });
  }

  private cleanupMessage (message: string): string {
    return message.replaceAll(
      /\[a url="(https?:\/\/)?([^"]+)"\]([^[]+)\[\/a\]/g,
      'https://$2'
    );
  }

  private getFileLink (media: any): Promise<any> {
    return this.apiCall(
      `/site/queue/chat/file?fileId=${media.id}&chatId=${this.chatId}`,
      null,
      'blob',
      'GET'
    ).then((data) => {
      const objectUrl = URL.createObjectURL(data);
      this.objectUrls.push(objectUrl);
      return {
        type: /image\/(png|gif|jpeg)/.test(media.mime) ? 'image' : 'file',
        name: media.name,
        url: objectUrl,
      };
    });
  }

  public send (message: string, hidden = false): Promise<any> {
    if (hidden) {
      this.hiddenUserMessages += 1;
    }
    return this.apiCall(
      '/site/queue/chat/segment',
      {
        segment: {
          text: message,
          type: MessageType.UserInput,
          chatId: this.chatId,
          action: 0,
        },
      },
      'text'
    );
  }

  private requestSession (): Promise<any> {
    return this.apiCall('/site/session', SESSION_REQUEST_DATA);
  }

  private sendVariables (): Promise<any> {
    return this.apiCall(
      '/site/session/variables',
      {
        variables: [
          {
            siteId: this.config.siteId,
            value: this.ticketId,
            name: 'ticket_id',
          },
        ],
      },
      'text'
    );
  }

  private sendSurvey (): Promise<any> {
    return this.apiCall(
      '/site/queue/survey',
      {
        survey: {
          id: 0,
          queueId: this.config.queueId,
          type: SURVEY_TYPE_PRE_CHAT,
          items: [
            { id: 828, type: 12, value: this.userProfile.name },
            { id: 829, type: 1, value: this.userProfile.company },
            { id: 830, type: 6, value: this.userProfile.email },
          ],
        },
      }
    );
  }

  private apiCall (url: string, body: Record<string, any> = null, type: string = 'json', method: string = 'POST'): Promise<any> {
    const request: any = {
      headers: {
        Accept: '*/*',
        'x-chatbeacon': this.getAuthHeader(),
      },
      method: method,
    };
    if (method === 'POST') {
      request.headers['Content-Type'] = 'application/json';
    }
    if (body) {
      request.body = JSON.stringify(body);
    }
    return fetch(`${this.apiRoot}${url}`, request)
      .then((response) => {
        return response[type]();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        this.context.$emit('message', 'text', 'system', {
          text: `Something went wrong! Error: ${error}`,
        });
      });
  }

  private getAuthHeader () {
    return Buffer.from(Object.values(this.sessionData).join(':')).toString('base64');
  }

  private getSocketAuth () {
    return Buffer.from(JSON.stringify({ ...this.sessionData, chatId: this.chatId })).toString('base64');
  }
}

/**
 * File: browserTrustRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class BrowserTrustRequest extends ApiRequest {
  get url () {
    return '/account/api/v1/browser/mark_trusted';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return { id: this.params.id };
  }
}

/**
 * File: deleteSubsetRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import CreateSubsetRequest from './createSubsetRequest';
import { getServiceUrl } from '@core/api/routing';

export default class DeleteSubsetRequest extends CreateSubsetRequest {
  get method () {
    return 'DELETE';
  }

  get url () {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}/subsets/${this.params.subsetUuid}`);
  }

  getJson () {
    // No JSON content expected here
    return null;
  }
}

/**
 * File: deleteProfileRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import md5 from 'blueimp-md5';
import { support } from '@core/constants/links';

export default class DeleteProfileRequest extends ApiRequest {
  get url () {
    return '/account/api/v1/profile/delete';
  }

  get method () {
    return 'POST';
  }

  get body () {
    return {
      password: md5(this.params.password),
    };
  }

  get errors () {
    return {
      'rate limited': $t('Too many deletion requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    };
  }
}

/**
 * File: listBusinessDomainsRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

interface RequestParams {
  'get_signed_domains_for': string;
}

export interface BusinessDomain {
  id: number;
  uid: number;
  name: string;
  status: string;
  'is_admin': boolean;
  'is_business': boolean;
  'is_invite': boolean;
  'can_leave': boolean;
  country: string;
  state: string;
  city: string;
  'mfa_status'?: boolean;
}

export type ListBusinessDomainsRequestResponse = {
  domains: BusinessDomain[];
  'signed_domains_ids': string;
}

export default class ListBusinessDomainsRequest extends ApiRequest<RequestParams, ListBusinessDomainsRequestResponse> {
  get url () {
    const uri = '/account/webapp/list_business_domains';
    const queryString = this.buildQueryString({
      get_signed_domains_for: this.params.get_signed_domains_for,
    });

    return queryString ? uri + queryString : uri;
  }

  get cacheNameSpace () {
    return null;
  }
}

<copyright>
File: invitationCard.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

card(type="info", :equalTo="equalTo")
  template(slot="title")
    .text-ellipsis {{ domain.name }}
  template(slot="info")
    .margin-bottom-3x
      list
        list-item(v-if="location")
          template(slot="label") {{ $t('Location') }}
          template(slot="value") {{ location }}
        list-item
          template(slot="label") {{ $t('Role') }}
          template(slot="value") {{ role }}
  template(slot="menu")
    .text-right.line-height-4x
      loader.pull-right(:loading="loading")
        template(v-if="domain.is_invite && !domain.dismissed")
          btn.pull-right.margin-bottom.margin-left-sm.block-xs(
            color="blue",
            @click="accept",
            :data-name="$name('button-join-account')"
          ).block-xs {{ $t('Join Account') }}
          btn.pull-right.block-xs(
            color="red-stroke",
            @click="dismiss",
            :data-name="$name('button-dismiss-invitation')"
          ) {{ $t('Dismiss Invitation') }}
        template(v-else-if="!domain.is_invite")
          icon(name="check-green", :text="$t('Invitation accepted')", key="check-green")
        template(v-else-if="domain.is_invite && domain.dismissed")
          icon(name="stop", :text="$t('Invitation dismissed')", key="stop")

</template>

<script>

import DismissInvitationRequest from '@/api/dismissInvitationRequest';
import { STATES } from '@core/constants/geo';
import { getCountriesWithEmbargoed } from '@/plugins/sets';
import { HTTP_CODES } from '@core/constants/http';

export default {
  name: 'invitation-card',
  props: {
    domain: {
      type: Object,
    },
    equalTo: {
      type: String,
    },
  },

  data () {
    return {
      loading: false,
    };
  },

  computed: {
    role () {
      return this.domain.is_admin ? this.$t('Administrator') : this.$t('Member');
    },
    location () {
      const location = [];
      const domainCountry = this.domain.country;
      if (domainCountry) {
        const country = getCountriesWithEmbargoed(domainCountry)[domainCountry] || domainCountry;
        location.push(country);
      }
      if (this.domain.state) {
        const state = STATES[this.domain.country] && STATES[this.domain.country][this.domain.state];
        location.push(state || this.domain.state);
      }
      if (this.domain.city) {
        location.push(this.domain.city);
      }
      return location.join(', ');
    },
  },

  methods: {
    dismiss () {
      this.loading = true;

      const request = new DismissInvitationRequest(this.domain.id);

      this.$api.authorizedCall(request).then(() => {
        // eslint-disable-next-line vue/no-mutating-props
        this.domain.dismissed = true;
        this.$appData.session.invitationProcessed();
      }).catch((err) => {
        if (err.response && err.response.status === HTTP_CODES.CONFLICT) {
          // looks like invitation is already dismissed, so pretending that It succeeded
          // eslint-disable-next-line vue/no-mutating-props
          this.domain.dismissed = true;
          this.$appData.session.invitationProcessed();
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    accept () {
      this.$emit('acceptInvitation', {
        domainId: this.domain.id,
      });
    },
  },
};

</script>

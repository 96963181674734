/**
 * File: svcSubscriptionRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json } from '@core/api/apiRequest';
import Subscription from '@/models/subscription';
import { getServiceUrl } from '@core/api/routing';
import Service from '@core/constants/services';

export interface SvcSubscriptionRequestParams {
  uuid: string;
  service: Service;
}

export default class SvcSubscriptionRequest<T extends SvcSubscriptionRequestParams = SvcSubscriptionRequestParams> extends ApiRequest<T, { subscriptions: Json }> {
  get cacheNameSpace (): string {
    return 'subscriptions';
  }

  get url (): string {
    return getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.uuid}`);
  }

  getSubscription (): Subscription {
    if (this.data && this.data.subscriptions && this.data.subscriptions[0]) {
      return new Subscription(this.data.subscriptions[0]);
    }
    return null;
  }
}

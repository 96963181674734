
import Vue from 'vue';
import StepsMixIn from './StepsMixIn.vue';
import { STEP_EMAIL_NAME, STEP_LICENSE_KEY_NAME } from './constants';

export default Vue.extend({
  name: 'forgot-page-step-done',
  mixins: [StepsMixIn],
  props: {
    fromStep: {
      type: String,
    },
  },
  data () {
    return {
      STEP_EMAIL_NAME,
      STEP_LICENSE_KEY_NAME,
    };
  },
});

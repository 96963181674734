/**
 * File: azmpManagedApplications.ts
 *
 * Copyright:
 * Copyright © 2024 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import { V2Response } from '@/apiv2/V2RequestType';

interface AzmpManagedApplicationsResponse {
  'uuid': string;
  'activated_at': Date;
  'azure_subscription_uuid': string;
  'subscription_uuid': string;
  'display_name': string;
}

export default class AzmpManagedApplications extends ApiRequest<{}, V2Response<AzmpManagedApplicationsResponse[]>> {
  get method () {
    return 'GET' as const;
  }

  get url () {
    return '/ras-azmp/api/managed_apps/';
  }

  get cacheNameSpace (): string | undefined {
    return undefined;
  }
}

<!--
File: confirmation.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
-->

<script>

import ResendBrowserConfirmation from '@/api/resendBrowserConfirmation';
import ContactSupportModal, { CONFIRMATION_EMAIL_SCENARIO } from '@/modules/contactSupportModal';

const STORAGE_KEY_NAME = 'resend-email-attempts';
const MAX_ATTEMPTS = 2;

export default {
  name: 'confirmation',
  props: {
    code: String,
    appData: Object,
  },
  components: {
    ContactSupportModal,
  },
  data () {
    return {
      loading: false,
      attempts: this.$sessionStorage.get(STORAGE_KEY_NAME, {}),
      CONFIRMATION_EMAIL_SCENARIO,
    };
  },
  methods: {
    logout () {
      this.loading = true;
      this.$api.logout();
    },
    resend () {
      const token = this.$appData.session.token;
      const request = new ResendBrowserConfirmation();

      if (typeof this.attempts[token] === 'number') {
        this.attempts[token]++;
      } else {
        this.attempts[token] = 1;
      }

      this.loading = true;

      this.$api.authorizedCall(request).then(() => {
        this.$toast.show({
          color: 'green',
          text: $t('Confirmation email has been sent to the account email address.'),
        });

        if (this.attempts[token] > MAX_ATTEMPTS) {
          this.$modal.show(ContactSupportModal);
        } else {
          this.$sessionStorage.set(STORAGE_KEY_NAME, this.attempts);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    confirm () {
      this.loading = true;
      this.$api.refreshToken(true).then((data) => {
        if (data.trusted) {
          this.$router.push({ name: 'index' });
        } else {
          this.$toast.show({
            text: this.$t('Access has not been confirmed yet!'),
            color: 'orange',
          });
          this.loading = false;
        }
      });
    },
  },
};

</script>

<template lang="pug">

page(:appData="appData", :alignCenter="true")
  template(slot="pageContent")
    .row
      .col-md-8.col-md-offset-2.col-xs-12
        card(type="plain")
          loader(:loading="loading")
            .margin-bottom-2x
              .confirm-browser-image
            h2.margin-bottom.text-center {{ $t('Unrecognized Web Browser') }}
            .text-center {{ $t('To prevent unauthorized access to your data, confirm that you trust the web browser which is currently being used to login to your Parallels account.') }}
            .margin-bottom-2x.text-center {{ $t('Follow the instructions that have been sent to your account email.') }}
            .margin-bottom-3x.collapsible.text-muted
              collapsible
                template(#title) {{ $t('If you cannot find the email from Parallels') }}
                template(#content)
                  .margin-bottom 1. {{ $t('Check your spam/junk folders for emails from noreply@parallels.com. If there are such emails, mark the latest one as NOT spam and follow the instructions from it.') }}
                  .margin-bottom 2. {{ $t('If you cannot find any emails, add noreply@parallels.com to the trusted senders (e.g., to your list of contacts).') }}
                  .margin-bottom 3. {{ $t('Then repeat the “Resend Email” procedure and check for the email from Parallels again. Make sure to check in the spam/junk folders too.') }}
            .clearfix
              btn.pull-right-sm.margin-left-sm.block-xs.margin-bottom-xs(
                @click="confirm",
                :disabled="loading",
                :data-name="$name('button-access-allowed')"
                ) {{ $t('Continue') }}
              btn.pull-right-sm.block-xs.margin-bottom-xs(
                color="white",
                @click="resend",
                :disabled="loading",
                :data-name="$name('button-resend-email')"
              ) {{ $t('Resend Email') }}
              btn.pull-left-sm.block-xs(
                color="white",
                @click="logout",
                :data-name="$name('button-sign-out')"
              ) {{ $t('Sign Out') }}
    contact-support-modal(:scenario="CONFIRMATION_EMAIL_SCENARIO")

</template>

<style scoped lang="sass">

.confirm-browser-image
  width: 157px
  height: 132px
  margin: auto
  background: url(assets/pending.svg) no-repeat

.collapsible /deep/
  .title
    font-size: $base-font-size

</style>


import Vue from 'vue';
import { PDE_KEYS_CONFLICT_KB_ARTICLE_URL } from '@core/common/links';
import SuspendSubscriptionRequest from '@/api/suspendSubscriptionRequest';
import Subscription from '@/models/subscription';
import { prodKeyToName, PRODUCT_KEY_NAME_PDE } from '@core/constants/subscriptions';
import AddKeyFormMixin from '@/modules/addKey/addKeyMixin';
import { GET_SUPPORT_PAGE, SUBSCRIPTION_DETAILS_PAGE } from '@/routes/constants';
import { FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import { support as supportLinks } from '@core/constants/links';

export interface KeyConflictModalData {
  keyInfo: Subscription;
  existedSubscription: Subscription;
  requestParams: {};
  isSuspendable: boolean;
  existedLicKey: string;
  registeredLicKey: string;
}

export default Vue.extend({
  name: 'key-conflict-modal',
  mixins: [AddKeyFormMixin],
  data () {
    return {
      isSuspendable: false,
      loading: false,
      registeredLicKey: '',
      existedLicKey: '',
      existingSubscription: {},
      keyInfo: {},
      requestParams: {},
    };
  },
  computed: {
    kbLink (): string {
      return PDE_KEYS_CONFLICT_KB_ARTICLE_URL(this.$appData.session.isoLocale);
    },
    registeredProductName (): string {
      return prodKeyToName((this.keyInfo as Subscription)?.firstProductKeyName) || '';
    },
    existingProductName (): string {
      return prodKeyToName((this.existingSubscription as Subscription)?.firstProductKeyName) || '';
    },
    existingSubscriptionExpirationDate (): Date | string {
      return (this.existingSubscription as Subscription)?.expirationDate || '';
    },
  },
  methods: {
    show (e, data: KeyConflictModalData) {
      this.registeredLicKey = data.registeredLicKey;
      this.isSuspendable = data.isSuspendable;
      this.existingSubscription = data?.existedSubscription;
      this.existedLicKey = data.existedLicKey;
      this.keyInfo = data.keyInfo;
      this.requestParams = data.requestParams;
    },
    closeModal (): void {
      this.$modal.hide();
    },
    openSupport () {
      if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
        this.$router.push({ name: GET_SUPPORT_PAGE, params: { product: PRODUCT_KEY_NAME_PDE } });
      } else {
        window.location.href = supportLinks[PRODUCT_KEY_NAME_PDE];
      }
    },
    async suspendSubscription () {
      this.loading = true;
      try {
        const newSubscriptionUuid = (this.keyInfo as Subscription).uuid;
        const request = new SuspendSubscriptionRequest({ uuid: (this.existingSubscription as Subscription).uuid });
        await this.$api.authorizedCall(request);
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
        await this.sendAddKeyRequest(this.requestParams);
        this.$router.push({ name: SUBSCRIPTION_DETAILS_PAGE, params: { id: newSubscriptionUuid } });
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
  },
});

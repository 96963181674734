/**
 * File: universalKey.ts
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';
import Subscription from '@/models/subscription';

export enum UniversalKeyType {
  Primary = 'Primary',
  Reserved = 'Reserved',
  Dynamic = 'Dynamic'
}

export default class UniversalKey extends CoreModel {
  subscription: Subscription;
  subscriptionUuid: string;
  subsetUuid: string;
  key: string;
  quota: number;
  used: number;
  vacant: number;
  isLimited: boolean;
  expirationDate: Date;
  isBlacklisted: boolean;

  __name: string;
  __licKey: string;
  __resources: any[]; // todo: create resource model

  constructor (data, subscription?: Subscription) {
    super(data);
    this.subscription = subscription;
  }

  get licKey () {
    return this.key || this.__licKey;
  }

  get name () {
    return this.__name || '';
  }

  set name (value) {
    this.__name = value;
  }

  get limit () {
    /**
     * Alias for backward compatibility.
     */
    return this.quota;
  }

  get usage () {
    /**
     * Alias for backward compatibility.
     */
    return this.used;
  }

  get available () {
    /**
     * Alias for backward compatibility.
     */
    return this.vacant;
  }

  get type (): UniversalKeyType {
    if (!this.subsetUuid) {
      return UniversalKeyType.Primary;
    }

    if (this.firstResource) {
      if (this.firstResource.reservation) {
        return UniversalKeyType.Reserved;
      } else {
        return UniversalKeyType.Dynamic;
      }
    }
  }

  get isPrimary () {
    return this.type === UniversalKeyType.Primary;
  }

  get resources () {
    return this.__resources || [];
  }

  get firstResource () {
    return this.resources[0];
  }
}


import Vue from 'vue';

import GetBrowserInfoRequest from '@/api/getBrowserInfoRequest';
import ConfirmBrowserRequest from '@/api/confirmBrowserRequest';
import { Browser } from '@/models/trustedEntity';
import { LOGIN_PAGE } from '@/routes/constants';

export default Vue.extend({
  name: 'trust',
  props: {
    code: String,
    appData: Object,
  },
  data () {
    return {
      loading: false,
      browser: new Browser({}),
      error: false,
    };
  },
  mounted () {
    const
      getInfoRequest = new GetBrowserInfoRequest({
        code: this.code,
      });
    const confirmRequest = new ConfirmBrowserRequest({
      code: this.code,
    });

    this.loading = true;

    this.$api.call(getInfoRequest).then(() => {
      this.browser = getInfoRequest.getBrowser();
      return this.$api.call(confirmRequest);
    }).then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
      this.error = true;
    });
  },
  methods: {
    async signIn () {
      this.loading = true;
      try {
        await this.$api.refreshToken(true);
        const customRouterData = this.browser.parsedRouterData;
        if (customRouterData) {
          this.$router.push(customRouterData);
        } else {
          this.$router.push({
            name: LOGIN_PAGE,
          });
        }
      } catch (e) {
        this.loading = false;
      }
    },
  },
});


/**
 * File: changeAuthenticatorAppPropertiesRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface ChangeAuthenticatorAppPropertiesRequestParams {
  newName: string;
  authAppOuterId: string;
}

export default class ChangeAuthenticatorAppPropertiesRequest extends ApiRequest<ChangeAuthenticatorAppPropertiesRequestParams> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/auth_apps/${this.params.authAppOuterId}`;
  }

  get method (): Method {
    return 'PUT';
  }

  get body (): Json {
    return {
      new_name: this.params.newName,
    };
  }
}

/**
 * File: businessProfile.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

import CompanyProfileRequest from '@/api/companyProfileRequest';
import BusinessProfileGeneral from './businessProfileGeneral.vue';
import BusinessProfileMembers from './businessProfileMembers/businessProfileMembers.vue';
import BusinessProfileSecurity from './businessProfileSecurity/mfaSettings.vue';
import BusinessProfileIdPIntegration from './businessProfileIdPIntegration/businessProfileIdPIntegration.vue';
import {
  BUSINESS_PROFILE_SCOPE,
  GENERAL_PAGE,
  IDP_INTEGRATION_PAGE,
  SECURITY_PAGE,
  SSO_MEMBER_DETAILS_PAGE,
  SSO_USERS_PAGE,
  USERS_PAGE
} from '@/routes/constants';
import { FEATURE_SIGN_IN_WITH_SSO, FEATURE_TWO_STEP_VERIFICATION_VIA_TOTP } from '@core/constants/features';
import BusinessProfileMembersSSO
  from '@/modules/businessProfile/businessProfileMembersSSO/businessProfileMembersSSO.vue';
import LsSubscriptionsRequest from '@/api/lsSubscriptionsRequest';
import { BUSINESS_PRODUCTS, RESOURCE_PDB_PER_USER_BY_SUBSCRIPTION_KEY_NAME } from '@core/constants/subscriptions';
import SSOCompanyUser from '@core/models/ssoCompanyUser';
import { SSOCompanyUsersRequest } from '@/api/sso/ssoCompanyUsersRequest';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'business-profile',

  props: {
    appData: {
      type: Object,
    },
    page: {
      type: String,
      default: GENERAL_PAGE,
    },
  },
  mixins: [BreadcrumbsMixin],
  components: {
    BusinessProfileGeneral,
    BusinessProfileMembers,
    BusinessProfileMembersSSO,
    BusinessProfileSecurity,
    BusinessProfileIdPIntegration,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  data () {
    return {
      employeesAmount: 0,
      USERS_PAGE,
      SSO_USERS_PAGE,
      SECURITY_PAGE,
      IDP_INTEGRATION_PAGE,
      isPerUserSubscriptionAvailable: false,
    };
  },

  computed: {
    subpages (): MenuItem[] {
      const subPages = [
        {
          text: this.$t('General'),
          link: { name: BUSINESS_PROFILE_SCOPE, params: { page: GENERAL_PAGE } },
        },
      ];
      if (this.isTotpAccessible && !this.isSso) {
        subPages.push({
          text: this.$t('Security'),
          link: { name: BUSINESS_PROFILE_SCOPE, params: { page: SECURITY_PAGE } },
        });
      }
      if (this.isSso) {
        subPages.push({
          text: this.$t('Users ({count})', { count: this.employeesAmount }),
          link: { name: BUSINESS_PROFILE_SCOPE, params: { page: SSO_USERS_PAGE } },
        });
      } else {
        subPages.push({
          text: this.$t('Users ({count})', { count: this.employeesAmount }),
          link: { name: BUSINESS_PROFILE_SCOPE, params: { page: USERS_PAGE } },
        });
      }
      if (this.isFeatureAccessible(FEATURE_SIGN_IN_WITH_SSO) || this.isPerUserSubscriptionAvailable) {
        subPages.push({
          text: this.$t('SSO'),
          link: { name: BUSINESS_PROFILE_SCOPE, params: { page: IDP_INTEGRATION_PAGE } },
        });
      }
      return subPages;
    },
    isTotpAccessible (): boolean {
      return this.$appData.session?.isFeatureAccessible(FEATURE_TWO_STEP_VERIFICATION_VIA_TOTP);
    },
    isSso (): boolean {
      return this.$appData.session.sso;
    },
  },

  async created () {
    if (this.$appData.session.sso) {
      const ssoCompanyUsersRequest = new SSOCompanyUsersRequest({ companyUuid: this.$appData.session.businessDomainId });
      const data = await this.$api.authorizedCall(ssoCompanyUsersRequest);
      this.employeesAmount = data.users.length;
    } else {
      const companyRequest = new CompanyProfileRequest({}, this.$appData.session.businessDomainId);
      const data = await this.$api.authorizedCall(companyRequest);
      this.employeesAmount = data.staff_count;
    }
    const lsPerUserSubscriptionsRequest = new LsSubscriptionsRequest({ type: BUSINESS_PRODUCTS, product: RESOURCE_PDB_PER_USER_BY_SUBSCRIPTION_KEY_NAME });
    const data = await this.$api.authorizedCall(lsPerUserSubscriptionsRequest);
    this.isPerUserSubscriptionAvailable = data.subscriptions.length > 0;
  },

  methods: {
    updateMembersAmount (params) {
      this.employeesAmount = params.amount;
    },
    isFeatureAccessible (featureName) {
      return this.$appData.session && this.$appData.session?.isFeatureAccessible(featureName);
    },
    showSSOUserDetails (member: SSOCompanyUser) {
      this.$router.push({ name: SSO_MEMBER_DETAILS_PAGE, params: { memberId: member.uuid } });
    },
  },

});

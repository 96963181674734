/**
 * File: automaticLicensesRevocation.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */
import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import { DESKTOP_SERVICE } from '@core/constants/services';
import { AutomaticLicensesRevocationPeriod } from '@core/constants/subscriptions';

/* eslint-disable camelcase */
interface AutomaticLicensesRevocationResponseData {
  per_user_automatic_license_revocation: AutomaticLicensesRevocationPeriod;
}
/* eslint-enable camelcase */

interface GetAutomaticLicensesRevocationRequestParams {
  subscriptionUuid: string;
}

interface ApplyAutomaticLicensesRevocationRequestParams {
  subscriptionUuid: string;
  period: string;
}

export class GetAutomaticLicensesRevocationRequest extends ApiRequest<GetAutomaticLicensesRevocationRequestParams, AutomaticLicensesRevocationResponseData> {
  get url (): string {
    return `/${DESKTOP_SERVICE}/api/v1/ba/subscriptions/${this.params.subscriptionUuid}/automatic_license_revocation`;
  }

  get cacheNameSpace () {
    return undefined;
  }

  get method (): Method {
    return 'GET';
  }
}

export class ApplyAutomaticLicensesRevocationRequest extends ApiRequest<ApplyAutomaticLicensesRevocationRequestParams, AutomaticLicensesRevocationResponseData> {
  get url (): string {
    return `/${DESKTOP_SERVICE}/api/v1/ba/subscriptions/${this.params.subscriptionUuid}/automatic_license_revocation`;
  }

  get method (): Method {
    return 'PUT';
  }

  get body (): Json {
    return {
      per_user_automatic_license_revocation: this.params.period,
    };
  }
}

/**
 * File: consent.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 **/

export default {
  required: true,
  id: undefined,
  url: undefined,
  init (params = {}) {
    this.required = typeof params.consent_required !== 'undefined' ? params.consent_required : true;
    this.id = params.consent_id;
    // Remove protocol://origin part of url
    // due URL web api is not supported by some browsers
    this.url = params.url && params.url.replace(/\w+:\/\/[^/]+/, '');
  },
};

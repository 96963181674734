/**
 * File: confirmEmailChangeRevert.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export class ConfirmEmailChangeRevert extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return `/account/webapp/confirm_email_change/revert/${this.params.actionCode}`;
  }
}

/**
 * File: subscriptionUniversalKeysRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import UniversalKey from '@/models/universalKey';
import { getServiceUrl } from '@core/api/routing';

export default class SubscriptionUniversalKeysRequest extends ApiRequest {
  get cacheNameSpace () {
    return 'universal_keys';
  }

  /**
   *
   * @param {Object} params
   * @param {String} params.service
   * @param {String} params.subscriptionUuid
   * @param {?Boolean} params.includeSubset
   */
  constructor (params) {
    super(params);
    this.params = params;
  }

  get url () {
    const url = getServiceUrl(this.params.service, `/api/v1/subscriptions/${this.params.subscriptionUuid}/universal_keys`);
    const qp = {};

    if (this.params.hasOwnProperty('includeSubsets')) {
      qp.include_subsets = this.params.includeSubsets;
    }

    const qs = this.buildQueryString(qp);

    return qs ? url + qs : url;
  }

  /**
   *
   * @returns {Array<UniversalKey>} - list of UniversalKey models.
   */
  getUniversalKeys () {
    return this.data && this.data.universal_keys.map((key) => new UniversalKey(key));
  }

  getPrimaryKey () {
    const key = this.data && this.data.universal_keys.find((key) => !key.subset_uuid);
    return key && new UniversalKey(key);
  }
}

/**
 * File: registerLegacyLicenseRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';

export default class RegisterLegacyLicenseRequest extends ApiRequest {
  get method () {
    return 'POST';
  }

  get url () {
    return '/desktop/api/v1/licenses/register';
  }

  get body () {
    return {
      lic_key: this.params.licenseKey,
    };
  }
}

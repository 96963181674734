/**
 * File: authenticatorAppsListRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Json } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

import { patchAuthenticatorAppName, AuthenticatorAppResponse } from '@/api/mfaUtils';
import AuthenticatorApp from '@/models/authenticatorApp';

/* eslint-disable camelcase */
interface AuthenticatorAppsListResponseData {
  auth_apps: AuthenticatorAppResponse[];
}
/* eslint-enable camelcase */

export default class AuthenticatorAppsListRequest extends ApiRequest<Json, AuthenticatorAppsListResponseData> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/mfa/auth_apps`;
  }

  getAuthenticatorApps (): AuthenticatorApp[] {
    return this.data.auth_apps.map((data, index) => {
      return new AuthenticatorApp(patchAuthenticatorAppName(data, index));
    });
  }
}

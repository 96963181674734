/**
 * File: rasBannersRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json } from '@core/api/apiRequest';
import RasNfrBanner from '@/models/rasNfrBanner';

interface RasBannersRequestResponseData {
  banners: Record<string, any>[];
}

export default class RasBannersRequest extends ApiRequest<Json, RasBannersRequestResponseData> {
  get url (): string {
    return '/ras/webapp/banners';
  }

  getBanners (): Array<RasNfrBanner> {
    return this.data.banners.map((item) => new RasNfrBanner(item));
  }
}

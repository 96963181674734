/**
 * File: getStatusPageDetails.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';

export default class GetStatusPageDetails extends ApiRequest {
  get url (): string {
    return '/system-status';
  }

  get method (): Method {
    return 'GET';
  }
}

/**
 * File: breadcrumbs.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import Session from '@/models/session';

export const getBreadcrumbsFirstItem = (vue: any): BreadcrumbsItem => {
  let option = {
    name: '',
    type: ''
  };
  const $appData: AppData = vue.$appData;
  const session: Session | undefined = $appData?.session;
  if (!session) {
    return option;
  }
  const userInDomain = $appData.userInDomain;
  const isPersonalDomain = session.personalDomainId === userInDomain;
  const isBusinessDomain = session.businessDomainId === userInDomain;
  const businessDomains = session.getBusinessDomains();
  const targetBusinessDomain = businessDomains.find((domain) => domain.companyUuid === userInDomain);
  if (isPersonalDomain && $appData.products?.personal.length) {
    return {
      name: (session.name && session.name.trim()) || session.email,
      type: vue.$t('Personal Account')
    };
  }
  if (isBusinessDomain && targetBusinessDomain) {
    option = {
      name: targetBusinessDomain.companyName,
      type: vue.$t('Business Account')
    };
  }
  return option;
};

/**
 * File: checkResetPasswordCodeRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest from '@core/api/apiRequest';
import { support } from '@core/constants/links';
import { camelize } from '@core/common/utils';

export default class CheckResetPasswordCodeRequest extends ApiRequest {
  get url () {
    return `/account/webapp/restore/${this.params.code}/reset`;
  }

  onFetchSuccess (data) {
    return camelize(data);
  }

  get errors () {
    return {
      'rate limited': $t('Too many password reset requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    };
  }
}

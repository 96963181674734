/**
 * File: subscriptionDetailsPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import SubscriptionNotification from './notification/index.vue';
import SubscriptionDetailsContent from './subscriptionDetailsContent.vue';
import Vue from 'vue';
import { PRODUCT_KEY_NAME_PSW } from '@core/constants/subscriptions';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default Vue.extend({
  name: 'subscription-details-page',
  mixins: [BreadcrumbsMixin],
  components: {
    SubscriptionNotification,
    SubscriptionDetailsContent,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  props: ['appData'],

  data () {
    return {
      error: false,
      subscription: null,
      displayNotification: true,
      isShowProperties: false,
    };
  },

  methods: {
    onNotFound () {
      this.error = true;
    },

    onSubscriptionLoaded (subscription) {
      this.subscription = subscription;
      this.isShowProperties = subscription.firstProductKeyName === PRODUCT_KEY_NAME_PSW;
    },
  },
});

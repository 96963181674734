
import Vue from 'vue';

import OneTimePasswordMixin from '@/modules/oneTimePasswordMixin';
import AddNewAuthenticatorAppForm
  from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/addNewAuthenticatorAppForm.vue';

export default Vue.extend({
  name: 'add-new-authenticator-app-modal',

  mixins: [OneTimePasswordMixin],
  props: {
    setupAccountLink: String,
    secret: String,
    asInline: Boolean,
  },

  components: { AddNewAuthenticatorAppForm },

  computed: {
    splitSecret (): string {
      const chunks = this.secret.match(/.{1,4}/g);
      return chunks ? chunks.join(' ') : '';
    },
  },
});


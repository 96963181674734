/**
 * File: rasPrepaidFarmsRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Json } from '@core/api/apiRequest.ts';
import RasHost from '@/models/rasHost.ts';

/* eslint-disable camelcase */
interface RasPrepaidFarmsResponseData {
  hosts: {
    activated_at: string | null;
    activated_offline: boolean;
    active: boolean;
    daily: number;
    farm_guid: string;
    hw_id: string;
    name: string;
    notes: string;
    postpaid_last_reported_at: string | null;
    prepaid_last_reported_at: string | null;
    product_version: string;
    universal_key: string | null;
    used_as_postpaid: boolean;
    used_as_prepaid: boolean;
  }[];
}
/* eslint-enable camelcase */

export default class RasPrepaidFarmsRequest extends ApiRequest<Json, RasPrepaidFarmsResponseData> {
  get cacheNameSpace (): string {
    return 'RasPrepaidFarmsRequest';
  }

  get url (): string {
    return '/ras/api/v1/prepaid_hosts';
  }

  getHosts (): RasHost[] {
    return this.data.hosts.map((data) => new RasHost(data));
  }
}


import Vue from 'vue';

import OneTimePasswordMixIn from '@/modules/oneTimePasswordMixin';

export default Vue.extend({
  name: 'web-browser-verification-modal',
  mixins: [OneTimePasswordMixIn],
  data () {
    return { otpMode: false };
  },
  methods: {
    show (e: { name: string }, otpMode: boolean = false) {
      this.otpMode = otpMode;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetOneTimePasswordField();
    },
  },
});


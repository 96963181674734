/**
 * File: getPdbDowngradeSeatsRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import ApiRequest, { Method } from '@core/api/apiRequest';
import { Resources } from '@core/constants/resources';
import { DESKTOP_SERVICE } from '@core/constants/services';

interface IGetPdbDowngradeSeatsRequestParams {
  'planned_date': string;
  resources: { 'resource_id': number; 'new_limit': number }[];
  uuid: string;
}

interface IDeactivatedHostResponse {
  activated: boolean;
  'activated_at': string;
  blocked: boolean;
  'build_number': any | null;
  'host_name': string;
  'last_connection_at': string;
  'serial_number': string;
  'updated_at': string;
}

export interface IGetPdbDowngradeSeatsResponse {
  'planned_date': string;
  uuid: string;
  resources: {
    'current_limit': number;
    'new_limit': number;
    'resource_id': Resources;
    'deactivated_hosts': IDeactivatedHostResponse[];
  }[];
}

export default class GetPdbDowngradeSeatsRequest extends ApiRequest<IGetPdbDowngradeSeatsRequestParams, IGetPdbDowngradeSeatsResponse> {
  constructor (params: IGetPdbDowngradeSeatsRequestParams) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get cacheNameSpace () {
    return undefined;
  }

  get method (): Method {
    return 'POST';
  }

  get body () {
    return {
      uuid: this.params.uuid,
      planned_date: this.params.planned_date,
      resources: this.params.resources,
    };
  }

  get url () {
    return `/${DESKTOP_SERVICE}/api/v1/subscriptions/forecast`;
  }
}

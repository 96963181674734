/**
 * File: ticketsMixIn.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';
import Ticket, { TicketStatus } from '@/models/ticket';

export default Vue.extend({
  name: 'ticket-mixin',

  methods: {

    getStatusColor (ticket: Ticket): string {
      const status = ticket.fields.status;

      if (status === TicketStatus.closed || status === TicketStatus.inEngineering || status === TicketStatus.merged) {
        return 'text-success';
      }
      if (status === TicketStatus.waitingMyResponse || status === TicketStatus.callScheduled) {
        return 'text-danger';
      }
      if (status === TicketStatus.inProgress) {
        return 'text-muted';
      }

      return '';
    },

    getStatusName (ticket: Ticket): string {
      const status = ticket.fields.status;

      if (status === TicketStatus.inProgress) {
        return this.$t('Work In Progress');
      } else if (status === TicketStatus.waitingMyResponse) {
        return this.$t('Waiting for My Response');
      } else if (status === TicketStatus.callScheduled) {
        return this.$t('Pending Scheduled Event');
      } else if (status === TicketStatus.closed) {
        return this.$t('Solution Provided');
      } else if (status === TicketStatus.inEngineering) {
        return this.$t('In Engineering');
      } else if (status === TicketStatus.merged) {
        return this.$t('Merged with ticket {link}ticketId{/link}');
      }
      return this.$t('Unknown');
    },
  },
});

/**
 * File: mfaUtils.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import { MFA_AUTH_MECHANISM } from '@/models/authenticatorApp';

/* eslint-disable camelcase */
export interface AuthenticatorAppResponse {
  outer_id: string;
  name: string;
  auth_mechanism: MFA_AUTH_MECHANISM;
  created_at: string;
  updated_at: string;
}
/* eslint-enable camelcase */

export function patchAuthenticatorAppName (responseData: AuthenticatorAppResponse, index: number) {
  const dataCopy = { ...responseData };
  if (dataCopy.name === null) {
    dataCopy.name = `Authenticator App #${index + 1}`;
  }
  return dataCopy;
}

<copyright>
File: buySubscriptionModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

modal(:name="name", @show="onShow", @hide="onHide", :size="12")
  template(slot="header")
    template(v-if="upgrade") {{ $t('Upgrading to Parallels Desktop Subscription') }}
    template(v-else) {{ $t('Buying New Parallels Desktop Subscription') }}
  template(slot="content")
    loader(:loading="loading")
      template(v-if="awaitingPayment")
        formatter(:text="$t('Complete the purchase in the Parallels Online Shopping cart (opened in a separate web browser’s tab). Click {b}Done{/b} when your transaction is finished.')")
          template(v-slot:b="{ text }")
            b {{ text }}
      template(v-else)
        .margin-bottom {{ $t('To proceed with the purchase, you will be redirected to Parallels Online Store for checkout.') }}
        div {{ $t('Return to this page after completing the purchase.') }}
  template(slot="footer")
    template(v-if="awaitingPayment")
      btn(@click="checkPurchase", key="done", :disabled="loading") {{ $t('Done') }}
    template(v-else)
      btn.proceed(:href="link", target="_blank", @click="onPurchase", key="purchase", :disabled="loading") {{ $t('Proceed to Checkout') }}
    btn(color="white", @click="$modal.hide()") {{ $t('Cancel') }}

</template>

<script>

import GenerateCartBuyLinkRequest from '@/api/generateCartBuyLinkRequest';
import SubscriptionsRequest from '@/api/lsSubscriptionsRequest';
import {
  PD_PRO_BUY_PUBLIC_ID,
  PD_UPGRADE_TO_PRO_PUBLIC_ID,
  PDPRO_BY_SUBSCRIPTION_KEY_NAME
} from '@core/constants/subscriptions';

export default {
  name: 'upgrade-to-subscription-modal',
  props: {
    name: {
      type: String,
      required: true,
    },
    purchaseInProgress: {
      type: Boolean,
      default: false,
    },
    upgrade: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      link: undefined,
      inProgress: false,
    };
  },
  computed: {
    awaitingPayment () {
      return this.inProgress || this.purchaseInProgress;
    },
    product () {
      return this.upgrade ? PD_UPGRADE_TO_PRO_PUBLIC_ID : PD_PRO_BUY_PUBLIC_ID;
    },
  },
  methods: {
    onShow (params) {
      if (this.link) {
        return;
      }

      const request = new GenerateCartBuyLinkRequest({ product: this.product });

      this.loading = true;

      this.$api.authorizedCall(request).then((data) => {
        this.link = data.url;
      }).finally(() => {
        this.loading = false;
      });
    },
    onHide () {
      this.inProgress = false;
    },
    onPurchase () {
      this.inProgress = true;
    },
    checkPurchase () {
      const request = new SubscriptionsRequest({
        product: PDPRO_BY_SUBSCRIPTION_KEY_NAME,
      });

      request.dropCache();

      this.loading = true;

      this.$api.authorizedCall(request).then(() => {
        const subscriptions = request.getSubscriptions();

        if (subscriptions.find((s) => s.isActive)) {
          this.$modal.hide();
          this.$emit('reload');
        } else {
          this.$toast.show({
            text: $t('No updates about the purchase. Make sure the transaction is completed.'),
            color: 'yellow',
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};

</script>

<style scoped lang="sass">

.proceed
  float: right
  margin-left: $grid-step
  +phone
    display: block
    float: none
    width: 100%
    margin: 0 0 $grid-step 0

</style>

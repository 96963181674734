/**
 * File: initialConfigRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';
import InitialConfig from '@/models/initialConfig';

export default class InitialConfigRequest extends ApiRequest {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/webapp/initial_config`;
  }

  getInitialConfig () {
    return this.data && new InitialConfig(this.data);
  }
}

/**
 * File: helpers.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */
import { RawLocation } from 'vue-router/types/router';
import { PRODUCT_CONTEXT_TO_ROUTE_MAP } from '@/routes/constants';
import {
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PTB_CVT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_HDT
} from '@core/constants/subscriptions';
import Subscription from '@/models/subscription';

const TOOLBOX_STANDALONE_KEY_NAMES = [
  PRODUCT_KEY_NAME_PTB_HDT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_CVT,
];

const textFilter = (entry, val): boolean => {
  // No filter
  if (!val) {
    return true;
  }

  val = val.trim().toLowerCase();
  // Filter by subscription name
  if (entry.name.toLowerCase().indexOf(val) >= 0) {
    return true;
  }

  if (entry.universalKeys) {
    // Filter by license key
    for (let i = 0; i < entry.universalKeys.length; i++) {
      if (entry.universalKeys[i].licKey.toLowerCase().indexOf(val) >= 0) {
        return true;
      }

      if (entry.universalKeys[i].name.toLowerCase().indexOf(val) >= 0) {
        return true;
      }
    }
  }

  if (entry.licKey && entry.licKey.toLowerCase().indexOf(val) >= 0) {
    return true;
  }

  if (entry.products) {
    // Filter by product keyName and description
    for (let i = 0; i < entry.products.length; i++) {
      const description = entry.products[i].description;
      if (description && description.toLowerCase().indexOf(val) >= 0) {
        return true;
      }
      const keyName = entry.products[i].keyName;
      if (keyName && keyName.toLowerCase().indexOf(val) >= 0) {
        return true;
      }
      const productName = entry.products[i].description;
      if (productName && productName.toLowerCase().indexOf(val) >= 0) {
        return true;
      }
    }
  }

  // Filter by status
  if (entry.status.toLowerCase().indexOf(val) >= 0) {
    return true;
  }
  return false;
};

const getSubscriptionLinkParams = (subscription: Subscription, contextProduct: string, utility: string): RawLocation => {
  const productName = contextProduct || subscription.firstProductKeyName;
  const routeName = PRODUCT_CONTEXT_TO_ROUTE_MAP[productName] || 'subscriptionDetails';
  const params = {
    id: subscription.uuid || 'nouuid',
  } as { [key: string]: string };
  if (productName === PRODUCT_KEY_NAME_PDB || productName === PRODUCT_KEY_NAME_PDFC || productName === PRODUCT_KEY_NAME_PDFM) {
    params.product = productName;
  } else if (TOOLBOX_STANDALONE_KEY_NAMES.includes(productName)) {
    params.utility = utility;
  }

  return { name: routeName, params };
};

export {
  textFilter,
  getSubscriptionLinkParams
};

/**
 * File: getUsersPermissionsRequest.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequestWithFallback from '@core/api/apiRequestWithFallback';
import { PRODUCT_KEY_NAME_RAS, prodKeyToName } from '@core/constants/subscriptions';
import { LICENSE_SERVICE, RAS_LICENSE_SERVICE } from '@core/constants/services';

export default class GetUsersPermissionsRequest extends ApiRequestWithFallback {
  get cacheNameSpace () {
    return null;
  }

  get urlSuffix () {
    return `/api/v1/ba/domains/${this.params.domainId}/permissions`;
  }

  needFallback (response, data) {
    this.permissions = data;
    return true;
  }

  getPermissions () {
    this.permissions = this.permissions.concat(this.data).map((item) => {
      item.subscriptions = item.subscriptions.map((subscription) => {
        subscription.targetServiceName = subscription.product_key_names.includes(PRODUCT_KEY_NAME_RAS) ? RAS_LICENSE_SERVICE : LICENSE_SERVICE;
        subscription.productName = prodKeyToName(subscription.product_key_names[0]);
        subscription.name = subscription.name || 'Primary Key';
        return subscription;
      });
      return item;
    });

    return this.permissions;
  }
}

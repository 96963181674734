
import Vue, { PropType } from 'vue';
import { isDomainNameValid, maxLength, required } from '@core/common/validators';

import ComponentMixin from '@/modules/componentMixIn';
import SsoDomain, { SSO_DOMAIN_STATUS } from '@/models/ssoDomain';
import {
  AddSsoDomainJobStatusRequest,
  AddSsoDomainJobStatusRequestResponse,
  AddSsoDomainRequest,
  AddSsoDomainRequestResponse,
  CreateSsoDomainJobStatus,
  UpdateSsoDomainRequest
} from '@/api/sso/ssoDomainsRequests';

export default Vue.extend({
  name: 'sso-domain-modal',
  props: {
    verificationCode: {
      type: String as PropType<string>,
      required: true,
    },
  },
  mixins: [ComponentMixin],
  beforeDestroy () {
    this.reset();
  },
  data () {
    return {
      domain: null as SsoDomain,
      domainName: '',
      description: '',
      domainFailedVerification: false,
      domainUnverified: false,
      domainVerified: false,
      notes: '',
      jobId: null,
    };
  },
  validations: {
    domainName: {
      required,
      pattern: function (value) {
        return isDomainNameValid(value);
      },
    },
    description: {
      required,
      maxLength: maxLength(50),
    },
    notes: {
      maxLength: maxLength(256),
    },
  },
  methods: {
    onShow (e, params?: { domain: SsoDomain }): void {
      if (typeof params !== 'undefined') {
        this.domain = params.domain;
        this.domainFailedVerification = params.domain.status === SSO_DOMAIN_STATUS.STATUS_FAILED;
        this.domainUnverified = params.domain.status === SSO_DOMAIN_STATUS.STATUS_UNVERIFIED;
        this.domainVerified = params.domain.status === SSO_DOMAIN_STATUS.STATUS_VERIFIED;
        this.domainName = params.domain.domainName;
        this.description = params.domain.description;
        this.notes = params.domain.notes;
      }
    },
    reset (): void {
      this.domainName = '';
      this.description = '';
      this.notes = '';
      this.domain = null;
      this.loading = false;
      this.jobId = null;
    },
    handleConflictError (data): void {
    },
    callRequest (request) {
      return this.authorizedCall(request).then(() => {
        this.$emit('reload');
        this.$modal.hide();
        this.reset();
      }).catch(() => {
        this.$toast.show({ text: this.$t('Error occurred.'), color: 'red' });
      });
    },
    async waitWhileSsoDomainBeAdded (): Promise<void> {
      if (!this.jobId) {
        return;
      }

      const jobId = this.jobId;
      const request = new AddSsoDomainJobStatusRequest(
        { companyUuid: this.$appData.session.businessDomainId, jobId }
      );
      try {
        const response: AddSsoDomainJobStatusRequestResponse = await this.$api.authorizedCall(request);
        if (response.status === CreateSsoDomainJobStatus.COMPLETED) {
          const text = this.$t('Domain {domainName} added.', { domainName: this.domainName });
          this.$toast.show({ text });
          this.$emit('reload');
          this.$modal.hide();
          this.reset();
        } else if (response.status === CreateSsoDomainJobStatus.EXISTS) {
          let message = '';
          if (response.exists_domain_id === this.$appData.session.businessDomainId) {
            message = this.$t('Domain {name} already exists', { name: this.domainName });
          } else {
            message = this.$t('Domain {name} belongs to the different entity', { name: this.domainName });
          }
          this.$toast.show({ text: message, color: 'red' });
          this.loading = false;
          this.jobId = null;
        } else if (response.status === CreateSsoDomainJobStatus.FAILED) {
          this.$toast.show({
            text: this.$t(
              'Failed to validate domain {domainName}. Please ensure that appropriate DNS TXT record was added and try again.',
              { domainName: this.domainName }
            ),
            color: 'red',
          });
          this.loading = false;
          this.jobId = null;
        } else {
          if (jobId === this.jobId) { // still the same job
            // Re-schedule job status check in 0.5 secs
            setTimeout(this.waitWhileSsoDomainBeAdded.bind(this), 500);
          }
        }
      } catch (e) {
        this.loading = false;
        this.$toast.show({ text: this.$t('Error occurred.'), color: 'red' });
      }
    },
    onAddClick (): void {
      const addSsoDomainRequest = new AddSsoDomainRequest({
        companyUuid: this.$appData.session.businessDomainId,
        domainName: this.domainName,
        description: this.description,
        notes: this.notes,
      });
      this.loading = true;
      this.$api.authorizedCall(addSsoDomainRequest).then((data: AddSsoDomainRequestResponse) => {
        this.jobId = data.job_id;
        this.waitWhileSsoDomainBeAdded();
      }).catch(() => {
        this.$toast.show({ text: this.$t('Error occurred.'), color: 'red' });
        this.loading = false;
      });
    },
    onSaveClick (): void {
      const updateSsoDomainRequest = new UpdateSsoDomainRequest({
        companyUuid: this.$appData.session.businessDomainId,
        domainId: this.domain.id,
        description: this.description,
        notes: this.notes,
      });

      this.callRequest(updateSsoDomainRequest).then(() => {
        const text = this.$t('Changes for the domain {domainName} saved.', { domainName: this.domainName });
        this.$toast.show({ text });
      });
    },
    onDeleteClick (): void {
      this.$modal.hide();
      this.$emit('delete', Object.assign({}, this.domain));
      this.reset();
    },
    onCancelClick (): void {
      this.$modal.hide();
      this.reset();
    },
    reload (): void {
      this.$emit('reload');
    },
  },
});

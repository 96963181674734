<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'disconnect-hosts-hint',
};

</script>

<template lang="pug">

hint
  .hint-container
    .margin-bottom {{ $t('You have remote computers registered in Parallels Access. When resetting your Parallels account password, you can choose to keep these computers connected or disconnect them immediately.') }}
    .margin-bottom {{ $t('Disconnecting your computers is advisable if you`ve noticed suspicious activity on your Parallels account.') }}
    .margin-bottom {{ $t('If you choose to disconnect your computers, you will have to re-login with your new account password on each computer individually to be able to access them via Parallels Access again.') }}

</template>

<style lang="sass" scoped>

.hint-container
  width: $grid-step * 40

</style>

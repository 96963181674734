/**
 * File: computerDetails.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';

import { FEATURE_CONFIGURATION_PROFILE_PER_HOST } from '@core/constants/features';
import ComponentMixIn from '@/modules/componentMixIn';
import PdDeactivateHostsRequest from '@/api/pdDeactivateHostsRequest';
import PdUpdateHostRequest from '@/api/pdUpdateHostRequest';
import {
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER
} from '@core/constants/subscriptions';
import ConfigurationProfileDropdown from '@/ui/configurationProfileDropdown/index.vue';
import PdHost from '@/models/pdHost';
import { Dictionary } from '@core/common/types';
import { DESKTOP_CONFIGURATION_PROFILES_PAGE, DESKTOP_SUBSCRIPTION_DETAILS_CONTENT } from '@/routes/constants';
import { SSOHostInfo } from '@core/models/ssoCompanyUser';

export default Vue.extend({
  name: 'computer-details',

  mixins: [ComponentMixIn],
  components: { ConfigurationProfileDropdown },
  props: {
    contextProduct: {
      type: String,
    },
  },

  data () {
    return {
      tab: this.$route.query.tab,
      deactivating: false,
      updating: false,
      form: {
        name: '',
        notes: '',
        configurationProfileId: null as number,
      },
      host: null as PdHost | SSOHostInfo,
      profilesLoading: false,
      DESKTOP_CONFIGURATION_PROFILES_PAGE,
      DESKTOP_SUBSCRIPTION_DETAILS_CONTENT,
    };
  },

  methods: {
    switchTab (params: { tab: string }) {
      const query = Object.assign({}, this.$route.query);
      query.tab = params.tab;
      this.$router.replace({
        query,
      });
    },

    show (host: PdHost | SSOHostInfo) {
      this.host = host;
      this.form.name = host.name;
      this.form.notes = host.notes;
      this.form.configurationProfileId = host.generalConfigurationProfileId || null;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
      this.$emit('show', this.host);
    },

    hide () {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.editableHeader.undo();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.hide();
      this.$emit('hide', this.host);
    },

    deactivate () {
      this.authorizedCall(
        new PdDeactivateHostsRequest({ hwIds: [this.host.hwId] }),
        'deactivating'
      ).then(() => {
        this.host.activated = false;
        this.host.licKey = null;
        this.host.subscription = null;
        this.host.subscriptionUuid = null;
        this.host.activatedAt = null;
        this.$emit('hostDeactivated', this.host);
        this.showToast($t('Computer "{name}" has been deactivated.', { name: this.host.name }));
      });
    },

    save () {
      if (!this.changed) {
        return;
      }

      const requestData: Dictionary<string|number> = {
        hwId: this.host.hwId,
        name: this.form.name,
        notes: this.form.notes,
      };

      if (this.canManageConfigurationProfile) {
        requestData.configurationProfileId = this.form.configurationProfileId;
      }

      this.authorizedCall(new PdUpdateHostRequest(requestData), 'updating').then(() => {
        this.host.name = this.form.name;
        this.host.notes = this.form.notes;
        if (this.form.configurationProfileId) {
          this.host.configurationProfile = {
            id: this.form.configurationProfileId,
            // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
            name: this.$refs.configurationProfileDropdown.selectedName,
          };
        } else {
          this.host.configurationProfile = null;
        }
        this.$emit('hostUpdated', this.host);
        this.showToast($t('Computer "{name}" has been updated.', { name: this.form.name }));
      });
    },

    onProfilesReady () {
      this.profilesLoading = false;
    },

    onProfilesLoadStarted () {
      this.profilesLoading = true;
    },
  },

  computed: {
    tabs (): Dictionary<string> {
      const tabs: Dictionary<string> = {
        general: this.$t('General'),
        hardware: this.$t('Hardware'),
      };

      if (this.canManageConfigurationProfile) {
        tabs.configurationProfile = this.$t('Configuration Profile');
      }

      return tabs;
    },
    changed (): boolean {
      const host = this.host; const form = this.form;
      return (host && (
        host.notes !== form.notes ||
        host.name !== form.name ||
        host.generalConfigurationProfileId !== form.configurationProfileId)
      );
    },
    isPdfc (): boolean {
      return this.contextProduct === PRODUCT_KEY_NAME_PDFC;
    },
    isPdb (): boolean {
      return [PRODUCT_KEY_NAME_PDB, PRODUCT_KEY_NAME_PDB_PER_USER].includes(this.contextProduct);
    },
    serialNumberPropertyName (): string {
      return this.isPdfc ? this.$t('Directory API ID') : this.$t('Serial Number');
    },
    canManageConfigurationProfile (): boolean {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.isPdb && this.isFeatureAccessible(FEATURE_CONFIGURATION_PROFILE_PER_HOST);
    },
    configurationProfileAllowed (): boolean {
      // Deny select CP if CP set per subscription.
      return !!this.host.configurationProfile || !this.host.generalConfigurationProfile;
    },
  },
});

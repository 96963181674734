/**
 * File: ssoDomain.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import CoreModel from '@core/common/model';

export enum SSO_DOMAIN_STATUS {
  STATUS_FAILED = -1,
  STATUS_UNVERIFIED = 0,
  STATUS_VERIFIED = 1
}

export default class SsoDomain extends CoreModel {
  id: number;
  domainName: string;
  description: string;
  createdAt: Date;
  status: SSO_DOMAIN_STATUS;
  users: number;
  notes: string;
  usersCnt: number;

  get isVerified (): boolean {
    return this.status === SSO_DOMAIN_STATUS.STATUS_VERIFIED;
  }

  get isFailed (): boolean {
    return this.status === SSO_DOMAIN_STATUS.STATUS_FAILED;
  }
}

<copyright>
File: uploader.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>
import fileUploader from '@/ui/file-uploader';
import PmmOfflineRequest from '@/api/pmmOfflineRequest';
import componentMixIn from '@/modules/componentMixIn';
import { HTTP_CODES } from '@core/constants/http';

export default {
  mixins: [fileUploader, componentMixIn],

  methods: {
    upload (fileList) {
      this.error = '';

      this.$emit('start');

      const request = new PmmOfflineRequest({}, fileList[0]);

      this.authorizedCall(request).then((data) => {
        this.$emit('success', data, fileList[0].name);
        return data;
      }).catch((e) => {
        if (e.response.status === HTTP_CODES.BAD_REQUEST || e.response.status === HTTP_CODES.PAYLOAD_TOO_LARGE) {
          return { msg: 'Invalid file' };
        }
        return e.response.json();
      }).then((data) => {
        this.error = data;
        this.$emit('error', this.error);
      });
    },
  },
};
</script>

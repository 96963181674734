/**
 * File: getBytebotStatisticsRequest.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 **/

import ApiRequest from '@core/api/apiRequest';

export default class GetBytebotStatisticsRequest extends ApiRequest {
  get method () {
    return 'GET';
  }

  get url () {
    return `/desktop/api/v1/ba/subscriptions/${this.params.subscriptionUuid}/bytebot_info`;
  }
}

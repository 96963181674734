/**
 * File: convertPdlPage.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import componentMixIn from '@/modules/componentMixIn';
import ConvertPdlOptionsRequest from '@/api/convertPdlOptionsRequest';
import GenerateConvertPdlLinkRequest from '@/api/generateConvertPdlLinkRequest';
import renewMixin from '../renew/renewMixin';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

export default {
  name: 'convert-pdl-page',
  props: ['appData', 'id'],
  mixins: [componentMixIn, renewMixin],
  components: { Breadcrumbs }, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  mounted () {
    if (this.id === 'null') {
      const request = new SubscriptionsRequest({ type: 'personal' });
      this.authorizedCall(request).then(() => {
        const activePdl = request.getSubscriptions().filter(subs => subs.isActivePdlSubscription);
        if (activePdl[0] && activePdl[0].uuid) {
          this.$router.replace({ params: { id: activePdl[0].uuid } });
          this.$nextTick(() => {
            this.load();
          });
        }
      });
    } else {
      this.load().then(() => {
        if (!this.subscription.isActivePdlSubscription) {
          this.subscription = null;
        }
      });
    }
  },

  methods: {

    getOptionsRequest () {
      return new ConvertPdlOptionsRequest({ uuid: this.id });
    },

    getCheckoutUrlRequest () {
      return new GenerateConvertPdlLinkRequest({
        plan: this.selectedOption.plan_ext_key,
        uuid: this.selectedOption.uuid,
      });
    },

    proceed () {
      if (this.subscription.isAutorenewable) {
        this.$refs.reminder.show();
      } else {
        this.proceedToCheckout();
      }
    },

  },

  computed: {

    durationOptions () {
      const options = this.renewOptions || [];
      return options.map((option) => ({
        value: option.uuid,
        text: option.description,
      }));
    },

    selectedDuration () {
      if (this.selectedOption) {
        return this.getDurationString(this.selectedOption.duration_value, this.selectedOption.duration_unit);
      } else {
        return this.subscriptionDuration;
      }
    },

    selectedNextDate () {
      if (this.selectedOption) {
        // nextBillingDate of selected option
        return this.nextBillingDate;
      } else {
        return this.renewOptions && this.renewOptions[0] && this.renewOptions[0].next_billing_at;
      }
    },

  },

};

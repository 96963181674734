/**
 * File: apiRequest.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import 'whatwg-fetch';
// @ts-ignore
import mitt from 'mitt';
import Cache from './cache';
import { Dictionary } from '../common/types';
import { HTTP_CODES } from '../constants/http';

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS';

export type Json = string | number | boolean | null | Dictionary<Json> | Array<Json>;

/**
 * Events:
 *   beforeLoad - Fires before a request to retrieve a data object.
 *   started - Fires when loading started.
 *   afterLoad - loading completed, raw data accepted.
 *   error - Fires if an exception occurs in the ApiRequest during a remote request.
 *   completed - when data has accepted and modified or error
 */
export default class ApiRequest<ParamsType = Dictionary, ResponseData = any> {
  json: boolean = true;

  params: ParamsType;
  data: ResponseData;
  cache: Cache;
  emitter: any;
  headers: Dictionary<string>;
  promise: Promise<Response>;
  response: Response;
  error: Error;
  _json: any;

  /**
   * Function which builds the query string by params.
   * @param {Object} params
   * @returns {string}
   */
  buildQueryString (params) {
    let queryString = '';

    // Filter undefined values
    params = Object.entries(params).filter((entry) => entry[1] !== undefined);

    if (params.length) {
      queryString = '?' + params.map((param) => {
        return param[0] + '=' + encodeURIComponent(param[1]);
      }).join('&');
    }

    return queryString;
  }

  constructor (params?: ParamsType) {
    // @ts-ignore
    this.params = params || {};
    this.emitter = mitt();
    this.cache = new Cache();
    this.headers = { 'Content-Type': 'application/json' };
    this._json = null;
  }

  get method (): Method {
    return 'GET';
  }

  get body (): Json {
    return null;
  }

  get defaultOptions (): Dictionary<string> {
    return {
      credentials: 'same-origin'
    };
  }

  get options (): Dictionary {
    let options: Dictionary = Object.assign({}, this.defaultOptions);
    options.method = this.method;
    let body = this.body;
    if (body) {
      options.body = JSON.stringify(body);
    }
    options.headers = new Headers(this.headers);
    return options;
  }

  /**
   * @returns {String}
   */
  get url (): string {
    if (this.params.hasOwnProperty('url')) {
      // @ts-ignore
      return this.params.url;
    }
    throw new Error('get url () not implemented');
  }

  load (): Promise<ResponseData> {
    this.emitter.emit('beforeLoad', this);

    return this.getPromise()
      .then((data) => {
        this.data = data;
        this.emitter.emit('completed', this);
        return data;
      });
  }

  getPromise (): Promise<ResponseData> {
    return this.getFromCache() ? this.getCachePromise() : this.getFetchPromise();
  }

  getCachePromise (): Promise<ResponseData> {
    return new Promise<ResponseData>((resolve) => {
      resolve(this.getFromCache());
    });
  }

  getFetchPromise () : Promise<ResponseData> {
    this.promise = fetch(this.url, this.options);

    // Safari 10.1.2 fetch returns Promise without finally even it polyfilled
    // @ts-ignore
    if (!this.promise.finally && this.promise.__proto__) { // eslint-disable-line
      // @ts-ignore
      this.promise.__proto__.finally = Promise.prototype.finally; // eslint-disable-line
    }

    this.emitter.emit('started', this);
    return this.promise
      .then((response) => {
        this.response = response;
        return response;
      })
      .then((response) => {
        return this.checkStatus(response);
      })
      .then((response) => {
        return this.getJson(response);
      })
      .then((data) => {
        return this.onFetchSuccess(data);
      })
      .catch((error) => {
        let response = error.response;
        if (this.checkUnauthorizedResponse && response && response.status === HTTP_CODES.UNAUTHORIZED) {
          response.json().then((data) => {
            if (data && (data.msg === 'incorrect credentials' || data.msg === 'invalid token')) {
              // Prevent infinite loop
              location.href = '/login';
            }
          });
        }
        this.error = error;
        this.emitter.emit('error', this);
        throw error;
      });
  }

  async checkStatus (response) {
    if (response && response.status >= HTTP_CODES.OK && response.status < HTTP_CODES.MULTIPLE_CHOICES) {
      return response;
    } else {
      let error: any = new Error(response.statusText);
      // This promise allow to read from response.json multiple times
      // TODO: completely rewrite this class logic - set response data before error is thrown
      let promise = new Promise((resolve, reject) => {
        let getJson = this._json || response.json();
        getJson.then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      });

      error.response = {
        status: response.status,
        statusText: response.statusText,
        json () {
          return promise;
        }
      };

      throw error;
    }
  }

  getJson (response: Response): Promise<ResponseData> {
    if (!this.json) return null;
    // storing response data into variable to allow reading request body multiple times
    if (this._json === null && response && response.status !== HTTP_CODES.NO_CONTENT) {
      this._json = response.json();
    }
    return this._json;
  }

  shareCache (data: ResponseData) {
  }

  onFetchSuccess (data: ResponseData): ResponseData {
    this.emitter.emit('afterLoad', { scope: this, data });
    this.saveToCache(data);
    this.shareCache(data);
    return data;
  }

  /**
   *  Cache methods
   */

  get cacheNameSpace (): string | undefined {
    return 'noname';
  }

  get cacheId (): string {
    return JSON.stringify(this.url);
  }

  // Check for redirect to login on 401 and 409 error
  get checkUnauthorizedResponse () : boolean {
    if (this.params.hasOwnProperty('checkUnauthorizedResponse')) {
      // @ts-ignore
      return this.params.checkUnauthorizedResponse;
    }
    return true;
  }

  getFromCache () {
    return this.cacheNameSpace && this.method.toUpperCase() === 'GET' && this.cache.getCache(this.cacheNameSpace, this.cacheId);
  }

  saveToCache (data) {
    if (this.cacheNameSpace) {
      this.cache.saveCache(this.cacheNameSpace, this.cacheId, data);
    }
  }

  dropCache () {
    this._json = null;
    if (this.cacheNameSpace) {
      this.cache.dropCache(this.cacheNameSpace, this.cacheId);
    }
  }

  dropFullCache () {
    if (this.cacheNameSpace) {
      this.cache.dropCache(this.cacheNameSpace);
    }
  }

  setAuthHeader (name, value) {
    this.headers.Authorization = `${name} ${value}`;
  }
}

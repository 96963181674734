/**
 * File: extendSubscriptionModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import Vue, { PropType } from 'vue';
import { Dictionary } from '@core/common/types';
import Subscription from '@/models/subscription';
import UniversalKey from '@/models/universalKey';
import { fixSlotsForPluralization } from '@/plugins/locale';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import ExtendSubscriptionRequest from '@/api/extendSubscriptionRequest';
import { getSubscriptionName } from '@core/common/subscriptions';
import { date } from '@core/common/format/date';
import { DEFAULT_LOCALE } from '@core/constants/geo';

const MODAL_ID = 'addKeyExtendSubscription';
const MODAL_SUCCESS_ID = `${MODAL_ID}Success`;
const MODAL_PLACEHOLDERS = [
  'limit',
  'key',
  'type',
  'date',
  'date1',
  'date2',
  'units',
  'name',
];

const EVENT_ERROR = 'error';
const EVENT_CANCEL = 'cancel';
const EVENT_EXTEND = 'extend';
const EVENT_EXTENDED = 'extended';
const EVENT_REGISTER = 'register';
const EVENT_COMPLETED = 'completed';
const EVENT_BEFORE_LOAD = 'beforeLoad';

interface ModalData {
  loading: boolean;
  selectedValue: string | null;
  extendedSubscription: Subscription | null;
  subscriptions: Subscription[];
}

interface ExtendSubscriptionRequestData {
  subscription: Subscription;
}

export default Vue.extend({
  name: 'extend-subscription-modal',

  props: {
    extendOptions: {
      type: Array as PropType<Subscription[]>,
    },
    newSubscription: {
      type: Object as PropType<Subscription>,
    },
  },

  data (): ModalData {
    return {
      loading: false,
      selectedValue: null,
      extendedSubscription: null,
      subscriptions: [] as Subscription[],
    };
  },

  computed: {

    localizationPlaceholders (): Dictionary {
      return fixSlotsForPluralization(MODAL_PLACEHOLDERS);
    },

    isMany (): boolean {
      const options = this.extendOptions as Subscription[];

      return options && options.length > 1;
    },

    newSubscriptionEdition (): string {
      const subscription = this.newSubscription as Subscription;

      return subscription && subscription.products[0].productName;
    },

    newSubscriptionLimit (): number {
      const subscription = this.newSubscription as Subscription;

      return subscription && subscription.products[0].licenses.thisPeriod;
    },

    newSubscriptionProductUnits (): string {
      const subscription = this.newSubscription as Subscription;

      return subscription && subscription.firstProductUnits;
    },

    newSubscriptionExpirationDate (): Date {
      const subscription = this.newSubscription as Subscription;

      return subscription && subscription.expirationDate;
    },

    selectedOption (): Subscription | null {
      const options = this.extendOptions as Subscription[];

      if (this.isMany) {
        if (this.selectedValue) {
          return options && options.find(this.compareFirstAndSelectedKeys);
        } else {
          return null;
        }
      } else {
        return options && options[0];
      }
    },

    selectedOptionLicenseKey (): string {
      const option = this.selectedOption as Subscription;

      return option && option.universalKeys[0].licKey;
    },

    selectedOptionName (): string {
      const option = this.selectedOption as Subscription;

      return option && option.name;
    },

    selectedOptionExpirationDate (): Date {
      const option = this.selectedOption as Subscription;

      return option && option.expirationDate;
    },

    extendedSubscriptionEdition (): string {
      const subscription = this.extendedSubscription as Subscription;

      return subscription && subscription.products[0].productName;
    },

    extendedSubscriptionLicenseKey (): string {
      const subscription = this.extendedSubscription as Subscription;

      return subscription && subscription.universalKeys[0].licKey;
    },

    extendedSubscriptionExpirationDate (): Date {
      const subscription = this.extendedSubscription as Subscription;

      return subscription && subscription.expirationDate;
    },

    dropboxOptions (): Subscription[] | Dictionary[] {
      const options = this.extendOptions as Subscription[];

      return options && options.map(opt => {
        const subscriptionData = this.subscriptions.find((sub) => sub.universalKeys[0].licKey === opt.universalKeys[0].licKey);
        let note = `${opt.universalKeys[0].licKey}, `;
        note += `${this.amountLine(opt)} `;
        note += this.$t('valid until {date}', { date: date(opt.expirationDate, DEFAULT_LOCALE, false) });
        return {
          note,
          text: subscriptionData?.name || getSubscriptionName(opt, this.$t.bind(this)),
          value: opt.universalKeys[0].licKey,
        };
      });
    },

  },

  methods: {
    show () {
      this.loadSubscriptions();
    },

    loadSubscriptions () {
      this.loading = true;
      // TODO: need to clean it after BE changes https://parallels.atlassian.net/browse/CPCLOUD-20936
      const request = new SubscriptionsRequest({
        type: this.$appData.session.isBusinessUser(this.$appData.userInDomain) ?
          SubscriptionsRequest.TYPE_BUSINESS :
          SubscriptionsRequest.TYPE_PERSONAL,
      });
      this.$api.authorizedCall(request)
        .then(() => {
          this.subscriptions = request.getSubscriptions();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    compareFirstAndSelectedKeys (subscription: Subscription): boolean {
      const keys = subscription.universalKeys as UniversalKey[];

      return keys[0].licKey === this.selectedValue;
    },

    resetForm (): void {
      this.selectedValue = null;
    },

    extendHandler (): void {
      const option = this.selectedOption as Subscription;
      const subscription = this.newSubscription as Subscription;
      const licKey = subscription.universalKeys[0].licKey;
      const uuid = option.uuid;
      const extend = new ExtendSubscriptionRequest({ licKey, uuid });

      this.$emit(EVENT_EXTEND, subscription, option);

      extend.emitter.on(EVENT_BEFORE_LOAD, () => {
        this.loading = true;
      });

      extend.emitter.on(EVENT_COMPLETED, () => {
        this.loading = false;
      });

      extend.emitter.on(EVENT_ERROR, () => {
        this.loading = false;
      });

      return this.$api.authorizedCall(extend).then(this.extendSuccessHandler);
    },

    extendSuccessHandler (data: ExtendSubscriptionRequestData): void {
      this.extendedSubscription = data.subscription;

      this.$emit(
        EVENT_EXTENDED,
        this.extendedSubscription,
        this.extendedSubscriptionEdition,
        this.extendedSubscriptionLicenseKey,
        this.extendedSubscriptionExpirationDate
      );

      this.$modal.hide(MODAL_ID);
      this.$modal.show(MODAL_SUCCESS_ID);

      this.resetForm();
    },

    cancelHandler (): void {
      this.resetForm();

      this.$modal.hide(MODAL_ID);

      this.$emit(EVENT_CANCEL);
    },

    registerHandler (): void {
      this.$modal.hide(MODAL_ID);

      this.$emit(EVENT_REGISTER, this.newSubscription);
    },

    amountLine (subscription): string {
      const firstProductLimit = subscription.firstProductLimitThisPeriod;
      const units = subscription.firstProductUnits;
      const text = `{limit} ${units}, `;

      // $tc('{limit} concurrent users')
      // $tc('{limit} named users')
      // $tc('{limit} users')
      return this.$tc(text, firstProductLimit, { limit: firstProductLimit });
    },

  },

});

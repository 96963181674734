/**
 * File: resourceUsageNotificationGetRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest, { Method } from '@core/api/apiRequest';
import { ScheduleRule, ThresholdRule } from '@/api/resourceUsageNotificationUpdateRequest';

type ResourceUsageNotificationGetRequestParams = {
  subscriptionUuid: string;
}

type ResourceUsageNotificationGetRequestResponse = {
  threshold: ThresholdRule;
  schedule: ScheduleRule;
  sendSooner: boolean;
  isActive: boolean;
  locale: string;
  receivers: Array<string>;
}

export default class ResourceUsageNotificationGetRequest extends ApiRequest<ResourceUsageNotificationGetRequestParams, ResourceUsageNotificationGetRequestResponse> {
  get method (): Method {
    return 'GET';
  }

  get url (): string {
    return `/ras/webapp/subscription/usage_notification/${this.params.subscriptionUuid}`;
  }
}

/**
 * File: registerBusinessAccountModal.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';
import { getCountries, STATES, State } from '@core/constants/geo';
import * as validators from '@core/common/validators';
import RegisterBusinessAccountRequest from '@/api/registerBusinessAccountRequest';

export default Vue.extend({
  name: 'register-business-account-modal',

  data () {
    return {
      loading: false,
      form: {
        name: '',
        address: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        phone: '',
      },
      purpose: undefined, // returns as argument with 'registered' event
    };
  },

  validations: {
    form: {
      name: validators.companyName,
      address: validators.address,
      city: validators.city,
      country: validators.country,
      state: validators.state,
      zip: validators.zip,
      phone: validators.phone,
    },
  },

  created () {
    this.resetForm();
  },

  methods: {

    show (purpose) {
      this.purpose = purpose;
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.$refs.modal.show();
    },

    resetForm () {
      this.form = {
        name: '',
        address: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        phone: '',
      };
      // "form.country" watcher changes form field, so we have to wait for this before validation reset
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    register () {
      let
        firstName = this.$appData.session.firstName;
      let lastName = this.$appData.session.lastName;

      if (!firstName && !lastName) {
        firstName = 'Parallels';
        lastName = 'User';
      } else {
        firstName = firstName || 'Parallels User';
        lastName = lastName || 'Parallels User';
      }

      const register = new RegisterBusinessAccountRequest({
        company: {
          name: this.form.name,
          address_line1: this.form.address,
          city: this.form.city,
          country: this.form.country,
          zip: this.form.zip,
          state: this.form.state || undefined,
          phone: this.form.phone,
        },
        first_name: firstName,
        last_name: lastName,
      });

      register.emitter.on('beforeLoad', () => {
        this.loading = true;
      });
      register.emitter.on('error', () => {
        this.loading = false;
      });

      return this.$api.authorizedCall(register).then((data) => {
        return this.$api.refreshToken(true).then(() => {
          this.loading = false;
          return data;
        });
      });
    },

    registerHandler () {
      this.register().then((data) => {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-18112
        this.hideModal();
        this.resetForm();
        this.$emit('registered', { purpose: this.purpose, domainId: data.business_domain.company_uuid });
      });
    },

    cancelHandler () {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-18112
      this.hideModal();
      this.resetForm();
      this.$emit('cancel');
    },

  },

  watch: {
    'form.country': function () {
      this.form.state = '';
    },
  },

  computed: {
    countries (): State {
      return getCountries();
    },
    states (): State {
      return STATES[this.form.country.toUpperCase()] || {};
    },
  },

});

<copyright>
File: reactivationModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import componentMixIn from '@/modules/componentMixIn';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import PdReactivateHostRequest from '@/api/pdReactivateHostRequest';
import PdBusinessSubscriptionsRequest from '@/api/pdBusinessSubscriptionsRequest';
import { textFilter } from '@/modules/subscriptions/helpers';

const STATUS_ALREADY_ACTIVATED = 'already activated';
const STATUS_NOT_PERMITTED = 'not permitted';
const STATUS_NOT_ACTIVATED = 'not activated';
const STATUS_BLOCKED = 'blocked';
const STATUS_NOT_FOUND = 'not found';
const STATUS_SUCCESS = 'success';

export default {
  name: 'reactivation-modal',
  mixins: [componentMixIn, SearchFilterMixin],
  props: {
    contextProduct: {
      type: String,
    },
  },
  data () {
    return {
      modalName: 'reactivationModal',
      hosts: [],
      header: undefined,
      allLicenses: [],
      loading: false,
      hideIneligible: true,
      columns: ['uuid', 'keyFullName', 'available', 'expirationDate', 'details'],
      options: {
        orderBy: {
          column: 'keyFullName',
        },
        sortable: ['keyFullName', 'available', 'expirationDate'],
        uniqueKey: 'uuid',
        saveState: false,
        texts: {
          noResults: String(this.$t('No eligible keys found')),
        },
      },
      selectedUuid: null,
      filters: {},
      textFilter,
    };
  },
  methods: {
    show (params) {
      this.$refs.searchField.clear();
      this.hosts = params.hosts || [];
      this.header = params.header;
      this.allLicenses = [];
      this.load();
      this.$modal.show(this.modalName);
    },
    hide () {
      this.$modal.hide();
      this.selectedUuid = null;
      this.hosts = [];
    },
    load () {
      const request = new PdBusinessSubscriptionsRequest({
        product: this.contextProduct,
      });

      this.authorizedCall(request).then((data) => {
        this.allLicenses = request.getSubscriptions();
      });
    },
    reactivate () {
      this.loading = true;

      const request = new PdReactivateHostRequest({
        uuid: this.selectedUuid,
        hosts: this.reactivatableHosts.map((h) => h.hwId),
      });

      this.$api.authorizedCall(request).then((data) => {
        let successful = (data[STATUS_SUCCESS] && data[STATUS_SUCCESS].length) || 0;

        if (data[STATUS_ALREADY_ACTIVATED]) {
          successful += data[STATUS_ALREADY_ACTIVATED].length;
        }

        if (successful) {
          const message = $tc('{amount} computers have been reactivated.', successful, { amount: successful });
          this.$toast.show({ text: message, color: 'green' });
        }

        // Some copypaste to avoid crunches with localization
        if (data[STATUS_NOT_PERMITTED]) {
          const failed = data[STATUS_NOT_PERMITTED].length;
          const message = $tc('{amount} computers could not be reactivated due to insufficient permissions.', failed, { amount: failed });
          this.$toast.show({ text: message, color: 'red' });
        }

        if (data[STATUS_NOT_ACTIVATED]) {
          const failed = data[STATUS_NOT_ACTIVATED].length;
          const message = $tc('{amount} computers could not be reactivated because they were explicitly deactivated and cannot be assign a different license key.', failed, { amount: failed });
          this.$toast.show({ text: message, color: 'red' });
        }

        if (data[STATUS_BLOCKED]) {
          const failed = data[STATUS_BLOCKED].length;
          const message = $tc('{amount} computers could not be reactivated because they are blocked. You need to unblock them and then assign a license key to each one manually.', failed, { amount: failed });
          this.$toast.show({ text: message, color: 'red' });
        }

        if (data[STATUS_NOT_FOUND]) {
          const failed = data[STATUS_NOT_FOUND].length;
          const message = $tc('{amount} computers were not found. Please make sure that the computers were not activated using a different license key since you opened this page.', failed, { amount: failed });
          this.$toast.show({ text: message, color: 'red' });
        }

        this.$emit('reactivated');
        this.loading = false;
        this.hide();
      }).catch(() => {
        this.loading = false;
      });
    },
    isIneligibleUuid (uuid) {
      return this.ineligibleLicenses.some((l) => l.uuid === uuid);
    },
  },
  computed: {
    /**
     *  True is list of hosts is provided and license selected.
     */
    canReactivate () {
      return this.reactivatableHosts.length > 0 && this.selectedUuid;
    },

    searchResults () {
      return this.applySearchFilters(this.filters, this.licenses);
    },

    /**
     *  List of licenses which depends from hideIneligible parameter.
     */
    licenses () {
      if (this.hideIneligible) {
        const ineligibleUuids = this.ineligibleLicenses.map((il) => il.uuid);
        return this.allLicenses.filter((l) => {
          return ineligibleUuids.indexOf(l.uuid) < 0;
        });
      }
      return this.allLicenses;
    },

    /**
     * Expired licenses or licences which available seats is less than required.
     */
    ineligibleLicenses () {
      return this.allLicenses.filter((l) => {
        return l.isExpired || l.available < this.reactivatableHosts.length || this.hosts.every((host) => host.subscriptionUuid === l.uuid);
      });
    },

    /**
     * Hosts which can be reactivated by selected license. Excluding already activated by selected license.
     */
    reactivatableHosts () {
      return this.hosts.filter((h) => {
        return !this.selectedUuid || this.selectedUuid !== h.subscriptionUuid;
      });
    },
  },
  watch: {
    searchResults (val) {
      if (this.selectedUuid && !val.some((s) => s.uuid === this.selectedUuid)) {
        this.selectedUuid = null;
      }
    },
  },
};

</script>

<template lang="pug">

modal(:name="modalName", :closeButton="true", :size="20", :data-name="$name()")
  template(slot="header") {{ header }}
  template(slot="content")
    loader(:loading="loading")
      .row.margin-top-negative.margin-bottom-2x
        .col-xs-12 {{ $tc('Select the new license key to reactivate {amount} computers', hosts.length, {amount: hosts.length}) }}:
      .row.margin-bottom-2x
        filter-textbox(
          ref="searchField",
          name="text",
          :filters.sync="filters",
          :filterFunction="textFilter",
          :disabled="!!loading",
          :data-name="$name('filter-textbox-search')"
        )
      datatable.table.height-30x(
        name="reactivationLicenses",
        :data="searchResults",
        :columns="columns",
        :options="options"
      )
        //-
        //- Table header
        //-
        template(slot="uuid_header", slot-scope="s")
        template(slot="keyFullName_header", slot-scope="s") {{ $t('Key') }}
        template(slot="available_header", slot-scope="s") {{ $t('Available Licenses') }}
        template(slot="expirationDate_header", slot-scope="s") {{ $t('Expires On') }}
        template(slot="details_header", slot-scope="s")
        //-
        //- Table body
        //-
        template(slot="uuid" slot-scope="s")
          //- TODO disable if illigable
          radiobutton(
            v-model="selectedUuid",
            :value="s.row.uuid",
            :disabled="isIneligibleUuid(s.row.uuid)",
            name="reactivation_subscription"
          )
        template(slot="keyFullName", slot-scope="props")
          h3
            template {{ props.row.keyFullName }}
          .text-muted {{ props.row.licKey }}
        template(slot="available", slot-scope="s")
          div(:class="s.row.available < hosts.length ? 'text-danger' : ''")
            | {{ $t('{vacant} of {total}', { vacant: s.row.available, total: s.row.limit }) }}
        template(slot="expirationDate", slot-scope="s")
          .text-danger(v-if="s.row.isExpired") {{ $t('Expired') }}
          div(v-else) {{ s.row.expirationDate | date }}
        template(slot="details", slot-scope="s")
          router-link(
            :to="{ name: 'desktopSubscriptionDetails', params: { id: s.row.parentUuid || s.row.uuid } }",
            v-text="$t('Details')",
            :data-name="$name('link-subscription-details')"
          )
      .margin-bottom-2x.margin-top-2x(v-if="ineligibleLicenses.length")
        formatter(v-if="hideIneligible", :text="$tc('{amount} ineligible keys have been hidden ({show})', ineligibleLicenses.length, { amount: ineligibleLicenses.length, show: '{show}' })")
          template(slot="show", slot-scope="s")
            a(@click="hideIneligible=false") {{ $t('Show') }}
        formatter(v-else, :text="$tc('{amount} keys are ineligible ({hide})', ineligibleLicenses.length, { amount: ineligibleLicenses.length, hide: '{hide}' })")
          template(slot="hide", slot-scope="s")
            a(@click="hideIneligible=true") {{ $t('Hide') }}
  template(slot="footer")
    btn(
      @click="reactivate",
      :disabled="!canReactivate",
      :data-name="$name('button-reactivate')"
    ) {{ $tc('Reactivate {amount} Computers', reactivatableHosts.length, { amount: reactivatableHosts.length }) }}
    btn(
      color="white",
      @click="hide",
      :data-name="$name('button-close')"
    ) {{ $t('Close') }}

</template>

/**
 * File: toolboxRoutes.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ToolboxPage from '../modules/toolbox/toolboxPage.vue';
import { TOOLBOX_DOWNLOADS_PAGE, TOOLBOX_UTILITY_DOWNLOADS_PAGE } from '@/routes/constants';

export default [
  {
    path: '/toolbox/downloads',
    name: TOOLBOX_DOWNLOADS_PAGE,
    component: ToolboxPage,
    props: (route) => ({
      content: 'downloads',
    }),
    meta: {
      requiresAuth: true,
      title: 'Download - Parallels Toolbox',
    },
  },
  {
    path: '/toolbox/computers',
    name: 'toolboxComputers',
    component: ToolboxPage,
    props: () => ({
      content: 'computers',
    }),
    meta: {
      requiresAuth: true,
      title: 'Computers - Parallels Toolbox',
    },
  },
  {
    path: '/toolbox/subscriptions',
    name: 'toolboxSubscriptions',
    component: ToolboxPage,
    props: (route) => ({
      content: 'subscriptions',
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Toolbox',
    },
  },
  {
    path: '/toolbox/subscriptions/:id',
    name: 'toolboxSubscriptionDetails',
    component: ToolboxPage,
    props: (route) => ({
      content: 'toolboxSubscriptionDetails',
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Toolbox',
    },
  },
  {
    path: '/toolbox/:utility(cvt|hdt|dvt|dnst)/downloads',
    name: TOOLBOX_UTILITY_DOWNLOADS_PAGE,
    component: ToolboxPage,
    props: (route) => ({
      content: 'downloads',
      utility: route.params.utility,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/toolbox/:utility(cvt|hdt|dvt|dnst)/subscriptions',
    name: 'toolboxUtilitySubscriptions',
    component: ToolboxPage,
    props: (route) => ({
      content: 'subscriptions',
      utility: route.params.utility,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/toolbox/:utility(cvt|hdt|dvt|dnst)/subscriptions/:id',
    name: 'toolboxUtilitySubscriptionDetails',
    component: ToolboxPage,
    props: (route) => ({
      content: 'toolboxSubscriptionDetails',
      utility: route.params.utility,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/toolbox',
    redirect: {
      name: 'toolboxSubscriptions',
    },
  },
];

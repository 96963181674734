/**
 * File: toolboxHost.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import CoreModel from '@core/common/model';

interface LicKey {
  key: string;
  subscriptionUuid: string;
};

export default class ToolboxHost extends CoreModel {
  licKeys: LicKey[];
  isBlocked: boolean;
  hwId: string;

  get keys () {
    return this.licKeys.map((key) => key.key).join(',');
  }

  get isActivated () {
    return this.licKeys.length > 0;
  }
};

/**
 * File: changePasswordRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import md5 from 'blueimp-md5';
import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import passwordErrors from './errorHandlers/passwordErrors';
import { support } from '@core/constants/links';
import { ACCOUNT_SERVICE } from '@core/constants/services';
import { Dictionary } from '@core/common/types';
import TranslationString from '@/models/translationString';

/* eslint-disable camelcase */
interface ChangePasswordRequestParams {
  password: string;
  new_password: string;
  disconnect_remote_hosts: boolean;
}
/* eslint-enable camelcase */

export interface ChangePasswordRequestResponse {
  token: string;
}

export default class ChangePasswordRequest extends ApiRequest<ChangePasswordRequestParams, ChangePasswordRequestResponse> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/profile/change_password`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    this.params.password = md5(this.params.password);
    return this.params as any as Json;
  }

  get errors (): Dictionary<TranslationString> {
    return Object.assign({
      'rate limited': $t('Too many password change requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    }, passwordErrors());
  }
}

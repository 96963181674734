

import Vue, { PropType } from 'vue';
import { maxLength, required } from '@core/common/validators';
import AuthenticatorApp from '@/models/authenticatorApp';
import {
  MAX_AUTHENTICATOR_APP_NAME_LENGTH
} from '@/modules/personalProfile/personalProfileSecurity/mfaSettings/constants';

export default Vue.extend({
  name: 'authenticator-app-properties-modal',

  props: {
    authenticatorApp: Object as PropType<AuthenticatorApp>,
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(MAX_AUTHENTICATOR_APP_NAME_LENGTH),
      },
    },
  },

  methods: {
    onSave () {
      this.$emit('changed', { newName: this.form.name, authApp: this.authenticatorApp });
      this.$modal.hide();
    },
    onDelete () {
      this.$modal.hide();
      this.$emit('deleteapp', this.authenticatorApp.outerId);
    },
    onCancel () {
      this.$modal.hide();
    },
    onShow () {
      Vue.nextTick(() => {
        (this.$el.querySelector('input[name=name]') as HTMLInputElement).focus();
      });
    },
  },

  data () {
    return {
      form: { name: this.authenticatorApp.name },
    };
  },

  watch: {
    authenticatorApp: function () {
      this.form.name = this.authenticatorApp.name;
    },
  },
});


/**
 * File: farmDetailsModal.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ComponentMixIn from '@/modules/componentMixIn';
import GeneralTab from './general.vue';
import UsageTab from './usage.vue';
import RasUpdateHostRequest from '@/api/rasUpdateHostRequest';
import RasDeactivateHostRequest from '@/api/rasDeactivateHostRequest';

export default {
  // TODO Make common component for PDB, RAS, PMM ... modals
  name: 'farm-details-modal',

  mixins: [ComponentMixIn],

  props: {
    isPostpaid: {
      type: Boolean,
      required: true,
    },
  },

  components: { GeneralTab, UsageTab },

  data () {
    return {
      updating: false,
      deactivating: false,
      tab: 'general',
      host: null,
      form: {
        name: '',
        notes: '',
      },
      options: {},
    };
  },

  methods: {
    show (host, options) {
      options = options || {};
      this.host = host;
      this.form.name = host.name;
      this.form.notes = host.notes;
      this.tab = 'general';
      this.options = options;
      this.$refs.modal.show();
      this.$emit('show', host);
    },

    hide () {
      this.$refs.editableHeader.undo();
      this.$refs.modal.hide();
      this.$emit('hide', this.host);
    },

    deactivate () {
      this.authorizedCall(
        new RasDeactivateHostRequest({
          hwId: this.host.hwId,
        }),
        'deactivating'
      ).then(() => {
        this.$emit('hostDeactivated', this.host);
        this.showToast($t('Farm "{name}" has been deactivated.', { name: this.form.name }));
      });
    },

    save () {
      if (!this.changed) {
        return;
      }
      this.authorizedCall(
        new RasUpdateHostRequest({
          hwId: this.host.hwId,
          name: this.form.name,
          notes: this.form.notes,
        }),
        'updating'
      ).then(() => {
        this.host.name = this.form.name;
        this.host.notes = this.form.notes;
        this.$emit('hostUpdated', this.host);
        this.showToast($t('Farm "{name}" has been updated.', { name: this.form.name }));
      });
    },

    switchTab (params) {
      this.tab = params.tab;
    },
  },

  computed: {
    changed () {
      const host = this.host; const form = this.form;
      return (host && (host.notes !== form.notes || host.name !== form.name));
    },

    tabs () {
      const tabs = {
        general: this.$t('General'),
        usage: this.$t('Usage'),
      };
      return tabs;
    },
  },
};

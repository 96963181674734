
import ProductCardMixIn from './productCardMixIn';
import { PRODUCT_NAME_DAAS } from '@core/constants/subscriptions';
import { DAAS_DOWNLOADS_PAGE, DAAS_SUBPAGE_SUBSCRIPTIONS } from '@/routes/constants';
import Vue from 'vue';
import { TYPE_ALL, TYPE_SPLA, TYPE_SUBSCRIPTION } from '@/models/subscription';

export default Vue.extend({
  name: 'product-card-daas',
  mixins: [ProductCardMixIn],
  props: ['subscriptions'],
  data () {
    return {
      DAAS_DOWNLOADS_PAGE,
      DAAS_SUBPAGE_SUBSCRIPTIONS,
      PRODUCT_NAME_DAAS,
      TYPE_SUBSCRIPTION,
      TYPE_SPLA,
      TYPE_ALL,
    };
  },
  computed: {
    isBusinessAdmin (): boolean {
      return this.$appData.session.isBusinessAdmin;
    },
    daasAdminPortalLink (): string {
      return this.$appData.session.daasAdminPortalUrl;
    },
    daasUserPortalLink (): string {
      return this.$appData.session.daasUserPortalUrl;
    },
  },
});




import Vue, { PropType } from 'vue';
import componentMixIn from '@/modules/componentMixIn';
import searchFilterMixin from '@/modules/searchFilterMixin';
import columnsObserverMixin from '@/modules/columnsObserverMixin';
import AuthenticatorApp from '@/models/authenticatorApp';

export default Vue.extend({
  name: 'authenticator-apps',

  props: {
    authenticatorApps: {
      type: Array as PropType<AuthenticatorApp[]>,
      default: () => ([]),
    },
  },

  mixins: [componentMixIn, searchFilterMixin, columnsObserverMixin],

  data () {
    return {
      loading: false,

      table: {
        name: 'authenticatorApps',
        columns: ['name', 'outerId'],
        options: {
          sortable: [],
          uniqueKey: 'outerId',
        },
        selected: [],
        smartFilters: {},
      },
      filters: {},
    };
  },

  computed: {
    columnsOptions (): { options: ColumnsOptions[] } {
      return {
        options: [
          { text: this.$t('Application'), value: 'name' },
          { text: '', value: 'outerId' },
        ],
      };
    },

    searchResults (): AuthenticatorApp[] {
      const filters = Object.assign({}, this.filters, this.table.smartFilters);
      // @ts-ignore  FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.applySearchFilters(filters, this.authenticatorApps);
    },
  },
});



import Vue from 'vue';
import debounce from 'lodash-es/debounce';
import {
  ACTION_CLOSE,
  ACTION_FORK,
  ACTION_REOPEN,
  ACTION_REPLY,
  ACTION_RESCHEDULE,
  ACTION_SURVEY,
  RESCHEDULE_SESSION_URI
} from '../../constants';
import ComponentMixIn from '@/modules/componentMixIn';
import TicketMixIn from '../../ticketsMixIn';
import RtTicketsRequest from '@/api/rt/rtTicketsRequest';
import RtUpdateTicketRequest from '@/api/rt/rtUpdateTicketRequest';
import RtForkTicketRequest from '@/api/rt/rtForkTicketRequest';
import RtGenerateSurveyRequest from '@/api/rt/rtGenerateSurveyRequest';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import TicketHistoryModal from './ticketHistoryModal.vue';
import ConfirmationModal from '@/ui/confirmationModal/index.vue';
import ConfirmRedirectModal from '@/ui/confirmRedirectModal/index.vue';
import Ticket, { ProductKey, TicketStatus } from '@/models/ticket';
import { CombinedVueInstance } from 'vue/types/vue';
import Session from '@/models/session';
import {
  PRODUCT_NAME_DAAS,
  PRODUCT_NAME_MY_ACCOUNT,
  PRODUCT_NAME_PAX,
  PRODUCT_NAME_PBI,
  PRODUCT_NAME_PMM,
  PRODUCT_NAME_PTB
} from '@core/constants/subscriptions';

const PRODUCT_TYPE_NAME_PD = 'Parallels Desktop';
const PRODUCT_TYPE_NAME_PSW = 'Parallels Secure Workspace';
const PRODUCT_TYPE_NAME_RAS = 'Parallels RAS';

const productMap = new Map<ProductKey, string>([
  [ProductKey.awg, PRODUCT_TYPE_NAME_PSW],
  [ProductKey.daas, PRODUCT_NAME_DAAS],
  [ProductKey.myacc, PRODUCT_NAME_MY_ACCOUNT],
  [ProductKey.pax, PRODUCT_NAME_PAX],
  [ProductKey.paxb, PRODUCT_NAME_PAX],
  [ProductKey.pbi, PRODUCT_NAME_PBI],
  [ProductKey.pdb, PRODUCT_TYPE_NAME_PD],
  [ProductKey.pdfc, PRODUCT_TYPE_NAME_PD],
  [ProductKey.pdfm, PRODUCT_TYPE_NAME_PD],
  [ProductKey.pdpro, PRODUCT_TYPE_NAME_PD],
  [ProductKey.pdlite, PRODUCT_TYPE_NAME_PD],
  [ProductKey.pmm, PRODUCT_NAME_PMM],
  [ProductKey.ptb, PRODUCT_NAME_PTB],
  [ProductKey.ptbb, PRODUCT_NAME_PTB],
  [ProductKey.ras, PRODUCT_TYPE_NAME_RAS],
]);

export default Vue.extend({
  name: 'support-mytickets',
  mixins: [ComponentMixIn, SearchFilterMixin, TicketMixIn],
  components: {
    TicketHistoryModal,
    ConfirmationModal,
    ConfirmRedirectModal,
  },

  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      loading: false,
      tickets: [] as Ticket[],
      amount: 0,
      perPage: 30,
      table: {
        columns: ['id', 'product', 'subject', 'author', 'created_at', 'hint', 'status', 'actions'],
        options: {
          sortable: [],
          pagination: false,
          saveState: false,
        },
      },
      columnsOptions: [
        { text: this.$t('Subject'), value: 'subject' },
        { text: this.$t('Author'), value: 'author' },
        { text: this.$t('Created'), value: 'created_at' },
      ],
      page: Number(this.$route.query.page) - 1 || 0,
      query: this.$route.query.query || '',
      status: this.$route.query.status || 'all',
      orderBy: this.$route.query.order || 'id',
      ascending: this.$route.query.asc || false,
      amountAll: 0,
      amountCallScheduled: 0,
      amountClosed: 0,
      amountInEngineering: 0,
      amountInProgress: 0,
      amountWaitingMyResponse: 0,
      selectedDomainId: this.$appData.userInDomain,
    };
  },

  mounted () {
    this.load();
  },

  updated () {
    if (!this.$el.querySelector) {
      return;
    }

    const container = this.$el.querySelector('.table-responsive');
    const subjects = Array.from<HTMLElement>(this.$el.querySelectorAll('table .subject'));

    // Remove maxWidth from subjects in table
    for (const subject of subjects) {
      subject.style.maxWidth = '';
    }

    // If table fits all columns without scroll - leave it as is
    if (container && container.clientWidth === container.scrollWidth) {
      return;
    }

    // Calculate max width and set 75% of it to subjects widths
    const maxWidth = Math.max.apply(null, subjects.map((s) => (s.parentNode as Element).clientWidth));
    const width = maxWidth * 0.75;

    if (width) {
      for (const subject of subjects) {
        subject.style.maxWidth = `${width}px`;
      }
    }
  },

  methods: {
    load () {
      const
        request = new RtTicketsRequest({
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16311
          q: this.query,
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16311
          status: this.status,
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16311
          offset: this.page,
          limit: this.perPage,
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16311
          order: this.orderBy,
          dir: this.ascending ? 'asc' : 'desc',
        });

      this.loading = true;

      this.$api.callRt(request).then((data) => {
        this.amount = data.amount;
        this.amountAll = data.amountAll;
        this.amountCallScheduled = data.amountCallScheduled;
        this.amountClosed = data.amountClosed;
        this.amountInEngineering = data.amountInEngineering;
        this.amountInProgress = data.amountInProgress;
        this.amountWaitingMyResponse = data.amountWaitingMyResponse;
        this.tickets = data.tickets;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    reload () {
      new RtTicketsRequest().dropFullCache();
      this.load();
    },

    onActionClick (action, row) {
      if (action === ACTION_REPLY) {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.ticketHistoryModal.show(row.id);
      } else if (action === ACTION_CLOSE) {
        this.$modal.show(ConfirmationModal, {
          title: this.$t('Close Ticket'),
          message: this.$t('Let us know the results:'),
          size: 18,
          btnLabel: this.$t('Close'),
          showNotes: true,
          notesMandatory: true,
          success: (data) => { this.closeTicket(row.id, data.notes); },
        });
      } else if (action === ACTION_RESCHEDULE) {
        // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
        this.$refs.confirmRedirectToSeparatePage.show(`${this.$appData.session.rtUrl}${RESCHEDULE_SESSION_URI}${row.id}`);
      } else if (action === ACTION_SURVEY) {
        this.generateSurvey(row.id).then((data) => {
          this.$trackEvent({
            category: 'Support',
            name: 'Survey clicked',
          });
          // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
          this.$refs.confirmRedirectToSeparatePage.show(data.url);
        });
      } else if (action === ACTION_REOPEN) {
        this.$modal.show(ConfirmationModal, {
          title: this.$t('Reopen Ticket'),
          message: this.$t('Let us know how we can help you'),
          size: 18,
          btnLabel: this.$t('Reopen'),
          showNotes: true,
          notesMandatory: true,
          success: (data) => { this.reopenTicket(row.id, data.notes); },
        });
      } else if (action === ACTION_FORK) {
        this.$modal.show(ConfirmationModal, {
          title: this.$t('Reopen Ticket'),
          message: this.$t('Let us know how we can help you'),
          size: 18,
          btnLabel: this.$t('Reopen'),
          showNotes: true,
          notesMandatory: true,
          success: (data) => { this.forkTicket(row.id, data.notes); },
        });
      }
    },

    closeTicket (ticketId, notes) {
      this.loading = true;
      this.$api.callRt(new RtUpdateTicketRequest({ ticketId: ticketId, status: 'resolved', comment: notes }))
        .then(() => {
          this.reload();
          this.showToast(this.$t('Thank you! We`re always here to help.', { ticketId: ticketId }));
        })
        .finally(() => {
          this.loading = false;
          this.$trackEvent({
            category: 'Support',
            name: 'Ticket closed',
          });
        });
    },

    reopenTicket (ticketId, notes) {
      this.loading = true;
      this.$api.callRt(new RtUpdateTicketRequest({ ticketId: ticketId, status: 'open', comment: notes }))
        .then(() => {
          this.reload();
          this.showToast(this.$t('Ticket {ticketId} reopened.', { ticketId: ticketId }));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    forkTicket (ticketId, notes) {
      this.loading = true;
      this.$api.callRt(new RtForkTicketRequest({ ticketId: ticketId, comment: notes }))
        .then((data) => {
          this.reload();
          this.showToast(this.$t('Thank you! Ticket #{ticketId} was created successfully. Parallels Support Team will contact you soon.', { ticketId: data.ticket_id }));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    generateSurvey (ticketId) {
      this.loading = true;
      return this.$api.callRt(new RtGenerateSurveyRequest({ ticketId: ticketId }))
        .then((data) => {
          this.loading = false;
          return data;
        })
        .catch((data) => {
          this.loading = false;
        });
    },

    getStatusTooltip (ticket: Ticket): string {
      const status = ticket.fields.status;
      if (status === TicketStatus.inProgress) {
        return this.$t('We are working on your issue and will get back to you with any updates.');
      } else if (status === TicketStatus.waitingMyResponse) {
        return this.$t('We are waiting for your reply to continue working on your issue.');
      } else if (status === TicketStatus.callScheduled) {
        return this.$t('We will follow up with you at the appointed time.');
      } else if (status === TicketStatus.closed) {
        return this.$t('We believe your issue is solved. Let us know if not and we will resume work.');
      } else if (status === TicketStatus.inEngineering) {
        return this.$t('The issue is being investigated by the Engineering Team and will be addressed in one of the product updates.');
      }
      return '';
    },

    changePage (n) {
      this.page = n;
      this.reload();
    },

    onHeaderClick (column) {
      if (this.orderBy === column) {
        this.ascending = !this.ascending;
      }
      this.orderBy = column;
      this.reload();
    },

    onUserSwitched: function (val) {
      this.$appData.setUserInDomain(val);
    },

    getProductName (productKey: ProductKey): string {
      return productMap.get(productKey) || '?';
    },
  },

  computed: {

    switcherOptions (): Record<string, any>[] {
      const session: Session = this.$appData.session;
      const options: Record<string, any>[] = [];
      if (this.$appData.products.personal.length) {
        let name = session.name && session.name.trim();
        if (!name) {
          name = session.email;
        }
        options.push({
          title: name,
          text: this.$t(' (Personal Account)'),
          value: session.personalDomainId,
        });
      }

      session.getBusinessDomains().forEach((domain) => {
        options.push({
          title: domain.companyName,
          text: this.$t(' (Business Account)'),
          value: domain.companyUuid,
        });
      });
      return options;
    },

    statusOptions (): Record<string, any>[] {
      return [
        {
          text: this.$t('All ({count})', { count: this.amountAll }),
          value: 'all',
        },
        {
          text: this.$t('Work in Progress ({count})', { count: this.amountInProgress }),
          value: TicketStatus.inProgress,
        },
        {
          text: this.$t('Waiting for My Response ({count})', { count: this.amountWaitingMyResponse }),
          value: TicketStatus.waitingMyResponse,
        },
        {
          text: this.$t('Pending Scheduled Event ({count})', { count: this.amountCallScheduled }),
          value: TicketStatus.callScheduled,
        },
        {
          text: this.$t('In Engineering ({count})', { count: this.amountInEngineering }),
          value: TicketStatus.inEngineering,
        },
        {
          text: this.$t('Solution Provided ({count})', { count: this.amountClosed }),
          value: TicketStatus.closed,
        },
      ];
    },

    pages (): number[] {
      return [...Array(Math.ceil(this.amount / this.perPage)).keys()];
    },

    rows (): Record<string, any>[] {
      const restrictedActions = [ACTION_CLOSE, ACTION_REOPEN, ACTION_FORK];

      return this.tickets.map((ticket) => {
        const fields = ticket.fields;
        const row = {
          id: String(ticket.id),
          product: this.getProductName(fields.productKey),
          subject: fields.summary,
          author: fields.author.length > 30 ? fields.author.substring(0, 30) + '...' : fields.author,
          created_at: fields.created,
          actions: [] as string[],
          ticket,
          mergedIn: fields.mergedIn,
        };

        const canSeeRestrictedActions = this.isAdmin || fields.author === this.$appData.session.email;

        row.actions = ticket.actions.filter((action) => {
          return !restrictedActions.includes(action) || canSeeRestrictedActions;
        });

        return row;
      });
    },

    showDomainSwitcher (): boolean {
      return this.switcherOptions.length > 1;
    },

    isAdmin (): boolean {
      return this.$appData.session.isBusinessAdmin;
    },
  },

  watch: {
    '$appData.userInDomain': function (userInDomain) {
      if (this.$appData.session.isPersonalUser(userInDomain) || this.$appData.session.isCurrentBusinessUser(userInDomain)) {
        this.$emit('reload');
        this.reload();
      } else {
        this.loading = true;
        this.$api.switchBusinessDomain(userInDomain).then((data) => {
          this.$emit('reload');
          this.reload();
        });
      }
    },

    pages (pages) {
      if (pages.length && this.page > pages.length - 1 && this.page !== 0) {
        this.changePage(pages.length - 1);
      }
    },

    page (newValue) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { page: newValue + 1 }) });
    },

    orderBy (newValue) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { sort: newValue }) });
    },

    ascending (newValue) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { asc: newValue }) });
    },

    status (newValue, oldValue) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { status: newValue }) });
      this.page = 0;
      this.reload();
    },

    query: debounce(function (this: CombinedVueInstance<any, any, any, any, any>, newValue, oldValue) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { query: newValue }) });
      this.page = 0;
      this.reload();
    }, 500),
  },
});


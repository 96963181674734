/**
 * File: oneTimePasswordWorkflowMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import Vue from 'vue';

export const MAX_INVALID_PASSWORD_ATTEMPTS = 3;

export default Vue.extend({
  name: 'one-time-password-workflow-mix-in',
  data () {
    return {
      invalidOneTimePassword: false,
      appliedOneTimePassword: null as string,
      invalidPasswordAttempts: 0,
    };
  },
  methods: {
    invalidPasswordEntered () {
      this.invalidOneTimePassword = true;
      this.invalidPasswordAttempts += 1;
      if (this.invalidPasswordAttempts > MAX_INVALID_PASSWORD_ATTEMPTS) {
        this.$toast.show({
          color: 'red',
          text: this.$t('Too many invalid passwords.'),
        });
        this.$modal.hide();
        this.invalidPasswordAttempts = 0;
      }
    },

    resetAppliedPasswordState (appliedPassword: string = '') {
      this.invalidOneTimePassword = false;
      this.appliedOneTimePassword = appliedPassword;
    },
  },
});



import Vue from 'vue';
import { FooterView } from '@parallels/ui-kit';
import LanguageSwitcher from '@/ui/languageSwitcher.vue';
import { LEGAL_NOTICES_URL, SYSTEM_STATUS } from '@core/constants/links';
import { FEATURE_STATUS_PAGE_LINK_IN_MA } from '@core/constants/features';
import GetStatusPageDetails from '@/api/getStatusPageDetails';

export default Vue.extend({
  name: 'footer-view-my',
  components: {
    FooterView,
    LanguageSwitcher,
  },
  data () {
    return {
      year: (new Date()).getFullYear(),
      SYSTEM_STATUS,
      LEGAL_NOTICES_URL,
      showSystemStatus: this.$appData.session.isFeatureAccessible(FEATURE_STATUS_PAGE_LINK_IN_MA),
      isActiveIncident: false,
    };
  },
  async created () {
    if (this.showSystemStatus) {
      const result = await this.$api.authorizedCall(new GetStatusPageDetails());
      this.isActiveIncident = !!result?.data?.['active_incident_details'].length;
    }
  },
});


/**
 * File: oneTimePasswordMixin.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import Vue from 'vue';

import { oneTimePassword } from '@core/common/validators';
import { CANT_USE_RESCUE_CODES_PAGE, CHANGE_SETTINGS_MODE } from '@/routes/constants';
import { OTP_TYPE_RC, OTP_TYPE_OTP } from '@/ui/one-time-password';
import InputOneTimePassword from '@/ui/one-time-password/index.vue';

export default Vue.extend<{ form; otpInputType; OTP_TYPE_RC; OTP_TYPE_OTP }, unknown, unknown, { loading; invalidOneTimePassword; appliedOneTimePassword }>({
  props: {
    loading: Boolean,
    invalidOneTimePassword: Boolean,
    appliedOneTimePassword: String,
  },

  components: {
    InputOneTimePassword,
  },

  validations: {
    form: {
      otp: {
        ...oneTimePassword,
        invalidOneTimePassword: function () {
          return !(this.invalidOneTimePassword && this.appliedOneTimePassword === this.form.otp);
        },
      },
    },
  },

  data () {
    return {
      OTP_TYPE_RC,
      OTP_TYPE_OTP,

      otpInputType: OTP_TYPE_OTP,
      form: { otp: '' },
    };
  },

  methods: {
    resetOneTimePasswordField () {
      this.form.otp = '';
      this.$v.$reset();
    },

    goToCantUseRescueCodesPage () {
      this.$modal.hide();
      this.$router.push({ name: CANT_USE_RESCUE_CODES_PAGE, params: { mode: CHANGE_SETTINGS_MODE } });
    },

    returnToOneTimePassword (): void {
      this.otpInputType = OTP_TYPE_OTP;
    },

    cantUseOneTimePassword (): void {
      this.otpInputType = OTP_TYPE_RC;
    },
  },

  watch: {
    'form.otp': function (value: string) {
      if (value.includes(' ')) {
        this.form.otp = value.replace(' ', '');
      }
      if (!this.form.otp.invalidOneTimePassword) {
        this.$v.$touch();
      }
    },
  },

});

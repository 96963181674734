
import Vue from 'vue';
import ComponentMixin from '@/modules/componentMixIn';
import { KB_LINK_PSW, PSW_EXCHANGE_KEY_LINK } from '@core/constants/links';

export default Vue.extend({
  name: 'exchange-old-keys-modal',
  mixins: [ComponentMixin],
  data () {
    return {
      loading: false,
      operatorOptions: [],
      KB_LINK_PSW,
    };
  },
  methods: {
    onContinueClick (): void {
      window.open(PSW_EXCHANGE_KEY_LINK, '_blank');
      this.$modal.hide();
    },
    onCloseClick (): void {
      this.$modal.hide();
    },
  },
});

/**
 * File: hideSubscriptionRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';

export default class HideSubscriptionRequest extends ApiRequest {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    const subscription = this.params.subscription;
    return `/${subscription.targetServiceName}/api/v1/subscriptions/${subscription.uuid}/hide`;
  }

  get body (): Json {
    return {};
  }

  load () {
    return super.load().then((response) => {
      new SubscriptionsRequest({}).dropFullCache();
      return response;
    });
  }
}

/**
 * File: convertPdlOptionsRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class ConvertPdlOptionsRequest extends ApiRequest {
  get cacheNameSpace () {
    return false;
  }

  get url () {
    return `/license/api/v1/subscriptions/${this.params.uuid}/sell_options?species=DISCOUNT`;
  }
}

<copyright>
File: emailChangeSecurityNoticePage.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">
page(:appData="appData")
  template(slot="pageContent")
    .row
      .col-md-6.col-md-offset-3
        emailChangeSecurityNotice(:showHeader="true")
</template>
<script>
import emailChangeSecurityNotice from './emailChangeSecurityNotice.vue';

export default {
  name: 'email-change-security-notice-page',
  components: {
    emailChangeSecurityNotice,
  },
  props: {
    appData: Object,
  },
};
</script>

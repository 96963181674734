
import Vue from 'vue';

import { GPS_SUBSCRIPTION_MANAGEMENT_URL } from '@core/constants/links';

import { CONFIGURE_GPS_MODAL } from '@/modules/subscriptions/constants';

export default Vue.extend({
  name: 'configure-gps-modal',
  data () {
    return {
      CONFIGURE_GPS_MODAL,
      fromModal: '',
      gpsLink: GPS_SUBSCRIPTION_MANAGEMENT_URL,
    };
  },
  methods: {
    clickHandler (props: { fromModal: string }): void {
      this.fromModal = props.fromModal;
      this.$modal.show(CONFIGURE_GPS_MODAL);
    },
    cancelHandler (): void {
      this.$modal.hide(CONFIGURE_GPS_MODAL);
      if (this.fromModal) {
        this.$modal.show(this.fromModal);
      }
    },
  },
});


/**
 * File: licenseTokenRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method } from '@core/api/apiRequest';

export type LicenseTokenRequestRequestParams = {
  subscriptionUuid: string;
};

type LicenseTokenRequestResponseData = {
  // eslint-disable-next-line camelcase
  license_file_token: string;
};

export default class LicenseTokenRequest extends ApiRequest<LicenseTokenRequestRequestParams, LicenseTokenRequestResponseData> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/awingu/webapp/subscriptions/${this.params.subscriptionUuid}/license_file`;
  }
}

/**
 * File: rtCreateActivationTicketRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 */

import RtRequest from './rtRequest';
import RtTicketsRequest from './rtTicketsRequest';
import { Method } from '@core/api/apiRequest';

interface RtCreateActivationTicketRequestParams {
  email: string;
  country: string;
  name: string;
  description: string;
}

/**
 * https://wiki.prls.net/x/gjCrAg#apiforaccount.parallels.com-create_ticket_activation
 */
export default class RtCreateActivationTicketRequest extends RtRequest<RtCreateActivationTicketRequestParams, { id: number }> {
  get uri (): string {
    return '/ticket/activation';
  }

  get method (): Method {
    return 'POST';
  }

  get body (): any {
    return { fields: this.params };
  }

  async load () {
    const data = await super.load();
    new RtTicketsRequest().dropCache();
    return data;
  }
}

/**
 * File: verifyOneTimePasswordRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

interface VerifyOneTimePasswordRequestParams {
  oneTimePassword: string;
  markTrusted?: boolean;
}

export default class VerifyOneTimePasswordRequest extends ApiRequest<VerifyOneTimePasswordRequestParams> {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/mfa/auth_apps/verify`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      one_time_password: this.params.oneTimePassword,
      mark_trusted: this.params.markTrusted,
    };
  }
}

<copyright>
File: hostDetailsModal.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

modal(id="hosts-details", ref="modal", name="hosts-details", size="big")
  editable-header(
    v-if="host",
    ref="editableHeader",
    v-model="name",
    slot="editableHeader",
    :disabled="isRenaming",
    @confirm="$nextTick(save)"
  )
  template(slot="content", v-if="host")
    .margin-bottom-2x
      list
        list-item
          template(v-if="licKeys.length")
            .text-success.line-height-3x(slot="label") {{ $t('Activated') }}
            btn(
              slot="value",
              size="small",
              color="red-stroke",
              :disabled="isDeactivating",
              @click="deactivate",
              :data-name="$name('button-deactivate')"
            ) {{ $t('Deactivate') }}
          template(v-else-if="host.isBlocked")
            .text-danger.line-height-3x(slot="label") {{ $t('Blacklisted') }}
          template(v-else)
            .text-danger.line-height-3x(slot="label") {{ $t('Deactivated') }}

        list-item
          template(slot="label") {{ $t('Parallels Toolbox Version') }}
          template(slot="value") {{ host.productVersion }}

        list-item
          template(slot="label") {{ $t('Host OS') }}
          template(slot="value") {{ host.osName }}

        list-item(v-if="licKeys", labelWidth="4")
          template(slot="label") {{ $t('Activation Key') }}
          template(slot="value")
            template(v-for="licKey in licKeys")
              router-link(
                :to="{ name: 'toolboxSubscriptionDetails', params: { id: licKey.subscriptionUuid } }",
                :data-name="$name('link-lic-key')"
              ) {{ licKey.key }}
              br

        list-item
          template(slot="label") {{ $t('Activated Date') }}
          template(slot="value") {{ activatedAt | dateTime }}

  template(slot="footer")
    btn(
      color="white",
      @click.native="$modal.hide()",
      :data-name="$name('button-close')"
    ) {{ $t('Close') }}

</template>

<script>

import PtbDeactivateHostsRequest from '@/api/ptbDeactivateHostsRequest';
import PtbUpdateHostRequest from '@/api/ptbUpdateHostRequest';

export default {
  name: 'host-details-modal',

  data () {
    return {
      host: null,
      isDeactivating: false,
      isRenaming: false,
      name: '',
      activatedAt: null,
      licKeys: [],
    };
  },

  methods: {
    show (host) {
      this.host = host;
      this.activatedAt = host.activatedAt;
      this.name = host.name;
      this.licKeys = host.licKeys;
      this.$refs.modal.show();
    },

    async deactivate () {
      this.isDeactivating = true;

      const request = new PtbDeactivateHostsRequest({ hosts: [this.host] });
      try {
        await this.$api.authorizedCall(request);
      } catch (e) {
        this.$toast.show({ text: this.$t('Error occurred'), color: 'red' });
        throw e;
      } finally {
        this.isDeactivating = false;
      }

      this.licKeys = [];
      this.activatedAt = null;
      this.$toast.show({ text: this.$t('Computer "{name}" has been deactivated.', { name: this.host.name }) });
    },

    async save () {
      this.isRenaming = true;

      const request = new PtbUpdateHostRequest({ hwId: this.host.hwId, name: this.name });
      try {
        await this.$api.authorizedCall(request);
      } catch (e) {
        this.$toast.show({ text: this.$t('Error occurred'), color: 'red' });
        throw e;
      } finally {
        this.isRenaming = false;
      }

      this.host.name = this.name;
      this.$toast.show({ text: this.$t('Computer "{name}" has been updated.', { name: this.host.name }) });
    },
  },
};

</script>

/**
 * File: status.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

const KEY_NAME = 'session_expired';

function isExpired () {
  return !!Vue.ls.get(KEY_NAME);
}

function setExpiration (status) {
  if (status) {
    Vue.ls.set(KEY_NAME, true);
  } else {
    Vue.ls.remove(KEY_NAME);
  }
}

export default {
  isExpired,
  setExpiration,
};

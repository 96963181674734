/**
 * File: emailPatternMismatch.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

export default function emailPatternMismatch (response) {
  if (response && response.errors && response.errors.email === 'pattern mismatch') {
    return $t('Email address is not valid');
  }
  return undefined;
}

/**
 * File: visible.js
 *
 * Copyright:
 * Copyright © 2019 Parallels International GmbH. All rights reserved.
 *
 */

import Vue from 'vue';

Vue.directive('visible', function (el, binding) {
  const value = binding.value;
  el.style.visibility = value ? 'visible' : 'hidden';
});

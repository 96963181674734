<copyright>
  File: step2SelectSupportType.vue

  Copyright:
  Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

div
  .row
    .col-sm-offset-2.col-sm-8.col-xs-12
      h2(data-qa="choose-category-header") {{ $t('Choose a Category Below to Get Started') }}
  .row.margin-bottom
    .col-sm-offset-2.col-sm-8.col-xs-12
      card(
        type="plain",
        :clickable="true",
        @click="onCardClick({ name: GET_SUPPORT_PAGE, params: { product: product, supportType: 'presales' } }, 'Presales & Purchase')",
        :data-name="$name('router-link-product-' + product + '-license')",
        data-qa="presales-purchase-category-card"
      )
        div {{ $t('Pre-sales & Purchase') }}
        div.text-muted(v-if="isRasProduct") {{ $t('How-tos and technical questions for your POC') }}
        div.text-muted(v-else) {{ $t('Upgrade, billing, and returns') }}

  .row.margin-bottom
    .col-sm-offset-2.col-sm-8.col-xs-12
      card(
        type="plain",
        :clickable="true",
        @click="onCardClick({ name: GET_SUPPORT_PAGE, params: { product: product, supportType: 'license' } }, 'License & Subscription')",
        :data-name="$name('router-link-product-' + product + '-license')",
        data-qa="license-subscription-category-card"
      )
        div {{ $t('License & Subscription') }}
        div.text-muted {{ $t('Activation, registration, and subscription management') }}
  .row
    .col-sm-offset-2.col-sm-8.col-xs-12
      card(
        type="plain",
        :clickable="true",
        @click="onCardClick({ name: GET_SUPPORT_PAGE, params: { product: product, supportType: 'technical' } }, 'Technical Issues')",
        :data-name="$name('router-link-product-' + product + '-technical')",
        data-qa="technical-issues-category-card"
      )
        div {{ $t('Technical Issues') }}
        div.text-muted {{ $t('Product installation, configuration, and features') }}
  .row.margin-top-2x(v-if="downloads[product]")
    .col-sm-offset-2.col-sm-8.col-xs-12
      .line
      card(
        type="plain",
        :clickable="true",
        @click="onCardClick(downloads[product], 'Downloads & Documentation')",
        data-qa="downloads-documentation-category-card"
      )
        div {{ $t('Downloads & Documentation') }}
        div.text-muted {{ $t('Installation files and documents') }}

</template>

<script>

import {
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTBB
} from '@core/constants/subscriptions';
import { GET_SUPPORT_PAGE, PRODUCT_CONTEXT_TO_DOWNLOAD_MAP, TOOLBOX_DOWNLOADS_PAGE } from '@/routes/constants';

export default {
  name: 'support-step2-select-type',

  props: {
    product: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      downloads: {
        ...PRODUCT_CONTEXT_TO_DOWNLOAD_MAP,
        // In downloads section Toolbox has toolbox name, not ptb
        [PRODUCT_KEY_NAME_PTB]: { name: TOOLBOX_DOWNLOADS_PAGE },
        [PRODUCT_KEY_NAME_PTBB]: { name: TOOLBOX_DOWNLOADS_PAGE },
      },
      GET_SUPPORT_PAGE,
    };
  },

  computed: {
    isRasProduct () {
      return this.product === PRODUCT_KEY_NAME_RAS;
    },
  },

  methods: {
    onCardClick (route, category) {
      this.$trackEvent({
        category: 'Support',
        name: 'Support category click',
        source: category,
      });

      this.$router.push(route);
    },
  },
};

</script>

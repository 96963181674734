/**
 * File: loginRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import md5 from 'blueimp-md5';
import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import { support } from '@core/constants/links';
import emailPatternMismatch from './errorHandlers/emailPatternMismatch';

export type LoginRequestParams = {
  email: string;
  password: string;
  rememberMe?: boolean;
  gRecaptchaResponse?: boolean;
};

// https://wiki.prls.net/x/DtURBQ
export default class LoginRequest extends ApiRequest<LoginRequestParams> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return '/account/webapp/login';
  }

  get body (): Json {
    return {
      email: this.params.email,
      password: md5(this.params.password),
      remember_me: this.params.rememberMe,
      'g-recaptcha-response': this.params.gRecaptchaResponse,
    };
  }

  get errors () {
    return {
      'invalid login or password': $t('Invalid email or password'),
      'not confirmed': $t('Account not confirmed'),
      'account disabled': $t('This user account is blocked'),
      'The link you clicked is invalid or expired.': $t('The link you clicked is invalid or expired'),
      'invalid parameters': response => emailPatternMismatch(response) || $t('Invalid parameters'),
      'rate limited': $t('Too many login requests have been submitted from your IP address. For security reasons processing of further requests from that IP has been temporarily suspended. Please {link_start}contact Parallels Support{link_end} for assistance.', {
        link_start: `<a href="${support.base}" target="_blank">`,
        link_end: '</a>',
      }),
    };
  }
}

/**
 * File: translationString.js
 *
 * Copyright:
 * Copyright © 2020 Parallels International GmbH. All rights reserved.
 *
 **/

//
// Deprecated vue-tables-2 component makes a deep copy of table data (including translation strings),
// so this property was moved here to prevent infinite loop while cloning this object
// TODO: move back when vue-tables-2 will be removed from ui-kit
//
let context;

export default class TranslationString {
  constructor (params) {
    this.str = params.str;
    this.n = params.n;
    this.replacements = params.replacements;
    this.plural = typeof this.n === 'number';
    context = params.context;
  }

  toString () {
    if (this.plural) {
      return context.$tc(this.str, this.n, this.replacements);
    }
    return context.$t(this.str, this.replacements);
  }

  replace (placeholder, value) {
    placeholder = placeholder.replace(/[{}]/g, '');
    this.replacements[placeholder] = value;
    return this;
  }
}

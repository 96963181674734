/**
 * File: emailChangeStatusRequest.js
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';

export default class EmailChangeStatusRequest extends ApiRequest {
  get method () {
    return 'GET';
  }

  get url () {
    return '/account/api/v1/profile/change_email';
  }
}

/**
 * File: apiRequestDownloadFile.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from './apiRequest';
import { HTTP_CODES } from '../constants/http';

export default class ApiRequestDownloadFile extends ApiRequest {

  load () {
    this.emitter.emit('beforeLoad', this);
    return this.getPromise();
  }

  getPromise () {
    return this.getFetchPromise();
  }

  getFetchPromise () {
    this.promise = fetch(this.url, this.options);

    // Safari 10.1.2 fetch returns Promise without finally even it polyfilled
    // @ts-ignore
    if (!this.promise.finally && this.promise.__proto__) { // eslint-disable-line
      // @ts-ignore
      this.promise.__proto__.finally = Promise.prototype.finally; // eslint-disable-line
    }

    this.emitter.emit('started', this);
    return this.promise
      .then((response) => {
        this.response = response;
        return response;
      })
      .then((response) => {
        return this.checkStatus(response);
      })
      .then((response) => {
        return response.blob();
      })
      .catch((error) => {
        let response = error.response;
        if (this.checkUnauthorizedResponse && response && response.status === HTTP_CODES.UNAUTHORIZED) {
          response.json().then((data) => {
            if (data && (data.msg === 'incorrect credentials' || data.msg === 'invalid token')) {
              // Prevent infinite loop
              location.href = '/login';
            }
          });
        }
        this.error = error;
        this.emitter.emit('error', this);
        throw error;
      });
  }
};

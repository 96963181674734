/**
 * File: productCardMixIn.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue from 'vue';

import { support as supportLinks } from '@core/constants/links';
import { CUSTOMER_EXPERIENCE_PROGRAM_URL } from '@core/common/links';
import {
  prodKeyToName,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDPRO,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_RAS_AZMP,
  PRODUCT_NAME_PDB,
  PRODUCT_NAME_PDFM_PRO,
  PRODUCT_NAME_RAS
} from '@core/constants/subscriptions';
import { FEATURE_MANAGE_CEP_OPTION, FEATURE_SUPPORT_PORTAL } from '@core/constants/features';
import Subscription, {
  ProductInfo,
  CONCURRENT_PRODUCT_MARK,
  NAMED_PRODUCT_MARK,
  STATUS_ACTIVE,
  STATUS_ALL,
  STATUS_EXPIRED,
  STATUS_IS_EXPIRING_SOON
} from '@/models/subscription';
import { GET_SUPPORT_PAGE, PRODUCT_CONTEXT_TO_DOWNLOAD_MAP } from '@/routes/constants';
import SetCepOptionRequest from '@/api/setCepOptionRequest';
import { LICENSE_SERVICE } from '@core/constants/services';
import { HTTP_CODES } from '@core/constants/http';
import { RawLocation } from 'vue-router/types/router';

export default Vue.extend({
  props: {
    item: {
      type: Object,
    },

    index: {
      type: Number,
    },

    last: {
      type: Boolean,
    },

    equalTo: {
      type: String,
    },

    session: {
      type: Object,
    },

    personalMode: {
      type: Boolean,
    },

    subscriptions: {
      type: Array,
    },
  },
  data () {
    return {
      isCepOptionAccessible: (this.session && this.session.isFeatureAccessible) ? this.session.isFeatureAccessible(FEATURE_MANAGE_CEP_OPTION) : false,
      cepOption: !!this.$appData.products.cepOptions[this.item.productKeyName],
      setCepOptionLoading: false,
      supportLinks,
      STATUS_ALL,
      STATUS_ACTIVE,
      STATUS_IS_EXPIRING_SOON,
      STATUS_EXPIRED,
    };
  },
  mounted () {
    if ('setAttribute' in this.$el) {
      this.$el.setAttribute('data-name', this.$name());
    }
  },
  methods: {
    openCepInfo () {
      window.open(CUSTOMER_EXPERIENCE_PROGRAM_URL(this.$appData.session.isoLocale));
    },
    setCepOption (): void {
      this.setCepOptionLoading = true;
      const cepOption = !this.cepOption;
      const request = new SetCepOptionRequest({
        service: LICENSE_SERVICE, productKeyName: this.item.productKeyName, cepOption,
      });

      this.$api.authorizedCall(request).then(() => {
        this.$toast.show({
          text: this.$t('CEP option was {enabled} for {productName}', { enabled: cepOption ? this.$t('enabled') : this.$t('disabled'), productName: this.item.productName }), lifetime: 2000,
        });
        this.$appData.products.cepOptions[this.item.productKeyName] = cepOption;
        this.cepOption = cepOption;
      }).catch((error) => {
        if (error.status === HTTP_CODES.BAD_REQUEST) {
          this.$toast.show({
            text: this.$t('Invalid product key name {productKeyName}', { productKeyName: this.item.productKeyName }),
            color: 'red',
          });
        } else {
          throw error;
        }
      }).finally(() => {
        this.setCepOptionLoading = false;
      });
    },
    onSupportClick (): void {
      if (this.$appData.session.isFeatureAccessible(FEATURE_SUPPORT_PORTAL)) {
        let product = this.item.productKeyName;
        let productName;

        if (product === PRODUCT_KEY_NAME_PDB_PER_USER) {
          product = PRODUCT_KEY_NAME_PDB;
          productName = PRODUCT_NAME_PDB;
        }

        if (product === PRODUCT_KEY_NAME_PDFM && this.hasPdPro) {
          product = PRODUCT_KEY_NAME_PDPRO;
          productName = PRODUCT_NAME_PDFM_PRO;
        }

        if (product === PRODUCT_KEY_NAME_RAS_AZMP) {
          product = PRODUCT_KEY_NAME_RAS;
          productName = PRODUCT_NAME_RAS;
        }

        if (this.item.productKeyName.startsWith('ptb-')) {
          product = PRODUCT_KEY_NAME_PTB;
        }

        // Set click source to handle it on support side
        this.$sessionStorage.set('support-click', {
          source: 'dashboard',
        });

        this.$trackEvent({
          category: 'Support',
          name: 'Support button click on Dashboard',
          source: productName || prodKeyToName(product),
        });

        this.$router.push({
          name: GET_SUPPORT_PAGE,
          params: {
            product,
          },
        });
      } else {
        location.href = this.supportLinks[this.item.productKeyName];
      }
    },
    showAddLicensesModal (): void {
      this.$modal.show('addLicensesModal', { product: this.item.productKeyName });
    },
    getProductsCountByName (products, keyName: string): number {
      return products.filter(product => product.keyName.includes(keyName)).map(p => p.licenses.thisPeriod).reduce((sum, a) => sum + a, 0);
    },
  },
  computed: {
    activeSubscriptions (): Subscription[] {
      return this.subscriptions.filter((s: Subscription) => s.hasProduct(this.item.productKeyName) && s.isActive) as Subscription[];
    },
    splaContracts (): number {
      return this.activeSubscriptions.filter((s: Subscription) => s.isPostPaid).length;
    },
    notSplaProducts (): ProductInfo[] {
      return this.activeSubscriptions.filter((s: Subscription) => !s.isPostPaid).map((s: Subscription) => s.products).flat();
    },
    concurrentItemsCount (): number {
      return this.getProductsCountByName(this.notSplaProducts, CONCURRENT_PRODUCT_MARK);
    },
    namedItemsCount (): number {
      return this.getProductsCountByName(this.notSplaProducts, NAMED_PRODUCT_MARK);
    },
    forConcurrentSubtitle (): string {
      return $tc('for {limit} concurrent users', this.concurrentItemsCount, { limit: this.concurrentItemsCount });
    },
    forNamedSubtitle (): string {
      return $tc('for {limit} named users', this.namedItemsCount, { limit: this.namedItemsCount });
    },
    namedSubtitle (): string {
      return $tc('{limit} named users', this.namedItemsCount, { limit: this.namedItemsCount });
    },
    forConcurrentAndNamedSubtitle (): string {
      return `${this.forConcurrentSubtitle}, ${this.namedSubtitle}`;
    },
    concurrentSubtitle (): string {
      return $tc('{limit} concurrent users', this.concurrentItemsCount, { limit: this.concurrentItemsCount });
    },
    splaSubtitle (): string {
      return `${$tc('{limit} SPLA contract', this.splaContracts, { limit: this.splaContracts })}`;
    },
    splaConcurrentSubtitle (): string {
      return `${this.splaSubtitle}, ${this.concurrentSubtitle}`;
    },
    splaNamedSubtitle (): string {
      return `${this.splaSubtitle}, ${this.namedSubtitle}`;
    },
    splaNamedAndConcurrentSubtitle (): string {
      return `${this.splaSubtitle}, ${this.concurrentSubtitle}, ${this.namedSubtitle}`;
    },
    getSubtitle (): string {
      if (this.splaContracts) {
        return this.getSplaSubtitle;
      }
      return this.getNotSplaSubtitle;
    },
    getNotSplaSubtitle (): string {
      if (this.concurrentItemsCount && this.namedItemsCount) {
        return this.forConcurrentAndNamedSubtitle;
      }

      if (this.namedItemsCount) {
        return this.forNamedSubtitle;
      }

      return this.forConcurrentSubtitle;
    },

    getSplaSubtitle (): string {
      if (this.concurrentItemsCount && this.namedItemsCount) {
        return this.splaNamedAndConcurrentSubtitle;
      }

      if (this.namedItemsCount) {
        return this.splaNamedSubtitle;
      }

      if (this.concurrentItemsCount) {
        return this.splaConcurrentSubtitle;
      }

      return this.splaSubtitle;
    },
    getDownloadRoute (): RawLocation {
      return PRODUCT_CONTEXT_TO_DOWNLOAD_MAP[this.item.productKeyName];
    },
    defaultClass (): string {
      const className = ['margin-bottom-2x-xs-sm', 'height-auto-xs-sm'];
      if (!this.last) {
        className.push('margin-bottom-2x');
      }
      return className.join(' ');
    },
    hasPdPro (): boolean {
      return this.subscriptions.some((subscription: Subscription) => {
        return subscription.isActive && subscription.products.some((product) => {
          return ['pdfm11_pro', 'pdfm_pro_by_subscription'].indexOf(product.keyName) > -1;
        });
      });
    },
    productSubscriptions (): Subscription[] {
      return this.subscriptions.filter((subscription: Subscription) => subscription.hasProduct(this.item.productKeyName)) as Subscription[];
    },
    splaActiveNumber (): number {
      return this.productSubscriptions.filter((subscription) => subscription.isPostPaid && !subscription.isSuspended && !subscription.isExpired).length;
    },
    perpetualNumber (): number {
      return this.productSubscriptions.filter((subscription) => subscription.isPermanent && !subscription.isSuspended && !subscription.isExpired).length;
    },
    activeSubscriptionsNumber (): number {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.item.activeSubscriptions - this.splaActiveNumber - this.perpetualNumber;
    },
  },
});



import Vue from 'vue';
import ConfigurationProfileDropdown from '@/ui/configurationProfileDropdown/index.vue';
import PdApplyConfigurationProfileRequest from '@/api/pdApplyConfigurationProfileRequest';
import GetPdBusinessSubscriptionsRequest from '@/api/getPdBusinessSubscriptionsRequest';

const POLLING_PERIOD = 1000;

export default Vue.extend({
  name: 'configuration-profile-tab',
  components: { ConfigurationProfileDropdown },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    subset: {
      type: Object,
    },
  },

  data () {
    return {
      profilesLoading: true,
      subscriptionsLoading: false,
      configurationProfileId: null as number,
    };
  },

  computed: {
    loading (): boolean {
      return this.subscriptionsLoading || this.profilesLoading;
    },
  },

  created () {
    this.load();
    this.$bus.$on('subsetSaved', this.save);
  },

  destroyed () {
    this.$bus.$off('subsetSaved', this.save);
  },

  methods: {
    reset (): void {
      this.configurationProfileId = null;
    },
    load (): void {
      if (this.subset) {
        this.subscriptionsLoading = true;
        this.$api.authorizedCall(new GetPdBusinessSubscriptionsRequest({
          uuid: this.subset.subsetUuid || this.subset.subscriptionUuid,
        })).then((data) => {
          this.configurationProfileId = null;
          if (data.subscriptions.length && data.subscriptions[0].configuration_profile) {
            this.configurationProfileId = data.subscriptions[0].configuration_profile.id;
          }
        }).finally(() => {
          this.subscriptionsLoading = false;
        });
      }
    },

    save (params): void {
      const request = new GetPdBusinessSubscriptionsRequest({ uuid: params.uuid });

      // Waiting for subset to be created on desktop backend side
      this.$api.authorizedCall(request).then((response) => {
        if (response.subscriptions.length) {
          const promise = this.$api.authorizedCall(new PdApplyConfigurationProfileRequest({
            configurationProfileId: this.configurationProfileId,
            subscriptions: [params.uuid],
          }));
          this.$emit('save', promise);
        } else {
          setTimeout(() => {
            this.save(params);
          }, POLLING_PERIOD);
        }
      });
    },

    onProfilesReady (): void {
      this.profilesLoading = false;
    },

    onProfilesLoadStarted (): void {
      this.profilesLoading = true;
    },
  },

  watch: {
    subset (value) {
      if (value) {
        this.load();
      } else {
        this.reset();
      }
    },
  },
});


/**
 * File: pdConfigurationProfilesRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { Dictionary } from '@core/common/types';
import ConfigurationProfile from '@core/models/configurationProfile';

export type PdConfigurationProfilesRequestParams = {
  totalOnly?: boolean;
}

export default class PdConfigurationProfilesRequest extends ApiRequest<PdConfigurationProfilesRequestParams, any> {
  get cacheNameSpace (): undefined {
    return undefined;
  }

  get url (): string {
    const qs = this.buildQueryString(this.queryParams);
    return `/desktop/api/v1/ba/configuration_profiles${qs}`;
  }

  get queryParams (): Dictionary<boolean> {
    return {
      totalOnly: this.params.totalOnly,
    };
  }

  getTotal (): number {
    return this.data.total;
  }

  getProfiles (): ConfigurationProfile[] {
    return this.data.configuration_profiles.map((item) => new ConfigurationProfile(item));
  }
}

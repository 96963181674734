/**
 * File: pdBusinessSubscriptionsRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import PdSubscription from '@/models/pdSubscription';

export default class PdBusinessSubscriptionsRequest extends ApiRequest {
  /**
   *
   * @param {?Object} params
   * @param {?String} params.product
   */
  constructor (params) {
    /* eslint no-useless-constructor: "off" */
    super(params);
  }

  get url () {
    let url = '/desktop/api/v1/ba/licenses';

    if (this.params.hasOwnProperty('product')) {
      url += `?product=${this.params.product}`;
    }

    return url;
  }

  getSubscriptions () {
    if (this.data === null) {
      return null;
    }

    return this.data.licenses.reduce((result, license) => {
      const parent = new PdSubscription(license);

      return result.concat([parent]).concat(license.subsets.map((subset) => new PdSubscription(subset, parent)));
    }, []);
  }
}

<copyright>
File: splaNotification.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import md5 from 'blueimp-md5';

const KEY_NAME = 'spla_notification_hidden';

export default {
  name: 'spla-notification',
  data () {
    return {
      hash: md5(this.$appData.session.token),
    };
  },
  methods: {
    onClose () {
      this.$localStorage.set(KEY_NAME, this.hash);
    },
  },
  computed: {
    isVisible () {
      return this.hash !== this.$localStorage.get(KEY_NAME);
    },
  },
};

</script>

<template lang="pug">

notification(v-if="isVisible", :closeButton="true", @close="onClose")
  template {{ $t('Important! The data represented below is not a bill. It is provided for reference only.') }}

</template>

<!--
File: beta.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
-->

<script>

import Markdown from 'markdown';
import AcceptNdaRequest from '@/api/acceptNdaRequest';
import RegisterEventRequest from '@/api/registerEvent';
import { scrollTo } from '@core/common/animations';

const NDA_TEXT_FILENAME = 'nda';
const REQUEST_BETA_NAME = 'request';
const CHALLENGE_FILENAME = 'challenge';

export default {
  name: 'beta',
  props: {
    page: {
      type: String,
      validator (value) {
        return value === CHALLENGE_FILENAME;
      },
    },
    betaDocumentsRoot: {
      type: String,
      required: true,
    },
    featureName: {
      type: String,
      required: true,
    },
    betaTextFilename: {
      type: String,
      default: 'beta',
    },
    useTimestamp: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    const betaTextFilename = this.betaTextFilename;
    const env = this.$appData?.config?.environment;

    return {
      loading: false,
      tab: null,
      tabs: {
        [REQUEST_BETA_NAME]: 'Beta Request',
        [NDA_TEXT_FILENAME]: 'NDA',
        [betaTextFilename]: 'Beta Page',
        [CHALLENGE_FILENAME]: 'Challenge',
      },
      accepted: false,
      production: env,
      texts: {
        [betaTextFilename]: '',
        [NDA_TEXT_FILENAME]: '',
        [REQUEST_BETA_NAME]: '',
        [CHALLENGE_FILENAME]: '',
      },
    };
  },
  mounted () {
    this.load(this.filename);
    this.injectStylesheet();
  },
  destroyed () {
    this.ejectStylesheet();
  },
  computed: {
    feature () {
      return this.$appData.session.features[this.featureName] || {};
    },
    isAllowed () {
      return this.feature.accessible && (!this.feature.requiredNdaId || this.feature.ndaAccepted);
    },
    showCheckbox () {
      return this.feature.accessible && this.feature.requiredNdaId && !this.feature.ndaAccepted;
    },
    filename () {
      if (this.tab) {
        return this.tab;
      }
      if (this.isAllowed) {
        return this.page || this.betaTextFilename;
      }
      if (this.feature.accessible) {
        return NDA_TEXT_FILENAME;
      }
      return REQUEST_BETA_NAME;
    },
    htmlText () {
      const text = this.texts[this.filename];
      return text && Markdown.parse(text, 'Maruku');
    },
    stylesheetHref () {
      return `${this.betaDocumentsRoot}/style.css`;
    },
  },
  watch: {
    accepted () {
      const acceptNda = new AcceptNdaRequest({
        id: this.feature.requiredNdaId,
      });

      this.loading = true;

      this.$api.authorizedCall(acceptNda).then(() => {
        this.loading = false;
        this.feature.ndaAccepted = true;
        this.load(this.betaTextFilename);
        // Scroll top if NDA accepted
        scrollTo({
          to: document.documentElement,
          duration: 250,
        });
      }).catch(() => {
        this.$emit('error');
      });
    },
  },
  methods: {
    load (filename) {
      if (this.texts[filename] && this.texts[filename].length) {
        return;
      }
      if (this.isAllowed) {
        this.$api.authorizedCall(new RegisterEventRequest({
          eventName: `${this.featureName} view`,
          pageUrl: location.pathname,
        }));
      }

      let url = `${this.betaDocumentsRoot}/${filename}.md`;
      if (this.useTimestamp) {
        url += `?${Date.now()}`;
      }

      fetch(url).then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            this.texts[filename] = text;
            this.$nextTick(this.scrollTo);
          });
        }
      }).catch(() => {
        this.loading = false;
        this.$emit('error');
      });
    },
    switchTab (params) {
      this.load(params.tab);
      this.tab = params.tab;
    },
    scrollTo () {
      const element = location.hash && this.$el.querySelector(location.hash);

      if (element) {
        scrollTo({
          to: element,
        });
      }
    },
    showEditor () {
      this.switchTab({
        tab: this.filename,
      });
      this.$modal.show('mdEditor');
    },
    findStylesheet () {
      return document.querySelector(`link[href^="${this.stylesheetHref}"]`);
    },
    injectStylesheet () {
      let stylesheet = this.findStylesheet();

      if (stylesheet) {
        return;
      }

      stylesheet = document.createElement('link');
      stylesheet.type = 'text/css';
      stylesheet.rel = 'stylesheet';
      stylesheet.href = this.stylesheetHref + '?' + Date.now();
      document.head.appendChild(stylesheet);
    },
    ejectStylesheet () {
      const stylesheet = this.findStylesheet();

      if (stylesheet) {
        stylesheet.parentNode.removeChild(stylesheet);
      }
    },
  },
};

</script>

<template lang="pug">

div
  .pull-right.line-height-4x(v-if="!production")
    icon(@click="showEditor", name="pencil-white")
  .beta-text
    div(v-html="htmlText")
  checkbox(
    v-model="accepted",
    v-if="showCheckbox",
    :disabled="loading",
    :data-name="$name('checkbox-accept')"
  )
    | {{ $t('I have read and accept the confidentiality agreement') }}
  modal(name="mdEditor", size="big", v-if="!production", :data-name="$name('modal-text')")
    template(slot="header") Beta Text
    template(slot="content")
      tabs(:tabs="tabs", :current="tab", @switch="switchTab")
      textarea(v-model="texts[tab]").height-45x
      .margin-top.padding-bottom-4x
        btn(color="blue", @click="$modal.hide('mdEditor')").pull-right Apply

</template>

<style scoped lang="sass">

@for $i from 1 through 4
  .beta-text /deep/ * + h#{ $i }
    margin-bottom: $vertical-step
    margin-top: $vertical-step * 3

.beta-text /deep/ h4
  font-size: $small-font-size

</style>

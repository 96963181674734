
import Vue from 'vue';

import { MFA_RESCUE_CODE_PAGE } from '@/routes/constants';
import TwoStepVerificationPageMixin from './twoStepVerificationPageMixin';

export default Vue.extend({
  name: 'two-step-verification-one-time-password-page',

  mixins: [TwoStepVerificationPageMixin],

  methods: {
    async cantUseOneTimePassword () {
      await this.$router.push({ name: MFA_RESCUE_CODE_PAGE });
    },
  },
});


/**
 * File: pdApplyConfigurationProfileRequest.ts
 *
 * Copyright:
 * Copyright © 2021 Parallels International GmbH. All rights reserved.
 *
 **/

import ApiRequest, { Json, Method } from '@core/api/apiRequest';
import PdComputersRequest from './pdComputersRequest';
import Service from '@core/constants/services';
import { snakeize } from '@core/common/utils';

interface PdApplyConfigurationProfileRequestParams {
  configurationProfileId: number;
  hosts?: string[];
  subscriptions?: string[];
};

export default class PdApplyConfigurationProfileRequest extends ApiRequest<PdApplyConfigurationProfileRequestParams> {
  get method (): Method {
    return 'POST';
  }

  get url (): string {
    return `/${Service.Desktop}/api/v1/ba/configuration_profiles/apply`;
  }

  get body (): Json {
    return snakeize(this.params);
  }

  load (): Promise<Json> {
    return super.load()
      .then((data) => {
        new PdComputersRequest({}).dropFullCache();
        return data;
      });
  }
}

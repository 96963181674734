/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';

export const ACTION_REPLY = 'Reply'; // $t('Reply')
export const ACTION_CLOSE = 'Close'; // $t('Close')
export const ACTION_RESCHEDULE = 'Reschedule'; // $t('Reschedule')
export const ACTION_SURVEY = 'Survey'; // $t('Survey')
export const ACTION_REOPEN = 'Reopen'; // $t('Reopen')
export const ACTION_FORK = 'Fork'; // $t('Fork')

export const REF_WEBSITE = 'website';
export const REF_ACCOUNT = 'account';
export const REF_PRODUCT = 'product';
export const REF_DIRECT = 'direct';
export const REF_DASHBOARD = 'dashboard';

export enum SupportType {
  Presales = 'presales',
  License = 'license',
  Technical = 'technical'
}

export const SUPPORT_TYPE_PRESALES = SupportType.Presales;
export const SUPPORT_TYPE_LICENSE = SupportType.License;
export const SUPPORT_TYPE_TECHNICAL = SupportType.Technical;

export const OLD_TICKETS_DATE = '2018-11-01';

export const RESCHEDULE_SESSION_URI = '/NoAuth/Pages/SessionLink/';

export interface Entity {
  status: string;
  'product_key': string;
  created: string;
}

export function getContactSource () {
  const storeKey = `referrer-${process.env.VUE_APP_NAME}`;
  // @ts-ignore
  const referrer = Vue.ss.get(storeKey);
  // @ts-ignore
  const click = Vue.ss.get('support-click', {});

  if (referrer) {
    const search = referrer.href.split('?')[1];

    if (search && search.includes('utm_source=')) {
      return REF_PRODUCT;
    }

    if (!referrer.direct) {
      const domain = referrer.href.match(/:\/\/(.[^/]+)/)[1];

      if (domain === 'www.parallels.com' || domain === 'www.parallels.local') {
        return REF_WEBSITE;
      }

      if (click.source === REF_DASHBOARD) {
        return REF_DASHBOARD;
      }

      if (domain === 'my.parallels.com' || domain === 'my.parallels.local') {
        return REF_ACCOUNT;
      }
    }
  }

  return REF_DIRECT;
}

//
//  Labels
//
//  $t('Remote host OS')
//  $t('Call level')
//  $t('License Key')
//  $t('Technical Report ID')
//  $t('Phone number')
//  $t('Product OS')
//  $t('Product Version')
//  $t('Issue severity')
//  $t('Would you allow remote access to Parallels Support if required?')
//  $t('As an authorized Customer representative, I hereby agree to provide access to my server in accordance with Parallels Server Permission Policy and hereby accept the terms and conditions thereof without limitation.')
//  $t('Issue summary (will be used as your ticket subject)')
//  $t('Describe your issue, steps taken to troubleshoot it or paste the exact error message if any.')

//
//  Placeholders
//
//  $t('Please select')
//  $t('Select your operating system')
//  $t('Briefly describe your issue, or paste the exact error message (if any).')
//  $t('Provide as many details as possible.')

//
//  Tooltips
//
//  $t('Remote computer Parallels Access connects to.')
//  $t('Sending diagnostic data will help Parallels Support Team to speed up issue resolution. Click {kb_link}here{/kb_link} for instructions.')
//  $t('Visit {link_name}KB{/link_name} to learn how to check product version.')
//  $t('Sending diagnostic data will help Parallels Support Team to speed up issue resolution. Click for instructions {kb_link1}here{/kb_link1} for agent or {kb_link2}here{/kb_link2} for mobile.')
//  $t('For Severity 1 and Severity 2 cases please make sure that you can answer the phone right away in order to work on the issue on an ongoing basis.')
//  $t('If we cannot reach you, the Severity can be adjusted as per the {link_name}Severity definitions{/link_name}.')
//  $t('Before we can access your server, please read {link_name}Parallels Server Permission Policy{/link_name} and grant permission to Parallels Support.')

//
//  Errors
//
//  $t('This field is required')
//  $t('Invalid license key format')
//  $t('Invalid phone format')
//  $t('Please enter at least 5 characters and no more than 200')
//  $t('Please enter at least 5 characters and no more than 2000')

//
//  Options
//
//  $t('presales')
//  $t('license')
//  $t('technical')
//  $t('Accept')
//  $t('Decline')

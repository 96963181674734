/**
 * File: getSetsRequest.ts
 *
 * Copyright:
 * Copyright © 2022 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import { ACCOUNT_SERVICE } from '@core/constants/services';

export class GetCountriesRequest extends ApiRequest {
  get url (): string {
    return `/${ACCOUNT_SERVICE}/api/v1/sets/COUNTRIES`;
  }
}



import { defineComponent } from 'vue';
import LanguageSwitcher from '@/ui/languageSwitcher.vue';
import {
  LEGAL_NOTICES_URL,
  PRIVACY_POLICY_URL,
  HELP_WITH_SIGN_IN_URL,
  HELP_UPGRADE_TO_PRO_URL, SYSTEM_STATUS
} from '@core/constants/links';
import { FEATURE_STATUS_PAGE_LINK_IN_MA } from '@core/constants/features';
import GetStatusPageDetails from '@/api/getStatusPageDetails';

export default defineComponent({
  name: 'bottom-links',
  props: {
    options: {
      type: Array,
    },
  },
  components: {
    LanguageSwitcher,
  },
  data () {
    return {
      isActiveIncident: false,
      SYSTEM_STATUS,
    };
  },
  created () {
    this.checkActiveIncident();
  },
  computed: {
    showSystemStatus (): boolean {
      return this.$appData.session?.isFeatureAccessible(FEATURE_STATUS_PAGE_LINK_IN_MA);
    },
    items (): { [key: string]: string } {
      const links = {
        [LEGAL_NOTICES_URL]: this.$t('Legal Notices'),
        [PRIVACY_POLICY_URL]: this.$t('Privacy Policy'),
        [HELP_WITH_SIGN_IN_URL]: this.$t('Help with Sign In'),
        [SYSTEM_STATUS]: this.$t('System Status'),
        [HELP_UPGRADE_TO_PRO_URL]: this.$t('Help'),
      };
      if (!this.showSystemStatus) {
        delete links[SYSTEM_STATUS];
      }
      Object.keys(links).forEach((key) => {
        if (!this.options.includes(key) && key !== SYSTEM_STATUS) {
          delete links[key];
        }
      });

      return links;
    },
  },
  methods: {
    async checkActiveIncident () {
      if (this.showSystemStatus) {
        const result = await this.$api.authorizedCall(new GetStatusPageDetails());
        this.isActiveIncident = result?.data?.['active_incident_details'].length;
      }
    },
  },
  watch: {
    '$appData.session': async function () {
      await this.checkActiveIncident();
    },
  },
});


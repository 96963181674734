/**
 * File: subscriptionContactRequest.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 */

import ApiRequest, { Method, Json } from '@core/api/apiRequest';
import { camelize } from '@core/common/utils';
import Service from '@core/constants/services';

interface ResponseData {
  contactEmail: string;
  matched: boolean;
}

interface RequestParams {
  uuid: string;
  service: Service;
  email: string;
}

export default class SubscriptionContactRequest extends ApiRequest<RequestParams, ResponseData> {
  get url (): string {
    return `/${this.params.service}/api/v1/subscriptions/${this.params.uuid}/contact`;
  }

  get method (): Method {
    return 'POST';
  }

  get body (): Json {
    return {
      email: this.params.email,
    };
  }

  onFetchSuccess (data): ResponseData {
    return camelize(data);
  }
}

/**
 * File: links.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import { Environment } from '@/models/initialConfig';

export enum EnvDependentLink {
  STATIC_URL = 'staticUrl',
  DESKTOP_BETA_DOCUMENTS_ROOT = 'desktopBetaDocumentsRoot',
  RAS_BETA_DOCUMENTS_ROOT = 'rasBetaDocumentsRoot',
  PVAD_BETA_DOCUMENTS_ROOT = 'pvadBetaDocumentsRoot',
}

const linksMap = {
  [EnvDependentLink.STATIC_URL]: (isProd: boolean) => isProd ? 'https://static.parallels.com' : 'https://static.myparallels.com',
  [EnvDependentLink.DESKTOP_BETA_DOCUMENTS_ROOT]: (isProd: boolean) => isProd ? '//static.parallels.com/beta/desktop' : '//static.parallels.com/beta-staging/desktop',
  [EnvDependentLink.RAS_BETA_DOCUMENTS_ROOT]: (isProd: boolean) => isProd ? '//static.parallels.com/beta/ras' : '//static.parallels.com/beta-staging/ras',
  [EnvDependentLink.PVAD_BETA_DOCUMENTS_ROOT]: (isProd: boolean) => isProd ? '//static.parallels.com/beta/pvad' : '//static.parallels.com/beta-staging/pvad',
};

export const getEnvDependentLink = (vue: Vue, link: EnvDependentLink) => {
  const env = vue.$appData?.config?.environment;
  const productionEnv = env && env === Environment.Production;
  return linksMap[link](productionEnv);
};

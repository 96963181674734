/**
 * File: lsUniversalKeysRequest.js
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import ApiRequest from '@core/api/apiRequest';
import UniversalKey from '@/models/universalKey';

export default class LsUniversalKeysRequest extends ApiRequest {
  prefix = '/license';

  get cacheNameSpace () {
    return 'universal_keys';
  }

  /**
   *
   * @param {Object} params
   * @param {Boolean} params.business
   */
  constructor (params) {
    super(params);
    this.params = params;
  }

  get url () {
    return this.prefix + (this.params.business ? '/api/v1/ba/universal_keys' : '/api/v1/universal_keys');
  }

  /**
   *
   * @returns {Array<UniversalKey>} - list of UniversalKey models.
   */
  getUniversalKeys () {
    if (this.data === null) {
      return null;
    }
    const licenses = [];
    this.data.universal_keys.forEach((ukData) => {
      licenses.push(new UniversalKey(ukData));
    });
    return licenses;
  }
}
